import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function InActiveTextboxWithOutValue(props) {
  const { label, value, error } = props;

  return (
    <Box
      component="form"
      sx={{
        m: 1,
        mb: 0,
        width: "25ch",
        height: "30px",
      }}
      noValidate
      autoComplete="off"
    >
      {/* <TextField
        id="outlined-basic"
        label={label}
        sx={{
          height: "30px",
        }}
        // value={value}
        variant="filled"
        color="success"
        disabled
      /> */}
      <div
        style={{
          height: "30px",
          background: "rgb(224 224 224)",
          paddingLeft: "5px",
          display : "flex",
          flexDirection : "row",
          alignItems:"center",
          textTransform : "none"
        }}
      >
        {label}
      </div>
    </Box>
  );
}
