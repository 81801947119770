var initState = {};

export const addCofigurationReducer = (state = initState, action) => {
  const { type, data, id } = action;

  // console.log("state", state);

  switch (type) {
    case "get":
      //  console.log(" changed data ----> addCofigurationReducer", data);
      return data;
    case "add":
      // console.log("addCofigurationReducer", data);
      return {
        ...state,
        [data.type]: [...state[data.type], data.data],
      };
    case "update":
      // console.log("updateCofigurationReducer", data.data.id, data.data);
      let newdata = {
        ...state,
        [data.type]: state[data.type].filter((e) => {
          return e.id !== id;
        }),
      };
      return {
        ...newdata,
        [data.type]: [...newdata[data.type], data.data],
      };

    case "delete":
      // console.log("deleteCofigurationReducer",  id,data);
      return {
        ...state,
        [data.type]: state[data.type].filter((e) => {
          return e.id !== id;
        }),
      };

    default:
      return state;
  }
};
