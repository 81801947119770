import actions from "./UserAction";

const { USER_CREATE, SELECTED_USER } = actions;

const initState = {
  user: null,
  loading: false,
  error: null,
  formData: {},
  selectedRow: [],
  columnsStatus: {
    userId: true,
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    role: false,
    team: false,
  },
};

const UserReducers = (state = initState, action) => {
  const { type, data, err } = action;
  // console.log("3", data);
  switch (type) {
    case USER_CREATE:
      return { ...state, formData: data };
    case SELECTED_USER:
      return { ...state, selectedRow: data };
    case "COLUMNSTATUS":
      return {
        ...state,
        columnsStatus: {
          ...state.columnsStatus,
          [`${data}`]: state.columnsStatus[`${data}`]
            ? state.columnsStatus[`${data}`]
              ? false
              : true
            : true,
        },
      };
    default:
      return state;
  }
};
export default UserReducers;
