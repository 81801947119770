import axios from "axios";
import { Grid, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GeneralDateField } from "../../Components/GeneralComponents/GeneralDateField";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import { GeneralSelectField } from "../../Components/GeneralComponents/GeneralSelectField";
import "./ShareCapital.css";
import styles from "../Members/member.module.css";
import {
  shareCapitalAddForm,
  shareCapitalUpTableRowData,
} from "../../Redux/ShareCapital/shareCapitalActionCreator";
import "./ShareCapitalAddUpdateForm.css";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import {
  addNewShareCapitalActionCreater,
  getSayasAccountActionCreater,
} from "../../Redux/SayasAccount/sayasAccountActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { getDashboardTransfer } from "../../Redux/Dashboard/dashboardActionCreator";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [mnth, day, date.getFullYear()].join("/");
}
export const ShareCapitalAddForm = (props) => {
  const {
    setOpen,
    currentRowData,
    setCurrentRowData,
    setTableUpdate,
    tableConflict,
    // pricePerShare,
    title,
    modalClose,
    dataLength,
    open,
  } = props;

  const [data, setData] = useState(
    title === "Add"
      ? {
          // shareCapitalId: new Date().valueOf(),
          authorisedShareCapital: 0,
          authorisedShare: 0,
          date: new Date(),
          pricePerShare: 0,
          // pricePerShare: pricePerShare ? pricePerShare : 0,
          typesOfShare: "Common",
          remarks: "",
        }
      : currentRowData
  );
  var [conflict, setConflict] = useState(false);
  var [checkVal, setCheckVal] = useState(false);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [shareTypes, setShareTypes] = useState([]);
  const [sharePricePerShare, setSharePricePerShare] = useState({});
  /// fetch config data
  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  useEffect(() => {
    setShareTypes(
      getConfigData.shareType
        ? getConfigData.shareType.map((e) => {
            return e.type;
          })
        : []
    );
    setSharePricePerShare(
      getConfigData?.shareType?.reduce((ans, ele) => {
        return { ...ans, [`${ele.type}`]: parseInt(ele.pricePerShare) };
      }, {})
    );
  }, [getConfigData]);

  // console.log("shareCapitaladdupdate",sharePricePerShare);

  useEffect(() => {
    // console.log(initialState);
    let initialState = {
      // shareCapitalId: new Date().valueOf(),
      authorisedShareCapital: "",
      authorisedShare: "",
      date: new Date(),
      pricePerShare: sharePricePerShare[`Common`],
      // pricePerShare: 0,
      typesOfShare: "Common",
      remarks: "",
    };
    setData(title === "Add" ? initialState : currentRowData);
  }, [modalClose, open, sharePricePerShare]);

  // console.log("5", data);
  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const UpdateShareCapitalData = async (id) => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/v1/shareCapital" + `/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      dispatch(
        openSnackbar("Authorised Shares Updated Successfully", "success")
      );
      setSubmitted(false);
      setConflict(true);
    } catch (e) {
      // dispatch(openSnackbar(e, "error"));
      // dispatch(loaderEndActionCreater());
      dispatch(
        openSnackbar(`Authorised Shares Updated failed : ${e}`, "error")
      );
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
    props.setOpen(false);
  };
  // console.log(data);

  const handleClickOpen = () => {
    setSubmitted(true);
    console.log(data);
    let validate =
      data.authorisedShareCapital === "" ||
      data.authorisedShare === "" ||
      data.authorisedShare === NaN ||
      data.typesOfShare === "";
    // shareTypes?.length <= 0 ||
    // !shareTypes.includes("Common");

    // console.log("range", data.pricePerShare);
    if (validate) {
      dispatch(openSnackbar("Please fill all required fields.", "error"));
      return;
    } else if (
      parseInt(data.authorisedShare) <= 0 ||
      parseInt(data.authorisedShare) > 99999999999
    ) {
      // console.log("range");
      dispatch(openSnackbar("Please Enter a Valid Range", "error"));
      return;
    } else if (
      data.pricePerShare === "" ||
      isNaN(data.pricePerShare) ||
      parseInt(data.pricePerShare) <= 0
    ) {
      dispatch(openSnackbar("Please Enter a Valid Price Per Share", "error"));
      return;
    } else {
      // console.log("valid");
      setOpenConfirmModal(true);
    }
  };

  const handleClose = () => {
    setOpenConfirmModal(false);
  };

  const handleCheck = () => {
    setCheckVal(!checkVal);
  };

  useEffect(() => {
    // console.log(
    //   "on conflict",
    //   sharePricePerShare,
    //   sharePricePerShare[`${data.typesOfShare}`]
    // );

    setData({
      ...data,
      pricePerShare: sharePricePerShare[`${data.typesOfShare}`],
    });
    setConflict(false);
  }, [conflict]);

  return (
    <div
      className="shareCapitalAddForm"
      style={{
        padding: "20px",
        width: "26vw",
        // border: "1px solid black",
      }}
    >
      <div className="shareCapitalFormHeader w-100">
        <label
          style={{
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontSize: "20px",
            fontWeight: "400",
            color: "#303030",
          }}
        >
          Share Capital
        </label>
      </div>

      <GeneralSelectField
        data={data}
        setData={setData}
        required
        conflict={conflict}
        setConflict={setConflict}
        submitted={submitted}
        dataKey={"typesOfShare"}
        place={"Type of Share *"}
        items={shareTypes.length > 0 ? shareTypes : []}
      />

      <GeneralInputField
        data={data}
        setData={setData}
        conflict={conflict}
        setConflict={setConflict}
        required
        submitted={submitted}
        maxLimit={11}
        onlyNumber
        value={data.authorisedShare}
        dataKey={"authorisedShare"}
        label={"Authorised Shares"}
      />

      <GeneralInputField
        data={data}
        setData={setData}
        disabled={true}
        conflict={conflict}
        required
        submitted={submitted}
        value={sharePricePerShare[`${data.typesOfShare}`]}
        setConflict={setConflict}
        dataKey={"pricePerShare"}
        label={"Price Per Share"}
      />

      <GeneralInputField
        data={data}
        setData={setData}
        value={
          (data.authorisedShare === "" ? 0 : data.authorisedShare) *
          data.pricePerShare
        }
        disabled
        dataKey={"authorisedShareCapital"}
        label={"Authorised Share Capital"}
      />

      <GeneralDateField
        data={data}
        setData={setData}
        required
        maxCurrentDate
        submitted={submitted}
        dataKey={"date"}
        marginTop
        label={"Date"}
      />

      <Grid item xs={12} sx={{ mb: 2 }}>
        <GeneralInputField
          multiline
          data={data}
          setData={setData}
          dataKey={"remarks"}
          marginTop
          label={"Remarks"}
        />
      </Grid>

      <Grid
        className="shareCapitalAddFormBtns"
        fullWidth
        sx={{
          p: 1,
          m: 1,
          display: "flex",
          flexDirection: "row-reverse",
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
        }}
      >
        <Button
          type="submit"
          className="addBtn"
          variant="contained"
          sx={{
            bgcolor: "#0096FF!important",
            color: "#FFFFFF",
            marginLeft: "1rem",
            marginRight: "-1rem",
            padding: "0rem 2rem",
            borderRadius: "5px",
            fontFamily: "Mulish",
            fontWeight: "bold",
            fontStyle: "normal",
            height: "30px",
          }}
          onClick={handleClickOpen}
        >
          {props.title}
        </Button>
        <Button
          variant="text"
          className="addBtn"
          sx={{
            textTransform: "capitalize",
            color: "#ACACAC",
            padding: "0rem 2rem",
            borderRadius: "5px",
            fontFamily: "Mulish",
            fontWeight: "bold",
            fontStyle: "normal",
            height: "30px",
          }}
          onClick={() => {
            setOpen(false);
            if (title == "Add") {
              setData({
                // shareCapitalId: new Date().valueOf(),
                authorisedShareCapital: "",
                authorisedShare: "",
                date: new Date(),
                pricePerShare: 0,
                typesOfShare: "",
                remarks: "",
              });
              // dispatch(
              //   openSnackbar("Authorised Shares Addition Cancelled", "error")
              // );
            } else {
              setCurrentRowData([]);
              // dispatch(
              //   openSnackbar("Authorised Shares Update Cancelled", "error")
              // );
            }
          }}
        >
          Cancel
        </Button>
        {/* <Grid xs = {12}></Grid> */}

        <ConfirmTransferShare
          newData={data}
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          dataLength={dataLength}
          tableConflict={tableConflict}
          setData2={setData}
          setOpen2={setOpen}
        />
      </Grid>
    </div>
  );
};

export default function ConfirmTransferShare(props) {
  const {
    open,
    setOpen,
    newData,
    dataLength,
    tableConflict,
    setData2,
    setOpen2,
  } = props;

  const [submitted, setSubmitted] = useState(false);
  var [checkVal, setCheckVal] = useState(false);
  var [conflict, setConflict] = useState(false);
  const [shareTypes, setShareTypes] = useState([]);
  const [data, setData] = useState(newData);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const dispatch = useDispatch();
  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const addShareCapitalData = async () => {
    try {
      // let preId = data["typesOfShare"].charAt(0).toUpperCase();

      // console.log(preId);

      // data["shareCapitalId"] =
      //   `${preId}` + ("0000000" + (dataLength + 1)).slice(-7);
      // data["shareCapitalId"] = new Date().valueOf();
      // console.log(data);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/shareCapital",
        data,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      dispatch(
        openSnackbar("Authorised Shares Addition successfully", "success")
      );
      setSubmitted(false);
      setConflict(true);
      tableConflict(true);
      setOpen2(false);
      setData2({
        // shareCapitalId: new Date().valueOf(),
        authorisedShareCapital: "",
        authorisedShare: "",
        date: new Date(),
        // pricePerShare: 100,
        typesOfShare: "",
        remarks: "",
      });
      dispatch(getSayasAccountActionCreater(getAuthData));
    } catch (e) {
      // dispatch(openSnackbar(e, "error"))
      dispatch(loaderEndActionCreater());
      switch (e.response.status) {
        case 403:
        case 401:
          dispatch(
            openSnackbar(
              `Authorised Shares Addition failed - You dont have access to perform this operation`,
              "error"
            )
          );
          break;
        default:
          dispatch(openSnackbar(`Authorised Shares Addition failed`, "error"));
      }

      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
    setOpen(false);
    setData({
      // shareCapitalId: new Date().valueOf(),
      authorisedShareCapital: "",
      authorisedShare: "",
      date: new Date(),
      pricePerShare: 0,
      typesOfShare: "",
      remarks: "",
    });
  };

  useEffect(() => {
    setShareTypes(
      getConfigData.shareType
        ? getConfigData.shareType.map((e) => {
            return e.type;
          })
        : []
    );
  }, [getConfigData]);
  useEffect(() => {
    setData(newData);
  }, [newData]);

  // const handleClickOpen = () => {
  //   setSubmitted(true);
  //   // console.log(data);
  //   // if (
  //   //   data.fromMemberID === "" ||
  //   //   data.toMemberID === "" ||
  //   //   data.typeOfShare === "" ||
  //   //   data.totalShare === ""
  //   // ) {
  //   //   dispatch(openSnackbar("Please fill all mandatory field", "error"));
  //   //   // console.log("you enter wrong from member id");
  //   //   return;
  //   // } else if (data.toMemberName === "" || data.fromMemberName === "") {
  //   //   dispatch(openSnackbar("Entered Membership ID is incorrect", "error"));
  //   //   // console.log("you enter wrong to member id");
  //   //   return;
  //   // } else if (data.typeOfShare === "" || data.totalShare === null) {
  //   //   return;
  //   // } else if (data.fromMemberID === data.toMemberID) {
  //   //   dispatch(openSnackbar("Same member share transfer not allowed", "error"));
  //   //   return;
  //   // }
  //   setOpen(false);
  //   setOpenConfirmModal(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setCheckVal(false);
    setOpenConfirmModal(false);
    // setSubmitted(false);
    // setOpen2(false);

    // setData2({
    //   shareCapitalId: new Date().valueOf(),
    //   authorisedShareCapital: "",
    //   authorisedShare: "",
    //   date: new Date(),
    //   pricePerShare: 100,
    //   typesOfShare: "",
    //   remarks: "",
    // });
  };

  const handleCheck = () => {
    setCheckVal(!checkVal);
  };

  const handleSubmit = () => {
    if (!checkVal) {
      dispatch(openSnackbar("please check the confirm box.", "error"));
      return;
    }
    data.authorisedShare = parseInt(data.authorisedShare);
    data.authorisedShareCapital = parseInt(data.authorisedShareCapital);
    /////console
    // console.log(data);
    handleClose();
    dispatch(loaderStartActionCreater());

    setOpen(false);

    addShareCapitalData();
    dispatch(shareCapitalAddForm(data));
    setData({});

    if (props.setTableUpdate) props.setTableUpdate(!props.tableUpdate);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <label
            id={styles.lab}
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Please Confirm Your Data
          </label>
        </DialogTitle>
        <hr className="m-0" />
        <DialogContent>
          <DialogContentText>
            <div className="row">
              <div className="col">
                <GeneralInputField
                  data={data}
                  setData={setData}
                  conflict={conflict}
                  setConflict={setConflict}
                  disabled
                  required
                  submitted={submitted}
                  maxLimit={11}
                  onlyNumber
                  value={data.authorisedShare}
                  dataKey={"authorisedShare"}
                  label={"Authorised Shares"}
                />
                <GeneralInputField
                  data={data}
                  disabled
                  setData={setData}
                  // disabled={true}
                  dataKey={"pricePerShare"}
                  label={"Price Per Share"}
                />
                <GeneralInputField
                  data={data}
                  setData={setData}
                  value={
                    (data.authorisedShare === "" ? 0 : data.authorisedShare) *
                    data.pricePerShare
                  }
                  disabled
                  dataKey={"authorisedShareCapital"}
                  label={"Authorised Share Capital"}
                />
                <GeneralSelectField
                  data={data}
                  setData={setData}
                  disabled
                  required
                  submitted={submitted}
                  dataKey={"typesOfShare"}
                  place={"Type of Share *"}
                  items={shareTypes.length > 0 ? shareTypes : []}
                />
              </div>
              <div className="col">
                <GeneralDateField
                  data={data}
                  setData={setData}
                  disabled
                  required
                  maxCurrentDate
                  submitted={submitted}
                  dataKey={"date"}
                  marginTop
                  label={"Date"}
                />

                {/* <Grid item xs={12} sx={{ mb: 2 }}> */}
                <GeneralInputField
                  multiline
                  maxRows={7.5}
                  disabled
                  data={data}
                  setData={setData}
                  dataKey={"remarks"}
                  marginTop
                  label={"Remarks"}
                />
                {/* </Grid> */}
              </div>
            </div>
          </DialogContentText>
          <DialogContentText align="right" className="mt-4">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
                justifyContent: "flex-start",
                // float : "left"
              }}
            >
              <FormControlLabel
                checked={checkVal}
                control={<Checkbox />}
                label="Data Verified"
                onClick={handleCheck}
                sx={{
                  textTransform: "capitalize",
                }}
              />
            </div>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleSubmit}
              variant={checkVal ? "contained" : "outlined"}
              style={{
                marginLeft: "1rem",
              }}
            >
              Confirm
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

{
  /* ////confirm Modal */
}
//   <Dialog
//   open={openConfirmModal}
//   TransitionComponent={Transition}
//   keepMounted
//   onClose={handleClose}
//   aria-describedby="alert-dialog-slide-description"
//   maxWidth="xl"
// >
//   <DialogTitle
//     sx={{
//       fontWeight: "bold",
//       fontSize: "1.5rem!important",
//       textTransform: "capitalize",
//     }}
//   >
//     Please Confirm your data
//   </DialogTitle>
//   <DialogContent
//     sx={{
//       width: "40rem",
//     }}
//   >
//     <DialogContentText id="alert-dialog-slide-description">
//       {/* {console.log(data)} */}
//       <ul>
//         {Object.keys(data).map((key) => {
//           let val = data[`${key}`];
//           key = key?.split(/(?=[A-Z])/).join(" ");
//           // let formDate = key === "date" ? convert(val) : "12-3-2022";
//           return key === "shareCapitalId" ? (
//             ""
//           ) : (
//             <li
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "flex-start",
//                 justifyContent: "flex-start",
//               }}
//             >
//               <h4
//                 style={{
//                   width: "max-content",
//                   lineBreak: "none",
//                   textTransform: "capitalize",
//                   whiteSpace: "nowrap",
//                 }}
//               >{`${key} : `}</h4>{" "}
//               <h5
//                 style={{
//                   padding: "4px 10px",
//                   justifySelf: "center",
//                   textTransform: "capitalize",
//                 }}
//               >{`${key === "date" ? convert(val) : val}`}</h5>
//             </li>
//           );
//         })}
//       </ul>

//       {/* ///Checkbox */}
//       <div
//         sx={{
//           width: "max-content",
//           flexDirection: "row",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <FormControlLabel
//           checked={checkVal}
//           control={<Checkbox />}
//           label="Verified"
//           onClick={handleCheck}
//           sx={{
//             textTransform: "capitalize",
//           }}
//         />
//       </div>
//     </DialogContentText>
//   </DialogContent>
//   <DialogActions>
//     <Grid
//       className="shareCapitalAddFormBtns"
//       fullWidth
//       sx={{
//         p: 1,
//         // m: 1,
//         display: "flex",
//         flexDirection: "row-reverse",
//         // justifyContent: "flex-start",
//         // alignItems: "flex-start",
//       }}
//     >
//       <Button
//         type="submit"
//         className="addBtn"
//         variant={checkVal ? "contained" : "text"}
//         sx={{
//           padding: "0rem 2rem",
//           borderRadius: "5px",
//           fontFamily: "Mulish",
//           fontWeight: "bold",
//           fontStyle: "normal",
//           textTransform: "capitalize",
//           marginLeft: "1rem 0rem",
//           height: "40px",
//           fontSize: "1rem",
//         }}
//         onClick={handleSubmit}
//         // disabled={!checkVal}
//       >
//         Confirm
//       </Button>
//       <Button
//         variant="text"
//         className="addBtn"
//         sx={{
//           textTransform: "capitalize",
//           color: "#ACACAC",
//           padding: "0rem 2rem",
//           borderRadius: "5px",
//           fontFamily: "Mulish",
//           fontWeight: "bold",
//           fontStyle: "normal",
//           marginLeft: "1rem 0rem",
//           height: "40px",
//           fontSize: "1rem",
//         }}
//         onClick={() => {
//           handleClose();
//         }}
//       >
//         Cancel
//       </Button>
//     </Grid>
//   </DialogActions>
// </Dialog>
