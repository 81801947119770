import React, { useEffect, useState } from "react";
import DataTable from "../../Components/Table";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CsvBuilder } from "filefy";

const ActivityLogsTable = (props) => {
  const { selectedData, usedFor } = props;
  const [activityLogs, setActivityLogs] = useState([]);
  const params = useParams();

  useEffect(() => {
    getActivityLogs();
  }, [params.id]);

  const getActivityLogs = async () => {
    try {
      // console.log(val);
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/activityLogs?user=${params.id}`
      );

      // console.log("getMemberShareTransactions", response.data);

      setActivityLogs(response.data.data);

      // dispatch(loaderEndActionCreater());
    } catch (e) {
      // dispatch(openSnackbar("Get Transaction failed", "error"));
      // dispatch(loaderEndActionCreater());
    }
  };

  // console.log(activityLogs);

  const columnForMember = [
    // {
    //   title: "Description",
    //   field: "value",
    //   // render: (rowData) => {
    //   //   return rowData.value.slice(8);
    //   // },
    // },
    {
      title: "Date",
      field: "dateTime",
      render: (rowData) => {
        return (
          <span>
            {moment.utc(rowData?.createdAt).local().format("DD/MM/YYYY")}
          </span>
        );
      },
    },
    {
      title: "Time",
      field: "dateTime",
      render: (rowData) => {
        return (
          <span>
            {moment.utc(rowData?.createdAt).local().format("hh:mm a")}
          </span>
        );
      },
    },
    {
      title: "Action",
      field: "action",
    },
    {
      title: "Action Performed By",
      field: "performedByUser",
    },
    {
      title: "Action Performed On",
      field: "performedOnUser",
    },

    {
      title: "Value",
      field: "value",
    },
    {
      title: "Operation Status",
      field: "status",
    },
  ];

  const columns = [
    {
      title: "Date",
      field: "createdAt",
      render: (rowData) => {
        return <span>{moment(rowData.createdAt).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: "Time",
      field: "createdAt",
      render: (rowData) => {
        return <span>{moment(rowData.createdAt).format("hh:mm a")}</span>;
      },
    },
    {
      title: "Action",
      field: "action",
    },
    {
      title: "Action Performed By",
      field: "performedByUser",
    },
    {
      title: "Action Performed On",
      field: "performedOnUser",
    },
    {
      title: "Value",
      field: "value",
    },
    {
      title: "Operation Status",
      field: "status",
    },
  ];

  const options = {
    search: false,
    // selection: true,
    exportButton: {
      csv: true,
      pdf: false,
    },
    exportAllData: true,

    exportCsv: (columns, data) => {
      const columnTitles = columns.map((columnDef) => columnDef.title);

      const csvData = data.map((rowData) =>
        columns.map((columnDef) => rowData[columnDef.field])
      );

      const builder = new CsvBuilder(`data.csv`)
        .setColumns(columnTitles)
        .addRows(csvData)
        .exportFile();

      return builder;
    },

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      whiteSpace: "nowrap",
      padding: "8px 5px",
    },
    cellStyle: {
      padding: "10px",
      whiteSpace: "nowrap",
    },

    // paging: false,
    maxBodyHeight: "45vh",
  };

  return (
    <>
      <p>
        Activity Logs <i className="fa fa-caret-up" />
      </p>
      <DataTable
        title=""
        columns={usedFor === "member" ? columnForMember : columns}
        data={activityLogs}
        options={options}
      />
      {/* {props.noEmail ? null : (
        <p className="pt-4">
          Emails <i className="fa fa-caret-down" />
        </p>
      )} */}
    </>
  );
};

export default ActivityLogsTable;
