import actions from "./shareCapitalActions";
import axios from "axios";
import { useDispatch } from "react-redux";
const {
  addFormData,
  updateFormData,
  updateTableRowData,
  shareCapitalVisibiltyAction,
} = actions;

const shareCapitalAddForm = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(addFormData(formData));
    } catch (err) {
      console.log(err.response);
    }
  };
};

const shareCapitalUpdateForm = (formData) => {
  return async (dispatch) => {
    try {
      // console.log("1. shareCapitalUpdateFormData Action Creater",formData);
      dispatch(updateFormData(formData));
    } catch (err) {
      console.log(err.response);
    }
  };
};

const shareCapitalUpTableRowData = (rowData) => {
  return async (dispatch) => {
    try {
      // console.log("1. shareCapitalUpdateTableRowData Action Creater",formData);
      dispatch(updateTableRowData(rowData));
    } catch (err) {
      console.log(err.response);
    }
  };
};

const shareCapitalVisibiltyActionCreater = (data) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    try {
      dispatch(shareCapitalVisibiltyAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};
export {
  shareCapitalAddForm,
  shareCapitalUpdateForm,
  shareCapitalUpTableRowData,
  shareCapitalVisibiltyActionCreater,
};
