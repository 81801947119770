import React, { useState, useEffect } from "react";
import MenuDropdown from "../../Components/MenuItems/MenuDropdown";
import CsvDownload from "react-json-to-csv";
import DataAcqCard from "./DataAcqCard";
import DataAcquisitionTable from "./DataAcquisitionTable";
import DatePickers from "./DatePickers";
import { MultiSelectDropdown } from "../../Components/SelectDropdown/MultiSelectDropdown";
import { exportedData } from "./DataAcqUtils";
import ExportTable from "../../Components/Export/ExportTable";
import axios from "axios";
import { PieChartDataAcq } from "./PieChartDataAcq";
import moment from "moment";
import DataTable from "../../Components/Table";
import { BASE_URL } from "../../Components/URL/Url";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { Box, Typography } from "@mui/material";
import PieChartsData from "./pieChartTabs";
import exportFromJSON from "export-from-json";
const fileName = "download";
const exportType = exportFromJSON.types.csv;
const ApplicationInfo = (props) => {
  const {
    data,
    setData,
    dataAcqLength,
    columnsStatus,
    optionVal,
    selectedData,
    setSelectedData,
    id,
  } = props;
  const dispatch = useDispatch();

  const optionsForLaunch = ["Edit/View", "Cancel", "Close"];
  const optionsForDraft = ["Edit/View", "Launch"];
  const optionsForClosed = ["Edit/View"];
  const optionsForCancel = ["Edit/View", "Launch"];

  const [startDate, setStartDate] = useState("");
  const [isPoll, setIsPoll] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [allData, setAllData] = useState({});
  const [dataAquisition, setDataAquisition] = useState({});
  // console.log("columnsStatus",columnsStatus,id);
  const [userRole, serUserRole] = useState("");
  const tableRef = React.createRef();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  const getDataAcquisitionForExport = async (id) => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      // console.log("in getDataAcquisitionForExport!!", id);
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        BASE_URL + `/api/v1/dataAcquisitionResponse/data/${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log("before",response);

      response = {
        data: response.data.filter((d) =>
          d.type === null ? true : d.type === "submitted" && d.version === 2
        ),
      };

      // console.log("after",response);

      // console.log("response.data", response.data);

      let dummyData = response?.data?.map((ele) => {
        let que = ele?.response?.reduce((ans, e, i) => {
          if (e) {
            console.log("in reduse", typeof e.answer, e?.answer?.length);
            // i = i + 1 ;
            let res = {
              [`Question ${parseInt(i) + 1}`]: e?.question,
              [`Answer ${parseInt(i) + 1}`]:
                e?.options?.length <= 0 && !e?.answer?.option
                  ? e?.answer
                  : e?.questionType === "Multiple Choice" && e?.answer?.option
                  ? e?.answer?.option
                  : typeof e?.answer == "string"
                  ? e?.answer
                  : e?.answer
                      ?.map((ele) => {
                        return ele?.option;
                      })
                      ?.join(";"),
            };
            return { ...ans, ...res };
          } else return { ...ans };
        }, {});
        return {
          MemberId: ele?.memberId?.memberId,
          FirstName: ele?.memberId?.firstName,
          middleName: ele?.memberId?.middleName,
          lastName: ele?.memberId?.lastName,
          AddressLine1: ele?.memberId?.addressLine_1,
          AddressLine2: ele?.memberId?.addressLine_2,
          State: ele?.memberId?.state,
          District: ele.memberId.district,
          Taluka: ele.memberId.taluka,
          City: ele?.memberId?.city,
          Email: ele.memberId.email,
          Phone: ele.memberId.phone,
          memberType: ele.memberId.member,
          Organisation: ele?.memberId?.organisation_name,
          Date: moment(ele?.createdAt)?.format("DD/MM/yyyy"),
          ...que,
        };
      });

      // console.log("dummyData", dummyData);
      setExportData(dummyData);
      // dispatch(loaderEndActionCreater());
    } catch (err) {
      console.log(err);
      dispatch(loaderEndActionCreater());
      // dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const columns = [
    {
      title: "Membership Id",
      field: "memberId",
      customSort: (a, b) => {
        return (a.memberId || "").localeCompare(b.memberId || "");
      },
    },
    {
      title: "Member Name",
      field: "memberName",
      customSort: (a, b) => {
        return (a.memberName || "").localeCompare(b.memberName || "");
      },
    },
    {
      title: "Date & Time",
      field: "date",
      // type: "datetime",
      render: (rowData) => (
        <div
          style={{ borderRadius: "50%", width: "8rem" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="table_date"
            style={{
              // color: "#707675",
              color: "#000000",
              fontFamily: "Mulish",
              fontStyle: "normal",
              // fontWeight: "400",
              // lineHeight: "25px",
            }}
          >
            {moment(rowData.date).format("DD/MM/YYYY")}
          </div>
          <div
            className="table_time"
            style={{
              color: "#757575",
              fontFamily: "Mulish",
              fontStyle: "normal",
              fontWeight: "normal",
              // lineHeight: "18px",
            }}
          >
            {moment(rowData.date).format("HH:mm:ss")}
          </div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.date.slice(0, -1)).format("DD/MM/yyyy").indexOf(term) !=
        -1,
    },
    {
      title: "State",
      field: "memberState",
      customSort: (a, b) => {
        return (a.memberState || "").localeCompare(b.memberState || "");
      },
    },
    // { title: "District", field: "memberDistrict" },
    // { title: "Taluka", field: "memberTaluka" },
    {
      title: "City",
      field: "memberCity",
      customSort: (a, b) => {
        return (a.memberCity || "").localeCompare(b.memberCity || "");
      },
    },
    {
      title: "Organization",
      field: "memberOrg",
      customSort: (a, b) => {
        return (a.memberOrg || "").localeCompare(b.memberOrg || "");
      },
    },
    // { title: "Email", field: "memberEmail" },
    // { title: "Member Type", field: "memberType" },
  ];

  const tableOptions = {
    search: true,
    selection: false,
    filtering: true,
    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
    },
    maxBodyHeight: "55vh",
    pageSize: 10,
    searchFieldStyle: {
      marginRight: "6vw",
      width: "15vw",
    },
  };

  const getAcquisitionDetails = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/api/v1/dataAcquisition/${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(response.data);

      let data = response.data;

      let dummyData = {
        title: data.title,
        status: data.isActive,
        start: data.startDate,
        end: data.endDate,
        standing: data.standing,
        id: data.id,
      };

      // console.log("dummy data ",dummyData);

      setIsPoll(data?.type === "Poll");
      setDataAquisition(dummyData);
    } catch (err) {
      setDataAquisition({
        title: null,
        status: false,
        start: moment(),
        standing: null,
        end: moment(),
      });
      // console.log(err);
      if (err.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      dispatch(loaderEndActionCreater());
    }
  };

  const getAcquisitionData = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/dataAcquisitionResponse/data/${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log("###response", id, response);

      response = {
        data: response.data.filter((d) =>
          d.type === null ? true : d.type === "submitted" && d.version === 2
        ),
      };

      let totalRes = response.data.length;
      // `http://localhost:8080/api/v1/dataAcquisitionResponse/data/${id}`
      let dummyData = {};

      let ele = response.data;

      ele?.map((elem) => {
        elem.response?.map((e, i) => {
          // console.log("in Acquisition data!!", e);
          if (
            e?.options.length > 0 &&
            e?.questionType === "Multiple Choice" &&
            e.answer?.option !== null
          ) {
            dummyData = dummyData[`${e.question}`]
              ? { ...dummyData }
              : { ...dummyData, [`${e.question}`]: {} };

            // console.log("'dummyData !!", dummyData);

            let queOptions = { sayasQuestionNumber: i };
            e.options.map((el) => {
              queOptions = {
                ...queOptions,
                [`${el.option}`]: 0,
              };
            });
            dummyData = { ...dummyData, [`${e.question}`]: queOptions };
          }
        });
      });

      //pie cahrt data
      response.data?.map((ele, i) => {
        // console.log("after map !!", i, ele);

        //find option type que.
        // ele?.response?.map((e, i) => {
        //   if (
        //     e?.options.length > 0 &&
        //     e?.questionType === "Multiple Choice" &&
        //     e.answer?.option !== null
        //   ) {
        //     dummyData = dummyData[`${e.question}`]
        //       ? { ...dummyData }
        //       : { ...dummyData, [`${e.question}`]: {} };

        //     console.log("'dummyData !!", dummyData);

        //     let queOptions = { sayasQuestionNumber: i };
        //     e.options.map((el) => {
        //       queOptions = {
        //         ...queOptions,
        //         [`${el.option}`]: 0,
        //       };
        //     });
        //     dummyData = { ...dummyData, [`${e.question}`]: queOptions };
        //   }
        // });

        // multiple chose detail::
        ele?.response?.map((e) => {
          if (
            e?.options.length > 0 &&
            e?.questionType === "Multiple Choice" &&
            e.answer?.option !== null
          ) {
            // console.log("'In map !!", i, e);
            let que = e.question;
            let op = e.answer?.option;
            // console.log("'dummyData !!", dummyData);
            let newQue = {
              ...dummyData[`${que}`],
              [`${op}`]: parseInt(dummyData[`${que}`][`${op}`]) + 1,
            };

            // console.log("'Que !!", newQue);

            dummyData = {
              ...dummyData,
              [`${que}`]: newQue,
            };
          }
        });
      });

      let dataAcquisitionData = response.data[0]?.dataAcquisitionId;
      let tableData = response.data.map((ele) => {
        // console.log("MemberId::", ele.memberId);
        return {
          memberId: ele.memberId.memberId,
          memberName: "" + ele.memberId.firstName + " " + ele.memberId.lastName,
          date: ele.createdAt,
          memberCity: ele.memberId.city,
          memberState: ele.memberId.state,
          memberOrg: ele.memberId.organisation_name,
          // memberPhone: ele.memberId.phone,
          // memberDistrict: ele.memberId.district,
          // memberTaluka: ele.memberId.taluka,
          // memberEmail: ele.memberId.email,
          // memberType: ele.memberId.member,
        };
      });

      let dataAcquisitionInfo = {
        totalRes,
        dataAcquisitionData,
        tableData,
      };

      let newResponse = {
        quetionsStatus: dummyData,
        dataAcquisitionInfo,
      };

      // console.log("dummyData", newResponse);
      setAllData(newResponse);
      dispatch(loaderEndActionCreater());
    } catch (err) {
      // console.log(err);
      dispatch(loaderEndActionCreater());
      if (err.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    if (selectedData?.id && getAuthData)
      getDataAcquisitionForExport(selectedData?.id);
  }, [selectedData, getAuthData]);

  // console.log("info", selectedData, id);

  useEffect(() => {
    if (getAuthData && id) {
      getDataAcquisitionForExport(id);
      getAcquisitionDetails();
      getAcquisitionData();
    }
  }, [id, optionVal, selectedData, getAuthData]);

  // useEffect(() => {
  //   getDataAcquisitionForExport(id);
  // }, [allData, optionVal, selectedData]);

  const handleActivateStatus = async (status) => {};

  // console.log("selectedData-------", selectedData);
  useEffect(() => {
    // getDataAcquisitionForExport(selectedData?.id);
    if (selectedData == null) {
      setDataAquisition({
        title: null,
        status: false,
        start: moment(),
        standing: null,
        end: moment(),
      });
      setAllData({});
    }
  }, [selectedData]);

  // useEffect(() => {
  //   getDataAcquisitionForExport(id);
  //   // console.log("exportData", exportData, id);
  // }, [id, exportData]);
  // console.log("dataAquisition", allData, id);

  return (
    <div>
      <div
        align="right"
        style={{ padding: "45px 40px", borderBottom: "1px solid #CCCCCC" }}
      >
        <div
          className="d-flex justify-content-end align-items-center"
          style={{ width: "18%", gap: "20px" }}
        >
          {/* standing  */}
          <MenuDropdown
            dataAquisition={dataAquisition}
            options={
              userRole.toLowerCase() === "staff"
                ? ["Edit/View"]
                : dataAquisition?.standing === "save" ||
                  (new Date(dataAquisition.start) > new Date() &&
                    dataAquisition?.standing === "launch")
                ? optionsForDraft
                : new Date(dataAquisition.end) > new Date() &&
                  new Date(dataAquisition.start) < new Date() &&
                  dataAquisition?.standing === "launch"
                ? optionsForLaunch
                : (new Date(dataAquisition.end) < new Date() ||
                    new Date(dataAquisition.start) > new Date() ||
                    dataAquisition?.standing === "closed") &&
                  dataAquisition.standing !== "cancelled" &&
                  dataAquisition.standing !== "save" &&
                  new Date(dataAquisition.start) <= new Date()
                ? optionsForClosed
                : optionsForCancel
            }
            // setAllOptions={setAllOptions}
            handleActivateStatus={handleActivateStatus}
            setData={setData}
            data={data}
            dataLength={dataAcqLength}
            columnsStatus=""
            id={id}
          />

          {/* {console.log("selectedData", selectedData, allData)} */}

          {isPoll ? (
            ""
          ) : (
            <>
              {" "}
              {/* <ExportTable data={exportData} /> */}
              <div
                className="exportBtn"
                onClick={() => {
                  exportFromJSON({
                    // data: tableRef.current.state.data,
                    data: exportData,
                    fileName,
                    exportType,
                  });
                  // var encodedUri = encodeURI(exportData);
                  // var link = document.createElement("a");
                  // link.setAttribute("href","data:text/csv;charset=utf-8,%EF%BB%BF" +  encodedUri);
                  // link.setAttribute(
                  //   "download",
                  //   "upload_data" + new Date().getTime() + ".csv"
                  // );
                  // link.click();
                }}
              >
                <i
                  className="fa fa-download"
                  style={{ color: "#ffffff", fontSize: "22px" }}
                ></i>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <DataAcqCard
          selectedData={allData?.dataAcquisitionInfo}
          dataAquisition={dataAquisition}
        />
      </div>
      <div
        className="mt-3 mb-4 d-flex justify-content-start"
        style={{ gap: "30px", margin: "0 0 0 20px" }}
      >
        <div>
          <div align="left" style={{ marginBottom: "-20px" }}>
            <label>Start</label>
          </div>
          <DatePickers
            setStartDate={setStartDate}
            startDate={dataAquisition?.start}
            type="start"
            name="info"
          />
        </div>
        <div>
          <div align="left" style={{ marginBottom: "-20px" }}>
            <label>Close</label>
          </div>
          <DatePickers
            setEndDate={new Date()}
            endDate={dataAquisition?.end}
            type="close"
            name="info"
          />
        </div>
      </div>

      {/* ////////PieChart Space */}
      {/* pieChartsData = {allData?.quetionsStatus[`${ele}`] } */}

      <PieChartsData
        tableRef={tableRef}
        allData={allData}
        columns={columns}
        tableOptions={tableOptions}
      />

      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
          rowGap: "20px",
        }}
      >
        {allData?.quetionsStatus
          ? Object.keys(allData?.quetionsStatus).map((ele) => {
              return (
                <PieChartDataAcq
                  que={ele}
                  pieChartsData={allData?.quetionsStatus[`${ele}`]}
                />
              );
            })
          : ""}
      </div>

      <div>
        <DataTable
          title=""
          columns={columns}
          data={allData?.dataAcquisitionInfo?.tableData}
          options={tableOptions}
        />
      </div> */}
    </div>
  );
};

export default ApplicationInfo;
