import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataTable from "../../../Components/Table";
import { loaderEndActionCreater } from "../../../Redux/Loader/LoaderActionCreator";

const ShareApplicationMoneyTable = (props) => {
  const { finalData, tableRef,columns,columnsStatus } = props;


  const options = {
    search: false,
    // selection: true,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
      padding: "8px 5px",
      whiteSpace: "nowrap",
    },
    cellStyle: {
      padding: "10px",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
    maxBodyHeight: "58vh",
    pageSize: 10,
  };

  return (
    <>
      <DataTable
        tableRef={tableRef}
        title=""
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={finalData}
        options={options}
      />
    </>
  );
};

export default ShareApplicationMoneyTable;
