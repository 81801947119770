import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import axios from "axios";
import { FormControlLabel, Modal, Popover, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import Button from "@mui/material/Button";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";

import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0096FF",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

const style = {
  borderRadius: "10px",
  position: "absolute",
  top: "40%",
  right: "12%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const updateMessageTemplate = async (id, newData, dispatch, token) => {
  // console.log("newData", newData, id);
  if (window.confirm("Are you Sure to Update")) {
    dispatch(loaderStartActionCreater());
    try {
      // val.data.id = new Date().valueOf();
      // console.log(newData);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/configuration/emailTemplate/" +
          `${id}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log(newData);
      dispatch(getConfiguationActionCreater(token));
      dispatch(
        openSnackbar(
          "Update Notification Configuration Template Successfully",
          "success"
        )
      );
      dispatch(loaderEndActionCreater());
    } catch (e) {
      dispatch(openSnackbar("Update Configuration failed", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
    // code of update
  } else {
    dispatch(loaderEndActionCreater());
    // dispatch(openSnackbar("Update Configuration Canceled", "error"));
  }
};

const EmailTemplateInput = (props) => {
  const { item, dataType } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState({
    id: "ID001",
    dicription: "hi hello",
    title: "hi",
  });
  const [openModal, setOpenModal] = useState(false);
  // console.log(configType);
  // console.log(element);
  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  const dispatch = useDispatch();
  const feature = {
    fontSize: "30px",
    margin: "0 5px",
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div
      className="d-flex align-items-center"
      style={{
        width: "98%",
      }}
    >
      <GeneralInputField
        data={item}
        setData={setData}
        fullWidth
        // width={"70%"}
        dataKey={"title"}
        label={item?.name}
        // place={configType}
        value={item?.title}
        disabled
      />

      <div className="d-flex align-items-center " style={{ width: "30%" }}>
        <div style={{ width: "30%" }}>
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            <EditTwoToneIcon style={feature} />
          </IconButton>
        </div>
        {/* <div style={{ width: "30%" }} onClick={handleDelete}>
          {" "}
          <IconButton aria-label="delete" size="large">
            <DeleteTwoToneIcon style={feature} />
          </IconButton>
        </div> */}
        <div
          style={{ width: "30%" }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {" "}
          <IconButton aria-label="delete" size="large">
            <InfoTwoToneIcon style={{ fontSize: "30px" }} />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div
              style={{
                padding: ".5rem",
                fontSize: "1.1rem",
                maxWidth: "20rem",
                fontStyle: "none",
              }}
            >
              {item?.template
                ? dataType === "uploadEmailTemplate"
                  ? item?.emailTemplate
                  : dataType === "uploadMessageTemplate"
                  ? item?.template
                  : dataType === "uploadSmsTemplate"
                  ? item?.smsTemplate?.template
                  : "data not available"
                : "data not available"}
            </div>
          </Popover>
        </div>
      </div>

      {dataType === "uploadEmailTemplate" ? (
        <ThemeProvider theme={theme}>
          <FormControlLabel
            value="start"
            width="100%"
            control={
              <Switch
                color="primary"
                // sx={{
                //   color: "#0096FF",
                // }}
                checked={item.email}
                onChange={(ele) => {
                  // console.log("handleStatus");
                  // console.log("curr Status", check[`email`]);
                  const newDataForUpdate = {
                    ...item,
                    ["email"]: ele.target.checked,
                  };
                  // setCheck(newDataForUpdate);
                  updateMessageTemplate(
                    item?.id,
                    newDataForUpdate,
                    dispatch,
                    getAuthData
                  );
                }}
              />
            }
            label="Email"
            labelPlacement="start"
          />
        </ThemeProvider>
      ) : (
        ""
      )}
      {dataType === "uploadSmsTemplate" ? (
        <ThemeProvider theme={theme}>
          <FormControlLabel
            value="start"
            width="100%"
            control={
              <Switch
                // color="primary"
                sx={{
                  color: "#0096FF",
                }}
                checked={item.sms}
                onChange={(ele) => {
                  // console.log("handleStatus");
                  // console.log("curr Status", check[`sms`]);
                  const newDataForUpdate = {
                    ...item,
                    ["sms"]: ele.target.checked,
                  };
                  // setCheck(newDataForUpdate);
                  updateMessageTemplate(
                    item?.id,
                    newDataForUpdate,
                    dispatch,
                    getAuthData
                  );
                }}
              />
            }
            label="SMS"
            labelPlacement="start"
          />
        </ThemeProvider>
      ) : (
        ""
      )}
      {dataType === "uploadMessageTemplate" ? (
        <ThemeProvider theme={theme}>
          <FormControlLabel
            value="start"
            width="100%"
            control={
              <Switch
                // color="primary"
                sx={{
                  color: "#0096FF",
                }}
                fullWidth
                checked={item.mobileNotification}
                onChange={(ele) => {
                  // console.log("handleStatus");
                  // console.log("curr Status", check[`mobileNotification`]);
                  const newDataForUpdate = {
                    ...item,
                    ["mobileNotification"]: ele.target.checked,
                  };
                  // setCheck(newDataForUpdate);
                  updateMessageTemplate(
                    item?.id,
                    newDataForUpdate,
                    dispatch,
                    getAuthData
                  );
                }}
              />
            }
            label="Mobile Notification"
            labelPlacement="start"
          />
        </ThemeProvider>
      ) : (
        ""
      )}

      <AddConfigModal
        selectedData={item}
        openModal={openModal}
        setOpenModal={setOpenModal}
        dataType={dataType}
        title={item.title}
        // setOpenModal={setOpenModal}
      />
    </div>
  );
};

export const AddConfigModal = (props) => {
  const { setOpenModal, selectedData, openModal, dataType, title } = props;
  // console.log("addCofiguration",configType);
  const dispatch = useDispatch();
  const [data, setData] = useState({ type: "", description: "" });

  // const handleOpen = () => setOpen(true);
  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  const handleClose = () => {
    // setOpen(false);
    // setData({
    //   ...data,
    //   type: selectedData.title,
    //   description: selectedData.template,
    // });
    if (dataType === "uploadSmsTemplate") {
      setData(Object.create({ ...selectedData?.smsTemplate, title }));
    } else {
      setData(Object.create(selectedData));
    }
    // setData(Object.create(selectedData));
    setOpenModal(false);
  };

  const handleSubmit = () => {
    // data.id = 1234
    // console.log(data.type, data.description);
    // console.log("mylog", data, selectedData);
    if (dataType === "uploadMessageTemplate") {
      if (
        data.title === selectedData.title &&
        data.template === selectedData.template
      ) {
        dispatch(openSnackbar("Data already up to date", "error"));
        setOpenModal(false);
        return;
      } else {
        setOpenModal(false);
        // console.log("uploadMessageTemplate", dataType, data);
        const newDataForUpdate = {
          ...selectedData,
          ["title"]: data.title,
          ["template"]: data.template,
        };

        updateMessageTemplate(
          selectedData?.id,
          newDataForUpdate,
          dispatch,
          getAuthData
        );
        // dispatch(openSnackbar("Success", "success"));
        // dispatch(loaderStartActionCreater());
      }
    }
    if (dataType === "uploadEmailTemplate") {
      if (
        data.title === selectedData.title &&
        data.emailTemplate === selectedData.emailTemplate
      ) {
        dispatch(openSnackbar("Data already up to date", "error"));
        setOpenModal(false);
        return;
      } else {
        setOpenModal(false);
        const newDataForUpdate = {
          ...selectedData,
          ["title"]: data.title,
          ["emailTemplate"]: data.emailTemplate,
        };
        updateMessageTemplate(
          selectedData?.id,
          newDataForUpdate,
          dispatch,
          getAuthData
        );
        // dispatch(openSnackbar("Success", "success"));
        // dispatch(loaderStartActionCreater());
      }
    }
    if (dataType === "uploadSmsTemplate") {
      // if (
      //   data.title === selectedData.title &&
      //   data.emailTemplate === selectedData.emailTemplate
      // ) {
      //   dispatch(openSnackbar("Data already up to date", "error"));
      //   setOpenModal(false);
      //   return;
      // } else {
      setOpenModal(false);
      console.log("uploadSmsTemplate", dataType, data);
      const newDataForUpdate = {
        ...selectedData,
        ["title"]: data.title,
        ["smsTemplate"]: data,
      };
      // console.log("newDataForUpdate::", newDataForUpdate);
      updateMessageTemplate(
        selectedData?.id,
        newDataForUpdate,
        dispatch,
        getAuthData
      );
      // dispatch(openSnackbar("Success", "success"));
      // dispatch(loaderStartActionCreater());
      // }
    }
  };

  useEffect(() => {
    // setData({
    //   ...data,
    //   type: selectedData.title,
    //   description: selectedData.template,
    // });
    if (dataType === "uploadSmsTemplate") {
      setData(Object.create({ ...selectedData.smsTemplate, title }));
    } else {
      setData(Object.create(selectedData));
    }
  }, [selectedData, dataType]);
  console.log("selectedData", data, selectedData);

  return (
    <div>
      <Modal
        open={openModal}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "40px 30px" }}>
          <div>
            <GeneralInputField
              data={data}
              setData={setData}
              place={"Title"}
              dataKey={"title"}
              // value = {configType}
              disabled
            />

            {dataType === "uploadSmsTemplate" ? (
              <>
                {" "}
                <GeneralInputField
                  data={data}
                  setData={setData}
                  place={"DltTemplateId"}
                  dataKey={"DltTemplateId"}
                  // value = {configType}
                  // disabled
                />
                <GeneralInputField
                  data={data}
                  setData={setData}
                  place={"DltEntityId"}
                  dataKey={"DltEntityId"}
                  // value = {configType}
                  // disabled
                />
              </>
            ) : (
              ""
            )}

            <GeneralInputField
              data={data}
              setData={setData}
              place={"Template"}
              dataKey={
                dataType === "uploadMessageTemplate"
                  ? "template"
                  : dataType === "uploadEmailTemplate"
                  ? "emailTemplate"
                  : "template"
              }
              multiline
            />
          </div>
          <div
            align="right"
            className="pt-4"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignContent: "center",
            }}
            // sx={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              className="addBtn"
              variant="contained"
              sx={{
                // mr: 2,
                height: "25px",
                display: "flex",
                padding: 2,
                borderRadius: "5px",
                justifyContent: "center",
                bgcolor: "#0096FF!important",
                color: "#FFFFFF",
                fontFamily: "Mulish",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
              }}
              onClick={handleSubmit}
            >
              {selectedData.id ? "Update" : "Add Field"}
            </Button>
            <Button
              className="addBtn"
              varient="text"
              // className="p-4"
              style={{
                background: "none",
                color: "#828282",
                fontFamily: "Mulish",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
                lineHeight: "25px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModal(false);
                handleClose();
                // dispatch(
                //   openSnackbar(
                //     `${
                //       selectedData.id ? "update" : "Add New"
                //     }  Configuration Cancelled`,
                //     "error"
                //   )
                // );
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EmailTemplateInput;
