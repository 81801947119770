import * as React from "react";
import { ListItemButton } from "@mui/material";
import Button from "@restart/ui/esm/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import "./MultiSelectDropdown.css";
export const MultiSelectDropdown = (props) => {
  const { columns, columnsStatus, setColumnsStatus } = props;
  const [isChecked, setIsChecked] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ background: "#0096FF", borderRadius: "5px" }}>
      <Button
        aria-describedby={id}
        className="dropDownBtn"
        onClick={handlePopOver}
        sx={{
          // mr: 6,
          bgcolor: "#0096FF!important",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
        style={{
          // background: "#0096FF!important",
          color: "#FFFFFF",
          width: 38,
          height: 38,
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "20px",
          lineHeight: "25px",
          textTransform: "uppercase",
          // color: "#FFFFFF",
          borderRadius: "5px",
        }}
      >
        {open ? (
          <i className="fa fa-caret-up" />
        ) : (
          <i className="fa fa-caret-down" />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography
          sx={{
            maxHeight: "15rem",
          }}
        >
          <List dense={false}>
            {columns.map((item) => {
              return (
                <ListItem
                  onClick={(e) => {
                    // console.log(item.field);
                    setIsChecked(!isChecked);
                    // columnsStatus[item.field] = !columnsStatus.item;
                    // setColumnsStatus({
                    //   ...columnsStatus,
                    //   [item.field]: columnsStatus[item.field] ? false : true,
                    // });
                    setColumnsStatus(item.field);
                  }}
                  style={{ overflow: "hidden", padding: 0, margin: 0 }}
                >
                  <ListItemButton style={{ borderBottom: "1px solid #acacac" }}>
                    <Checkbox
                      checked={columnsStatus[item.field]}
                      style={{
                        color: "#0096FF",
                      }}
                    />
                    <ListItemText
                      primary={item.title}
                      style={{ textTransform: "capitalize" }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Typography>
      </Popover>
    </div>
  );
};
