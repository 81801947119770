import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { MenuItem, OutlinedInput, Select, Switch } from "@mui/material";
import UserInput from "./UserInput";
import UserSelectField from "./UserSelectField";
// import DatePickers from "./DatePickers";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { selectedUserRows } from "../../../Redux/Users/actionCreator";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import UUID from "uuid-int";
import styles from "../UserInput.module.css";
// import UsersCityField from "./UsersCityField";
// import UserStateField from "./UserStateField";
import { BASE_URL } from "./../../../Components/URL/Url";
// import pincodeDirectory from "india-pincode-lookup";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../../Redux/Configuration/configurationActionCreator";
import { Typography } from "@mui/material";
import moment from "moment";
import memberStyles from "../../Members/member.module.css";
// import userStyles from "../users.module.css";
import { makeStyles } from "@mui/styles";

// const id = 0;
const generator = UUID(0);
const uuid = generator.uuid();
// const accessList = ["Admin", "Staff"];

const initialData = {
  userId: "",
  // user: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  role: "",
  team: [],
  // addressLine1: "",
  // addressLine2: "",
  // organisationName: "",
  // nomineeName: "",
  // state: "Maharashtra",
  // city: "Thane",
  // pincode: "",
  // access: accessList[0],
  date: Date.now(),
};

const UpdateUserForm = (props) => {
  const { id, setId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [date, setDate] = useState();
  const { formData } = useSelector((state) => state.users);
  const [data, setData] = useState({});
  const [cityName, setCityName] = useState(null);

  // console.log("update user", id, formData);
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  // console.log(data);
  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  let errors = {};
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log("Values", { ...values, date: date });
      const userData = { ...values, date: date };
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      try {
        // console.log("User Data", userData);
        // console.log(formData, formData.id, userData);
        await axios.put(BASE_URL + `/api/v1/users/${userData.id}`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        history.push("/admin/users");
        dispatch(openSnackbar("User Updated Successfully!", "success"));
      } catch (err) {
        // console.log("Error", err.response.data.error);
        let errors = err?.response?.data?.error;
        let error =
          "Duplicate Field : " +
          err?.response?.data.split(": {")[1].slice(0, -1);
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
        if (error) dispatch(openSnackbar(error, "error"));
      }
    },
    validate: (values) => {
      // if (!values.user) {
      //   errors.user = "Required";
      // }

      // if (!values.organisationName) {
      //   errors.organisationName = "Required";
      // }

      // if (!values.nomineeName) {
      //   errors.nomineeName = "Required";
      // }

      // if (!values.state) {
      //   errors.state = "Required";
      // }

      // if (!values.city) {
      //   errors.city = "Required";
      // }

      if (!values.role) {
        errors.role = "Required";
      }
      if (!values.team) {
        errors.team = "Required";
      }

      // if (!values.access) {
      //   errors.access = "Required";
      // }

      if (!values.firstName) {
        errors.firstName = "Required";
      }

      if (!values.lastName) {
        errors.lastName = "Required";
      }

      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      ) {
        errors.email = "Invalid email";
      }

      if (!values.phone) {
        errors.phone = "Required";
      } else if (
        !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone)
      ) {
        errors.phone = "Invalid Phone";
      }

      // let checkPin = pincodeDirectory.lookup(values.pincode);

      // if (!values.pincode) {
      //   errors.pincode = "Required";
      // } else if (!/^[1-9][0-9]{5}$/.test(values.pincode)) {
      //   errors.pincode = "Invalid Pin Code";
      // } else if (checkPin[0]?.stateName !== values.state.toUpperCase()) {
      //   errors.pincode = "Invalid Pin Code";
      // }

      return errors;
    },
  });

  // console.log("In user::", data.role);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  useEffect(() => {
    formik.setValues(data);
    formik.setFieldValue(
      "userId",
      data.userId ? data.userId : uuid.toString().slice(9, 16)
    );
  }, [data]);

  ///fetch config data
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  useEffect(() => {
    setRoles(
      getConfigData?.roles?.map((e) => {
        return e.type;
      })
    );
    setTeams(
      getConfigData?.teams?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  useEffect(async () => {
    const url = window.location.href.split("/");
    // const id = url[url.length - 1];
    ////checktoken
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (getAuthData && id) {
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      try {
        const response = await axios.get(BASE_URL + `/api/v1/users/${id}`, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        // console.log(response.data);
        setData(response.data);
        setDate(response.data.date);
        setId(response.data.id);
      } catch (err) {
        // console.log(err);
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    }
  }, [id, getAuthData]);

  useEffect(() => {
    if (getAuthData) dispatch(getConfiguationActionCreater(getAuthData));
  }, [getAuthData]);

  const getUserDetails = async (id) => {
    // let token = window.localStorage.getItem("sayasLoginToken");
    let token = getAuthData;
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    try {
      const response = await axios.get(BASE_URL + `/api/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      });
      // console.log(response.data);
      setData(response.data);
      setDate(response.data.date);
      setId(response.data.id);
    } catch (err) {
      console.log(err);
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const updateActivityRole = async (e) => {
    // console.log("status");

    let userArray = [data._id];
    // console.log(userArray);
    if (e.target.checked) {
      const userData = { userIds: userArray, activityStatus: true };
      // console.log(userData);
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/users/updateStatus`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getUserDetails(data._id);
        dispatch(openSnackbar("User Activated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (!e.target.checked) {
      const userData = { userIds: userArray, activityStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/users/updateStatus`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getUserDetails(data._id);
        dispatch(openSnackbar("User Deactivated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    }
    // setOptions(option_action_1);
    // setOptionVal("All Users");
    // setSelectedData({});
  };

  useEffect(() => {
    if (getAuthData && id) getUserDetails(id);
  }, [id, getAuthData]);

  useEffect(() => {
    // console.log(teams);
  }, [teams]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(
      "team",
      typeof value === "string" ? value.split(",") : value,
      false
    );
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiSelect-select:hover": { backgroundColor: "none" },
    },
  });

  const classes = useStyles();

  return (
    <div
      className="m-4"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          // alert("this.state.message");
          // formik.handleSubmit();
        }
        return;
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "25px 30px",
            width: "100%",
          }}
        >
          <UserSelectField
            label="Role *"
            name="role"
            formik={formik}
            items={roles?.length > 0 ? roles : []}
            disabled={userRole?.toLowerCase() === "admin" ? false : true}
          />
          {/* <UserSelectField
            label="Team"
            name="team"
            formik={formik}
            items={teams?.length > 0 ? teams : []}
          /> */}

          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="mb-1" id={styles.userLabel}>
              Teams
            </label>

            {/* {console.log(formik.values.team, teams)} */}

            <Select
              className={classes.root}
              // labelId="demo-multiple-name-label"
              // id="demo-multiple-name"
              multiple
              disabled={
                userRole?.toLowerCase() === "admin" ||
                (userRole?.toLowerCase() === "manager" &&
                  data?.role?.toLowerCase() === "staff")
                  ? false
                  : true
              }
              value={
                formik.values.team?.filter((ele) => {
                  return teams?.includes(ele);
                }) || []
              }
              // value = {["AAA"]}
              label={null}
              onChange={handleChange}
              input={<OutlinedInput label=" " />}
              sx={{
                width: "90%",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Mulish",
                // fontSize: "18px",
                height: "2.5rem",
                padding: "2px 0px !important",
                // border: "2px solid red",
              }}
              // MenuProps={MenuProps}
            >
              {teams?.length > 0
                ? teams?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))
                : []}
            </Select>
          </div>

          <div className="d-flex align-items-center">
            <label className={memberStyles.switchLabel}>Activity Status</label>
            <Switch
              name="activityStatus"
              checked={formik.values.activityStatus}
              onChange={formik.handleChange}
              disabled={
                userRole?.toLowerCase() === "admin" ||
                (userRole?.toLowerCase() === "manager" &&
                  data?.role?.toLowerCase() === "staff")
                  ? false
                  : true
              }
              // disabled={true}
            />
          </div>
          <UserInput label="User ID" name="userId" formik={formik} />
          {/* <UserInput label="User" name="user" formik={formik} /> */}
          {/* <DatePickers
            disable={true}
            label="Date"
            name="date"
            formik={formik}
            setDate={setDate}
            data={data}
            customStyles={{ height: "38px" }}
            classProp={userStyles.userSelectField}
          /> */}
          {/* <UserSelectField
            label="Access"
            name="access"
            formik={formik}
            items={accessList?.length > 0 ? accessList : []}
          /> */}
          <UserInput
            label="First Name"
            name="firstName"
            formik={formik}
            disabled={
              userRole?.toLowerCase() === "admin" ||
              (userRole?.toLowerCase() === "manager" &&
                data?.role?.toLowerCase() === "staff")
                ? false
                : true
            }
          />
          <UserInput
            label="Middle Name"
            name="middleName"
            formik={formik}
            disabled={
              userRole?.toLowerCase() === "admin" ||
              (userRole?.toLowerCase() === "manager" &&
                data?.role?.toLowerCase() === "staff")
                ? false
                : true
            }
          />
          <UserInput
            label="Last Name"
            name="lastName"
            formik={formik}
            disabled={
              userRole?.toLowerCase() === "admin" ||
              (userRole?.toLowerCase() === "manager" &&
                data?.role?.toLowerCase() === "staff")
                ? false
                : true
            }
          />
          <UserInput
            label="Email"
            name="email"
            formik={formik}
            disabled={
              userRole?.toLowerCase() === "admin" ||
              (userRole?.toLowerCase() === "manager" &&
                data?.role?.toLowerCase() === "staff")
                ? false
                : true
            }
          />
          <UserInput
            label="Phone"
            name="phone"
            formik={formik}
            disabled={
              userRole?.toLowerCase() === "admin" ||
              (userRole?.toLowerCase() === "manager" &&
                data?.role?.toLowerCase() === "staff")
                ? false
                : true
            }
          />
          {/* <UserInput label="Nominee Name" name="nomineeName" formik={formik} />
          <UserInput
            label="Organisation Name"
            name="organisationName"
            formik={formik}
          />
          <UsersCityField
            label="State"
            name="state"
            formik={formik}
            setCityName={setCityName}
          />
          <UserStateField
            label="City"
            name="city"
            formik={formik}
            cityName={cityName}
          />

          <UserInput label="Pin Code" name="pincode" formik={formik} />
          <UserInput
            label="Address Line 1"
            name="addressLine1"
            formik={formik}
          />
          <UserInput
            label="Address Line 2"
            name="addressLine2"
            formik={formik}
          /> */}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography>
            <b>Date Created:</b>{" "}
            {moment(formik.values.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </div>
        <div align="right" style={{ margin: "40px 30px 0 0" }}>
          <button
            id={styles.cancelBtn}
            onClick={() => {
              // dispatch(selectedUserRows([]));
              history.push("/admin/users");
            }}
          >
            Cancel
          </button>
          {userRole?.toLowerCase() === "admin" ||
          (userRole?.toLowerCase() === "manager" &&
            data?.role?.toLowerCase() === "staff") ? (
            <Button id={styles.submitBtn} type="submit">
              Update
            </Button>
          ) : (
            ""
          )}
        </div>
      </form>
    </div>
  );
};

export default UpdateUserForm;
