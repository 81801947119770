import actions from "./reportActions";

const {
  SHARE_ISSUANCE,
  SHARE_TRANSFER,
  SHARE_APPLICATION_MONEY,
  SHARE_HOLING_TRANSACTION,
  SHARE_HOLDING_FILTERS,
} = actions;

const initState = {
  shareIssuance: {
    createdAt: true,
    toMembershipId: true,
    toMembershipName: true,
    noOfShares: true,
    typeOfShares: true,
    amount: true,
    validShareCertificationNo: true,
    invalidShareCertificationNo: true,
  },
  shareTransfer: {
    createdAt: true,
    toMembershipId: true,
    toMembershipName: true,
    fromMembershipId: true,
    fromMembershipName: true,
    noOfShares: true,
    typeOfShares: true,
    amount: true,
    shareNos:true,
    validShareCertificationNo: true,
    invalidShareCertificationNo: true,
  },
  shareApplicationMoney: {
    toMembershipId: true,
    id: true,
    toMembershipName: true,
    balance: true,
    amount: true,
    date: true,
    particulars: true,
  },
  shareHoldingTransaction: {
    createdAt: true,
    memberId: true,
    memberName: true,
    city: true,
    district: true,
    taluka: true,
    state: true,
    pincode: true,
    organisation_name: true,
    totalShare: true,
    shareCapital: true,
    shareApplicationMoney: true,
    avgShares: true,
    totalShare: true,
  },
  shareHoldingFilters: {
    name: true,
    totalShares: true,
    shareCapital: true,
    shareApplicationMoney: true,
    totalMember: true,
    avgShares: true,
    regularMembers: true,
    nominalMembers: true,
  },
};

const reportReducers = (state = initState, action) => {
  const { type, data, err } = action;
  // console.log("3", data);
  switch (type) {
    case SHARE_ISSUANCE:
      return {
        ...state,
        shareIssuance: {
          ...state.shareIssuance,
          [`${data}`]: state.shareIssuance[`${data}`]
            ? state.shareIssuance[`${data}`]
              ? false
              : true
            : true,
        },
      };
    case SHARE_TRANSFER:
      return {
        ...state,
        shareTransfer: {
          ...state.shareTransfer,
          [`${data}`]: state.shareTransfer[`${data}`]
            ? state.shareTransfer[`${data}`]
              ? false
              : true
            : true,
        },
      };
    case SHARE_APPLICATION_MONEY:
      return {
        ...state,
        shareApplicationMoney: {
          ...state.shareApplicationMoney,
          [`${data}`]: state.shareApplicationMoney[`${data}`]
            ? state.shareApplicationMoney[`${data}`]
              ? false
              : true
            : true,
        },
      };
    case SHARE_HOLING_TRANSACTION:
      return {
        ...state,
        shareHoldingTransaction: {
          ...state.shareHoldingTransaction,
          [`${data}`]: state.shareHoldingTransaction[`${data}`]
            ? state.shareHoldingTransaction[`${data}`]
              ? false
              : true
            : true,
        },
      };
    case SHARE_HOLDING_FILTERS:
      return {
        ...state,
        shareHoldingFilters: {
          ...state.shareHoldingFilters,
          [`${data}`]: state.shareHoldingFilters[`${data}`]
            ? state.shareHoldingFilters[`${data}`]
              ? false
              : true
            : true,
        },
      };
    default:
      return state;
  }
};
export default reportReducers;
