import actions from "./actions";

const { REGISTER_BEGIN, REGISTER_SUCCESS, LOGIN_BEGIN, LOGIN_SUCCESS } =
  actions;

const initState = {
  user: null,
  loading: false,
  error: null,
  formData: {},
  selectedRow: {},
  columnsStatus: {
    communicationId: true,
    dateAndTime: true,
    title: true,
    communicationType: true,
    type: true,
    visibleTo: true,
    description: false,
    attachmentUrl: false,
  },
};

const CommunicationReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
    case REGISTER_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case "COLUMNSTATUS":
      return {
        ...state,
        columnsStatus: {
          ...state.columnsStatus,
          [`${data}`]: state.columnsStatus[`${data}`]
            ? state.columnsStatus[`${data}`]
              ? false
              : true
            : true,
        },
      };
    default:
      return state;
  }
};
export default CommunicationReducer;
