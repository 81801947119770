import actions from "./DataAcqAction";
const { createDataAcqAction, selectedDataAcqAction,DAQVisibiltyAction } = actions;

const dataAcqActionCreator = (formData) => {
  // console.log("1", formData);
  return async (dispatch) => {
    try {
      dispatch(createDataAcqAction(formData));
    } catch (err) {
      console.log(err.response);
    }
  };
};

const selectedDataAcqRows = (selectedData) => {
  // console.log("1", selectedData);
  return async (dispatch) => {
    try {
      dispatch(selectedDataAcqAction(selectedData));
    } catch (err) {
      console.log(err);
    }
  };
};

const DAQVisibiltyActionCreater = (data) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    try {
      dispatch(DAQVisibiltyAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};


export { dataAcqActionCreator, selectedDataAcqRows,DAQVisibiltyActionCreater };
