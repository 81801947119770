import React, { useEffect, useReducer, useState } from "react";
import { DateRangeInput } from "@datepicker-react/styled";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import moment from "moment";
const initialState = {
  startDate: "",
  endDate: "",
  focusedInput: null,
};

export const GeneralDateRangePickerForReport = (props) => {
  const { setData, handleCloseD, setFilterDate, filterDate } = props;
  const [date, setDate] = useState(initialState);

  // useEffect(() => {
  //   if (date.startDate !== "" && date.endDate !== "") {
  //     props.setData({
  //       startDate: moment(date.startDate).format("YYYY MM DD"),
  //       endDate: moment(date.endDate).format("YYYY MM DD"),
  //     });
  //     props.handleCloseD();
  //   }
  // }, [date]);
  // console.log(date);

  const handleSubmit = (e) => {
    if (date.startDate !== "" && date.endDate !== "") {
      setData({
        startDate: moment(date.startDate).format("YYYY MM DD"),
        endDate: moment(date.endDate).format("YYYY MM DD"),
      });
      setFilterDate(date);
      handleCloseD();
    }
  };

  useEffect(() => {
    if (date.startDate === "" && date.endDate === "") {
      if (filterDate.startDate && filterDate.endDate) {
        setDate(filterDate);
      }
    }
  }, [filterDate]);

  return (
    <div className="d-flex align-items-center justify-content-center my-4 mr-2">
      <Form
        // onSubmit={handleSubmit}
        className="d-flex align-items-center  gap-4"
      >
        <div className="gap-2 align-items-center p-2">
          {/* <div>
          <label htmlFor="startDate">Start Date</label>
        </div> */}
          {/* <Input
          id="startDate"
          name="start-date"
          value={startDate}
          onChange={({ target }) => {
            if (
              moment(target.value).format("YYYY-MM-DD") >
              moment(Date.now()).format("YYYY-MM-DD")
            )
              setStartError(true);
            else setStartError(false);
            // console.log(target.value);
            setStartDate(target.value);
          }}
          type="date"
          error={startError}
          
        /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={date.startDate}
              maxDate={new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setDate({ ...date, startDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <div className="gap-2 align-items-center p-2">
          {/* <div>
          <label htmlFor="endDate">End Date</label>
        </div> */}
          {/* <Input
          id="endDate"
          name="end-date"
          value={endDate}
          onChange={({ target }) => {
            if (
              moment(target.value).format("YYYY-MM-DD") >
              moment(Date.now()).format("YYYY-MM-DD")
            )
              setEndError(true);
            else setEndError(false);
            // console.log(target.value);
            setEndDate(target.value);
          }}
          error={endError}
          type="date"
        /> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="End Date"
              value={date.endDate}
              minDate={date.startDate}
              maxDate={new Date()}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setDate({ ...date, endDate: newValue });
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
        <Button
          variant="contained"
          size="small"
          type="submit"
          sx={{
            bgcolor: "#0096FF",
          }}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          Set date
        </Button>
      </Form>
    </div>
  );
};
