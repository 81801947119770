import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
// import ConfigList from "./ConfigList";
// import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import ConfigInput from "./ConfigInput";
// import { Button } from "@mui/material";
import ConfigLeft from "./ConfigLeft";
import ConfigRight from "./ConfigRight";
import ConfigurationTable from "./ConfigurationTable";

const Configuration = () => {
  const configData = [
    { label: "Member Type", dataKey: "memberType" },
    { label: "Shareholder Types", dataKey: "shareholderType" },
    { label: "Share Types", dataKey: "shareType" },
    { label: "Teams", dataKey: "teams" },
    { label: "Roles", dataKey: "roles" },
    // { label: "Support Request Type", dataKey: "supportRequestTypes" },
    // {
    //   label: "Support Request Status Type",
    //   dataKey: "supportRequestStatusTypes",
    // },
    { label: "Upload Template", dataKey: "uploadTemplate" },
    { label: "Upload Notification Template", dataKey: "uploadMessageTemplate" },
    { label: "Upload Email Template", dataKey: "uploadEmailTemplate" },
    { label: "Upload SMS Template", dataKey: "uploadSmsTemplate" },
  ];

  const dispatch = useDispatch();
  const [configType, setConfigType] = useState( { label: "Member Type", dataKey: "memberType" });
  
  //token auth
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);
  return (
    <div>
      <div className="row m-0" style = {{height : "90vh"}}>
        <ConfigLeft configData={configData} setConfigType={setConfigType} />
        <ConfigRight configType={configType} />
      </div>
    </div>
  );
};

export default Configuration;
