var initState = {};

export const sayasAccountReducer = (state = initState, action) => {
  const { type, data, id } = action;

  // console.log("state", state);

  switch (type) {
    case "getSayasAcc":
      // console.log("addSayasAccountReducer", data);
      return data;

    default:
      return state;
  }
};
