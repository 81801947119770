import React, { useState, useEffect } from "react";
import DataAcquisitionTable from "./DataAcquisitionTable";
import styles from "./DataAcq.module.css";
import DataAccquisitionHeader from "./DataAccquisitionHeader";
// import DataAccquisitionFormHeader from "./DataAccquisitionFormHeader";
import { MultiSelectDropdown } from "./../../Components/SelectDropdown/MultiSelectDropdown";
import { BASE_URL } from "./../../Components/URL/Url";
import axios from "axios";
import {
  loaderStartActionCreater,
  loaderEndActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { memberDataActionCreater } from "../../Redux/Members/actionCreator";
import moment from "moment";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { DAQVisibiltyActionCreater } from "../../Redux/DataAcquisition/actionCreator";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const DataAcquisition = (props) => {
  const { dataAcqLength, setDataAcqLength, setId, id } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [finalData, setFinallData] = useState(null);
  const [AllData, setAllData] = useState([]);
  const tableRef = React.createRef();

  const option_users = [
    "All",
    "Application",
    "Survey",
    "Poll",
    "Open",
    "Closed",
    "Cancelled",
    "Draft",
  ];

  const [options, setOptions] = useState(["Launch", "Cancel", "Close"]);

  const [optionVal, setOptionVal] = useState("All");
  const [conflict, setConflict] = useState(false);
  const selectedUsers = useSelector(
    (state) => state.dataAcquisition.selectedRow
  );

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  //add permission
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  useEffect(() => {
    if (userRole !== "") {
      if (userRole.toLowerCase() === "staff") {
        setOptions([]);
      }
    }
  }, [userRole]);

  // useEffect(() => {
  //   console.log("all Rows", selectedUsers);
  // }, [selectedUsers]);

  const filterUsers = async (name) => {
    setOptionVal(name);
    // dispatch(loaderEndActionCreater());
    // dispatch(loaderStartActionCreater());
    // console.log(name);
    if (name == "All") {
      setData(AllData);
    } else if (name == "Open" || name == "Closed") {
      const filteredData = AllData.filter((item) => {
        // console.log(item.isActive);
        return name === "Open"
          ? new Date(item.endDate) > new Date() &&
              new Date(item.startDate) < new Date() &&
              item?.standing === "launch"
          : (new Date(item.endDate) < new Date() ||
              new Date(item.startDate) > new Date() ||
              item?.standing === "closed") &&
              item.standing !== "cancelled" &&
              item.standing !== "save" &&
              new Date(item.startDate) <= new Date();
      });
      setData(filteredData);
    } else if (name == "Cancelled") {
      const filteredData = AllData.filter((item) => {
        // console.log(item.isActive);
        return item.standing === "cancelled";
      });
      setData(filteredData);
    } else if (name == "Draft") {
      const filteredData = AllData.filter((item) => {
        // console.log(item.isActive);
        return (
          item.standing === "save" ||
          (new Date(item.startDate) > new Date() && item.standing === "launch")
        );
      });
      setData(filteredData);
    } else {
      const filteredData = AllData.filter((item) => item.type === name);
      setData(filteredData);
    }
    dispatch(loaderEndActionCreater());
  };

  const initialColumnStatus = {
    dataAcquisitionId: true,
    type: true,
    startDate: true,
    endDate: true,
    responces: true,
    status: true,
    title: true,
  };

  useEffect(() => {
    // console.log(data.map((ele) => ele.standing));

    setFinallData(
      data?.map((ele) => {
        // console.log("In fetch", ele);
        return {
          ...ele,
          responces:
            ele?.members?.length === 0
              ? "0"
              : ele?.members.filter((ele) => {
                  return !ele.memberId || ele?.isSubmit === "submitted";
                })?.length,
          status:
            ele.standing === "save" ||
            (new Date(ele.startDate) > new Date() &&
              ele.standing !== "closed" &&
              ele.standing !== "cancelled")
              ? "Draft"
              : ele.standing === "cancelled"
              ? "Cancelled"
              : new Date(ele.endDate) > new Date() &&
                new Date(ele.startDate) < new Date() &&
                ele?.standing === "launch"
              ? "Open"
              : "Closed",
        };
      })
    );
  }, [data]);

  const columns = [
    { title: "ID", field: "dataAcquisitionId" },
    {
      title: "Title",
      field: "title",
      customSort: (a, b) => {
        return (a.title || "").localeCompare(b.title || "");
      },
    },
    {
      title: "Type",
      field: "type",
      customSort: (a, b) => {
        return (a.type || "").localeCompare(b.type || "");
      },
    },
    // {
    //   title: "Open",
    //   field: "startDate",
    //   type: "date",
    //   dateSetting: {
    //     format: "dd/MM/yyyy",
    //   },
    //   render: (rowData) => (
    //     <div
    //       sx={{ display: "table-cell" }}
    //       style={{
    //         maxWidth: "10rem",
    //         wordBreak: "break-word",
    //         whiteSpace: "nowrap",
    //         whiteSpace: "nowrap",
    //         lineBreak: "auto",
    //         overflowX: "hidden",
    //         textOverflow: "ellipsis",
    //       }}
    //     >
    //       {moment(rowData.startDate.slice(0, -1)).format("DD/MM/yyyy")}
    //     </div>
    //   ),
    // },
    {
      title: "Open",
      field: "startDate",
      // type: "datetime",
      render: (rowData) => (
        <div
          style={{ borderRadius: "50%", width: "8rem" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="table_date"
            style={{
              // color: "#707675",
              color: "#000000",
              fontFamily: "Mulish",
              fontStyle: "normal",
              // fontWeight: "400",
              // lineHeight: "25px",
            }}
          >
            {moment(rowData.startDate).format("DD/MM/YYYY")}
          </div>
          <div
            className="table_time"
            style={{
              color: "#757575",
              fontFamily: "Mulish",
              fontStyle: "normal",
              fontWeight: "normal",
              // lineHeight: "18px",
            }}
          >
            {moment(rowData.startDate).format("HH:mm:ss")}
          </div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.startDate?.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) != -1,
    },
    {
      title: "Close",
      field: "endDate",
      // type: "date",
      dateSetting: {
        format: "dd/MM/yyyy",
      },
      render: (rowData) => (
        <div
          style={{ borderRadius: "50%", width: "8rem" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="table_date"
            style={{
              // color: "#707675",
              color: "#000000",
              fontFamily: "Mulish",
              fontStyle: "normal",
              // fontWeight: "400",
              // lineHeight: "25px",
            }}
          >
            {moment(rowData.endDate).format("DD/MM/YYYY")}
          </div>
          <div
            className="table_time"
            style={{
              color: "#757575",
              fontFamily: "Mulish",
              fontStyle: "normal",
              fontWeight: "normal",
              // lineHeight: "18px",
            }}
          >
            {moment(rowData.endDate).format("HH:mm:ss")}
          </div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.endDate?.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) != -1,
    },
    // {
    //   title: "Response",
    //   field: "response",
    // },
    {
      title: "Response",
      field: "responces",
      // render: (rowData) =>
      //   rowData.isActive === true ? <>Active</> : <>Inactive</>,
      // render: (rowData) => rowData.members.length,
    },
    {
      title: "Status",
      field: "status",
      customSort: (a, b) => {
        return (a.status || "").localeCompare(b.status || "");
      },
      // render: (rowData) =>
      //   rowData.isActive === true ? <>Active</> : <>Inactive</>,
      // render: (rowData) =>
      //   convert(moment(rowData.endDate)) < convert(moment())
      //     ? "Closed"
      //     : "Open",
    },
  ];
  // const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  const columnsStatus = useSelector((state) => {
    // console.log(state.dataAcquisition);
    return state.dataAcquisition.columnsStatus;
  });

  const setColumnsStatus = (data) => {
    // console.log(data);
    dispatch(DAQVisibiltyActionCreater(data));
  };

  const getTableDetails = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        BASE_URL + `/api/v1/dataAcquisition/data/dashboard`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log("all data",response.data);

      setData(
        [
          ...response.data.applications,
          ...response.data.surveys,
          ...response.data.polls,
        ].sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );
      setDataAcqLength(
        response.data.applications.length +
          response.data.surveys.length +
          response.data.polls.length
      );
      setAllData(
        [
          ...response.data.applications,
          ...response.data.surveys,
          ...response.data.polls,
        ].sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
      );
      dispatch(loaderEndActionCreater());
      // console.log("Res", response.data.data);
    } catch (err) {
      console.log(err);
      // let errors = err?.response.data.error;
      dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }

      // dispatch(openSnackbar(errors, "error"));
    }
  };

  useEffect(() => {
    if (getAuthData) {
      dispatch({ type: "start", file: "dacq" });
      dispatch(memberDataActionCreater(getAuthData));
      getTableDetails();
      setConflict(false);
      setOptionVal("All");
    }
  }, [conflict, getAuthData]);

  // console.log(optionVal);

  return (
    <>
      <DataAccquisitionHeader
        tableRef={tableRef}
        setData={setData}
        data={data}
        option_users={option_users}
        options={options}
        optionVal={optionVal}
        filterUsers={filterUsers}
        setColumnsStatus={setColumnsStatus}
        columnsStatus={columnsStatus}
        selectedUsers={selectedUsers}
        setConflict={setConflict}
      />
      {/* <DataAccquisitionFormHeader/> */}
      {/* <DataAccquisitionItemHeader/> */}

      <div className="overflow-hidden position-relative">
        <div
          align="right"
          style={{
            position: "absolute",
            top: "2%",
            right: "2%",
            zIndex: "2",
          }}
        >
          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>
        <DataAcquisitionTable
          tableRef={tableRef}
          data={finalData || []}
          columnsStatus={columnsStatus}
          setColumnsStatus={setColumnsStatus}
          columns={columns}
          setId={setId}
        />
      </div>
    </>
  );
};

export default DataAcquisition;
