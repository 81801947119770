import axios from "axios";
import { Grid, Popover } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import Slider from "@mui/material/Slider";
import Cards from "../../Components/Cards/Cards";
import "./ShareCapital.css";
import ShareCapitalTable from "./ShareCapitalTable";
import React, { createRef, useEffect, useRef, useState } from "react";
import { ShareCapitalAddForm } from "./ShareCapitalAddUpdateForm";
import { MultiSelectDropdown } from "./../../Components/SelectDropdown/MultiSelectDropdown";
import { useDispatch, useSelector } from "react-redux";
import ExportTable from "./../../Components/Export/ExportTable";
import moment from "moment";
import { getPricePerShareActionCreater } from "../../Redux/Dashboard/dashboardActionCreator";
import { getSayasAccountActionCreater } from "../../Redux/SayasAccount/sayasAccountActionCreator";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import CustomSearch from "../../Components/CustomSearch/CustomSearch";
import exportFromJSON from "export-from-json";
import { shareCapitalVisibiltyActionCreater } from "../../Redux/ShareCapital/shareCapitalActionCreator";
const fileName = "download";
const exportType = exportFromJSON.types.csv;

export const ShareCapital = () => {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState(null);
  const [tableUpdate, setTableUpdate] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalClose, setModalClose] = useState(true);
  const [conflict, setConflict] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [pricePerShare, setPricePerShare] = useState(0);
  const [currentRowData, setCurrentRowData] = useState([]);
  const [cardDataVal, setCardDataVal] = useState({
    authorisedShares: 0,
    authorisedShareCapital: 0,
    pricePerShare: 0,
    paidUpShareCapital: 0,
    shareIsued: 0,
  });
  const [finalAdvanceSearchData, setFinalAdvanceSearchData] = useState(null);
  const [paid, setPaid] = useState(null);
  const [exportData, setExportData] = React.useState([]);

  /////sayasAcc
  const [sayasAcc, setSayasAcc] = useState({});
  const [loginDetail, setLoginDetail] = useState({});
  const [userRole, serUserRole] = useState("");
  /// fetch SayasAccount data
  const getSayasData = useSelector((state) => {
    // console.log(state.sayasAccount);
    return state;
  });
  const dispatch = useDispatch();
  const tableRef = React.createRef();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    // console.log("userRole", getSayasData.auth.role);
    serUserRole(getSayasData.auth.role);
    setSayasAcc(getSayasData.sayasAccount);
    setLoginDetail(getSayasData.auth);
    setPricePerShare(getSayasData.sayasAccount.pricePerShare);
  }, [getSayasData]);

  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
    dispatch(getSayasAccountActionCreater(getAuthData));
  }, []);

  const getPaidUpShareCapital = async () => {
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/api/v1/member`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(response?.data?.data);
      const paidApp = response?.data?.data?.reduce((ans, ele) => {
        return ele.activityStatus === true
          ? ans + parseInt(ele.shareCapital)
          : ans;
      }, 0);

      setPaid(paidApp);
      dispatch(loaderEndActionCreater());

      // console.log(cardDataVal);

      // setCardDataVal({
      //   ...cardDataVal,
      //   paidUpShareCapital: paid,
      // });
    } catch (err) {
      // console.log(err);
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      dispatch(loaderEndActionCreater());
    }
  };

  useEffect(() => {
    if (getAuthData) {
      dispatch(loaderStartActionCreater());
      getPaidUpShareCapital();
    }
  }, [getAuthData]);

  useEffect(() => {
    if (paid && data) dispatch(loaderEndActionCreater());
  }, [paid, data]);

  useEffect(() => {
    setCardDataVal({
      ...cardDataVal,
      authorisedShares: sayasAcc.totalShares,
      authorisedShareCapital: sayasAcc.authorisedShareCapital,
      pricePerShare: sayasAcc.pricePerShare,
      paidUpShareCapital: paid,
      shareIsued: sayasAcc.sharesIssued,
    });
    dispatch(
      getPricePerShareActionCreater(getSayasData.sayasAccount.pricePerShare)
    );
  }, [sayasAcc, paid]);

  // console.log(sayasAcc,loginDetail);

  // console.log(isProgress);
  const handleClick = (event) => {
    setOpen(true);
    // setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setAnchorEl(null);
    setOpen(false);

    setModalClose(!modalClose);
  };

  const cardData = [
    {
      goal: "Authorised Share Capital",
      number: cardDataVal.authorisedShareCapital
        ? cardDataVal.authorisedShareCapital
        : 0,
    },
    {
      goal: "Authorised Shares",
      number: cardDataVal.authorisedShares ? cardDataVal.authorisedShares : 0,
    },

    {
      goal: "Current Price Per Share",
      number: cardDataVal.pricePerShare ? cardDataVal.pricePerShare : 0,
    },
    {
      goal: "Paid Up Share Capital",
      number: cardDataVal.paidUpShareCapital
        ? cardDataVal.paidUpShareCapital
        : 0,
    },
    {
      goal: "Share Issued",
      number: cardDataVal.shareIsued ? cardDataVal.shareIsued : 0,
    },
  ];

  const groupFilter = (target, search) => {
    return target !== null
      ? search
          .split(",")
          .filter((t) => t.trim() !== "")
          .some((t) =>
            typeof target === "string"
              ? target.toLowerCase().indexOf(t.trim().toLowerCase()) > -1
              : target.toString().indexOf(t.trim()) === 0
          )
      : false;
  };

  const columns = [
    {
      title: "ID",
      field: "shareCapitalId",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.shareCapitalId, term),
    },
    {
      title: "Authorised Share Capital",
      field: "authorisedShareCapital",
      // cellStyle: {
      //   minWidth: 280,
      // },
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.authorisedShareCapital, term),
    },
    {
      title: "Authorised Share",
      field: "authorisedShare",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.authorisedShare, term),
    },
    {
      title: "Price Per Share",
      field: "pricePerShare",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.pricePerShare, term),
    },
    {
      title: "Add Date",
      field: "date",
      // type: "date",
      format: "dd/MM/yyyy",

      // cellStyle: {
      //   minWidth: 170,
      // },
      dateSetting: {
        format: "dd/MM/yyyy",
      },
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData.date.slice(0, -1)).format("DD/MM/yyyy")}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        groupFilter(
          moment(rowData.date.slice(0, -1)).format("DD/MM/yyyy"),
          term
        ),
      // customFilterAndSearch: (term, rowData) =>
      //   moment(rowData.date.slice(0, -1)).format("DD/MM/yyyy").indexOf(term) !=
      //   -1,
    },
    {
      title: "Type of Share",
      field: "typesOfShare",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.typesOfShare, term),
      customSort: (a, b) => {
        return (a.typesOfShare || "").localeCompare(b.typesOfShare || "");
      },
    },
    {
      title: "Remarks",
      field: "remarks",
      // cellStyle: {
      //   minWidth: 200,
      // },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.remarks}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.remarks, term),
      customSort: (a, b) => {
        return (a.remarks || "").localeCompare(b.remarks || "");
      },
    },
    {
      title: "Balance Shares",
      field: "remainingShare",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.remainingShare, term),
    },
  ];

  const initialColumnStatus = {
    shareCapitalId: true,
    authorisedShareCapital: true,
    authorisedShare: true,
    pricePerShare: true,
    date: true,
    typesOfShare: true,
    remarks: true,
    remainingShare: true,
  };
  // const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  const columnsStatus = useSelector((state) => {
    // console.log(state.shareCapital);
    return state.shareCapital.columnsStatus;
  });

  const setColumnsStatus = (data) => {
    console.log(data);
    dispatch(shareCapitalVisibiltyActionCreater(data));
  };

  useEffect(() => {
    var colsKeys = Object.keys(columnsStatus);
    const mappedArrayData = data?.map((e) => {
      let data = colsKeys.map((ele) => {
        if (columnsStatus[ele] === true) {
          return e[ele];
        }
      });
      let finalAns = data.filter((e) => {
        return e != undefined;
      });
      // console.log(finalAns);
      return finalAns;
    });

    mappedArrayData?.unshift(
      colsKeys.filter((e) => {
        return columnsStatus[e] === true;
      })
    );
    let cleanData = mappedArrayData?.map((item) => {
      return item.map((e, i) => {
        return e;
      });
    });
    // console.log(cleanData);
    setExportData(cleanData);
  }, [data, columnsStatus]);

  return (
    <Box display="grid" sx={{ flexGrow: 1, width: "100%" }}>
      <div>
        <div
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 30px",
            borderBottom: "1px solid #afafaf",
          }}
        >
          <div className="sharecapitalHeading">Share Capital</div>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* <Grid xs={0.4}></Grid> */}
            <Grid
              sx={{
                background: "#0096FF!important",
                borderRadius: "5px",
              }}
            >
              <>
                <div
                  className="exportBtn"
                  style={{
                    background: "#0096FF!important",
                  }}
                  onClick={() => {
                    // console.log(tableRef.current.state.data);
                    exportFromJSON({
                      data: tableRef.current.state.data.map((ele) => {
                        delete ele["isActve"];
                        return {
                          ...ele,
                          issuedShare: ele.authorisedShare - ele.remainingShare,
                        };
                      }),
                      fileName,
                      exportType,
                    });
                  }}
                >
                  <i
                    className="fa fa-download"
                    style={{
                      color: "#ffffff",
                      background: "#0096FF!important",
                      fontSize: "22px",
                    }}
                  ></i>
                </div>
              </>
              {/*<ExportTable*/}
              {/*  data={*/}
              {/*    data*/}
              {/*      ? data.map((ele) => {*/}
              {/*          return {*/}
              {/*            shareCapitalId: ele.shareCapitalId,*/}
              {/*            authorisedShareCapital: ele.authorisedShareCapital,*/}
              {/*            authorisedShare: ele.authorisedShare,*/}
              {/*            pricePerShare: ele.pricePerShare,*/}
              {/*            addDate: moment(ele?.date?.slice(0, -1)).format(*/}
              {/*              "DD/MM/yyyy"*/}
              {/*            ),*/}
              {/*            typesOfShare: ele.typesOfShare,*/}
              {/*            remarks: ele.remarks,*/}
              {/*          };*/}
              {/*        })*/}
              {/*      : []*/}
              {/*  }*/}
              {/*/>*/}
            </Grid>
            {/* <Grid xs={0.4}></Grid> */}
            <Grid>
              {userRole.toLowerCase() === "admin" ? (
                <Button
                  className="addBtn"
                  variant="contained"
                  sx={{
                    mr: 2,
                    height: "40px",
                    width: "100%",
                    display: "flex",
                    borderRadius: "5px",
                    justifyContent: "center",
                    bgcolor: "#0096FF!important",
                    color: "#FFFFFF",
                    fontFamily: "Mulish",
                    fontStyle: "normal",
                    fontWeight: "bold",
                  }}
                  onClick={handleClick}
                >
                  ADD
                </Button>
              ) : (
                ""
              )}
              <Popover
                id="simple-popover"
                open={open}
                // anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                style={{ top: 145, left: -20 }}
              >
                <ShareCapitalAddForm
                  modalClose={modalClose}
                  open={open}
                  tableConflict={setConflict}
                  dataLength={dataLength}
                  tableUpdate={tableUpdate}
                  setTableUpdate={setTableUpdate}
                  pricePerShare={pricePerShare}
                  title="Add"
                  currentRowData={currentRowData}
                  setCurrentRowData={setCurrentRowData}
                  setOpen={setOpen}
                />
              </Popover>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        style={{
          // display: "flex",
          // justifyContent: "space-evenly",
          // flexWrap: "wrap",
          padding: "10px 0 10px",
        }}
      >
        <Grid
          className="row justify-content-evenly"
          // md={4}
          // sx={{
          //   display: "flex",
          //   flexDirection: "row",
          //   justifyContent: "space-evenly",
          //   flexWrap: "wrap",
          //   margin: "0 40px",
          //   rowGap: "20px",
          //   columnGap: "10px",
          //   width: "100%",
          // }}
        >
          {cardData.map((a, i) => (
            <div
              // className="sharecapitalCard"
              className="col-2"
              style={{
                display: "flex",
                flexShrink: 2,
                maxWidth: "226px",
              }}
            >
              <Cards data={a} />
            </div>
          ))}
        </Grid>
      </div>

      <div>
        <div
          className="d-flex justify-content-end gap-2 align-items-center mb-3"
          style={{ padding: "0rem 2rem" }}
        >
          <CustomSearch
            data={data}
            tableData={tableData}
            ref={tableRef}
            setTableData={setTableData}
            finalAdvanceSearchData={finalAdvanceSearchData}
            setFinalAdvanceSearchData={setFinalAdvanceSearchData}
            rangeSearchCol={[["Balance Shares", "remainingShare"]]}
            columns={columns}
            moduleName="shareCapital"
          />

          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>
        <ShareCapitalTable
          tableRef={tableRef}
          tableUpdate={tableUpdate}
          setDataLength={setDataLength}
          setTableUpdate={setTableUpdate}
          setOpen={setOpen}
          setConflict={setConflict}
          conflict={conflict}
          open={open}
          columnsStatus={columnsStatus}
          columns={columns}
          setFinalAdvanceSearchData={setFinalAdvanceSearchData}
          setColumnsStatus={setColumnsStatus}
          setData={setData}
          data={data}
          tableData={tableData}
          setTableData={setTableData}
        />
      </div>
    </Box>
  );
};
