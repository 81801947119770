import { useState, useEffect } from "react";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import Image from "./pinDocs.png";
import IconButton from "@mui/material/IconButton";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { handleUpload } from "../../Components/GeneralComponents/GeneralFileUpload";
import {
  addConfiguationActionCreater,
  deleteConfiguationActionCreater,
  getConfiguationActionCreater,
} from "../../Redux/Configuration/configurationActionCreator";
import { Popover } from "@mui/material";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
export const UploadTemplate = (props) => {
  const { place, dataVal, dataKey, completeData, infoMes } = props;
  // console.log(
  //   dataVal[0] ? dataVal[0].fileURL.split("/")[3].substring(13) : "not yet"
  // );
  // console.log(dataVal[0]);

  // console.log(dataVal);

  const initialData = {
    id: "",
    type: "",
    fileURL: "",
  };
  const [uploadFile, setUploadFile] = useState("No file chosen");
  const [dataStatus, setDataStatus] = useState(false);
  const [backData, setBackData] = useState(dataVal[0] ? dataVal[0] : "");
  const [data, setData] = useState({ id: "", type: "", fileURL: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();


  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  // console.log(backData);

  useEffect(() => {
    setBackData(dataVal[0] ? dataVal[0] : "");
  }, [dataVal]);
  
  const addNewTemplate = async (val, status) => {
    try {
      val.data.id = new Date().valueOf();
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/v1/configuration",
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(val);

      // dispatch(addConfiguationActionCreater(val));
      dispatch(getConfiguationActionCreater(getAuthData));

      if (status === "update") {
        dispatch(openSnackbar("Field updated successfully.", "success"));
      } else {
        dispatch(openSnackbar("New field added successfully.", "success"));
      }
      dispatch(loaderEndActionCreater());
    } catch (e) {
      if (status === "update") {
        dispatch(openSnackbar("Field updation failed", "error"));
      } else {
        dispatch(openSnackbar("New field addition failed.", "error"));
      }
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const addNewMessageTemplate = async (val, status) => {
    // console.log(val);
    try {
      // val.data.id = new Date().valueOf();
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/configuration/emailTemplate",
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(val);
      dispatch(getConfiguationActionCreater(getAuthData));

      dispatch(
        openSnackbar(
          "New field added successfully.",
          "success"
        )
      );

      dispatch(loaderEndActionCreater());
    } catch (e) {
      dispatch(openSnackbar("New field addition failed", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const updateMessageTemplate = async (id, newData) => {
    // console.log("newData", newData, id);
    if (window.confirm("Are you Sure to Update")) {
      dispatch(loaderStartActionCreater());

      try {
        // val.data.id = new Date().valueOf();
        // console.log(newData);
        ////checktoken
        // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.put(
          process.env.REACT_APP_BASE_URL +
            "/api/v1/configuration/emailTemplate/" +
            `${id}`,
          newData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );

        // console.log(newData);
        dispatch(getConfiguationActionCreater(getAuthData));

        dispatch(
          openSnackbar(
            "Field updated successfully.",
            "success"
          )
        );
        dispatch(loaderEndActionCreater());
      } catch (e) {
        dispatch(openSnackbar("Field updation failed", "error"));
        dispatch(loaderEndActionCreater());
        if (e.request.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }

      // code of update
    } else {
      dispatch(loaderEndActionCreater());
      // dispatch(openSnackbar("Update Configuration Canceled", "error"));
    }
  };

  const deleteTemplate = async (id, data, status) => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/configuration/delete" +
          `/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      if (status !== "update")
        dispatch(openSnackbar("Field deleted successfully.", "success"));
      setDataStatus(false);
      // dispatch(deleteConfiguationActionCreater(id, data));
      dispatch(getConfiguationActionCreater(getAuthData));

      setUploadFile("No file chosen");
      setData({ id: "", type: "", fileURL: "" });
    } catch (e) {
      dispatch(openSnackbar("Field delettion failed", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const handleDelete = () => {
    if (!dataStatus) {
      dispatch(openSnackbar("No such file for Delete", "error"));
      return;
    }
    let newData = {
      type: "uploadTemplate",
    };
    if (window.confirm("Are you Sure to delete")) {
      // console.log(newData);
      // dispatch(loaderStartActionCreater());
      // console.log(newData,dataVal[0].id);
      deleteTemplate(dataVal[0].id, newData);
    } else {
      dispatch(loaderEndActionCreater());
      // dispatch(openSnackbar("Delete Configuration Canceled", "error"));
    }
  };

  const handleUpdate = (id, newData) => {
    let delData = {
      type: "uploadTemplate",
    };
    if (window.confirm("Are you Sure to Update")) {
      dispatch(loaderStartActionCreater());
      deleteTemplate(id, delData, "update");
      addNewTemplate(newData, "update");
    } else {
      dispatch(loaderEndActionCreater());
      // dispatch(openSnackbar("Update Configuration Canceled", "error"));
    }
  };

  useEffect(() => {
    if (dataKey === "uploadMessageTemplate") {
      completeData.length > 0 ? setDataStatus(true) : setDataStatus(false);
    } else {
      completeData.map((e) => {
        if (e.type === dataKey) {
          setDataStatus(true);
        }
      });
    }
  }, [completeData]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div
      style={{
        width: "47%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <GeneralInputField
        // fullWidth
        // lowMargin
        data={data}
        setData={setData}
        uploadFile={uploadFile}
        disabled
        dataValue={uploadFile}
        value={
          backData
            ? dataKey === "uploadMessageTemplate"
              ? backData.template.split("/")[3].substring(13)
              : backData.fileURL.split("/")[3].substring(13)
            : "No file chosen"
        }
        paddingRight={"3.5rem"}
        label={`${place} *`}
        width={"70%"}
      />

      {/* ButtonField */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          height: "20px",
          width: "25%",
        }}
      >
        {/* PinButton */}
        <div style={{ width: "30%" }}>
          {" "}
          <IconButton aria-label="delete" size="large">
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              // width = "10px!important"
              style={{
                opacity: 0,
                width: "100%",
                position: "absolute",
                cursor: "pointer",
              }}
              onChange={async (e) => {
                if (!window.confirm("Are you Sure to Upload This File")) return;
                setUploadFile(e.target.files[0].name);

                let fileURL = await handleUpload(e.target.files);

                dispatch(loaderStartActionCreater());

                const newDataNotification = {
                  template: fileURL,
                  email: false,
                  sms: false,
                  mobileNotification: true,
                };
                const newDataForUpdate = {
                  ...backData,
                  template: fileURL,
                };
                const newData = {
                  type: "uploadTemplate",
                  data: {
                    ...data,
                    type: dataKey,
                    id: new Date().valueOf(),
                    fileURL,
                  },
                };

                // console.log(newData);

                if (dataStatus) {
                  if (dataKey === "uploadMessageTemplate") {
                    updateMessageTemplate(backData.id, newDataForUpdate);
                  } else {
                    handleUpdate(backData.id, newData);
                  }
                } else {
                  if (dataKey === "uploadMessageTemplate") {
                    addNewMessageTemplate(newDataNotification);
                  } else {
                    addNewTemplate(newData);
                  }
                }
              }}
              id="contained-button-file"
            />
            <img
              src={Image}
              srcSet={Image}
              alt="pin"
              height="100%"
              loading="lazy"
            />{" "}
            {/* <AttachFileIcon /> */}
          </IconButton>
        </div>

        {/* Delete Button */}
        <div
          style={{ width: "30%" }}
          onClick={
            dataKey === "uploadMessageTemplate"
              ? () => {
                  dispatch(
                    openSnackbar("Delete Configuration Not available", "error")
                  );
                  return;
                }
              : handleDelete
          }
        >
          <IconButton aria-label="delete" size="large">
            <DeleteIcon />
          </IconButton>
        </div>

        {/* information Button */}

        <div
          style={{ width: "30%" }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <IconButton aria-label="delete" size="large">
            <InfoTwoToneIcon />
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <div
                style={{
                  padding: ".5rem",
                  fontSize: "1.1rem",
                  maxWidth: "20rem",
                  fontStyle: "none",
                }}
              >
                {infoMes}
              </div>
            </Popover>
          </IconButton>
        </div>
      </div>
    </div>
  );
};
