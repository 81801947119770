import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Button from "@restart/ui/esm/Button";
import { Link, useRouteMatch } from "react-router-dom";
import MenuDropdown from "../../Components/MenuItems/MenuDropdown";
import { useDispatch, useSelector } from "react-redux";
import { selectedUserRows } from "../../Redux/Users/actionCreator";
import MaterialSelect from "./../../Components/SelectDropdown/MaterialSelect";
import "./userHeader.css";
import styles from "./UserInput.module.css";
import memberStyles from "../Members/member.module.css";

export const UserHeader = (props) => {
  const dispatch = useDispatch();
  const {
    option_users,
    option_action_1,
    option_action_2,
    options,
    setConflict,
    setOptions,
    handleActivateStatus,
    filterUsers,
    optionVal,
    setData,
    data,
    dataLength,
    columnsStatus,
    tableRef,
  } = props;

  const selectedUsers = useSelector((state) => state.users.selectedRow);

  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  // useEffect(() => {
  //   if (selectedUsers.length !== 0) {
  //     setOptions(option_action_2);
  //   } else {
  //     setOptions(option_action_1);
  //   }
  // }, [option_action_2, option_action_1]);

  const { path } = useRouteMatch();

  useEffect(() => {
    dispatch(selectedUserRows([]));
  }, []);

  // console.log(userRoleFetch, options);

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "20px 30px" }}
      >
        <div className="w-75">
          <MaterialSelect
            optionVal={optionVal}
            option_users={option_users}
            filterUsers={filterUsers}
          />
        </div>

        <div className="d-flex align-items-center justify-content-around gap-3">
          {userRole.toLowerCase() === "admin" ? (
            <Link to={`${path}/add-user`}>
              <button id={memberStyles.headerBtn}>Add User</button>
            </Link>
          ) : (
            ""
          )}

          {userRoleFetch?.toLowerCase() !== "staff" ? (
            <MenuDropdown
              tableRef={tableRef}
              options={
                selectedUsers.length !== 0 ? option_action_2 : option_action_1
              }
              // setAllOptions={setAllOptions}
              setConflict={setConflict}
              handleActivateStatus={handleActivateStatus}
              setData={setData}
              data={data}
              dataLength={dataLength}
              selectedUsers={selectedUsers}
              columnsStatus={columnsStatus}
              module={"user"}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
