import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect } from "react";

export const GeneralSelectField = (props) => {
  const {
    label,
    data,
    setData,
    dataKey,
    submitted,
    itemVal,
    place,
    disabled,
    setConflict,
    setIsVisibleCustom,
    type,
  } = props;

  const [value, setValue] = useState(
    data ? props?.value || data[`${dataKey}`] : ""
  );

  // console.log("Data in General Select Field on update", props.value);

  useEffect(() => {
    if (value) {
      setValue(props.value);
    }
  }, [props.value]);

  // console.log(props.value);

  var dropItems = props.items;

  const handleChange = (event) => {
    let newVal = data;
    // newVal[`${dataKey}`] = event.target.value;
    // setData(newVal);

    if (setConflict) setConflict(true);
    if (setIsVisibleCustom) {
      setIsVisibleCustom(event.target.value);
    }

    if (event === 0) {
      setValue("");
      newVal[`${dataKey}`] = event.target.value;
      setData(newVal);
    } else {
      setValue(event.target.value);
      let newVal = data;
      newVal[`${dataKey}`] = event.target.value;
      setData({ ...data, dataKey: event.target.value });
    }
  };

  useEffect(() => {
    setValue(itemVal ? itemVal : value);
    // console.log("ItemVal", itemVal);
  }, [itemVal]);

  // console.log(type);

  return (
    <FormControl
      sx={{
        width: `${props.width ? props.width : "100%"}`,
        margin: "1rem .1rem",
        marginBottom: `${props.lowMargin ? "0.1rem" : ".1rem"}`,
        textTransform: "capitalize",
        variant: `${type === "issue" ? "filled" : "standard"}`,
      }}
    >
      {/* first drop down */}
      <InputLabel
        id={`${
          type === "issue"
            ? "demo-simple-select-standard-label"
            : "demo-simple-select-label"
        }`}
      >
        {place ? place : props.label}
      </InputLabel>
      <Select
        labelId={`${
          type === "issue"
            ? "demo-simple-select-standard-label"
            : "demo-simple-select-label"
        }`}
        id="demo-simple-select"
        value={value}
        error={submitted && !value ? true : false}
        disabled={disabled ? true : false}
        label={place}
        onChange={handleChange}
      >
        {dropItems.map((e, i) => {
          return <MenuItem value={e}>{e}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
