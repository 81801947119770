import axios from "axios";
import { LogoutSayasActionCreate } from "../Authontication/authonticationActionCreator";
import actions from "./sayasAccountActions";
const { getSayasAccountAction } = actions;

const getSayasAccountData = async (dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/v1/sayas",
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // console.log("getConfigData", response.data);

    return response.data;
  } catch (e) {
    console.log(e);
    // alert(e);
    if (e.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

export const getSayasAccountActionCreater = (token) => {
  return async (dispatch) => {
    let data = await getSayasAccountData(dispatch, token);
    try {
      // console.log("1. getSayasAccountCreater",  data);
      dispatch(getSayasAccountAction(data));
    } catch (err) {
      // console.log(err.response);
    }
  };
};
