import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  ListItemButton,
} from "@mui/material";
import { border, height, width } from "@mui/system";
import Button from "@restart/ui/esm/Button";
import {
  bindTrigger,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import Popover from "@mui/material/Popover";
import { GeneralDateRangePicker } from "../../../Components/GeneralComponents/GeneralDateRangePicker";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { Link, useRouteMatch } from "react-router-dom";
import "../ReportsHeader.css";
import MaterialSelect from "../../../Components/SelectDropdown/MaterialSelect";
import ReportsSelectHeader from "../ReportSelectHeader";
import ExportTable from "../../../Components/Export/ExportTable";
import exportFromJSON from "export-from-json";
import { GeneralDateRangePickerForReport } from "../../../Components/GeneralComponents/GeneralDateRangePickerForReport";
const fileName = "download";
const exportType = exportFromJSON.types.csv;

export const ShareApplicationMoneyHeader = (props) => {
  const {
    setTabValue,
    tabValue,
    selectValue,
    setSelectValue,
    finalData,
    setData,
    tableRef,
  } = props;
  const [filterDate, setFilterDate] = useState({});

  const handleTabValue = (e) => {
    setTabValue(e.target.value);
  };

  const options = ["All", "Credit", "Debit"];

  const [secondary, setSecondary] = useState(false);
  const [dense, setDense] = useState(false);
  const ListItemForHeader = [
    "Today",
    "Yesterday",
    "This Week",
    "Previous Week",
    "Last 30 Days",
  ];

  const handleClickOnRightButton = (e) => {
    setSelectValue(e.target.innerText);
    handleClose();
    if (e.target.innerText === "Custom") popupState.open(anchorElD);
    switch (e.target.innerText) {
      case "Today":
        setData({
          startDate: moment().format("YYYY MM DD"),
          endDate: moment().format("YYYY MM DD"),
        });
        break;
      case "Yesterday":
        setData({
          startDate: moment().add(-1, "days").format("YYYY MM DD"),
          endDate: moment().add(-1, "days").format("YYYY MM DD"),
        });
        break;
      case "This Week":
        const today = moment();
        setData({
          startDate: today.startOf("week").format("YYYY MM DD"),
          endDate: today.endOf("week").format("YYYY MM DD"),
        });
        break;
      case "Previous Week":
        const day = moment().add(-7, "days");
        setData({
          startDate: day.startOf("week").format("YYYY MM DD"),
          endDate: day.endOf("week").format("YYYY MM DD"),
        });
        break;
      case "Last 30 Days":
        setData({
          startDate: moment().add(-30, "days").format("YYYY MM DD"),
          endDate: moment().format("YYYY MM DD"),
        });
        break;

      default:
        break;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElD, setAnchorElD] = useState(null);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorElD(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseD = () => {
    setAnchorElD(null);
    // console.log("close datepicker");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openD = Boolean(anchorElD);
  const idD = openD ? "simple-popover" : undefined;

  // console.log(
  //   "In shareApplication::",
  //   tableRef?.current?.state?.data,

  //   tableRef?.current?.state?.data?.map((ele) => {
  //     return {
  //       date: ele.createdAt,
  //       _id: ele._id,
  //       MemberId: ele?.memberId?.memberId,
  //       Member: ele?.memberId?.member,
  //       FirstName: ele?.memberId?.firstName,
  //       MiddleName: ele?.memberId?.middleName,
  //       LastName: ele?.memberId?.lastName,
  //       Email: ele?.memberId?.email,
  //       Phone: ele?.memberId?.phone,
  //       AddressLine_1: ele?.memberId?.addressLine_1,
  //       AddressLine_2: ele?.memberId?.addressLine_2,
  //       noOfShares: ele.noOfShares,
  //     };
  //   })
  // );

  return (
    <div style={{ margin: "20px 30px" }}>
      <Grid
        // container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderColor: "grey.500",
        }}
      >
        <Grid md={3}>
          <Grid sx={{ fontSize: "1.4rem" }}>
            <MaterialSelect
              option_users={options}
              optionVal={tabValue}
              filterUsers={setTabValue}
            />
          </Grid>
          {/* <Grid sx={{ fontSize: "1.4rem" }}>
            {" "}
            <select onChange={handleTabValue}>
              {options.map((item) => (
                <option
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {item}
                </option>
              ))}
            </select>
          </Grid> */}
        </Grid>

        <Grid
          spacing={1}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            m: 1,
            mr: 5.2,
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Grid>
            <>
              {/* <ExportTable
              data={finalData?.map((ele) => {
                return {
                  MembershipId: ele?.memberId?.memberId,
                  TransactionId: ele?.id,
                  memberName:
                    ele?.memberId?.firstName + " " + ele?.memberId?.lastName,
                  Balance: ele.balance || "0",
                  amount: ele?.amount || "0",
                  Date: moment(ele?.createdAt.slice(0, -1)).format("DD/MM/yyyy"),
                  Particulars: ele?.particulars,
                  Status: ele?.isActve ? "Active" : "Inactive",
                };
              })}
            /> */}
              <div
                className="exportBtn"
                onClick={() => {
                  exportFromJSON({
                    data: tableRef?.current?.state?.data?.map((ele) => {
                      return {
                        transactionDate: ele.createdAt,
                        _id: ele._id,
                        MemberId: ele?.memberId?.memberId?.trim(),
                        MemberType: ele?.memberId?.member?.trim(),
                        MemberShareHolder: ele?.memberId?.shareholder?.trim(),
                        FirstName: ele?.memberId?.firstName?.trim(),
                        MiddleName: ele?.memberId?.middleName?.trim(),
                        LastName: ele?.memberId?.lastName?.trim(),
                        Phone: ele?.memberId?.phone,
                        Email: ele?.memberId?.email?.trim(),
                        AddressLine_1: ele?.memberId?.addressLine_1?.trim(),
                        AddressLine_2: ele?.memberId?.addressLine_2?.trim(),
                        State: ele?.memberId?.state?.trim(),
                        District: ele?.memberId?.district?.trim(),
                        Taluka: ele?.memberId?.taluka?.trim(),
                        City: ele?.memberId?.city?.trim(),
                        Pincode: ele?.memberId?.pincode,
                        OrganisationName: ele?.memberId?.organisation_name?.trim(),
                        ActivityStatus: ele?.memberId?.activityStatus,
                        MobileSignUpStatus: ele?.memberId?.mobileSignUpStatus,
                        TotalShare: ele?.memberId?.totalShare,
                        ShareCapital: ele?.memberId?.shareCapital,
                        ShareApplicationMoney:
                          ele?.memberId?.shareApplicationMoney,
                        amount: ele.amount,
                        balance: ele.balance,
                        particulars:
                          ele.particulars === "debit"
                            ? "Paid towards sharers"
                            : "Contribution added",
                      };
                    }),
                    fileName,
                    exportType,
                  });
                }}
              >
                <i
                  className="fa fa-download"
                  style={{ color: "#ffffff", fontSize: "22px" }}
                ></i>
              </div>
            </>
          </Grid>

          <div
            style={{
              textTransform: "capitalize",
            }}
          >
            <button
              aria-describedby={id}
              className="btnHover"
              onClick={handlePopOver}
              style={{
                outline: "none",
                border: "none",
                fontSize: "20px",
                background: "#0096FF",
                borderRadius: "5px",
                color: "#FFFFFF",
                padding: "5px 15px",
                textTransform: "capitalize",
              }}
            >
              {selectValue}
              <i
                className={open ? "fa fa-caret-up" : "fa fa-caret-down"}
                style={{ marginLeft: 10 }}
              />
            </button>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <List dense={dense}>
                  {ListItemForHeader.map((value) => {
                    return (
                      <ListItem>
                        <ListItemButton
                          onClick={handleClickOnRightButton}
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          <ListItemText
                            primary={value}
                            secondary={secondary ? "Secondary text" : null}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  <ListItem>
                    {/* <PopupState variant="popover" popupId="demo-popup-popover"> */}
                    {/* {(popupState) => ( */}
                    {/* <div> */}
                    <ListItemButton
                      onClick={handleClickOnRightButton}
                      // {...bindTrigger(popupState)}
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      <ListItemText
                        primary={"Custom"}
                        // onClick={handleClickOnRightButton}
                        secondary={secondary ? "Secondary text" : null}
                      />
                    </ListItemButton>
                    {/* </div> */}
                    {/* )} */}
                    {/* </PopupState> */}
                  </ListItem>
                </List>
              </Typography>
            </Popover>
            <Popover
              id={idD}
              open={openD}
              anchorEl={anchorElD}
              onClose={handleCloseD}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography
                sx={{ p: 2 }}
                style={{
                  // height: "85vh",
                  width: "46.5vw",
                  // minHeight: "20vh",
                }}
              >
                {/* <GeneralDateRangePicker
                  setData={setData}
                  handleCloseD={popupState.close}
                /> */}
                <GeneralDateRangePickerForReport
                  setData={setData}
                  handleCloseD={popupState.close}
                  setFilterDate={setFilterDate}
                  filterDate={filterDate}
                />
              </Typography>
            </Popover>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
