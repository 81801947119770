import { Popover } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import DataTable from "../../Components/Table";
import { ShareCapitalAddForm } from "./ShareCapitalAddUpdateForm";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { CsvBuilder } from "filefy";
const ShareCapitalTable = (props) => {
  const {
    setDataLength,
    columns,
    columnsStatus,
    setConflict,
    conflict,
    setColumnsStatus,
    data,
    open,
    setData,
    tableData,
    setTableData,
    tableRef,
    setFinalAdvanceSearchData
  } = props;
  const history = useHistory();
  const [currentRowData, setCurrentRowData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const dispatch = useDispatch();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getTableDetails = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/shareCapital",
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      if (response?.data?.data?.length > 0) {
        let data = response.data.data;
        let shareTypes = [...new Set(data.map((data) => data.typesOfShare))];
        let activeIds = [];
        // console.log(shareTypes);
        shareTypes.forEach((st) => {
          let id = data.find((sc) => sc.typesOfShare === st).shareCapitalId;
          activeIds.push(id);
        });
        // console.log(activeIds);

        let newData = data.map((d) =>
          activeIds?.includes(d.shareCapitalId)
            ? { ...d, isActive: true }
            : { ...d, isActive: false }
        );
        setData(newData);
        setFinalAdvanceSearchData(newData)
        setTableData(newData);
      } else {
        setData(response?.data?.data);
        setTableData(response?.data?.data);
      }
      setDataLength(response?.data.count);
      // console.log(response.data.count);
      // dispatch(openSnackbar("Shares Capital fetch successfully", "success"));
      // dispatch(loaderEndActionCreater());
      setConflict(false);
    } catch (e) {
      console.log(e);
      // dispatch(openSnackbar("Shares Capital fetch failed", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    dispatch(loaderEndActionCreater());
  }, [tableData]);

  useEffect(() => {
    if (getAuthData) getTableDetails();
    // console.log("table");
  }, [props.tableUpdate, updateOpen, open, conflict, getAuthData]);

  const options = {
    search: false,
    selection: false,
    filtering: true,
    title: false,
    sorting: true,

    // exportButton: {
    //   csv: true,
    //   // pdf: true,
    // },
    exportCsv: (columns, data) => {
      console.log(data, columns, "<== CSV");
      const columnTitles = columns.map((columnDef) => {
        console.log(columnDef);
        return columnDef.title;
      });

      const csvData = data.map((rowData) =>
        columns.map((columnDef) => rowData[columnDef.field])
      );

      console.log(columnTitles, csvData);

      const builder = new CsvBuilder(`shareCapital.csv`)
        .setColumns(columnTitles)
        .addRows(csvData)
        .exportFile();

      return builder;
    },

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
      padding: "10px",
    },
    cellStyle: {
      padding: "10px",
    },
    // paging: false,
    maxBodyHeight: "45vh",
    pageSize: 10,
    searchFieldStyle: {
      marginRight: "6vw",
      width: "15vw",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
    // rowStyle: (rowData) => ({
    //   backgroundColor:
    //     rowData.isActive && rowData.remainingShare
    //       ? "rgb(2, 214, 16,0.4)"
    //       : rowData.remainingShare
    //       ? "rgba(252, 144, 3,0.4)"
    //       : "rgb(255,3,3, 0.4)",
    // }),
  };

  return (
    <>
      <DataTable
        tableRef={tableRef}
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={tableData || []}
        title={""}
        options={options}

        // headerStyle={{ FontFace: "bold" }}
        // onRowClick={(e, data) => {
        //   setUpdateOpen(true);
        //   setCurrentRowData(data);
        // }}
      />
      <Popover
        id="simple-popover"
        open={updateOpen}
        // anchorEl={anchorEl}
        onClose={() => {
          setUpdateOpen(false);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ top: 125, left: -20 }}
      >
        <ShareCapitalAddForm
          tableUpdate={props.tableUpdate}
          setTableUpdate={props.setTableUpdate}
          title="Update"
          currentRowData={currentRowData}
          setCurrentRowData={setCurrentRowData}
          setOpen={setUpdateOpen}
        />
      </Popover>
    </>
  );
};

export default ShareCapitalTable;
