import * as React from "react";
import Box from "@mui/material/Box";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import down from "../../assets/images/caret-down1.png";
import { styled } from '@mui/material/styles';
import { FormControl, MenuItem, Select } from "@mui/material";
import InputBase from '@mui/material/InputBase';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    width: 100,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function SelectDropdown(props) {
  const { pieChartFilter, setPieChartFilter } = props;

  const handleChange = (event) => {
    setPieChartFilter(event.target.value);
  };

  const options = ["Organisation", "State", "City"];

  return (
    <div>
      {/* <select
        style={{
          appearance: "none",
          outline: "none",
          border: "none",
          fontSize: "20px",
          background: "#C4C4C4",
          borderRadius: "5px",
          color: "#FFFFFF",
          padding: "5px 10px",
          backgroundImage: `url(${down})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "16px",
          backgroundPosition: "calc(100% - 5px) center",
          paddingRight: "30px",
        }}
      >
        {options.map((item) => (
          <option
            value={item}
            style={{ backgroundColor: "#ffffff", color: "#000000" }}
          >
            {item}
          </option>
        ))}
      
        
      </select> */}

      <FormControl sx={{ m: 1 }} variant="standard">
        {/* <InputLabel id="demo-customized-select-label">Age</InputLabel> */}
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={pieChartFilter}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {options.map((ele) => {
            return <MenuItem value={ele}>{ele}</MenuItem>;
          })}
          {/* <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
      </FormControl>
    </div>
  );
}
