import React, { useEffect } from "react";
import axios from "axios";
import Cards from "../../Components/Cards/Cards";
import Charts from "./../../Components/Charts/Charts";
import Tables from "./../../Components/Table/Tables";
import styles from "../Dashboard/Dashboard.module.css";
import PieCharts from "./../../Components/PieChart/PieChart";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  getDashboardTransfer,
  getPieChartDataActionCreater,
  getPricePerShareActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../Redux/Dashboard/dashboardActionCreator";
import { getSayasAccountActionCreater } from "../../Redux/SayasAccount/sayasAccountActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [sayasAcc, setSayasAcc] = useState({});
  const [moneyTransfered, setMoneyTransfered] = useState([]);
  const [issuance, setIssuance] = useState([]);
  const [topShareHolder, setTopShareHolder] = useState([]);
  const [totalShareHolders, setTotalShareHolders] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [paid, setPaid] = useState(null);
  const [shareAppMoney, setShareAppMoney] = useState(null);

  const dashboardData = useSelector((state) => {
    return state;
  });

  useEffect(() => {
    let loginVerified = window.localStorage.getItem("sayasIdentifier");
    if (loginVerified === "true") {
      window.addEventListener("beforeunload", () => {
        window.localStorage.removeItem("sayasIdentifier");
      });
    } else {
      window.localStorage.setItem("sayasIdentifier", "true");
      window.location.reload();
    }
  }, []);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    // console.log(dashboardData.dashboard);
    if (dashboardData.dashboard.dashTransfer) {
      setMoneyTransfered(dashboardData.dashboard.dashTransfer.transferMoney);
      setIssuance(dashboardData.dashboard.dashTransfer.issuanceShare);
    }
    setTopShareHolder(dashboardData.dashboard.topShareHolders);

    // console.log(dashboardData.dashboard.totalShareHoldersMembers);

    setTotalShareHolders(
      dashboardData.dashboard.totalShareHoldersMembers
        ? dashboardData.dashboard.totalShareHoldersMembers.shareHolders[0]
            ?.totalShareHolders
        : 0
    );
    setTotalMembers(
      dashboardData.dashboard.totalShareHoldersMembers
        ? dashboardData.dashboard.totalShareHoldersMembers.totalMember[0]
            ?.totalMembers
        : 0
    );
    // console.log(dashboardData.sayasAccount);
    setSayasAcc(dashboardData.sayasAccount);
  }, [dashboardData]);

  // console.log(
  //   "dashboard confirm",
  //   totalMembers, totalShareHolders
  // );
  useEffect(() => {
    dispatch(
      getPricePerShareActionCreater(dashboardData.sayasAccount?.pricePerShare)
    );
  }, [sayasAcc]);

  useEffect(() => {
    dispatch(loaderEndActionCreater());
    dispatch(getTop10ShareHolders(getAuthData));
    dispatch(getSayasAccountActionCreater(getAuthData));
    dispatch(getTotalShareHoldersMembers(getAuthData));
    dispatch(getDashboardTransfer(getAuthData));
  }, []);

  // console.log("Dashboard router!");
  const data = [
    {
      goal: "Authorised Share Capital",
      number: sayasAcc.authorisedShareCapital
        ? sayasAcc.authorisedShareCapital
        : 0,
    },
    {
      goal: "Paid Up Share Capital",
      number: paid || 0,
    },
    {
      goal: "Total Shares Issued",
      number: sayasAcc.sharesIssued ? sayasAcc.sharesIssued : 0,
    },
    {
      goal: "Share Application Money",
      number: shareAppMoney || 0,
    },
    {
      goal: "Total Shareholders",
      number: totalShareHolders ? totalShareHolders : 0,
    },
    {
      goal: "Total Members",
      number: totalMembers ? totalMembers : 0,
    },
  ];

  const getPaidUpShareCapital = async () => {
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        // dispatch(LogoutSayasActionCreate());
        return;
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/api/v1/member`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(response?.data?.data);
      const paidApp = response?.data?.data?.reduce((ans, ele) => {
        return ele.activityStatus === true
          ? ans + parseInt(ele.shareCapital)
          : ans;
      }, 0);

      const shareAppMoney = response?.data?.data?.reduce((ans, ele) => {
        return ele.activityStatus === true
          ? ans + parseInt(ele.shareApplicationMoney)
          : ans;
      }, 0);

      setPaid(paidApp);
      setShareAppMoney(shareAppMoney);

      // console.log(cardDataVal);

      // setCardDataVal({
      //   ...cardDataVal,
      //   paidUpShareCapital: paid,
      // });
      // dispatch(loaderEndActionCreater());
    } catch (err) {
      // console.log(err);
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      dispatch(loaderEndActionCreater());
    }
  };

  useEffect(() => {
    getPaidUpShareCapital();
  }, [getAuthData]);

  let dummyIssue = [
    { sum: 1903, _id: { month: 1, year: 2022 } },
    { sum: 6203, _id: { month: 2, year: 2022 } },
    { sum: 4903, _id: { month: 3, year: 2022 } },
    { sum: 8903, _id: { month: 4, year: 2022 } },
    { sum: 3903, _id: { month: 5, year: 2022 } },
    { sum: 4903, _id: { month: 6, year: 2022 } },
    { sum: 1203, _id: { month: 7, year: 2022 } },
    { sum: 4303, _id: { month: 8, year: 2022 } },
    { sum: 5403, _id: { month: 9, year: 2022 } },
    { sum: 6403, _id: { month: 10, year: 2022 } },
    { sum: 6203, _id: { month: 11, year: 2022 } },
    { sum: 1603, _id: { month: 12, year: 2022 } },
  ];
  let dummyMoneyTransfer = [
    { sum: 61903, _id: { month: 1, year: 2022 } },
    { sum: 69203, _id: { month: 2, year: 2022 } },
    { sum: 16903, _id: { month: 3, year: 2022 } },
    { sum: 10903, _id: { month: 4, year: 2022 } },
    { sum: 65403, _id: { month: 5, year: 2022 } },
    { sum: 32631, _id: { month: 6, year: 2022 } },
    { sum: 49871, _id: { month: 7, year: 2022 } },
    { sum: 92343, _id: { month: 8, year: 2022 } },
    { sum: 23403, _id: { month: 9, year: 2022 } },
    { sum: 67653, _id: { month: 10, year: 2022 } },
    { sum: 29098, _id: { month: 11, year: 2022 } },
    { sum: 92403, _id: { month: 12, year: 2022 } },
  ];

  // console.log(issuance, moneyTransfered);
  return (
    <>
      <div className={styles.container}>
        <div
          className="row justify-content-evenly"
          // className={styles.barGrid}
        >
          {/* <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}> */}
          {data.map((a, i) => (
            <div
              // className={styles.dashboardCard}
              className="col-2 dashboard-cards"
              style={{
                display: "flex",
                flexShrink: 2,
                maxWidth: "226px",
              }}
            >
              <Cards data={a} />
            </div>
          ))}
          {/* </Grid> */}
        </div>

        <div className="row">
          <div className="col-md-6 mt-5">
            <Charts
              text={"Share Issuance"}
              chartData={issuance}
              // chartData={dummyIssue}
            />
          </div>
          <div className="col-md-6 mt-5">
            <Charts
              text={"Share Application Money Contribution"}
              chartData={moneyTransfered}
              // chartData={dummyMoneyTransfer}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-5">
            <Tables topShareHolder={topShareHolder} />
          </div>
          <div className="col-md-6 mt-5">
            <PieCharts />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
