const StateCity = [
  {
    id: 57584,
    name: "Bhinmal",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.00743,
    longitude: 76.6076,
    wikiDataId: "Q4667324",
  },
  {
    id: 57585,
    name: "Abhayapuri",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.32255,
    longitude: 90.68526,
    wikiDataId: "Q490701",
  },
  {
    id: 57586,
    name: "Chinnamanur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.4423,
    longitude: 78.4399,
    wikiDataId: "Q490715",
  },
  {
    id: 57587,
    name: "Dera Bassi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.14453,
    longitude: 74.19552,
    wikiDataId: "Q490878",
  },
  {
    id: 57588,
    name: "Abrama",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.85865,
    longitude: 72.90648,
    wikiDataId: "Q490916",
  },
  {
    id: 57589,
    name: "Ale",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25665,
    longitude: 77.51006,
    wikiDataId: "Q490886",
  },
  {
    id: 57590,
    name: "Banthra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.17826,
    longitude: 77.75674,
    wikiDataId: "Q490739",
  },
  {
    id: 57591,
    name: "Adalaj",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.16453,
    longitude: 72.58107,
    wikiDataId: "Q2350169",
  },
  {
    id: 57592,
    name: "Dhanaula",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.43224,
    longitude: 75.71484,
    wikiDataId: "Q2350169",
  },
  {
    id: 57593,
    name: "Addanki",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.81061,
    longitude: 79.97338,
    wikiDataId: "Q2350169",
  },
  {
    id: 57594,
    name: "Chinnasekkadu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.34059,
    longitude: 79.37905,
    wikiDataId: "Q490694",
  },
  {
    id: 57595,
    name: "Cholapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.01542,
    longitude: 79.48093,
    wikiDataId: "Q490694",
  },
  {
    id: 57596,
    name: "Badagara",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.15595,
    longitude: 76.73192,
    wikiDataId: "Q490941",
  },
  {
    id: 57597,
    name: "Bara Banki",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.3937,
    longitude: 78.67393,
    wikiDataId: "Q490688",
  },
  {
    id: 57598,
    name: "Annigeri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.19986,
    longitude: 76.36018,
    wikiDataId: "Q490688",
  },
  {
    id: 57599,
    name: "Ambah",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.71177,
    longitude: 76.01571,
    wikiDataId: "Q490688",
  },
  {
    id: 57600,
    name: "Bahjoi",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83605,
    longitude: 91.27939,
    wikiDataId: "Q170454",
  },
  {
    id: 57601,
    name: "Baragaon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.18333,
    longitude: 78.01667,
    wikiDataId: "Q42941",
  },
  {
    id: 57602,
    name: "Amalner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.41386,
    longitude: 80.00359,
    wikiDataId: "Q3606967",
  },
  {
    id: 57603,
    name: "Amarnath",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.09457,
    longitude: 74.73843,
    wikiDataId: "Q223517",
  },
  {
    id: 57604,
    name: "Ambad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.70622,
    longitude: 76.93731,
    wikiDataId: "Q590521",
  },
  {
    id: 57606,
    name: "Ahmedabad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.02579,
    longitude: 72.58727,
    wikiDataId: "Q1070",
  },
  {
    id: 57607,
    name: "Baraut",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.01579,
    longitude: 83.03294,
    wikiDataId: "Q587150",
  },
  {
    id: 57608,
    name: "Ahwa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75718,
    longitude: 73.68626,
    wikiDataId: "Q1964214",
  },
  {
    id: 57609,
    name: "Ambarnath",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.15096,
    longitude: 72.99625,
    wikiDataId: "Q4698841",
  },
  {
    id: 57610,
    name: "Angul District",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.8,
    longitude: 92.9,
    wikiDataId: "Q1947322",
  },
  {
    id: 57611,
    name: "Amla",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.89879,
    longitude: 80.25921,
    wikiDataId: "Q583090",
  },
  {
    id: 57612,
    name: "Dhariwal",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.65,
    longitude: 76.7,
    wikiDataId: "Q2037672",
  },
  {
    id: 57613,
    name: "Arkalgud",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.72794,
    longitude: 76.0068,
    wikiDataId: "Q2037672",
  },
  {
    id: 57614,
    name: "Bikaner",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.25,
    longitude: 74.66667,
    wikiDataId: "Q413037",
  },
  {
    id: 57615,
    name: "Dhilwan",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.84473,
    longitude: 74.76295,
    wikiDataId: "Q585651",
  },
  {
    id: 57616,
    name: "Bhongaon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.79909,
    longitude: 82.2047,
    wikiDataId: "Q186040",
  },
  {
    id: 57617,
    name: "Ambegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.11601,
    longitude: 74.21097,
    wikiDataId: "Q584021",
  },
  {
    id: 57618,
    name: "Amgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.52532,
    longitude: 76.20611,
    wikiDataId: "Q239809",
  },
  {
    id: 57619,
    name: "Akaltara",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.02463,
    longitude: 82.42641,
    wikiDataId: "Q582949",
  },
  {
    id: 57620,
    name: "Akasahebpet",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.50455,
    longitude: 82.56597,
    wikiDataId: "Q582949",
  },
  {
    id: 57621,
    name: "Barkhera",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.42953,
    longitude: 82.53431,
    wikiDataId: "Q583899",
  },
  {
    id: 57622,
    name: "Bandipura",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.86667,
    longitude: 74.73333,
    wikiDataId: "Q416835",
  },
  {
    id: 57623,
    name: "Akividu",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.58225,
    longitude: 81.38112,
    wikiDataId: "Q416835",
  },
  {
    id: 57624,
    name: "Akkarampalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.65,
    longitude: 79.42,
    wikiDataId: "Q585782",
  },
  {
    id: 57625,
    name: "Bilara",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.41288,
    longitude: 76.56719,
    wikiDataId: "Q587185",
  },
  {
    id: 57626,
    name: "Anjad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.38027,
    longitude: 76.59875,
    wikiDataId: "Q582235",
  },
  {
    id: 57627,
    name: "Andheri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.5,
    longitude: 77.16667,
    wikiDataId: "Q213014",
  },
  {
    id: 57628,
    name: "Anjangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.0963,
    longitude: 77.0588,
    wikiDataId: "Q589224",
  },
  {
    id: 57629,
    name: "Dhudi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.82074,
    longitude: 75.89078,
    wikiDataId: "Q585750",
  },
  {
    id: 57630,
    name: "Coimbatore",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.88705,
    longitude: 78.91758,
    wikiDataId: "Q585750",
  },
  {
    id: 57631,
    name: "Antri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.02514,
    longitude: 78.79697,
    wikiDataId: "Q14201843",
  },
  {
    id: 57632,
    name: "Arsikere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.56425,
    longitude: 76.56854,
    wikiDataId: "Q2738477",
  },
  {
    id: 57633,
    name: "Anshing",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.67756,
    longitude: 73.89868,
    wikiDataId: "Q589653",
  },
  {
    id: 57634,
    name: "Colachel",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.0025,
    longitude: 80.20611,
    wikiDataId: "Q584845",
  },
  {
    id: 57635,
    name: "Cuddalore",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.04697,
    longitude: 78.09033,
    wikiDataId: "Q582312",
  },
  {
    id: 57636,
    name: "Denkanikota",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.62235,
    longitude: 78.75207,
    wikiDataId: "Q590029",
  },
  {
    id: 57637,
    name: "Beypore",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.49004,
    longitude: 76.3264,
    wikiDataId: "Q585026",
  },
  {
    id: 57638,
    name: "Aldona",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.59337,
    longitude: 73.87482,
    wikiDataId: "Q584939",
  },
  {
    id: 57639,
    name: "Anuppur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.31384,
    longitude: 74.36452,
    wikiDataId: "Q2667586",
  },
  {
    id: 57640,
    name: "Surianwan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.42012,
    longitude: 81.88385,
    wikiDataId: "Q1773426",
  },
  {
    id: 57641,
    name: "Basti",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5454,
    longitude: 79.68715,
    wikiDataId: "Q589789",
  },
  {
    id: 57642,
    name: "Argaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.43172,
    longitude: 80.06377,
    wikiDataId: "Q589789",
  },
  {
    id: 57643,
    name: "Chakdaha",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.69223,
    longitude: 79.49789,
    wikiDataId: "Q1805066",
  },
  {
    id: 57644,
    name: "Athni",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.42727,
    longitude: 74.74111,
    wikiDataId: "Q583849",
  },
  {
    id: 57645,
    name: "Along",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.16951,
    longitude: 94.8006,
    wikiDataId: "Q519770",
  },
  {
    id: 57646,
    name: "Aron",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.76336,
    longitude: 75.55662,
    wikiDataId: "Q583826",
  },
  {
    id: 57647,
    name: "Changanacheri",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.10764,
    longitude: 76.35158,
    wikiDataId: "Q588793",
  },
  {
    id: 57648,
    name: "Dhali",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.60635,
    longitude: 77.93983,
    wikiDataId: "Q588793",
  },
  {
    id: 57649,
    name: "Bissau",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5,
    longitude: 76.5,
    wikiDataId: "Q449690",
  },
  {
    id: 57650,
    name: "Chēlakara",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.10649,
    longitude: 76.35484,
    wikiDataId: "Q449690",
  },
  {
    id: 57651,
    name: "Dhuri",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.43161,
    longitude: 75.65614,
    wikiDataId: "Q583873",
  },
  {
    id: 57652,
    name: "Arangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.64813,
    longitude: 72.87579,
    wikiDataId: "Q385394",
  },
  {
    id: 57653,
    name: "Barokhar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.49358,
    longitude: 79.17127,
    wikiDataId: "Q589296",
  },
  {
    id: 57654,
    name: "Barsana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.83333,
    longitude: 78.16667,
    wikiDataId: "Q201832",
  },
  {
    id: 57655,
    name: "Alipur",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.79862,
    longitude: 77.13314,
    wikiDataId: "Q766918",
  },
  {
    id: 57656,
    name: "Aurad",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.97805,
    longitude: 75.99094,
    wikiDataId: "Q1320963",
  },
  {
    id: 57657,
    name: "Artist Village",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.03983,
    longitude: 75.05887,
    wikiDataId: "Q589095",
  },
  {
    id: 57658,
    name: "Amalapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.57868,
    longitude: 82.00609,
    wikiDataId: "Q588625",
  },
  {
    id: 57659,
    name: "Ashta",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.67486,
    longitude: 81.75908,
    wikiDataId: "Q588826",
  },
  {
    id: 57660,
    name: "Arvi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.2,
    longitude: 73.16667,
    wikiDataId: "Q584008",
  },
  {
    id: 57661,
    name: "Amarpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.03967,
    longitude: 86.90247,
    wikiDataId: "Q1609229",
  },
  {
    id: 57662,
    name: "Bahraich",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.5257,
    longitude: 91.65879,
    wikiDataId: "Q589674",
  },
  {
    id: 57663,
    name: "Babai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.31371,
    longitude: 80.97703,
    wikiDataId: "Q589830",
  },
  {
    id: 57664,
    name: "Badarwas",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.2978,
    longitude: 79.16943,
    wikiDataId: "Q585696",
  },
  {
    id: 57665,
    name: "Ashti",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.61301,
    longitude: 75.78906,
    wikiDataId: "Q583887",
  },
  {
    id: 57666,
    name: "Belthara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.85706,
    longitude: 77.33583,
    wikiDataId: "Q26791799",
  },
  {
    id: 57667,
    name: "Dharapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.71068,
    longitude: 77.4519,
    wikiDataId: "Q585696",
  },
  {
    id: 57668,
    name: "Dharmapuri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.09818,
    longitude: 80.16152,
    wikiDataId: "Q456737",
  },
  {
    id: 57669,
    name: "Beniganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.40544,
    longitude: 82.69762,
    wikiDataId: "Q456764",
  },
  {
    id: 57670,
    name: "Ambikapur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.11892,
    longitude: 83.19537,
    wikiDataId: "Q2088480",
  },
  {
    id: 57671,
    name: "Dindigul",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.79163,
    longitude: 78.71644,
    wikiDataId: "Q584854",
  },
  {
    id: 57672,
    name: "Ambagarh Chauki",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.77644,
    longitude: 80.74608,
    wikiDataId: "Q590056",
  },
  {
    id: 57673,
    name: "Badnawar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.70423,
    longitude: 78.22678,
    wikiDataId: "Q583837",
  },
  {
    id: 57674,
    name: "Asoda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.73312,
    longitude: 76.38616,
    wikiDataId: "Q584032",
  },
  {
    id: 57675,
    name: "Ateli Mandi",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.32854,
    longitude: 76.9422,
    wikiDataId: "Q2086226",
  },
  {
    id: 57676,
    name: "Borkhera",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.30609,
    longitude: 73.9258,
    wikiDataId: "Q583756",
  },
  {
    id: 57677,
    name: "Beswan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.01667,
    longitude: 81.05,
    wikiDataId: "Q583756",
  },
  {
    id: 57679,
    name: "Amguri",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.81482,
    longitude: 94.52614,
    wikiDataId: "Q585858",
  },
  {
    id: 57680,
    name: "Bag",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.92485,
    longitude: 78.12786,
    wikiDataId: "Q583603",
  },
  {
    id: 57681,
    name: "Dina Nagar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.60837,
    longitude: 76.23199,
    wikiDataId: "Q582278",
  },
  {
    id: 57682,
    name: "Dusi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.79476,
    longitude: 79.31986,
    wikiDataId: "Q653744",
  },
  {
    id: 57683,
    name: "Amod",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.99317,
    longitude: 72.87047,
    wikiDataId: "Q2724262",
  },
  {
    id: 57684,
    name: "Badlapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.93483,
    longitude: 77.75694,
    wikiDataId: "Q477558",
  },
  {
    id: 57685,
    name: "Amreli",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.50789,
    longitude: 71.18323,
    wikiDataId: "Q589163",
  },
  {
    id: 57686,
    name: "Dirba",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.67,
    longitude: 74.84,
    wikiDataId: "Q202822",
  },
  {
    id: 57687,
    name: "Bewar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.90314,
    longitude: 78.46984,
    wikiDataId: "Q584515",
  },
  {
    id: 57688,
    name: "Amroli",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25084,
    longitude: 72.83878,
    wikiDataId: "Q2722516",
  },
  {
    id: 57689,
    name: "Ausa",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.93333,
    longitude: 77.75,
    wikiDataId: "Q269899",
  },
  {
    id: 57690,
    name: "Amudalavalasa",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.41025,
    longitude: 83.90295,
    wikiDataId: "Q582993",
  },
  {
    id: 57691,
    name: "Ashoknagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.42664,
    longitude: 80.03579,
    wikiDataId: "Q583720",
  },
  {
    id: 57692,
    name: "Behat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.71222,
    longitude: 78.73788,
    wikiDataId: "Q582840",
  },
  {
    id: 57693,
    name: "Anakapalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.69134,
    longitude: 83.00395,
    wikiDataId: "Q589133",
  },
  {
    id: 57694,
    name: "Elayirampannai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.58303,
    longitude: 76.93441,
    wikiDataId: "Q584411",
  },
  {
    id: 57695,
    name: "Anand",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.4,
    longitude: 72.75,
    wikiDataId: "Q1798750",
  },
  {
    id: 57696,
    name: "Bhadarsa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.10062,
    longitude: 83.27156,
    wikiDataId: "Q581106",
  },
  {
    id: 57697,
    name: "Doraha",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.23926,
    longitude: 76.50253,
    wikiDataId: "Q589664",
  },
  {
    id: 57698,
    name: "Anantapur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.55,
    longitude: 77.41667,
    wikiDataId: "Q15212",
  },
  {
    id: 57699,
    name: "Banihal",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.73068,
    longitude: 75.15418,
    wikiDataId: "Q11360899",
  },
  {
    id: 57701,
    name: "Nizamabad",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.81458,
    longitude: 78.07713,
    wikiDataId: "Q11360899",
  },
  {
    id: 57702,
    name: "Badami",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.7111,
    longitude: 77.69557,
    wikiDataId: "Q588701",
  },
  {
    id: 57703,
    name: "Chengannur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.19055,
    longitude: 76.38789,
    wikiDataId: "Q588701",
  },
  {
    id: 57704,
    name: "Bhubaneshwar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.84089,
    longitude: 85.10192,
    wikiDataId: "Q518237",
  },
  {
    id: 57705,
    name: "Binka",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.84903,
    longitude: 85.06079,
    wikiDataId: "Q1772807",
  },
  {
    id: 57706,
    name: "Bagli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.04171,
    longitude: 75.05519,
    wikiDataId: "Q585729",
  },
  {
    id: 57707,
    name: "Bagewadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.16516,
    longitude: 77.3091,
    wikiDataId: "Q583982",
  },
  {
    id: 57708,
    name: "Anjaw",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.06549,
    longitude: 96.82878,
    wikiDataId: "Q15413",
  },
  {
    id: 57709,
    name: "Anjar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.11316,
    longitude: 70.02671,
    wikiDataId: "Q1945434",
  },
  {
    id: 57710,
    name: "Ankleshwar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.63236,
    longitude: 72.99001,
    wikiDataId: "Q588924",
  },
  {
    id: 57711,
    name: "Bagalkot",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.66049,
    longitude: 74.3047,
    wikiDataId: "Q583926",
  },
  {
    id: 57712,
    name: "Eraniel",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.4606,
    longitude: 78.70029,
    wikiDataId: "Q589776",
  },
  {
    id: 57713,
    name: "Bagepalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.42513,
    longitude: 75.4335,
    wikiDataId: "Q589122",
  },
  {
    id: 57714,
    name: "Erode",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.23616,
    longitude: 77.10514,
    wikiDataId: "Q585798",
  },
  {
    id: 57715,
    name: "Eral",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.4,
    longitude: 79.73333,
    wikiDataId: "Q585798",
  },
  {
    id: 57716,
    name: "Balapur, Akola district",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.0409,
    longitude: 77.31501,
    wikiDataId: "Q26791820",
  },
  {
    id: 57717,
    name: "Bundi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.15,
    longitude: 76.3,
    wikiDataId: "Q585798",
  },
  {
    id: 57718,
    name: "Erumaippatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.57506,
    longitude: 77.59043,
    wikiDataId: "Q585742",
  },
  {
    id: 57719,
    name: "Baihar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.05804,
    longitude: 78.21027,
    wikiDataId: "Q585742",
  },
  {
    id: 57720,
    name: "Bhadohi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.05654,
    longitude: 81.90267,
    wikiDataId: "Q584960",
  },
  {
    id: 57721,
    name: "Bakshwaho",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.05674,
    longitude: 81.68399,
    wikiDataId: "Q2299093",
  },
  {
    id: 57722,
    name: "Chaksu",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.19111,
    longitude: 73.20861,
    wikiDataId: "Q583742",
  },
  {
    id: 57724,
    name: "Bhagwantnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.35748,
    longitude: 78.26914,
    wikiDataId: "Q585153",
  },
  {
    id: 57725,
    name: "Bharatpura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.27402,
    longitude: 79.16521,
    wikiDataId: "Q581791",
  },
  {
    id: 57726,
    name: "Ettaiyapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.08449,
    longitude: 79.67053,
    wikiDataId: "Q584823",
  },
  {
    id: 57727,
    name: "Arambol",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.68681,
    longitude: 73.70449,
    wikiDataId: "Q584823",
  },
  {
    id: 57728,
    name: "Arang",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.19639,
    longitude: 81.96912,
    wikiDataId: "Q589854",
  },
  {
    id: 57729,
    name: "Gandhi Nagar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.17235,
    longitude: 78.99118,
    wikiDataId: "Q589854",
  },
  {
    id: 57730,
    name: "Araria",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2,
    longitude: 87.4,
    wikiDataId: "Q42901",
  },
  {
    id: 57731,
    name: "Gangaikondan",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.90569,
    longitude: 79.31897,
    wikiDataId: "Q589185",
  },
  {
    id: 57732,
    name: "Gangavalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.25498,
    longitude: 78.88403,
    wikiDataId: "Q582338",
  },
  {
    id: 57733,
    name: "Gingee",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.15,
    longitude: 79.25,
    wikiDataId: "Q15112",
  },
  {
    id: 57735,
    name: "Bail-Hongal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.76171,
    longitude: 76.06035,
    wikiDataId: "Q582261",
  },
  {
    id: 57736,
    name: "Chamba",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.15196,
    longitude: 76.96675,
    wikiDataId: "Q582737",
  },
  {
    id: 57737,
    name: "Gobichettipalayam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.66771,
    longitude: 79.28529,
    wikiDataId: "Q581497",
  },
  {
    id: 57738,
    name: "Cherpulassery",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.8694,
    longitude: 76.30498,
    wikiDataId: "Q588767",
  },
  {
    id: 57739,
    name: "Arrah",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.55629,
    longitude: 84.66335,
    wikiDataId: "Q588587",
  },
  {
    id: 57740,
    name: "Ballari",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.31446,
    longitude: 76.25704,
    wikiDataId: "Q588587",
  },
  {
    id: 57741,
    name: "Bandra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.03227,
    longitude: 73.04276,
    wikiDataId: "Q26794779",
  },
  {
    id: 57742,
    name: "Cherthala",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.86667,
    longitude: 76.35,
    wikiDataId: "Q588587",
  },
  {
    id: 57743,
    name: "Gudalur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.38096,
    longitude: 78.72965,
    wikiDataId: "Q585033",
  },
  {
    id: 57744,
    name: "Gudiyatham",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.5688,
    longitude: 78.09091,
    wikiDataId: "Q582527",
  },
  {
    id: 57745,
    name: "Guduvancheri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.5096,
    longitude: 78.09588,
    wikiDataId: "Q584327",
  },
  {
    id: 57746,
    name: "Gummidipundi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.36315,
    longitude: 76.7579,
    wikiDataId: "Q584458",
  },
  {
    id: 57747,
    name: "Arwal",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.16158,
    longitude: 84.6904,
    wikiDataId: "Q42917",
  },
  {
    id: 57749,
    name: "Asarganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.15046,
    longitude: 86.68639,
    wikiDataId: "Q589912",
  },
  {
    id: 57750,
    name: "Baldeogarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.58,
    longitude: 77.73,
    wikiDataId: "Q2246416",
  },
  {
    id: 57751,
    name: "Bankot",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.94943,
    longitude: 74.40936,
    wikiDataId: "Q588688",
  },
  {
    id: 57752,
    name: "Bamna",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.01754,
    longitude: 76.72208,
    wikiDataId: "Q582291",
  },
  {
    id: 57753,
    name: "Baramati",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.37671,
    longitude: 76.2252,
    wikiDataId: "Q582291",
  },
  {
    id: 57754,
    name: "Paloncha",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.35851,
    longitude: 79.28415,
    wikiDataId: "Q582291",
  },
  {
    id: 57755,
    name: "Bharwari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.28618,
    longitude: 80.57155,
    wikiDataId: "Q585869",
  },
  {
    id: 57756,
    name: "Bara Uchana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.1008,
    longitude: 76.2598,
    wikiDataId: "Q590003",
  },
  {
    id: 57757,
    name: "Ballary",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.72613,
    longitude: 75.06421,
    wikiDataId: "Q583995",
  },
  {
    id: 57758,
    name: "Atmakur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.88109,
    longitude: 78.58704,
    wikiDataId: "Q583995",
  },
  {
    id: 57759,
    name: "Bhimtal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.3333,
    longitude: 82.94727,
    wikiDataId: "Q526685",
  },
  {
    id: 57760,
    name: "Bhaupur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.02964,
    longitude: 78.28571,
    wikiDataId: "Q585063",
  },
  {
    id: 57761,
    name: "Attili",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.7,
    longitude: 81.6,
    wikiDataId: "Q4818468",
  },
  {
    id: 57762,
    name: "Chetwayi",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.69609,
    longitude: 76.81507,
    wikiDataId: "Q534444",
  },
  {
    id: 57763,
    name: "Hosur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.59414,
    longitude: 78.60143,
    wikiDataId: "Q282637",
  },
  {
    id: 57764,
    name: "Bhinga",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.64692,
    longitude: 79.42858,
    wikiDataId: "Q582908",
  },
  {
    id: 57765,
    name: "Bawanbir",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.88467,
    longitude: 75.33986,
    wikiDataId: "Q200713",
  },
  {
    id: 57766,
    name: "Aurangabad",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.75204,
    longitude: 84.3742,
    wikiDataId: "Q590550",
  },
  {
    id: 57767,
    name: "Idappadi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.00549,
    longitude: 79.80885,
    wikiDataId: "Q590361",
  },
  {
    id: 57768,
    name: "Banavar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.25397,
    longitude: 77.41761,
    wikiDataId: "Q582573",
  },
  {
    id: 57769,
    name: "Bhognipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.91414,
    longitude: 80.50792,
    wikiDataId: "Q582879",
  },
  {
    id: 57770,
    name: "Beed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.24728,
    longitude: 76.4993,
    wikiDataId: "Q591243",
  },
  {
    id: 57771,
    name: "Avanigadda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.02148,
    longitude: 80.91808,
    wikiDataId: "Q3430115",
  },
  {
    id: 57772,
    name: "Chittur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.60826,
    longitude: 76.1762,
    wikiDataId: "Q3430115",
  },
  {
    id: 57773,
    name: "Iluppur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.19297,
    longitude: 77.26865,
    wikiDataId: "Q278847",
  },
  {
    id: 57774,
    name: "Baramula",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.91978,
    longitude: 75.01515,
    wikiDataId: "Q278847",
  },
  {
    id: 57775,
    name: "Injambakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.44992,
    longitude: 77.55198,
    wikiDataId: "Q589926",
  },
  {
    id: 57776,
    name: "Irugur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.90141,
    longitude: 79.17984,
    wikiDataId: "Q3631399",
  },
  {
    id: 57777,
    name: "Bidhnu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.06832,
    longitude: 83.18358,
    wikiDataId: "Q526757",
  },
  {
    id: 57778,
    name: "Cochin",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.91524,
    longitude: 75.34761,
    wikiDataId: "Q526757",
  },
  {
    id: 57779,
    name: "Bighapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.54711,
    longitude: 80.70443,
    wikiDataId: "Q590070",
  },
  {
    id: 57780,
    name: "Bijnor",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.26419,
    longitude: 78.4056,
    wikiDataId: "Q589819",
  },
  {
    id: 57781,
    name: "Bikapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.72353,
    longitude: 77.84677,
    wikiDataId: "Q584725",
  },
  {
    id: 57782,
    name: "Bighapur Khurd",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.23947,
    longitude: 78.47028,
    wikiDataId: "Q585789",
  },
  {
    id: 57783,
    name: "Bilari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.92694,
    longitude: 78.23456,
    wikiDataId: "Q582043",
  },
  {
    id: 57784,
    name: "Bilariaganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.4709,
    longitude: 81.1158,
    wikiDataId: "Q582783",
  },
  {
    id: 57785,
    name: "Brahmapur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.11186,
    longitude: 85.38684,
    wikiDataId: "Q582783",
  },
  {
    id: 57786,
    name: "Dharmadam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.59776,
    longitude: 75.58142,
    wikiDataId: "Q2283340",
  },
  {
    id: 57787,
    name: "Badarpur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.86852,
    longitude: 92.59606,
    wikiDataId: "Q2563426",
  },
  {
    id: 57788,
    name: "Bamora",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.97516,
    longitude: 77.5649,
    wikiDataId: "Q583295",
  },
  {
    id: 57789,
    name: "Chaupal",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.95783,
    longitude: 76.79136,
    wikiDataId: "Q462577",
  },
  {
    id: 57790,
    name: "Batoti",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.89001,
    longitude: 74.66297,
    wikiDataId: "Q2594218",
  },
  {
    id: 57791,
    name: "Faridkot",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.6813,
    longitude: 75.29087,
    wikiDataId: "Q585375",
  },
  {
    id: 57792,
    name: "Bhadgaon, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.15516,
    longitude: 73.26553,
    wikiDataId: "Q588612",
  },
  {
    id: 57793,
    name: "Banda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.02181,
    longitude: 75.23268,
    wikiDataId: "Q584989",
  },
  {
    id: 57794,
    name: "Badvel",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.7451,
    longitude: 79.06288,
    wikiDataId: "Q3412838",
  },
  {
    id: 57795,
    name: "Baga",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.56517,
    longitude: 73.75517,
    wikiDataId: "Q3412838",
  },
  {
    id: 57796,
    name: "Bagaha",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.09918,
    longitude: 84.09003,
    wikiDataId: "Q590593",
  },
  {
    id: 57797,
    name: "Bangalore Urban",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.18,
    longitude: 75.69,
    wikiDataId: "Q1910231",
  },
  {
    id: 57798,
    name: "Chhabra",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.18784,
    longitude: 75.50012,
    wikiDataId: "Q1910231",
  },
  {
    id: 57799,
    name: "Bagasra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.48719,
    longitude: 70.95516,
    wikiDataId: "Q589286",
  },
  {
    id: 57800,
    name: "Champadanga",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.97315,
    longitude: 79.83224,
    wikiDataId: "Q1815313",
  },
  {
    id: 57801,
    name: "Bilgram",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.95,
    longitude: 77.2167,
    wikiDataId: "Q624402",
  },
  {
    id: 57802,
    name: "Bundu",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.73333,
    longitude: 86.31667,
    wikiDataId: "Q1797363",
  },
  {
    id: 57803,
    name: "Bilsi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.77416,
    longitude: 79.4974,
    wikiDataId: "Q625710",
  },
  {
    id: 57804,
    name: "Bilthra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.39502,
    longitude: 78.62659,
    wikiDataId: "Q632831",
  },
  {
    id: 57805,
    name: "Binauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.80021,
    longitude: 81.51855,
    wikiDataId: "Q638621",
  },
  {
    id: 57806,
    name: "Binaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.57429,
    longitude: 81.59474,
    wikiDataId: "Q638621",
  },
  {
    id: 57807,
    name: "Bindki",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.20063,
    longitude: 77.97221,
    wikiDataId: "Q287783",
  },
  {
    id: 57808,
    name: "Birdpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.83942,
    longitude: 80.62255,
    wikiDataId: "Q26791613",
  },
  {
    id: 57809,
    name: "Bahadurganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.26172,
    longitude: 87.82443,
    wikiDataId: "Q622603",
  },
  {
    id: 57810,
    name: "Barwala",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.69287,
    longitude: 76.93555,
    wikiDataId: "Q633471",
  },
  {
    id: 57811,
    name: "Bargi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.10133,
    longitude: 80.54967,
    wikiDataId: "Q625392",
  },
  {
    id: 57812,
    name: "Baikunthpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.26206,
    longitude: 82.56051,
    wikiDataId: "Q623920",
  },
  {
    id: 57813,
    name: "Barhi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.72768,
    longitude: 81.40975,
    wikiDataId: "Q623920",
  },
  {
    id: 57814,
    name: "Bannur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.8137,
    longitude: 74.85895,
    wikiDataId: "Q636552",
  },
  {
    id: 57815,
    name: "Bairagnia",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.74063,
    longitude: 85.27323,
    wikiDataId: "Q636552",
  },
  {
    id: 57816,
    name: "Baisi",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.86302,
    longitude: 87.74487,
    wikiDataId: "Q4848696",
  },
  {
    id: 57817,
    name: "Bisanda Buzurg",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.66226,
    longitude: 79.17625,
    wikiDataId: "Q622609",
  },
  {
    id: 57818,
    name: "Bakhtiyarpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.46179,
    longitude: 85.53179,
    wikiDataId: "Q622609",
  },
  {
    id: 57819,
    name: "Fatehgarh Sahib",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.47939,
    longitude: 75.91874,
    wikiDataId: "Q622831",
  },
  {
    id: 57820,
    name: "Baksa",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.69804,
    longitude: 91.15142,
    wikiDataId: "Q622831",
  },
  {
    id: 57821,
    name: "Barwani",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.25106,
    longitude: 79.28618,
    wikiDataId: "Q622831",
  },
  {
    id: 57822,
    name: "Chhapar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.28624,
    longitude: 76.23709,
    wikiDataId: "Q638714",
  },
  {
    id: 57823,
    name: "Buguda",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.49266,
    longitude: 86.93348,
    wikiDataId: "Q641098",
  },
  {
    id: 57824,
    name: "Begamganj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.75619,
    longitude: 79.06715,
    wikiDataId: "Q637094",
  },
  {
    id: 57825,
    name: "Biswan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.40684,
    longitude: 77.82214,
    wikiDataId: "Q637824",
  },
  {
    id: 57826,
    name: "Champua",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.25167,
    longitude: 82.10659,
    wikiDataId: "Q622840",
  },
  {
    id: 57827,
    name: "Bantval",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.15,
    longitude: 76.55,
    wikiDataId: "Q622840",
  },
  {
    id: 57828,
    name: "Bithur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.83333,
    longitude: 84.16667,
    wikiDataId: "Q7180187",
  },
  {
    id: 57829,
    name: "Bhiwandi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.84696,
    longitude: 79.34578,
    wikiDataId: "Q584644",
  },
  {
    id: 57830,
    name: "Balod",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.73081,
    longitude: 81.20578,
    wikiDataId: "Q921009",
  },
  {
    id: 57831,
    name: "Baloda",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.1389,
    longitude: 82.48171,
    wikiDataId: "Q622838",
  },
  {
    id: 57832,
    name: "Baloda Bazar",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.65678,
    longitude: 82.16062,
    wikiDataId: "Q625680",
  },
  {
    id: 57833,
    name: "Budaun",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43449,
    longitude: 82.40281,
    wikiDataId: "Q612328",
  },
  {
    id: 57835,
    name: "Brajarajnagar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75,
    longitude: 83.25,
    wikiDataId: "Q612328",
  },
  {
    id: 57836,
    name: "Bambolim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.46361,
    longitude: 73.8531,
    wikiDataId: "Q627037",
  },
  {
    id: 57837,
    name: "Bamboo Flat",
    state_id: 4023,
    state_code: "AN",
    state_name: "Andaman and Nicobar Islands",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.7,
    longitude: 92.71667,
    wikiDataId: "Q627037",
  },
  {
    id: 57838,
    name: "Beohari",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.09454,
    longitude: 74.76164,
    wikiDataId: "Q627037",
  },
  {
    id: 57839,
    name: "Betma",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.05539,
    longitude: 78.08925,
    wikiDataId: "Q636529",
  },
  {
    id: 57840,
    name: "Budhana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.46355,
    longitude: 77.35478,
    wikiDataId: "Q1320445",
  },
  {
    id: 57841,
    name: "Bulandshahr",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.99132,
    longitude: 80.07608,
    wikiDataId: "Q637818",
  },
  {
    id: 57842,
    name: "Betul",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.04488,
    longitude: 78.96094,
    wikiDataId: "Q637818",
  },
  {
    id: 57843,
    name: "Bhadarwah",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.50404,
    longitude: 74.82832,
    wikiDataId: "Q2983553",
  },
  {
    id: 57844,
    name: "Bijbehara",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.41728,
    longitude: 74.64308,
    wikiDataId: "Q2983553",
  },
  {
    id: 57845,
    name: "Bandora",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.40823,
    longitude: 73.98129,
    wikiDataId: "Q720252",
  },
  {
    id: 57846,
    name: "Firozpur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.18874,
    longitude: 75.99495,
    wikiDataId: "Q3634124",
  },
  {
    id: 57847,
    name: "Belagavi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.22567,
    longitude: 77.57501,
    wikiDataId: "Q806464",
  },
  {
    id: 57848,
    name: "Belluru",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13,
    longitude: 77.58333,
    wikiDataId: "Q806463",
  },
  {
    id: 57849,
    name: "Banganapalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.31771,
    longitude: 78.22669,
    wikiDataId: "Q1553292",
  },
  {
    id: 57850,
    name: "Bangaon",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.86728,
    longitude: 86.51152,
    wikiDataId: "Q5266882",
  },
  {
    id: 57851,
    name: "Beltangadi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.99116,
    longitude: 78.17804,
    wikiDataId: "Q637792",
  },
  {
    id: 57852,
    name: "Bishnah",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.43647,
    longitude: 75.19684,
    wikiDataId: "Q634172",
  },
  {
    id: 57853,
    name: "Chowari",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.639,
    longitude: 77.34055,
    wikiDataId: "Q8180265",
  },
  {
    id: 57854,
    name: "Banka",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.89214,
    longitude: 86.98425,
    wikiDataId: "Q43097",
  },
  {
    id: 57855,
    name: "Banmankhi",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.88857,
    longitude: 87.19421,
    wikiDataId: "Q4856831",
  },
  {
    id: 57856,
    name: "Belur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.33295,
    longitude: 76.86201,
    wikiDataId: "Q622613",
  },
  {
    id: 57857,
    name: "Bengaluru",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.8905,
    longitude: 75.03489,
    wikiDataId: "Q622613",
  },
  {
    id: 57858,
    name: "Banas Kantha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.25,
    longitude: 72.5,
    wikiDataId: "Q622613",
  },
  {
    id: 57859,
    name: "Firozpur District",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.55407,
    longitude: 76.71948,
    wikiDataId: "Q622613",
  },
  {
    id: 57860,
    name: "Bar Bigha",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.21855,
    longitude: 85.7332,
    wikiDataId: "Q622613",
  },
  {
    id: 57861,
    name: "Bawal",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.46747,
    longitude: 76.17798,
    wikiDataId: "Q2557959",
  },
  {
    id: 57862,
    name: "Cuttack",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.33333,
    longitude: 83.61667,
    wikiDataId: "Q808140",
  },
  {
    id: 57863,
    name: "Dariba",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.09,
    longitude: 76.66,
    wikiDataId: "Q808140",
  },
  {
    id: 57864,
    name: "Barauli",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.38109,
    longitude: 84.58648,
    wikiDataId: "Q12440787",
  },
  {
    id: 57865,
    name: "Charthawal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.10199,
    longitude: 77.26334,
    wikiDataId: "Q708505",
  },
  {
    id: 57866,
    name: "Daitari",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.10194,
    longitude: 85.37752,
    wikiDataId: "Q708505",
  },
  {
    id: 57867,
    name: "Chhaprauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.41667,
    longitude: 79.38333,
    wikiDataId: "Q213026",
  },
  {
    id: 57868,
    name: "Betul Bazar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.09678,
    longitude: 80.05084,
    wikiDataId: "Q637110",
  },
  {
    id: 57869,
    name: "Dhenkanal",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.33348,
    longitude: 83.61905,
    wikiDataId: "Q637110",
  },
  {
    id: 57870,
    name: "Bhabhra",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.03065,
    longitude: 79.7328,
    wikiDataId: "Q613463",
  },
  {
    id: 57871,
    name: "Bhainsdehi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.99138,
    longitude: 79.8755,
    wikiDataId: "Q613463",
  },
  {
    id: 57872,
    name: "Bhander",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.90326,
    longitude: 80.81516,
    wikiDataId: "Q709090",
  },
  {
    id: 57873,
    name: "Barhiya",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.28814,
    longitude: 86.02055,
    wikiDataId: "Q709090",
  },
  {
    id: 57874,
    name: "Deshnoke",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.41339,
    longitude: 74.47331,
    wikiDataId: "Q712396",
  },
  {
    id: 57875,
    name: "Bariarpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.28791,
    longitude: 86.57643,
    wikiDataId: "Q712396",
  },
  {
    id: 57876,
    name: "Bahsuma",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.6182,
    longitude: 91.35596,
    wikiDataId: "Q712396",
  },
  {
    id: 57877,
    name: "Chhibramau",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.45209,
    longitude: 79.80655,
    wikiDataId: "Q712381",
  },
  {
    id: 57878,
    name: "Chakradharpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.17594,
    longitude: 85.88938,
    wikiDataId: "Q712381",
  },
  {
    id: 57879,
    name: "Champahati",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.80861,
    longitude: 78.20596,
    wikiDataId: "Q2552248",
  },
  {
    id: 57880,
    name: "Chaibasa",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.62118,
    longitude: 85.46748,
    wikiDataId: "Q2552248",
  },
  {
    id: 57882,
    name: "Gardhiwala",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.37451,
    longitude: 75.5487,
    wikiDataId: "Q711996",
  },
  {
    id: 57883,
    name: "Barpathar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.28709,
    longitude: 93.88844,
    wikiDataId: "Q707805",
  },
  {
    id: 57884,
    name: "Barpeta",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.47104,
    longitude: 91.0308,
    wikiDataId: "Q41249",
  },
  {
    id: 57885,
    name: "Barpeta Road",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.50284,
    longitude: 90.96937,
    wikiDataId: "Q712460",
  },
  {
    id: 57886,
    name: "Digapahandi",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.19005,
    longitude: 83.58721,
    wikiDataId: "Q712508",
  },
  {
    id: 57887,
    name: "Chhutmalpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.64802,
    longitude: 77.3764,
    wikiDataId: "Q712494",
  },
  {
    id: 57888,
    name: "Bhanpura",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.02485,
    longitude: 74.91805,
    wikiDataId: "Q2126754",
  },
  {
    id: 57889,
    name: "Chakulia",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.8478,
    longitude: 84.11049,
    wikiDataId: "Q712480",
  },
  {
    id: 57890,
    name: "Beri Khas",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.36747,
    longitude: 75.90809,
    wikiDataId: "Q712480",
  },
  {
    id: 57892,
    name: "Charkhari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.47554,
    longitude: 78.71224,
    wikiDataId: "Q627748",
  },
  {
    id: 57893,
    name: "Bhadravati",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.87445,
    longitude: 76.94972,
    wikiDataId: "Q627748",
  },
  {
    id: 57894,
    name: "Bhalki",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.57278,
    longitude: 75.97252,
    wikiDataId: "Q627748",
  },
  {
    id: 57895,
    name: "Dhaulpur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.8315,
    longitude: 76.04856,
    wikiDataId: "Q627748",
  },
  {
    id: 57897,
    name: "Bhusaval",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.32872,
    longitude: 77.15746,
    wikiDataId: "Q648370",
  },
  {
    id: 57898,
    name: "Basna",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.27885,
    longitude: 82.8267,
    wikiDataId: "Q708834",
  },
  {
    id: 57899,
    name: "Didwana",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.17232,
    longitude: 73.64519,
    wikiDataId: "Q708834",
  },
  {
    id: 57900,
    name: "Bastar",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.26794,
    longitude: 81.73828,
    wikiDataId: "Q100152",
  },
  {
    id: 57901,
    name: "Chirgaon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.82816,
    longitude: 82.77924,
    wikiDataId: "Q2574579",
  },
  {
    id: 57903,
    name: "Dig",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.14955,
    longitude: 76.58345,
    wikiDataId: "Q2574579",
  },
  {
    id: 57904,
    name: "Gurdaspur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.20747,
    longitude: 74.93893,
    wikiDataId: "Q33424787",
  },
  {
    id: 57905,
    name: "Gandarbal",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.11826,
    longitude: 75.30889,
    wikiDataId: "Q712099",
  },
  {
    id: 57906,
    name: "Garhshankar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.80921,
    longitude: 75.20294,
    wikiDataId: "Q709254",
  },
  {
    id: 57907,
    name: "Ganjam",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83773,
    longitude: 84.32618,
    wikiDataId: "Q303761",
  },
  {
    id: 57908,
    name: "Gopalpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.833,
    longitude: 84.333,
    wikiDataId: "Q2363639",
  },
  {
    id: 57909,
    name: "Bawana",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.7982,
    longitude: 77.03431,
    wikiDataId: "Q760346",
  },
  {
    id: 57910,
    name: "Fatehpur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.90791,
    longitude: 77.28985,
    wikiDataId: "Q760257",
  },
  {
    id: 57911,
    name: "Bedi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.50143,
    longitude: 70.04363,
    wikiDataId: "Q516587",
  },
  {
    id: 57912,
    name: "Bilashi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98921,
    longitude: 75.75634,
    wikiDataId: "Q814033",
  },
  {
    id: 57913,
    name: "Bhikangaon",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.59917,
    longitude: 78.34064,
    wikiDataId: "Q760167",
  },
  {
    id: 57914,
    name: "Ghanaur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.61152,
    longitude: 75.52135,
    wikiDataId: "Q760357",
  },
  {
    id: 57915,
    name: "Begusarai",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.41853,
    longitude: 86.13389,
    wikiDataId: "Q598985",
  },
  {
    id: 57917,
    name: "Ganganagar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.98333,
    longitude: 75,
    wikiDataId: "Q759147",
  },
  {
    id: 57918,
    name: "Chitrakoot",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.1718,
    longitude: 77.6139,
    wikiDataId: "Q757842",
  },
  {
    id: 57919,
    name: "Gulabpura",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.88832,
    longitude: 76.28108,
    wikiDataId: "Q760169",
  },
  {
    id: 57920,
    name: "Chitrakoot Dham",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.92058,
    longitude: 81.99629,
    wikiDataId: "Q760169",
  },
  {
    id: 57921,
    name: "Gudari",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.88249,
    longitude: 84.63801,
    wikiDataId: "Q2444458",
  },
  {
    id: 57922,
    name: "Bhatkal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.33333,
    longitude: 74.75,
    wikiDataId: "Q815464",
  },
  {
    id: 57923,
    name: "Peddapalli",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.05577,
    longitude: 79.493,
    wikiDataId: "Q759677",
  },
  {
    id: 57924,
    name: "Basavakalyan",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.14205,
    longitude: 76.92398,
    wikiDataId: "Q429063",
  },
  {
    id: 57925,
    name: "Bidar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9814,
    longitude: 76.73308,
    wikiDataId: "Q429063",
  },
  {
    id: 57926,
    name: "Bahua",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25178,
    longitude: 91.45407,
    wikiDataId: "Q757844",
  },
  {
    id: 57927,
    name: "Belsand",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.44365,
    longitude: 85.40076,
    wikiDataId: "Q721485",
  },
  {
    id: 57928,
    name: "Bilgi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.98333,
    longitude: 75.3,
    wikiDataId: "Q721485",
  },
  {
    id: 57929,
    name: "Jalakandapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.70752,
    longitude: 78.41437,
    wikiDataId: "Q721485",
  },
  {
    id: 57930,
    name: "Birur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.16558,
    longitude: 75.86519,
    wikiDataId: "Q760603",
  },
  {
    id: 57931,
    name: "Bemetara",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.71556,
    longitude: 81.53423,
    wikiDataId: "Q4885104",
  },
  {
    id: 57932,
    name: "Benaulim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.26435,
    longitude: 73.92812,
    wikiDataId: "Q2564851",
  },
  {
    id: 57933,
    name: "Byadgi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.97194,
    longitude: 77.59369,
    wikiDataId: "Q1355",
  },
  {
    id: 57934,
    name: "Chunar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.29293,
    longitude: 80.44364,
    wikiDataId: "Q2442072",
  },
  {
    id: 57935,
    name: "Bhind",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.02423,
    longitude: 81.37831,
    wikiDataId: "Q2314377",
  },
  {
    id: 57936,
    name: "Bhitarwar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63134,
    longitude: 77.43351,
    wikiDataId: "Q2281620",
  },
  {
    id: 57937,
    name: "Buriya",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.70146,
    longitude: 76.57708,
    wikiDataId: "Q2234154",
  },
  {
    id: 57938,
    name: "Churk",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.63792,
    longitude: 77.88019,
    wikiDataId: "Q2442016",
  },
  {
    id: 57939,
    name: "Betamcherla",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.45144,
    longitude: 78.14797,
    wikiDataId: "Q2442016",
  },
  {
    id: 57940,
    name: "Bhopal",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.68653,
    longitude: 75.61456,
    wikiDataId: "Q757845",
  },
  {
    id: 57941,
    name: "Bettiah",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.80229,
    longitude: 84.50311,
    wikiDataId: "Q759222",
  },
  {
    id: 57942,
    name: "Biaora",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.83333,
    longitude: 77.83333,
    wikiDataId: "Q1815279",
  },
  {
    id: 57943,
    name: "Bijawar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.85572,
    longitude: 77.92913,
    wikiDataId: "Q2314399",
  },
  {
    id: 57944,
    name: "Colonelganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.21869,
    longitude: 79.29761,
    wikiDataId: "Q730492",
  },
  {
    id: 57945,
    name: "Edakkulam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.17151,
    longitude: 75.80611,
    wikiDataId: "Q759539",
  },
  {
    id: 57946,
    name: "Galiakot",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.10119,
    longitude: 74.32028,
    wikiDataId: "Q757855",
  },
  {
    id: 57947,
    name: "Bhabhua",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.04049,
    longitude: 83.60749,
    wikiDataId: "Q759544",
  },
  {
    id: 57948,
    name: "Bhachau",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.29858,
    longitude: 70.34279,
    wikiDataId: "Q759550",
  },
  {
    id: 57949,
    name: "Ganderbal",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.97941,
    longitude: 75.71723,
    wikiDataId: "Q760372",
  },
  {
    id: 57950,
    name: "Giddarbaha",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.47651,
    longitude: 75.33049,
    wikiDataId: "Q757840",
  },
  {
    id: 57951,
    name: "Dalmau",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.39526,
    longitude: 82.5703,
    wikiDataId: "Q759217",
  },
  {
    id: 57952,
    name: "Rajanna Sircilla",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.5546,
    longitude: 80.61976,
    wikiDataId: "Q759217",
  },
  {
    id: 57953,
    name: "Gunupur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21,
    longitude: 86.6,
    wikiDataId: "Q685638",
  },
  {
    id: 57954,
    name: "Hinjilikatu",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.05447,
    longitude: 86.5156,
    wikiDataId: "Q759556",
  },
  {
    id: 57955,
    name: "Quthbullapur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.66846,
    longitude: 80.88887,
    wikiDataId: "Q759204",
  },
  {
    id: 57956,
    name: "Byndoor",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.84846,
    longitude: 75.70502,
    wikiDataId: "Q2284099",
  },
  {
    id: 57957,
    name: "Bhagirathpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2695,
    longitude: 86.06346,
    wikiDataId: "Q2284099",
  },
  {
    id: 57958,
    name: "Dankaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.22383,
    longitude: 80.7575,
    wikiDataId: "Q26791707",
  },
  {
    id: 57959,
    name: "Bodri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.64491,
    longitude: 77.63023,
    wikiDataId: "Q759139",
  },
  {
    id: 57960,
    name: "Ramagundam",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.11285,
    longitude: 77.96336,
    wikiDataId: "Q759999",
  },
  {
    id: 57961,
    name: "Boisar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.18333,
    longitude: 80,
    wikiDataId: "Q33424761",
  },
  {
    id: 57963,
    name: "Hirakud",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.92719,
    longitude: 84.58201,
    wikiDataId: "Q760179",
  },
  {
    id: 57964,
    name: "Jahazpur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.21,
    longitude: 77.29,
    wikiDataId: "Q854846",
  },
  {
    id: 57965,
    name: "Dasna",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75231,
    longitude: 79.2218,
    wikiDataId: "Q662285",
  },
  {
    id: 57966,
    name: "Dataganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.56078,
    longitude: 81.49164,
    wikiDataId: "Q2442005",
  },
  {
    id: 57967,
    name: "Bharuch",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.69482,
    longitude: 72.9805,
    wikiDataId: "Q760153",
  },
  {
    id: 57968,
    name: "Jaipur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.03895,
    longitude: 77.04849,
    wikiDataId: "Q26791337",
  },
  {
    id: 57969,
    name: "Bhatgaon",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.15,
    longitude: 81.7,
    wikiDataId: "Q13403597",
  },
  {
    id: 57970,
    name: "Challakere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.98534,
    longitude: 74.55531,
    wikiDataId: "Q13403597",
  },
  {
    id: 57971,
    name: "Bhattiprolu",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.1026,
    longitude: 80.78074,
    wikiDataId: "Q3429099",
  },
  {
    id: 57972,
    name: "Bhavnagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.76287,
    longitude: 72.15331,
    wikiDataId: "Q242992",
  },
  {
    id: 57973,
    name: "Jalarpet",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.44553,
    longitude: 77.68215,
    wikiDataId: "Q1749241",
  },
  {
    id: 57974,
    name: "Bhawanipur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.45352,
    longitude: 87.02744,
    wikiDataId: "Q4901597",
  },
  {
    id: 57975,
    name: "Chanderi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.41582,
    longitude: 75.83552,
    wikiDataId: "Q4901597",
  },
  {
    id: 57976,
    name: "Jagatsinghapur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.90717,
    longitude: 83.16697,
    wikiDataId: "Q759348",
  },
  {
    id: 57977,
    name: "Guru Har Sahai",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.26685,
    longitude: 76.03854,
    wikiDataId: "Q760176",
  },
  {
    id: 57978,
    name: "Borivli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.30157,
    longitude: 72.85107,
    wikiDataId: "Q4901615",
  },
  {
    id: 57979,
    name: "Brahmapuri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.3007,
    longitude: 74.76701,
    wikiDataId: "Q26791344",
  },
  {
    id: 57980,
    name: "Chandia",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.86764,
    longitude: 75.96391,
    wikiDataId: "Q2314441",
  },
  {
    id: 57981,
    name: "Bhilai",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.20919,
    longitude: 81.4285,
    wikiDataId: "Q242144",
  },
  {
    id: 57982,
    name: "Chandla",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.5,
    longitude: 78.75,
    wikiDataId: "Q2341700",
  },
  {
    id: 57983,
    name: "Jaitaran",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.50235,
    longitude: 74.18551,
    wikiDataId: "Q2341700",
  },
  {
    id: 57984,
    name: "Deoband",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.70283,
    longitude: 81.9343,
    wikiDataId: "Q795811",
  },
  {
    id: 57985,
    name: "Chhatarpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.79216,
    longitude: 78.11085,
    wikiDataId: "Q796805",
  },
  {
    id: 57986,
    name: "Jalore",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.21024,
    longitude: 76.86056,
    wikiDataId: "Q796761",
  },
  {
    id: 57987,
    name: "Breach Candy",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.30023,
    longitude: 73.05881,
    wikiDataId: "Q31856876",
  },
  {
    id: 57988,
    name: "Charkhi Dadri",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.75,
    longitude: 76.16667,
    wikiDataId: "Q1852857",
  },
  {
    id: 57990,
    name: "Hoshiarpur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.55442,
    longitude: 75.64271,
    wikiDataId: "Q796770",
  },
  {
    id: 57991,
    name: "Bhojpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.30886,
    longitude: 84.44504,
    wikiDataId: "Q796770",
  },
  {
    id: 57992,
    name: "Chandil",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63962,
    longitude: 86.44105,
    wikiDataId: "Q795805",
  },
  {
    id: 57993,
    name: "Deoria",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.25515,
    longitude: 79.18118,
    wikiDataId: "Q4901949",
  },
  {
    id: 57994,
    name: "Ramgundam",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.51544,
    longitude: 78.88563,
    wikiDataId: "Q797132",
  },
  {
    id: 57995,
    name: "Chhindwara",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25469,
    longitude: 77.40289,
    wikiDataId: "Q80989",
  },
  {
    id: 57997,
    name: "Chakur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.14861,
    longitude: 73.84336,
    wikiDataId: "Q797462",
  },
  {
    id: 57998,
    name: "Chandrakona",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.38985,
    longitude: 79.50481,
    wikiDataId: "Q796801",
  },
  {
    id: 57999,
    name: "Jagatsinghpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.88197,
    longitude: 85.83334,
    wikiDataId: "Q796742",
  },
  {
    id: 58000,
    name: "Jajpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.27241,
    longitude: 85.83385,
    wikiDataId: "Q171771",
  },
  {
    id: 58001,
    name: "Chalisgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.90742,
    longitude: 74.59954,
    wikiDataId: "Q26791355",
  },
  {
    id: 58002,
    name: "Bhuj",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25397,
    longitude: 69.66928,
    wikiDataId: "Q798382",
  },
  {
    id: 58004,
    name: "Jhalawar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.78333,
    longitude: 74.93333,
    wikiDataId: "Q4902137",
  },
  {
    id: 58005,
    name: "Chandor",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.04365,
    longitude: 75.78506,
    wikiDataId: "Q796650",
  },
  {
    id: 58006,
    name: "Bijrauni",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.53048,
    longitude: 74.32846,
    wikiDataId: "Q796650",
  },
  {
    id: 58007,
    name: "Hindaun",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.10298,
    longitude: 75.17138,
    wikiDataId: "Q777668",
  },
  {
    id: 58008,
    name: "Hanumangarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.31457,
    longitude: 74.28952,
    wikiDataId: "Q777668",
  },
  {
    id: 58009,
    name: "Bhagalpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.29023,
    longitude: 87.06665,
    wikiDataId: "Q49155",
  },
  {
    id: 58010,
    name: "Canacona",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.04348,
    longitude: 77.206,
    wikiDataId: "Q49155",
  },
  {
    id: 58011,
    name: "Burhanpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.73581,
    longitude: 78.74555,
    wikiDataId: "Q759166",
  },
  {
    id: 58012,
    name: "Burhar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.513,
    longitude: 75.7469,
    wikiDataId: "Q674268",
  },
  {
    id: 58013,
    name: "Bhanpuri",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.0919,
    longitude: 80.93218,
    wikiDataId: "Q720123",
  },
  {
    id: 58014,
    name: "Bhanvad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.93053,
    longitude: 69.78081,
    wikiDataId: "Q720917",
  },
  {
    id: 58015,
    name: "Bhatapara",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.735,
    longitude: 81.94711,
    wikiDataId: "Q757847",
  },
  {
    id: 58017,
    name: "Bhayavadar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.85523,
    longitude: 70.24791,
    wikiDataId: "Q759168",
  },
  {
    id: 58018,
    name: "Hariana",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.05918,
    longitude: 75.535,
    wikiDataId: "Q2546717",
  },
  {
    id: 58019,
    name: "Jaisalmer",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 74.75,
    wikiDataId: "Q41991",
  },
  {
    id: 58020,
    name: "Chandannagar",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.34447,
    longitude: 79.56336,
    wikiDataId: "Q41991",
  },
  {
    id: 58021,
    name: "Bhimavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.54078,
    longitude: 81.52322,
    wikiDataId: "Q757853",
  },
  {
    id: 58022,
    name: "Bhimunipatnam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.89017,
    longitude: 83.45203,
    wikiDataId: "Q2483868",
  },
  {
    id: 58023,
    name: "Jalor",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.99944,
    longitude: 72.27141,
    wikiDataId: "Q797437",
  },
  {
    id: 58024,
    name: "Chandgad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.45908,
    longitude: 75.65877,
    wikiDataId: "Q796520",
  },
  {
    id: 58025,
    name: "Chichli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.9205,
    longitude: 76.91074,
    wikiDataId: "Q796769",
  },
  {
    id: 58026,
    name: "Chandur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.83333,
    longitude: 75.75,
    wikiDataId: "Q814037",
  },
  {
    id: 58027,
    name: "Dewa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.80172,
    longitude: 79.50829,
    wikiDataId: "Q796775",
  },
  {
    id: 58028,
    name: "Bihpuriagaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.01718,
    longitude: 93.91673,
    wikiDataId: "Q796775",
  },
  {
    id: 58029,
    name: "Bihar Sharif",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.20084,
    longitude: 85.52389,
    wikiDataId: "Q2308343",
  },
  {
    id: 58030,
    name: "Bihariganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.73415,
    longitude: 86.98837,
    wikiDataId: "Q4907104",
  },
  {
    id: 58031,
    name: "Aluva",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.82442,
    longitude: 75.71537,
    wikiDataId: "Q3636023",
  },
  {
    id: 58032,
    name: "Bijapur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.84322,
    longitude: 80.7761,
    wikiDataId: "Q100164",
  },
  {
    id: 58033,
    name: "Gho Brahmanan de",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.79378,
    longitude: 75.107,
    wikiDataId: "Q100164",
  },
  {
    id: 58034,
    name: "Bijni",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.49588,
    longitude: 90.70298,
    wikiDataId: "Q797126",
  },
  {
    id: 58035,
    name: "Dhanghata",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.41667,
    longitude: 78.51667,
    wikiDataId: "Q796664",
  },
  {
    id: 58036,
    name: "Daboh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.93296,
    longitude: 77.64352,
    wikiDataId: "Q1937865",
  },
  {
    id: 58038,
    name: "Chorhat",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.62351,
    longitude: 79.48994,
    wikiDataId: "Q797222",
  },
  {
    id: 58039,
    name: "Bikramganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.21073,
    longitude: 84.25508,
    wikiDataId: "Q796526",
  },
  {
    id: 58040,
    name: "Dibai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.19593,
    longitude: 83.2269,
    wikiDataId: "Q795717",
  },
  {
    id: 58041,
    name: "Chuari Khas",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.33027,
    longitude: 76.75663,
    wikiDataId: "Q795717",
  },
  {
    id: 58042,
    name: "Channagiri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.34714,
    longitude: 75.61804,
    wikiDataId: "Q796485",
  },
  {
    id: 58043,
    name: "Dohrighat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.17509,
    longitude: 80.03201,
    wikiDataId: "Q795814",
  },
  {
    id: 58044,
    name: "Domariaganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.84345,
    longitude: 80.06388,
    wikiDataId: "Q858170",
  },
  {
    id: 58045,
    name: "Bilimora",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.76957,
    longitude: 72.96134,
    wikiDataId: "Q862141",
  },
  {
    id: 58046,
    name: "Bilkha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.4415,
    longitude: 70.60063,
    wikiDataId: "Q11909377",
  },
  {
    id: 58047,
    name: "Chembur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.77385,
    longitude: 77.72463,
    wikiDataId: "Q861330",
  },
  {
    id: 58048,
    name: "Dostpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.24341,
    longitude: 79.95135,
    wikiDataId: "Q861856",
  },
  {
    id: 58049,
    name: "Dudhi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.12941,
    longitude: 78.9109,
    wikiDataId: "Q859148",
  },
  {
    id: 58050,
    name: "Etah",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.12705,
    longitude: 83.89148,
    wikiDataId: "Q859148",
  },
  {
    id: 58051,
    name: "Jhunjhunun",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.18067,
    longitude: 73.7055,
    wikiDataId: "Q796497",
  },
  {
    id: 58052,
    name: "Dhaurahra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.62146,
    longitude: 78.80361,
    wikiDataId: "Q796749",
  },
  {
    id: 58053,
    name: "Bilasipara",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.23285,
    longitude: 90.2341,
    wikiDataId: "Q795803",
  },
  {
    id: 58054,
    name: "Chhachhrauli",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.3045,
    longitude: 77.30424,
    wikiDataId: "Q3639927",
  },
  {
    id: 58055,
    name: "Bilaspur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.38333,
    longitude: 82.13333,
    wikiDataId: "Q100157",
  },
  {
    id: 58057,
    name: "Faizabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.03613,
    longitude: 80.57617,
    wikiDataId: "Q858148",
  },
  {
    id: 58058,
    name: "Jaleshwar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.02626,
    longitude: 83.81197,
    wikiDataId: "Q860226",
  },
  {
    id: 58059,
    name: "Chittaranjan",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.0712,
    longitude: 78.28189,
    wikiDataId: "Q860226",
  },
  {
    id: 58060,
    name: "Jatani",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.4,
    longitude: 84.76667,
    wikiDataId: "Q860226",
  },
  {
    id: 58061,
    name: "Channapatna",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.59723,
    longitude: 75.97167,
    wikiDataId: "Q861119",
  },
  {
    id: 58062,
    name: "Fatehganj West",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.30772,
    longitude: 78.93678,
    wikiDataId: "Q862048",
  },
  {
    id: 58063,
    name: "Fatehgarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.4035,
    longitude: 80.61889,
    wikiDataId: "Q859094",
  },
  {
    id: 58064,
    name: "South West Garo Hills",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.60769,
    longitude: 93.77998,
    wikiDataId: "Q859094",
  },
  {
    id: 58065,
    name: "Hajan",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.6106,
    longitude: 74.85557,
    wikiDataId: "Q861136",
  },
  {
    id: 58066,
    name: "Fatehpur (Barabanki)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.25914,
    longitude: 83.11643,
    wikiDataId: "Q2606051",
  },
  {
    id: 58067,
    name: "Jobner",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.24737,
    longitude: 75.07666,
    wikiDataId: "Q739697",
  },
  {
    id: 58068,
    name: "Fatehpur Chaurasi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.49581,
    longitude: 80.99618,
    wikiDataId: "Q858291",
  },
  {
    id: 58069,
    name: "Fatehpur Sikri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.60664,
    longitude: 80.27098,
    wikiDataId: "Q858291",
  },
  {
    id: 58070,
    name: "Bobbili",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.57366,
    longitude: 83.35925,
    wikiDataId: "Q861841",
  },
  {
    id: 58071,
    name: "Rangareddi",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.66208,
    longitude: 77.88581,
    wikiDataId: "Q859167",
  },
  {
    id: 58072,
    name: "Jayamkondacholapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.01171,
    longitude: 77.34976,
    wikiDataId: "Q859153",
  },
  {
    id: 58073,
    name: "Dabra",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.16524,
    longitude: 81.43262,
    wikiDataId: "Q858405",
  },
  {
    id: 58074,
    name: "Chicholi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.80362,
    longitude: 72.75598,
    wikiDataId: "Q858592",
  },
  {
    id: 58075,
    name: "Bokajan",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.02131,
    longitude: 93.77945,
    wikiDataId: "Q633816",
  },
  {
    id: 58076,
    name: "Chas",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.68562,
    longitude: 85.99026,
    wikiDataId: "Q2295925",
  },
  {
    id: 58077,
    name: "Bokakhat",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.64018,
    longitude: 93.60052,
    wikiDataId: "Q859160",
  },
  {
    id: 58079,
    name: "Jeypore",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.11312,
    longitude: 85.33645,
    wikiDataId: "Q1926941",
  },
  {
    id: 58080,
    name: "Bomdila",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.26475,
    longitude: 92.42472,
    wikiDataId: "Q859141",
  },
  {
    id: 58081,
    name: "Bongaigaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.4603,
    longitude: 90.6464,
    wikiDataId: "Q42197",
  },
  {
    id: 58082,
    name: "Chikhli (Jalna)",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.23496,
    longitude: 72.85976,
    wikiDataId: "Q4945504",
  },
  {
    id: 58083,
    name: "Kaman",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.52115,
    longitude: 75.64028,
    wikiDataId: "Q4945504",
  },
  {
    id: 58084,
    name: "Borsad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.40788,
    longitude: 72.89817,
    wikiDataId: "Q858219",
  },
  {
    id: 58085,
    name: "Botad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.16917,
    longitude: 71.66671,
    wikiDataId: "Q858162",
  },
  {
    id: 58086,
    name: "Jharsuguda",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.31151,
    longitude: 84.7929,
    wikiDataId: "Q860210",
  },
  {
    id: 58087,
    name: "Jharsuguda District",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.81667,
    longitude: 83.91667,
    wikiDataId: "Q860215",
  },
  {
    id: 58088,
    name: "Firozabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.11667,
    longitude: 78.98333,
    wikiDataId: "Q798839",
  },
  {
    id: 58089,
    name: "Buddh Gaya",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.69808,
    longitude: 84.9869,
    wikiDataId: "Q176767",
  },
  {
    id: 58090,
    name: "Jagraon",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.92799,
    longitude: 75.56205,
    wikiDataId: "Q783187",
  },
  {
    id: 58091,
    name: "Fyzabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.28805,
    longitude: 77.47534,
    wikiDataId: "Q861968",
  },
  {
    id: 58092,
    name: "Kaintragarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.80806,
    longitude: 84.79084,
    wikiDataId: "Q858514",
  },
  {
    id: 58093,
    name: "Gahlon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.41667,
    longitude: 77.83333,
    wikiDataId: "Q861248",
  },
  {
    id: 58094,
    name: "Chinchpokli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.58333,
    longitude: 76.41667,
    wikiDataId: "Q1752328",
  },
  {
    id: 58096,
    name: "Damoh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.31,
    longitude: 76.23,
    wikiDataId: "Q2125592",
  },
  {
    id: 58097,
    name: "Datia",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.21494,
    longitude: 81.53204,
    wikiDataId: "Q861346",
  },
  {
    id: 58099,
    name: "Kalahandi",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.50976,
    longitude: 83.87259,
    wikiDataId: "Q1937513",
  },
  {
    id: 58100,
    name: "Buxar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 84.1,
    wikiDataId: "Q49161",
  },
  {
    id: 58101,
    name: "Chik Ballapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.86667,
    longitude: 74.63333,
    wikiDataId: "Q3595293",
  },
  {
    id: 58102,
    name: "Channarayapatna",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.67325,
    longitude: 75.4868,
    wikiDataId: "Q858385",
  },
  {
    id: 58103,
    name: "Bamor Kalan",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.70256,
    longitude: 77.93494,
    wikiDataId: "Q765367",
  },
  {
    id: 58104,
    name: "Babra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.84577,
    longitude: 71.30544,
    wikiDataId: "Q8239185",
  },
  {
    id: 58105,
    name: "Bangalore Rural",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.91495,
    longitude: 75.67683,
    wikiDataId: "Q8239185",
  },
  {
    id: 58106,
    name: "Barela",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.35905,
    longitude: 74.79052,
    wikiDataId: "Q1924964",
  },
  {
    id: 58107,
    name: "Bangarapet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.78338,
    longitude: 77.79667,
    wikiDataId: "Q633164",
  },
  {
    id: 58109,
    name: "Fatehgarh Churian",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.68809,
    longitude: 75.09838,
    wikiDataId: "Q625714",
  },
  {
    id: 58111,
    name: "Barghat",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.64124,
    longitude: 76.34877,
    wikiDataId: "Q2719910",
  },
  {
    id: 58112,
    name: "Bilsanda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.86912,
    longitude: 78.59385,
    wikiDataId: "Q633132",
  },
  {
    id: 58113,
    name: "Bisalpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.89793,
    longitude: 77.67836,
    wikiDataId: "Q2426646",
  },
  {
    id: 58114,
    name: "Burla",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.5,
    longitude: 86.75,
    wikiDataId: "Q2022279",
  },
  {
    id: 58115,
    name: "Chhoti Sadri",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.19725,
    longitude: 73.29117,
    wikiDataId: "Q632862",
  },
  {
    id: 58116,
    name: "Chidawa",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.83242,
    longitude: 72.24,
    wikiDataId: "Q638695",
  },
  {
    id: 58117,
    name: "Chandbali",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.17838,
    longitude: 85.11327,
    wikiDataId: "Q632799",
  },
  {
    id: 58118,
    name: "Fazilka",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.06062,
    longitude: 76.30166,
    wikiDataId: "Q632799",
  },
  {
    id: 58119,
    name: "Basoda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.96667,
    longitude: 80.33333,
    wikiDataId: "Q641904",
  },
  {
    id: 58120,
    name: "Palwancha",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.31018,
    longitude: 78.49969,
    wikiDataId: "Q641904",
  },
  {
    id: 58121,
    name: "Bhayandar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.66612,
    longitude: 76.77386,
    wikiDataId: "Q2315612",
  },
  {
    id: 58122,
    name: "Berasia",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.89298,
    longitude: 78.15105,
    wikiDataId: "Q622611",
  },
  {
    id: 58123,
    name: "Chatrapur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.77889,
    longitude: 85.17033,
    wikiDataId: "Q632773",
  },
  {
    id: 58124,
    name: "Captainganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 80.5,
    wikiDataId: "Q2575623",
  },
  {
    id: 58125,
    name: "Chittaurgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.05087,
    longitude: 76.57325,
    wikiDataId: "Q629203",
  },
  {
    id: 58126,
    name: "Chail",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.8912,
    longitude: 80.21149,
    wikiDataId: "Q607333",
  },
  {
    id: 58128,
    name: "Chikitigarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.37912,
    longitude: 85.52953,
    wikiDataId: "Q2563333",
  },
  {
    id: 58129,
    name: "Chittarkonda",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.24834,
    longitude: 84.81044,
    wikiDataId: "Q2563333",
  },
  {
    id: 58130,
    name: "Chandauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.88377,
    longitude: 84.21827,
    wikiDataId: "Q637106",
  },
  {
    id: 58131,
    name: "Chandauli District",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.55032,
    longitude: 83.34503,
    wikiDataId: "Q625687",
  },
  {
    id: 58132,
    name: "Chandausi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.17749,
    longitude: 82.93442,
    wikiDataId: "Q637722",
  },
  {
    id: 58133,
    name: "Patancheru",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.37725,
    longitude: 77.88007,
    wikiDataId: "Q3428196",
  },
  {
    id: 58134,
    name: "Churu",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.54109,
    longitude: 74.4425,
    wikiDataId: "Q633308",
  },
  {
    id: 58135,
    name: "Bantva",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.48815,
    longitude: 70.07576,
    wikiDataId: "Q625718",
  },
  {
    id: 58136,
    name: "Basavana Bagevadi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.41029,
    longitude: 76.16314,
    wikiDataId: "Q625718",
  },
  {
    id: 58137,
    name: "Bapatla",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.90422,
    longitude: 80.46743,
    wikiDataId: "Q633274",
  },
  {
    id: 58138,
    name: "Chanduasi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.93864,
    longitude: 81.3274,
    wikiDataId: "Q633274",
  },
  {
    id: 58139,
    name: "Doda",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.19287,
    longitude: 74.3692,
    wikiDataId: "Q633274",
  },
  {
    id: 58140,
    name: "Bardoli",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.12297,
    longitude: 73.11151,
    wikiDataId: "Q712003",
  },
  {
    id: 58141,
    name: "Barh",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.48339,
    longitude: 85.70928,
    wikiDataId: "Q709313",
  },
  {
    id: 58142,
    name: "Dausa",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.64661,
    longitude: 77.61634,
    wikiDataId: "Q707848",
  },
  {
    id: 58143,
    name: "Devgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 71.5,
    wikiDataId: "Q42016",
  },
  {
    id: 58144,
    name: "Bhum",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.23454,
    longitude: 75.69275,
    wikiDataId: "Q42016",
  },
  {
    id: 58145,
    name: "Baruni",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.47509,
    longitude: 85.96813,
    wikiDataId: "Q42016",
  },
  {
    id: 58146,
    name: "Bhugaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.15174,
    longitude: 74.57767,
    wikiDataId: "Q712015",
  },
  {
    id: 58148,
    name: "Bhawaniganj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.85153,
    longitude: 77.93652,
    wikiDataId: "Q714617",
  },
  {
    id: 58149,
    name: "Gajapati",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.11974,
    longitude: 86.72896,
    wikiDataId: "Q712632",
  },
  {
    id: 58150,
    name: "Basugaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.46742,
    longitude: 90.41951,
    wikiDataId: "Q712428",
  },
  {
    id: 58151,
    name: "Basar",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.99008,
    longitude: 94.69451,
    wikiDataId: "Q712428",
  },
  {
    id: 58152,
    name: "Bhiwani",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.07184,
    longitude: 76.58312,
    wikiDataId: "Q759164",
  },
  {
    id: 58153,
    name: "Champdani",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.15299,
    longitude: 79.10814,
    wikiDataId: "Q759164",
  },
  {
    id: 58154,
    name: "Chamrajnagar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.08333,
    longitude: 77.33333,
    wikiDataId: "Q1790568",
  },
  {
    id: 58155,
    name: "Dhanaura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.34734,
    longitude: 80.65698,
    wikiDataId: "Q796734",
  },
  {
    id: 58156,
    name: "Jhalrapatan",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.01762,
    longitude: 73.31495,
    wikiDataId: "Q200718",
  },
  {
    id: 58158,
    name: "Dharau",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.59534,
    longitude: 82.13272,
    wikiDataId: "Q795718",
  },
  {
    id: 58159,
    name: "Divrasai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.88655,
    longitude: 79.2703,
    wikiDataId: "Q795718",
  },
  {
    id: 58160,
    name: "Birpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.50823,
    longitude: 87.01194,
    wikiDataId: "Q795718",
  },
  {
    id: 58161,
    name: "Faridpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.29253,
    longitude: 79.80472,
    wikiDataId: "Q858297",
  },
  {
    id: 58162,
    name: "Kanor",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.43855,
    longitude: 75.63735,
    wikiDataId: "Q862612",
  },
  {
    id: 58163,
    name: "Chatra",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.16095,
    longitude: 85.59007,
    wikiDataId: "Q862612",
  },
  {
    id: 58164,
    name: "Dabwali",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.15911,
    longitude: 77.35814,
    wikiDataId: "Q862612",
  },
  {
    id: 58165,
    name: "Calangute",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.5439,
    longitude: 73.7553,
    wikiDataId: "Q861976",
  },
  {
    id: 58166,
    name: "Chikkaballapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.9959,
    longitude: 74.05056,
    wikiDataId: "Q861976",
  },
  {
    id: 58167,
    name: "Candolim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.51807,
    longitude: 73.76259,
    wikiDataId: "Q858424",
  },
  {
    id: 58168,
    name: "Gahmar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.9264,
    longitude: 83.71334,
    wikiDataId: "Q2544447",
  },
  {
    id: 58169,
    name: "Carapur",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.56588,
    longitude: 73.98713,
    wikiDataId: "Q861379",
  },
  {
    id: 58170,
    name: "Cavelossim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.17255,
    longitude: 73.94194,
    wikiDataId: "Q5054993",
  },
  {
    id: 58171,
    name: "Central Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.64857,
    longitude: 77.21895,
    wikiDataId: "Q107941",
  },
  {
    id: 58172,
    name: "Gaini",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.42654,
    longitude: 81.63198,
    wikiDataId: "Q3665740",
  },
  {
    id: 58173,
    name: "Gajraula",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.04891,
    longitude: 83.22155,
    wikiDataId: "Q3665752",
  },
  {
    id: 58174,
    name: "Chaklasi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.6532,
    longitude: 72.94497,
    wikiDataId: "Q607388",
  },
  {
    id: 58175,
    name: "Daltonganj",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.67611,
    longitude: 85.62892,
    wikiDataId: "Q278913",
  },
  {
    id: 58176,
    name: "Churulia",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.70369,
    longitude: 77.86386,
    wikiDataId: "Q858555",
  },
  {
    id: 58177,
    name: "Kapren",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.6051,
    longitude: 75.94814,
    wikiDataId: "Q861891",
  },
  {
    id: 58178,
    name: "Chikkamagaluru",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.318,
    longitude: 76.65165,
    wikiDataId: "Q2340109",
  },
  {
    id: 58179,
    name: "Challapalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.11756,
    longitude: 80.93139,
    wikiDataId: "Q2340109",
  },
  {
    id: 58180,
    name: "Chalala",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.41073,
    longitude: 71.16621,
    wikiDataId: "Q858287",
  },
  {
    id: 58181,
    name: "Dagshai",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.57147,
    longitude: 76.10229,
    wikiDataId: "Q1060614",
  },
  {
    id: 58182,
    name: "Contai",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.5,
    longitude: 79.5,
    wikiDataId: "Q1797372",
  },
  {
    id: 58183,
    name: "Cooch Behar",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.28756,
    longitude: 80.03737,
    wikiDataId: "Q1797372",
  },
  {
    id: 58184,
    name: "Asika",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.47444,
    longitude: 93.32556,
    wikiDataId: "Q1965256",
  },
  {
    id: 58185,
    name: "Chiknayakanhalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.96,
    longitude: 77.09,
    wikiDataId: "Q1965256",
  },
  {
    id: 58186,
    name: "Gangoh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.25803,
    longitude: 83.26825,
    wikiDataId: "Q2733368",
  },
  {
    id: 58187,
    name: "Ganj Dundawara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.26134,
    longitude: 83.26408,
    wikiDataId: "Q2733368",
  },
  {
    id: 58188,
    name: "Deori Khas",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.71312,
    longitude: 78.13809,
    wikiDataId: "Q860192",
  },
  {
    id: 58189,
    name: "Depalpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.65647,
    longitude: 80.70911,
    wikiDataId: "Q2446932",
  },
  {
    id: 58190,
    name: "Chandigarh",
    state_id: 4031,
    state_code: "CH",
    state_name: "Chandigarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.73629,
    longitude: 76.7884,
    wikiDataId: "Q2446932",
  },
  {
    id: 58191,
    name: "Dewas",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.07148,
    longitude: 80.19294,
    wikiDataId: "Q858413",
  },
  {
    id: 58192,
    name: "Darwha",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.11793,
    longitude: 79.44377,
    wikiDataId: "Q861194",
  },
  {
    id: 58193,
    name: "Garautha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.45178,
    longitude: 78.78277,
    wikiDataId: "Q858672",
  },
  {
    id: 58194,
    name: "Sadaseopet",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.87455,
    longitude: 78.10017,
    wikiDataId: "Q721388",
  },
  {
    id: 58195,
    name: "Elur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.44203,
    longitude: 76.53604,
    wikiDataId: "Q721388",
  },
  {
    id: 58196,
    name: "Changlang",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.36265,
    longitude: 96.34518,
    wikiDataId: "Q15427",
  },
  {
    id: 58197,
    name: "Chikodi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.02399,
    longitude: 75.92577,
    wikiDataId: "Q15427",
  },
  {
    id: 58198,
    name: "Chincholi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.65143,
    longitude: 77.20672,
    wikiDataId: "Q721354",
  },
  {
    id: 58199,
    name: "Chintamani",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.90642,
    longitude: 76.38775,
    wikiDataId: "Q886632",
  },
  {
    id: 58200,
    name: "Dharuhera",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.59166,
    longitude: 76.27161,
    wikiDataId: "Q888946",
  },
  {
    id: 58201,
    name: "Garhi Pukhta",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.40304,
    longitude: 79.74877,
    wikiDataId: "Q886602",
  },
  {
    id: 131509,
    name: "Garhmuktesar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.54687,
    longitude: 77.59438,
    wikiDataId: "",
  },
  {
    id: 131510,
    name: "Kantabanji",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.35574,
    longitude: 84.98359,
    wikiDataId: "Q656584",
  },
  {
    id: 131511,
    name: "Dugda",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.20645,
    longitude: 84.87085,
    wikiDataId: "Q5087769",
  },
  {
    id: 131512,
    name: "Dalhousie",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.94647,
    longitude: 77.5884,
    wikiDataId: "Q2451092",
  },
  {
    id: 131513,
    name: "Karanpur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.76667,
    longitude: 75.88333,
    wikiDataId: "Q885611",
  },
  {
    id: 131514,
    name: "Chemmumiahpet",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.89794,
    longitude: 79.32129,
    wikiDataId: "Q886385",
  },
  {
    id: 131515,
    name: "Kadambur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.30889,
    longitude: 78.79137,
    wikiDataId: "Q888598",
  },
  {
    id: 131516,
    name: "Ernakulam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.31575,
    longitude: 76.61513,
    wikiDataId: "Q888384",
  },
  {
    id: 131517,
    name: "Kadayanallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.08784,
    longitude: 80.27847,
    wikiDataId: "Q1352",
  },
  {
    id: 131518,
    name: "Kalakkadu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.16378,
    longitude: 77.60388,
    wikiDataId: "Q888377",
  },
  {
    id: 131519,
    name: "Ferokh",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.87655,
    longitude: 76.30932,
    wikiDataId: "Q888377",
  },
  {
    id: 131520,
    name: "Lunglei",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.30089,
    longitude: 91.69619,
    wikiDataId: "Q211030",
  },
  {
    id: 131521,
    name: "Guruvayur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.68444,
    longitude: 76.33558,
    wikiDataId: "Q888665",
  },
  {
    id: 131522,
    name: "Kalavai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.07,
    longitude: 80.24083,
    wikiDataId: "Q888665",
  },
  {
    id: 131523,
    name: "Kallakkurichchi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.91248,
    longitude: 77.03699,
    wikiDataId: "Q2443984",
  },
  {
    id: 131524,
    name: "Idukki",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.52885,
    longitude: 76.04793,
    wikiDataId: "Q888436",
  },
  {
    id: 131525,
    name: "Kallidaikurichi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.66052,
    longitude: 79.54308,
    wikiDataId: "Q5094634",
  },
  {
    id: 131526,
    name: "Kallupatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.34948,
    longitude: 80.00304,
    wikiDataId: "Q5308120",
  },
  {
    id: 131527,
    name: "Karauli",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.66472,
    longitude: 76.84379,
    wikiDataId: "Q888797",
  },
  {
    id: 131528,
    name: "Ellenabad",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.24492,
    longitude: 77.36027,
    wikiDataId: "Q885359",
  },
  {
    id: 131529,
    name: "Chhala",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.30779,
    longitude: 72.77404,
    wikiDataId: "Q2757294",
  },
  {
    id: 131530,
    name: "Gauriganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.20989,
    longitude: 77.17454,
    wikiDataId: "Q888805",
  },
  {
    id: 131531,
    name: "Gautam Buddha Nagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.9247,
    longitude: 78.40102,
    wikiDataId: "Q26791112",
  },
  {
    id: 131532,
    name: "Dhamnod",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.75,
    longitude: 79.75,
    wikiDataId: "Q2449785",
  },
  {
    id: 131533,
    name: "Ghatampur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.14872,
    longitude: 79.50078,
    wikiDataId: "Q985000",
  },
  {
    id: 131534,
    name: "Dhana",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.05697,
    longitude: 78.93958,
    wikiDataId: "Q986135",
  },
  {
    id: 131535,
    name: "Chhota Udepur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.30401,
    longitude: 74.0158,
    wikiDataId: "Q612721",
  },
  {
    id: 131536,
    name: "Keshorai Patan",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.38145,
    longitude: 74.7012,
    wikiDataId: "Q986875",
  },
  {
    id: 131537,
    name: "Ghazipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.03209,
    longitude: 77.75329,
    wikiDataId: "Q1105774",
  },
  {
    id: 131538,
    name: "Chhuikhadan",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.52316,
    longitude: 80.99788,
    wikiDataId: "Q1105863",
  },
  {
    id: 131539,
    name: "Kekri",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.819,
    longitude: 74.43936,
    wikiDataId: "Q888928",
  },
  {
    id: 131540,
    name: "Gawan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.72374,
    longitude: 77.5081,
    wikiDataId: "Q888816",
  },
  {
    id: 131541,
    name: "Chhatapur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.21965,
    longitude: 87.00479,
    wikiDataId: "Q888816",
  },
  {
    id: 131542,
    name: "Chicalim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.39835,
    longitude: 73.84216,
    wikiDataId: "Q1105928",
  },
  {
    id: 131543,
    name: "Dhar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.83363,
    longitude: 78.82611,
    wikiDataId: "Q1104216",
  },
  {
    id: 131544,
    name: "Deccan Gymkhana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.46926,
    longitude: 79.70151,
    wikiDataId: "Q1104151",
  },
  {
    id: 131545,
    name: "Kalugumalai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.39933,
    longitude: 79.69144,
    wikiDataId: "Q1104151",
  },
  {
    id: 131546,
    name: "Khandela",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.23937,
    longitude: 75.64035,
    wikiDataId: "Q1190810",
  },
  {
    id: 131547,
    name: "Chitapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.43512,
    longitude: 77.72787,
    wikiDataId: "Q1023689",
  },
  {
    id: 131548,
    name: "Chikhli",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75751,
    longitude: 73.06268,
    wikiDataId: "Q1945471",
  },
  {
    id: 131549,
    name: "Dehu",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.35046,
    longitude: 76.25774,
    wikiDataId: "Q3674596",
  },
  {
    id: 131550,
    name: "Kantilo",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.20233,
    longitude: 84.6145,
    wikiDataId: "Q1105855",
  },
  {
    id: 131551,
    name: "Chitradurga",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.55,
    longitude: 77.87,
    wikiDataId: "Q1072629",
  },
  {
    id: 131552,
    name: "Closepet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.49,
    longitude: 75.73,
    wikiDataId: "Q743077",
  },
  {
    id: 131554,
    name: "Coondapoor",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.41609,
    longitude: 76.62063,
    wikiDataId: "Q1105843",
  },
  {
    id: 131555,
    name: "Dakshina Kannada",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.42898,
    longitude: 74.58591,
    wikiDataId: "Q1105246",
  },
  {
    id: 131556,
    name: "Chilakalurupet",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.08987,
    longitude: 80.16705,
    wikiDataId: "Q1105252",
  },
  {
    id: 131557,
    name: "Ghiror",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.28221,
    longitude: 83.5064,
    wikiDataId: "Q709260",
  },
  {
    id: 131558,
    name: "Deoni",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.87458,
    longitude: 72.6851,
    wikiDataId: "Q1104169",
  },
  {
    id: 131559,
    name: "Chinchinim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.21447,
    longitude: 73.97555,
    wikiDataId: "Q1105849",
  },
  {
    id: 131560,
    name: "Dandeli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.46508,
    longitude: 77.41874,
    wikiDataId: "Q1105849",
  },
  {
    id: 131561,
    name: "Kamuthi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.69184,
    longitude: 79.97661,
    wikiDataId: "Q888769",
  },
  {
    id: 131562,
    name: "Kanadukattan",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.63422,
    longitude: 78.87412,
    wikiDataId: "Q130577",
  },
  {
    id: 131563,
    name: "Chinnachowk",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.47516,
    longitude: 78.8354,
    wikiDataId: "Q721289",
  },
  {
    id: 131564,
    name: "Kancheepuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.83999,
    longitude: 77.38109,
    wikiDataId: "Q1104025",
  },
  {
    id: 131565,
    name: "Kanchipuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.16089,
    longitude: 80.25727,
    wikiDataId: "Q1103998",
  },
  {
    id: 131566,
    name: "Davanagere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.40051,
    longitude: 78.05172,
    wikiDataId: "Q5766625",
  },
  {
    id: 131567,
    name: "Devrukh",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.53339,
    longitude: 73.50935,
    wikiDataId: "Q1117869",
  },
  {
    id: 131568,
    name: "Chirang",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.52527,
    longitude: 90.49066,
    wikiDataId: "Q2574898",
  },
  {
    id: 131569,
    name: "Ghorawal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.57198,
    longitude: 78.81432,
    wikiDataId: "Q932111",
  },
  {
    id: 131570,
    name: "Dumka",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.31093,
    longitude: 85.27601,
    wikiDataId: "Q1193823",
  },
  {
    id: 131571,
    name: "Dharwad",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.2,
    longitude: 76.5,
    wikiDataId: "Q165264",
  },
  {
    id: 131572,
    name: "Ghosi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.20511,
    longitude: 81.08962,
    wikiDataId: "Q165264",
  },
  {
    id: 131573,
    name: "Kendrapara",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.12533,
    longitude: 82.1089,
    wikiDataId: "Q165264",
  },
  {
    id: 131574,
    name: "Khanpur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.5,
    longitude: 74.5,
    wikiDataId: "Q1075011",
  },
  {
    id: 131575,
    name: "Chittoor",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.41667,
    longitude: 79,
    wikiDataId: "Q15213",
  },
  {
    id: 131576,
    name: "Iringal",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.69967,
    longitude: 76.7471,
    wikiDataId: "Q2568293",
  },
  {
    id: 131577,
    name: "Chodavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.82884,
    longitude: 82.93526,
    wikiDataId: "Q5103763",
  },
  {
    id: 131578,
    name: "Kangayam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.35193,
    longitude: 77.56839,
    wikiDataId: "Q1190702",
  },
  {
    id: 131579,
    name: "Gokul",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.51954,
    longitude: 83.02287,
    wikiDataId: "Q1193786",
  },
  {
    id: 131580,
    name: "Dharangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.24578,
    longitude: 75.29946,
    wikiDataId: "Q1192614",
  },
  {
    id: 131581,
    name: "Dharampuri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.42743,
    longitude: 81.66948,
    wikiDataId: "Q1191532",
  },
  {
    id: 131582,
    name: "Chotila",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.42347,
    longitude: 71.19641,
    wikiDataId: "Q2723942",
  },
  {
    id: 131583,
    name: "Daulatpur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.4319,
    longitude: 76.012,
    wikiDataId: "Q2723942",
  },
  {
    id: 131584,
    name: "Gola Bazar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.12776,
    longitude: 82.8821,
    wikiDataId: "Q1192683",
  },
  {
    id: 131585,
    name: "South West Khasi Hills",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.33333,
    longitude: 93.68333,
    wikiDataId: "Q2577281",
  },
  {
    id: 131587,
    name: "Dera Gopipur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.43058,
    longitude: 76.01428,
    wikiDataId: "Q1192134",
  },
  {
    id: 131588,
    name: "Chabua",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.48253,
    longitude: 95.17451,
    wikiDataId: "Q861884",
  },
  {
    id: 131589,
    name: "Colaba",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.76059,
    longitude: 73.86351,
    wikiDataId: "Q1020740",
  },
  {
    id: 131590,
    name: "Chakia",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.41598,
    longitude: 85.04665,
    wikiDataId: "Q1924830",
  },
  {
    id: 131591,
    name: "Deogarh",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.48301,
    longitude: 86.71793,
    wikiDataId: "Q858180",
  },
  {
    id: 131592,
    name: "Daddi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.45781,
    longitude: 75.01596,
    wikiDataId: "Q858598",
  },
  {
    id: 131593,
    name: "Champa",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.03532,
    longitude: 82.64234,
    wikiDataId: "Q3666066",
  },
  {
    id: 131594,
    name: "Kamakhyanagar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.06734,
    longitude: 85.66463,
    wikiDataId: "Q860201",
  },
  {
    id: 131595,
    name: "Chanasma",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.71472,
    longitude: 72.11279,
    wikiDataId: "Q859113",
  },
  {
    id: 131596,
    name: "Dahanu",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.95076,
    longitude: 79.29523,
    wikiDataId: "Q861194",
  },
  {
    id: 131597,
    name: "Kandhamal",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.77519,
    longitude: 86.74139,
    wikiDataId: "Q861194",
  },
  {
    id: 131598,
    name: "Dhanbad",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.95745,
    longitude: 86.05331,
    wikiDataId: "Q720244",
  },
  {
    id: 131599,
    name: "Dapoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.3306,
    longitude: 74.24467,
    wikiDataId: "Q720244",
  },
  {
    id: 131600,
    name: "Ganj Muradabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.13489,
    longitude: 78.27187,
    wikiDataId: "Q861597",
  },
  {
    id: 131601,
    name: "Daryapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.73444,
    longitude: 79.17167,
    wikiDataId: "Q3666120",
  },
  {
    id: 131603,
    name: "Dattapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.23853,
    longitude: 77.74713,
    wikiDataId: "Q3666120",
  },
  {
    id: 131604,
    name: "Chapar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.27266,
    longitude: 90.44556,
    wikiDataId: "Q2733320",
  },
  {
    id: 131605,
    name: "Chapra",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.78031,
    longitude: 84.74709,
    wikiDataId: "Q1755440",
  },
  {
    id: 131606,
    name: "Dhanwar",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63556,
    longitude: 86.16712,
    wikiDataId: "Q886561",
  },
  {
    id: 131607,
    name: "Sangareddi",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.55303,
    longitude: 80.6477,
    wikiDataId: "Q721302",
  },
  {
    id: 131608,
    name: "Chiria",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.55038,
    longitude: 85.80249,
    wikiDataId: "Q532883",
  },
  {
    id: 131609,
    name: "Erattupetta",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.69289,
    longitude: 76.34387,
    wikiDataId: "Q532883",
  },
  {
    id: 131610,
    name: "Jaito",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.6854,
    longitude: 76.08643,
    wikiDataId: "Q532883",
  },
  {
    id: 131611,
    name: "Chipurupalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.31142,
    longitude: 83.56846,
    wikiDataId: "Q532883",
  },
  {
    id: 131612,
    name: "Chirala",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.82385,
    longitude: 80.35219,
    wikiDataId: "Q1193800",
  },
  {
    id: 131613,
    name: "Devanhalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.12357,
    longitude: 77.0824,
    wikiDataId: "Q1192111",
  },
  {
    id: 131614,
    name: "Khetri",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.30415,
    longitude: 74.96718,
    wikiDataId: "Q1192416",
  },
  {
    id: 131615,
    name: "Cossimbazar",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.26361,
    longitude: 78.00862,
    wikiDataId: "Q1190648",
  },
  {
    id: 131616,
    name: "Dod Ballapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.72181,
    longitude: 77.28149,
    wikiDataId: "Q13371953",
  },
  {
    id: 131617,
    name: "Irinjalakuda",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.93988,
    longitude: 76.26022,
    wikiDataId: "Q1800",
  },
  {
    id: 131618,
    name: "Kanniyakumari",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.8,
    longitude: 77.09,
    wikiDataId: "Q15136",
  },
  {
    id: 131619,
    name: "Karaikkudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.17938,
    longitude: 77.25818,
    wikiDataId: "Q727731",
  },
  {
    id: 131620,
    name: "Colgong",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.26328,
    longitude: 87.23264,
    wikiDataId: "Q1190209",
  },
  {
    id: 131621,
    name: "Gonda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.13432,
    longitude: 81.69868,
    wikiDataId: "Q1193537",
  },
  {
    id: 131622,
    name: "Colovale",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.63522,
    longitude: 73.82426,
    wikiDataId: "Q939521",
  },
  {
    id: 131623,
    name: "Colva",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.27976,
    longitude: 73.92285,
    wikiDataId: "Q3440760",
  },
  {
    id: 131624,
    name: "French Rocks",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.63126,
    longitude: 74.6902,
    wikiDataId: "Q3440760",
  },
  {
    id: 131625,
    name: "Cortalim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.40247,
    longitude: 73.90881,
    wikiDataId: "Q3440760",
  },
  {
    id: 131626,
    name: "Karamadai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.52,
    longitude: 79.51,
    wikiDataId: "Q15150",
  },
  {
    id: 131627,
    name: "Cuddapah",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.41667,
    longitude: 78.75,
    wikiDataId: "Q15342",
  },
  {
    id: 131628,
    name: "Cumbum",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.58171,
    longitude: 79.11059,
    wikiDataId: "Q15342",
  },
  {
    id: 131629,
    name: "Karambakkudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.73647,
    longitude: 77.2847,
    wikiDataId: "Q2282438",
  },
  {
    id: 131630,
    name: "Cuncolim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.1773,
    longitude: 73.99392,
    wikiDataId: "Q1190666",
  },
  {
    id: 131631,
    name: "Curchorem",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.26349,
    longitude: 74.10875,
    wikiDataId: "Q1190690",
  },
  {
    id: 131632,
    name: "Curti",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.41667,
    longitude: 74.01667,
    wikiDataId: "Q12420204",
  },
  {
    id: 131633,
    name: "Kendraparha",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.5,
    longitude: 86.25,
    wikiDataId: "Q2022256",
  },
  {
    id: 131634,
    name: "Cachar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.78213,
    longitude: 92.85771,
    wikiDataId: "Q42209",
  },
  {
    id: 131635,
    name: "Dabhoi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.18333,
    longitude: 73.43333,
    wikiDataId: "Q1156554",
  },
  {
    id: 131636,
    name: "Dindori",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.00239,
    longitude: 78.87658,
    wikiDataId: "Q385237",
  },
  {
    id: 131637,
    name: "Etawa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.88572,
    longitude: 78.33221,
    wikiDataId: "Q1190879",
  },
  {
    id: 131638,
    name: "Faridabad",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.94906,
    longitude: 74.73832,
    wikiDataId: "Q1190879",
  },
  {
    id: 131639,
    name: "Dadra",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.32504,
    longitude: 72.96618,
    wikiDataId: "Q1190879",
  },
  {
    id: 131640,
    name: "Dadra & Nagar Haveli",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.20651,
    longitude: 73.00811,
    wikiDataId: "Q10752928",
  },
  {
    id: 131641,
    name: "Dharamsala",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.88431,
    longitude: 77.05228,
    wikiDataId: "Q960817",
  },
  {
    id: 131642,
    name: "Dahegam",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.16903,
    longitude: 72.82161,
    wikiDataId: "Q582485",
  },
  {
    id: 131643,
    name: "Kendujhar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.1,
    longitude: 85.75,
    wikiDataId: "Q1190218",
  },
  {
    id: 131644,
    name: "Gadag",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.84,
    longitude: 75.29,
    wikiDataId: "Q950571",
  },
  {
    id: 131645,
    name: "Gagret",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.55219,
    longitude: 75.94663,
    wikiDataId: "Q1925180",
  },
  {
    id: 131646,
    name: "Gonda City",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.06477,
    longitude: 81.0298,
    wikiDataId: "Q1189794",
  },
  {
    id: 131647,
    name: "Dalsingh Sarai",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.66795,
    longitude: 85.83636,
    wikiDataId: "Q1189794",
  },
  {
    id: 131648,
    name: "Garhwa",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.03971,
    longitude: 84.0658,
    wikiDataId: "Q936464",
  },
  {
    id: 131649,
    name: "Daman",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.41431,
    longitude: 72.83236,
    wikiDataId: "Q463252",
  },
  {
    id: 131650,
    name: "Daman District",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.41667,
    longitude: 72.88333,
    wikiDataId: "Q463252",
  },
  {
    id: 131651,
    name: "Gadarwara",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.75,
    longitude: 79.58333,
    wikiDataId: "Q2479331",
  },
  {
    id: 131652,
    name: "Gadag-Betageri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.26667,
    longitude: 74.61667,
    wikiDataId: "Q721378",
  },
  {
    id: 131653,
    name: "Gopamau",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.35121,
    longitude: 77.55508,
    wikiDataId: "Q1190816",
  },
  {
    id: 131654,
    name: "Darbhanga",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26,
    longitude: 86,
    wikiDataId: "Q49160",
  },
  {
    id: 131655,
    name: "Kishangarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.94865,
    longitude: 74.1342,
    wikiDataId: "Q1192796",
  },
  {
    id: 131656,
    name: "Athagarh",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.01336,
    longitude: 92.92439,
    wikiDataId: "Q1190883",
  },
  {
    id: 131657,
    name: "Darrang",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.51195,
    longitude: 92.16843,
    wikiDataId: "Q42461",
  },
  {
    id: 131658,
    name: "Darsi",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.76978,
    longitude: 79.67939,
    wikiDataId: "Q3298990",
  },
  {
    id: 131659,
    name: "Diglur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.92489,
    longitude: 77.32644,
    wikiDataId: "Q1190791",
  },
  {
    id: 131660,
    name: "Sathupalli",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.65399,
    longitude: 78.51213,
    wikiDataId: "Q1193652",
  },
  {
    id: 131661,
    name: "Jalalabad",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.81679,
    longitude: 75.6531,
    wikiDataId: "Q1190082",
  },
  {
    id: 131662,
    name: "Garha Brahman",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 78.5,
    wikiDataId: "Q2206266",
  },
  {
    id: 131663,
    name: "Digras",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.78075,
    longitude: 78.1407,
    wikiDataId: "Q2206266",
  },
  {
    id: 131664,
    name: "Daudnagar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.03473,
    longitude: 84.40095,
    wikiDataId: "Q1191619",
  },
  {
    id: 131665,
    name: "Ghumarwin",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.78871,
    longitude: 75.99154,
    wikiDataId: "Q1925028",
  },
  {
    id: 131666,
    name: "Dindori, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.93611,
    longitude: 75.22148,
    wikiDataId: "Q2249594",
  },
  {
    id: 131667,
    name: "Diveagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.46515,
    longitude: 74.58375,
    wikiDataId: "Q3703003",
  },
  {
    id: 131668,
    name: "Gulaothi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.11344,
    longitude: 77.70467,
    wikiDataId: "Q1190900",
  },
  {
    id: 131669,
    name: "Kotputli",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.88269,
    longitude: 76.57053,
    wikiDataId: "Q1173042",
  },
  {
    id: 131670,
    name: "Gajendragarh",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.43,
    longitude: 75.9,
    wikiDataId: "Q1173042",
  },
  {
    id: 131672,
    name: "Davorlim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.27221,
    longitude: 73.99242,
    wikiDataId: "Q1192143",
  },
  {
    id: 131673,
    name: "Dayapar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63371,
    longitude: 68.90192,
    wikiDataId: "Q15242758",
  },
  {
    id: 131674,
    name: "Gunnaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.22122,
    longitude: 78.01095,
    wikiDataId: "Q26791189",
  },
  {
    id: 131676,
    name: "Dakshin Dinajpur district",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.33,
    longitude: 78.06,
    wikiDataId: "Q1815740",
  },
  {
    id: 131677,
    name: "Dehri",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.90247,
    longitude: 84.18217,
    wikiDataId: "Q1190622",
  },
  {
    id: 131678,
    name: "Dongri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.71851,
    longitude: 73.76635,
    wikiDataId: "Q1192430",
  },
  {
    id: 131679,
    name: "Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.65195,
    longitude: 77.23149,
    wikiDataId: "Q1353",
  },
  {
    id: 131680,
    name: "Delvada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.77544,
    longitude: 71.04646,
    wikiDataId: "Q1353",
  },
  {
    id: 131681,
    name: "Kariapatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.5301,
    longitude: 77.78887,
    wikiDataId: "Q1353",
  },
  {
    id: 131682,
    name: "Gursahaiganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.69505,
    longitude: 77.67964,
    wikiDataId: "Q1017658",
  },
  {
    id: 131683,
    name: "Kesinga",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.53827,
    longitude: 84.73337,
    wikiDataId: "Q1193197",
  },
  {
    id: 131684,
    name: "Ghatsila",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.44382,
    longitude: 86.72607,
    wikiDataId: "Q2030017",
  },
  {
    id: 131685,
    name: "Kuchaman",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75728,
    longitude: 75.37991,
    wikiDataId: "Q3299159",
  },
  {
    id: 131686,
    name: "Durgapur, Chandrapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.6492,
    longitude: 78.48023,
    wikiDataId: "Q2429122",
  },
  {
    id: 131687,
    name: "Deoli",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.50254,
    longitude: 77.23117,
    wikiDataId: "Q2429122",
  },
  {
    id: 131688,
    name: "Dudhani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.94404,
    longitude: 73.83441,
    wikiDataId: "Q958334",
  },
  {
    id: 131689,
    name: "Gyanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.62989,
    longitude: 79.47648,
    wikiDataId: "Q1190738",
  },
  {
    id: 131690,
    name: "Deori",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.45,
    longitude: 82.61667,
    wikiDataId: "Q1190738",
  },
  {
    id: 131691,
    name: "Garhakota",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.39017,
    longitude: 79.0163,
    wikiDataId: "Q1649811",
  },
  {
    id: 131692,
    name: "Haldaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.66667,
    longitude: 83.75,
    wikiDataId: "Q1023685",
  },
  {
    id: 131693,
    name: "Gautampura",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.85095,
    longitude: 75.54224,
    wikiDataId: "Q2446961",
  },
  {
    id: 131694,
    name: "Hamirpur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.87919,
    longitude: 76.21871,
    wikiDataId: "Q1193478",
  },
  {
    id: 131695,
    name: "Dergaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.7,
    longitude: 93.96667,
    wikiDataId: "Q1189812",
  },
  {
    id: 131696,
    name: "Jalandhar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.03733,
    longitude: 75.02787,
    wikiDataId: "Q1190222",
  },
  {
    id: 131697,
    name: "Kuchera",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.79836,
    longitude: 73.34297,
    wikiDataId: "Q1190784",
  },
  {
    id: 131698,
    name: "Karumbakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.43727,
    longitude: 79.48145,
    wikiDataId: "Q1190753",
  },
  {
    id: 131699,
    name: "Karur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.14673,
    longitude: 77.6439,
    wikiDataId: "Q1260462",
  },
  {
    id: 131700,
    name: "Katpadi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.94704,
    longitude: 78.8233,
    wikiDataId: "Q1260517",
  },
  {
    id: 131701,
    name: "Gangawati",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.24655,
    longitude: 77.71183,
    wikiDataId: "Q2087756",
  },
  {
    id: 131702,
    name: "Dalkola",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.14603,
    longitude: 78.60272,
    wikiDataId: "Q2087756",
  },
  {
    id: 131703,
    name: "Secunderabad",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.69186,
    longitude: 78.92073,
    wikiDataId: "Q2087756",
  },
  {
    id: 131704,
    name: "Devbhumi Dwarka",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.20253,
    longitude: 69.65498,
    wikiDataId: "Q2087756",
  },
  {
    id: 131705,
    name: "Devgadh Bariya",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.70517,
    longitude: 73.90882,
    wikiDataId: "Q1263667",
  },
  {
    id: 131706,
    name: "Kumher",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.52533,
    longitude: 73.90812,
    wikiDataId: "Q1007981",
  },
  {
    id: 131707,
    name: "Ghansor",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23,
    longitude: 76.16667,
    wikiDataId: "Q2025998",
  },
  {
    id: 131708,
    name: "Hapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.03621,
    longitude: 81.16692,
    wikiDataId: "Q1253622",
  },
  {
    id: 131709,
    name: "Faizpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.01757,
    longitude: 76.03755,
    wikiDataId: "Q1189972",
  },
  {
    id: 131710,
    name: "Bajna",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.84307,
    longitude: 91.92591,
    wikiDataId: "Q2086546",
  },
  {
    id: 131711,
    name: "Kattivakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.51049,
    longitude: 77.18806,
    wikiDataId: "Q2554844",
  },
  {
    id: 131712,
    name: "Dhamtari",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.70718,
    longitude: 81.54874,
    wikiDataId: "Q1264075",
  },
  {
    id: 131713,
    name: "Jandiala Guru",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.28216,
    longitude: 75.57341,
    wikiDataId: "Q1265949",
  },
  {
    id: 131714,
    name: "Haraiya",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.95912,
    longitude: 78.25629,
    wikiDataId: "Q1258768",
  },
  {
    id: 131715,
    name: "Giridih",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.80199,
    longitude: 86.44324,
    wikiDataId: "Q244159",
  },
  {
    id: 131716,
    name: "Dhandhuka",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.38185,
    longitude: 71.98664,
    wikiDataId: "Q1259584",
  },
  {
    id: 131717,
    name: "Dhanera",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.50967,
    longitude: 72.02343,
    wikiDataId: "Q1266017",
  },
  {
    id: 131718,
    name: "Gobindpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.41074,
    longitude: 85.98183,
    wikiDataId: "Q720234",
  },
  {
    id: 131719,
    name: "Dharampur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.53693,
    longitude: 73.17368,
    wikiDataId: "Q2465676",
  },
  {
    id: 131720,
    name: "Guna",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.14951,
    longitude: 75.34439,
    wikiDataId: "Q1266025",
  },
  {
    id: 131721,
    name: "Jawala Mukhi",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.22006,
    longitude: 76.32013,
    wikiDataId: "Q186182",
  },
  {
    id: 131722,
    name: "Gadchiroli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.01187,
    longitude: 75.27407,
    wikiDataId: "Q1264596",
  },
  {
    id: 131723,
    name: "Kattupputtur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.73828,
    longitude: 77.53223,
    wikiDataId: "Q325951",
  },
  {
    id: 131724,
    name: "Kadakkavoor",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.77538,
    longitude: 75.46459,
    wikiDataId: "Q1265354",
  },
  {
    id: 131725,
    name: "Bakewar",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.36667,
    longitude: 92.16667,
    wikiDataId: "Q1258127",
  },
  {
    id: 131726,
    name: "Kaveripatnam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.1277,
    longitude: 78.15794,
    wikiDataId: "Q9894",
  },
  {
    id: 131727,
    name: "Dharmavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.41435,
    longitude: 77.72035,
    wikiDataId: "Q1948238",
  },
  {
    id: 131728,
    name: "Gangakher",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.89116,
    longitude: 77.8494,
    wikiDataId: "Q1266630",
  },
  {
    id: 131729,
    name: "Gangolli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.37,
    longitude: 75.14,
    wikiDataId: "Q1790904",
  },
  {
    id: 131730,
    name: "Kushalgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.71183,
    longitude: 77.73956,
    wikiDataId: "Q1207709",
  },
  {
    id: 131731,
    name: "Harduaganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.99814,
    longitude: 81.08975,
    wikiDataId: "Q26790326",
  },
  {
    id: 131732,
    name: "Dhekiajuli",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.70367,
    longitude: 92.47808,
    wikiDataId: "Q1258219",
  },
  {
    id: 131733,
    name: "Dhemaji",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.6091,
    longitude: 94.7942,
    wikiDataId: "Q42473",
  },
  {
    id: 131735,
    name: "Khallikot",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75,
    longitude: 85.5,
    wikiDataId: "Q1948389",
  },
  {
    id: 131736,
    name: "Kartarpur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.51432,
    longitude: 75.34574,
    wikiDataId: "Q1258759",
  },
  {
    id: 131737,
    name: "Dhing",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.46793,
    longitude: 92.47336,
    wikiDataId: "Q1259385",
  },
  {
    id: 131738,
    name: "Dhola",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.88129,
    longitude: 71.77269,
    wikiDataId: "Q2355879",
  },
  {
    id: 131739,
    name: "Dholka",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.72732,
    longitude: 72.44128,
    wikiDataId: "Q1263568",
  },
  {
    id: 131740,
    name: "Dhone",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.3952,
    longitude: 77.8715,
    wikiDataId: "Q1263568",
  },
  {
    id: 131741,
    name: "Dhoraji",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.73359,
    longitude: 70.45004,
    wikiDataId: "Q1259573",
  },
  {
    id: 131742,
    name: "Dhrol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.567,
    longitude: 70.41769,
    wikiDataId: "Q927778",
  },
  {
    id: 131743,
    name: "Dhrangadhra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.99167,
    longitude: 71.46793,
    wikiDataId: "Q1265559",
  },
  {
    id: 131744,
    name: "Dhubri",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.06749,
    longitude: 90.02238,
    wikiDataId: "Q1265559",
  },
  {
    id: 131746,
    name: "Gevrai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.06852,
    longitude: 74.58837,
    wikiDataId: "Q31856850",
  },
  {
    id: 131747,
    name: "Dhuwaran",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23779,
    longitude: 72.7591,
    wikiDataId: "Q2761882",
  },
  {
    id: 131748,
    name: "Dhaka",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.67479,
    longitude: 85.16698,
    wikiDataId: "Q1257018",
  },
  {
    id: 131749,
    name: "Gogapur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.20928,
    longitude: 75.47057,
    wikiDataId: "Q1259705",
  },
  {
    id: 131750,
    name: "Haraipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.30883,
    longitude: 78.51083,
    wikiDataId: "Q1259747",
  },
  {
    id: 131751,
    name: "Gohadi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.74697,
    longitude: 78.86234,
    wikiDataId: "Q1259557",
  },
  {
    id: 131753,
    name: "Govindgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.59373,
    longitude: 75.29774,
    wikiDataId: "Q617987",
  },
  {
    id: 131754,
    name: "Dam Dam",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.84707,
    longitude: 80.51951,
    wikiDataId: "Q684523",
  },
  {
    id: 131755,
    name: "Dhari",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.32855,
    longitude: 71.02645,
    wikiDataId: "Q2761749",
  },
  {
    id: 131756,
    name: "Kapurthala",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.95616,
    longitude: 75.32386,
    wikiDataId: "Q2761749",
  },
  {
    id: 131757,
    name: "Faridabad District",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.20553,
    longitude: 76.79691,
    wikiDataId: "Q1258723",
  },
  {
    id: 131758,
    name: "Gangapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.82017,
    longitude: 76.10937,
    wikiDataId: "Q2546835",
  },
  {
    id: 131759,
    name: "Ghargaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.9013,
    longitude: 74.77737,
    wikiDataId: "Q773603",
  },
  {
    id: 131760,
    name: "Khanna",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.36846,
    longitude: 75.86791,
    wikiDataId: "Q1259563",
  },
  {
    id: 131761,
    name: "Hastinapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.20849,
    longitude: 78.26173,
    wikiDataId: "Q592554",
  },
  {
    id: 131762,
    name: "Dibrugarh",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5,
    longitude: 95,
    wikiDataId: "Q42479",
  },
  {
    id: 131763,
    name: "Dibang Valley",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.7,
    longitude: 95.7,
    wikiDataId: "Q42479",
  },
  {
    id: 131764,
    name: "Dicholi",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.59319,
    longitude: 73.94571,
    wikiDataId: "Q796819",
  },
  {
    id: 131765,
    name: "Kharhial",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.37275,
    longitude: 84.57184,
    wikiDataId: "Q1255951",
  },
  {
    id: 131766,
    name: "Digboi",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.39321,
    longitude: 95.61839,
    wikiDataId: "Q1267577",
  },
  {
    id: 131767,
    name: "Dighwara",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.74434,
    longitude: 85.01003,
    wikiDataId: "Q1255941",
  },
  {
    id: 131768,
    name: "Ghoti Budrukh",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.1035,
    longitude: 77.71846,
    wikiDataId: "Q1259725",
  },
  {
    id: 131769,
    name: "Diguvametta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.39507,
    longitude: 78.8293,
    wikiDataId: "Q5276378",
  },
  {
    id: 131770,
    name: "Dima Hasao District",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 93,
    wikiDataId: "Q42774",
  },
  {
    id: 131771,
    name: "Barpali",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.77852,
    longitude: 93.78508,
    wikiDataId: "Q634262",
  },
  {
    id: 131773,
    name: "Dinapore",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.63705,
    longitude: 85.04794,
    wikiDataId: "Q1022305",
  },
  {
    id: 131774,
    name: "Kayalpattinam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.4,
    longitude: 77.8,
    wikiDataId: "Q15154",
  },
  {
    id: 131775,
    name: "Gurh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.8,
    longitude: 81.1,
    wikiDataId: "Q2398551",
  },
  {
    id: 131776,
    name: "Diphu",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.84341,
    longitude: 93.43116,
    wikiDataId: "Q1227167",
  },
  {
    id: 131777,
    name: "Khemkaran",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.07222,
    longitude: 75.99607,
    wikiDataId: "Q1257699",
  },
  {
    id: 131778,
    name: "Dispur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.13564,
    longitude: 91.80069,
    wikiDataId: "Q271911",
  },
  {
    id: 131779,
    name: "Diu",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.72081,
    longitude: 70.93989,
    wikiDataId: "Q2552347",
  },
  {
    id: 131780,
    name: "Gokak",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.29452,
    longitude: 77.53777,
    wikiDataId: "Q1264006",
  },
  {
    id: 131781,
    name: "Hiranagar",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.14916,
    longitude: 75.54746,
    wikiDataId: "Q1263751",
  },
  {
    id: 131782,
    name: "Dohad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.9,
    longitude: 74,
    wikiDataId: "Q186518",
  },
  {
    id: 131783,
    name: "Hata (India)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.27217,
    longitude: 83.50916,
    wikiDataId: "Q1257816",
  },
  {
    id: 131784,
    name: "Darjeeling",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.17667,
    longitude: 78.11659,
    wikiDataId: "Q1258676",
  },
  {
    id: 131785,
    name: "Gondia",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.21667,
    longitude: 73.08333,
    wikiDataId: "Q3034709",
  },
  {
    id: 131786,
    name: "Gondiya",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.3236,
    longitude: 74.56804,
    wikiDataId: "Q26789856",
  },
  {
    id: 131787,
    name: "Dongargaon",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.97172,
    longitude: 80.85077,
    wikiDataId: "Q1387439",
  },
  {
    id: 131788,
    name: "Dongargarh",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.18893,
    longitude: 80.75459,
    wikiDataId: "Q1387087",
  },
  {
    id: 131789,
    name: "Serilingampalle",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.44475,
    longitude: 80.14905,
    wikiDataId: "Q1387087",
  },
  {
    id: 131790,
    name: "Kot Isa Khan",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.79953,
    longitude: 76.02355,
    wikiDataId: "Q1193432",
  },
  {
    id: 131791,
    name: "Hulas",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.27486,
    longitude: 82.47091,
    wikiDataId: "Q1387112",
  },
  {
    id: 131792,
    name: "Goregaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.35792,
    longitude: 76.36688,
    wikiDataId: "Q1387098",
  },
  {
    id: 131793,
    name: "Debagram",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.80673,
    longitude: 78.61109,
    wikiDataId: "Q1256996",
  },
  {
    id: 131794,
    name: "Godda",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.74516,
    longitude: 86.17175,
    wikiDataId: "Q1387263",
  },
  {
    id: 131795,
    name: "Duliagaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.37227,
    longitude: 95.30754,
    wikiDataId: "Q1387263",
  },
  {
    id: 131796,
    name: "Dum Duma",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.56884,
    longitude: 95.55664,
    wikiDataId: "Q1387064",
  },
  {
    id: 131797,
    name: "Gomoh",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.3,
    longitude: 87.25,
    wikiDataId: "Q2577657",
  },
  {
    id: 131798,
    name: "Dumra",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.56708,
    longitude: 85.5204,
    wikiDataId: "Q2577657",
  },
  {
    id: 131799,
    name: "Dumraon",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.55265,
    longitude: 84.15149,
    wikiDataId: "Q1524667",
  },
  {
    id: 131800,
    name: "Dungarpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.28777,
    longitude: 71.7556,
    wikiDataId: "Q1524667",
  },
  {
    id: 131801,
    name: "Durg",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.15,
    longitude: 81.4,
    wikiDataId: "Q100182",
  },
  {
    id: 131802,
    name: "Guhagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.0054,
    longitude: 79.30273,
    wikiDataId: "Q3716506",
  },
  {
    id: 131803,
    name: "Dwarka",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23944,
    longitude: 68.96778,
    wikiDataId: "Q817247",
  },
  {
    id: 131804,
    name: "Debipur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.77785,
    longitude: 79.42731,
    wikiDataId: "Q1524687",
  },
  {
    id: 131805,
    name: "Dharmabad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.58971,
    longitude: 73.18001,
    wikiDataId: "Q5207479",
  },
  {
    id: 131806,
    name: "Gonda (Aligarh)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.55257,
    longitude: 77.55403,
    wikiDataId: "Q1192802",
  },
  {
    id: 131807,
    name: "Dhawalpuri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.96778,
    longitude: 72.71263,
    wikiDataId: "Q1190226",
  },
  {
    id: 131808,
    name: "Dakor",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.75268,
    longitude: 73.14967,
    wikiDataId: "Q1192377",
  },
  {
    id: 131809,
    name: "Damnagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.69232,
    longitude: 71.51747,
    wikiDataId: "Q1189805",
  },
  {
    id: 131810,
    name: "Dighori",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.31017,
    longitude: 77.77257,
    wikiDataId: "Q1190716",
  },
  {
    id: 131811,
    name: "Govardhan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.67736,
    longitude: 77.52252,
    wikiDataId: "Q1190804",
  },
  {
    id: 131812,
    name: "Greater Noida",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.0253,
    longitude: 79.40819,
    wikiDataId: "Q1192215",
  },
  {
    id: 131813,
    name: "Lachhmangarh Sikar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.40096,
    longitude: 74.57537,
    wikiDataId: "Q1264589",
  },
  {
    id: 131814,
    name: "Ladnun",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.47188,
    longitude: 77.32564,
    wikiDataId: "Q1192807",
  },
  {
    id: 131815,
    name: "Ghatkopar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.54829,
    longitude: 77.57695,
    wikiDataId: "Q1192446",
  },
  {
    id: 131816,
    name: "Kharar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.13664,
    longitude: 75.47291,
    wikiDataId: "Q1258159",
  },
  {
    id: 131817,
    name: "Disa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.25612,
    longitude: 72.17928,
    wikiDataId: "Q31856848",
  },
  {
    id: 131818,
    name: "Ibrahimpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.21357,
    longitude: 83.24067,
    wikiDataId: "Q1387427",
  },
  {
    id: 131819,
    name: "Lakheri",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.84306,
    longitude: 73.71466,
    wikiDataId: "Q741991",
  },
  {
    id: 131820,
    name: "Kayattar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.77574,
    longitude: 79.67892,
    wikiDataId: "Q2541621",
  },
  {
    id: 131821,
    name: "East Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.66242,
    longitude: 77.29122,
    wikiDataId: "Q107960",
  },
  {
    id: 131822,
    name: "Bhavani",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.33333,
    longitude: 88.66667,
    wikiDataId: "Q1772832",
  },
  {
    id: 131823,
    name: "East Godavari",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.83333,
    longitude: 81.83333,
    wikiDataId: "Q15338",
  },
  {
    id: 131824,
    name: "Mamit",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.61372,
    longitude: 90.62426,
    wikiDataId: "Q2085455",
  },
  {
    id: 131825,
    name: "North Vanlaiphai",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.35976,
    longitude: 92.3668,
    wikiDataId: "Q15923776",
  },
  {
    id: 131826,
    name: "East Kameng",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.3,
    longitude: 93.05,
    wikiDataId: "Q15424",
  },
  {
    id: 131827,
    name: "Saiha",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.3805,
    longitude: 91.78905,
    wikiDataId: "Q1945304",
  },
  {
    id: 131828,
    name: "East Siang",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.12379,
    longitude: 95.16339,
    wikiDataId: "Q1945304",
  },
  {
    id: 131829,
    name: "Kalamassery",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.6102,
    longitude: 76.18352,
    wikiDataId: "Q1945304",
  },
  {
    id: 131830,
    name: "Elamanchili",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.54907,
    longitude: 82.85749,
    wikiDataId: "Q3415001",
  },
  {
    id: 131831,
    name: "Farrukhnagar",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.45282,
    longitude: 74.66122,
    wikiDataId: "Q1524663",
  },
  {
    id: 131832,
    name: "Ellore",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.71311,
    longitude: 81.10437,
    wikiDataId: "Q1003496",
  },
  {
    id: 131833,
    name: "Kelamangalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.86501,
    longitude: 77.69923,
    wikiDataId: "Q1525119",
  },
  {
    id: 131834,
    name: "Keelakarai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.27033,
    longitude: 77.82494,
    wikiDataId: "Q1525566",
  },
  {
    id: 131835,
    name: "Kalavoor",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.06667,
    longitude: 76.28333,
    wikiDataId: "Q1525566",
  },
  {
    id: 131836,
    name: "Emmiganur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.77203,
    longitude: 77.48345,
    wikiDataId: "Q2734064",
  },
  {
    id: 131837,
    name: "Kil Bhuvanagiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.62584,
    longitude: 78.02282,
    wikiDataId: "Q1525095",
  },
  {
    id: 131838,
    name: "Hadgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.92206,
    longitude: 75.32641,
    wikiDataId: "Q1523435",
  },
  {
    id: 131839,
    name: "Kilvelur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.20589,
    longitude: 77.31726,
    wikiDataId: "Q1525157",
  },
  {
    id: 131840,
    name: "Kannangad",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10,
    longitude: 76.5,
    wikiDataId: "Q1525157",
  },
  {
    id: 131841,
    name: "Kiranur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.34,
    longitude: 77.55,
    wikiDataId: "Q15155",
  },
  {
    id: 131842,
    name: "Erraguntla",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.63853,
    longitude: 78.53974,
    wikiDataId: "Q720903",
  },
  {
    id: 131843,
    name: "Kodaikanal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.14671,
    longitude: 78.28996,
    wikiDataId: "Q720903",
  },
  {
    id: 131844,
    name: "Kalpatta",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.68747,
    longitude: 76.77891,
    wikiDataId: "Q2475592",
  },
  {
    id: 131845,
    name: "Iglas",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5,
    longitude: 78.75,
    wikiDataId: "Q1525330",
  },
  {
    id: 131846,
    name: "Etikoppaka",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.5,
    longitude: 82.73333,
    wikiDataId: "Q1773429",
  },
  {
    id: 131847,
    name: "Kodumudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.14405,
    longitude: 77.99066,
    wikiDataId: "Q1773429",
  },
  {
    id: 131848,
    name: "Gwalior",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.18351,
    longitude: 78.20289,
    wikiDataId: "Q858501",
  },
  {
    id: 131849,
    name: "Ikauna",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 79.25,
    wikiDataId: "Q878237",
  },
  {
    id: 131850,
    name: "Halkarni",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.16766,
    longitude: 75.86006,
    wikiDataId: "Q1523144",
  },
  {
    id: 131851,
    name: "Indragarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 82,
    wikiDataId: "Q842411",
  },
  {
    id: 131852,
    name: "Islamnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.32081,
    longitude: 77.76185,
    wikiDataId: "Q1522971",
  },
  {
    id: 131853,
    name: "Fatehabad",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.41124,
    longitude: 77.31316,
    wikiDataId: "Q200663",
  },
  {
    id: 131854,
    name: "Fatehabad District",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.46292,
    longitude: 77.3716,
    wikiDataId: "Q2086173",
  },
  {
    id: 131856,
    name: "Firozpur Jhirka",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.44745,
    longitude: 76.82391,
    wikiDataId: "Q1525392",
  },
  {
    id: 131857,
    name: "Siddipet",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.07787,
    longitude: 78.20339,
    wikiDataId: "Q1525398",
  },
  {
    id: 131858,
    name: "Itimadpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5,
    longitude: 79.5,
    wikiDataId: "Q4549",
  },
  {
    id: 131859,
    name: "Kotkapura",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.67399,
    longitude: 74.75579,
    wikiDataId: "Q1780350",
  },
  {
    id: 131860,
    name: "Islamnagar (Badaun)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.76923,
    longitude: 77.62934,
    wikiDataId: "Q1523007",
  },
  {
    id: 131861,
    name: "Itaunja",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.20997,
    longitude: 79.54149,
    wikiDataId: "Q741724",
  },
  {
    id: 131862,
    name: "Gohana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.31,
    longitude: 75.27,
    wikiDataId: "Q2301753",
  },
  {
    id: 131863,
    name: "Jagnair",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.4662,
    longitude: 79.30657,
    wikiDataId: "Q26789979",
  },
  {
    id: 131864,
    name: "Jahanabad (Pilibhit)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.36409,
    longitude: 79.63111,
    wikiDataId: "Q1023671",
  },
  {
    id: 131865,
    name: "Laungowal",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.86431,
    longitude: 74.95665,
    wikiDataId: "Q1524046",
  },
  {
    id: 131866,
    name: "Ludhiana",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.64379,
    longitude: 76.34787,
    wikiDataId: "Q1524046",
  },
  {
    id: 131867,
    name: "Lalsot",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.99486,
    longitude: 74.95628,
    wikiDataId: "Q2720811",
  },
  {
    id: 131868,
    name: "Jahangirabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 80.75,
    wikiDataId: "Q1525461",
  },
  {
    id: 131869,
    name: "Jainpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.78925,
    longitude: 80.26547,
    wikiDataId: "Q1524332",
  },
  {
    id: 131870,
    name: "Jais",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.0937,
    longitude: 77.66003,
    wikiDataId: "Q179046",
  },
  {
    id: 131871,
    name: "Jagdishpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.02645,
    longitude: 78.3027,
    wikiDataId: "Q179046",
  },
  {
    id: 131872,
    name: "Gharaunda",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.51525,
    longitude: 75.45554,
    wikiDataId: "Q1320989",
  },
  {
    id: 131873,
    name: "Fatwa",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.50958,
    longitude: 85.30504,
    wikiDataId: "Q1320989",
  },
  {
    id: 131874,
    name: "Kannavam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.17989,
    longitude: 75.84141,
    wikiDataId: "Q1525568",
  },
  {
    id: 131875,
    name: "Majitha",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.92574,
    longitude: 74.61311,
    wikiDataId: "Q652098",
  },
  {
    id: 131876,
    name: "Jalali",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.20072,
    longitude: 78.42867,
    wikiDataId: "Q1452991",
  },
  {
    id: 131877,
    name: "Makhu",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.89,
    longitude: 74.56,
    wikiDataId: "Q172385",
  },
  {
    id: 131878,
    name: "Forbesganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.30253,
    longitude: 87.26556,
    wikiDataId: "Q721265",
  },
  {
    id: 131879,
    name: "Gokarna",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.50094,
    longitude: 76.67416,
    wikiDataId: "Q2449488",
  },
  {
    id: 131880,
    name: "Jalaun",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.77549,
    longitude: 82.15018,
    wikiDataId: "Q842411",
  },
  {
    id: 131881,
    name: "Machhiwara",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.40207,
    longitude: 74.02836,
    wikiDataId: "Q1525269",
  },
  {
    id: 131882,
    name: "Gorakhpur",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.78853,
    longitude: 76.94496,
    wikiDataId: "Q1523132",
  },
  {
    id: 131884,
    name: "Goribidnur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.49835,
    longitude: 75.65187,
    wikiDataId: "Q1452991",
  },
  {
    id: 131885,
    name: "Gorur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.4167,
    longitude: 75.6167,
    wikiDataId: "Q1452991",
  },
  {
    id: 131886,
    name: "Harnai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.8,
    longitude: 80.2,
    wikiDataId: "Q1452991",
  },
  {
    id: 131887,
    name: "Singapur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.36687,
    longitude: 78.5242,
    wikiDataId: "Q1523012",
  },
  {
    id: 131888,
    name: "Gadhada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.96957,
    longitude: 71.57828,
    wikiDataId: "Q1525333",
  },
  {
    id: 131889,
    name: "Helwak",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.22291,
    longitude: 74.3501,
    wikiDataId: "Q1523294",
  },
  {
    id: 131890,
    name: "Sirpur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.23504,
    longitude: 77.79556,
    wikiDataId: "Q1525224",
  },
  {
    id: 131891,
    name: "Jogindarnagar",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.65846,
    longitude: 76.06144,
    wikiDataId: "Q1524343",
  },
  {
    id: 131892,
    name: "Khordha",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.91,
    longitude: 84.2,
    wikiDataId: "Q1947292",
  },
  {
    id: 131893,
    name: "Gubbi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.73628,
    longitude: 75.96976,
    wikiDataId: "Q1525274",
  },
  {
    id: 131894,
    name: "Jarwa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.8457,
    longitude: 78.2396,
    wikiDataId: "Q1524653",
  },
  {
    id: 131895,
    name: "Gajuwaka",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.7,
    longitude: 83.21667,
    wikiDataId: "Q1524653",
  },
  {
    id: 131896,
    name: "Losal",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.51995,
    longitude: 74.02028,
    wikiDataId: "Q1656995",
  },
  {
    id: 131897,
    name: "Gandai",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.66667,
    longitude: 81.10013,
    wikiDataId: "Q1656707",
  },
  {
    id: 131898,
    name: "Jaurian",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.29467,
    longitude: 75.19996,
    wikiDataId: "Q2556028",
  },
  {
    id: 131899,
    name: "Gandevi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.81214,
    longitude: 72.99811,
    wikiDataId: "Q721365",
  },
  {
    id: 131900,
    name: "Gandhinagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.21667,
    longitude: 72.68333,
    wikiDataId: "Q11910",
  },
  {
    id: 131901,
    name: "Konganapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.85785,
    longitude: 77.78019,
    wikiDataId: "Q1656451",
  },
  {
    id: 131902,
    name: "Koothanallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.49828,
    longitude: 78.64966,
    wikiDataId: "Q1656922",
  },
  {
    id: 131903,
    name: "Jarwal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.78004,
    longitude: 77.26346,
    wikiDataId: "Q1654816",
  },
  {
    id: 131904,
    name: "Guledagudda",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.65024,
    longitude: 74.67072,
    wikiDataId: "Q5521176",
  },
  {
    id: 131905,
    name: "Bodinayakkanur",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.32574,
    longitude: 88.61216,
    wikiDataId: "Q186141",
  },
  {
    id: 131906,
    name: "Ganguvada",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.66667,
    longitude: 84.11667,
    wikiDataId: "Q186141",
  },
  {
    id: 131907,
    name: "Hinganghat",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.96962,
    longitude: 76.74946,
    wikiDataId: "Q186141",
  },
  {
    id: 131908,
    name: "Mahwah",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.92009,
    longitude: 73.87496,
    wikiDataId: "Q490883",
  },
  {
    id: 131909,
    name: "Makrana",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.47249,
    longitude: 76.71744,
    wikiDataId: "Q12422879",
  },
  {
    id: 131910,
    name: "Hingoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.69718,
    longitude: 75.01045,
    wikiDataId: "Q2546805",
  },
  {
    id: 131911,
    name: "Gudibanda",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.4313,
    longitude: 76.52933,
    wikiDataId: "Q1656906",
  },
  {
    id: 131912,
    name: "Jaswantnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.73308,
    longitude: 78.94119,
    wikiDataId: "Q1654851",
  },
  {
    id: 131913,
    name: "Jaunpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.95733,
    longitude: 80.184,
    wikiDataId: "Q268045",
  },
  {
    id: 131914,
    name: "Khurda",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.38705,
    longitude: 85.05079,
    wikiDataId: "Q12422937",
  },
  {
    id: 131915,
    name: "Gannavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.54092,
    longitude: 80.80213,
    wikiDataId: "Q5521417",
  },
  {
    id: 131916,
    name: "Jewar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5719,
    longitude: 79.29764,
    wikiDataId: "Q1654825",
  },
  {
    id: 131917,
    name: "Harda Khas",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.86873,
    longitude: 77.35731,
    wikiDataId: "Q1654825",
  },
  {
    id: 131918,
    name: "Malaut",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.74147,
    longitude: 75.75567,
    wikiDataId: "Q1657028",
  },
  {
    id: 131919,
    name: "Jhajhar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.5498,
    longitude: 77.30881,
    wikiDataId: "Q1657028",
  },
  {
    id: 131920,
    name: "Jhalu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.78732,
    longitude: 78.10214,
    wikiDataId: "Q26789435",
  },
  {
    id: 131921,
    name: "Malerkotla",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.21537,
    longitude: 76.14149,
    wikiDataId: "Q1655903",
  },
  {
    id: 131922,
    name: "Gopinathpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.07494,
    longitude: 83.71023,
    wikiDataId: "Q1655903",
  },
  {
    id: 131923,
    name: "Dhaniakhali community development block",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.96366,
    longitude: 78.92853,
    wikiDataId: "Q2085474",
  },
  {
    id: 131924,
    name: "Harpalpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.7791,
    longitude: 79.14321,
    wikiDataId: "Q1655893",
  },
  {
    id: 131925,
    name: "Gariadhar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.53889,
    longitude: 71.57737,
    wikiDataId: "Q536870",
  },
  {
    id: 131926,
    name: "Gariaband",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.63323,
    longitude: 82.06221,
    wikiDataId: "Q536870",
  },
  {
    id: 131927,
    name: "Gaurela",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.75449,
    longitude: 81.90107,
    wikiDataId: "Q536870",
  },
  {
    id: 131928,
    name: "Gauripur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.08334,
    longitude: 89.96118,
    wikiDataId: "Q279871",
  },
  {
    id: 131929,
    name: "Jhinjhana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.35898,
    longitude: 77.55076,
    wikiDataId: "Q279871",
  },
  {
    id: 131930,
    name: "Harrai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.98664,
    longitude: 75.51921,
    wikiDataId: "Q279871",
  },
  {
    id: 131931,
    name: "Jhusi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.41969,
    longitude: 78.35186,
    wikiDataId: "Q1654847",
  },
  {
    id: 131932,
    name: "Gaya",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.79686,
    longitude: 85.00385,
    wikiDataId: "Q251329",
  },
  {
    id: 131934,
    name: "Hirapur Hamesha",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.26372,
    longitude: 75.75007,
    wikiDataId: "Q1654133",
  },
  {
    id: 131935,
    name: "Maur Mandi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.33092,
    longitude: 76.61203,
    wikiDataId: "Q1656935",
  },
  {
    id: 131936,
    name: "Harsud",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.65976,
    longitude: 79.95013,
    wikiDataId: "Q2313263",
  },
  {
    id: 131937,
    name: "Gurgaon",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.53692,
    longitude: 76.97142,
    wikiDataId: "Q1708340",
  },
  {
    id: 131938,
    name: "Gharghoda",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.17427,
    longitude: 83.3517,
    wikiDataId: "Q1706460",
  },
  {
    id: 131939,
    name: "Sirsilla",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.45081,
    longitude: 78.68366,
    wikiDataId: "Q1706460",
  },
  {
    id: 131940,
    name: "Kabrai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.58333,
    longitude: 83.58526,
    wikiDataId: "Q1006321",
  },
  {
    id: 131941,
    name: "Kachhwa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.18912,
    longitude: 78.79312,
    wikiDataId: "Q26789451",
  },
  {
    id: 131942,
    name: "Kathua",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.5559,
    longitude: 74.9539,
    wikiDataId: "Q1708861",
  },
  {
    id: 131943,
    name: "Ghoga",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.21738,
    longitude: 87.1571,
    wikiDataId: "Q1706729",
  },
  {
    id: 131944,
    name: "Ghogha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.68813,
    longitude: 72.2763,
    wikiDataId: "Q1707463",
  },
  {
    id: 131945,
    name: "Kadaura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.75459,
    longitude: 82.77965,
    wikiDataId: "Q1707134",
  },
  {
    id: 131946,
    name: "Kadipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.10587,
    longitude: 83.5393,
    wikiDataId: "Q1707016",
  },
  {
    id: 131947,
    name: "Indapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.71641,
    longitude: 73.62821,
    wikiDataId: "Q26789453",
  },
  {
    id: 131948,
    name: "Jaisingpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.9381,
    longitude: 79.11192,
    wikiDataId: "Q1706933",
  },
  {
    id: 131949,
    name: "Jubbal",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.44166,
    longitude: 76.71509,
    wikiDataId: "Q1707166",
  },
  {
    id: 131950,
    name: "Jiyanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15272,
    longitude: 80.16803,
    wikiDataId: "Q1707190",
  },
  {
    id: 131951,
    name: "Ichalkaranji",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.14183,
    longitude: 78.31333,
    wikiDataId: "Q1707151",
  },
  {
    id: 131952,
    name: "Gua",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.58531,
    longitude: 86.47682,
    wikiDataId: "Q12425262",
  },
  {
    id: 131953,
    name: "Jyotiba Phule Nagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.66535,
    longitude: 77.43915,
    wikiDataId: "Q207098",
  },
  {
    id: 131955,
    name: "Giddalur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.37439,
    longitude: 78.92609,
    wikiDataId: "Q207098",
  },
  {
    id: 131956,
    name: "Moga",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.19953,
    longitude: 74.66627,
    wikiDataId: "Q1708540",
  },
  {
    id: 131957,
    name: "Koradachcheri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.25282,
    longitude: 79.41727,
    wikiDataId: "Q1524976",
  },
  {
    id: 131958,
    name: "Gir Somnath",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.91287,
    longitude: 70.3671,
    wikiDataId: "Q1524976",
  },
  {
    id: 131959,
    name: "Gumia",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.25,
    longitude: 85.91667,
    wikiDataId: "Q1524976",
  },
  {
    id: 131960,
    name: "Goa Velha",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.44384,
    longitude: 73.88572,
    wikiDataId: "Q553907",
  },
  {
    id: 131961,
    name: "Korampallam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.45496,
    longitude: 77.4422,
    wikiDataId: "Q254244",
  },
  {
    id: 131962,
    name: "Gumla",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.63393,
    longitude: 86.07162,
    wikiDataId: "Q1706442",
  },
  {
    id: 131963,
    name: "Hazaribag",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.83333,
    longitude: 87.21667,
    wikiDataId: "Q638980",
  },
  {
    id: 131964,
    name: "Godhra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.77547,
    longitude: 73.61488,
    wikiDataId: "Q1708114",
  },
  {
    id: 131965,
    name: "Hatod",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.55746,
    longitude: 75.51665,
    wikiDataId: "Q1708114",
  },
  {
    id: 131966,
    name: "Hatta",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.43278,
    longitude: 78.44205,
    wikiDataId: "Q1707618",
  },
  {
    id: 131967,
    name: "Gohpur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.88184,
    longitude: 93.6156,
    wikiDataId: "Q1708616",
  },
  {
    id: 131968,
    name: "Hansi",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.13777,
    longitude: 76.70247,
    wikiDataId: "Q12424844",
  },
  {
    id: 131969,
    name: "Kagarol",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.69871,
    longitude: 79.54567,
    wikiDataId: "Q1707176",
  },
  {
    id: 131970,
    name: "Gundlupēt",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.16901,
    longitude: 74.82393,
    wikiDataId: "Q1707176",
  },
  {
    id: 131971,
    name: "Gurmatkal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.55,
    longitude: 74.31667,
    wikiDataId: "Q1025271",
  },
  {
    id: 131972,
    name: "Gokavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.25823,
    longitude: 81.84985,
    wikiDataId: "Q5578050",
  },
  {
    id: 131973,
    name: "Kaimganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43926,
    longitude: 77.72019,
    wikiDataId: "Q12424362",
  },
  {
    id: 131974,
    name: "Kairana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.3446,
    longitude: 83.35303,
    wikiDataId: "Q1925043",
  },
  {
    id: 131975,
    name: "Kakori",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.07837,
    longitude: 80.47054,
    wikiDataId: "Q12424722",
  },
  {
    id: 131976,
    name: "Golaghat",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.4092,
    longitude: 93.91193,
    wikiDataId: "Q42517",
  },
  {
    id: 131977,
    name: "Golakganj",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.10216,
    longitude: 89.82275,
    wikiDataId: "Q1707247",
  },
  {
    id: 131979,
    name: "Baksar",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.53399,
    longitude: 91.48122,
    wikiDataId: "Q1708160",
  },
  {
    id: 131980,
    name: "Hazaribagh",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.87355,
    longitude: 86.1516,
    wikiDataId: "Q1707276",
  },
  {
    id: 131981,
    name: "Kakrala",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.18581,
    longitude: 81.96693,
    wikiDataId: "Q2428556",
  },
  {
    id: 131982,
    name: "Gondal",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.96074,
    longitude: 70.80255,
    wikiDataId: "Q1536978",
  },
  {
    id: 131983,
    name: "Jalgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.05,
    longitude: 80.25,
    wikiDataId: "Q1536978",
  },
  {
    id: 131985,
    name: "Kalpi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.13181,
    longitude: 81.95332,
    wikiDataId: "Q2428556",
  },
  {
    id: 131986,
    name: "Hesla",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.66301,
    longitude: 86.075,
    wikiDataId: "Q2428556",
  },
  {
    id: 131987,
    name: "Gopalganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.5,
    longitude: 84.33333,
    wikiDataId: "Q49171",
  },
  {
    id: 131988,
    name: "Kiri Buru",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.25861,
    longitude: 84.90517,
    wikiDataId: "Q1747725",
  },
  {
    id: 131989,
    name: "Sriramnagar",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.6122,
    longitude: 77.80728,
    wikiDataId: "Q1747725",
  },
  {
    id: 131990,
    name: "Kalyanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.53468,
    longitude: 80.28507,
    wikiDataId: "Q734556",
  },
  {
    id: 131991,
    name: "Hasanpur",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.44768,
    longitude: 75.67206,
    wikiDataId: "Q734556",
  },
  {
    id: 131992,
    name: "Kamalganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.91667,
    longitude: 83.25,
    wikiDataId: "Q5584396",
  },
  {
    id: 131993,
    name: "Gorantla",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.98411,
    longitude: 77.77224,
    wikiDataId: "Q3415057",
  },
  {
    id: 131994,
    name: "Jalkot",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.15483,
    longitude: 73.29147,
    wikiDataId: "Q3773513",
  },
  {
    id: 131995,
    name: "Hadagalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.61072,
    longitude: 77.51738,
    wikiDataId: "Q3773513",
  },
  {
    id: 131996,
    name: "Haliyal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.82297,
    longitude: 76.06463,
    wikiDataId: "Q3773513",
  },
  {
    id: 131997,
    name: "Goshaingaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.43946,
    longitude: 89.96307,
    wikiDataId: "Q1707386",
  },
  {
    id: 131998,
    name: "Kandhla",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.57115,
    longitude: 82.38091,
    wikiDataId: "Q1707229",
  },
  {
    id: 132000,
    name: "Kannauj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.49658,
    longitude: 77.46263,
    wikiDataId: "Q1707255",
  },
  {
    id: 132001,
    name: "Govindapuram,Chilakaluripet,Guntur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.15477,
    longitude: 80.10279,
    wikiDataId: "Q1707255",
  },
  {
    id: 132002,
    name: "Hindoria",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.37845,
    longitude: 81.29644,
    wikiDataId: "Q2228620",
  },
  {
    id: 132003,
    name: "Malpura",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.50423,
    longitude: 76.99938,
    wikiDataId: "Q2228620",
  },
  {
    id: 132004,
    name: "Goalpara",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.12791,
    longitude: 90.60974,
    wikiDataId: "Q42522",
  },
  {
    id: 132005,
    name: "Kanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.49615,
    longitude: 77.53601,
    wikiDataId: "Q2711899",
  },
  {
    id: 132006,
    name: "Husainabad",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.21361,
    longitude: 85.38774,
    wikiDataId: "Q2738419",
  },
  {
    id: 132007,
    name: "Hampi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.31216,
    longitude: 76.94102,
    wikiDataId: "Q559413",
  },
  {
    id: 132008,
    name: "Kotagiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.67826,
    longitude: 77.24951,
    wikiDataId: "Q1708408",
  },
  {
    id: 132009,
    name: "Hangal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.67099,
    longitude: 77.70414,
    wikiDataId: "Q1708408",
  },
  {
    id: 132010,
    name: "Gudivada",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.43547,
    longitude: 80.99555,
    wikiDataId: "Q2350125",
  },
  {
    id: 132011,
    name: "Kottaiyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.94601,
    longitude: 78.87377,
    wikiDataId: "Q1707438",
  },
  {
    id: 132012,
    name: "Gudlavalleru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.35,
    longitude: 81.05,
    wikiDataId: "Q1707438",
  },
  {
    id: 132013,
    name: "Kodala",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.34762,
    longitude: 83.78128,
    wikiDataId: "Q1320157",
  },
  {
    id: 132014,
    name: "Jalna",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.48415,
    longitude: 73.19289,
    wikiDataId: "Q1708334",
  },
  {
    id: 132015,
    name: "Guirim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.57552,
    longitude: 73.80722,
    wikiDataId: "Q1708211",
  },
  {
    id: 132016,
    name: "Kerur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.16667,
    longitude: 77.08333,
    wikiDataId: "Q2641873",
  },
  {
    id: 132017,
    name: "Harihar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.05025,
    longitude: 75.78997,
    wikiDataId: "Q2641873",
  },
  {
    id: 132018,
    name: "Mandalgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.90448,
    longitude: 74.66025,
    wikiDataId: "Q1709684",
  },
  {
    id: 132019,
    name: "Kanpur Dehat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.58938,
    longitude: 77.79318,
    wikiDataId: "Q1707543",
  },
  {
    id: 132020,
    name: "Jagannathpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.7975,
    longitude: 85.82523,
    wikiDataId: "Q1707401",
  },
  {
    id: 132021,
    name: "Jamadoba",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.08055,
    longitude: 84.53834,
    wikiDataId: "Q2295865",
  },
  {
    id: 132023,
    name: "Krishnagiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.40765,
    longitude: 80.10879,
    wikiDataId: "Q12423923",
  },
  {
    id: 132024,
    name: "Hoshangabad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.55464,
    longitude: 77.20082,
    wikiDataId: "Q930027",
  },
  {
    id: 132025,
    name: "Harpanahalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.81004,
    longitude: 76.69027,
    wikiDataId: "Q1708600",
  },
  {
    id: 132026,
    name: "Kant",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.23995,
    longitude: 78.43994,
    wikiDataId: "Q1708842",
  },
  {
    id: 132027,
    name: "Guntakal Junction",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.17112,
    longitude: 77.36244,
    wikiDataId: "Q586397",
  },
  {
    id: 132028,
    name: "Guntur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.29974,
    longitude: 80.45729,
    wikiDataId: "Q3120966",
  },
  {
    id: 132030,
    name: "Konarka",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.0804,
    longitude: 83.80879,
    wikiDataId: "Q1707762",
  },
  {
    id: 132031,
    name: "Mohali",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.92,
    longitude: 75.27,
    wikiDataId: "Q146708",
  },
  {
    id: 132032,
    name: "Hisar",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.43891,
    longitude: 77.00592,
    wikiDataId: "Q1815766",
  },
  {
    id: 132033,
    name: "Iawar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.50265,
    longitude: 81.50037,
    wikiDataId: "Q1706610",
  },
  {
    id: 132034,
    name: "Hassan",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.86773,
    longitude: 77.39088,
    wikiDataId: "Q1706530",
  },
  {
    id: 132035,
    name: "Kanth",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.11518,
    longitude: 79.73174,
    wikiDataId: "Q1707142",
  },
  {
    id: 132036,
    name: "Kaptanganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.61677,
    longitude: 79.18053,
    wikiDataId: "Q1707577",
  },
  {
    id: 132037,
    name: "Morinda",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.70862,
    longitude: 74.40407,
    wikiDataId: "Q1706501",
  },
  {
    id: 132038,
    name: "Kannur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.5943,
    longitude: 76.0411,
    wikiDataId: "Q1706501",
  },
  {
    id: 132039,
    name: "Guwahati",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.1844,
    longitude: 91.7458,
    wikiDataId: "Q199605",
  },
  {
    id: 132040,
    name: "Ichhawar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.22983,
    longitude: 78.17337,
    wikiDataId: "Q158467",
  },
  {
    id: 132041,
    name: "Chengam",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.28952,
    longitude: 88.25764,
    wikiDataId: "Q1707638",
  },
  {
    id: 132042,
    name: "Kara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.33268,
    longitude: 82.46637,
    wikiDataId: "Q1557962",
  },
  {
    id: 132043,
    name: "Harda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.9235,
    longitude: 78.7849,
    wikiDataId: "Q1525301",
  },
  {
    id: 132044,
    name: "Jammu",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.22619,
    longitude: 74.77478,
    wikiDataId: "Q2308228",
  },
  {
    id: 132045,
    name: "Kombai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.00639,
    longitude: 80.25417,
    wikiDataId: "Q1657026",
  },
  {
    id: 132046,
    name: "Gandhidham",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.08333,
    longitude: 70.13333,
    wikiDataId: "Q41543",
  },
  {
    id: 132048,
    name: "Gidam",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.97431,
    longitude: 81.39894,
    wikiDataId: "Q41543",
  },
  {
    id: 132050,
    name: "Kovilpatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.84519,
    longitude: 80.06055,
    wikiDataId: "Q202132",
  },
  {
    id: 132051,
    name: "Gudur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.15093,
    longitude: 79.8521,
    wikiDataId: "Q1708134",
  },
  {
    id: 132052,
    name: "Suriapet",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.49174,
    longitude: 78.82302,
    wikiDataId: "Q1708134",
  },
  {
    id: 132053,
    name: "Haveri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.02048,
    longitude: 75.93185,
    wikiDataId: "Q1708134",
  },
  {
    id: 132054,
    name: "Jamod",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.49552,
    longitude: 77.65863,
    wikiDataId: "Q1708363",
  },
  {
    id: 132055,
    name: "Hailakandi",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.5017,
    longitude: 92.60069,
    wikiDataId: "Q42505",
  },
  {
    id: 132057,
    name: "Karari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.28988,
    longitude: 78.28437,
    wikiDataId: "Q1707597",
  },
  {
    id: 132058,
    name: "Dhulagari",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.22254,
    longitude: 79.5286,
    wikiDataId: "Q1707839",
  },
  {
    id: 132059,
    name: "Heggadadevankote",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.32864,
    longitude: 74.75638,
    wikiDataId: "Q1707839",
  },
  {
    id: 132060,
    name: "Halvad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.01516,
    longitude: 71.18029,
    wikiDataId: "Q1707007",
  },
  {
    id: 132061,
    name: "Hirekerur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.3352,
    longitude: 76.4603,
    wikiDataId: "Q1707007",
  },
  {
    id: 132062,
    name: "Karbigwan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 80,
    wikiDataId: "Q2737448",
  },
  {
    id: 132063,
    name: "Jutogh",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.75,
    longitude: 76.5,
    wikiDataId: "Q2086180",
  },
  {
    id: 132064,
    name: "Karchana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.36379,
    longitude: 82.18655,
    wikiDataId: "Q1707590",
  },
  {
    id: 132065,
    name: "Hansot",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.58496,
    longitude: 72.80764,
    wikiDataId: "Q2777153",
  },
  {
    id: 132066,
    name: "Mandawar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.11,
    longitude: 74.6,
    wikiDataId: "Q2777153",
  },
  {
    id: 132068,
    name: "Kausani",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.79477,
    longitude: 82.46436,
    wikiDataId: "Q1707848",
  },
  {
    id: 132069,
    name: "Dhulian",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.43863,
    longitude: 77.74058,
    wikiDataId: "Q1707100",
  },
  {
    id: 132070,
    name: "Iklehra",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23406,
    longitude: 76.96431,
    wikiDataId: "Q2173003",
  },
  {
    id: 132071,
    name: "Indore",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.34414,
    longitude: 77.09536,
    wikiDataId: "Q1707802",
  },
  {
    id: 132072,
    name: "Kemri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.41667,
    longitude: 80.25,
    wikiDataId: "Q1708143",
  },
  {
    id: 132074,
    name: "Khada",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.94361,
    longitude: 78.15789,
    wikiDataId: "Q1707052",
  },
  {
    id: 132075,
    name: "Dhupguri",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.94791,
    longitude: 78.16025,
    wikiDataId: "Q273030",
  },
  {
    id: 132076,
    name: "Holalkere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.51288,
    longitude: 75.80716,
    wikiDataId: "Q1708108",
  },
  {
    id: 132077,
    name: "Nabha",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.63512,
    longitude: 75.83887,
    wikiDataId: "Q1706366",
  },
  {
    id: 132078,
    name: "Jat, Sangli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.8134,
    longitude: 73.09668,
    wikiDataId: "Q1706366",
  },
  {
    id: 132079,
    name: "Hole Narsipur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.78766,
    longitude: 75.98863,
    wikiDataId: "Q1707612",
  },
  {
    id: 132080,
    name: "Isagarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.28773,
    longitude: 79.33279,
    wikiDataId: "Q1709670",
  },
  {
    id: 132081,
    name: "Itarsi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.61428,
    longitude: 79.22207,
    wikiDataId: "Q1706431",
  },
  {
    id: 132082,
    name: "Jabalpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.09947,
    longitude: 76.73423,
    wikiDataId: "Q1708353",
  },
  {
    id: 132083,
    name: "Kulattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.05267,
    longitude: 78.48023,
    wikiDataId: "Q1707447",
  },
  {
    id: 132084,
    name: "Inda Chhoi",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.96944,
    longitude: 77.49544,
    wikiDataId: "Q1707447",
  },
  {
    id: 132085,
    name: "Khaga",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.72249,
    longitude: 78.28436,
    wikiDataId: "Q12460079",
  },
  {
    id: 132086,
    name: "Homnabad",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.95,
    longitude: 76.08333,
    wikiDataId: "Q956732",
  },
  {
    id: 132087,
    name: "Khair",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.16042,
    longitude: 78.00762,
    wikiDataId: "Q956732",
  },
  {
    id: 132088,
    name: "Jaithari",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.13406,
    longitude: 79.60119,
    wikiDataId: "Q956732",
  },
  {
    id: 132089,
    name: "Honavar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.73732,
    longitude: 75.41062,
    wikiDataId: "Q765481",
  },
  {
    id: 132090,
    name: "Hayuliang",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.07301,
    longitude: 96.54305,
    wikiDataId: "Q18348790",
  },
  {
    id: 132091,
    name: "Jamtara",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.99241,
    longitude: 85.36162,
    wikiDataId: "Q1024667",
  },
  {
    id: 132092,
    name: "Jamshedpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24,
    longitude: 85.25,
    wikiDataId: "Q1945416",
  },
  {
    id: 132093,
    name: "Honnali",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.08809,
    longitude: 76.32957,
    wikiDataId: "Q1707117",
  },
  {
    id: 132094,
    name: "Jasidih",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.06313,
    longitude: 85.87905,
    wikiDataId: "Q1707332",
  },
  {
    id: 132095,
    name: "Hilsa",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.31642,
    longitude: 85.28234,
    wikiDataId: "Q2734910",
  },
  {
    id: 132096,
    name: "Himatnagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.59893,
    longitude: 72.96602,
    wikiDataId: "Q1708155",
  },
  {
    id: 132097,
    name: "Manohar Thana",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.73411,
    longitude: 77.03519,
    wikiDataId: "Q1709662",
  },
  {
    id: 132098,
    name: "Jamai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.90345,
    longitude: 79.56861,
    wikiDataId: "Q1706686",
  },
  {
    id: 132099,
    name: "Hindupur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.82807,
    longitude: 77.49143,
    wikiDataId: "Q760056",
  },
  {
    id: 132100,
    name: "Jawhar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.54875,
    longitude: 78.83978,
    wikiDataId: "Q762823",
  },
  {
    id: 132101,
    name: "Jaysingpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.7,
    longitude: 77.15,
    wikiDataId: "Q1708167",
  },
  {
    id: 132102,
    name: "Koraput",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.48166,
    longitude: 84.74489,
    wikiDataId: "Q1708660",
  },
  {
    id: 132103,
    name: "Hosanagara",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.45506,
    longitude: 75.3952,
    wikiDataId: "Q1706912",
  },
  {
    id: 132104,
    name: "Hosangadi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.94455,
    longitude: 76.61723,
    wikiDataId: "Q1708067",
  },
  {
    id: 132105,
    name: "Khaur",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.45493,
    longitude: 75.27187,
    wikiDataId: "Q1707549",
  },
  {
    id: 132106,
    name: "Indri",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.15394,
    longitude: 75.72294,
    wikiDataId: "Q1772825",
  },
  {
    id: 132107,
    name: "Hisua",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.8336,
    longitude: 85.41729,
    wikiDataId: "Q2317259",
  },
  {
    id: 132109,
    name: "Jagadhri",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.89196,
    longitude: 77.36744,
    wikiDataId: "Q616583",
  },
  {
    id: 132110,
    name: "Hojai",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.00281,
    longitude: 92.85605,
    wikiDataId: "Q640538",
  },
  {
    id: 132111,
    name: "Hosdurga",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.04295,
    longitude: 76.18496,
    wikiDataId: "Q1122103",
  },
  {
    id: 132112,
    name: "Hoskote",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.78635,
    longitude: 76.24331,
    wikiDataId: "Q1122103",
  },
  {
    id: 132113,
    name: "Hospet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.77074,
    longitude: 77.12519,
    wikiDataId: "Q1708205",
  },
  {
    id: 132114,
    name: "Hukeri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.23976,
    longitude: 75.64507,
    wikiDataId: "Q1708205",
  },
  {
    id: 132115,
    name: "Hubballi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.28088,
    longitude: 74.44497,
    wikiDataId: "Q1708098",
  },
  {
    id: 132117,
    name: "Hungund",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.91387,
    longitude: 75.06503,
    wikiDataId: "Q1706414",
  },
  {
    id: 132118,
    name: "Hunsur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.69756,
    longitude: 74.95427,
    wikiDataId: "Q1706414",
  },
  {
    id: 132119,
    name: "Ilkal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.79631,
    longitude: 76.28408,
    wikiDataId: "Q1708080",
  },
  {
    id: 132120,
    name: "Jaora",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.58827,
    longitude: 77.98887,
    wikiDataId: "Q620801",
  },
  {
    id: 132122,
    name: "Nakodar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.53723,
    longitude: 75.91269,
    wikiDataId: "Q928349",
  },
  {
    id: 132123,
    name: "Indi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.0707,
    longitude: 77.79814,
    wikiDataId: "Q1708186",
  },
  {
    id: 132124,
    name: "Jagalur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.26954,
    longitude: 76.3871,
    wikiDataId: "Q33414",
  },
  {
    id: 132125,
    name: "Kulittalai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.73647,
    longitude: 77.83264,
    wikiDataId: "Q297967",
  },
  {
    id: 132126,
    name: "Howli",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.42237,
    longitude: 90.98004,
    wikiDataId: "Q1707538",
  },
  {
    id: 132127,
    name: "Jamkhandi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.34776,
    longitude: 75.13378,
    wikiDataId: "Q33412",
  },
  {
    id: 132128,
    name: "Jevargi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.23082,
    longitude: 74.60244,
    wikiDataId: "Q1755404",
  },
  {
    id: 132129,
    name: "Kadur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.06213,
    longitude: 76.0586,
    wikiDataId: "Q46643",
  },
  {
    id: 132130,
    name: "Kalaburgi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.30359,
    longitude: 76.29275,
    wikiDataId: "Q1755397",
  },
  {
    id: 132131,
    name: "Jharia",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.52849,
    longitude: 84,
    wikiDataId: "Q1755397",
  },
  {
    id: 132132,
    name: "Tandur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.38405,
    longitude: 78.45636,
    wikiDataId: "Q1361",
  },
  {
    id: 132134,
    name: "Haflong",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.16478,
    longitude: 93.01744,
    wikiDataId: "Q1026900",
  },
  {
    id: 132135,
    name: "Katra",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.29895,
    longitude: 74.61681,
    wikiDataId: "Q1707355",
  },
  {
    id: 132136,
    name: "Mukerian",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.97714,
    longitude: 75.75438,
    wikiDataId: "Q1707355",
  },
  {
    id: 132137,
    name: "Hajo",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2452,
    longitude: 91.52525,
    wikiDataId: "Q1707355",
  },
  {
    id: 132138,
    name: "Hajipur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.68544,
    longitude: 85.20981,
    wikiDataId: "Q1949403",
  },
  {
    id: 132139,
    name: "Halol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.50321,
    longitude: 73.47242,
    wikiDataId: "Q1707605",
  },
  {
    id: 132140,
    name: "Hiriyur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.76465,
    longitude: 75.1246,
    wikiDataId: "Q1707624",
  },
  {
    id: 132141,
    name: "Hodal",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.10239,
    longitude: 75.96253,
    wikiDataId: "Q1707791",
  },
  {
    id: 132142,
    name: "Karhal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.72985,
    longitude: 77.78068,
    wikiDataId: "Q11139912",
  },
  {
    id: 132143,
    name: "Harij",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.69356,
    longitude: 71.907,
    wikiDataId: "Q1708423",
  },
  {
    id: 132144,
    name: "Kharela",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.59551,
    longitude: 78.05201,
    wikiDataId: "Q1709695",
  },
  {
    id: 132145,
    name: "Jaisinghnagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.79378,
    longitude: 75.73928,
    wikiDataId: "Q1706662",
  },
  {
    id: 132146,
    name: "Khairabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.7412,
    longitude: 83.74526,
    wikiDataId: "Q3783627",
  },
  {
    id: 132148,
    name: "Kuchaiburi",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.52502,
    longitude: 83.87275,
    wikiDataId: "Q1620116",
  },
  {
    id: 132149,
    name: "Jintur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.55546,
    longitude: 79.78581,
    wikiDataId: "Q1707583",
  },
  {
    id: 132150,
    name: "Juhu",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.69117,
    longitude: 74.46054,
    wikiDataId: "Q583818",
  },
  {
    id: 132151,
    name: "Ichchapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.11393,
    longitude: 84.68721,
    wikiDataId: "Q952228",
  },
  {
    id: 132152,
    name: "Jawad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.02816,
    longitude: 77.01729,
    wikiDataId: "Q1753922",
  },
  {
    id: 132153,
    name: "Kumaralingam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.58624,
    longitude: 77.83891,
    wikiDataId: "Q9904",
  },
  {
    id: 132154,
    name: "Kasaragod",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10,
    longitude: 77,
    wikiDataId: "Q301821",
  },
  {
    id: 132155,
    name: "Junnar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.69522,
    longitude: 73.5626,
    wikiDataId: "Q1756158",
  },
  {
    id: 132156,
    name: "Khekra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.711,
    longitude: 77.93967,
    wikiDataId: "Q1755803",
  },
  {
    id: 132157,
    name: "Kheri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.53097,
    longitude: 81.96917,
    wikiDataId: "Q1753938",
  },
  {
    id: 132158,
    name: "Jhabua",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.14667,
    longitude: 76.39044,
    wikiDataId: "Q1753642",
  },
  {
    id: 132159,
    name: "Kumbakonam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.60659,
    longitude: 78.00676,
    wikiDataId: "Q1753768",
  },
  {
    id: 132160,
    name: "Kalghatgi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.95923,
    longitude: 76.11351,
    wikiDataId: "Q2282329",
  },
  {
    id: 132161,
    name: "Kunnattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.51347,
    longitude: 78.62357,
    wikiDataId: "Q1753729",
  },
  {
    id: 132162,
    name: "Tura",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.80805,
    longitude: 93.9442,
    wikiDataId: "Q208097",
  },
  {
    id: 132163,
    name: "Jakhal",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.64042,
    longitude: 75.79041,
    wikiDataId: "Q208097",
  },
  {
    id: 132164,
    name: "Khudaganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.93521,
    longitude: 79.6712,
    wikiDataId: "Q1753651",
  },
  {
    id: 132165,
    name: "Kampli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.17735,
    longitude: 75.9526,
    wikiDataId: "Q1629848",
  },
  {
    id: 132166,
    name: "Jiran",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.66667,
    longitude: 75.75,
    wikiDataId: "Q742938",
  },
  {
    id: 132167,
    name: "Jhajjar",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.87999,
    longitude: 77.05972,
    wikiDataId: "Q1619362",
  },
  {
    id: 132168,
    name: "Kachurwahi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.3,
    longitude: 73.25,
    wikiDataId: "Q1753960",
  },
  {
    id: 132169,
    name: "Kurinjippadi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9162,
    longitude: 80.2488,
    wikiDataId: "Q1753737",
  },
  {
    id: 132170,
    name: "Kasaragod District",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.55929,
    longitude: 75.61663,
    wikiDataId: "Q1753737",
  },
  {
    id: 132171,
    name: "Kattanam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.34238,
    longitude: 76.21124,
    wikiDataId: "Q1754141",
  },
  {
    id: 132172,
    name: "Kuttalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.01782,
    longitude: 77.06285,
    wikiDataId: "Q1753744",
  },
  {
    id: 132173,
    name: "Khutar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.32896,
    longitude: 78.72524,
    wikiDataId: "Q2725852",
  },
  {
    id: 132174,
    name: "Islampur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.14075,
    longitude: 85.20587,
    wikiDataId: "Q1747221",
  },
  {
    id: 132175,
    name: "Jobat",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.83906,
    longitude: 77.88274,
    wikiDataId: "Q1753790",
  },
  {
    id: 132176,
    name: "Kiraoli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.08347,
    longitude: 80.89672,
    wikiDataId: "Q1756056",
  },
  {
    id: 132177,
    name: "Kiratpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.23541,
    longitude: 78.19829,
    wikiDataId: "Q1756056",
  },
  {
    id: 132178,
    name: "Itanagar",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.08694,
    longitude: 93.60987,
    wikiDataId: "Q41128",
  },
  {
    id: 132179,
    name: "Kailaras",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.61477,
    longitude: 77.76222,
    wikiDataId: "Q928162",
  },
  {
    id: 132180,
    name: "Jatara",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.00943,
    longitude: 76.5007,
    wikiDataId: "Q928162",
  },
  {
    id: 132181,
    name: "Kaimori",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.16,
    longitude: 79.95,
    wikiDataId: "Q632093",
  },
  {
    id: 132182,
    name: "Kankanhalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.51957,
    longitude: 76.33915,
    wikiDataId: "Q2010464",
  },
  {
    id: 132183,
    name: "Jugsalai",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.22115,
    longitude: 85.63917,
    wikiDataId: "Q2010464",
  },
  {
    id: 132184,
    name: "Kuchinda",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.2557,
    longitude: 86.17112,
    wikiDataId: "Q2670444",
  },
  {
    id: 132185,
    name: "Malakanagiri",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.2,
    longitude: 86.3,
    wikiDataId: "Q971581",
  },
  {
    id: 132186,
    name: "Jagdalpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.08136,
    longitude: 82.02131,
    wikiDataId: "Q1006363",
  },
  {
    id: 132187,
    name: "Kishanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.74967,
    longitude: 80.5451,
    wikiDataId: "Q2452913",
  },
  {
    id: 132188,
    name: "Jagdispur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.46811,
    longitude: 84.41939,
    wikiDataId: "Q5475354",
  },
  {
    id: 132189,
    name: "Jaggayyapeta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.8938,
    longitude: 80.09807,
    wikiDataId: "Q5475354",
  },
  {
    id: 132191,
    name: "Kishanpur baral",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.8636,
    longitude: 77.60231,
    wikiDataId: "Q26788831",
  },
  {
    id: 132192,
    name: "Nurmahal",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.78783,
    longitude: 75.47391,
    wikiDataId: "Q2016667",
  },
  {
    id: 132193,
    name: "Uppal Kalan",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.79473,
    longitude: 78.91661,
    wikiDataId: "Q2016650",
  },
  {
    id: 132194,
    name: "Jind",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.16719,
    longitude: 77.30367,
    wikiDataId: "Q2016879",
  },
  {
    id: 132195,
    name: "Kopaganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.17919,
    longitude: 77.70501,
    wikiDataId: "Q1925049",
  },
  {
    id: 132196,
    name: "Konch",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.40549,
    longitude: 78.10588,
    wikiDataId: "Q2016849",
  },
  {
    id: 132197,
    name: "Kishni",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.63025,
    longitude: 79.71818,
    wikiDataId: "Q3806140",
  },
  {
    id: 132198,
    name: "Jahanabad",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.21368,
    longitude: 84.9871,
    wikiDataId: "Q3806140",
  },
  {
    id: 132199,
    name: "Meethari Marwar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.61994,
    longitude: 75.27609,
    wikiDataId: "Q2007612",
  },
  {
    id: 132200,
    name: "Kosi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15389,
    longitude: 83.33505,
    wikiDataId: "Q2441673",
  },
  {
    id: 132201,
    name: "Merta",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27,
    longitude: 76,
    wikiDataId: "Q1134781",
  },
  {
    id: 132202,
    name: "Kotra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2649,
    longitude: 81.54855,
    wikiDataId: "Q608760",
  },
  {
    id: 132203,
    name: "Mundwa",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.99382,
    longitude: 71.00889,
    wikiDataId: "Q1419708",
  },
  {
    id: 132204,
    name: "Kannod",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.68582,
    longitude: 81.39085,
    wikiDataId: "Q1419708",
  },
  {
    id: 132205,
    name: "Kadegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.77639,
    longitude: 74.55361,
    wikiDataId: "Q547588",
  },
  {
    id: 132206,
    name: "Kareli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.20856,
    longitude: 78.61487,
    wikiDataId: "Q547588",
  },
  {
    id: 132207,
    name: "Nurpur Kalan",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.45126,
    longitude: 74.89189,
    wikiDataId: "Q547588",
  },
  {
    id: 132208,
    name: "Nadbai",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.20446,
    longitude: 73.93676,
    wikiDataId: "Q2007146",
  },
  {
    id: 132209,
    name: "Malkangiri",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.84149,
    longitude: 86.31237,
    wikiDataId: "Q2087771",
  },
  {
    id: 132210,
    name: "Kuzhithurai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.69779,
    longitude: 77.87298,
    wikiDataId: "Q2008545",
  },
  {
    id: 132211,
    name: "Patiala",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.41667,
    longitude: 75.61667,
    wikiDataId: "Q1817425",
  },
  {
    id: 132212,
    name: "Kundarkhi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.47315,
    longitude: 78.3031,
    wikiDataId: "Q2007607",
  },
  {
    id: 132213,
    name: "Mayurbhanj",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.80176,
    longitude: 87.2225,
    wikiDataId: "Q2008618",
  },
  {
    id: 132214,
    name: "Kaij",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.00292,
    longitude: 75.56602,
    wikiDataId: "Q30951",
  },
  {
    id: 132215,
    name: "Kalamb",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.05194,
    longitude: 76.53464,
    wikiDataId: "Q2016803",
  },
  {
    id: 132216,
    name: "Kalamboli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.8,
    longitude: 75.9,
    wikiDataId: "Q30771",
  },
  {
    id: 132217,
    name: "Nagaur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.34558,
    longitude: 72.61559,
    wikiDataId: "Q1804863",
  },
  {
    id: 132218,
    name: "Nainwa",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.08,
    longitude: 72.29,
    wikiDataId: "Q1804863",
  },
  {
    id: 132219,
    name: "Jalalpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.94896,
    longitude: 72.89829,
    wikiDataId: "Q2013180",
  },
  {
    id: 132220,
    name: "Kulpahar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.31162,
    longitude: 82.73859,
    wikiDataId: "Q2016869",
  },
  {
    id: 132221,
    name: "Kuchesar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.61853,
    longitude: 77.43908,
    wikiDataId: "Q2016869",
  },
  {
    id: 132223,
    name: "Pathankot",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.60622,
    longitude: 74.25727,
    wikiDataId: "Q2016869",
  },
  {
    id: 132224,
    name: "Kudarkot",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.8668,
    longitude: 78.25267,
    wikiDataId: "Q2544386",
  },
  {
    id: 132225,
    name: "Lalgudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.57025,
    longitude: 78.57318,
    wikiDataId: "Q2544386",
  },
  {
    id: 132226,
    name: "Jambusar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.05236,
    longitude: 72.80074,
    wikiDataId: "Q2013301",
  },
  {
    id: 132227,
    name: "Karkala",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.50461,
    longitude: 75.29146,
    wikiDataId: "Q2018128",
  },
  {
    id: 132228,
    name: "Jammalamadugu",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.84677,
    longitude: 78.38314,
    wikiDataId: "Q2010440",
  },
  {
    id: 132229,
    name: "Kishtwar",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.75,
    longitude: 74.83333,
    wikiDataId: "Q1947371",
  },
  {
    id: 132230,
    name: "Jamnagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.47292,
    longitude: 70.06673,
    wikiDataId: "Q1154",
  },
  {
    id: 132231,
    name: "Kalikapur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.80278,
    longitude: 86.18545,
    wikiDataId: "Q200054",
  },
  {
    id: 132232,
    name: "Kandra",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24,
    longitude: 86.85,
    wikiDataId: "Q2980986",
  },
  {
    id: 132233,
    name: "Jamui",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.92082,
    longitude: 86.17538,
    wikiDataId: "Q49168",
  },
  {
    id: 132234,
    name: "Jamalpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.31258,
    longitude: 86.48888,
    wikiDataId: "Q2734136",
  },
  {
    id: 132236,
    name: "Patti",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.1593,
    longitude: 75.61755,
    wikiDataId: "Q532365",
  },
  {
    id: 132237,
    name: "Phagwara",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.56198,
    longitude: 75.0277,
    wikiDataId: "Q3806985",
  },
  {
    id: 132238,
    name: "Vemalwada",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.72602,
    longitude: 79.15236,
    wikiDataId: "Q2483796",
  },
  {
    id: 132239,
    name: "Vikarabad",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.72943,
    longitude: 79.16096,
    wikiDataId: "Q2483796",
  },
  {
    id: 132240,
    name: "Janjgir-Champa",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.9,
    longitude: 82.7,
    wikiDataId: "Q2575633",
  },
  {
    id: 132241,
    name: "Karrapur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63783,
    longitude: 75.12711,
    wikiDataId: "Q1287117",
  },
  {
    id: 132242,
    name: "Kushinagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.1629,
    longitude: 81.54179,
    wikiDataId: "Q2441603",
  },
  {
    id: 132243,
    name: "Jasdan",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.03709,
    longitude: 71.20794,
    wikiDataId: "Q1714838",
  },
  {
    id: 132244,
    name: "Jashpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.78495,
    longitude: 83.84573,
    wikiDataId: "Q2577551",
  },
  {
    id: 132245,
    name: "Jashpurnagar",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.88783,
    longitude: 84.13864,
    wikiDataId: "Q2577551",
  },
  {
    id: 132246,
    name: "Kanke",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.51379,
    longitude: 86.64576,
    wikiDataId: "Q2558207",
  },
  {
    id: 132247,
    name: "Diamond Harbour",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.27919,
    longitude: 78.82798,
    wikiDataId: "Q2450948",
  },
  {
    id: 132248,
    name: "Kusmara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.23587,
    longitude: 78.65244,
    wikiDataId: "Q774370",
  },
  {
    id: 132249,
    name: "Kuthaund",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.88271,
    longitude: 78.90256,
    wikiDataId: "Q2441652",
  },
  {
    id: 132250,
    name: "Nabarangpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.15975,
    longitude: 85.70742,
    wikiDataId: "Q2352196",
  },
  {
    id: 132251,
    name: "Kasrawad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.00964,
    longitude: 79.04869,
    wikiDataId: "Q2314429",
  },
  {
    id: 132252,
    name: "Laharpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 82.75,
    wikiDataId: "Q1025475",
  },
  {
    id: 132253,
    name: "Kud",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.83255,
    longitude: 74.57612,
    wikiDataId: "Q1356060",
  },
  {
    id: 132254,
    name: "Kalyani Nagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.91213,
    longitude: 73.22679,
    wikiDataId: "Q2317504",
  },
  {
    id: 132255,
    name: "Kalka",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.87456,
    longitude: 76.32013,
    wikiDataId: "Q2317504",
  },
  {
    id: 132256,
    name: "Madambakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.21266,
    longitude: 79.36369,
    wikiDataId: "Q2317504",
  },
  {
    id: 132257,
    name: "Wanparti",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.19678,
    longitude: 79.93976,
    wikiDataId: "Q2317504",
  },
  {
    id: 132258,
    name: "Jaynagar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.59048,
    longitude: 86.13791,
    wikiDataId: "Q2317504",
  },
  {
    id: 132259,
    name: "Jehanabad",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.20701,
    longitude: 84.99573,
    wikiDataId: "Q49176",
  },
  {
    id: 132260,
    name: "Kamatgi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.27658,
    longitude: 74.16008,
    wikiDataId: "Q1755879",
  },
  {
    id: 132261,
    name: "Jetalsar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.70891,
    longitude: 70.57695,
    wikiDataId: "Q26788212",
  },
  {
    id: 132262,
    name: "Jetpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.75482,
    longitude: 70.62347,
    wikiDataId: "Q968713",
  },
  {
    id: 132263,
    name: "Karwar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.01394,
    longitude: 76.77317,
    wikiDataId: "Q581554",
  },
  {
    id: 132264,
    name: "Lakhna",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.122,
    longitude: 77.55734,
    wikiDataId: "Q2281629",
  },
  {
    id: 132265,
    name: "Nayagarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.8563,
    longitude: 82.5716,
    wikiDataId: "Q2281629",
  },
  {
    id: 132266,
    name: "Kalanaur",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.6063,
    longitude: 76.6565,
    wikiDataId: "Q2304016",
  },
  {
    id: 132267,
    name: "Jhanjharpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.26467,
    longitude: 86.27993,
    wikiDataId: "Q607709",
  },
  {
    id: 132268,
    name: "Katras",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.74079,
    longitude: 86.41456,
    wikiDataId: "Q12429699",
  },
  {
    id: 132269,
    name: "Nayagarh District",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.85531,
    longitude: 84.00698,
    wikiDataId: "Q2088489",
  },
  {
    id: 132270,
    name: "Nilgiri",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.85,
    longitude: 84,
    wikiDataId: "Q569181",
  },
  {
    id: 132271,
    name: "Lawar Khas",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.52118,
    longitude: 77.2247,
    wikiDataId: "Q2441593",
  },
  {
    id: 132272,
    name: "Jhulasan",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.3286,
    longitude: 72.47314,
    wikiDataId: "Q2758980",
  },
  {
    id: 132273,
    name: "Nasirabad",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.12559,
    longitude: 75.39797,
    wikiDataId: "Q1006350",
  },
  {
    id: 132274,
    name: "Jha-Jha",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.77107,
    longitude: 86.37888,
    wikiDataId: "Q1006350",
  },
  {
    id: 132275,
    name: "Katni",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.76772,
    longitude: 74.59087,
    wikiDataId: "Q1922267",
  },
  {
    id: 132276,
    name: "Naraina",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.54205,
    longitude: 76.17242,
    wikiDataId: "Q925884",
  },
  {
    id: 132277,
    name: "Lambhua",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.33609,
    longitude: 78.22608,
    wikiDataId: "Q2441614",
  },
  {
    id: 132278,
    name: "Napasar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.59633,
    longitude: 76.16499,
    wikiDataId: "Q538013",
  },
  {
    id: 132279,
    name: "Lar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 78.5,
    wikiDataId: "Q242995",
  },
  {
    id: 132280,
    name: "Lawar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.56093,
    longitude: 79.73423,
    wikiDataId: "Q269511",
  },
  {
    id: 132281,
    name: "Loni",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.43745,
    longitude: 81.9055,
    wikiDataId: "Q2308530",
  },
  {
    id: 132282,
    name: "Kamptee",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.61186,
    longitude: 76.6874,
    wikiDataId: "Q2444446",
  },
  {
    id: 132283,
    name: "Khailar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.41599,
    longitude: 74.56828,
    wikiDataId: "Q2314355",
  },
  {
    id: 132284,
    name: "Nathdwara",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.97257,
    longitude: 75.38752,
    wikiDataId: "Q2442024",
  },
  {
    id: 132285,
    name: "Jodhpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.90174,
    longitude: 70.0327,
    wikiDataId: "Q1609645",
  },
  {
    id: 132286,
    name: "Nawa",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 72.75,
    wikiDataId: "Q1434965",
  },
  {
    id: 132287,
    name: "Jodiya Bandar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.71667,
    longitude: 70.28333,
    wikiDataId: "Q1434965",
  },
  {
    id: 132288,
    name: "Jogbani",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.39905,
    longitude: 87.26525,
    wikiDataId: "Q2286929",
  },
  {
    id: 132289,
    name: "Kangar",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.98727,
    longitude: 76.78906,
    wikiDataId: "Q2087722",
  },
  {
    id: 132290,
    name: "Warangal",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.23401,
    longitude: 77.80564,
    wikiDataId: "Q2087722",
  },
  {
    id: 132291,
    name: "Jogighopa",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.22646,
    longitude: 90.57247,
    wikiDataId: "Q6214788",
  },
  {
    id: 132293,
    name: "Chennai",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.10696,
    longitude: 88.32332,
    wikiDataId: "Q2342106",
  },
  {
    id: 132294,
    name: "Jorhat",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.8,
    longitude: 94.26,
    wikiDataId: "Q42611",
  },
  {
    id: 132296,
    name: "Digha",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.55543,
    longitude: 79.56436,
    wikiDataId: "Q1026878",
  },
  {
    id: 132297,
    name: "Jua",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.5307,
    longitude: 73.95047,
    wikiDataId: "Q1026878",
  },
  {
    id: 132298,
    name: "Kangra",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.10923,
    longitude: 77.65085,
    wikiDataId: "Q2286859",
  },
  {
    id: 132299,
    name: "Kenduadih",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.77668,
    longitude: 86.18351,
    wikiDataId: "Q2558165",
  },
  {
    id: 132300,
    name: "Kharsawan",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.4349,
    longitude: 85.52951,
    wikiDataId: "Q2355907",
  },
  {
    id: 132301,
    name: "Kankauli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.20815,
    longitude: 73.8752,
    wikiDataId: "Q2445461",
  },
  {
    id: 132302,
    name: "Kasauli",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.1,
    longitude: 77.11667,
    wikiDataId: "Q2317123",
  },
  {
    id: 132303,
    name: "Lucknow District",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.7716,
    longitude: 78.33871,
    wikiDataId: "Q1891677",
  },
  {
    id: 132305,
    name: "Kaithal",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.79627,
    longitude: 75.82392,
    wikiDataId: "Q2016886",
  },
  {
    id: 132306,
    name: "Kunda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26,
    longitude: 79.5,
    wikiDataId: "Q2016904",
  },
  {
    id: 132308,
    name: "Jumri Tilaiya",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.71667,
    longitude: 86.4,
    wikiDataId: "Q2008058",
  },
  {
    id: 132309,
    name: "Karera",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.19644,
    longitude: 78.59191,
    wikiDataId: "Q2446421",
  },
  {
    id: 132312,
    name: "Janjgir",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.00922,
    longitude: 82.5778,
    wikiDataId: "Q3634114",
  },
  {
    id: 132313,
    name: "Kurara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.32502,
    longitude: 77.85044,
    wikiDataId: "Q2441565",
  },
  {
    id: 132314,
    name: "Katangi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.59916,
    longitude: 74.86261,
    wikiDataId: "Q2314365",
  },
  {
    id: 132315,
    name: "Kalanwali",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.31577,
    longitude: 76.31502,
    wikiDataId: "Q1807935",
  },
  {
    id: 132316,
    name: "Khachrod",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.30871,
    longitude: 74.89087,
    wikiDataId: "Q2552146",
  },
  {
    id: 132317,
    name: "Junagadh",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25,
    longitude: 70.33333,
    wikiDataId: "Q11864",
  },
  {
    id: 132318,
    name: "Junagarh",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.85993,
    longitude: 82.93385,
    wikiDataId: "Q1937526",
  },
  {
    id: 132319,
    name: "Kabeerdham",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.1,
    longitude: 81.2,
    wikiDataId: "Q2450255",
  },
  {
    id: 132320,
    name: "Machhali Shahar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.40281,
    longitude: 79.9997,
    wikiDataId: "Q2441646",
  },
  {
    id: 132321,
    name: "Kachchh",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.58333,
    longitude: 70,
    wikiDataId: "Q1063417",
  },
  {
    id: 132322,
    name: "Machhlishahr",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.20615,
    longitude: 82.71442,
    wikiDataId: "Q2441584",
  },
  {
    id: 132323,
    name: "Kayankulam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.67921,
    longitude: 76.76714,
    wikiDataId: "Q2441584",
  },
  {
    id: 132324,
    name: "Madipakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.99739,
    longitude: 77.86191,
    wikiDataId: "Q2443947",
  },
  {
    id: 132325,
    name: "Madhoganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.98537,
    longitude: 79.83842,
    wikiDataId: "Q2441694",
  },
  {
    id: 132326,
    name: "Madukkarai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.07277,
    longitude: 77.34152,
    wikiDataId: "Q2445990",
  },
  {
    id: 132327,
    name: "Kadi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.29908,
    longitude: 72.33362,
    wikiDataId: "Q2726621",
  },
  {
    id: 132328,
    name: "Kadiri",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.11168,
    longitude: 78.15982,
    wikiDataId: "Q2351757",
  },
  {
    id: 132329,
    name: "Kadod",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.21717,
    longitude: 73.21972,
    wikiDataId: "Q2726639",
  },
  {
    id: 132330,
    name: "Kavalur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.55285,
    longitude: 76.01164,
    wikiDataId: "Q2351757",
  },
  {
    id: 132331,
    name: "Yellandu",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.33159,
    longitude: 79.46605,
    wikiDataId: "Q2290801",
  },
  {
    id: 132332,
    name: "Kaikalur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.55154,
    longitude: 81.214,
    wikiDataId: "Q2290801",
  },
  {
    id: 132333,
    name: "Khajuraho Group of Monuments",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.30498,
    longitude: 77.616,
    wikiDataId: "Q2313276",
  },
  {
    id: 132334,
    name: "Balamau",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.33199,
    longitude: 92.00391,
    wikiDataId: "Q2576072",
  },
  {
    id: 132335,
    name: "Maharajganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.55441,
    longitude: 79.33525,
    wikiDataId: "Q2543754",
  },
  {
    id: 132336,
    name: "Khamaria",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.38465,
    longitude: 79.7442,
    wikiDataId: "Q2234399",
  },
  {
    id: 132337,
    name: "Kaimur District",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.05077,
    longitude: 83.58261,
    wikiDataId: "Q77367",
  },
  {
    id: 132338,
    name: "Nimaparha",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.72115,
    longitude: 84.53514,
    wikiDataId: "Q588668",
  },
  {
    id: 132339,
    name: "Mahmudabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.39541,
    longitude: 77.2054,
    wikiDataId: "Q2450872",
  },
  {
    id: 132340,
    name: "Kanina Khas",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.80153,
    longitude: 76.39959,
    wikiDataId: "Q1949385",
  },
  {
    id: 132341,
    name: "West Garo Hills",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.4982,
    longitude: 93.98126,
    wikiDataId: "Q2466061",
  },
  {
    id: 132342,
    name: "Maholi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.89269,
    longitude: 79.1945,
    wikiDataId: "Q2441509",
  },
  {
    id: 132343,
    name: "Madukkur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.5138,
    longitude: 77.54944,
    wikiDataId: "Q2283418",
  },
  {
    id: 132344,
    name: "Kizhake Chalakudi",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.0614,
    longitude: 76.32631,
    wikiDataId: "Q2475675",
  },
  {
    id: 132345,
    name: "Karanja Lad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.04437,
    longitude: 73.95554,
    wikiDataId: "Q3812558",
  },
  {
    id: 132346,
    name: "Karmala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.67386,
    longitude: 77.31149,
    wikiDataId: "Q2442616",
  },
  {
    id: 132347,
    name: "Phillaur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.01227,
    longitude: 75.15063,
    wikiDataId: "Q2625405",
  },
  {
    id: 132348,
    name: "Kasara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.17241,
    longitude: 74.79045,
    wikiDataId: "Q2625405",
  },
  {
    id: 132349,
    name: "Madurai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.77029,
    longitude: 79.41999,
    wikiDataId: "Q2443833",
  },
  {
    id: 132350,
    name: "Kodungallur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.57046,
    longitude: 76.32756,
    wikiDataId: "Q3530107",
  },
  {
    id: 132351,
    name: "Khanapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.18315,
    longitude: 74.97099,
    wikiDataId: "Q2452207",
  },
  {
    id: 132352,
    name: "Madurantakam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.7404,
    longitude: 78.959,
    wikiDataId: "Q2452207",
  },
  {
    id: 132353,
    name: "Mallapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.68591,
    longitude: 77.46592,
    wikiDataId: "Q2452207",
  },
  {
    id: 132354,
    name: "Mallasamudram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.71667,
    longitude: 77.86667,
    wikiDataId: "Q2452207",
  },
  {
    id: 132355,
    name: "Kati",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.23219,
    longitude: 78.91988,
    wikiDataId: "Q26788279",
  },
  {
    id: 132356,
    name: "Kollam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.60871,
    longitude: 76.08343,
    wikiDataId: "Q911025",
  },
  {
    id: 132357,
    name: "Mallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.14941,
    longitude: 77.70569,
    wikiDataId: "Q2443878",
  },
  {
    id: 132358,
    name: "Kalyandurg",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.54519,
    longitude: 77.10552,
    wikiDataId: "Q2738467",
  },
  {
    id: 132359,
    name: "Katral",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.2437,
    longitude: 73.13554,
    wikiDataId: "Q366754",
  },
  {
    id: 132360,
    name: "Karnal",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.82823,
    longitude: 76.3955,
    wikiDataId: "Q368353",
  },
  {
    id: 132361,
    name: "Mahul",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.26181,
    longitude: 79.63134,
    wikiDataId: "Q2441528",
  },
  {
    id: 132362,
    name: "Baldeo",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.19593,
    longitude: 91.83438,
    wikiDataId: "Q2564042",
  },
  {
    id: 132363,
    name: "Agartala",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.32567,
    longitude: 78.33416,
    wikiDataId: "Q2564042",
  },
  {
    id: 132364,
    name: "Mailani",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.61268,
    longitude: 79.27687,
    wikiDataId: "Q2544221",
  },
  {
    id: 132365,
    name: "Kodagu",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.40626,
    longitude: 76.60013,
    wikiDataId: "Q2316852",
  },
  {
    id: 132366,
    name: "Kamrup Metropolitan",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.05375,
    longitude: 92.00763,
    wikiDataId: "Q2316852",
  },
  {
    id: 132367,
    name: "Manalurpettai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.40732,
    longitude: 78.37337,
    wikiDataId: "Q2443802",
  },
  {
    id: 132368,
    name: "Manamadurai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.17209,
    longitude: 78.77935,
    wikiDataId: "Q981017",
  },
  {
    id: 132369,
    name: "Manappakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.67,
    longitude: 79.99,
    wikiDataId: "Q15157",
  },
  {
    id: 132370,
    name: "Manapparai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.83515,
    longitude: 79.70006,
    wikiDataId: "Q212332",
  },
  {
    id: 132371,
    name: "Padampur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.3,
    longitude: 84,
    wikiDataId: "Q2085500",
  },
  {
    id: 132372,
    name: "Kandukur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.21542,
    longitude: 79.9039,
    wikiDataId: "Q2564821",
  },
  {
    id: 132373,
    name: "Manavalakurichi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.00599,
    longitude: 77.5609,
    wikiDataId: "Q2564821",
  },
  {
    id: 132374,
    name: "Kanigiri",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.40555,
    longitude: 79.50694,
    wikiDataId: "Q12992748",
  },
  {
    id: 132375,
    name: "Khapa",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.26609,
    longitude: 73.71217,
    wikiDataId: "Q2549200",
  },
  {
    id: 132376,
    name: "Kankipadu",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.4353,
    longitude: 80.76715,
    wikiDataId: "Q3428285",
  },
  {
    id: 132377,
    name: "Kharda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.25684,
    longitude: 75.13786,
    wikiDataId: "Q2340084",
  },
  {
    id: 132378,
    name: "Majhupur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.0177,
    longitude: 79.67846,
    wikiDataId: "Q614890",
  },
  {
    id: 132379,
    name: "Kottayam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.8445,
    longitude: 75.66266,
    wikiDataId: "Q6364007",
  },
  {
    id: 132380,
    name: "Mandapam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.32,
    longitude: 77.34,
    wikiDataId: "Q15158",
  },
  {
    id: 132382,
    name: "Khandwa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.66764,
    longitude: 76.74286,
    wikiDataId: "Q2447720",
  },
  {
    id: 132383,
    name: "Kovalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.16667,
    longitude: 75.33333,
    wikiDataId: "Q2980652",
  },
  {
    id: 132384,
    name: "Makanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.46523,
    longitude: 80.34975,
    wikiDataId: "Q31856807",
  },
  {
    id: 132385,
    name: "Malasa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.41506,
    longitude: 79.98957,
    wikiDataId: "Q610612",
  },
  {
    id: 132386,
    name: "Patamundai",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.36152,
    longitude: 85.19212,
    wikiDataId: "Q2549190",
  },
  {
    id: 132387,
    name: "Paradip Garh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.46709,
    longitude: 82.92042,
    wikiDataId: "Q1723964",
  },
  {
    id: 132388,
    name: "Kanuru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.28584,
    longitude: 81.25464,
    wikiDataId: "Q2564832",
  },
  {
    id: 132389,
    name: "Kheri Sampla",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.33093,
    longitude: 76.31099,
    wikiDataId: "Q1320976",
  },
  {
    id: 132390,
    name: "Kapadvanj",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.02302,
    longitude: 73.07113,
    wikiDataId: "Q2341421",
  },
  {
    id: 132392,
    name: "Qadian",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.38011,
    longitude: 75.38105,
    wikiDataId: "Q781354",
  },
  {
    id: 132393,
    name: "Marakkanam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.45866,
    longitude: 79.14101,
    wikiDataId: "Q2443975",
  },
  {
    id: 132394,
    name: "Karamsad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.54243,
    longitude: 72.90392,
    wikiDataId: "Q2349890",
  },
  {
    id: 132395,
    name: "Nimbahera",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.84042,
    longitude: 73.45519,
    wikiDataId: "Q2441948",
  },
  {
    id: 132396,
    name: "Niwai",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.58,
    longitude: 77.1,
    wikiDataId: "Q1419668",
  },
  {
    id: 132397,
    name: "Khandwa district",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.91533,
    longitude: 79.06378,
    wikiDataId: "Q1419668",
  },
  {
    id: 132398,
    name: "Khargapur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.45815,
    longitude: 78.13583,
    wikiDataId: "Q2313234",
  },
  {
    id: 132399,
    name: "Amanganj",
    state_id: 4852,
    state_code: "LA",
    state_name: "Ladakh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.55765,
    longitude: 76.12622,
    wikiDataId: "Q2088176",
  },
  {
    id: 132400,
    name: "Mataundh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.00089,
    longitude: 78.93935,
    wikiDataId: "Q2441548",
  },
  {
    id: 132401,
    name: "Marandahalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.67505,
    longitude: 78.09992,
    wikiDataId: "Q2441061",
  },
  {
    id: 132402,
    name: "Karimganj",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.6,
    longitude: 92.4,
    wikiDataId: "Q42542",
  },
  {
    id: 132403,
    name: "Khetia",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.9107,
    longitude: 73.32354,
    wikiDataId: "Q2283155",
  },
  {
    id: 132404,
    name: "Khopoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.4077,
    longitude: 75.19386,
    wikiDataId: "Q2283149",
  },
  {
    id: 132405,
    name: "Kurukshetra",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.66667,
    longitude: 76.83333,
    wikiDataId: "Q607915",
  },
  {
    id: 132406,
    name: "Karol Bagh",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.65136,
    longitude: 77.19072,
    wikiDataId: "Q13641055",
  },
  {
    id: 132407,
    name: "Khargone",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.94891,
    longitude: 78.86584,
    wikiDataId: "Q2447696",
  },
  {
    id: 132408,
    name: "Rahon",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.44268,
    longitude: 75.49847,
    wikiDataId: "Q3813164",
  },
  {
    id: 132409,
    name: "Masinigudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.70203,
    longitude: 80.0911,
    wikiDataId: "Q3813164",
  },
  {
    id: 132410,
    name: "Mattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.95771,
    longitude: 78.08095,
    wikiDataId: "Q817218",
  },
  {
    id: 132411,
    name: "Kolar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.81361,
    longitude: 74.12972,
    wikiDataId: "Q2727978",
  },
  {
    id: 132412,
    name: "Kharghar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.28937,
    longitude: 74.18183,
    wikiDataId: "Q2445542",
  },
  {
    id: 132413,
    name: "Mankapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.45241,
    longitude: 81.42675,
    wikiDataId: "Q2441465",
  },
  {
    id: 132415,
    name: "Ambasa",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.33844,
    longitude: 79.22938,
    wikiDataId: "Q15373",
  },
  {
    id: 132416,
    name: "Kulu",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.89856,
    longitude: 76.96587,
    wikiDataId: "Q2287036",
  },
  {
    id: 132417,
    name: "Kasba",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.85643,
    longitude: 87.53836,
    wikiDataId: "Q2231019",
  },
  {
    id: 132418,
    name: "Dubrajpur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.21399,
    longitude: 78.95693,
    wikiDataId: "Q1026180",
  },
  {
    id: 132419,
    name: "Khategaon",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.12745,
    longitude: 75.61101,
    wikiDataId: "Q2313249",
  },
  {
    id: 132420,
    name: "Khilchipur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.77369,
    longitude: 79.80513,
    wikiDataId: "Q2311273",
  },
  {
    id: 132421,
    name: "Katghora",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.50247,
    longitude: 82.54279,
    wikiDataId: "Q2317239",
  },
  {
    id: 132422,
    name: "Kulgam",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.58333,
    longitude: 75.5,
    wikiDataId: "Q2375700",
  },
  {
    id: 132423,
    name: "Katihar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 87.6,
    wikiDataId: "Q2375700",
  },
  {
    id: 132424,
    name: "Khirkiyan",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83555,
    longitude: 80.39417,
    wikiDataId: "Q746441",
  },
  {
    id: 132425,
    name: "Katpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.05869,
    longitude: 71.79457,
    wikiDataId: "Q746441",
  },
  {
    id: 132426,
    name: "Mau Aima",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.50871,
    longitude: 82.02636,
    wikiDataId: "Q746441",
  },
  {
    id: 132427,
    name: "Kupwara",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.99167,
    longitude: 74.93195,
    wikiDataId: "Q2668323",
  },
  {
    id: 132428,
    name: "Kunnamangalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.17614,
    longitude: 76.56325,
    wikiDataId: "Q2668323",
  },
  {
    id: 132429,
    name: "Melur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.21667,
    longitude: 80.31667,
    wikiDataId: "Q658886",
  },
  {
    id: 132430,
    name: "Maudaha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.53074,
    longitude: 81.37729,
    wikiDataId: "Q658886",
  },
  {
    id: 132431,
    name: "Kollegal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.28829,
    longitude: 75.9433,
    wikiDataId: "Q6379072",
  },
  {
    id: 132432,
    name: "Amarpatan",
    state_id: 4019,
    state_code: "LD",
    state_name: "Lakshadweep",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.56688,
    longitude: 72.64203,
    wikiDataId: "Q6379072",
  },
  {
    id: 132433,
    name: "Kawardha",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.00853,
    longitude: 81.23148,
    wikiDataId: "Q1929610",
  },
  {
    id: 132434,
    name: "Kawant",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.09282,
    longitude: 74.05078,
    wikiDataId: "Q1929610",
  },
  {
    id: 132435,
    name: "Minjur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.57143,
    longitude: 78.11992,
    wikiDataId: "Q603704",
  },
  {
    id: 132436,
    name: "Mohanur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.94834,
    longitude: 77.77424,
    wikiDataId: "Q603704",
  },
  {
    id: 132437,
    name: "Mudukulattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.23183,
    longitude: 78.78545,
    wikiDataId: "Q2135478",
  },
  {
    id: 132438,
    name: "Nohar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.97132,
    longitude: 75.14992,
    wikiDataId: "Q2138432",
  },
  {
    id: 132439,
    name: "Mulanur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.60307,
    longitude: 77.85193,
    wikiDataId: "Q2125834",
  },
  {
    id: 132440,
    name: "Mauranwan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.80673,
    longitude: 79.2048,
    wikiDataId: "Q303932",
  },
  {
    id: 132441,
    name: "Patnagarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.5,
    longitude: 86.5,
    wikiDataId: "Q2299172",
  },
  {
    id: 132442,
    name: "Phulbani",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.50166,
    longitude: 86.42227,
    wikiDataId: "Q2082443",
  },
  {
    id: 132443,
    name: "Lohardaga",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.77574,
    longitude: 86.37609,
    wikiDataId: "Q2138555",
  },
  {
    id: 132444,
    name: "Pipili",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.5,
    longitude: 85.5,
    wikiDataId: "Q2138555",
  },
  {
    id: 132445,
    name: "Konanur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.01384,
    longitude: 75.54631,
    wikiDataId: "Q2135572",
  },
  {
    id: 132446,
    name: "Keshod",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.30328,
    longitude: 70.24861,
    wikiDataId: "Q599414",
  },
  {
    id: 132447,
    name: "Nokha",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.29275,
    longitude: 75.93948,
    wikiDataId: "Q599414",
  },
  {
    id: 132448,
    name: "Polasara",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.18778,
    longitude: 83.21949,
    wikiDataId: "Q2135894",
  },
  {
    id: 132449,
    name: "Maurawan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.18333,
    longitude: 83.88333,
    wikiDataId: "Q2135894",
  },
  {
    id: 132450,
    name: "Kondhali",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.5635,
    longitude: 73.85205,
    wikiDataId: "Q2234392",
  },
  {
    id: 132451,
    name: "Khagaria",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5022,
    longitude: 86.46708,
    wikiDataId: "Q2070587",
  },
  {
    id: 132452,
    name: "Khagaul",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.57898,
    longitude: 85.04564,
    wikiDataId: "Q2120482",
  },
  {
    id: 132453,
    name: "Khurai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.34127,
    longitude: 78.53133,
    wikiDataId: "Q2119908",
  },
  {
    id: 132454,
    name: "Meerut",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.94195,
    longitude: 77.84243,
    wikiDataId: "Q2119936",
  },
  {
    id: 132455,
    name: "Mehdawal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.52698,
    longitude: 80.75461,
    wikiDataId: "Q599786",
  },
  {
    id: 132456,
    name: "Khairagarh",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.41859,
    longitude: 80.97942,
    wikiDataId: "Q3195939",
  },
  {
    id: 132457,
    name: "Kolaras",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.84809,
    longitude: 79.93351,
    wikiDataId: "Q178948",
  },
  {
    id: 132458,
    name: "Puri",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.60908,
    longitude: 85.08609,
    wikiDataId: "Q178948",
  },
  {
    id: 132459,
    name: "Mehnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.77268,
    longitude: 83.07179,
    wikiDataId: "Q2483802",
  },
  {
    id: 132460,
    name: "Korwai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.22558,
    longitude: 79.88007,
    wikiDataId: "Q2483802",
  },
  {
    id: 132462,
    name: "Khambhat",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.31744,
    longitude: 72.61916,
    wikiDataId: "Q1740527",
  },
  {
    id: 132463,
    name: "Khamharia",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.976,
    longitude: 82.25116,
    wikiDataId: "Q1740527",
  },
  {
    id: 132464,
    name: "Barjala",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.5,
    longitude: 80.33333,
    wikiDataId: "Q15371",
  },
  {
    id: 132465,
    name: "Raikot",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.81725,
    longitude: 76.35478,
    wikiDataId: "Q542614",
  },
  {
    id: 132466,
    name: "Partapur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.60499,
    longitude: 75.502,
    wikiDataId: "Q2120436",
  },
  {
    id: 132467,
    name: "Kotar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.82427,
    longitude: 76.35086,
    wikiDataId: "Q1648551",
  },
  {
    id: 132468,
    name: "Kothi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.75,
    longitude: 76.58333,
    wikiDataId: "Q1648551",
  },
  {
    id: 132469,
    name: "Rajasansi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.70547,
    longitude: 76.22196,
    wikiDataId: "Q1101873",
  },
  {
    id: 132470,
    name: "Kharagpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.12446,
    longitude: 86.55578,
    wikiDataId: "Q2120147",
  },
  {
    id: 132471,
    name: "Kuhi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.43997,
    longitude: 73.77545,
    wikiDataId: "Q26787499",
  },
  {
    id: 132472,
    name: "Rajpura",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.74632,
    longitude: 76.64689,
    wikiDataId: "Q2120147",
  },
  {
    id: 132473,
    name: "Milak",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.54277,
    longitude: 79.81235,
    wikiDataId: "Q2135557",
  },
  {
    id: 132474,
    name: "Kotwa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.82306,
    longitude: 75.61028,
    wikiDataId: "Q2273900",
  },
  {
    id: 132475,
    name: "Milkipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.37611,
    longitude: 81.9882,
    wikiDataId: "Q2120241",
  },
  {
    id: 132476,
    name: "Kotma",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.823,
    longitude: 79.144,
    wikiDataId: "Q2120489",
  },
  {
    id: 132477,
    name: "Purushottampur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.28845,
    longitude: 82.7606,
    wikiDataId: "Q2120387",
  },
  {
    id: 132478,
    name: "Ladwa",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.8787,
    longitude: 76.91069,
    wikiDataId: "Q2120387",
  },
  {
    id: 132479,
    name: "Miranpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.83644,
    longitude: 77.74159,
    wikiDataId: "Q2120387",
  },
  {
    id: 132480,
    name: "Kharod",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.7442,
    longitude: 82.5788,
    wikiDataId: "Q2120445",
  },
  {
    id: 132481,
    name: "Kharsia",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.98953,
    longitude: 83.10476,
    wikiDataId: "Q2317203",
  },
  {
    id: 132482,
    name: "Madhupur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.79093,
    longitude: 85.83102,
    wikiDataId: "Q2120338",
  },
  {
    id: 132483,
    name: "Miranpur Katra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.27844,
    longitude: 77.73302,
    wikiDataId: "Q2120516",
  },
  {
    id: 132484,
    name: "Egra",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.92134,
    longitude: 79.97075,
    wikiDataId: "Q940388",
  },
  {
    id: 132485,
    name: "Ladakh",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.6027,
    longitude: 74.80918,
    wikiDataId: "Q743744",
  },
  {
    id: 132486,
    name: "Bada Barabil",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.37807,
    longitude: 93.12797,
    wikiDataId: "Q2119988",
  },
  {
    id: 132487,
    name: "Kusumba",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.71888,
    longitude: 73.39693,
    wikiDataId: "Q2119988",
  },
  {
    id: 132488,
    name: "Kheda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.75,
    longitude: 72.83333,
    wikiDataId: "Q775759",
  },
  {
    id: 132489,
    name: "Khedbrahma",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.0299,
    longitude: 73.04632,
    wikiDataId: "Q9461",
  },
  {
    id: 132490,
    name: "Mirganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.86586,
    longitude: 77.2841,
    wikiDataId: "Q26787510",
  },
  {
    id: 132491,
    name: "Ram Das",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.14443,
    longitude: 74.55938,
    wikiDataId: "Q581774",
  },
  {
    id: 132492,
    name: "Mirzapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.11667,
    longitude: 80.71667,
    wikiDataId: "Q1928876",
  },
  {
    id: 132493,
    name: "Loharu",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.7781,
    longitude: 76.7756,
    wikiDataId: "Q1755447",
  },
  {
    id: 132494,
    name: "Kheralu",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.88534,
    longitude: 72.61869,
    wikiDataId: "Q2236264",
  },
  {
    id: 132495,
    name: "Lanja",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.67124,
    longitude: 74.58535,
    wikiDataId: "Q2231812",
  },
  {
    id: 132496,
    name: "Phalodi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.00069,
    longitude: 75.78644,
    wikiDataId: "Q2234697",
  },
  {
    id: 132497,
    name: "Kumbhraj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.03943,
    longitude: 76.578,
    wikiDataId: "Q2230387",
  },
  {
    id: 132498,
    name: "Lahar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.16732,
    longitude: 76.86137,
    wikiDataId: "Q2233697",
  },
  {
    id: 132499,
    name: "Khonsa",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.01667,
    longitude: 95.56667,
    wikiDataId: "Q2231406",
  },
  {
    id: 132500,
    name: "Latur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.78562,
    longitude: 73.34589,
    wikiDataId: "Q12422734",
  },
  {
    id: 132501,
    name: "Rambha",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.2,
    longitude: 85.6,
    wikiDataId: "Q662818",
  },
  {
    id: 132502,
    name: "Baldev",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.07964,
    longitude: 91.59972,
    wikiDataId: "Q643369",
  },
  {
    id: 132503,
    name: "Misrikh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.14607,
    longitude: 79.71472,
    wikiDataId: "Q2234182",
  },
  {
    id: 132504,
    name: "Lakhnadon",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78597,
    longitude: 76.61773,
    wikiDataId: "Q2235923",
  },
  {
    id: 132505,
    name: "Lavasa",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.00671,
    longitude: 75.19245,
    wikiDataId: "Q1025625",
  },
  {
    id: 132506,
    name: "Malkera",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.07602,
    longitude: 85.27818,
    wikiDataId: "Q614401",
  },
  {
    id: 132507,
    name: "Leteri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.04372,
    longitude: 78.33014,
    wikiDataId: "Q2234625",
  },
  {
    id: 132508,
    name: "Raurkela",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.18268,
    longitude: 85.61629,
    wikiDataId: "Q2241122",
  },
  {
    id: 132509,
    name: "Mohan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.25382,
    longitude: 77.85535,
    wikiDataId: "Q2235607",
  },
  {
    id: 132510,
    name: "Khusropur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.48174,
    longitude: 85.38492,
    wikiDataId: "Q2235607",
  },
  {
    id: 132511,
    name: "Khujner",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.42322,
    longitude: 75.28185,
    wikiDataId: "Q2120413",
  },
  {
    id: 132512,
    name: "Mawana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.77215,
    longitude: 81.10393,
    wikiDataId: "Q2120073",
  },
  {
    id: 132513,
    name: "Kopela",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.70738,
    longitude: 76.56827,
    wikiDataId: "Q2138775",
  },
  {
    id: 132514,
    name: "Mehndawal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.53446,
    longitude: 78.06546,
    wikiDataId: "Q3418055",
  },
  {
    id: 132515,
    name: "Parvatsar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.73241,
    longitude: 76.39601,
    wikiDataId: "Q3418055",
  },
  {
    id: 132516,
    name: "Konnur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.63969,
    longitude: 74.50847,
    wikiDataId: "Q2120803",
  },
  {
    id: 132517,
    name: "Kudal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.42243,
    longitude: 78.98168,
    wikiDataId: "Q2135471",
  },
  {
    id: 132518,
    name: "Kharupatia",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.51839,
    longitude: 92.14722,
    wikiDataId: "Q2120118",
  },
  {
    id: 132519,
    name: "Kukshi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.59573,
    longitude: 76.9133,
    wikiDataId: "Q939294",
  },
  {
    id: 132520,
    name: "Mohanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.20307,
    longitude: 80.27046,
    wikiDataId: "Q2237055",
  },
  {
    id: 132521,
    name: "Falakata",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.91154,
    longitude: 79.52009,
    wikiDataId: "Q2237143",
  },
  {
    id: 132522,
    name: "Muttupet",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.76721,
    longitude: 79.74186,
    wikiDataId: "Q2236200",
  },
  {
    id: 132523,
    name: "Kyelang",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.58333,
    longitude: 78.41667,
    wikiDataId: "Q1862950",
  },
  {
    id: 132524,
    name: "Lohogaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.62557,
    longitude: 78.1987,
    wikiDataId: "Q2237173",
  },
  {
    id: 132525,
    name: "Kirandul",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.63649,
    longitude: 81.25827,
    wikiDataId: "Q2237173",
  },
  {
    id: 132526,
    name: "Moth",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.13768,
    longitude: 77.78516,
    wikiDataId: "Q747765",
  },
  {
    id: 132527,
    name: "Rayagada",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.08333,
    longitude: 85.35,
    wikiDataId: "Q747765",
  },
  {
    id: 132528,
    name: "Moradabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.63745,
    longitude: 82.91596,
    wikiDataId: "Q747765",
  },
  {
    id: 132529,
    name: "Kishanganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.3,
    longitude: 88,
    wikiDataId: "Q747765",
  },
  {
    id: 132530,
    name: "Phulera",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.59006,
    longitude: 74.85397,
    wikiDataId: "Q927794",
  },
  {
    id: 132531,
    name: "Mughal Sarai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.64232,
    longitude: 81.0227,
    wikiDataId: "Q26787532",
  },
  {
    id: 132532,
    name: "Mukteshwar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.02487,
    longitude: 79.262,
    wikiDataId: "Q2236984",
  },
  {
    id: 132533,
    name: "Magam",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.52958,
    longitude: 76.01462,
    wikiDataId: "Q2321899",
  },
  {
    id: 132535,
    name: "Mungra Badshahpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.86684,
    longitude: 77.93861,
    wikiDataId: "Q26787533",
  },
  {
    id: 132536,
    name: "Kunnumma",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.30067,
    longitude: 76.33763,
    wikiDataId: "Q3815660",
  },
  {
    id: 132537,
    name: "Koppa",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.41667,
    longitude: 75.75,
    wikiDataId: "Q1553185",
  },
  {
    id: 132538,
    name: "Nagapattinam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.23925,
    longitude: 77.48932,
    wikiDataId: "Q253851",
  },
  {
    id: 132539,
    name: "Remuna",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.62425,
    longitude: 84.94075,
    wikiDataId: "Q1170466",
  },
  {
    id: 132540,
    name: "Manoharpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.46753,
    longitude: 85.59397,
    wikiDataId: "Q2087687",
  },
  {
    id: 132541,
    name: "Koppal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.72136,
    longitude: 77.38629,
    wikiDataId: "Q2234072",
  },
  {
    id: 132542,
    name: "Koratagere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.80087,
    longitude: 75.88662,
    wikiDataId: "Q2234072",
  },
  {
    id: 132543,
    name: "Lonar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.87639,
    longitude: 74.1909,
    wikiDataId: "Q2237032",
  },
  {
    id: 132544,
    name: "Nagercoil",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.07751,
    longitude: 77.88363,
    wikiDataId: "Q2236709",
  },
  {
    id: 132545,
    name: "Kutiatodu",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.23263,
    longitude: 76.19513,
    wikiDataId: "Q1006282",
  },
  {
    id: 132546,
    name: "Belonia",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.9985,
    longitude: 79.9656,
    wikiDataId: "Q1006282",
  },
  {
    id: 132547,
    name: "Kodinar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.79393,
    longitude: 70.70216,
    wikiDataId: "Q2239007",
  },
  {
    id: 132548,
    name: "Koelwar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.58055,
    longitude: 84.79751,
    wikiDataId: "Q2239007",
  },
  {
    id: 132549,
    name: "Basudebpur",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.67467,
    longitude: 94.11099,
    wikiDataId: "Q234056",
  },
  {
    id: 132551,
    name: "Kokrajhar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.6,
    longitude: 90.2,
    wikiDataId: "Q42618",
  },
  {
    id: 132552,
    name: "Kolanukonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.45392,
    longitude: 80.61046,
    wikiDataId: "Q6427052",
  },
  {
    id: 132553,
    name: "Kotturu",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.13,
    longitude: 78.23,
    wikiDataId: "Q2509866",
  },
  {
    id: 132554,
    name: "Balangir",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.22388,
    longitude: 92.67869,
    wikiDataId: "Q1112188",
  },
  {
    id: 132555,
    name: "Balasore",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.22215,
    longitude: 92.67697,
    wikiDataId: "Q1947343",
  },
  {
    id: 132556,
    name: "Lonavla",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.69013,
    longitude: 74.22981,
    wikiDataId: "Q209698",
  },
  {
    id: 132558,
    name: "Kuttampuzha",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.88113,
    longitude: 76.58469,
    wikiDataId: "Q321475",
  },
  {
    id: 132559,
    name: "Krishnarajpet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.15449,
    longitude: 77.11051,
    wikiDataId: "Q2238928",
  },
  {
    id: 132561,
    name: "Lodhikheda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.21928,
    longitude: 77.61167,
    wikiDataId: "Q509592",
  },
  {
    id: 132562,
    name: "Namagiripettai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.84745,
    longitude: 77.29603,
    wikiDataId: "Q1930255",
  },
  {
    id: 132563,
    name: "Kudachi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.63016,
    longitude: 76.05037,
    wikiDataId: "Q1930255",
  },
  {
    id: 132564,
    name: "Munsyari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.99451,
    longitude: 79.15127,
    wikiDataId: "Q2240864",
  },
  {
    id: 132565,
    name: "Kondagaon",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.59083,
    longitude: 81.664,
    wikiDataId: "Q2234676",
  },
  {
    id: 132566,
    name: "Mahabaleshwar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.80727,
    longitude: 77.77031,
    wikiDataId: "Q2234676",
  },
  {
    id: 132567,
    name: "Kondapalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.61989,
    longitude: 80.54244,
    wikiDataId: "Q2234676",
  },
  {
    id: 132568,
    name: "Namakkal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.57105,
    longitude: 77.9004,
    wikiDataId: "Q2234048",
  },
  {
    id: 132569,
    name: "Kudligi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.20138,
    longitude: 74.74886,
    wikiDataId: "Q2238933",
  },
  {
    id: 132570,
    name: "Rengali",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.89758,
    longitude: 86.11413,
    wikiDataId: "Q749189",
  },
  {
    id: 132571,
    name: "Nambiyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.7199,
    longitude: 79.5157,
    wikiDataId: "Q2235726",
  },
  {
    id: 132572,
    name: "Mahim",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.88239,
    longitude: 74.47605,
    wikiDataId: "Q2241490",
  },
  {
    id: 132573,
    name: "Kumsi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.53044,
    longitude: 75.36329,
    wikiDataId: "Q738998",
  },
  {
    id: 132574,
    name: "Kumta",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.5,
    longitude: 76.2,
    wikiDataId: "Q956387",
  },
  {
    id: 132575,
    name: "Muradabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.01923,
    longitude: 83.5663,
    wikiDataId: "Q388795",
  },
  {
    id: 132576,
    name: "Nambutalai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.77019,
    longitude: 79.49158,
    wikiDataId: "Q2233889",
  },
  {
    id: 132577,
    name: "Nandambakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.77506,
    longitude: 78.09158,
    wikiDataId: "Q2233889",
  },
  {
    id: 132578,
    name: "Sambalpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19,
    longitude: 83,
    wikiDataId: "Q1947300",
  },
  {
    id: 132579,
    name: "Kundgol",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.522,
    longitude: 77.2373,
    wikiDataId: "Q2237163",
  },
  {
    id: 132580,
    name: "Dhalai",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.82154,
    longitude: 78.71186,
    wikiDataId: "Q2235879",
  },
  {
    id: 132581,
    name: "Korba",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.5,
    longitude: 82.6,
    wikiDataId: "Q2299121",
  },
  {
    id: 132582,
    name: "Makhjan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.64573,
    longitude: 74.05909,
    wikiDataId: "Q2729341",
  },
  {
    id: 132583,
    name: "Koriya",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.48326,
    longitude: 82.15037,
    wikiDataId: "Q2299121",
  },
  {
    id: 132584,
    name: "Korukollu",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.5,
    longitude: 81.25,
    wikiDataId: "Q6432751",
  },
  {
    id: 132585,
    name: "Machalpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.11774,
    longitude: 78.04007,
    wikiDataId: "Q6432751",
  },
  {
    id: 132586,
    name: "Majalgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.24758,
    longitude: 79.10575,
    wikiDataId: "Q6432751",
  },
  {
    id: 132588,
    name: "Kosamba",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.46202,
    longitude: 72.95842,
    wikiDataId: "Q431049",
  },
  {
    id: 132589,
    name: "Muradnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.79449,
    longitude: 77.4368,
    wikiDataId: "Q431049",
  },
  {
    id: 132590,
    name: "Kosigi",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.8551,
    longitude: 77.24463,
    wikiDataId: "Q6433178",
  },
  {
    id: 132591,
    name: "Rupnagar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.94659,
    longitude: 75.1378,
    wikiDataId: "Q6433178",
  },
  {
    id: 132592,
    name: "Mursan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.44643,
    longitude: 83.13063,
    wikiDataId: "Q3816622",
  },
  {
    id: 132593,
    name: "Pilani",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.17512,
    longitude: 75.84412,
    wikiDataId: "Q999432",
  },
  {
    id: 132594,
    name: "Nangavalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.42072,
    longitude: 76.86035,
    wikiDataId: "Q279106",
  },
  {
    id: 132595,
    name: "Lalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.06435,
    longitude: 76.62843,
    wikiDataId: "Q279106",
  },
  {
    id: 132596,
    name: "Kotaparh",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.14256,
    longitude: 82.32536,
    wikiDataId: "Q279106",
  },
  {
    id: 132597,
    name: "Farakka",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.74612,
    longitude: 78.52219,
    wikiDataId: "Q2087739",
  },
  {
    id: 132598,
    name: "Maheshwar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.7526,
    longitude: 80.77751,
    wikiDataId: "Q2233986",
  },
  {
    id: 132599,
    name: "Dharmanagar",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.35176,
    longitude: 79.48323,
    wikiDataId: "Q2233986",
  },
  {
    id: 132600,
    name: "Samrala",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.5819,
    longitude: 74.83298,
    wikiDataId: "Q2235570",
  },
  {
    id: 132601,
    name: "Lahul and Spiti",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.11728,
    longitude: 77.53936,
    wikiDataId: "Q2244259",
  },
  {
    id: 132602,
    name: "Manali",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.25,
    longitude: 76.03333,
    wikiDataId: "Q6434088",
  },
  {
    id: 132603,
    name: "Mahgawan",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.20383,
    longitude: 81.97904,
    wikiDataId: "Q2240936",
  },
  {
    id: 132604,
    name: "Pilibangan",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.70207,
    longitude: 76.19911,
    wikiDataId: "Q2241196",
  },
  {
    id: 132605,
    name: "Musafir-Khana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.8077,
    longitude: 79.30909,
    wikiDataId: "Q2725557",
  },
  {
    id: 132606,
    name: "Gomati",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.55106,
    longitude: 80.61779,
    wikiDataId: "Q2238988",
  },
  {
    id: 132607,
    name: "Nangilickondan",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.10956,
    longitude: 78.7956,
    wikiDataId: "Q583767",
  },
  {
    id: 132608,
    name: "Kailashahar",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.49543,
    longitude: 79.0943,
    wikiDataId: "Q583767",
  },
  {
    id: 132609,
    name: "Mahē",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.66667,
    longitude: 76.66667,
    wikiDataId: "Q1353354",
  },
  {
    id: 132610,
    name: "Kunigal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.82442,
    longitude: 76.22005,
    wikiDataId: "Q2475108",
  },
  {
    id: 132611,
    name: "Maihar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.0308,
    longitude: 81.31908,
    wikiDataId: "Q2231837",
  },
  {
    id: 132612,
    name: "Kota",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.29507,
    longitude: 82.02366,
    wikiDataId: "Q2236924",
  },
  {
    id: 132613,
    name: "Madhogarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.69802,
    longitude: 80.98073,
    wikiDataId: "Q1924838",
  },
  {
    id: 132614,
    name: "Malappuram",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.36667,
    longitude: 76.99667,
    wikiDataId: "Q1785641",
  },
  {
    id: 132615,
    name: "Nanguneri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.17167,
    longitude: 77.86989,
    wikiDataId: "Q2244024",
  },
  {
    id: 132616,
    name: "Kovvur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.0162,
    longitude: 81.72934,
    wikiDataId: "Q2235664",
  },
  {
    id: 132618,
    name: "Malabar Hill",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.4,
    longitude: 73.76667,
    wikiDataId: "Q6435154",
  },
  {
    id: 132619,
    name: "Manjeri",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.5,
    longitude: 76,
    wikiDataId: "Q1142979",
  },
  {
    id: 132620,
    name: "Koath",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.32643,
    longitude: 84.25983,
    wikiDataId: "Q2239138",
  },
  {
    id: 132621,
    name: "Krishna",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.66667,
    longitude: 81,
    wikiDataId: "Q15382",
  },
  {
    id: 132622,
    name: "Nannilam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.58,
    longitude: 77.96,
    wikiDataId: "Q15183",
  },
  {
    id: 132623,
    name: "Kurgunta",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.66621,
    longitude: 76.4877,
    wikiDataId: "Q15183",
  },
  {
    id: 132624,
    name: "Sonepur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.26675,
    longitude: 86.17385,
    wikiDataId: "Q2726074",
  },
  {
    id: 132625,
    name: "Pipar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.98747,
    longitude: 73.97108,
    wikiDataId: "Q2232971",
  },
  {
    id: 132626,
    name: "Sorada",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.74356,
    longitude: 84.34848,
    wikiDataId: "Q2241549",
  },
  {
    id: 132627,
    name: "Pindwara",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.14745,
    longitude: 74.85655,
    wikiDataId: "Q2241549",
  },
  {
    id: 132628,
    name: "Kushalnagar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.62784,
    longitude: 74.85408,
    wikiDataId: "Q2235245",
  },
  {
    id: 132629,
    name: "Malad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.01148,
    longitude: 73.68867,
    wikiDataId: "Q2239036",
  },
  {
    id: 132630,
    name: "Mugma",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.72536,
    longitude: 85.51023,
    wikiDataId: "Q1925136",
  },
  {
    id: 132631,
    name: "Majholi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.20677,
    longitude: 74.75788,
    wikiDataId: "Q2241499",
  },
  {
    id: 132632,
    name: "Naravarikuppam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.0032,
    longitude: 78.1928,
    wikiDataId: "Q2241499",
  },
  {
    id: 132633,
    name: "Noria",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.64456,
    longitude: 75.01923,
    wikiDataId: "Q83661",
  },
  {
    id: 132634,
    name: "Nattam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.93487,
    longitude: 78.41251,
    wikiDataId: "Q2243995",
  },
  {
    id: 132635,
    name: "Nadigaon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.32007,
    longitude: 79.63931,
    wikiDataId: "Q1393339",
  },
  {
    id: 132636,
    name: "Mandi",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32,
    longitude: 77.25,
    wikiDataId: "Q1393339",
  },
  {
    id: 132637,
    name: "Nattarasankottai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.48936,
    longitude: 77.3499,
    wikiDataId: "Q1393339",
  },
  {
    id: 132638,
    name: "Needamangalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.96209,
    longitude: 79.39124,
    wikiDataId: "Q913399",
  },
  {
    id: 132639,
    name: "Manjēshvar",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.9063,
    longitude: 76.31127,
    wikiDataId: "Q913399",
  },
  {
    id: 132640,
    name: "Maksi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.37338,
    longitude: 77.04841,
    wikiDataId: "Q2239879",
  },
  {
    id: 132641,
    name: "Kumhari",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.26667,
    longitude: 81.51667,
    wikiDataId: "Q2234421",
  },
  {
    id: 132642,
    name: "Lakshmeshwar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.05455,
    longitude: 75.39992,
    wikiDataId: "Q2234421",
  },
  {
    id: 132643,
    name: "Lingsugur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.42853,
    longitude: 74.4189,
    wikiDataId: "Q2238911",
  },
  {
    id: 132644,
    name: "Nagina",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.71702,
    longitude: 81.51396,
    wikiDataId: "Q2413194",
  },
  {
    id: 132645,
    name: "Nagla",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.68304,
    longitude: 78.78559,
    wikiDataId: "Q2236973",
  },
  {
    id: 132646,
    name: "Londa",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.25612,
    longitude: 75.24735,
    wikiDataId: "Q2240854",
  },
  {
    id: 132647,
    name: "Kundla",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.34222,
    longitude: 71.30633,
    wikiDataId: "Q2734216",
  },
  {
    id: 132648,
    name: "Maddagiri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.02319,
    longitude: 77.02518,
    wikiDataId: "Q255432",
  },
  {
    id: 132649,
    name: "Mannarakkat",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.30459,
    longitude: 75.87772,
    wikiDataId: "Q255432",
  },
  {
    id: 132650,
    name: "Marayur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.64667,
    longitude: 76.06695,
    wikiDataId: "Q2234522",
  },
  {
    id: 132651,
    name: "Neelankarai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.34782,
    longitude: 78.51046,
    wikiDataId: "Q2234522",
  },
  {
    id: 132652,
    name: "Mattanur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.35672,
    longitude: 76.41343,
    wikiDataId: "Q2234522",
  },
  {
    id: 132653,
    name: "Kuppam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.74931,
    longitude: 78.34189,
    wikiDataId: "Q587868",
  },
  {
    id: 132654,
    name: "Padam",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.53193,
    longitude: 74.26605,
    wikiDataId: "Q2297306",
  },
  {
    id: 132656,
    name: "Malkapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.68317,
    longitude: 74.58892,
    wikiDataId: "Q1949085",
  },
  {
    id: 132657,
    name: "Manchar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.09339,
    longitude: 75.41567,
    wikiDataId: "Q2238903",
  },
  {
    id: 132658,
    name: "Maddur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.19321,
    longitude: 77.35772,
    wikiDataId: "Q720834",
  },
  {
    id: 132659,
    name: "Negapatam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.55028,
    longitude: 79.59066,
    wikiDataId: "Q2242726",
  },
  {
    id: 132660,
    name: "Kurnool",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.58333,
    longitude: 78.33333,
    wikiDataId: "Q15381",
  },
  {
    id: 132661,
    name: "Kurud",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83073,
    longitude: 81.72212,
    wikiDataId: "Q591621",
  },
  {
    id: 132662,
    name: "Maham",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30,
    longitude: 76.75,
    wikiDataId: "Q980118",
  },
  {
    id: 132663,
    name: "Kurung Kumey",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.99983,
    longitude: 93.3924,
    wikiDataId: "Q2449506",
  },
  {
    id: 132664,
    name: "Najibabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.98046,
    longitude: 79.98984,
    wikiDataId: "Q2236466",
  },
  {
    id: 132665,
    name: "Nanauta",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.74028,
    longitude: 83.88889,
    wikiDataId: "Q825673",
  },
  {
    id: 132666,
    name: "Magadi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.75623,
    longitude: 76.19112,
    wikiDataId: "Q2230846",
  },
  {
    id: 132667,
    name: "Pokaran",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.19899,
    longitude: 74.45074,
    wikiDataId: "Q2234705",
  },
  {
    id: 132668,
    name: "Madikeri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.45795,
    longitude: 75.95904,
    wikiDataId: "Q254885",
  },
  {
    id: 132669,
    name: "Mavelikara",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.8,
    longitude: 76.33333,
    wikiDataId: "Q254885",
  },
  {
    id: 132670,
    name: "Kutiyana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.6241,
    longitude: 69.98494,
    wikiDataId: "Q2238993",
  },
  {
    id: 132671,
    name: "Mavoor",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.15033,
    longitude: 76.73544,
    wikiDataId: "Q2238993",
  },
  {
    id: 132672,
    name: "Nellikkuppam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.9303,
    longitude: 77.26951,
    wikiDataId: "Q2238993",
  },
  {
    id: 132673,
    name: "Nilakottai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.31792,
    longitude: 77.19192,
    wikiDataId: "Q2230820",
  },
  {
    id: 132674,
    name: "Khowai",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.66781,
    longitude: 78.5289,
    wikiDataId: "Q2233371",
  },
  {
    id: 132675,
    name: "Nadaun",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.5717,
    longitude: 77.02448,
    wikiDataId: "Q2233371",
  },
  {
    id: 132676,
    name: "Maghar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.16779,
    longitude: 82.37028,
    wikiDataId: "Q2308537",
  },
  {
    id: 132677,
    name: "Karajagi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.57702,
    longitude: 74.31544,
    wikiDataId: "Q2442675",
  },
  {
    id: 132678,
    name: "Kakinada",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.96036,
    longitude: 82.23809,
    wikiDataId: "Q59018",
  },
  {
    id: 132679,
    name: "Mahoba",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.868,
    longitude: 80.7857,
    wikiDataId: "Q2450968",
  },
  {
    id: 132680,
    name: "Khunti",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.61662,
    longitude: 86.2881,
    wikiDataId: "Q654120",
  },
  {
    id: 132681,
    name: "Kinnaur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.83982,
    longitude: 76.94065,
    wikiDataId: "Q1026888",
  },
  {
    id: 132682,
    name: "Kalol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.60777,
    longitude: 73.46272,
    wikiDataId: "Q2724740",
  },
  {
    id: 132683,
    name: "Mahrajganj (Raebareli)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.11667,
    longitude: 79.73333,
    wikiDataId: "Q2281643",
  },
  {
    id: 132684,
    name: "Katol",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.9802,
    longitude: 73.12708,
    wikiDataId: "Q2281643",
  },
  {
    id: 132685,
    name: "Dinhata",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.28351,
    longitude: 79.351,
    wikiDataId: "Q2449425",
  },
  {
    id: 132686,
    name: "Domjur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.4778,
    longitude: 78.78449,
    wikiDataId: "Q2449425",
  },
  {
    id: 132687,
    name: "Nowrangapur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.75,
    longitude: 83,
    wikiDataId: "Q1876588",
  },
  {
    id: 132688,
    name: "Kharkhauda",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.83573,
    longitude: 74.9717,
    wikiDataId: "Q2452176",
  },
  {
    id: 132689,
    name: "Kalavad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.20789,
    longitude: 70.38343,
    wikiDataId: "Q2251785",
  },
  {
    id: 132690,
    name: "Mahrajganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.62019,
    longitude: 80.08152,
    wikiDataId: "Q2441455",
  },
  {
    id: 132691,
    name: "Kamalapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.5983,
    longitude: 78.66948,
    wikiDataId: "Q2441455",
  },
  {
    id: 132692,
    name: "Nawalgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.65791,
    longitude: 77.26908,
    wikiDataId: "Q8104138",
  },
  {
    id: 132693,
    name: "Kamrup",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.22322,
    longitude: 91.65344,
    wikiDataId: "Q2247441",
  },
  {
    id: 132694,
    name: "Khandala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.21615,
    longitude: 79.1973,
    wikiDataId: "Q2283184",
  },
  {
    id: 132695,
    name: "Nuapada",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.93385,
    longitude: 85.54489,
    wikiDataId: "Q2480576",
  },
  {
    id: 132696,
    name: "Zahirabad",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.32001,
    longitude: 78.34177,
    wikiDataId: "Q2480576",
  },
  {
    id: 132697,
    name: "Mainpuri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.32104,
    longitude: 77.27101,
    wikiDataId: "Q2309869",
  },
  {
    id: 132698,
    name: "Kandla",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.03333,
    longitude: 70.21667,
    wikiDataId: "Q581480",
  },
  {
    id: 132699,
    name: "Kodarma",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.8517,
    longitude: 86.05192,
    wikiDataId: "Q2558144",
  },
  {
    id: 132700,
    name: "Khangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.4203,
    longitude: 79.27663,
    wikiDataId: "Q520995",
  },
  {
    id: 132701,
    name: "Kotkhai",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.09135,
    longitude: 76.26267,
    wikiDataId: "Q662868",
  },
  {
    id: 132702,
    name: "Kotla",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.16667,
    longitude: 76.25,
    wikiDataId: "Q727232",
  },
  {
    id: 132703,
    name: "Kuju",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.43478,
    longitude: 85.32059,
    wikiDataId: "Q660473",
  },
  {
    id: 132704,
    name: "Kanker",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.27193,
    longitude: 81.49177,
    wikiDataId: "Q609848",
  },
  {
    id: 132705,
    name: "Kankon",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.02698,
    longitude: 74.04617,
    wikiDataId: "Q861954",
  },
  {
    id: 132706,
    name: "Kodigenahalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.54654,
    longitude: 77.42005,
    wikiDataId: "Q861954",
  },
  {
    id: 132707,
    name: "Kotamangalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.30814,
    longitude: 75.10632,
    wikiDataId: "Q720627",
  },
  {
    id: 132708,
    name: "Kanodar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.08932,
    longitude: 72.39354,
    wikiDataId: "Q2564896",
  },
  {
    id: 132709,
    name: "Neem ka Thana",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.43437,
    longitude: 74.26546,
    wikiDataId: "Q1928835",
  },
  {
    id: 132711,
    name: "Malihabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.81049,
    longitude: 79.79185,
    wikiDataId: "Q1928835",
  },
  {
    id: 132712,
    name: "Maniar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.05939,
    longitude: 78.62951,
    wikiDataId: "Q2725679",
  },
  {
    id: 132713,
    name: "Nimaj",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.40529,
    longitude: 76.07431,
    wikiDataId: "Q2725679",
  },
  {
    id: 132714,
    name: "Mangalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.06615,
    longitude: 78.76784,
    wikiDataId: "Q1023693",
  },
  {
    id: 132715,
    name: "Budhlada",
    state_id: 4011,
    state_code: "PY",
    state_name: "Puducherry",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.92209,
    longitude: 79.83353,
    wikiDataId: "Q639264",
  },
  {
    id: 132716,
    name: "Mannargudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.24058,
    longitude: 76.96009,
    wikiDataId: "Q2308150",
  },
  {
    id: 132717,
    name: "Khed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.48273,
    longitude: 77.48857,
    wikiDataId: "Q2317517",
  },
  {
    id: 132718,
    name: "Karbi Anglong",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26,
    longitude: 93.5,
    wikiDataId: "Q42558",
  },
  {
    id: 132719,
    name: "Kodlipet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.21428,
    longitude: 74.99234,
    wikiDataId: "Q2284508",
  },
  {
    id: 132720,
    name: "Kozhikode",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.49838,
    longitude: 74.98959,
    wikiDataId: "Q317217",
  },
  {
    id: 132721,
    name: "Kumbalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.49246,
    longitude: 74.99062,
    wikiDataId: "Q1419703",
  },
  {
    id: 132722,
    name: "Mathura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.80882,
    longitude: 78.64579,
    wikiDataId: "Q2019118",
  },
  {
    id: 132723,
    name: "Kathor",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.28854,
    longitude: 72.9407,
    wikiDataId: "Q2726550",
  },
  {
    id: 132724,
    name: "Kodoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.96137,
    longitude: 75.88895,
    wikiDataId: "Q2019118",
  },
  {
    id: 132725,
    name: "Kolhapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.27388,
    longitude: 78.5858,
    wikiDataId: "Q2445558",
  },
  {
    id: 132726,
    name: "Mayiladuthurai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.96951,
    longitude: 79.14552,
    wikiDataId: "Q2445403",
  },
  {
    id: 132727,
    name: "Latehar",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.79752,
    longitude: 86.29834,
    wikiDataId: "Q2120741",
  },
  {
    id: 132728,
    name: "Mettuppalaiyam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.99385,
    longitude: 78.21929,
    wikiDataId: "Q2120741",
  },
  {
    id: 132729,
    name: "Kavali",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.9163,
    longitude: 79.99449,
    wikiDataId: "Q2138686",
  },
  {
    id: 132730,
    name: "Mettur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.42186,
    longitude: 78.2188,
    wikiDataId: "Q3535883",
  },
  {
    id: 132731,
    name: "Kunnamkulam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.18173,
    longitude: 76.50093,
    wikiDataId: "Q672672",
  },
  {
    id: 132732,
    name: "Musiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.44216,
    longitude: 79.64763,
    wikiDataId: "Q1320456",
  },
  {
    id: 132733,
    name: "Naduvattam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.56988,
    longitude: 78.78682,
    wikiDataId: "Q1320456",
  },
  {
    id: 132734,
    name: "Mubarakpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.50671,
    longitude: 78.20613,
    wikiDataId: "Q779417",
  },
  {
    id: 132735,
    name: "Nawanshahr",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.07246,
    longitude: 75.28727,
    wikiDataId: "Q2233491",
  },
  {
    id: 132736,
    name: "Kushtagi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.905,
    longitude: 76.38527,
    wikiDataId: "Q794818",
  },
  {
    id: 132737,
    name: "Kamalpur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.48486,
    longitude: 78.41376,
    wikiDataId: "Q794818",
  },
  {
    id: 132738,
    name: "Pirawa",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.31657,
    longitude: 77.37079,
    wikiDataId: "Q1791992",
  },
  {
    id: 132739,
    name: "Pratapgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.82294,
    longitude: 75.02754,
    wikiDataId: "Q2237414",
  },
  {
    id: 132740,
    name: "Pahlgam",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.33333,
    longitude: 77.41667,
    wikiDataId: "Q200667",
  },
  {
    id: 132741,
    name: "Malhargarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.19401,
    longitude: 78.94137,
    wikiDataId: "Q200667",
  },
  {
    id: 132742,
    name: "Lakhimpur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.35,
    longitude: 94.25,
    wikiDataId: "Q42743",
  },
  {
    id: 132743,
    name: "Lakhipur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.79281,
    longitude: 93.0091,
    wikiDataId: "Q12450962",
  },
  {
    id: 132744,
    name: "Lakhisarai",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.2,
    longitude: 86.2,
    wikiDataId: "Q77505",
  },
  {
    id: 132745,
    name: "Naugama",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.64822,
    longitude: 79.1477,
    wikiDataId: "Q2234647",
  },
  {
    id: 132746,
    name: "Manasa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.60049,
    longitude: 79.60094,
    wikiDataId: "Q2234278",
  },
  {
    id: 132747,
    name: "Lakhtar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.85683,
    longitude: 71.78844,
    wikiDataId: "Q2234278",
  },
  {
    id: 132748,
    name: "Narora",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.94822,
    longitude: 80.77935,
    wikiDataId: "Q8584167",
  },
  {
    id: 132749,
    name: "Fort Gloster",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.7587,
    longitude: 78.04148,
    wikiDataId: "Q656164",
  },
  {
    id: 132750,
    name: "Amarwara",
    state_id: 4019,
    state_code: "LD",
    state_name: "Lakshadweep",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.27333,
    longitude: 74.04582,
    wikiDataId: "Q10784153",
  },
  {
    id: 132751,
    name: "North Tripura",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.86667,
    longitude: 79.21667,
    wikiDataId: "Q10784153",
  },
  {
    id: 132752,
    name: "Mahalingpur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.12689,
    longitude: 75.46935,
    wikiDataId: "Q2237516",
  },
  {
    id: 132753,
    name: "Ranir Bazar",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.34769,
    longitude: 78.55757,
    wikiDataId: "Q2237516",
  },
  {
    id: 132754,
    name: "Nilgiris",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.87419,
    longitude: 78.81935,
    wikiDataId: "Q2242627",
  },
  {
    id: 132755,
    name: "Nautanwa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.5,
    longitude: 78.5,
    wikiDataId: "Q1025286",
  },
  {
    id: 132756,
    name: "Gaighata community development block",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.84183,
    longitude: 78.68014,
    wikiDataId: "Q2233977",
  },
  {
    id: 132757,
    name: "Nawabganj (Barabanki)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.20394,
    longitude: 83.96906,
    wikiDataId: "Q3827036",
  },
  {
    id: 132758,
    name: "Mushabani",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.75,
    longitude: 84.4,
    wikiDataId: "Q2244762",
  },
  {
    id: 132759,
    name: "Mangrul Pir",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.4,
    longitude: 76.8,
    wikiDataId: "Q61394",
  },
  {
    id: 132760,
    name: "Sanaur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.19393,
    longitude: 75.68089,
    wikiDataId: "Q1948713",
  },
  {
    id: 132761,
    name: "Baleshwar",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.53,
    longitude: 92.9,
    wikiDataId: "Q1948713",
  },
  {
    id: 132762,
    name: "Amarkantak",
    state_id: 4852,
    state_code: "LA",
    state_name: "Ladakh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.16504,
    longitude: 77.58402,
    wikiDataId: "Q230818",
  },
  {
    id: 132763,
    name: "Manawar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.05979,
    longitude: 77.40858,
    wikiDataId: "Q230818",
  },
  {
    id: 132764,
    name: "Limbdi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.56507,
    longitude: 71.81076,
    wikiDataId: "Q715228",
  },
  {
    id: 132765,
    name: "Malavalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.15876,
    longitude: 76.52174,
    wikiDataId: "Q46641",
  },
  {
    id: 132766,
    name: "Mandideep",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.58235,
    longitude: 78.85911,
    wikiDataId: "Q2230836",
  },
  {
    id: 132767,
    name: "Gairkata",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.40356,
    longitude: 80.08965,
    wikiDataId: "Q2236959",
  },
  {
    id: 132768,
    name: "Neturhat",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.5,
    longitude: 84.6,
    wikiDataId: "Q1948301",
  },
  {
    id: 132769,
    name: "Lohit District",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.84012,
    longitude: 96.19521,
    wikiDataId: "Q15438",
  },
  {
    id: 132770,
    name: "Mankeshwar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.59921,
    longitude: 73.92701,
    wikiDataId: "Q26786371",
  },
  {
    id: 132772,
    name: "Mandholi Kalan",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.42993,
    longitude: 75.80779,
    wikiDataId: "Q2240876",
  },
  {
    id: 132773,
    name: "Manmad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.75275,
    longitude: 73.40575,
    wikiDataId: "Q1929511",
  },
  {
    id: 132774,
    name: "Malpe",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.46907,
    longitude: 74.51906,
    wikiDataId: "Q13770162",
  },
  {
    id: 132775,
    name: "Nichlaul",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.75143,
    longitude: 77.29023,
    wikiDataId: "Q61436",
  },
  {
    id: 132776,
    name: "Mankhurd",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.98533,
    longitude: 76.52046,
    wikiDataId: "Q61436",
  },
  {
    id: 132777,
    name: "Lormi",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.27434,
    longitude: 81.70181,
    wikiDataId: "Q303819",
  },
  {
    id: 132778,
    name: "Rajaldesar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.4,
    longitude: 74.91667,
    wikiDataId: "Q2233448",
  },
  {
    id: 132779,
    name: "Lower Dibang Valley",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.37258,
    longitude: 95.8804,
    wikiDataId: "Q2373368",
  },
  {
    id: 132780,
    name: "Lower Subansiri",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.62554,
    longitude: 93.93908,
    wikiDataId: "Q15436",
  },
  {
    id: 132781,
    name: "Luckeesarai",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.1765,
    longitude: 86.0947,
    wikiDataId: "Q1929578",
  },
  {
    id: 132782,
    name: "Nigoh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.83928,
    longitude: 80.92313,
    wikiDataId: "Q47916",
  },
  {
    id: 132783,
    name: "Nihtaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 81,
    wikiDataId: "Q1773416",
  },
  {
    id: 132785,
    name: "Sangrur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.91204,
    longitude: 75.85379,
    wikiDataId: "Q200123",
  },
  {
    id: 132786,
    name: "Lumding Railway Colony",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.74903,
    longitude: 93.16998,
    wikiDataId: "Q2244284",
  },
  {
    id: 132787,
    name: "Balimila",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.9,
    longitude: 92.75,
    wikiDataId: "Q1947352",
  },
  {
    id: 132788,
    name: "Pushkar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.65312,
    longitude: 74.39993,
    wikiDataId: "Q1947352",
  },
  {
    id: 132789,
    name: "Mahendragarh",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.9935,
    longitude: 77.04563,
    wikiDataId: "Q2235937",
  },
  {
    id: 132790,
    name: "Naraura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.70827,
    longitude: 80.90256,
    wikiDataId: "Q2236872",
  },
  {
    id: 132791,
    name: "Nagar",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.5,
    longitude: 77.83333,
    wikiDataId: "Q837595",
  },
  {
    id: 132792,
    name: "Raisinghnagar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.67237,
    longitude: 76.17692,
    wikiDataId: "Q2234659",
  },
  {
    id: 132793,
    name: "Lala",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.55418,
    longitude: 92.61592,
    wikiDataId: "Q1929695",
  },
  {
    id: 132794,
    name: "Muluppilagadu",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.71667,
    longitude: 76.7,
    wikiDataId: "Q1929695",
  },
  {
    id: 132795,
    name: "Lalganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.86894,
    longitude: 85.17394,
    wikiDataId: "Q720183",
  },
  {
    id: 132796,
    name: "Naurangpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.93182,
    longitude: 81.70478,
    wikiDataId: "Q720183",
  },
  {
    id: 132797,
    name: "Lalpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.19073,
    longitude: 69.96351,
    wikiDataId: "Q2771361",
  },
  {
    id: 132798,
    name: "Rajakhera",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.55951,
    longitude: 76.32915,
    wikiDataId: "Q2229760",
  },
  {
    id: 132799,
    name: "Mandwa",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.86086,
    longitude: 73.54993,
    wikiDataId: "Q2237794",
  },
  {
    id: 132800,
    name: "Mangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.1427,
    longitude: 74.23946,
    wikiDataId: "Q2235584",
  },
  {
    id: 132802,
    name: "Lathi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.7231,
    longitude: 71.38843,
    wikiDataId: "Q3827464",
  },
  {
    id: 132803,
    name: "Newara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.11091,
    longitude: 77.77767,
    wikiDataId: "Q2244269",
  },
  {
    id: 132804,
    name: "Lunavada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.12841,
    longitude: 73.61043,
    wikiDataId: "Q2240915",
  },
  {
    id: 132805,
    name: "Noida",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.68564,
    longitude: 82.41106,
    wikiDataId: "Q2233483",
  },
  {
    id: 132806,
    name: "Machilipatnam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.18747,
    longitude: 81.13888,
    wikiDataId: "Q845662",
  },
  {
    id: 132807,
    name: "Odugattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.8525,
    longitude: 80.04667,
    wikiDataId: "Q2230752",
  },
  {
    id: 132808,
    name: "Madanapalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.5503,
    longitude: 78.50288,
    wikiDataId: "Q1017723",
  },
  {
    id: 132809,
    name: "Malur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.66035,
    longitude: 77.21239,
    wikiDataId: "Q2238951",
  },
  {
    id: 132810,
    name: "Mandya",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.58283,
    longitude: 77.04294,
    wikiDataId: "Q591656",
  },
  {
    id: 132811,
    name: "Madgaon",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.27501,
    longitude: 73.95786,
    wikiDataId: "Q515117",
  },
  {
    id: 132812,
    name: "Madhepura",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.8,
    longitude: 87,
    wikiDataId: "Q77746",
  },
  {
    id: 132813,
    name: "Madhipura",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.92127,
    longitude: 86.79271,
    wikiDataId: "Q1929621",
  },
  {
    id: 132814,
    name: "Mandleshwar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.56401,
    longitude: 80.91126,
    wikiDataId: "Q1929621",
  },
  {
    id: 132815,
    name: "Madhubani",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.35367,
    longitude: 86.07169,
    wikiDataId: "Q2767858",
  },
  {
    id: 132816,
    name: "Nirsa",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.27419,
    longitude: 86.63929,
    wikiDataId: "Q1883345",
  },
  {
    id: 132817,
    name: "Mangaluru",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.42602,
    longitude: 75.7382,
    wikiDataId: "Q1883345",
  },
  {
    id: 132818,
    name: "Omalur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.96226,
    longitude: 80.19864,
    wikiDataId: "Q2231394",
  },
  {
    id: 132819,
    name: "Ooty",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.90568,
    longitude: 76.96344,
    wikiDataId: "Q2236242",
  },
  {
    id: 132820,
    name: "Padmanabhapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.48098,
    longitude: 79.39939,
    wikiDataId: "Q2236038",
  },
  {
    id: 132821,
    name: "Palakkodu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.89,
    longitude: 78.03,
    wikiDataId: "Q15184",
  },
  {
    id: 132822,
    name: "Palamedu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.51167,
    longitude: 79.88485,
    wikiDataId: "Q2242785",
  },
  {
    id: 132823,
    name: "Orai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75586,
    longitude: 83.12773,
    wikiDataId: "Q1748623",
  },
  {
    id: 132824,
    name: "Sabrum",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.6004,
    longitude: 80.00543,
    wikiDataId: "Q1748623",
  },
  {
    id: 132825,
    name: "Mustafabad",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.96912,
    longitude: 76.29495,
    wikiDataId: "Q429091",
  },
  {
    id: 132826,
    name: "Pachperwa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.16945,
    longitude: 83.50667,
    wikiDataId: "Q429091",
  },
  {
    id: 132827,
    name: "Mahasamund",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.2,
    longitude: 82.5,
    wikiDataId: "Q2450240",
  },
  {
    id: 132828,
    name: "Sonamura",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.63171,
    longitude: 77.75556,
    wikiDataId: "Q15380",
  },
  {
    id: 132830,
    name: "Chima",
    state_id: 4011,
    state_code: "PY",
    state_name: "Puducherry",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.7,
    longitude: 75.53333,
    wikiDataId: "Q639279",
  },
  {
    id: 132831,
    name: "Mahemdavad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.82359,
    longitude: 72.75551,
    wikiDataId: "Q2233469",
  },
  {
    id: 132832,
    name: "Narayangarh",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.25,
    longitude: 76.16667,
    wikiDataId: "Q684019",
  },
  {
    id: 132833,
    name: "Mandsaur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.17592,
    longitude: 75.58715,
    wikiDataId: "Q1023674",
  },
  {
    id: 132834,
    name: "Mahesana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.66667,
    longitude: 72.5,
    wikiDataId: "Q2019694",
  },
  {
    id: 132835,
    name: "Mangawan",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.49471,
    longitude: 78.61593,
    wikiDataId: "Q2019694",
  },
  {
    id: 132836,
    name: "Padrauna",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.29191,
    longitude: 81.11775,
    wikiDataId: "Q1925034",
  },
  {
    id: 132837,
    name: "Pahasu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.2921,
    longitude: 79.87242,
    wikiDataId: "Q1814470",
  },
  {
    id: 132839,
    name: "Paigaon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.66368,
    longitude: 80.47371,
    wikiDataId: "Q2543369",
  },
  {
    id: 132840,
    name: "Parichha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.58624,
    longitude: 78.72771,
    wikiDataId: "Q2543313",
  },
  {
    id: 132841,
    name: "Mahudha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.82082,
    longitude: 72.94032,
    wikiDataId: "Q2606805",
  },
  {
    id: 132842,
    name: "Mahur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.18305,
    longitude: 93.11342,
    wikiDataId: "Q1925068",
  },
  {
    id: 132843,
    name: "Rajsamand",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.04594,
    longitude: 76.93152,
    wikiDataId: "Q2234118",
  },
  {
    id: 132844,
    name: "Manor",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.56,
    longitude: 73.4,
    wikiDataId: "Q463273",
  },
  {
    id: 132845,
    name: "Oran",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43262,
    longitude: 77.74338,
    wikiDataId: "Q544790",
  },
  {
    id: 132846,
    name: "Mansar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.08333,
    longitude: 73.41667,
    wikiDataId: "Q2238564",
  },
  {
    id: 132847,
    name: "Manvi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.3888,
    longitude: 75.10873,
    wikiDataId: "Q2237479",
  },
  {
    id: 132849,
    name: "Maharajgani",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.11017,
    longitude: 84.50365,
    wikiDataId: "Q2733647",
  },
  {
    id: 132852,
    name: "Munnar",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.70172,
    longitude: 75.53474,
    wikiDataId: "Q277480",
  },
  {
    id: 132853,
    name: "Maibong",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.30125,
    longitude: 93.13811,
    wikiDataId: "Q2563522",
  },
  {
    id: 132854,
    name: "Manpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.26594,
    longitude: 80.76063,
    wikiDataId: "Q747140",
  },
  {
    id: 132855,
    name: "Parshadepur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.29088,
    longitude: 80.3438,
    wikiDataId: "Q2606817",
  },
  {
    id: 132856,
    name: "Maregaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.45739,
    longitude: 76.2932,
    wikiDataId: "Q2546729",
  },
  {
    id: 132857,
    name: "Pathakpura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.16667,
    longitude: 79,
    wikiDataId: "Q2287173",
  },
  {
    id: 132858,
    name: "Sairang",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.56165,
    longitude: 91.63602,
    wikiDataId: "Q2606858",
  },
  {
    id: 132859,
    name: "Mairwa",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.23218,
    longitude: 84.16349,
    wikiDataId: "Q2563828",
  },
  {
    id: 132860,
    name: "Mau",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.50114,
    longitude: 79.92396,
    wikiDataId: "Q2552260",
  },
  {
    id: 132861,
    name: "Shahid Bhagat Singh Nagar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.75711,
    longitude: 74.95891,
    wikiDataId: "Q584810",
  },
  {
    id: 132862,
    name: "Shahkot",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.10335,
    longitude: 74.99631,
    wikiDataId: "Q2444472",
  },
  {
    id: 132863,
    name: "Ramganj Mandi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.04361,
    longitude: 74.72445,
    wikiDataId: "Q2650203",
  },
  {
    id: 132864,
    name: "Mauganj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25999,
    longitude: 76.14567,
    wikiDataId: "Q2446473",
  },
  {
    id: 132865,
    name: "Soro",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.36428,
    longitude: 81.888,
    wikiDataId: "Q2446473",
  },
  {
    id: 132866,
    name: "Muvattupula",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11,
    longitude: 76.16667,
    wikiDataId: "Q1030918",
  },
  {
    id: 132867,
    name: "Sham Churasi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.21121,
    longitude: 74.4818,
    wikiDataId: "Q1030918",
  },
  {
    id: 132868,
    name: "Mayakonda",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.38556,
    longitude: 77.06045,
    wikiDataId: "Q1030918",
  },
  {
    id: 132869,
    name: "Mihona",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.28286,
    longitude: 74.99024,
    wikiDataId: "Q2548648",
  },
  {
    id: 132870,
    name: "South Tripura",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.44781,
    longitude: 78.52633,
    wikiDataId: "Q640210",
  },
  {
    id: 132871,
    name: "Subarnapur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.25,
    longitude: 81.95,
    wikiDataId: "Q5122619",
  },
  {
    id: 132872,
    name: "Mehekar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.26214,
    longitude: 74.17574,
    wikiDataId: "Q2631025",
  },
  {
    id: 132873,
    name: "Noamundi",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78213,
    longitude: 86.28767,
    wikiDataId: "Q2648554",
  },
  {
    id: 132875,
    name: "Palavakkam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.49333,
    longitude: 78.03119,
    wikiDataId: "Q745078",
  },
  {
    id: 132876,
    name: "Palani",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.98231,
    longitude: 78.24796,
    wikiDataId: "Q745078",
  },
  {
    id: 132877,
    name: "Melukote",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.34962,
    longitude: 74.70394,
    wikiDataId: "Q745078",
  },
  {
    id: 132878,
    name: "Phalauda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.92223,
    longitude: 80.71078,
    wikiDataId: "Q2543287",
  },
  {
    id: 132879,
    name: "Balugaon",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78492,
    longitude: 92.46939,
    wikiDataId: "Q751531",
  },
  {
    id: 132880,
    name: "Pallappatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.16667,
    longitude: 80.26667,
    wikiDataId: "Q986582",
  },
  {
    id: 132881,
    name: "Pallattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.00788,
    longitude: 79.09184,
    wikiDataId: "Q2540962",
  },
  {
    id: 132882,
    name: "Pallavaram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.67318,
    longitude: 78.47096,
    wikiDataId: "Q2444092",
  },
  {
    id: 132883,
    name: "Pallikondai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.01083,
    longitude: 80.16861,
    wikiDataId: "Q2444092",
  },
  {
    id: 132884,
    name: "Pallipattu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.60772,
    longitude: 78.42582,
    wikiDataId: "Q2554965",
  },
  {
    id: 132885,
    name: "Pallippatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.14776,
    longitude: 77.30552,
    wikiDataId: "Q2541643",
  },
  {
    id: 132886,
    name: "Mehkar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.00436,
    longitude: 73.94346,
    wikiDataId: "Q1320215",
  },
  {
    id: 132887,
    name: "Unakoti",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.87074,
    longitude: 79.42863,
    wikiDataId: "Q720842",
  },
  {
    id: 132888,
    name: "West Tripura",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.96506,
    longitude: 79.47475,
    wikiDataId: "Q2483821",
  },
  {
    id: 132889,
    name: "Panruti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.27571,
    longitude: 79.12362,
    wikiDataId: "Q2540938",
  },
  {
    id: 132890,
    name: "Mandapeta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.86254,
    longitude: 81.92921,
    wikiDataId: "Q2249065",
  },
  {
    id: 132891,
    name: "Mandasa",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.8683,
    longitude: 84.46296,
    wikiDataId: "Q2249065",
  },
  {
    id: 132892,
    name: "Narnaul",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.7085,
    longitude: 75.68296,
    wikiDataId: "Q2249065",
  },
  {
    id: 132893,
    name: "Nahan",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.71194,
    longitude: 76.93273,
    wikiDataId: "Q195931",
  },
  {
    id: 132894,
    name: "Morena",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.08166,
    longitude: 77.53328,
    wikiDataId: "Q2446436",
  },
  {
    id: 132895,
    name: "Multai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.64041,
    longitude: 80.51344,
    wikiDataId: "Q2341670",
  },
  {
    id: 132897,
    name: "Mungaoli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.33333,
    longitude: 75.25,
    wikiDataId: "Q1870014",
  },
  {
    id: 132898,
    name: "Muddebihal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.5223,
    longitude: 76.89746,
    wikiDataId: "Q1006277",
  },
  {
    id: 132899,
    name: "Phaphamau",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.48655,
    longitude: 78.12732,
    wikiDataId: "Q2441875",
  },
  {
    id: 132900,
    name: "Ringas",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.86374,
    longitude: 76.54999,
    wikiDataId: "Q2441875",
  },
  {
    id: 132901,
    name: "Maner",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.64602,
    longitude: 84.87291,
    wikiDataId: "Q2317827",
  },
  {
    id: 132902,
    name: "Mangalagiri",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.43083,
    longitude: 80.56815,
    wikiDataId: "Q2349939",
  },
  {
    id: 132903,
    name: "Papanasam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.76473,
    longitude: 78.64037,
    wikiDataId: "Q2625490",
  },
  {
    id: 132904,
    name: "Mangaldai",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.44212,
    longitude: 92.03047,
    wikiDataId: "Q2308247",
  },
  {
    id: 132905,
    name: "Mudgal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.91723,
    longitude: 74.85603,
    wikiDataId: "Q127041",
  },
  {
    id: 132906,
    name: "Chennimalai",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.50965,
    longitude: 88.52206,
    wikiDataId: "Q2308307",
  },
  {
    id: 132907,
    name: "Murwara",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.66754,
    longitude: 81.54644,
    wikiDataId: "Q2552252",
  },
  {
    id: 132908,
    name: "Gangadharpur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.79094,
    longitude: 77.87836,
    wikiDataId: "Q2552130",
  },
  {
    id: 132909,
    name: "Mohol",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.31379,
    longitude: 77.34178,
    wikiDataId: "Q2552130",
  },
  {
    id: 132910,
    name: "Phaphund",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.98546,
    longitude: 84.17233,
    wikiDataId: "Q26783828",
  },
  {
    id: 132911,
    name: "Manihari",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.33891,
    longitude: 87.61998,
    wikiDataId: "Q2453339",
  },
  {
    id: 132912,
    name: "Mudgere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.35,
    longitude: 74.78333,
    wikiDataId: "Q2453339",
  },
  {
    id: 132913,
    name: "Muvattupuzha",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.12018,
    longitude: 76.11996,
    wikiDataId: "Q2568380",
  },
  {
    id: 132914,
    name: "Pheona",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.53046,
    longitude: 81.37566,
    wikiDataId: "Q2333431",
  },
  {
    id: 132915,
    name: "Nadapuram",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.71287,
    longitude: 74.88857,
    wikiDataId: "Q720793",
  },
  {
    id: 132916,
    name: "Saitlaw",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.53347,
    longitude: 89.86373,
    wikiDataId: "Q720860",
  },
  {
    id: 132917,
    name: "Phulpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.05189,
    longitude: 82.22961,
    wikiDataId: "Q926951",
  },
  {
    id: 132918,
    name: "Moram",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.25334,
    longitude: 74.43755,
    wikiDataId: "Q2317441",
  },
  {
    id: 132919,
    name: "Papireddippatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.66626,
    longitude: 79.45064,
    wikiDataId: "Q2673456",
  },
  {
    id: 132920,
    name: "Naduvannur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.99223,
    longitude: 76.46418,
    wikiDataId: "Q2673456",
  },
  {
    id: 132921,
    name: "Salumbar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.24,
    longitude: 76.80182,
    wikiDataId: "Q931999",
  },
  {
    id: 132922,
    name: "Sambhar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.29769,
    longitude: 75.9495,
    wikiDataId: "Q931999",
  },
  {
    id: 132923,
    name: "Pakur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.37456,
    longitude: 85.19234,
    wikiDataId: "Q931999",
  },
  {
    id: 132924,
    name: "Morsi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.7244,
    longitude: 72.90966,
    wikiDataId: "Q3844824",
  },
  {
    id: 132926,
    name: "Mowad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.39602,
    longitude: 79.2635,
    wikiDataId: "Q1320187",
  },
  {
    id: 132927,
    name: "Achhnera",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.65087,
    longitude: 79.66501,
    wikiDataId: "Q6752062",
  },
  {
    id: 132928,
    name: "Afzalgarh",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.98102,
    longitude: 80.7547,
    wikiDataId: "Q2483781",
  },
  {
    id: 132929,
    name: "Nagrota",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.2574,
    longitude: 77.17481,
    wikiDataId: "Q83443",
  },
  {
    id: 132930,
    name: "Mohgaon",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.47764,
    longitude: 75.14095,
    wikiDataId: "Q83443",
  },
  {
    id: 132931,
    name: "Morar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23566,
    longitude: 75.08917,
    wikiDataId: "Q2446481",
  },
  {
    id: 132932,
    name: "Papparappatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.19214,
    longitude: 79.94193,
    wikiDataId: "Q2554778",
  },
  {
    id: 132933,
    name: "Nedumangad",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.27641,
    longitude: 77.16205,
    wikiDataId: "Q2554778",
  },
  {
    id: 132934,
    name: "Margherita",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.28482,
    longitude: 95.66796,
    wikiDataId: "Q1924981",
  },
  {
    id: 132935,
    name: "Marhaura",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.97349,
    longitude: 84.86796,
    wikiDataId: "Q2560728",
  },
  {
    id: 132936,
    name: "Pihani",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.60404,
    longitude: 82.60379,
    wikiDataId: "Q676662",
  },
  {
    id: 132937,
    name: "Mariani",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.65725,
    longitude: 94.31529,
    wikiDataId: "Q2330626",
  },
  {
    id: 132938,
    name: "Masaurhi Buzurg",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.35417,
    longitude: 85.03195,
    wikiDataId: "Q2453418",
  },
  {
    id: 132939,
    name: "Pattukkottai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.56831,
    longitude: 76.64087,
    wikiDataId: "Q2453418",
  },
  {
    id: 132940,
    name: "Pilibhit",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.43594,
    longitude: 80.15653,
    wikiDataId: "Q2673424",
  },
  {
    id: 132941,
    name: "Pilkhua",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.63333,
    longitude: 77.58333,
    wikiDataId: "Q200311",
  },
  {
    id: 132942,
    name: "Neyyattinkara",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.93018,
    longitude: 75.57152,
    wikiDataId: "Q2475713",
  },
  {
    id: 132943,
    name: "Nagod",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.26584,
    longitude: 78.67108,
    wikiDataId: "Q3852371",
  },
  {
    id: 132944,
    name: "Pilkhuwa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.0294,
    longitude: 83.50756,
    wikiDataId: "Q493091",
  },
  {
    id: 132945,
    name: "Pipraich",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.69515,
    longitude: 81.92336,
    wikiDataId: "Q2544231",
  },
  {
    id: 132946,
    name: "Prayagraj (Allahabad)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.68312,
    longitude: 80.11419,
    wikiDataId: "Q580138",
  },
  {
    id: 132947,
    name: "Naigarhi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.66721,
    longitude: 81.87339,
    wikiDataId: "Q547781",
  },
  {
    id: 132948,
    name: "Sultanpur Lodhi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.08333,
    longitude: 75.25,
    wikiDataId: "Q3852404",
  },
  {
    id: 132949,
    name: "Pukhrayan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.42876,
    longitude: 80.88008,
    wikiDataId: "Q656717",
  },
  {
    id: 132950,
    name: "Ottappalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.26667,
    longitude: 75.91667,
    wikiDataId: "Q2475602",
  },
  {
    id: 132951,
    name: "Purmafi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.10288,
    longitude: 77.92199,
    wikiDataId: "Q2544268",
  },
  {
    id: 132952,
    name: "Pennagaram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.10354,
    longitude: 79.655,
    wikiDataId: "Q339986",
  },
  {
    id: 132953,
    name: "West Jaintia Hills",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.60998,
    longitude: 93.88873,
    wikiDataId: "Q2564021",
  },
  {
    id: 132954,
    name: "Sundargarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.75,
    longitude: 86.5,
    wikiDataId: "Q1914546",
  },
  {
    id: 132955,
    name: "Agra",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.75,
    longitude: 78.25,
    wikiDataId: "Q15386",
  },
  {
    id: 132956,
    name: "Ahraura",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.62972,
    longitude: 78.48139,
    wikiDataId: "Q3430279",
  },
  {
    id: 132957,
    name: "Aidalpur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.60644,
    longitude: 78.54007,
    wikiDataId: "Q3430279",
  },
  {
    id: 132958,
    name: "Qadirganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.91667,
    longitude: 77.68333,
    wikiDataId: "Q200237",
  },
  {
    id: 132959,
    name: "Samdari",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.57615,
    longitude: 74.68661,
    wikiDataId: "Q1764627",
  },
  {
    id: 132960,
    name: "Meghraj",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.49805,
    longitude: 73.51352,
    wikiDataId: "Q2673443",
  },
  {
    id: 132961,
    name: "Murgud",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.1505,
    longitude: 76.56841,
    wikiDataId: "Q2673732",
  },
  {
    id: 132962,
    name: "Radha Kund",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.87889,
    longitude: 83.11611,
    wikiDataId: "Q1122593",
  },
  {
    id: 132963,
    name: "Radhakund",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.97579,
    longitude: 83.10995,
    wikiDataId: "Q2544297",
  },
  {
    id: 132964,
    name: "Mulgund",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.66258,
    longitude: 76.64861,
    wikiDataId: "Q2667680",
  },
  {
    id: 132965,
    name: "Pennathur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.03241,
    longitude: 78.3393,
    wikiDataId: "Q2554919",
  },
  {
    id: 132966,
    name: "Mendarda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.32112,
    longitude: 70.44078,
    wikiDataId: "Q2768932",
  },
  {
    id: 132967,
    name: "Sanchor",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.64859,
    longitude: 74.03414,
    wikiDataId: "Q2544240",
  },
  {
    id: 132969,
    name: "Peraiyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.16806,
    longitude: 78.44944,
    wikiDataId: "Q1321125",
  },
  {
    id: 132970,
    name: "Perambalur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.78796,
    longitude: 77.8008,
    wikiDataId: "Q2725572",
  },
  {
    id: 132971,
    name: "Murud (Ratnagiri)",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.1334,
    longitude: 73.11162,
    wikiDataId: "Q2546690",
  },
  {
    id: 132972,
    name: "Murum",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.63359,
    longitude: 74.78773,
    wikiDataId: "Q26782291",
  },
  {
    id: 132973,
    name: "Nainpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.28373,
    longitude: 78.98048,
    wikiDataId: "Q2548623",
  },
  {
    id: 132974,
    name: "Raebareli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.61031,
    longitude: 79.16997,
    wikiDataId: "Q670604",
  },
  {
    id: 132975,
    name: "Airwa",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.8722,
    longitude: 79.56247,
    wikiDataId: "Q15528101",
  },
  {
    id: 132976,
    name: "Rasulabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.1449,
    longitude: 82.56534,
    wikiDataId: "Q956371",
  },
  {
    id: 132977,
    name: "Rath",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43137,
    longitude: 80.53157,
    wikiDataId: "Q26782310",
  },
  {
    id: 132978,
    name: "Modasa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.46253,
    longitude: 73.29857,
    wikiDataId: "Q2320097",
  },
  {
    id: 132979,
    name: "Sunam",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.80376,
    longitude: 75.14938,
    wikiDataId: "Q1946896",
  },
  {
    id: 132980,
    name: "Talwandi Bhai",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.67995,
    longitude: 76.72211,
    wikiDataId: "Q1949069",
  },
  {
    id: 132981,
    name: "Rehar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.25261,
    longitude: 80.24498,
    wikiDataId: "Q1925007",
  },
  {
    id: 132982,
    name: "Peranampattu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.05936,
    longitude: 78.13965,
    wikiDataId: "Q2540986",
  },
  {
    id: 132983,
    name: "Namli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.63941,
    longitude: 78.73638,
    wikiDataId: "Q2552172",
  },
  {
    id: 132984,
    name: "Mohiuddinnagar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.57374,
    longitude: 85.66944,
    wikiDataId: "Q2564073",
  },
  {
    id: 132985,
    name: "Nagpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.31012,
    longitude: 78.82969,
    wikiDataId: "Q751161",
  },
  {
    id: 132986,
    name: "Raya",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.78008,
    longitude: 80.67497,
    wikiDataId: "Q1925021",
  },
  {
    id: 132987,
    name: "West Khasi Hills",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.4975,
    longitude: 93.77791,
    wikiDataId: "Q2625382",
  },
  {
    id: 132988,
    name: "Mokameh",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.39662,
    longitude: 85.9219,
    wikiDataId: "Q2453482",
  },
  {
    id: 132989,
    name: "Baud",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.31393,
    longitude: 94.51675,
    wikiDataId: "Q2175311",
  },
  {
    id: 132990,
    name: "Baudh",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 94.83333,
    wikiDataId: "Q2339648",
  },
  {
    id: 132991,
    name: "Monghyr",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.37459,
    longitude: 86.47455,
    wikiDataId: "Q36796",
  },
  {
    id: 132992,
    name: "Naldurg",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.78812,
    longitude: 76.47077,
    wikiDataId: "Q26782317",
  },
  {
    id: 132993,
    name: "Morbi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.81731,
    longitude: 70.8377,
    wikiDataId: "Q2087713",
  },
  {
    id: 132994,
    name: "Narsimhapur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.16667,
    longitude: 77.5,
    wikiDataId: "Q2341467",
  },
  {
    id: 132995,
    name: "Morigaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.25213,
    longitude: 92.34238,
    wikiDataId: "Q42737",
  },
  {
    id: 132996,
    name: "Talwara",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.79014,
    longitude: 76.49883,
    wikiDataId: "Q1748062",
  },
  {
    id: 132997,
    name: "Morjim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.63097,
    longitude: 73.73903,
    wikiDataId: "Q6912471",
  },
  {
    id: 132998,
    name: "Mormugao",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.38914,
    longitude: 73.81491,
    wikiDataId: "Q1814480",
  },
  {
    id: 132999,
    name: "Nalegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.3403,
    longitude: 78.01258,
    wikiDataId: "Q26782328",
  },
  {
    id: 133000,
    name: "Morwa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.90469,
    longitude: 73.83912,
    wikiDataId: "Q1814480",
  },
  {
    id: 133001,
    name: "Renukoot",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.8525,
    longitude: 78.79703,
    wikiDataId: "Q1345006",
  },
  {
    id: 133002,
    name: "Moranha",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.18735,
    longitude: 94.91557,
    wikiDataId: "Q1345006",
  },
  {
    id: 133003,
    name: "Naraini",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2264,
    longitude: 78.22482,
    wikiDataId: "Q1345006",
  },
  {
    id: 133004,
    name: "Renukut",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.72595,
    longitude: 78.95029,
    wikiDataId: "Q2228666",
  },
  {
    id: 133005,
    name: "Mothihari",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.64862,
    longitude: 84.91656,
    wikiDataId: "Q1774606",
  },
  {
    id: 133006,
    name: "Nampur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.46475,
    longitude: 78.45103,
    wikiDataId: "Q1774606",
  },
  {
    id: 133007,
    name: "Reoti",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.08866,
    longitude: 83.29088,
    wikiDataId: "Q12447218",
  },
  {
    id: 133008,
    name: "Mundargi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.33782,
    longitude: 76.13173,
    wikiDataId: "Q12447218",
  },
  {
    id: 133009,
    name: "Mundgod",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.01191,
    longitude: 76.44203,
    wikiDataId: "Q2451106",
  },
  {
    id: 133010,
    name: "Munirabad",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.13353,
    longitude: 75.6416,
    wikiDataId: "Q2552562",
  },
  {
    id: 133011,
    name: "Murudeshwara",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.33354,
    longitude: 75.28305,
    wikiDataId: "Q1514203",
  },
  {
    id: 133012,
    name: "Nanded",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.15657,
    longitude: 77.50304,
    wikiDataId: "Q2546606",
  },
  {
    id: 133013,
    name: "Peravurani",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.34169,
    longitude: 78.51388,
    wikiDataId: "Q2546606",
  },
  {
    id: 133014,
    name: "Reotipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.28307,
    longitude: 83.11968,
    wikiDataId: "Q26782335",
  },
  {
    id: 133015,
    name: "Palamu",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.77015,
    longitude: 86.72746,
    wikiDataId: "Q2738433",
  },
  {
    id: 133016,
    name: "Richha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.61907,
    longitude: 83.75576,
    wikiDataId: "Q2542966",
  },
  {
    id: 133017,
    name: "Tarn Taran Sahib",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.95394,
    longitude: 75.61716,
    wikiDataId: "Q2442516",
  },
  {
    id: 133018,
    name: "Nandgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.70636,
    longitude: 77.36795,
    wikiDataId: "Q26782341",
  },
  {
    id: 133019,
    name: "Behror",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.47426,
    longitude: 74.5166,
    wikiDataId: "Q2087676",
  },
  {
    id: 133020,
    name: "Mysuru",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.16352,
    longitude: 78.39346,
    wikiDataId: "Q2558254",
  },
  {
    id: 133021,
    name: "Nagamangala",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.2807,
    longitude: 75.52132,
    wikiDataId: "Q2552545",
  },
  {
    id: 133022,
    name: "Narsinghgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.77463,
    longitude: 78.25756,
    wikiDataId: "Q2446460",
  },
  {
    id: 133023,
    name: "Palackattumala",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.79788,
    longitude: 75.45111,
    wikiDataId: "Q2568413",
  },
  {
    id: 133024,
    name: "Nandurbar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.07283,
    longitude: 72.88261,
    wikiDataId: "Q1156",
  },
  {
    id: 133025,
    name: "Narayangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.12636,
    longitude: 72.84897,
    wikiDataId: "Q2341660",
  },
  {
    id: 133026,
    name: "Narasimharajapura",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.20677,
    longitude: 75.8839,
    wikiDataId: "Q2341660",
  },
  {
    id: 133027,
    name: "Naregal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.97144,
    longitude: 75.03658,
    wikiDataId: "Q2282297",
  },
  {
    id: 133028,
    name: "Narwar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.06693,
    longitude: 76.49326,
    wikiDataId: "Q2552231",
  },
  {
    id: 133029,
    name: "Mundra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.83918,
    longitude: 69.7219,
    wikiDataId: "Q2234250",
  },
  {
    id: 133030,
    name: "Nasrullahganj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.40837,
    longitude: 78.09588,
    wikiDataId: "Q2446412",
  },
  {
    id: 133031,
    name: "Mungeli",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.06566,
    longitude: 81.68543,
    wikiDataId: "Q2452734",
  },
  {
    id: 133032,
    name: "Munger",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.36099,
    longitude: 86.46515,
    wikiDataId: "Q258280",
  },
  {
    id: 133033,
    name: "Nargund",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.30928,
    longitude: 76.3383,
    wikiDataId: "Q2738447",
  },
  {
    id: 133034,
    name: "Palakkad district",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.08818,
    longitude: 77.06239,
    wikiDataId: "Q1025124",
  },
  {
    id: 133035,
    name: "Nardana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.25395,
    longitude: 73.38993,
    wikiDataId: "Q2546816",
  },
  {
    id: 133036,
    name: "Nariman Point",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.39604,
    longitude: 74.19142,
    wikiDataId: "Q2445568",
  },
  {
    id: 133037,
    name: "Murliganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.8969,
    longitude: 86.99577,
    wikiDataId: "Q2560591",
  },
  {
    id: 133038,
    name: "Sachendi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.57788,
    longitude: 77.94091,
    wikiDataId: "Q2725627",
  },
  {
    id: 133039,
    name: "Narkhed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.73299,
    longitude: 77.36694,
    wikiDataId: "Q2445448",
  },
  {
    id: 133040,
    name: "Nashik",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.32817,
    longitude: 72.9621,
    wikiDataId: "Q1924853",
  },
  {
    id: 133041,
    name: "Navalgund",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.0943,
    longitude: 74.4845,
    wikiDataId: "Q2445448",
  },
  {
    id: 133042,
    name: "Neemuch",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83776,
    longitude: 80.39405,
    wikiDataId: "Q172581",
  },
  {
    id: 133043,
    name: "Sabalpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.78069,
    longitude: 77.49865,
    wikiDataId: "Q2450981",
  },
  {
    id: 133044,
    name: "Pashchim Singhbhum",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.51135,
    longitude: 86.45713,
    wikiDataId: "Q2738423",
  },
  {
    id: 133045,
    name: "Periyanayakkanpalaiyam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.95299,
    longitude: 78.44427,
    wikiDataId: "Q2554808",
  },
  {
    id: 133046,
    name: "Gangarampur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.45498,
    longitude: 78.07068,
    wikiDataId: "Q668395",
  },
  {
    id: 133047,
    name: "Narnaund",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.2022,
    longitude: 77.14873,
    wikiDataId: "Q3868703",
  },
  {
    id: 133048,
    name: "Sadabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.37837,
    longitude: 81.79607,
    wikiDataId: "Q2542407",
  },
  {
    id: 133049,
    name: "Periyanegamam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.39505,
    longitude: 79.49353,
    wikiDataId: "Q2542407",
  },
  {
    id: 133050,
    name: "Panamaram",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.97985,
    longitude: 76.57381,
    wikiDataId: "Q2341743",
  },
  {
    id: 133051,
    name: "Safipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.45,
    longitude: 77.58333,
    wikiDataId: "Q2768828",
  },
  {
    id: 133052,
    name: "Muzaffarpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.16667,
    longitude: 85.41667,
    wikiDataId: "Q77731",
  },
  {
    id: 133053,
    name: "Nelamangala",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.23,
    longitude: 76.42,
    wikiDataId: "Q591781",
  },
  {
    id: 133054,
    name: "Mandla",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.12767,
    longitude: 76.31672,
    wikiDataId: "Q2231756",
  },
  {
    id: 133055,
    name: "Macherla",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.47635,
    longitude: 79.43533,
    wikiDataId: "Q2241562",
  },
  {
    id: 133056,
    name: "Sardulgarh",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.91557,
    longitude: 76.20016,
    wikiDataId: "Q2241537",
  },
  {
    id: 133057,
    name: "Nurpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.11807,
    longitude: 80.14058,
    wikiDataId: "Q2232340",
  },
  {
    id: 133058,
    name: "Obra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.27522,
    longitude: 79.1859,
    wikiDataId: "Q2237064",
  },
  {
    id: 133059,
    name: "Madugula",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.91589,
    longitude: 82.81578,
    wikiDataId: "Q3420577",
  },
  {
    id: 133060,
    name: "Manipal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.95706,
    longitude: 77.22374,
    wikiDataId: "Q3420577",
  },
  {
    id: 133061,
    name: "Parol",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.09256,
    longitude: 74.59016,
    wikiDataId: "Q2229351",
  },
  {
    id: 133062,
    name: "Mastiholi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.15988,
    longitude: 76.20832,
    wikiDataId: "Q2546703",
  },
  {
    id: 133063,
    name: "Masur, India",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.2698,
    longitude: 73.50031,
    wikiDataId: "Q2546703",
  },
  {
    id: 133064,
    name: "Makum",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.48652,
    longitude: 95.43646,
    wikiDataId: "Q2563541",
  },
  {
    id: 133065,
    name: "Mazagaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.54966,
    longitude: 74.53462,
    wikiDataId: "Q580965",
  },
  {
    id: 133066,
    name: "Sirhind-Fategarh",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.5309,
    longitude: 75.87949,
    wikiDataId: "Q1470987",
  },
  {
    id: 133067,
    name: "Palladam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.54424,
    longitude: 78.14078,
    wikiDataId: "Q2725565",
  },
  {
    id: 133068,
    name: "Malpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.36035,
    longitude: 73.46595,
    wikiDataId: "Q2234238",
  },
  {
    id: 133069,
    name: "Rani",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2838,
    longitude: 75.36458,
    wikiDataId: "Q2544554",
  },
  {
    id: 133070,
    name: "Mehergaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.05981,
    longitude: 73.4629,
    wikiDataId: "Q2450888",
  },
  {
    id: 133071,
    name: "Mudbidri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.00322,
    longitude: 77.93798,
    wikiDataId: "Q775609",
  },
  {
    id: 133072,
    name: "Rawatbhata",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.44126,
    longitude: 74.56979,
    wikiDataId: "Q775609",
  },
  {
    id: 133073,
    name: "Mandal",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.28865,
    longitude: 71.91854,
    wikiDataId: "Q26783813",
  },
  {
    id: 133074,
    name: "Rawatsar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.19407,
    longitude: 75.07215,
    wikiDataId: "Q2442085",
  },
  {
    id: 133075,
    name: "Mundi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.17598,
    longitude: 75.65995,
    wikiDataId: "Q2326728",
  },
  {
    id: 133076,
    name: "Mandvi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.83282,
    longitude: 69.35237,
    wikiDataId: "Q2570689",
  },
  {
    id: 133077,
    name: "Mangrol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.12268,
    longitude: 70.11484,
    wikiDataId: "Q2577440",
  },
  {
    id: 133078,
    name: "Sadri",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.33061,
    longitude: 76.50973,
    wikiDataId: "Q674716",
  },
  {
    id: 133079,
    name: "Phariha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.06083,
    longitude: 81.09961,
    wikiDataId: "Q2648298",
  },
  {
    id: 133080,
    name: "Nagda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.43151,
    longitude: 75.62107,
    wikiDataId: "Q1924868",
  },
  {
    id: 133081,
    name: "Mansa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.42564,
    longitude: 72.65739,
    wikiDataId: "Q720211",
  },
  {
    id: 133082,
    name: "Sri Muktsar Sahib",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.98844,
    longitude: 75.40167,
    wikiDataId: "Q2573916",
  },
  {
    id: 133083,
    name: "Mudhol",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.99126,
    longitude: 77.05034,
    wikiDataId: "Q2451070",
  },
  {
    id: 133084,
    name: "Mudkhed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.30133,
    longitude: 76.49735,
    wikiDataId: "Q2546880",
  },
  {
    id: 133085,
    name: "Manavadar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.49813,
    longitude: 70.13775,
    wikiDataId: "Q1330938",
  },
  {
    id: 133086,
    name: "Mapuca",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.59154,
    longitude: 73.80898,
    wikiDataId: "Q2087705",
  },
  {
    id: 133087,
    name: "Pichhaura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.7368,
    longitude: 78.56891,
    wikiDataId: "Q2087705",
  },
  {
    id: 133088,
    name: "Markapur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.73534,
    longitude: 79.26848,
    wikiDataId: "Q2087705",
  },
  {
    id: 133089,
    name: "Paramagudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.38826,
    longitude: 78.00316,
    wikiDataId: "Q2540915",
  },
  {
    id: 133090,
    name: "Mumbai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98281,
    longitude: 73.2652,
    wikiDataId: "Q463526",
  },
  {
    id: 133091,
    name: "Pennadam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.32147,
    longitude: 79.20245,
    wikiDataId: "Q1155053",
  },
  {
    id: 133092,
    name: "Nilēshwar",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.25929,
    longitude: 76.55642,
    wikiDataId: "Q1155053",
  },
  {
    id: 133093,
    name: "Mulbagal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.28894,
    longitude: 76.08305,
    wikiDataId: "Q1155053",
  },
  {
    id: 133094,
    name: "Peranamallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.27951,
    longitude: 80.25815,
    wikiDataId: "Q2554830",
  },
  {
    id: 133095,
    name: "Rasra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.54012,
    longitude: 79.20817,
    wikiDataId: "Q1748364",
  },
  {
    id: 133096,
    name: "Ranipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.29026,
    longitude: 77.94939,
    wikiDataId: "Q2544316",
  },
  {
    id: 133097,
    name: "Ranipur Barsi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.02963,
    longitude: 79.66778,
    wikiDataId: "Q2544316",
  },
  {
    id: 133098,
    name: "Mulki",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.06653,
    longitude: 74.99525,
    wikiDataId: "Q1949058",
  },
  {
    id: 133099,
    name: "Nandnee",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.06987,
    longitude: 79.67826,
    wikiDataId: "Q3824950",
  },
  {
    id: 133100,
    name: "Periyakulam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.79426,
    longitude: 77.7115,
    wikiDataId: "Q2541013",
  },
  {
    id: 133101,
    name: "Nanjangud",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.09101,
    longitude: 74.79353,
    wikiDataId: "Q2558126",
  },
  {
    id: 133102,
    name: "Sangaria",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.0631,
    longitude: 73.82304,
    wikiDataId: "Q2544352",
  },
  {
    id: 133103,
    name: "Palghat",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.98493,
    longitude: 76.57728,
    wikiDataId: "Q2544352",
  },
  {
    id: 133104,
    name: "Talcher",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.2333,
    longitude: 82.55,
    wikiDataId: "Q2396798",
  },
  {
    id: 133105,
    name: "Nabinagar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.60681,
    longitude: 84.12624,
    wikiDataId: "Q2453472",
  },
  {
    id: 133106,
    name: "Nadiad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.69385,
    longitude: 72.86157,
    wikiDataId: "Q219039",
  },
  {
    id: 133107,
    name: "Paravur Tekkumbhagam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.48772,
    longitude: 75.77511,
    wikiDataId: "Q219039",
  },
  {
    id: 133108,
    name: "Periyapatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.48075,
    longitude: 76.54365,
    wikiDataId: "Q2554761",
  },
  {
    id: 133109,
    name: "Saharanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.10784,
    longitude: 79.02283,
    wikiDataId: "Q2542862",
  },
  {
    id: 133110,
    name: "Nagaon",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.35037,
    longitude: 92.69225,
    wikiDataId: "Q42686",
  },
  {
    id: 133111,
    name: "Periyapattinam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.85,
    longitude: 79.74,
    wikiDataId: "Q15185",
  },
  {
    id: 133112,
    name: "Sardarshahr",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.42397,
    longitude: 77.09922,
    wikiDataId: "Q1925183",
  },
  {
    id: 133113,
    name: "Palampur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.13808,
    longitude: 77.17393,
    wikiDataId: "Q4311529",
  },
  {
    id: 133114,
    name: "Nagari",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.32139,
    longitude: 79.58557,
    wikiDataId: "Q3869911",
  },
  {
    id: 133115,
    name: "Nepanagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.45834,
    longitude: 75.41759,
    wikiDataId: "Q1026190",
  },
  {
    id: 133116,
    name: "Nevasa",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.14631,
    longitude: 79.08491,
    wikiDataId: "Q1513",
  },
  {
    id: 133117,
    name: "Nighoj",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.14911,
    longitude: 79.10748,
    wikiDataId: "Q305259",
  },
  {
    id: 133118,
    name: "Pandoh",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.0571,
    longitude: 76.09139,
    wikiDataId: "Q305259",
  },
  {
    id: 133119,
    name: "Sahawar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.61872,
    longitude: 81.14043,
    wikiDataId: "Q2542873",
  },
  {
    id: 133120,
    name: "Sahaspur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.44433,
    longitude: 78.43646,
    wikiDataId: "Q2441847",
  },
  {
    id: 133121,
    name: "Naharlagun",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.10467,
    longitude: 93.69518,
    wikiDataId: "Q2576091",
  },
  {
    id: 133122,
    name: "Nahorkatiya",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.28912,
    longitude: 95.3418,
    wikiDataId: "Q2733371",
  },
  {
    id: 133123,
    name: "Garui",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.39743,
    longitude: 79.44686,
    wikiDataId: "Q773213",
  },
  {
    id: 133124,
    name: "Palera",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.42996,
    longitude: 80.10561,
    wikiDataId: "Q2446391",
  },
  {
    id: 133125,
    name: "Sawai Madhopur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.77145,
    longitude: 75.84978,
    wikiDataId: "Q2544256",
  },
  {
    id: 133126,
    name: "Najafgarh",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.60922,
    longitude: 76.97982,
    wikiDataId: "Q2544256",
  },
  {
    id: 133127,
    name: "Sahibabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.61194,
    longitude: 78.34274,
    wikiDataId: "Q2543489",
  },
  {
    id: 133128,
    name: "Abhaneri",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.12586,
    longitude: 75.47508,
    wikiDataId: "Q2546657",
  },
  {
    id: 133129,
    name: "Sahpau",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.91964,
    longitude: 77.30438,
    wikiDataId: "Q2234260",
  },
  {
    id: 133130,
    name: "Nalbari",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.5,
    longitude: 91.4,
    wikiDataId: "Q42779",
  },
  {
    id: 133131,
    name: "Nipani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.81667,
    longitude: 76.28182,
    wikiDataId: "Q2572967",
  },
  {
    id: 133132,
    name: "Aliganj",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.16667,
    longitude: 79.5,
    wikiDataId: "Q15384",
  },
  {
    id: 133133,
    name: "Naliya",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.26058,
    longitude: 68.82655,
    wikiDataId: "Q6960925",
  },
  {
    id: 133134,
    name: "Perur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.3,
    longitude: 78.13,
    wikiDataId: "Q15187",
  },
  {
    id: 133135,
    name: "Pollachi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.35811,
    longitude: 77.32115,
    wikiDataId: "Q943727",
  },
  {
    id: 133136,
    name: "Polur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.72766,
    longitude: 79.00707,
    wikiDataId: "Q943727",
  },
  {
    id: 133137,
    name: "Chetput",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.16494,
    longitude: 88.3638,
    wikiDataId: "Q2483862",
  },
  {
    id: 133138,
    name: "Pali",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.46115,
    longitude: 75.06036,
    wikiDataId: "Q2548631",
  },
  {
    id: 133139,
    name: "Saidpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.71215,
    longitude: 77.41728,
    wikiDataId: "Q2542644",
  },
  {
    id: 133140,
    name: "Ponnamaravati",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.97795,
    longitude: 80.06781,
    wikiDataId: "Q2542644",
  },
  {
    id: 133141,
    name: "Osmanabad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.91667,
    longitude: 77.5,
    wikiDataId: "Q855122",
  },
  {
    id: 133142,
    name: "Sakhanu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.71102,
    longitude: 77.38653,
    wikiDataId: "Q2627110",
  },
  {
    id: 133143,
    name: "Nandigama",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.7717,
    longitude: 80.28596,
    wikiDataId: "Q3428280",
  },
  {
    id: 133144,
    name: "Nandikotkur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.85668,
    longitude: 78.26569,
    wikiDataId: "Q6963320",
  },
  {
    id: 133145,
    name: "Paithan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.37,
    longitude: 74.2,
    wikiDataId: "Q2087583",
  },
  {
    id: 133146,
    name: "Nandyal",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.47799,
    longitude: 78.4836,
    wikiDataId: "Q1772751",
  },
  {
    id: 133147,
    name: "Abu",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.38966,
    longitude: 76.37574,
    wikiDataId: "Q2311518",
  },
  {
    id: 133148,
    name: "Ponneri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.76189,
    longitude: 77.89093,
    wikiDataId: "Q2541538",
  },
  {
    id: 133149,
    name: "Poonamalle",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.25539,
    longitude: 79.47508,
    wikiDataId: "Q2541538",
  },
  {
    id: 133150,
    name: "Porur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.49326,
    longitude: 77.65806,
    wikiDataId: "Q2542321",
  },
  {
    id: 133151,
    name: "Pangala",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.11764,
    longitude: 76.68397,
    wikiDataId: "Q2542321",
  },
  {
    id: 133152,
    name: "Pudukkottai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.87933,
    longitude: 79.61062,
    wikiDataId: "Q632960",
  },
  {
    id: 133153,
    name: "Sheoganj",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.96059,
    longitude: 73.55913,
    wikiDataId: "Q632960",
  },
  {
    id: 133154,
    name: "Sikar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.79069,
    longitude: 75.20608,
    wikiDataId: "Q632960",
  },
  {
    id: 133155,
    name: "Panagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.19033,
    longitude: 80.475,
    wikiDataId: "Q2542976",
  },
  {
    id: 133156,
    name: "Narasannapeta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.41428,
    longitude: 84.04463,
    wikiDataId: "Q3429912",
  },
  {
    id: 133157,
    name: "Narasapur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.43425,
    longitude: 81.69845,
    wikiDataId: "Q379516",
  },
  {
    id: 133158,
    name: "Narasaraopet",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.23488,
    longitude: 80.04927,
    wikiDataId: "Q2483789",
  },
  {
    id: 133159,
    name: "Pavugada",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.61075,
    longitude: 75.512,
    wikiDataId: "Q2475130",
  },
  {
    id: 133160,
    name: "Narasingapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.60759,
    longitude: 79.31652,
    wikiDataId: "Q2475130",
  },
  {
    id: 133161,
    name: "Salempur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.48547,
    longitude: 78.71484,
    wikiDataId: "Q2542897",
  },
  {
    id: 133162,
    name: "Salon",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.20147,
    longitude: 78.38723,
    wikiDataId: "Q2441434",
  },
  {
    id: 133163,
    name: "Narayanpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.60426,
    longitude: 81.08119,
    wikiDataId: "Q2322000",
  },
  {
    id: 133164,
    name: "Piriyapatna",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.57316,
    longitude: 75.80805,
    wikiDataId: "Q2552530",
  },
  {
    id: 133165,
    name: "Narela",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.85267,
    longitude: 77.09288,
    wikiDataId: "Q6965751",
  },
  {
    id: 133166,
    name: "Garulia",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.16173,
    longitude: 78.28712,
    wikiDataId: "Q1109422",
  },
  {
    id: 133167,
    name: "Ponnampet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.72299,
    longitude: 75.38666,
    wikiDataId: "Q1109422",
  },
  {
    id: 133168,
    name: "Narharpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.44892,
    longitude: 81.62004,
    wikiDataId: "Q15261015",
  },
  {
    id: 133169,
    name: "Narmada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.87377,
    longitude: 73.49527,
    wikiDataId: "Q15261015",
  },
  {
    id: 133170,
    name: "Naroda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.07041,
    longitude: 72.65702,
    wikiDataId: "Q6966092",
  },
  {
    id: 133171,
    name: "Pandhana",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.91667,
    longitude: 79.16667,
    wikiDataId: "Q15261015",
  },
  {
    id: 133172,
    name: "Pandhurna",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.70758,
    longitude: 77.09319,
    wikiDataId: "Q584544",
  },
  {
    id: 133173,
    name: "Narsipatnam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.66709,
    longitude: 82.61245,
    wikiDataId: "Q2563464",
  },
  {
    id: 133174,
    name: "Panna",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.6439,
    longitude: 77.9129,
    wikiDataId: "Q2563464",
  },
  {
    id: 133175,
    name: "Palwal",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.59903,
    longitude: 76.11927,
    wikiDataId: "Q2452693",
  },
  {
    id: 133176,
    name: "Narwana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.47798,
    longitude: 77.12804,
    wikiDataId: "Q2734986",
  },
  {
    id: 133177,
    name: "Pandharpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.99727,
    longitude: 73.79096,
    wikiDataId: "Q200235",
  },
  {
    id: 133178,
    name: "Pandhurli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.98295,
    longitude: 73.78942,
    wikiDataId: "Q526400",
  },
  {
    id: 133179,
    name: "Pansemal",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.6837,
    longitude: 77.27069,
    wikiDataId: "Q2446380",
  },
  {
    id: 133180,
    name: "Sirohi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.30473,
    longitude: 74.73364,
    wikiDataId: "Q3870501",
  },
  {
    id: 133181,
    name: "Pudur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.22776,
    longitude: 78.22969,
    wikiDataId: "Q3870501",
  },
  {
    id: 133182,
    name: "Naugachhia",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.38807,
    longitude: 87.09906,
    wikiDataId: "Q2453298",
  },
  {
    id: 133183,
    name: "Sandila",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.42752,
    longitude: 83.41789,
    wikiDataId: "Q2725709",
  },
  {
    id: 133184,
    name: "Rabkavi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.55877,
    longitude: 75.35305,
    wikiDataId: "Q2475087",
  },
  {
    id: 133185,
    name: "Navelim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.53333,
    longitude: 73.98333,
    wikiDataId: "Q2475087",
  },
  {
    id: 133186,
    name: "Panvel",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.03681,
    longitude: 73.01582,
    wikiDataId: "Q61445",
  },
  {
    id: 133187,
    name: "Navsari",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.95,
    longitude: 72.92,
    wikiDataId: "Q1922248",
  },
  {
    id: 133189,
    name: "Sri Dungargarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.85161,
    longitude: 75.27384,
    wikiDataId: "Q2233964",
  },
  {
    id: 133190,
    name: "Abu Road",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.1245,
    longitude: 76.11613,
    wikiDataId: "Q2233964",
  },
  {
    id: 133191,
    name: "Sant Kabir Nagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.93129,
    longitude: 81.19841,
    wikiDataId: "Q1748351",
  },
  {
    id: 133192,
    name: "Nawada",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.75,
    longitude: 85.5,
    wikiDataId: "Q100067",
  },
  {
    id: 133193,
    name: "Pattan",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.76505,
    longitude: 74.52772,
    wikiDataId: "Q100067",
  },
  {
    id: 133194,
    name: "Chettipalaiyam",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.13082,
    longitude: 88.23972,
    wikiDataId: "Q100067",
  },
  {
    id: 133195,
    name: "Titlagarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.13,
    longitude: 85.1,
    wikiDataId: "Q100067",
  },
  {
    id: 133196,
    name: "Tarabha",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.12882,
    longitude: 85.09626,
    wikiDataId: "Q586859",
  },
  {
    id: 133197,
    name: "Pachmarhi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.78686,
    longitude: 81.77868,
    wikiDataId: "Q586859",
  },
  {
    id: 133198,
    name: "Pariyapuram",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.60267,
    longitude: 77.00139,
    wikiDataId: "Q932690",
  },
  {
    id: 133199,
    name: "Puliyangudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.77378,
    longitude: 79.41875,
    wikiDataId: "Q2540773",
  },
  {
    id: 133200,
    name: "Puliyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9495,
    longitude: 80.2592,
    wikiDataId: "Q12435937",
  },
  {
    id: 133201,
    name: "Sri Madhopur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.73976,
    longitude: 75.78652,
    wikiDataId: "Q12435937",
  },
  {
    id: 133202,
    name: "Parasia",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.47,
    longitude: 74.87,
    wikiDataId: "Q12435937",
  },
  {
    id: 133203,
    name: "Pullambadi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.76377,
    longitude: 79.84313,
    wikiDataId: "Q695585",
  },
  {
    id: 133204,
    name: "Raichur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.09978,
    longitude: 77.39364,
    wikiDataId: "Q2340194",
  },
  {
    id: 133205,
    name: "Punjai Puliyampatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.77554,
    longitude: 79.67016,
    wikiDataId: "Q2541207",
  },
  {
    id: 133206,
    name: "Nellore",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.08333,
    longitude: 79.58333,
    wikiDataId: "Q15383",
  },
  {
    id: 133207,
    name: "Patharia",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.4538,
    longitude: 76.39335,
    wikiDataId: "Q15383",
  },
  {
    id: 133208,
    name: "Parbhani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.02475,
    longitude: 73.31688,
    wikiDataId: "Q2247572",
  },
  {
    id: 133209,
    name: "patamda",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.47457,
    longitude: 84.2678,
    wikiDataId: "Q12436050",
  },
  {
    id: 133210,
    name: "New Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.63576,
    longitude: 77.22445,
    wikiDataId: "Q987",
  },
  {
    id: 133211,
    name: "Pathanamthitta",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.39854,
    longitude: 77.08586,
    wikiDataId: "Q2569280",
  },
  {
    id: 133212,
    name: "Sarai Mir",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.31247,
    longitude: 83.7253,
    wikiDataId: "Q691786",
  },
  {
    id: 133213,
    name: "Nicobar",
    state_id: 4023,
    state_code: "AN",
    state_name: "Andaman and Nicobar Islands",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 7.03002,
    longitude: 93.79028,
    wikiDataId: "Q797295",
  },
  {
    id: 133214,
    name: "Nidadavole",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.90572,
    longitude: 81.67222,
    wikiDataId: "Q2563407",
  },
  {
    id: 133215,
    name: "Sardhana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.32416,
    longitude: 78.38724,
    wikiDataId: "Q26780956",
  },
  {
    id: 133216,
    name: "Rajapalaiyam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.165,
    longitude: 77.85024,
    wikiDataId: "Q2541224",
  },
  {
    id: 133217,
    name: "Parola",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.11675,
    longitude: 76.75279,
    wikiDataId: "Q2546766",
  },
  {
    id: 133218,
    name: "Ramanathapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.46,
    longitude: 76.64,
    wikiDataId: "Q15188",
  },
  {
    id: 133219,
    name: "Karaikal",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.05756,
    longitude: 86.00436,
    wikiDataId: "Q15188",
  },
  {
    id: 133220,
    name: "Parseoni",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.399,
    longitude: 74.38285,
    wikiDataId: "Q2283213",
  },
  {
    id: 133221,
    name: "Amanpur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.09685,
    longitude: 78.34407,
    wikiDataId: "Q2087729",
  },
  {
    id: 133222,
    name: "Nirmali",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.31397,
    longitude: 86.58537,
    wikiDataId: "Q545770",
  },
  {
    id: 133223,
    name: "Pathardih",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78438,
    longitude: 86.70692,
    wikiDataId: "Q2451059",
  },
  {
    id: 133224,
    name: "Sunel",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.36073,
    longitude: 75.91836,
    wikiDataId: "Q2544247",
  },
  {
    id: 133225,
    name: "Sarila",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.87611,
    longitude: 77.5382,
    wikiDataId: "Q3877398",
  },
  {
    id: 133226,
    name: "Amauli",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.75,
    longitude: 78.25,
    wikiDataId: "Q15391",
  },
  {
    id: 133228,
    name: "Sarurpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.05295,
    longitude: 83.05787,
    wikiDataId: "Q2424539",
  },
  {
    id: 133229,
    name: "Surajgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.18292,
    longitude: 74.77064,
    wikiDataId: "Q2544415",
  },
  {
    id: 133230,
    name: "Sasni",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.58,
    longitude: 77.33,
    wikiDataId: "Q278204",
  },
  {
    id: 133231,
    name: "Suratgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.56155,
    longitude: 73.47141,
    wikiDataId: "Q1749714",
  },
  {
    id: 133232,
    name: "Serchhip",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.9023,
    longitude: 91.87694,
    wikiDataId: "Q2483960",
  },
  {
    id: 133233,
    name: "Thenzawl",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.51704,
    longitude: 91.26484,
    wikiDataId: "Q2341832",
  },
  {
    id: 133234,
    name: "North Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.6692,
    longitude: 77.22273,
    wikiDataId: "Q693367",
  },
  {
    id: 133235,
    name: "Cheyyar",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.66667,
    longitude: 88.5,
    wikiDataId: "Q693367",
  },
  {
    id: 133236,
    name: "North East Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.6869,
    longitude: 77.30195,
    wikiDataId: "Q429329",
  },
  {
    id: 133237,
    name: "Dimapur",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.89682,
    longitude: 90.61602,
    wikiDataId: "Q7055466",
  },
  {
    id: 133238,
    name: "North Goa",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.53397,
    longitude: 73.96408,
    wikiDataId: "Q108234",
  },
  {
    id: 133239,
    name: "North Guwahati",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.19749,
    longitude: 91.7202,
    wikiDataId: "Q2733384",
  },
  {
    id: 133240,
    name: "North Lakhimpur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.23517,
    longitude: 94.10357,
    wikiDataId: "Q2088889",
  },
  {
    id: 133241,
    name: "Ballia",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.1305,
    longitude: 92.15552,
    wikiDataId: "Q2088889",
  },
  {
    id: 133242,
    name: "Banapur",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.13227,
    longitude: 93.06532,
    wikiDataId: "Q2726315",
  },
  {
    id: 133243,
    name: "North West Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.70113,
    longitude: 77.10154,
    wikiDataId: "Q766125",
  },
  {
    id: 133244,
    name: "Pulwama",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.52095,
    longitude: 74.79845,
    wikiDataId: "Q585098",
  },
  {
    id: 133245,
    name: "Mahe",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.23114,
    longitude: 82.54826,
    wikiDataId: "Q2085319",
  },
  {
    id: 133246,
    name: "Purba Singhbhum",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.16094,
    longitude: 85.50416,
    wikiDataId: "Q2738436",
  },
  {
    id: 133247,
    name: "Puducherry",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.6,
    longitude: 82.5,
    wikiDataId: "Q2738436",
  },
  {
    id: 133248,
    name: "Numaligarh",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.62249,
    longitude: 93.72225,
    wikiDataId: "Q2738436",
  },
  {
    id: 133249,
    name: "Ramanagara",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.14869,
    longitude: 75.57641,
    wikiDataId: "Q2738436",
  },
  {
    id: 133250,
    name: "Zira",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.37577,
    longitude: 76.15292,
    wikiDataId: "Q1023691",
  },
  {
    id: 133251,
    name: "Nalagarh",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.78303,
    longitude: 76.3431,
    wikiDataId: "Q2452658",
  },
  {
    id: 133252,
    name: "Sangod",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.22288,
    longitude: 77.19569,
    wikiDataId: "Q2725552",
  },
  {
    id: 133253,
    name: "Pappinisshēri",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.68465,
    longitude: 75.65493,
    wikiDataId: "Q2725552",
  },
  {
    id: 133254,
    name: "Nyamti",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.81939,
    longitude: 76.75456,
    wikiDataId: "Q2475066",
  },
  {
    id: 133255,
    name: "Akbarpur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.4821,
    longitude: 78.32471,
    wikiDataId: "Q2475066",
  },
  {
    id: 133256,
    name: "Sarwar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.20201,
    longitude: 73.73394,
    wikiDataId: "Q2299130",
  },
  {
    id: 133257,
    name: "Perundurai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.17899,
    longitude: 77.43227,
    wikiDataId: "Q847606",
  },
  {
    id: 133258,
    name: "Nagireddipalli",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.27005,
    longitude: 79.10131,
    wikiDataId: "Q847606",
  },
  {
    id: 133259,
    name: "Orchha",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.56924,
    longitude: 80.58809,
    wikiDataId: "Q2007857",
  },
  {
    id: 133260,
    name: "Nerur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.54225,
    longitude: 73.13493,
    wikiDataId: "Q592673",
  },
  {
    id: 133261,
    name: "Paonta Sahib",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.56029,
    longitude: 77.29426,
    wikiDataId: "Q781615",
  },
  {
    id: 133262,
    name: "Nalanda",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.25,
    longitude: 85.58333,
    wikiDataId: "Q77633",
  },
  {
    id: 133263,
    name: "Parwanoo",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.04168,
    longitude: 76.72285,
    wikiDataId: "Q1815875",
  },
  {
    id: 133264,
    name: "Perungudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.45513,
    longitude: 78.26818,
    wikiDataId: "Q2554940",
  },
  {
    id: 133266,
    name: "Namrup",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.19395,
    longitude: 95.31929,
    wikiDataId: "Q938816",
  },
  {
    id: 133267,
    name: "Ozar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.3068,
    longitude: 74.65501,
    wikiDataId: "Q1931382",
  },
  {
    id: 133268,
    name: "Pahur, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83417,
    longitude: 76.45924,
    wikiDataId: "Q546955",
  },
  {
    id: 133269,
    name: "Nangloi Jat",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.67957,
    longitude: 77.06799,
    wikiDataId: "Q375592",
  },
  {
    id: 133270,
    name: "Sakit",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.86459,
    longitude: 81.50036,
    wikiDataId: "Q2307991",
  },
  {
    id: 133271,
    name: "Puduppatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.19133,
    longitude: 80.18473,
    wikiDataId: "Q2554960",
  },
  {
    id: 133272,
    name: "Nilokheri",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.04444,
    longitude: 76.10833,
    wikiDataId: "Q2341892",
  },
  {
    id: 133273,
    name: "Nuh",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.22047,
    longitude: 76.14278,
    wikiDataId: "Q2452748",
  },
  {
    id: 133274,
    name: "Allahabad",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.04468,
    longitude: 78.42516,
    wikiDataId: "Q2228677",
  },
  {
    id: 133275,
    name: "Narayanavanam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.42565,
    longitude: 79.58881,
    wikiDataId: "Q2349929",
  },
  {
    id: 133276,
    name: "Panara",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.27083,
    longitude: 75.05007,
    wikiDataId: "Q1925454",
  },
  {
    id: 133277,
    name: "Aligarh",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.74799,
    longitude: 77.4954,
    wikiDataId: "Q1925454",
  },
  {
    id: 133278,
    name: "Allahganj",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.84577,
    longitude: 79.46165,
    wikiDataId: "Q2563445",
  },
  {
    id: 133279,
    name: "Nasriganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.0514,
    longitude: 84.32839,
    wikiDataId: "Q6967322",
  },
  {
    id: 133280,
    name: "Siwana",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.93805,
    longitude: 73.82392,
    wikiDataId: "Q2281634",
  },
  {
    id: 133281,
    name: "Puduvayal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.86905,
    longitude: 78.55305,
    wikiDataId: "Q2541245",
  },
  {
    id: 133282,
    name: "Sojat",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.0195,
    longitude: 75.00226,
    wikiDataId: "Q3873467",
  },
  {
    id: 133283,
    name: "Nayudupet",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.90742,
    longitude: 79.89465,
    wikiDataId: "Q3873467",
  },
  {
    id: 133284,
    name: "Nazira",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.91649,
    longitude: 94.73611,
    wikiDataId: "Q2733289",
  },
  {
    id: 133285,
    name: "Udayagiri",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.46235,
    longitude: 86.76794,
    wikiDataId: "Q2563320",
  },
  {
    id: 133286,
    name: "Panchkula",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.83671,
    longitude: 76.93191,
    wikiDataId: "Q2558245",
  },
  {
    id: 133287,
    name: "Pattanamtitta",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.25953,
    longitude: 75.1352,
    wikiDataId: "Q2558245",
  },
  {
    id: 133288,
    name: "Suket",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.62166,
    longitude: 74.67999,
    wikiDataId: "Q2441995",
  },
  {
    id: 133289,
    name: "Sujangarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.14995,
    longitude: 74.00094,
    wikiDataId: "Q7037551",
  },
  {
    id: 133290,
    name: "Panipat",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.10296,
    longitude: 77.00144,
    wikiDataId: "Q629396",
  },
  {
    id: 133291,
    name: "Ajmer",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.09662,
    longitude: 75.59386,
    wikiDataId: "Q10938567",
  },
  {
    id: 133292,
    name: "Satrikh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.14956,
    longitude: 78.4084,
    wikiDataId: "Q10938567",
  },
  {
    id: 133293,
    name: "Aklera",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.16667,
    longitude: 76.48333,
    wikiDataId: "Q10938567",
  },
  {
    id: 133294,
    name: "Nuzvid",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.78854,
    longitude: 80.84593,
    wikiDataId: "Q2734090",
  },
  {
    id: 133295,
    name: "Saurikh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.41863,
    longitude: 82.98797,
    wikiDataId: "Q1008248",
  },
  {
    id: 133296,
    name: "Rameswaram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.76793,
    longitude: 78.88304,
    wikiDataId: "Q2716972",
  },
  {
    id: 133297,
    name: "Okha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.46756,
    longitude: 69.07002,
    wikiDataId: "Q2726034",
  },
  {
    id: 133298,
    name: "Olpad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.33649,
    longitude: 72.75161,
    wikiDataId: "Q2726258",
  },
  {
    id: 133299,
    name: "Rasipuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.74099,
    longitude: 78.04559,
    wikiDataId: "Q2726258",
  },
  {
    id: 133300,
    name: "Ongole",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.50357,
    longitude: 80.04454,
    wikiDataId: "Q1920249",
  },
  {
    id: 133301,
    name: "Saint Thomas Mount",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.4134,
    longitude: 76.69521,
    wikiDataId: "Q9888",
  },
  {
    id: 133302,
    name: "Sector",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.99023,
    longitude: 79.45334,
    wikiDataId: "Q640646",
  },
  {
    id: 133303,
    name: "Seohara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.36882,
    longitude: 80.7423,
    wikiDataId: "Q1748417",
  },
  {
    id: 133304,
    name: "Pawai",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.35192,
    longitude: 78.64033,
    wikiDataId: "Q201631",
  },
  {
    id: 133305,
    name: "Pathri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.25,
    longitude: 76.16667,
    wikiDataId: "Q2462135",
  },
  {
    id: 133306,
    name: "Payyannur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.7735,
    longitude: 76.37758,
    wikiDataId: "Q2568304",
  },
  {
    id: 133307,
    name: "Patur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.09473,
    longitude: 73.92816,
    wikiDataId: "Q2719433",
  },
  {
    id: 133308,
    name: "Petlawad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.46791,
    longitude: 78.43312,
    wikiDataId: "Q1949041",
  },
  {
    id: 133309,
    name: "Shahganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.51234,
    longitude: 82.64297,
    wikiDataId: "Q2718275",
  },
  {
    id: 133310,
    name: "Punch",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.46659,
    longitude: 76.88488,
    wikiDataId: "Q1297208",
  },
  {
    id: 133311,
    name: "Takhatgarh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.70885,
    longitude: 73.62539,
    wikiDataId: "Q1297208",
  },
  {
    id: 133312,
    name: "Abohar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.99932,
    longitude: 83.06325,
    wikiDataId: "Q1297208",
  },
  {
    id: 133313,
    name: "Paddhari",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.43654,
    longitude: 70.60162,
    wikiDataId: "Q2575916",
  },
  {
    id: 133314,
    name: "Salem",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.24462,
    longitude: 77.32581,
    wikiDataId: "Q2575916",
  },
  {
    id: 133315,
    name: "Padra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.2398,
    longitude: 73.08451,
    wikiDataId: "Q2247454",
  },
  {
    id: 133316,
    name: "Shahi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.90403,
    longitude: 83.98087,
    wikiDataId: "Q2341393",
  },
  {
    id: 133317,
    name: "Qazigund",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.01592,
    longitude: 75.31899,
    wikiDataId: "Q2085326",
  },
  {
    id: 133318,
    name: "Shahjahanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.1722,
    longitude: 78.06376,
    wikiDataId: "Q2718317",
  },
  {
    id: 133319,
    name: "Pawni",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.47506,
    longitude: 75.38558,
    wikiDataId: "Q2445534",
  },
  {
    id: 133320,
    name: "Ranchi",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.63925,
    longitude: 87.84239,
    wikiDataId: "Q2575907",
  },
  {
    id: 133321,
    name: "Perumbavoor",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.74356,
    longitude: 76.6294,
    wikiDataId: "Q7126341",
  },
  {
    id: 133322,
    name: "Perumpavur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.775,
    longitude: 76.651,
    wikiDataId: "Q1535742",
  },
  {
    id: 133323,
    name: "Sattur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.45034,
    longitude: 77.5209,
    wikiDataId: "Q1535742",
  },
  {
    id: 133324,
    name: "Sayalkudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9535,
    longitude: 80.2572,
    wikiDataId: "Q2718134",
  },
  {
    id: 133325,
    name: "Piploda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.02013,
    longitude: 79.22818,
    wikiDataId: "Q2548639",
  },
  {
    id: 133326,
    name: "Shankargarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.43205,
    longitude: 80.58137,
    wikiDataId: "Q2718673",
  },
  {
    id: 133327,
    name: "Seven Pagodas",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.99175,
    longitude: 77.28633,
    wikiDataId: "Q278755",
  },
  {
    id: 133328,
    name: "Sholinghur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.72057,
    longitude: 77.87951,
    wikiDataId: "Q1320784",
  },
  {
    id: 133329,
    name: "Singanallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.14609,
    longitude: 78.80309,
    wikiDataId: "Q1320784",
  },
  {
    id: 133330,
    name: "Palle",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.46667,
    longitude: 74.08333,
    wikiDataId: "Q1320784",
  },
  {
    id: 133331,
    name: "Pallevada",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.5779,
    longitude: 81.29463,
    wikiDataId: "Q7127847",
  },
  {
    id: 133332,
    name: "Sirkazhi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.90518,
    longitude: 78.9427,
    wikiDataId: "Q282347",
  },
  {
    id: 133333,
    name: "Sirumugai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.3386,
    longitude: 79.44489,
    wikiDataId: "Q282347",
  },
  {
    id: 133334,
    name: "Sivaganga",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.9399,
    longitude: 78.40161,
    wikiDataId: "Q282347",
  },
  {
    id: 133335,
    name: "Singapperumalkovil",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.96796,
    longitude: 80.15025,
    wikiDataId: "Q1752584",
  },
  {
    id: 133336,
    name: "Palmaner",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.2,
    longitude: 78.74725,
    wikiDataId: "Q1752584",
  },
  {
    id: 133337,
    name: "Pataudi",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.14469,
    longitude: 77.32546,
    wikiDataId: "Q2086217",
  },
  {
    id: 133338,
    name: "Ambedkar Nagar",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.58152,
    longitude: 80.67651,
    wikiDataId: "Q772160",
  },
  {
    id: 133339,
    name: "Ray",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.91667,
    longitude: 84.08333,
    wikiDataId: "Q1797254",
  },
  {
    id: 133340,
    name: "Palasa",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.77257,
    longitude: 84.41012,
    wikiDataId: "Q1797254",
  },
  {
    id: 133341,
    name: "Palasbari",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.12388,
    longitude: 91.53974,
    wikiDataId: "Q2228584",
  },
  {
    id: 133342,
    name: "Panaji",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.49574,
    longitude: 73.82624,
    wikiDataId: "Q214681",
  },
  {
    id: 133343,
    name: "Piravam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.74014,
    longitude: 76.07369,
    wikiDataId: "Q214681",
  },
  {
    id: 133344,
    name: "Punasa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.20568,
    longitude: 78.55093,
    wikiDataId: "Q1768640",
  },
  {
    id: 133345,
    name: "Pehowa",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.72883,
    longitude: 76.94716,
    wikiDataId: "Q1898143",
  },
  {
    id: 133346,
    name: "Pandaria",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.22495,
    longitude: 81.40994,
    wikiDataId: "Q520157",
  },
  {
    id: 133347,
    name: "Phulambri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.67924,
    longitude: 75.33098,
    wikiDataId: "Q1779418",
  },
  {
    id: 133348,
    name: "Raghogarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.69816,
    longitude: 76.22487,
    wikiDataId: "Q2720747",
  },
  {
    id: 133349,
    name: "Pimpalgaon Baswant",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.8121,
    longitude: 74.11007,
    wikiDataId: "Q2719424",
  },
  {
    id: 133350,
    name: "Pinjaur",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.33259,
    longitude: 76.92634,
    wikiDataId: "Q2086163",
  },
  {
    id: 133351,
    name: "Raisen",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.5,
    longitude: 80.25,
    wikiDataId: "Q2341630",
  },
  {
    id: 133352,
    name: "Sivagiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.77662,
    longitude: 79.55269,
    wikiDataId: "Q2229494",
  },
  {
    id: 133353,
    name: "Pimpalgaon Raja",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98878,
    longitude: 73.11013,
    wikiDataId: "Q297511",
  },
  {
    id: 133354,
    name: "Porsa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.28539,
    longitude: 79.99509,
    wikiDataId: "Q2548319",
  },
  {
    id: 133355,
    name: "Sivakasi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.92687,
    longitude: 79.27056,
    wikiDataId: "Q2717803",
  },
  {
    id: 133356,
    name: "Srivaikuntam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.54633,
    longitude: 78.5907,
    wikiDataId: "Q2717803",
  },
  {
    id: 133357,
    name: "Ponnani",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.7947,
    longitude: 76.66798,
    wikiDataId: "Q1939468",
  },
  {
    id: 133358,
    name: "Pimpri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.5,
    longitude: 76.75,
    wikiDataId: "Q1920284",
  },
  {
    id: 133359,
    name: "Punalur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.01667,
    longitude: 75.86667,
    wikiDataId: "Q1797389",
  },
  {
    id: 133360,
    name: "Pipri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.85057,
    longitude: 76.53163,
    wikiDataId: "Q1797389",
  },
  {
    id: 133361,
    name: "Parlakimidi",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.78113,
    longitude: 84.08836,
    wikiDataId: "Q1797389",
  },
  {
    id: 133362,
    name: "Parnera",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.56101,
    longitude: 72.94846,
    wikiDataId: "Q2735009",
  },
  {
    id: 133363,
    name: "Rajaori",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.34598,
    longitude: 75.43441,
    wikiDataId: "Q3896351",
  },
  {
    id: 133364,
    name: "Prabhadevi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.88098,
    longitude: 75.11937,
    wikiDataId: "Q3786783",
  },
  {
    id: 133365,
    name: "Shikarpur (Bulandshahr)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.07354,
    longitude: 81.49207,
    wikiDataId: "Q3786783",
  },
  {
    id: 133366,
    name: "Tijara",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.59276,
    longitude: 74.17396,
    wikiDataId: "Q2228748",
  },
  {
    id: 133367,
    name: "Pulgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.59925,
    longitude: 76.21541,
    wikiDataId: "Q2719414",
  },
  {
    id: 133368,
    name: "Todabhim",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.88604,
    longitude: 74.76602,
    wikiDataId: "Q2718760",
  },
  {
    id: 133369,
    name: "Sabathu",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.83716,
    longitude: 76.96143,
    wikiDataId: "Q2558662",
  },
  {
    id: 133370,
    name: "Adampur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.31641,
    longitude: 86.6085,
    wikiDataId: "Q2726083",
  },
  {
    id: 133371,
    name: "Rajpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.1913,
    longitude: 78.75904,
    wikiDataId: "Q1390016",
  },
  {
    id: 133372,
    name: "Shibnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.97841,
    longitude: 77.93422,
    wikiDataId: "Q1390016",
  },
  {
    id: 133373,
    name: "Pashchim Champaran",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27,
    longitude: 84.5,
    wikiDataId: "Q1390016",
  },
  {
    id: 133374,
    name: "Sahibganj",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.5,
    longitude: 85.5,
    wikiDataId: "Q1390016",
  },
  {
    id: 133375,
    name: "Pasan",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.84412,
    longitude: 82.19823,
    wikiDataId: "Q2720089",
  },
  {
    id: 133376,
    name: "Pavi Jetpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.7,
    longitude: 71.8,
    wikiDataId: "Q579702",
  },
  {
    id: 133377,
    name: "Amethi",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.53334,
    longitude: 78.2645,
    wikiDataId: "Q720824",
  },
  {
    id: 133378,
    name: "Punahana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.32547,
    longitude: 76.77858,
    wikiDataId: "Q1025646",
  },
  {
    id: 133379,
    name: "Pathalgaon",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.55656,
    longitude: 83.46355,
    wikiDataId: "Q2558945",
  },
  {
    id: 133380,
    name: "Ramamangalam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.26667,
    longitude: 76.78333,
    wikiDataId: "Q1025266",
  },
  {
    id: 133381,
    name: "Ranapur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.89921,
    longitude: 79.19393,
    wikiDataId: "Q2443517",
  },
  {
    id: 133382,
    name: "Alwar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.27484,
    longitude: 75.65287,
    wikiDataId: "Q1647167",
  },
  {
    id: 133384,
    name: "Amet",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.33625,
    longitude: 76.3922,
    wikiDataId: "Q208162",
  },
  {
    id: 133385,
    name: "Shishgarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.69086,
    longitude: 78.99823,
    wikiDataId: "Q208162",
  },
  {
    id: 133386,
    name: "Patna",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.41667,
    longitude: 85.16667,
    wikiDataId: "Q100077",
  },
  {
    id: 133387,
    name: "Ajnala",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.70833,
    longitude: 83.13263,
    wikiDataId: "Q2038554",
  },
  {
    id: 133388,
    name: "Rajauri",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.16125,
    longitude: 74.55634,
    wikiDataId: "Q2228603",
  },
  {
    id: 133389,
    name: "Shertallai",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.28068,
    longitude: 76.86967,
    wikiDataId: "Q634935",
  },
  {
    id: 133390,
    name: "Anta",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.28092,
    longitude: 74.85849,
    wikiDataId: "Q942642",
  },
  {
    id: 133391,
    name: "Shrawasti",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.9215,
    longitude: 82.20048,
    wikiDataId: "Q3873181",
  },
  {
    id: 133392,
    name: "Srivilliputhur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.42358,
    longitude: 79.31949,
    wikiDataId: "Q2716570",
  },
  {
    id: 133393,
    name: "Ajitgarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.57806,
    longitude: 86.56063,
    wikiDataId: "Q2716570",
  },
  {
    id: 133394,
    name: "Ghatal",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.15286,
    longitude: 78.7771,
    wikiDataId: "Q2249295",
  },
  {
    id: 133395,
    name: "Pavuluru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.85292,
    longitude: 80.16468,
    wikiDataId: "Q2249295",
  },
  {
    id: 133396,
    name: "Ratangarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.26635,
    longitude: 80.16196,
    wikiDataId: "Q2720731",
  },
  {
    id: 133397,
    name: "Rahata",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.79229,
    longitude: 79.63644,
    wikiDataId: "Q2719440",
  },
  {
    id: 133398,
    name: "Siddharthnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.06626,
    longitude: 80.10305,
    wikiDataId: "Q11940631",
  },
  {
    id: 133399,
    name: "Shōranur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.0935,
    longitude: 75.20249,
    wikiDataId: "Q539909",
  },
  {
    id: 133400,
    name: "Pedana",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.25582,
    longitude: 81.14378,
    wikiDataId: "Q2563472",
  },
  {
    id: 133401,
    name: "Amroha",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.61357,
    longitude: 79.37442,
    wikiDataId: "Q7159047",
  },
  {
    id: 133402,
    name: "Peddapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.07701,
    longitude: 82.13836,
    wikiDataId: "Q7159047",
  },
  {
    id: 133403,
    name: "Pundri",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.97897,
    longitude: 76.58249,
    wikiDataId: "Q2451134",
  },
  {
    id: 133404,
    name: "Raireshwar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.73734,
    longitude: 73.09603,
    wikiDataId: "Q1382194",
  },
  {
    id: 133405,
    name: "Pendra",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.77548,
    longitude: 81.95968,
    wikiDataId: "Q2558897",
  },
  {
    id: 133406,
    name: "Suchindram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.40389,
    longitude: 79.24156,
    wikiDataId: "Q2716691",
  },
  {
    id: 133407,
    name: "Sulur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.13433,
    longitude: 77.89525,
    wikiDataId: "Q2716664",
  },
  {
    id: 133408,
    name: "Surandai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.24681,
    longitude: 79.22592,
    wikiDataId: "Q2716197",
  },
  {
    id: 133409,
    name: "Penugonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.65363,
    longitude: 81.7455,
    wikiDataId: "Q2716197",
  },
  {
    id: 133410,
    name: "Penukonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.08286,
    longitude: 77.59473,
    wikiDataId: "Q2572975",
  },
  {
    id: 133411,
    name: "Swamimalai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.73579,
    longitude: 77.78955,
    wikiDataId: "Q2716592",
  },
  {
    id: 133412,
    name: "Tambaram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.272,
    longitude: 78.8738,
    wikiDataId: "Q15186",
  },
  {
    id: 133413,
    name: "Tanjore",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.57052,
    longitude: 79.43332,
    wikiDataId: "Q638124",
  },
  {
    id: 133414,
    name: "Taramangalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9343,
    longitude: 78.7189,
    wikiDataId: "Q638124",
  },
  {
    id: 133415,
    name: "Tattayyangarpettai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.29035,
    longitude: 79.20156,
    wikiDataId: "Q2716752",
  },
  {
    id: 133416,
    name: "Belaguntha",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.51276,
    longitude: 93.73716,
    wikiDataId: "Q2716752",
  },
  {
    id: 133417,
    name: "Thanjavur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.12268,
    longitude: 77.54372,
    wikiDataId: "Q2543104",
  },
  {
    id: 133418,
    name: "Tharangambadi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.15255,
    longitude: 76.95159,
    wikiDataId: "Q2543104",
  },
  {
    id: 133419,
    name: "Theni",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.74317,
    longitude: 77.10296,
    wikiDataId: "Q2543104",
  },
  {
    id: 133420,
    name: "Thenkasi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.75812,
    longitude: 77.27087,
    wikiDataId: "Q2716679",
  },
  {
    id: 133421,
    name: "Thirukattupalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.27263,
    longitude: 78.90232,
    wikiDataId: "Q7168972",
  },
  {
    id: 133422,
    name: "Pernem",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.723,
    longitude: 73.79511,
    wikiDataId: "Q2736166",
  },
  {
    id: 133423,
    name: "Talipparamba",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.10695,
    longitude: 76.47366,
    wikiDataId: "Q2737591",
  },
  {
    id: 133424,
    name: "Tellicherry",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.11544,
    longitude: 76.47611,
    wikiDataId: "Q2737591",
  },
  {
    id: 133425,
    name: "Thiruthani",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.27564,
    longitude: 77.58794,
    wikiDataId: "Q329934",
  },
  {
    id: 133426,
    name: "Thiruvaiyaru",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.96095,
    longitude: 80.24094,
    wikiDataId: "Q2072728",
  },
  {
    id: 133427,
    name: "Thanniyam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.83334,
    longitude: 75.85408,
    wikiDataId: "Q2072728",
  },
  {
    id: 133428,
    name: "Thiruvallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.97519,
    longitude: 76.91292,
    wikiDataId: "Q2716122",
  },
  {
    id: 133429,
    name: "Pipavav",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.47681,
    longitude: 72.79995,
    wikiDataId: "Q2734226",
  },
  {
    id: 133430,
    name: "Ratlam",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.01102,
    longitude: 74.79772,
    wikiDataId: "Q2720761",
  },
  {
    id: 133431,
    name: "Anupgarh",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.22452,
    longitude: 75.77387,
    wikiDataId: "Q338696",
  },
  {
    id: 133432,
    name: "Sidhauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.18824,
    longitude: 77.82996,
    wikiDataId: "Q2718698",
  },
  {
    id: 133433,
    name: "Todaraisingh",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.13102,
    longitude: 72.36826,
    wikiDataId: "Q2718721",
  },
  {
    id: 133434,
    name: "Rajapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.99113,
    longitude: 74.43177,
    wikiDataId: "Q2341367",
  },
  {
    id: 133435,
    name: "Sikandarabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.59888,
    longitude: 79.46437,
    wikiDataId: "Q2718260",
  },
  {
    id: 133436,
    name: "Sikandarpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.32166,
    longitude: 78.47267,
    wikiDataId: "Q26780840",
  },
  {
    id: 133437,
    name: "Aizawl",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.66667,
    longitude: 94.5,
    wikiDataId: "Q2726376",
  },
  {
    id: 133438,
    name: "Bhadrak",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 94.5,
    wikiDataId: "Q590882",
  },
  {
    id: 133439,
    name: "Asind",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.01887,
    longitude: 75.79111,
    wikiDataId: "Q2719352",
  },
  {
    id: 133440,
    name: "Phirangipuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.29078,
    longitude: 80.26233,
    wikiDataId: "Q3430158",
  },
  {
    id: 133441,
    name: "Akalgarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.48101,
    longitude: 84.23063,
    wikiDataId: "Q2296037",
  },
  {
    id: 133442,
    name: "Tonk",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.87401,
    longitude: 75.24171,
    wikiDataId: "Q2718672",
  },
  {
    id: 133443,
    name: "Sikandra Rao",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.54895,
    longitude: 82.0895,
    wikiDataId: "Q2718672",
  },
  {
    id: 133445,
    name: "Sirathu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.61987,
    longitude: 80.20343,
    wikiDataId: "Q2718692",
  },
  {
    id: 133446,
    name: "Udpura",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.44964,
    longitude: 74.10093,
    wikiDataId: "Q2718833",
  },
  {
    id: 133447,
    name: "Sisauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.71271,
    longitude: 77.656,
    wikiDataId: "Q2718583",
  },
  {
    id: 133448,
    name: "Udaipur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.36725,
    longitude: 75.60352,
    wikiDataId: "Q1936947",
  },
  {
    id: 133449,
    name: "Ramewadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.62292,
    longitude: 73.80696,
    wikiDataId: "Q11941642",
  },
  {
    id: 133450,
    name: "Uniara",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.79749,
    longitude: 73.05505,
    wikiDataId: "Q2718702",
  },
  {
    id: 133451,
    name: "Radaur",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.79873,
    longitude: 76.91822,
    wikiDataId: "Q2718702",
  },
  {
    id: 133452,
    name: "Sitapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.88487,
    longitude: 78.37647,
    wikiDataId: "Q2718289",
  },
  {
    id: 133453,
    name: "Alawalpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.11357,
    longitude: 85.83147,
    wikiDataId: "Q2471613",
  },
  {
    id: 133454,
    name: "Rehli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.35,
    longitude: 75.43333,
    wikiDataId: "Q2720099",
  },
  {
    id: 133455,
    name: "Pippara",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.71667,
    longitude: 81.55,
    wikiDataId: "Q2720099",
  },
  {
    id: 133456,
    name: "Sonbhadra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.82745,
    longitude: 83.52632,
    wikiDataId: "Q2718126",
  },
  {
    id: 133457,
    name: "Thiruvananthapuram",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.86667,
    longitude: 76.5,
    wikiDataId: "Q2718126",
  },
  {
    id: 133458,
    name: "Robertsonpet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.33497,
    longitude: 76.10073,
    wikiDataId: "Q2718126",
  },
  {
    id: 133459,
    name: "Piro",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.33218,
    longitude: 84.40454,
    wikiDataId: "Q2228653",
  },
  {
    id: 133460,
    name: "East District",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.15506,
    longitude: 76.02728,
    wikiDataId: "Q2228786",
  },
  {
    id: 133461,
    name: "Pitampura",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.68964,
    longitude: 77.13126,
    wikiDataId: "Q2228786",
  },
  {
    id: 133462,
    name: "Rehti",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.60197,
    longitude: 75.69649,
    wikiDataId: "Q2548346",
  },
  {
    id: 133463,
    name: "Pithora",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25021,
    longitude: 82.51707,
    wikiDataId: "Q745401",
  },
  {
    id: 133464,
    name: "Gobardanga",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30,
    longitude: 80.25,
    wikiDataId: "Q745401",
  },
  {
    id: 133465,
    name: "Pithapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.1168,
    longitude: 82.25284,
    wikiDataId: "Q2563530",
  },
  {
    id: 133466,
    name: "Gangtok",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.92007,
    longitude: 71.91631,
    wikiDataId: "Q2563530",
  },
  {
    id: 133467,
    name: "Amloh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.69386,
    longitude: 84.81401,
    wikiDataId: "Q954271",
  },
  {
    id: 133468,
    name: "Polavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.24754,
    longitude: 81.64372,
    wikiDataId: "Q3420642",
  },
  {
    id: 133469,
    name: "Thiruvarur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.65825,
    longitude: 77.0085,
    wikiDataId: "Q298780",
  },
  {
    id: 133470,
    name: "Thiruvidaimaruthur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.51217,
    longitude: 79.12405,
    wikiDataId: "Q745163",
  },
  {
    id: 133471,
    name: "Ponda",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.40341,
    longitude: 74.01519,
    wikiDataId: "Q2287007",
  },
  {
    id: 133472,
    name: "Thrissur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.00798,
    longitude: 76.52023,
    wikiDataId: "Q2287007",
  },
  {
    id: 133473,
    name: "Thoothukudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.28032,
    longitude: 78.53601,
    wikiDataId: "Q2287007",
  },
  {
    id: 133474,
    name: "Ron",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.14473,
    longitude: 75.94514,
    wikiDataId: "Q7227904",
  },
  {
    id: 133475,
    name: "Tindivanam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.33868,
    longitude: 80.19487,
    wikiDataId: "Q2715009",
  },
  {
    id: 133476,
    name: "Ponnur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.07114,
    longitude: 80.54944,
    wikiDataId: "Q720891",
  },
  {
    id: 133477,
    name: "Thrissur District",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.76695,
    longitude: 75.92523,
    wikiDataId: "Q2568315",
  },
  {
    id: 133478,
    name: "Ponnuru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.06547,
    longitude: 80.55203,
    wikiDataId: "Q2568315",
  },
  {
    id: 133479,
    name: "Tinnanur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.04888,
    longitude: 80.11488,
    wikiDataId: "Q2229614",
  },
  {
    id: 133480,
    name: "Prabhas Patan",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.64219,
    longitude: 69.60929,
    wikiDataId: "Q33473477",
  },
  {
    id: 133481,
    name: "Rewa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.67444,
    longitude: 78.37081,
    wikiDataId: "Q2548362",
  },
  {
    id: 133482,
    name: "Port Blair",
    state_id: 4023,
    state_code: "AN",
    state_name: "Andaman and Nicobar Islands",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.66613,
    longitude: 92.74635,
    wikiDataId: "Q203476",
  },
  {
    id: 133483,
    name: "Tiruchchendur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.03565,
    longitude: 80.15821,
    wikiDataId: "Q2229484",
  },
  {
    id: 133484,
    name: "Raver",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.1164,
    longitude: 72.90471,
    wikiDataId: "Q13118508",
  },
  {
    id: 133485,
    name: "Prakasam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.5,
    longitude: 79.5,
    wikiDataId: "Q15390",
  },
  {
    id: 133486,
    name: "Gyalshing",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.03,
    longitude: 74.78,
    wikiDataId: "Q1585433",
  },
  {
    id: 133488,
    name: "Suar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 81.75,
    wikiDataId: "Q1636939",
  },
  {
    id: 133489,
    name: "Proddatur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.7502,
    longitude: 78.54813,
    wikiDataId: "Q2349966",
  },
  {
    id: 133490,
    name: "Dasuya",
    state_id: 4011,
    state_code: "PY",
    state_name: "Puducherry",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.93381,
    longitude: 79.82979,
    wikiDataId: "Q639421",
  },
  {
    id: 133491,
    name: "Tiruchengode",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.35,
    longitude: 78.9,
    wikiDataId: "Q15190",
  },
  {
    id: 133492,
    name: "Tiruchirappalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.15217,
    longitude: 78.21205,
    wikiDataId: "Q15190",
  },
  {
    id: 133493,
    name: "Tirumullaivasal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.33015,
    longitude: 80.14577,
    wikiDataId: "Q15190",
  },
  {
    id: 133494,
    name: "Tirukkoyilur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.99801,
    longitude: 79.14352,
    wikiDataId: "Q15190",
  },
  {
    id: 133495,
    name: "Tajpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.22375,
    longitude: 79.83739,
    wikiDataId: "Q2718080",
  },
  {
    id: 133496,
    name: "Revadanda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.72204,
    longitude: 78.32056,
    wikiDataId: "Q2719107",
  },
  {
    id: 133497,
    name: "Pulivendla",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.42139,
    longitude: 78.22502,
    wikiDataId: "Q2719107",
  },
  {
    id: 133498,
    name: "Tirunelveli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.17489,
    longitude: 77.39799,
    wikiDataId: "Q2230511",
  },
  {
    id: 133499,
    name: "Tirunelveli Kattabo",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.63375,
    longitude: 78.84139,
    wikiDataId: "Q2716506",
  },
  {
    id: 133500,
    name: "Tiruppalaikudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.9411,
    longitude: 78.91041,
    wikiDataId: "Q2716576",
  },
  {
    id: 133501,
    name: "Ramban",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.87405,
    longitude: 74.89955,
    wikiDataId: "Q2351777",
  },
  {
    id: 133502,
    name: "Tirur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.01956,
    longitude: 76.92261,
    wikiDataId: "Q742720",
  },
  {
    id: 133503,
    name: "Ramgarh",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.70178,
    longitude: 74.19916,
    wikiDataId: "Q2983134",
  },
  {
    id: 133504,
    name: "Revdanda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.51957,
    longitude: 73.85535,
    wikiDataId: "Q1538",
  },
  {
    id: 133505,
    name: "Risod",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.74673,
    longitude: 73.75465,
    wikiDataId: "Q1854679",
  },
  {
    id: 133506,
    name: "Punganuru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.36672,
    longitude: 78.57186,
    wikiDataId: "Q1854679",
  },
  {
    id: 133507,
    name: "Tirupparangunram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.35163,
    longitude: 77.16671,
    wikiDataId: "Q1854679",
  },
  {
    id: 133508,
    name: "Sabalgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23507,
    longitude: 76.39335,
    wikiDataId: "Q1854679",
  },
  {
    id: 133509,
    name: "Pupri",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.47079,
    longitude: 85.70311,
    wikiDataId: "Q2453330",
  },
  {
    id: 133510,
    name: "Sijua",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.59238,
    longitude: 86.48341,
    wikiDataId: "Q2453330",
  },
  {
    id: 133511,
    name: "Amritsar",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.9,
    longitude: 85.6,
    wikiDataId: "Q1817158",
  },
  {
    id: 133512,
    name: "Purnia",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.81614,
    longitude: 87.40708,
    wikiDataId: "Q100082",
  },
  {
    id: 133513,
    name: "Anandpur Sahib",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.52024,
    longitude: 84.88514,
    wikiDataId: "Q100082",
  },
  {
    id: 133514,
    name: "Tanda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.45756,
    longitude: 80.77403,
    wikiDataId: "Q2718104",
  },
  {
    id: 133515,
    name: "Sakri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.91274,
    longitude: 77.57838,
    wikiDataId: "Q2724580",
  },
  {
    id: 133516,
    name: "Jorethang",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.49022,
    longitude: 74.55211,
    wikiDataId: "Q749170",
  },
  {
    id: 133517,
    name: "Puttaparthi",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.1652,
    longitude: 77.8117,
    wikiDataId: "Q1068595",
  },
  {
    id: 133518,
    name: "Sadalgi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.75975,
    longitude: 75.20169,
    wikiDataId: "Q1089504",
  },
  {
    id: 133519,
    name: "Puttur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.44189,
    longitude: 79.55314,
    wikiDataId: "Q1945739",
  },
  {
    id: 133520,
    name: "Paud",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.66727,
    longitude: 75.35305,
    wikiDataId: "Q2719146",
  },
  {
    id: 133521,
    name: "Pakala",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.44903,
    longitude: 79.11493,
    wikiDataId: "Q3430034",
  },
  {
    id: 133522,
    name: "Sathankulam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.30696,
    longitude: 78.07022,
    wikiDataId: "Q2718151",
  },
  {
    id: 133523,
    name: "Palakollu",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.5167,
    longitude: 81.73,
    wikiDataId: "Q1945121",
  },
  {
    id: 133524,
    name: "Sathyamangalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.10501,
    longitude: 78.11336,
    wikiDataId: "Q2718076",
  },
  {
    id: 133525,
    name: "Rajgarh",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.11453,
    longitude: 76.55681,
    wikiDataId: "Q2718076",
  },
  {
    id: 133526,
    name: "Palanpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.17128,
    longitude: 72.43827,
    wikiDataId: "Q949907",
  },
  {
    id: 133527,
    name: "Pedgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.69693,
    longitude: 72.76543,
    wikiDataId: "Q2724596",
  },
  {
    id: 133528,
    name: "Perya",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.77319,
    longitude: 76.65366,
    wikiDataId: "Q1006240",
  },
  {
    id: 133529,
    name: "Taranagar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.77276,
    longitude: 73.32335,
    wikiDataId: "Q6634626",
  },
  {
    id: 133530,
    name: "Pithampur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.36453,
    longitude: 81.04374,
    wikiDataId: "Q3786790",
  },
  {
    id: 133531,
    name: "Shamsabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.49188,
    longitude: 78.41617,
    wikiDataId: "Q3786790",
  },
  {
    id: 133532,
    name: "Palitana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.52519,
    longitude: 71.82309,
    wikiDataId: "Q3786790",
  },
  {
    id: 133533,
    name: "Paliyad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.25757,
    longitude: 71.56024,
    wikiDataId: "Q3786790",
  },
  {
    id: 133534,
    name: "Palkonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.60374,
    longitude: 83.75568,
    wikiDataId: "Q3415029",
  },
  {
    id: 133535,
    name: "Ambahta",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.60184,
    longitude: 80.70509,
    wikiDataId: "Q3415029",
  },
  {
    id: 133536,
    name: "Panch Mahals",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.75,
    longitude: 73.6,
    wikiDataId: "Q1781463",
  },
  {
    id: 133537,
    name: "Phaltan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.92449,
    longitude: 73.8008,
    wikiDataId: "Q463437",
  },
  {
    id: 133538,
    name: "Rahatgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.59556,
    longitude: 78.52628,
    wikiDataId: "Q2445471",
  },
  {
    id: 133539,
    name: "Rampur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.66902,
    longitude: 77.05359,
    wikiDataId: "Q2445471",
  },
  {
    id: 133540,
    name: "Pandatarai",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.18714,
    longitude: 81.32815,
    wikiDataId: "Q7130519",
  },
  {
    id: 133541,
    name: "Ranibennur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.25,
    longitude: 74.75,
    wikiDataId: "Q7130519",
  },
  {
    id: 133542,
    name: "Rajnagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.65981,
    longitude: 74.69937,
    wikiDataId: "Q2720790",
  },
  {
    id: 133544,
    name: "Rohru",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.43666,
    longitude: 77.62462,
    wikiDataId: "Q2734471",
  },
  {
    id: 133545,
    name: "Srimushnam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.91774,
    longitude: 78.36865,
    wikiDataId: "Q2734471",
  },
  {
    id: 133546,
    name: "Ponmana",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.95655,
    longitude: 75.34034,
    wikiDataId: "Q2475612",
  },
  {
    id: 133547,
    name: "Sriperumbudur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.22086,
    longitude: 78.0592,
    wikiDataId: "Q1749154",
  },
  {
    id: 133548,
    name: "Pardi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.5087,
    longitude: 72.94569,
    wikiDataId: "Q1925363",
  },
  {
    id: 133549,
    name: "Parvatipuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.78392,
    longitude: 83.42569,
    wikiDataId: "Q1925363",
  },
  {
    id: 133550,
    name: "Sherkot",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.50789,
    longitude: 78.75954,
    wikiDataId: "Q2718662",
  },
  {
    id: 133551,
    name: "Pasighat",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.06631,
    longitude: 95.32678,
    wikiDataId: "Q1936930",
  },
  {
    id: 133552,
    name: "Patan",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.03333,
    longitude: 81.53333,
    wikiDataId: "Q3786789",
  },
  {
    id: 133554,
    name: "Pune",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.37513,
    longitude: 73.90143,
    wikiDataId: "Q3788531",
  },
  {
    id: 133555,
    name: "Rampura",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.28636,
    longitude: 79.68962,
    wikiDataId: "Q3788531",
  },
  {
    id: 133556,
    name: "Pune Division",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.17279,
    longitude: 75.17425,
    wikiDataId: "Q368499",
  },
  {
    id: 133557,
    name: "Sarubera",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.6658,
    longitude: 86.43166,
    wikiDataId: "Q2734924",
  },
  {
    id: 133558,
    name: "Puntamba",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.2588,
    longitude: 76.43412,
    wikiDataId: "Q2719439",
  },
  {
    id: 133559,
    name: "Pural",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.46093,
    longitude: 76.93725,
    wikiDataId: "Q2544958",
  },
  {
    id: 133560,
    name: "Raybag",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.09953,
    longitude: 77.28018,
    wikiDataId: "Q2475098",
  },
  {
    id: 133561,
    name: "Sirsaganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.63124,
    longitude: 79.80436,
    wikiDataId: "Q1197824",
  },
  {
    id: 133563,
    name: "Giria",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.42553,
    longitude: 79.43066,
    wikiDataId: "Q2980705",
  },
  {
    id: 133564,
    name: "Ratnagiri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.79371,
    longitude: 75.53519,
    wikiDataId: "Q2980705",
  },
  {
    id: 133565,
    name: "Wer",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.38441,
    longitude: 73.54394,
    wikiDataId: "Q2980705",
  },
  {
    id: 133567,
    name: "Ratia",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.76096,
    longitude: 76.56034,
    wikiDataId: "Q2737500",
  },
  {
    id: 133568,
    name: "Rania",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.86371,
    longitude: 77.20432,
    wikiDataId: "Q2557912",
  },
  {
    id: 133569,
    name: "Talbahat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.51283,
    longitude: 80.14829,
    wikiDataId: "Q2718070",
  },
  {
    id: 133570,
    name: "Purba Champaran",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.58333,
    longitude: 84.83333,
    wikiDataId: "Q2718070",
  },
  {
    id: 133571,
    name: "Sakoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.1817,
    longitude: 77.02566,
    wikiDataId: "Q2545550",
  },
  {
    id: 133572,
    name: "Quepem",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.2128,
    longitude: 74.0772,
    wikiDataId: "Q2561452",
  },
  {
    id: 133573,
    name: "Queula",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.39011,
    longitude: 73.98557,
    wikiDataId: "Q2734444",
  },
  {
    id: 133574,
    name: "Anandnagar",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.50107,
    longitude: 78.45818,
    wikiDataId: "Q2734444",
  },
  {
    id: 133575,
    name: "Bagar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.82198,
    longitude: 75.37663,
    wikiDataId: "Q1026436",
  },
  {
    id: 133576,
    name: "Riasi",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.63828,
    longitude: 75.14261,
    wikiDataId: "Q63066",
  },
  {
    id: 133577,
    name: "Sagar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.47567,
    longitude: 75.1106,
    wikiDataId: "Q63066",
  },
  {
    id: 133578,
    name: "Thakurdwara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.25153,
    longitude: 77.60253,
    wikiDataId: "Q2551301",
  },
  {
    id: 133579,
    name: "Rewari",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.02706,
    longitude: 77.15177,
    wikiDataId: "Q2735019",
  },
  {
    id: 133580,
    name: "Tikaitnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2309,
    longitude: 81.23315,
    wikiDataId: "Q1000485",
  },
  {
    id: 133581,
    name: "Rafiganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.81757,
    longitude: 84.63445,
    wikiDataId: "Q2560697",
  },
  {
    id: 133582,
    name: "Raghunathpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.64492,
    longitude: 87.91762,
    wikiDataId: "Q2560697",
  },
  {
    id: 133583,
    name: "Sangamner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.5921,
    longitude: 74.19966,
    wikiDataId: "Q2573077",
  },
  {
    id: 133584,
    name: "Raha",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.23333,
    longitude: 92.51667,
    wikiDataId: "Q2573077",
  },
  {
    id: 133585,
    name: "Raia",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.30499,
    longitude: 73.97096,
    wikiDataId: "Q2573077",
  },
  {
    id: 133586,
    name: "Sakleshpur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.16,
    longitude: 76.91,
    wikiDataId: "Q1430830",
  },
  {
    id: 133587,
    name: "Raigarh",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.08582,
    longitude: 83.30603,
    wikiDataId: "Q2286310",
  },
  {
    id: 133588,
    name: "Sangola",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.57,
    longitude: 73.13,
    wikiDataId: "Q2286310",
  },
  {
    id: 133589,
    name: "Mangan",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.04259,
    longitude: 74.02373,
    wikiDataId: "Q2286310",
  },
  {
    id: 133590,
    name: "Raipur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25621,
    longitude: 81.69022,
    wikiDataId: "Q2295914",
  },
  {
    id: 133591,
    name: "Sanchi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25,
    longitude: 78.08333,
    wikiDataId: "Q1815223",
  },
  {
    id: 133592,
    name: "Antu",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.38629,
    longitude: 78.8156,
    wikiDataId: "Q1815223",
  },
  {
    id: 133593,
    name: "Samba",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.37526,
    longitude: 74.3092,
    wikiDataId: "Q1949395",
  },
  {
    id: 133594,
    name: "Tiruppur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.45296,
    longitude: 77.55335,
    wikiDataId: "Q2237707",
  },
  {
    id: 133595,
    name: "Satna",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.30393,
    longitude: 74.35568,
    wikiDataId: "Q582800",
  },
  {
    id: 133596,
    name: "Singtam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.23822,
    longitude: 73.93503,
    wikiDataId: "Q596693",
  },
  {
    id: 133597,
    name: "Anupshahr",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.755,
    longitude: 79.474,
    wikiDataId: "Q1780338",
  },
  {
    id: 133598,
    name: "Sandur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.65,
    longitude: 77.35,
    wikiDataId: "Q427679",
  },
  {
    id: 133599,
    name: "Tiruppuvanam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.37158,
    longitude: 78.83077,
    wikiDataId: "Q775255",
  },
  {
    id: 133600,
    name: "Ramanayyapeta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.94516,
    longitude: 82.2385,
    wikiDataId: "Q2229781",
  },
  {
    id: 133601,
    name: "Sopur",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.32301,
    longitude: 75.1861,
    wikiDataId: "Q2321939",
  },
  {
    id: 133602,
    name: "Badhni Kalan",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.51667,
    longitude: 85.1,
    wikiDataId: "Q3929858",
  },
  {
    id: 133603,
    name: "Tiruttangal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.2885,
    longitude: 79.31271,
    wikiDataId: "Q1435144",
  },
  {
    id: 133604,
    name: "Simdega",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63073,
    longitude: 85.56057,
    wikiDataId: "Q2663612",
  },
  {
    id: 133605,
    name: "Rampachodavaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.44088,
    longitude: 81.77558,
    wikiDataId: "Q2010452",
  },
  {
    id: 133606,
    name: "Sini",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.34316,
    longitude: 85.3094,
    wikiDataId: "Q174461",
  },
  {
    id: 133607,
    name: "Araul",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.27883,
    longitude: 78.16844,
    wikiDataId: "Q15388",
  },
  {
    id: 133608,
    name: "Rangia",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.44931,
    longitude: 91.61356,
    wikiDataId: "Q2475022",
  },
  {
    id: 133609,
    name: "Cheyyur",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.17733,
    longitude: 88.53358,
    wikiDataId: "Q2726240",
  },
  {
    id: 133610,
    name: "Rangapara",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.83772,
    longitude: 92.66876,
    wikiDataId: "Q2732997",
  },
  {
    id: 133611,
    name: "Tiruvannamalai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.46009,
    longitude: 78.18635,
    wikiDataId: "Q2229745",
  },
  {
    id: 133612,
    name: "Tulsipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.8576,
    longitude: 83.85487,
    wikiDataId: "Q2718120",
  },
  {
    id: 133613,
    name: "Tundla",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.74491,
    longitude: 80.49012,
    wikiDataId: "Q2717648",
  },
  {
    id: 133614,
    name: "Adirampattinam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.08137,
    longitude: 74.61854,
    wikiDataId: "Q3930456",
  },
  {
    id: 133615,
    name: "Sausar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.81667,
    longitude: 75.11667,
    wikiDataId: "Q10357555",
  },
  {
    id: 133616,
    name: "Ratanpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.2866,
    longitude: 82.16823,
    wikiDataId: "Q720204",
  },
  {
    id: 133617,
    name: "Rohtak",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.69029,
    longitude: 75.57688,
    wikiDataId: "Q2557870",
  },
  {
    id: 133618,
    name: "Sehore",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.33033,
    longitude: 75.04032,
    wikiDataId: "Q652376",
  },
  {
    id: 133619,
    name: "Satara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17,
    longitude: 73.5,
    wikiDataId: "Q1922257",
  },
  {
    id: 133620,
    name: "Bagha Purana",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.22496,
    longitude: 84.86414,
    wikiDataId: "Q1012706",
  },
  {
    id: 133621,
    name: "Raxaul",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.97982,
    longitude: 84.85065,
    wikiDataId: "Q2087576",
  },
  {
    id: 133622,
    name: "Bakloh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.4,
    longitude: 83.5,
    wikiDataId: "Q2577997",
  },
  {
    id: 133623,
    name: "Sendhwa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.63722,
    longitude: 79.06275,
    wikiDataId: "Q2548659",
  },
  {
    id: 133624,
    name: "Seondha",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.73781,
    longitude: 77.43399,
    wikiDataId: "Q2720270",
  },
  {
    id: 133625,
    name: "Balachor",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.52798,
    longitude: 86.87156,
    wikiDataId: "Q2428731",
  },
  {
    id: 133626,
    name: "Banga",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.64602,
    longitude: 84.05311,
    wikiDataId: "Q2428731",
  },
  {
    id: 133627,
    name: "Renigunta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.65143,
    longitude: 79.51256,
    wikiDataId: "Q720932",
  },
  {
    id: 133628,
    name: "Usehat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.21641,
    longitude: 83.0358,
    wikiDataId: "Q2566510",
  },
  {
    id: 133629,
    name: "Uska",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.85091,
    longitude: 84.3778,
    wikiDataId: "Q2718686",
  },
  {
    id: 133630,
    name: "Repalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.0184,
    longitude: 80.82958,
    wikiDataId: "Q2483836",
  },
  {
    id: 133631,
    name: "Savda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.55363,
    longitude: 72.92559,
    wikiDataId: "Q7317762",
  },
  {
    id: 133632,
    name: "Revelganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.78976,
    longitude: 84.63596,
    wikiDataId: "Q2453460",
  },
  {
    id: 133633,
    name: "Seoni Malwa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.53256,
    longitude: 81.29234,
    wikiDataId: "Q1000495",
  },
  {
    id: 133634,
    name: "Samalkha",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.19613,
    longitude: 76.61607,
    wikiDataId: "Q2301759",
  },
  {
    id: 133635,
    name: "Safidon",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.199,
    longitude: 76.6183,
    wikiDataId: "Q1949364",
  },
  {
    id: 133636,
    name: "Kohima",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.88997,
    longitude: 91.82707,
    wikiDataId: "Q1884672",
  },
  {
    id: 133637,
    name: "Varanasi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.69467,
    longitude: 79.52284,
    wikiDataId: "Q2718149",
  },
  {
    id: 133638,
    name: "Gurdaha",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.10778,
    longitude: 78.29255,
    wikiDataId: "Q502141",
  },
  {
    id: 133639,
    name: "Sawantvadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.97671,
    longitude: 76.78799,
    wikiDataId: "Q2545016",
  },
  {
    id: 133640,
    name: "Sumbal",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.08115,
    longitude: 74.83242,
    wikiDataId: "Q2545016",
  },
  {
    id: 133641,
    name: "Vindhyachal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.6886,
    longitude: 83.06784,
    wikiDataId: "Q1709323",
  },
  {
    id: 133642,
    name: "Sargur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.95629,
    longitude: 78.27539,
    wikiDataId: "Q2557879",
  },
  {
    id: 133643,
    name: "Sabar Kantha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.19646,
    longitude: 69.27076,
    wikiDataId: "Q2557879",
  },
  {
    id: 133644,
    name: "Selu",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.43687,
    longitude: 73.11964,
    wikiDataId: "Q15272986",
  },
  {
    id: 133645,
    name: "Rohini",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.74322,
    longitude: 77.06778,
    wikiDataId: "Q15272986",
  },
  {
    id: 133646,
    name: "Sarka Ghat",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.20269,
    longitude: 77.75484,
    wikiDataId: "Q2558692",
  },
  {
    id: 133647,
    name: "Shadipur Julana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.83333,
    longitude: 76.66667,
    wikiDataId: "Q967388",
  },
  {
    id: 133648,
    name: "Rohtas",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.97941,
    longitude: 84.02774,
    wikiDataId: "Q100085",
  },
  {
    id: 133649,
    name: "Saundatti",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.69935,
    longitude: 75.73408,
    wikiDataId: "Q2624337",
  },
  {
    id: 133650,
    name: "Guskhara",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.86632,
    longitude: 77.89118,
    wikiDataId: "Q1020718",
  },
  {
    id: 133652,
    name: "Habra",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.60872,
    longitude: 79.06517,
    wikiDataId: "Q1805059",
  },
  {
    id: 133654,
    name: "Bari",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.04,
    longitude: 76.52,
    wikiDataId: "Q1805059",
  },
  {
    id: 133655,
    name: "Wazirganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.49001,
    longitude: 79.90108,
    wikiDataId: "Q2717615",
  },
  {
    id: 133656,
    name: "Rusera",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75355,
    longitude: 86.02597,
    wikiDataId: "Q2717615",
  },
  {
    id: 133657,
    name: "Rajkot",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83238,
    longitude: 71.6047,
    wikiDataId: "Q2068178",
  },
  {
    id: 133658,
    name: "Tigri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.52432,
    longitude: 77.49101,
    wikiDataId: "Q2037248",
  },
  {
    id: 133660,
    name: "Sailana",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.44318,
    longitude: 77.19768,
    wikiDataId: "Q2037248",
  },
  {
    id: 133661,
    name: "Sanawad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78968,
    longitude: 78.39473,
    wikiDataId: "Q632752",
  },
  {
    id: 133662,
    name: "Bakani",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.05275,
    longitude: 76.11907,
    wikiDataId: "Q2545885",
  },
  {
    id: 133663,
    name: "Sangli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.39069,
    longitude: 74.64979,
    wikiDataId: "Q2544979",
  },
  {
    id: 133665,
    name: "Bali",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.65,
    longitude: 75.6,
    wikiDataId: "Q2442782",
  },
  {
    id: 133666,
    name: "Gobindapur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.31097,
    longitude: 78.08979,
    wikiDataId: "Q3929684",
  },
  {
    id: 133667,
    name: "Namchi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.53583,
    longitude: 73.44917,
    wikiDataId: "Q2551329",
  },
  {
    id: 133668,
    name: "Raj Nandgaon",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.16667,
    longitude: 81,
    wikiDataId: "Q2551329",
  },
  {
    id: 133669,
    name: "Raj-Nandgaon",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.09687,
    longitude: 81.0289,
    wikiDataId: "Q2551329",
  },
  {
    id: 133670,
    name: "Balotra",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.72021,
    longitude: 74.8008,
    wikiDataId: "Q387676",
  },
  {
    id: 133671,
    name: "Rajahmundry",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.00517,
    longitude: 81.77784,
    wikiDataId: "Q1639492",
  },
  {
    id: 133672,
    name: "North District",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.02849,
    longitude: 74.47442,
    wikiDataId: "Q931170",
  },
  {
    id: 133673,
    name: "Shupiyan",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.25,
    longitude: 74.25,
    wikiDataId: "Q544279",
  },
  {
    id: 133674,
    name: "Rangpo",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.23731,
    longitude: 76.62243,
    wikiDataId: "Q3813222",
  },
  {
    id: 133675,
    name: "Sanwer",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.67821,
    longitude: 74.94483,
    wikiDataId: "Q2468221",
  },
  {
    id: 133676,
    name: "Santokhgarh",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.85142,
    longitude: 77.30066,
    wikiDataId: "Q2231162",
  },
  {
    id: 133677,
    name: "Saoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.86667,
    longitude: 73.9,
    wikiDataId: "Q2719101",
  },
  {
    id: 133678,
    name: "Rajgir",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.02828,
    longitude: 85.42079,
    wikiDataId: "Q863683",
  },
  {
    id: 133679,
    name: "Rajpipla",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.33333,
    longitude: 70.83333,
    wikiDataId: "Q11854",
  },
  {
    id: 133680,
    name: "Sarangpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.88929,
    longitude: 79.91178,
    wikiDataId: "Q1815245",
  },
  {
    id: 133681,
    name: "Bandikui",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.47856,
    longitude: 76.59284,
    wikiDataId: "Q2546585",
  },
  {
    id: 133682,
    name: "Rajula",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.86667,
    longitude: 73.5,
    wikiDataId: "Q1816866",
  },
  {
    id: 133684,
    name: "Ranavav",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.03854,
    longitude: 71.44345,
    wikiDataId: "Q2734999",
  },
  {
    id: 133685,
    name: "Saoner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.11087,
    longitude: 78.89311,
    wikiDataId: "Q2719488",
  },
  {
    id: 133686,
    name: "Naya Bazar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.89802,
    longitude: 78.171,
    wikiDataId: "Q2718772",
  },
  {
    id: 133687,
    name: "Sangrampur, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.65679,
    longitude: 73.51701,
    wikiDataId: "Q2718772",
  },
  {
    id: 133688,
    name: "Tikri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.38725,
    longitude: 81.15125,
    wikiDataId: "Q2718772",
  },
  {
    id: 133689,
    name: "Sarangkheda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.77947,
    longitude: 79.36459,
    wikiDataId: "Q2570708",
  },
  {
    id: 133690,
    name: "Banswara",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.96739,
    longitude: 74.9087,
    wikiDataId: "Q2570708",
  },
  {
    id: 133691,
    name: "Ramachandrapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.83636,
    longitude: 82.02871,
    wikiDataId: "Q542856",
  },
  {
    id: 133692,
    name: "Tiruvalla",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.93333,
    longitude: 76.5,
    wikiDataId: "Q7288856",
  },
  {
    id: 133695,
    name: "South District",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.64648,
    longitude: 75.94325,
    wikiDataId: "Q2718708",
  },
  {
    id: 133696,
    name: "West District",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.25097,
    longitude: 75.17893,
    wikiDataId: "Q2119813",
  },
  {
    id: 133698,
    name: "Soyibug",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.40379,
    longitude: 74.22388,
    wikiDataId: "Q2119813",
  },
  {
    id: 133699,
    name: "Aonla",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.80084,
    longitude: 79.45206,
    wikiDataId: "Q2119813",
  },
  {
    id: 133700,
    name: "Tilhar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.90172,
    longitude: 83.83758,
    wikiDataId: "Q729633",
  },
  {
    id: 133701,
    name: "Ramnagar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.16371,
    longitude: 84.32342,
    wikiDataId: "Q2228976",
  },
  {
    id: 133702,
    name: "Srinagar",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.80728,
    longitude: 75.31119,
    wikiDataId: "Q3929900",
  },
  {
    id: 133703,
    name: "Gosaba",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.3925,
    longitude: 79.1283,
    wikiDataId: "Q2669058",
  },
  {
    id: 133704,
    name: "Tilsahri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.26907,
    longitude: 83.02971,
    wikiDataId: "Q2669058",
  },
  {
    id: 133705,
    name: "Tindwari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.81014,
    longitude: 79.02699,
    wikiDataId: "Q1487593",
  },
  {
    id: 133706,
    name: "Sarahan",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.44943,
    longitude: 77.63087,
    wikiDataId: "Q2566469",
  },
  {
    id: 133707,
    name: "Titron",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.34967,
    longitude: 79.18234,
    wikiDataId: "Q3929948",
  },
  {
    id: 133708,
    name: "Baran",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.256,
    longitude: 75.24116,
    wikiDataId: "Q3929948",
  },
  {
    id: 133709,
    name: "Satwas",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.467,
    longitude: 75.43996,
    wikiDataId: "Q3929948",
  },
  {
    id: 133710,
    name: "Satana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.39562,
    longitude: 79.32725,
    wikiDataId: "Q2545077",
  },
  {
    id: 133711,
    name: "Ramanuj Ganj",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.80637,
    longitude: 83.69981,
    wikiDataId: "Q2545077",
  },
  {
    id: 133712,
    name: "Ramapuram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.28749,
    longitude: 77.86722,
    wikiDataId: "Q1605050",
  },
  {
    id: 133714,
    name: "Abiramam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.35031,
    longitude: 73.30885,
    wikiDataId: "Q1936774",
  },
  {
    id: 133715,
    name: "Rewari District",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.52454,
    longitude: 74.83689,
    wikiDataId: "Q2232515",
  },
  {
    id: 133716,
    name: "Gosanimari",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.64082,
    longitude: 79.43229,
    wikiDataId: "Q1923840",
  },
  {
    id: 133717,
    name: "Saugor",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.64704,
    longitude: 74.52118,
    wikiDataId: "Q2719656",
  },
  {
    id: 133718,
    name: "Ranpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.68734,
    longitude: 69.74485,
    wikiDataId: "Q2570700",
  },
  {
    id: 133719,
    name: "Sanivarsante",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.62239,
    longitude: 75.62951,
    wikiDataId: "Q2737504",
  },
  {
    id: 133720,
    name: "Tori Fatehpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.25034,
    longitude: 79.06204,
    wikiDataId: "Q2231200",
  },
  {
    id: 133721,
    name: "Balrampur",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83463,
    longitude: 91.36614,
    wikiDataId: "Q2231200",
  },
  {
    id: 133722,
    name: "Reha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.57267,
    longitude: 70.64718,
    wikiDataId: "Q2724400",
  },
  {
    id: 133723,
    name: "Ugu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.59474,
    longitude: 79.5666,
    wikiDataId: "Q3930467",
  },
  {
    id: 133724,
    name: "Satara Division",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.24757,
    longitude: 76.03509,
    wikiDataId: "Q2362462",
  },
  {
    id: 133725,
    name: "Aduthurai",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.92981,
    longitude: 75.59209,
    wikiDataId: "Q2554975",
  },
  {
    id: 133726,
    name: "Alagapuram",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.26724,
    longitude: 74.40288,
    wikiDataId: "Q667224",
  },
  {
    id: 133727,
    name: "Topchanchi",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.6843,
    longitude: 85.05457,
    wikiDataId: "Q667224",
  },
  {
    id: 133728,
    name: "Ujhani",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.55607,
    longitude: 77.78972,
    wikiDataId: "Q1925374",
  },
  {
    id: 133729,
    name: "Rayachoti",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.05723,
    longitude: 78.75056,
    wikiDataId: "Q2349951",
  },
  {
    id: 133730,
    name: "Rayadrug",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.69971,
    longitude: 76.85241,
    wikiDataId: "Q2349951",
  },
  {
    id: 133731,
    name: "Sankeshwar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.49178,
    longitude: 74.77391,
    wikiDataId: "Q2349951",
  },
  {
    id: 133732,
    name: "Razampeta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.19544,
    longitude: 79.15896,
    wikiDataId: "Q3537413",
  },
  {
    id: 133733,
    name: "Razole",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.47608,
    longitude: 81.83912,
    wikiDataId: "Q3537413",
  },
  {
    id: 133734,
    name: "Razam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.44909,
    longitude: 83.65957,
    wikiDataId: "Q3537413",
  },
  {
    id: 133735,
    name: "Gorubathan",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.01864,
    longitude: 78.2293,
    wikiDataId: "Q3537413",
  },
  {
    id: 133736,
    name: "Alandur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.3636,
    longitude: 75.56838,
    wikiDataId: "Q2718839",
  },
  {
    id: 133737,
    name: "Vrindavan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.44467,
    longitude: 83.61302,
    wikiDataId: "Q2718839",
  },
  {
    id: 133738,
    name: "Shahdol",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.24918,
    longitude: 77.40786,
    wikiDataId: "Q2548310",
  },
  {
    id: 133739,
    name: "Sachin",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.62974,
    longitude: 73.00197,
    wikiDataId: "Q2548310",
  },
  {
    id: 133740,
    name: "Banat",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.00153,
    longitude: 91.72427,
    wikiDataId: "Q2733025",
  },
  {
    id: 133741,
    name: "Seoni",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.97494,
    longitude: 76.99137,
    wikiDataId: "Q514228",
  },
  {
    id: 133742,
    name: "Salaya",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.08718,
    longitude: 72.88153,
    wikiDataId: "Q2774361",
  },
  {
    id: 133743,
    name: "Savanur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.5587,
    longitude: 74.53211,
    wikiDataId: "Q385985",
  },
  {
    id: 133744,
    name: "Zaidpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43818,
    longitude: 78.03758,
    wikiDataId: "Q2543436",
  },
  {
    id: 133745,
    name: "Asalatganj",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.61925,
    longitude: 77.95263,
    wikiDataId: "Q2229196",
  },
  {
    id: 133746,
    name: "Shahabad",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.40596,
    longitude: 76.67042,
    wikiDataId: "Q2557947",
  },
  {
    id: 133747,
    name: "Almora",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.73783,
    longitude: 80.3435,
    wikiDataId: "Q2717627",
  },
  {
    id: 133748,
    name: "Sagauli",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.7639,
    longitude: 84.74341,
    wikiDataId: "Q7399079",
  },
  {
    id: 133749,
    name: "Saharsa",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.87498,
    longitude: 86.59611,
    wikiDataId: "Q1949422",
  },
  {
    id: 133750,
    name: "Barkot",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.12125,
    longitude: 78.62273,
    wikiDataId: "Q2717638",
  },
  {
    id: 133751,
    name: "Bazpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.07227,
    longitude: 78.75082,
    wikiDataId: "Q2717953",
  },
  {
    id: 133752,
    name: "Afzalpur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.99354,
    longitude: 87.67333,
    wikiDataId: "Q767878",
  },
  {
    id: 133753,
    name: "Bageshwar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.9,
    longitude: 77.68333,
    wikiDataId: "Q242895",
  },
  {
    id: 133754,
    name: "Bhim Tal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.79603,
    longitude: 78.83373,
    wikiDataId: "Q2717629",
  },
  {
    id: 133755,
    name: "Chakrata",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.53749,
    longitude: 83.22378,
    wikiDataId: "Q2717629",
  },
  {
    id: 133756,
    name: "Banki",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.49183,
    longitude: 92.98143,
    wikiDataId: "Q2576192",
  },
  {
    id: 133757,
    name: "Shahpura",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.46219,
    longitude: 74.92318,
    wikiDataId: "Q2548299",
  },
  {
    id: 133758,
    name: "Tiruvottiyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.00334,
    longitude: 80.19614,
    wikiDataId: "Q2548299",
  },
  {
    id: 133759,
    name: "Banposh",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.81034,
    longitude: 92.65226,
    wikiDataId: "Q2726267",
  },
  {
    id: 133760,
    name: "Shiggaon",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.94119,
    longitude: 75.78467,
    wikiDataId: "Q2475072",
  },
  {
    id: 133761,
    name: "Sakti",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.02662,
    longitude: 82.96091,
    wikiDataId: "Q2734644",
  },
  {
    id: 133762,
    name: "Champawat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43463,
    longitude: 78.77903,
    wikiDataId: "Q2717655",
  },
  {
    id: 133763,
    name: "Tisaiyanvilai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.69,
    longitude: 78.29,
    wikiDataId: "Q15192",
  },
  {
    id: 133764,
    name: "Saligao",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.55359,
    longitude: 73.79036,
    wikiDataId: "Q2734462",
  },
  {
    id: 133765,
    name: "Dehradun",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.02857,
    longitude: 81.45403,
    wikiDataId: "Q1925383",
  },
  {
    id: 133766,
    name: "Samakhiali",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.31038,
    longitude: 69.60376,
    wikiDataId: "Q2734222",
  },
  {
    id: 133767,
    name: "Samastipur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 85.91667,
    wikiDataId: "Q2734222",
  },
  {
    id: 133768,
    name: "Thang",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.57523,
    longitude: 75.10929,
    wikiDataId: "Q1117086",
  },
  {
    id: 133769,
    name: "Banur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.4,
    longitude: 83.88333,
    wikiDataId: "Q1267306",
  },
  {
    id: 133770,
    name: "Devaprayag",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.58498,
    longitude: 78.56959,
    wikiDataId: "Q247248",
  },
  {
    id: 133771,
    name: "Alangulam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.81299,
    longitude: 72.57879,
    wikiDataId: "Q7409136",
  },
  {
    id: 133772,
    name: "Bari Sadri",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.83601,
    longitude: 76.19324,
    wikiDataId: "Q2545261",
  },
  {
    id: 133773,
    name: "Dharchula",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.84348,
    longitude: 78.90683,
    wikiDataId: "Q2543468",
  },
  {
    id: 133774,
    name: "Sirsa",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.23552,
    longitude: 77.01273,
    wikiDataId: "Q2557930",
  },
  {
    id: 133776,
    name: "Barmer",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.30182,
    longitude: 76.45786,
    wikiDataId: "Q2545523",
  },
  {
    id: 133777,
    name: "Sancoale",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.37794,
    longitude: 73.90352,
    wikiDataId: "Q2573142",
  },
  {
    id: 133778,
    name: "Dugadda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.06989,
    longitude: 80.51497,
    wikiDataId: "Q2717875",
  },
  {
    id: 133779,
    name: "Shikarpur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.08613,
    longitude: 76.54692,
    wikiDataId: "Q1698292",
  },
  {
    id: 133780,
    name: "Shevgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.56784,
    longitude: 74.21154,
    wikiDataId: "Q2466083",
  },
  {
    id: 133781,
    name: "Alwa Tirunagari",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.79886,
    longitude: 74.46683,
    wikiDataId: "Q1320932",
  },
  {
    id: 133782,
    name: "Shikrapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.11202,
    longitude: 74.7699,
    wikiDataId: "Q429215",
  },
  {
    id: 133783,
    name: "Ambasamudram",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.92707,
    longitude: 76.28649,
    wikiDataId: "Q2718817",
  },
  {
    id: 133785,
    name: "Basi",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.24506,
    longitude: 75.84488,
    wikiDataId: "Q2441398",
  },
  {
    id: 133786,
    name: "Sanguem",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.22901,
    longitude: 74.15149,
    wikiDataId: "Q614055",
  },
  {
    id: 133787,
    name: "Atarra",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.62477,
    longitude: 78.08669,
    wikiDataId: "Q614055",
  },
  {
    id: 133788,
    name: "Shimoga",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.72824,
    longitude: 75.88669,
    wikiDataId: "Q614055",
  },
  {
    id: 133789,
    name: "Shirhatti",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.25649,
    longitude: 74.48195,
    wikiDataId: "Q721843",
  },
  {
    id: 133790,
    name: "Sarbhon",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.17021,
    longitude: 73.5782,
    wikiDataId: "Q2774237",
  },
  {
    id: 133791,
    name: "Sanquelim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.56422,
    longitude: 74.00799,
    wikiDataId: "Q720140",
  },
  {
    id: 133792,
    name: "Dwarahat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.79016,
    longitude: 83.03481,
    wikiDataId: "Q1945445",
  },
  {
    id: 133793,
    name: "Garhwal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.35792,
    longitude: 82.4308,
    wikiDataId: "Q1945445",
  },
  {
    id: 133794,
    name: "Shimla",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.35205,
    longitude: 76.31775,
    wikiDataId: "Q2558670",
  },
  {
    id: 133795,
    name: "Sanvordem",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.26269,
    longitude: 74.11965,
    wikiDataId: "Q720846",
  },
  {
    id: 133796,
    name: "Shajapur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.17391,
    longitude: 76.06993,
    wikiDataId: "Q2465668",
  },
  {
    id: 133797,
    name: "Shirol",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.3851,
    longitude: 78.92155,
    wikiDataId: "Q2544743",
  },
  {
    id: 133798,
    name: "Sapatgram",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.33732,
    longitude: 90.1236,
    wikiDataId: "Q2732976",
  },
  {
    id: 133799,
    name: "Harbatpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.74442,
    longitude: 79.09353,
    wikiDataId: "Q2732976",
  },
  {
    id: 133800,
    name: "Ajjampur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.69963,
    longitude: 85.93126,
    wikiDataId: "Q2575928",
  },
  {
    id: 133801,
    name: "Saraipali",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.3153,
    longitude: 83.00629,
    wikiDataId: "Q2559019",
  },
  {
    id: 133802,
    name: "Jaspur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.49404,
    longitude: 79.09177,
    wikiDataId: "Q26780575",
  },
  {
    id: 133803,
    name: "Joshimath",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.14551,
    longitude: 77.61433,
    wikiDataId: "Q2307984",
  },
  {
    id: 133804,
    name: "Basni",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.69224,
    longitude: 75.23608,
    wikiDataId: "Q2546476",
  },
  {
    id: 133805,
    name: "Ambattur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.44062,
    longitude: 74.491,
    wikiDataId: "Q2546476",
  },
  {
    id: 133806,
    name: "Shorapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.99971,
    longitude: 76.39611,
    wikiDataId: "Q7423978",
  },
  {
    id: 133807,
    name: "Solan",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.69887,
    longitude: 76.73529,
    wikiDataId: "Q7423978",
  },
  {
    id: 133808,
    name: "Sathamba",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.98297,
    longitude: 72.50196,
    wikiDataId: "Q3107283",
  },
  {
    id: 133809,
    name: "Sarupathar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.206,
    longitude: 96.81,
    wikiDataId: "Q638291",
  },
  {
    id: 133810,
    name: "Ambur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.06272,
    longitude: 75.01104,
    wikiDataId: "Q2544188",
  },
  {
    id: 133811,
    name: "Sirmaur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.50988,
    longitude: 77.79395,
    wikiDataId: "Q7422938",
  },
  {
    id: 133812,
    name: "Haridwar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.02705,
    longitude: 82.91843,
    wikiDataId: "Q2717644",
  },
  {
    id: 133813,
    name: "Haldwani",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.3789,
    longitude: 81.51035,
    wikiDataId: "Q2717644",
  },
  {
    id: 133814,
    name: "Kaladhungi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.77579,
    longitude: 79.67535,
    wikiDataId: "Q2717870",
  },
  {
    id: 133815,
    name: "Shrigonda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.68589,
    longitude: 73.99333,
    wikiDataId: "Q581562",
  },
  {
    id: 133816,
    name: "Sillod",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.72601,
    longitude: 74.06433,
    wikiDataId: "Q581562",
  },
  {
    id: 133817,
    name: "Tondi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.44164,
    longitude: 77.91349,
    wikiDataId: "Q2715680",
  },
  {
    id: 133818,
    name: "Atrauli",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.24968,
    longitude: 80.86899,
    wikiDataId: "Q2715680",
  },
  {
    id: 133819,
    name: "Turaiyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.50526,
    longitude: 77.23826,
    wikiDataId: "Q242563",
  },
  {
    id: 133820,
    name: "Shujalpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.5,
    longitude: 81,
    wikiDataId: "Q2577924",
  },
  {
    id: 133821,
    name: "Khatima",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.86045,
    longitude: 81.19567,
    wikiDataId: "Q2717886",
  },
  {
    id: 133822,
    name: "Sattenapalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.39381,
    longitude: 80.15221,
    wikiDataId: "Q3445011",
  },
  {
    id: 133823,
    name: "Udangudi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.35592,
    longitude: 77.92457,
    wikiDataId: "Q2229604",
  },
  {
    id: 133824,
    name: "Sidhi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.53628,
    longitude: 76.68452,
    wikiDataId: "Q2720094",
  },
  {
    id: 133825,
    name: "Shivaji Nagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.59483,
    longitude: 74.20301,
    wikiDataId: "Q2247428",
  },
  {
    id: 133826,
    name: "Sihora",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83877,
    longitude: 78.73874,
    wikiDataId: "Q2247428",
  },
  {
    id: 133827,
    name: "Shrirangapattana",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.76615,
    longitude: 75.11778,
    wikiDataId: "Q2282286",
  },
  {
    id: 133828,
    name: "Kichha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.03051,
    longitude: 79.48813,
    wikiDataId: "Q2717561",
  },
  {
    id: 133829,
    name: "Simaria",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.65576,
    longitude: 78.79669,
    wikiDataId: "Q2546577",
  },
  {
    id: 133830,
    name: "Siddapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.97335,
    longitude: 75.33724,
    wikiDataId: "Q1026882",
  },
  {
    id: 133831,
    name: "Savli",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.33726,
    longitude: 71.3035,
    wikiDataId: "Q2734216",
  },
  {
    id: 133832,
    name: "Ammapettai",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.02301,
    longitude: 76.34408,
    wikiDataId: "Q946827",
  },
  {
    id: 133833,
    name: "Kotdwara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.5708,
    longitude: 77.3261,
    wikiDataId: "Q946827",
  },
  {
    id: 133834,
    name: "Atraulia",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.50427,
    longitude: 78.54263,
    wikiDataId: "Q1003502",
  },
  {
    id: 133835,
    name: "Singoli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.2,
    longitude: 77.08333,
    wikiDataId: "Q2077421",
  },
  {
    id: 133836,
    name: "Sion, Mumbai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.45512,
    longitude: 76.44073,
    wikiDataId: "Q2718737",
  },
  {
    id: 133837,
    name: "Champhai",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.26705,
    longitude: 94.02237,
    wikiDataId: "Q2718737",
  },
  {
    id: 133838,
    name: "Singrauli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.68562,
    longitude: 75.09622,
    wikiDataId: "Q2624000",
  },
  {
    id: 133839,
    name: "Laksar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.20904,
    longitude: 78.58837,
    wikiDataId: "Q2717525",
  },
  {
    id: 133840,
    name: "Sironj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15422,
    longitude: 78.7812,
    wikiDataId: "Q2546462",
  },
  {
    id: 133841,
    name: "Sundarnagar",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.24188,
    longitude: 77.12362,
    wikiDataId: "Q3955580",
  },
  {
    id: 133842,
    name: "Sitamau",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.08503,
    longitude: 79.55037,
    wikiDataId: "Q2287206",
  },
  {
    id: 133843,
    name: "Sohagi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.45046,
    longitude: 77.4665,
    wikiDataId: "Q2545959",
  },
  {
    id: 133845,
    name: "Seorinarayan",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.72055,
    longitude: 82.59344,
    wikiDataId: "Q2545959",
  },
  {
    id: 133846,
    name: "Sidlaghatta",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.17859,
    longitude: 77.28998,
    wikiDataId: "Q2552553",
  },
  {
    id: 133847,
    name: "Barbil",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.28172,
    longitude: 92.90039,
    wikiDataId: "Q2086190",
  },
  {
    id: 133849,
    name: "Auraiya",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.49313,
    longitude: 78.30196,
    wikiDataId: "Q2726292",
  },
  {
    id: 133850,
    name: "Serula",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.54774,
    longitude: 73.84329,
    wikiDataId: "Q2726292",
  },
  {
    id: 133851,
    name: "Uppiliyapuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.62091,
    longitude: 80.19331,
    wikiDataId: "Q208000",
  },
  {
    id: 133852,
    name: "Shahbazpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.30511,
    longitude: 87.28865,
    wikiDataId: "Q208000",
  },
  {
    id: 133853,
    name: "Sohagpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.5,
    longitude: 81.5,
    wikiDataId: "Q1572231",
  },
  {
    id: 133854,
    name: "Baswa",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.13183,
    longitude: 76.13328,
    wikiDataId: "Q202710",
  },
  {
    id: 133855,
    name: "Pauri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.01718,
    longitude: 78.12358,
    wikiDataId: "Q1748608",
  },
  {
    id: 133856,
    name: "Pipalkoti",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.182,
    longitude: 81.61769,
    wikiDataId: "Q2717353",
  },
  {
    id: 133857,
    name: "Sonegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.7932,
    longitude: 76.69921,
    wikiDataId: "Q2566517",
  },
  {
    id: 133858,
    name: "Sheikhpura",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.13073,
    longitude: 85.78176,
    wikiDataId: "Q2566517",
  },
  {
    id: 133859,
    name: "Andippatti",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.13915,
    longitude: 73.06784,
    wikiDataId: "Q2228878",
  },
  {
    id: 133860,
    name: "Sheohar",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.5,
    longitude: 85.3,
    wikiDataId: "Q2483944",
  },
  {
    id: 133861,
    name: "Tekanpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.8,
    longitude: 77,
    wikiDataId: "Q620105",
  },
  {
    id: 133862,
    name: "Pithoragarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.65128,
    longitude: 79.36815,
    wikiDataId: "Q620105",
  },
  {
    id: 133863,
    name: "Sherghati",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.5595,
    longitude: 84.79162,
    wikiDataId: "Q2560001",
  },
  {
    id: 133864,
    name: "Raiwala Bara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.32704,
    longitude: 78.57429,
    wikiDataId: "Q2717652",
  },
  {
    id: 133865,
    name: "Vaikam",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.68581,
    longitude: 76.33996,
    wikiDataId: "Q2717652",
  },
  {
    id: 133866,
    name: "Sira",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.99053,
    longitude: 75.22499,
    wikiDataId: "Q2717652",
  },
  {
    id: 133867,
    name: "Rudraprayag",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.108,
    longitude: 78.58661,
    wikiDataId: "Q2717915",
  },
  {
    id: 133868,
    name: "Sirsi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.2698,
    longitude: 75.35643,
    wikiDataId: "Q2717915",
  },
  {
    id: 133869,
    name: "Rishikesh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.28072,
    longitude: 78.01411,
    wikiDataId: "Q3959470",
  },
  {
    id: 133870,
    name: "Mokokchung",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.56892,
    longitude: 91.88313,
    wikiDataId: "Q207752",
  },
  {
    id: 133871,
    name: "Theog",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.16667,
    longitude: 77.58333,
    wikiDataId: "Q1921404",
  },
  {
    id: 133872,
    name: "Siruguppa",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.05,
    longitude: 75.16,
    wikiDataId: "Q2981389",
  },
  {
    id: 133873,
    name: "Soygaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.61875,
    longitude: 74.70907,
    wikiDataId: "Q26795347",
  },
  {
    id: 133874,
    name: "Surgana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.76616,
    longitude: 74.47738,
    wikiDataId: "Q1949112",
  },
  {
    id: 133875,
    name: "Taklibhan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.69589,
    longitude: 72.71527,
    wikiDataId: "Q26780636",
  },
  {
    id: 133876,
    name: "Someshwar",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.23352,
    longitude: 75.57996,
    wikiDataId: "Q2571876",
  },
  {
    id: 133877,
    name: "Talegaon Dabhade",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.34821,
    longitude: 74.88035,
    wikiDataId: "Q7498967",
  },
  {
    id: 133878,
    name: "Taloda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.15059,
    longitude: 73.97788,
    wikiDataId: "Q2544718",
  },
  {
    id: 133879,
    name: "Talode",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.53017,
    longitude: 73.85263,
    wikiDataId: "Q26793755",
  },
  {
    id: 133880,
    name: "Tendukheda",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.42378,
    longitude: 77.66223,
    wikiDataId: "Q2036966",
  },
  {
    id: 133882,
    name: "Sihor",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.42319,
    longitude: 73.60865,
    wikiDataId: "Q2299042",
  },
  {
    id: 133883,
    name: "Usilampatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.1181,
    longitude: 79.42025,
    wikiDataId: "Q2229686",
  },
  {
    id: 133884,
    name: "Somvarpet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.521,
    longitude: 76.75738,
    wikiDataId: "Q2449498",
  },
  {
    id: 133885,
    name: "Tehri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.50746,
    longitude: 82.0047,
    wikiDataId: "Q1945458",
  },
  {
    id: 133886,
    name: "Tarapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.61527,
    longitude: 74.69895,
    wikiDataId: "Q2566494",
  },
  {
    id: 133887,
    name: "Sorab",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.42264,
    longitude: 76.68439,
    wikiDataId: "Q2566494",
  },
  {
    id: 133888,
    name: "Teonthar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.40673,
    longitude: 76.7098,
    wikiDataId: "Q2720813",
  },
  {
    id: 133889,
    name: "Thanna Mandi",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.73067,
    longitude: 74.81869,
    wikiDataId: "Q2073646",
  },
  {
    id: 133891,
    name: "Sohna",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.12368,
    longitude: 76.40516,
    wikiDataId: "Q3811046",
  },
  {
    id: 133892,
    name: "Lohaghat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.04965,
    longitude: 82.68423,
    wikiDataId: "Q2717661",
  },
  {
    id: 133893,
    name: "Sultanpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.31365,
    longitude: 79.11806,
    wikiDataId: "Q2720736",
  },
  {
    id: 133894,
    name: "Manglaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.55023,
    longitude: 79.31761,
    wikiDataId: "Q3958997",
  },
  {
    id: 133895,
    name: "Mussoorie",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28,
    longitude: 79.83333,
    wikiDataId: "Q2078241",
  },
  {
    id: 133897,
    name: "Bayana",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.08173,
    longitude: 75.33708,
    wikiDataId: "Q2545272",
  },
  {
    id: 133898,
    name: "Sindhnur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.69605,
    longitude: 76.8422,
    wikiDataId: "Q3958999",
  },
  {
    id: 133899,
    name: "Susner",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.23742,
    longitude: 76.22558,
    wikiDataId: "Q3959001",
  },
  {
    id: 133900,
    name: "Siddhpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.15611,
    longitude: 70.77068,
    wikiDataId: "Q3959001",
  },
  {
    id: 133901,
    name: "Naini Tal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.3501,
    longitude: 77.5516,
    wikiDataId: "Q3959003",
  },
  {
    id: 133902,
    name: "Shahpur",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.60293,
    longitude: 84.40412,
    wikiDataId: "Q1925497",
  },
  {
    id: 133903,
    name: "Anamalais",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.62094,
    longitude: 74.92487,
    wikiDataId: "Q3959004",
  },
  {
    id: 133904,
    name: "Tal",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.13663,
    longitude: 79.66402,
    wikiDataId: "Q3959004",
  },
  {
    id: 133905,
    name: "Lansdowne",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.6431,
    longitude: 79.9402,
    wikiDataId: "Q1748735",
  },
  {
    id: 133906,
    name: "Sonipat",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.16776,
    longitude: 76.87046,
    wikiDataId: "Q1748735",
  },
  {
    id: 133907,
    name: "Sindgi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.1307,
    longitude: 76.94361,
    wikiDataId: "Q2738743",
  },
  {
    id: 133908,
    name: "Sivala, East Godavari district",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.54538,
    longitude: 74.47106,
    wikiDataId: "Q2738743",
  },
  {
    id: 133909,
    name: "Solapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.45231,
    longitude: 73.32572,
    wikiDataId: "Q589527",
  },
  {
    id: 133910,
    name: "Talen",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.5,
    longitude: 76.25,
    wikiDataId: "Q2449803",
  },
  {
    id: 133911,
    name: "Beawar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.50028,
    longitude: 75.74917,
    wikiDataId: "Q2449803",
  },
  {
    id: 133912,
    name: "Tarana",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.18817,
    longitude: 75.63903,
    wikiDataId: "Q2546410",
  },
  {
    id: 133913,
    name: "Narendranagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.4497,
    longitude: 77.30959,
    wikiDataId: "Q2450994",
  },
  {
    id: 133914,
    name: "Sitarganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.72928,
    longitude: 79.31469,
    wikiDataId: "Q2717725",
  },
  {
    id: 133915,
    name: "Varkala",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.76181,
    longitude: 76.27078,
    wikiDataId: "Q2720784",
  },
  {
    id: 133916,
    name: "Sibsagar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.98427,
    longitude: 94.63784,
    wikiDataId: "Q1284453",
  },
  {
    id: 133918,
    name: "Tehri-Garhwal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.25797,
    longitude: 83.01465,
    wikiDataId: "Q15261172",
  },
  {
    id: 133919,
    name: "Auras",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.10483,
    longitude: 78.84858,
    wikiDataId: "Q2572203",
  },
  {
    id: 133920,
    name: "Sravana Belgola",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.34322,
    longitude: 74.894,
    wikiDataId: "Q2571834",
  },
  {
    id: 133921,
    name: "Uttarkashi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.28202,
    longitude: 80.8345,
    wikiDataId: "Q2717642",
  },
  {
    id: 133922,
    name: "Thandla",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.25,
    longitude: 82,
    wikiDataId: "Q2449793",
  },
  {
    id: 133923,
    name: "Vikasnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.63312,
    longitude: 78.86918,
    wikiDataId: "Q2733455",
  },
  {
    id: 133924,
    name: "Sringeri",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.38896,
    longitude: 77.86444,
    wikiDataId: "Q2733455",
  },
  {
    id: 133925,
    name: "Sinor",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.71134,
    longitude: 71.96179,
    wikiDataId: "Q2572958",
  },
  {
    id: 133926,
    name: "Tikamgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.4871,
    longitude: 80.10404,
    wikiDataId: "Q2546438",
  },
  {
    id: 133927,
    name: "Alnavar",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.77617,
    longitude: 86.33028,
    wikiDataId: "Q1925470",
  },
  {
    id: 133928,
    name: "Aistala",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.04327,
    longitude: 84.05298,
    wikiDataId: "Q26780648",
  },
  {
    id: 133929,
    name: "Ahmedpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.45226,
    longitude: 77.70004,
    wikiDataId: "Q2717646",
  },
  {
    id: 133930,
    name: "Aknapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.36722,
    longitude: 79.6298,
    wikiDataId: "Q521637",
  },
  {
    id: 133931,
    name: "Alipurduar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.68859,
    longitude: 78.37985,
    wikiDataId: "Q2717618",
  },
  {
    id: 133932,
    name: "Sojitra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.43218,
    longitude: 69.84158,
    wikiDataId: "Q1617473",
  },
  {
    id: 133933,
    name: "Silao",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.08358,
    longitude: 85.42804,
    wikiDataId: "Q1617473",
  },
  {
    id: 133934,
    name: "Silapathar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.59441,
    longitude: 94.72402,
    wikiDataId: "Q1617473",
  },
  {
    id: 133935,
    name: "Silchar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.82733,
    longitude: 92.79787,
    wikiDataId: "Q944769",
  },
  {
    id: 133936,
    name: "Tardeo",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.30303,
    longitude: 75.65284,
    wikiDataId: "Q2450958",
  },
  {
    id: 133937,
    name: "Silvassa",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.27386,
    longitude: 72.99673,
    wikiDataId: "Q318404",
  },
  {
    id: 133938,
    name: "Tirodi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.79497,
    longitude: 81.152,
    wikiDataId: "Q2720724",
  },
  {
    id: 133939,
    name: "Alur",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.61523,
    longitude: 84.50208,
    wikiDataId: "Q2483929",
  },
  {
    id: 133940,
    name: "Simga",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.6281,
    longitude: 81.70376,
    wikiDataId: "Q2734480",
  },
  {
    id: 133941,
    name: "Srinivaspur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.91883,
    longitude: 76.23368,
    wikiDataId: "Q46566",
  },
  {
    id: 133942,
    name: "Sulya",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.76983,
    longitude: 76.75581,
    wikiDataId: "Q2571844",
  },
  {
    id: 133943,
    name: "Telhara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.17,
    longitude: 73.7,
    wikiDataId: "Q768332",
  },
  {
    id: 133944,
    name: "Thalner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.80509,
    longitude: 78.88752,
    wikiDataId: "Q1946403",
  },
  {
    id: 133945,
    name: "Uttiramerur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.75947,
    longitude: 80.0075,
    wikiDataId: "Q2551291",
  },
  {
    id: 133946,
    name: "Singarayakonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.23046,
    longitude: 80.02794,
    wikiDataId: "Q720856",
  },
  {
    id: 133947,
    name: "Udaipura",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.96667,
    longitude: 75.3,
    wikiDataId: "Q2722287",
  },
  {
    id: 133948,
    name: "Ujjain",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.19973,
    longitude: 82.67535,
    wikiDataId: "Q255780",
  },
  {
    id: 133949,
    name: "Chidambaram",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.23467,
    longitude: 88.50168,
    wikiDataId: "Q255780",
  },
  {
    id: 133950,
    name: "Uttamapalaiyam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.99898,
    longitude: 77.03238,
    wikiDataId: "Q255780",
  },
  {
    id: 133951,
    name: "Ayodhya",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.46982,
    longitude: 78.12574,
    wikiDataId: "Q255780",
  },
  {
    id: 133952,
    name: "Anekal",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.79325,
    longitude: 85.94543,
    wikiDataId: "Q3961862",
  },
  {
    id: 133953,
    name: "Trimbak",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.84505,
    longitude: 73.99866,
    wikiDataId: "Q2724534",
  },
  {
    id: 133954,
    name: "Songadh",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.91117,
    longitude: 73.33974,
    wikiDataId: "Q2773921",
  },
  {
    id: 133955,
    name: "Begun",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.64321,
    longitude: 76.38421,
    wikiDataId: "Q2672955",
  },
  {
    id: 133956,
    name: "Ukwa",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.83648,
    longitude: 81.36448,
    wikiDataId: "Q1928918",
  },
  {
    id: 133957,
    name: "Tira Sujanpur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.75,
    longitude: 77.5,
    wikiDataId: "Q654331",
  },
  {
    id: 133958,
    name: "Annavasal",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.83333,
    longitude: 72.75,
    wikiDataId: "Q205719",
  },
  {
    id: 133959,
    name: "Umaria",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.10313,
    longitude: 77.69055,
    wikiDataId: "Q720147",
  },
  {
    id: 133960,
    name: "Azamgarh",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.47953,
    longitude: 79.57558,
    wikiDataId: "Q7530597",
  },
  {
    id: 133961,
    name: "Taoru",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.53489,
    longitude: 75.02898,
    wikiDataId: "Q1797298",
  },
  {
    id: 133962,
    name: "Talikota",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.62072,
    longitude: 74.83554,
    wikiDataId: "Q959109",
  },
  {
    id: 133963,
    name: "Arambagh community development block",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.63916,
    longitude: 78.64303,
    wikiDataId: "Q956754",
  },
  {
    id: 133964,
    name: "Azizpur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.38865,
    longitude: 78.81048,
    wikiDataId: "Q2563435",
  },
  {
    id: 133965,
    name: "Amtala",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.2634,
    longitude: 82.0919,
    wikiDataId: "Q3962064",
  },
  {
    id: 133966,
    name: "Andal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.05715,
    longitude: 78.68661,
    wikiDataId: "Q2717329",
  },
  {
    id: 133967,
    name: "Tarikere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.63,
    longitude: 76.89217,
    wikiDataId: "Q2717329",
  },
  {
    id: 133968,
    name: "V.S.K.Valasai (Dindigul-Dist.)",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.32137,
    longitude: 77.00521,
    wikiDataId: "Q2231112",
  },
  {
    id: 133969,
    name: "Amta",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.64292,
    longitude: 81.31855,
    wikiDataId: "Q2717592",
  },
  {
    id: 133970,
    name: "Tumsar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.8276,
    longitude: 74.37475,
    wikiDataId: "Q2717592",
  },
  {
    id: 133971,
    name: "Asansol",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.41386,
    longitude: 77.4689,
    wikiDataId: "Q2717503",
  },
  {
    id: 133972,
    name: "Ashoknagar Kalyangarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.14652,
    longitude: 83.75803,
    wikiDataId: "Q2717503",
  },
  {
    id: 133973,
    name: "Haldia",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.9293,
    longitude: 79.70436,
    wikiDataId: "Q2720109",
  },
  {
    id: 133974,
    name: "Vadakku Valliyur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.92762,
    longitude: 78.53763,
    wikiDataId: "Q15195",
  },
  {
    id: 133975,
    name: "Vadakku Viravanallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.34461,
    longitude: 77.42911,
    wikiDataId: "Q3533189",
  },
  {
    id: 133976,
    name: "Vadamadurai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.44999,
    longitude: 77.79797,
    wikiDataId: "Q2061870",
  },
  {
    id: 133977,
    name: "Siwan",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.22096,
    longitude: 84.35609,
    wikiDataId: "Q2234098",
  },
  {
    id: 133978,
    name: "Annur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.65154,
    longitude: 72.42243,
    wikiDataId: "Q7532533",
  },
  {
    id: 133979,
    name: "Soalkuchi",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.16806,
    longitude: 91.57111,
    wikiDataId: "Q249311",
  },
  {
    id: 133980,
    name: "Thanesar",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.24737,
    longitude: 77.06544,
    wikiDataId: "Q2557903",
  },
  {
    id: 133981,
    name: "Umri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.98181,
    longitude: 81.69558,
    wikiDataId: "Q2557903",
  },
  {
    id: 133982,
    name: "Unhel",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.70055,
    longitude: 78.19522,
    wikiDataId: "Q2546550",
  },
  {
    id: 133983,
    name: "Anthiyur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.92493,
    longitude: 73.66633,
    wikiDataId: "Q2726340",
  },
  {
    id: 133984,
    name: "Supedi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.53884,
    longitude: 72.71984,
    wikiDataId: "Q7555023",
  },
  {
    id: 133985,
    name: "Yol",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.08333,
    longitude: 76.83333,
    wikiDataId: "Q2980937",
  },
  {
    id: 133986,
    name: "Ulhasnagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.75,
    longitude: 75.5,
    wikiDataId: "Q237847",
  },
  {
    id: 133987,
    name: "Solim",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.61521,
    longitude: 73.7674,
    wikiDataId: "Q2734452",
  },
  {
    id: 133989,
    name: "Tekkalakote",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.49112,
    longitude: 75.06646,
    wikiDataId: "Q607071",
  },
  {
    id: 133990,
    name: "Sompeta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.94419,
    longitude: 84.58449,
    wikiDataId: "Q607071",
  },
  {
    id: 133991,
    name: "Terdal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.59698,
    longitude: 75.84957,
    wikiDataId: "Q2738785",
  },
  {
    id: 133992,
    name: "Baduria",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.40212,
    longitude: 83.05352,
    wikiDataId: "Q607798",
  },
  {
    id: 133993,
    name: "Umarkhed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.62915,
    longitude: 78.69207,
    wikiDataId: "Q26780008",
  },
  {
    id: 133994,
    name: "Barnala",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83333,
    longitude: 83.91667,
    wikiDataId: "Q1026366",
  },
  {
    id: 133995,
    name: "Surat",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.16966,
    longitude: 73.56357,
    wikiDataId: "Q2734240",
  },
  {
    id: 133996,
    name: "Sonitpur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.76748,
    longitude: 92.96425,
    wikiDataId: "Q2734240",
  },
  {
    id: 133997,
    name: "Banbasa",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.47547,
    longitude: 91.2659,
    wikiDataId: "Q2733123",
  },
  {
    id: 133998,
    name: "Sonari",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.02462,
    longitude: 95.01629,
    wikiDataId: "Q2737021",
  },
  {
    id: 133999,
    name: "Tohana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29,
    longitude: 76.91667,
    wikiDataId: "Q2241746",
  },
  {
    id: 134000,
    name: "Tral",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.28671,
    longitude: 74.47228,
    wikiDataId: "Q2321929",
  },
  {
    id: 134001,
    name: "Tiptur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.38144,
    longitude: 75.09183,
    wikiDataId: "Q2738723",
  },
  {
    id: 134002,
    name: "Batala",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.76082,
    longitude: 84.42997,
    wikiDataId: "Q2545590",
  },
  {
    id: 134003,
    name: "Sorbhog",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.48612,
    longitude: 90.8859,
    wikiDataId: "Q2545590",
  },
  {
    id: 134004,
    name: "Begowal",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.27851,
    longitude: 86.68833,
    wikiDataId: "Q3965109",
  },
  {
    id: 134005,
    name: "Bagdogra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.89055,
    longitude: 78.74621,
    wikiDataId: "Q2717515",
  },
  {
    id: 134006,
    name: "South Andaman",
    state_id: 4023,
    state_code: "AN",
    state_name: "Andaman and Nicobar Islands",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.75776,
    longitude: 92.52136,
    wikiDataId: "Q796979",
  },
  {
    id: 134007,
    name: "South Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.53009,
    longitude: 77.25174,
    wikiDataId: "Q2061938",
  },
  {
    id: 134008,
    name: "Chingleput",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.33333,
    longitude: 88.41667,
    wikiDataId: "Q2061938",
  },
  {
    id: 134009,
    name: "Mon",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.30162,
    longitude: 90.5853,
    wikiDataId: "Q2329228",
  },
  {
    id: 134010,
    name: "South Goa",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.20425,
    longitude: 74.16733,
    wikiDataId: "Q108244",
  },
  {
    id: 134011,
    name: "Bangarmau",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.1671,
    longitude: 91.60953,
    wikiDataId: "Q1822159",
  },
  {
    id: 134012,
    name: "South West Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.5806,
    longitude: 77.0672,
    wikiDataId: "Q2379189",
  },
  {
    id: 134013,
    name: "Peren",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.47245,
    longitude: 89.93399,
    wikiDataId: "Q15961576",
  },
  {
    id: 134014,
    name: "Tuensang",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.32155,
    longitude: 91.29462,
    wikiDataId: "Q15923741",
  },
  {
    id: 134015,
    name: "Uruli Kanchan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.59606,
    longitude: 75.61765,
    wikiDataId: "Q26780029",
  },
  {
    id: 134016,
    name: "Tsrar Sharif",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.07677,
    longitude: 74.7057,
    wikiDataId: "Q15923741",
  },
  {
    id: 134017,
    name: "Arakkonam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.09617,
    longitude: 74.00868,
    wikiDataId: "Q1525583",
  },
  {
    id: 134018,
    name: "Arantangi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.46599,
    longitude: 75.59736,
    wikiDataId: "Q967961",
  },
  {
    id: 134019,
    name: "Srikakulam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.2989,
    longitude: 83.89751,
    wikiDataId: "Q671757",
  },
  {
    id: 134020,
    name: "Udhampur",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.08565,
    longitude: 74.80555,
    wikiDataId: "Q170115",
  },
  {
    id: 134021,
    name: "Tirumakudal Narsipur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.41698,
    longitude: 75.25271,
    wikiDataId: "Q170115",
  },
  {
    id: 134022,
    name: "Valavanur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.62931,
    longitude: 77.91281,
    wikiDataId: "Q2715777",
  },
  {
    id: 134023,
    name: "Vallam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.51272,
    longitude: 77.63369,
    wikiDataId: "Q2562483",
  },
  {
    id: 134024,
    name: "Tirthahalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.85737,
    longitude: 76.48886,
    wikiDataId: "Q1026193",
  },
  {
    id: 134026,
    name: "Vadippatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.40118,
    longitude: 79.40384,
    wikiDataId: "Q2716515",
  },
  {
    id: 134027,
    name: "Haldibari",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.22243,
    longitude: 78.78341,
    wikiDataId: "Q2548709",
  },
  {
    id: 134029,
    name: "Tumakuru",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.33914,
    longitude: 78.21175,
    wikiDataId: "Q2552087",
  },
  {
    id: 134030,
    name: "Valangaiman",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.96763,
    longitude: 79.94197,
    wikiDataId: "Q2547993",
  },
  {
    id: 134031,
    name: "Baberu",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.26652,
    longitude: 78.25544,
    wikiDataId: "Q2547993",
  },
  {
    id: 134032,
    name: "Srisailain",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.07217,
    longitude: 78.86816,
    wikiDataId: "Q2269015",
  },
  {
    id: 134033,
    name: "Vada",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.04592,
    longitude: 73.01552,
    wikiDataId: "Q2269015",
  },
  {
    id: 134034,
    name: "Bhadaur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.93154,
    longitude: 83.82486,
    wikiDataId: "Q1473957",
  },
  {
    id: 134035,
    name: "Valparai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.15442,
    longitude: 77.46704,
    wikiDataId: "Q2548004",
  },
  {
    id: 134036,
    name: "Arimalam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.64609,
    longitude: 76.0417,
    wikiDataId: "Q2718626",
  },
  {
    id: 134037,
    name: "Bhadasar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.21468,
    longitude: 75.19602,
    wikiDataId: "Q2545249",
  },
  {
    id: 134038,
    name: "Halisahar",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.75534,
    longitude: 78.11034,
    wikiDataId: "Q2545249",
  },
  {
    id: 134039,
    name: "Vidisha",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.13812,
    longitude: 77.93404,
    wikiDataId: "Q3976891",
  },
  {
    id: 134040,
    name: "Bagula",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.25,
    longitude: 82,
    wikiDataId: "Q1356154",
  },
  {
    id: 134041,
    name: "Vandalur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.02427,
    longitude: 77.12565,
    wikiDataId: "Q2716112",
  },
  {
    id: 134042,
    name: "Turuvekere",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.561,
    longitude: 75.38741,
    wikiDataId: "Q2571896",
  },
  {
    id: 134043,
    name: "Uri",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.23072,
    longitude: 74.6472,
    wikiDataId: "Q3976899",
  },
  {
    id: 134044,
    name: "Bhatinda",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.25,
    longitude: 84.5,
    wikiDataId: "Q2296047",
  },
  {
    id: 134045,
    name: "Akhnur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.53523,
    longitude: 76.905,
    wikiDataId: "Q972593",
  },
  {
    id: 134046,
    name: "Ariyalur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.37065,
    longitude: 75.95708,
    wikiDataId: "Q7639941",
  },
  {
    id: 134047,
    name: "Udupi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.45594,
    longitude: 75.8297,
    wikiDataId: "Q7639941",
  },
  {
    id: 134048,
    name: "Bhadra",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.12883,
    longitude: 75.79943,
    wikiDataId: "Q512825",
  },
  {
    id: 134049,
    name: "Supaul",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.25,
    longitude: 86.8,
    wikiDataId: "Q100139",
  },
  {
    id: 134050,
    name: "Talaja",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.72706,
    longitude: 71.64856,
    wikiDataId: "Q1023682",
  },
  {
    id: 134051,
    name: "Surguja",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.89624,
    longitude: 83.09631,
    wikiDataId: "Q1805075",
  },
  {
    id: 134052,
    name: "Vadgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.55956,
    longitude: 73.63747,
    wikiDataId: "Q2341453",
  },
  {
    id: 134053,
    name: "Bahula",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.46387,
    longitude: 82.41922,
    wikiDataId: "Q2341453",
  },
  {
    id: 134054,
    name: "Babina",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.14054,
    longitude: 79.62045,
    wikiDataId: "Q2573090",
  },
  {
    id: 134055,
    name: "Waraseoni",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.94667,
    longitude: 76.08825,
    wikiDataId: "Q426442",
  },
  {
    id: 134056,
    name: "Bagnan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.02841,
    longitude: 79.05654,
    wikiDataId: "Q3976469",
  },
  {
    id: 134057,
    name: "Vaniyambadi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.95747,
    longitude: 79.32931,
    wikiDataId: "Q771388",
  },
  {
    id: 134058,
    name: "Alanganallur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.18555,
    longitude: 73.45288,
    wikiDataId: "Q2551307",
  },
  {
    id: 134059,
    name: "Zamania",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.67117,
    longitude: 83.30269,
    wikiDataId: "Q1925358",
  },
  {
    id: 134060,
    name: "Seram",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.16498,
    longitude: 75.02901,
    wikiDataId: "Q1925358",
  },
  {
    id: 134061,
    name: "Shahgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.75,
    longitude: 78.75,
    wikiDataId: "Q1854670",
  },
  {
    id: 134063,
    name: "Baragarh",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.97187,
    longitude: 92.57454,
    wikiDataId: "Q2726255",
  },
  {
    id: 134064,
    name: "Alangayam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.13524,
    longitude: 74.04442,
    wikiDataId: "Q2544199",
  },
  {
    id: 134065,
    name: "Salur",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.51716,
    longitude: 83.20548,
    wikiDataId: "Q2563482",
  },
  {
    id: 134066,
    name: "Samalkot",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.05675,
    longitude: 82.17639,
    wikiDataId: "Q2563482",
  },
  {
    id: 134068,
    name: "Alangudi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.90806,
    longitude: 75.19137,
    wikiDataId: "Q1925370",
  },
  {
    id: 134069,
    name: "Sankheda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.99227,
    longitude: 72.38177,
    wikiDataId: "Q2228906",
  },
  {
    id: 134070,
    name: "Shamgarh",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.48646,
    longitude: 77.7378,
    wikiDataId: "Q2228906",
  },
  {
    id: 134071,
    name: "Alappakkam",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.75361,
    longitude: 71.7728,
    wikiDataId: "Q2228906",
  },
  {
    id: 134072,
    name: "Doiwala",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.28867,
    longitude: 79.9519,
    wikiDataId: "Q2228906",
  },
  {
    id: 134074,
    name: "Shiraguppi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.43948,
    longitude: 75.19379,
    wikiDataId: "Q429215",
  },
  {
    id: 134075,
    name: "Sheopur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.97415,
    longitude: 75.8271,
    wikiDataId: "Q429215",
  },
  {
    id: 134076,
    name: "Saran",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.91667,
    longitude: 84.75,
    wikiDataId: "Q100146",
  },
  {
    id: 134077,
    name: "Sarangarh",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.58614,
    longitude: 83.0785,
    wikiDataId: "Q2317301",
  },
  {
    id: 134078,
    name: "Shivpuri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.56651,
    longitude: 76.47306,
    wikiDataId: "Q2317301",
  },
  {
    id: 134079,
    name: "Aland",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.81813,
    longitude: 85.99628,
    wikiDataId: "Q2317301",
  },
  {
    id: 134080,
    name: "Kashipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.70287,
    longitude: 78.08278,
    wikiDataId: "Q2717855",
  },
  {
    id: 134081,
    name: "Shirud",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.34351,
    longitude: 74.03102,
    wikiDataId: "Q2544796",
  },
  {
    id: 134082,
    name: "Sindhudurg",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.90413,
    longitude: 73.82191,
    wikiDataId: "Q1130165",
  },
  {
    id: 134083,
    name: "Sindi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.15054,
    longitude: 75.88938,
    wikiDataId: "Q3238579",
  },
  {
    id: 134084,
    name: "Udumalaippettai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.16925,
    longitude: 78.44702,
    wikiDataId: "Q2716110",
  },
  {
    id: 134085,
    name: "Shivrajpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.54925,
    longitude: 71.48324,
    wikiDataId: "Q2775259",
  },
  {
    id: 134086,
    name: "Annamalainagar",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.61206,
    longitude: 75.13996,
    wikiDataId: "Q33334",
  },
  {
    id: 134087,
    name: "Suntikoppa",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.74155,
    longitude: 76.9043,
    wikiDataId: "Q33334",
  },
  {
    id: 134088,
    name: "Uttukkuli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.23725,
    longitude: 79.73585,
    wikiDataId: "Q1278427",
  },
  {
    id: 134089,
    name: "Sitamarhi",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.66667,
    longitude: 85.5,
    wikiDataId: "Q1278427",
  },
  {
    id: 134090,
    name: "Umaria District",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.01473,
    longitude: 75.35324,
    wikiDataId: "Q1815885",
  },
  {
    id: 134091,
    name: "Badkulla",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5,
    longitude: 80.91667,
    wikiDataId: "Q922701",
  },
  {
    id: 134092,
    name: "Arcot",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.7,
    longitude: 74.46667,
    wikiDataId: "Q2248468",
  },
  {
    id: 134093,
    name: "Suluru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.7,
    longitude: 80.01667,
    wikiDataId: "Q2248468",
  },
  {
    id: 134094,
    name: "Arni",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.31005,
    longitude: 75.73271,
    wikiDataId: "Q1818140",
  },
  {
    id: 134095,
    name: "Vandavasi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.97574,
    longitude: 77.41923,
    wikiDataId: "Q2715986",
  },
  {
    id: 134096,
    name: "Sutrapada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.17801,
    longitude: 72.81189,
    wikiDataId: "Q4629",
  },
  {
    id: 134097,
    name: "Arumbavur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.3215,
    longitude: 73.89979,
    wikiDataId: "Q2566526",
  },
  {
    id: 134098,
    name: "Arumuganeri",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.32235,
    longitude: 73.00487,
    wikiDataId: "Q2720802",
  },
  {
    id: 134099,
    name: "Takhatpur",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.12915,
    longitude: 81.86959,
    wikiDataId: "Q2734491",
  },
  {
    id: 134100,
    name: "Vairag",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.73502,
    longitude: 73.67561,
    wikiDataId: "Q2719127",
  },
  {
    id: 134101,
    name: "Taleigao",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.46915,
    longitude: 73.83285,
    wikiDataId: "Q7679155",
  },
  {
    id: 134102,
    name: "Adawad",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.56949,
    longitude: 76.72821,
    wikiDataId: "Q2720317",
  },
  {
    id: 134103,
    name: "Vayalar",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.04161,
    longitude: 75.35927,
    wikiDataId: "Q2568358",
  },
  {
    id: 134104,
    name: "Vangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.56128,
    longitude: 74.21238,
    wikiDataId: "Q26780072",
  },
  {
    id: 134105,
    name: "Bharatpur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.85584,
    longitude: 74.92979,
    wikiDataId: "Q2568358",
  },
  {
    id: 134106,
    name: "Bhasawar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.9376,
    longitude: 75.88657,
    wikiDataId: "Q2465707",
  },
  {
    id: 134107,
    name: "Tapi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.3527,
    longitude: 72.03524,
    wikiDataId: "Q766933",
  },
  {
    id: 134108,
    name: "Vasudevanallur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9246,
    longitude: 80.12707,
    wikiDataId: "Q2229754",
  },
  {
    id: 134109,
    name: "Darlawn",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.97548,
    longitude: 93.51563,
    wikiDataId: "Q2301717",
  },
  {
    id: 134110,
    name: "Harindanga",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.074,
    longitude: 80.11139,
    wikiDataId: "Q2317343",
  },
  {
    id: 134111,
    name: "Vattalkundu",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.78523,
    longitude: 79.13909,
    wikiDataId: "Q41496",
  },
  {
    id: 134112,
    name: "Than",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.65622,
    longitude: 70.74945,
    wikiDataId: "Q7683522",
  },
  {
    id: 134113,
    name: "Tanuku",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.75438,
    longitude: 81.68143,
    wikiDataId: "Q2566481",
  },
  {
    id: 134114,
    name: "Thangadh",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.12,
    longitude: 73.4,
    wikiDataId: "Q670165",
  },
  {
    id: 134115,
    name: "Bhikhi",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.73252,
    longitude: 83.67443,
    wikiDataId: "Q132295",
  },
  {
    id: 134116,
    name: "Uttar Kannada",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.70954,
    longitude: 75.81382,
    wikiDataId: "Q2552592",
  },
  {
    id: 134118,
    name: "Bhilwara",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.45191,
    longitude: 74.92777,
    wikiDataId: "Q2341728",
  },
  {
    id: 134119,
    name: "Agar Panchaitan",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.33383,
    longitude: 76.04253,
    wikiDataId: "Q2248410",
  },
  {
    id: 134120,
    name: "Tawang",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.57417,
    longitude: 91.92437,
    wikiDataId: "Q1006270",
  },
  {
    id: 134121,
    name: "Teghra",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.49043,
    longitude: 85.94001,
    wikiDataId: "Q1006270",
  },
  {
    id: 134122,
    name: "Haringhata",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.39086,
    longitude: 78.4803,
    wikiDataId: "Q2249856",
  },
  {
    id: 134123,
    name: "Haripur",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.5,
    longitude: 78.66667,
    wikiDataId: "Q1357107",
  },
  {
    id: 134124,
    name: "Aheri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.99401,
    longitude: 78.28322,
    wikiDataId: "Q2552125",
  },
  {
    id: 134125,
    name: "Vadigenhalli",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.53444,
    longitude: 76.87703,
    wikiDataId: "Q429171",
  },
  {
    id: 134126,
    name: "Tekkali",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.6057,
    longitude: 84.23546,
    wikiDataId: "Q429171",
  },
  {
    id: 134127,
    name: "Tekari",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.94253,
    longitude: 84.84265,
    wikiDataId: "Q2733597",
  },
  {
    id: 134128,
    name: "Velas, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.02694,
    longitude: 76.83889,
    wikiDataId: "Q2544731",
  },
  {
    id: 134129,
    name: "Vettur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.74811,
    longitude: 75.4929,
    wikiDataId: "Q367354",
  },
  {
    id: 134130,
    name: "Ahiri",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.3962,
    longitude: 79.53947,
    wikiDataId: "Q384228",
  },
  {
    id: 134132,
    name: "Ahmadnagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.98207,
    longitude: 81.64194,
    wikiDataId: "Q2546511",
  },
  {
    id: 134133,
    name: "Vijayapura",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.49379,
    longitude: 75.04667,
    wikiDataId: "Q2552503",
  },
  {
    id: 134134,
    name: "Tezpur",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.63333,
    longitude: 92.8,
    wikiDataId: "Q1021162",
  },
  {
    id: 134135,
    name: "Tezu",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.91256,
    longitude: 96.12882,
    wikiDataId: "Q13221",
  },
  {
    id: 134136,
    name: "Balurghat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.19203,
    longitude: 78.86145,
    wikiDataId: "Q2716924",
  },
  {
    id: 134137,
    name: "Ahmadpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.00959,
    longitude: 74.57747,
    wikiDataId: "Q530401",
  },
  {
    id: 134138,
    name: "Vengurla",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.33333,
    longitude: 73.25,
    wikiDataId: "Q207749",
  },
  {
    id: 134139,
    name: "Bagra",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.9274,
    longitude: 76.79336,
    wikiDataId: "Q943099",
  },
  {
    id: 134140,
    name: "Velankanni",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.64,
    longitude: 79.22,
    wikiDataId: "Q15194",
  },
  {
    id: 134141,
    name: "Barka Kana",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.54204,
    longitude: 74.381,
    wikiDataId: "Q15194",
  },
  {
    id: 134142,
    name: "Wayanad",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.41667,
    longitude: 76.13333,
    wikiDataId: "Q15194",
  },
  {
    id: 134143,
    name: "Vellanur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.02764,
    longitude: 79.85425,
    wikiDataId: "Q732594",
  },
  {
    id: 134144,
    name: "The Dangs",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.39597,
    longitude: 71.62577,
    wikiDataId: "Q2557742",
  },
  {
    id: 134145,
    name: "Umrala",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75,
    longitude: 73.75,
    wikiDataId: "Q2557742",
  },
  {
    id: 134146,
    name: "Vellore",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.85,
    longitude: 77.42,
    wikiDataId: "Q2557742",
  },
  {
    id: 134147,
    name: "Velur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.96003,
    longitude: 77.31525,
    wikiDataId: "Q259749",
  },
  {
    id: 134148,
    name: "Bargarh",
    state_id: 4036,
    state_code: "MZ",
    state_name: "Mizoram",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.31667,
    longitude: 92.75,
    wikiDataId: "Q2726305",
  },
  {
    id: 134149,
    name: "Anantnag",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.12155,
    longitude: 77.35838,
    wikiDataId: "Q2452060",
  },
  {
    id: 134150,
    name: "Vengavasal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.84431,
    longitude: 78.95647,
    wikiDataId: "Q2715717",
  },
  {
    id: 134151,
    name: "Vettaikkaranpudur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.17594,
    longitude: 79.61637,
    wikiDataId: "Q2715717",
  },
  {
    id: 134152,
    name: "Vettavalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.88405,
    longitude: 79.10362,
    wikiDataId: "Q2229514",
  },
  {
    id: 134153,
    name: "Vijayapuri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.25,
    longitude: 80,
    wikiDataId: "Q2229514",
  },
  {
    id: 134154,
    name: "Kargil",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.60399,
    longitude: 76.98574,
    wikiDataId: "Q162612",
  },
  {
    id: 134155,
    name: "Vikravandi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.77269,
    longitude: 79.6368,
    wikiDataId: "Q1025399",
  },
  {
    id: 134156,
    name: "Vilattikulam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.99857,
    longitude: 79.45227,
    wikiDataId: "Q1025399",
  },
  {
    id: 134158,
    name: "Villupuram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.76735,
    longitude: 78.13425,
    wikiDataId: "Q41562",
  },
  {
    id: 134159,
    name: "Khawhai",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.62205,
    longitude: 94.01001,
    wikiDataId: "Q2086198",
  },
  {
    id: 134161,
    name: "Leh",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.51667,
    longitude: 76.21667,
    wikiDataId: "Q163798",
  },
  {
    id: 134162,
    name: "Kavaratti",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.52022,
    longitude: 76.2204,
    wikiDataId: "Q2429655",
  },
  {
    id: 134163,
    name: "Thakurganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.42742,
    longitude: 88.13112,
    wikiDataId: "Q720796",
  },
  {
    id: 134164,
    name: "Tharad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.57422,
    longitude: 71.19942,
    wikiDataId: "Q2231030",
  },
  {
    id: 134165,
    name: "Bamangola community development block",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.58605,
    longitude: 77.41811,
    wikiDataId: "Q2231193",
  },
  {
    id: 134167,
    name: "Uklana",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.97323,
    longitude: 76.83214,
    wikiDataId: "Q2088448",
  },
  {
    id: 134168,
    name: "Umarpada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.79831,
    longitude: 73.21174,
    wikiDataId: "Q2759268",
  },
  {
    id: 134169,
    name: "Aruvankad",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.93411,
    longitude: 76.85541,
    wikiDataId: "Q2719176",
  },
  {
    id: 134170,
    name: "Bankra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.94612,
    longitude: 81.56583,
    wikiDataId: "Q26780117",
  },
  {
    id: 134171,
    name: "Bansberia",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.96282,
    longitude: 79.73827,
    wikiDataId: "Q2551283",
  },
  {
    id: 134172,
    name: "Viraganur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.234,
    longitude: 79.65551,
    wikiDataId: "Q2465629",
  },
  {
    id: 134173,
    name: "Barabazar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.61739,
    longitude: 80.52718,
    wikiDataId: "Q2717482",
  },
  {
    id: 134174,
    name: "Virudhunagar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.11448,
    longitude: 80.02713,
    wikiDataId: "Q2717482",
  },
  {
    id: 134175,
    name: "Tinsukia",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.6,
    longitude: 95.6,
    wikiDataId: "Q42756",
  },
  {
    id: 134176,
    name: "Virarajendrapet",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.2563,
    longitude: 76.47768,
    wikiDataId: "Q2475078",
  },
  {
    id: 134177,
    name: "Airoli",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.68522,
    longitude: 79.71906,
    wikiDataId: "Q1121279",
  },
  {
    id: 134178,
    name: "Vriddhachalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.49725,
    longitude: 78.11906,
    wikiDataId: "Q1792360",
  },
  {
    id: 134179,
    name: "Walajapet",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.38016,
    longitude: 77.89444,
    wikiDataId: "Q2229506",
  },
  {
    id: 134180,
    name: "Wallajahbad",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.97,
    longitude: 78.65,
    wikiDataId: "Q15201",
  },
  {
    id: 134181,
    name: "Wellington",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.9662,
    longitude: 79.20259,
    wikiDataId: "Q1113675",
  },
  {
    id: 134182,
    name: "Yadgir",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.21207,
    longitude: 76.9018,
    wikiDataId: "Q2248455",
  },
  {
    id: 134183,
    name: "Tirumala",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.68333,
    longitude: 79.35,
    wikiDataId: "Q3424247",
  },
  {
    id: 134184,
    name: "Adilabad",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.23996,
    longitude: 79.83705,
    wikiDataId: "Q3424247",
  },
  {
    id: 134185,
    name: "Andol",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.72742,
    longitude: 77.6838,
    wikiDataId: "Q213248",
  },
  {
    id: 134186,
    name: "Asifabad",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.7927,
    longitude: 77.57409,
    wikiDataId: "Q213248",
  },
  {
    id: 134187,
    name: "Tirupati",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.63551,
    longitude: 79.41989,
    wikiDataId: "Q213248",
  },
  {
    id: 134188,
    name: "Bellampalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.88151,
    longitude: 78.07306,
    wikiDataId: "Q213248",
  },
  {
    id: 134189,
    name: "Bhadrachalam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.11541,
    longitude: 77.35456,
    wikiDataId: "Q836527",
  },
  {
    id: 134190,
    name: "Bhadradri Kothagudem",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.82564,
    longitude: 78.25795,
    wikiDataId: "Q836527",
  },
  {
    id: 134191,
    name: "Banswada",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.54606,
    longitude: 78.91721,
    wikiDataId: "Q836527",
  },
  {
    id: 134192,
    name: "Lakshadweep",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.91368,
    longitude: 75.92118,
    wikiDataId: "Q2722267",
  },
  {
    id: 134193,
    name: "Bhaisa",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.48333,
    longitude: 77.83333,
    wikiDataId: "Q2722267",
  },
  {
    id: 134194,
    name: "Agar",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.3816,
    longitude: 76.57489,
    wikiDataId: "Q2336443",
  },
  {
    id: 134196,
    name: "Bhongir",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.51,
    longitude: 79.09,
    wikiDataId: "Q15207",
  },
  {
    id: 134198,
    name: "Bodhan",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.15823,
    longitude: 80.30181,
    wikiDataId: "Q265743",
  },
  {
    id: 134199,
    name: "Tirap",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.01917,
    longitude: 95.51788,
    wikiDataId: "Q15448",
  },
  {
    id: 134200,
    name: "Chatakonda",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.33702,
    longitude: 77.86776,
    wikiDataId: "Q15448",
  },
  {
    id: 134201,
    name: "Bhogpur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.28961,
    longitude: 83.15233,
    wikiDataId: "Q1411490",
  },
  {
    id: 134202,
    name: "Baranagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.66824,
    longitude: 77.32391,
    wikiDataId: "Q2717487",
  },
  {
    id: 134203,
    name: "Titabar",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.6014,
    longitude: 94.20386,
    wikiDataId: "Q2717487",
  },
  {
    id: 134204,
    name: "Attayyampatti",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.91667,
    longitude: 76.81667,
    wikiDataId: "Q2719210",
  },
  {
    id: 134205,
    name: "Attur",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.02401,
    longitude: 75.48182,
    wikiDataId: "Q2719131",
  },
  {
    id: 134206,
    name: "Yamunanagar",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.71332,
    longitude: 75.90441,
    wikiDataId: "Q527194",
  },
  {
    id: 134207,
    name: "Dasnapur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.74173,
    longitude: 79.01774,
    wikiDataId: "Q527194",
  },
  {
    id: 134208,
    name: "Auroville",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.16667,
    longitude: 75.58333,
    wikiDataId: "Q915880",
  },
  {
    id: 134209,
    name: "Ankola",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.90381,
    longitude: 86.19792,
    wikiDataId: "Q2735194",
  },
  {
    id: 134210,
    name: "Bardhaman",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.45505,
    longitude: 79.11428,
    wikiDataId: "Q11952191",
  },
  {
    id: 134211,
    name: "Arki",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.86993,
    longitude: 75.9165,
    wikiDataId: "Q2557953",
  },
  {
    id: 134212,
    name: "Vijaydurg",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.93268,
    longitude: 73.52907,
    wikiDataId: "Q26778822",
  },
  {
    id: 134213,
    name: "Barki Saria",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.92708,
    longitude: 75.11585,
    wikiDataId: "Q2483323",
  },
  {
    id: 134214,
    name: "Barwadih",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33.86319,
    longitude: 74.76524,
    wikiDataId: "Q2483323",
  },
  {
    id: 134215,
    name: "Bhadrakh",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.26704,
    longitude: 94.82415,
    wikiDataId: "Q2726329",
  },
  {
    id: 134216,
    name: "Bhanjanagar",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.25,
    longitude: 94.75,
    wikiDataId: "Q2571393",
  },
  {
    id: 134217,
    name: "Vile Parle",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.00804,
    longitude: 76.07011,
    wikiDataId: "Q2724627",
  },
  {
    id: 134218,
    name: "Barjora",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.5337,
    longitude: 82.41653,
    wikiDataId: "Q2543331",
  },
  {
    id: 134219,
    name: "Yelahanka",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.5,
    longitude: 77,
    wikiDataId: "Q1301635",
  },
  {
    id: 134221,
    name: "Vinchur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.38333,
    longitude: 79.73333,
    wikiDataId: "Q338371",
  },
  {
    id: 134222,
    name: "Tuni",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.35905,
    longitude: 82.54607,
    wikiDataId: "Q2576041",
  },
  {
    id: 134223,
    name: "Tuensang District",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.51421,
    longitude: 90.20239,
    wikiDataId: "Q2317186",
  },
  {
    id: 134224,
    name: "Devarkonda",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.14968,
    longitude: 78.5987,
    wikiDataId: "Q2317186",
  },
  {
    id: 134225,
    name: "Yelandur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.16374,
    longitude: 76.66641,
    wikiDataId: "Q2738731",
  },
  {
    id: 134226,
    name: "Tadepalle",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.48333,
    longitude: 80.6,
    wikiDataId: "Q2738731",
  },
  {
    id: 134227,
    name: "Tadepallegudem",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.81467,
    longitude: 81.52717,
    wikiDataId: "Q2235626",
  },
  {
    id: 134228,
    name: "Tadpatri",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.90832,
    longitude: 78.01031,
    wikiDataId: "Q2336339",
  },
  {
    id: 134229,
    name: "Baidyabati",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.16242,
    longitude: 78.48458,
    wikiDataId: "Q2336339",
  },
  {
    id: 134230,
    name: "Achalpur",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.71979,
    longitude: 75.38514,
    wikiDataId: "Q2336339",
  },
  {
    id: 134231,
    name: "Bakreswar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.04357,
    longitude: 78.43441,
    wikiDataId: "Q2336339",
  },
  {
    id: 134232,
    name: "Bhawanigarh",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.94927,
    longitude: 85.23354,
    wikiDataId: "Q2336339",
  },
  {
    id: 134233,
    name: "Balarampur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.04753,
    longitude: 79.64811,
    wikiDataId: "Q2717357",
  },
  {
    id: 134234,
    name: "Ullal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.47311,
    longitude: 76.31085,
    wikiDataId: "Q1026891",
  },
  {
    id: 134235,
    name: "Bali Chak",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.97621,
    longitude: 78.94187,
    wikiDataId: "Q3980776",
  },
  {
    id: 134237,
    name: "Babrala",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.24849,
    longitude: 77.57698,
    wikiDataId: "Q720922",
  },
  {
    id: 134238,
    name: "Tosham",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.21173,
    longitude: 76.94984,
    wikiDataId: "Q2557937",
  },
  {
    id: 134239,
    name: "Vedaraniyam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.69403,
    longitude: 77.97035,
    wikiDataId: "Q2557937",
  },
  {
    id: 134240,
    name: "Aruppukkottai",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.6686,
    longitude: 75.03207,
    wikiDataId: "Q2248494",
  },
  {
    id: 134241,
    name: "Vashi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.86499,
    longitude: 72.68426,
    wikiDataId: "Q993332",
  },
  {
    id: 134242,
    name: "Vatul",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.037,
    longitude: 74.60171,
    wikiDataId: "Q2544755",
  },
  {
    id: 134243,
    name: "Vedasandur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.12417,
    longitude: 78.44916,
    wikiDataId: "Q2544755",
  },
  {
    id: 134244,
    name: "Ahmednagar",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.74327,
    longitude: 78.83061,
    wikiDataId: "Q2287213",
  },
  {
    id: 134245,
    name: "Bankura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.2291,
    longitude: 77.35479,
    wikiDataId: "Q656788",
  },
  {
    id: 134246,
    name: "Awantipur",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.83364,
    longitude: 76.50539,
    wikiDataId: "Q592107",
  },
  {
    id: 134247,
    name: "Wadi",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.68835,
    longitude: 75.24548,
    wikiDataId: "Q2350142",
  },
  {
    id: 134248,
    name: "Barrackpore",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.2146,
    longitude: 78.23683,
    wikiDataId: "Q2717192",
  },
  {
    id: 134249,
    name: "Bansdih",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.53333,
    longitude: 91.48333,
    wikiDataId: "Q2248438",
  },
  {
    id: 134250,
    name: "Avadi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.33,
    longitude: 73.77,
    wikiDataId: "Q1321577",
  },
  {
    id: 134251,
    name: "Ajra",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.07434,
    longitude: 78.51108,
    wikiDataId: "Q2546414",
  },
  {
    id: 134252,
    name: "Udalguri",
    state_id: 4027,
    state_code: "AS",
    state_name: "Assam",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75367,
    longitude: 92.10215,
    wikiDataId: "Q2726152",
  },
  {
    id: 134253,
    name: "Dornakal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.42918,
    longitude: 78.02968,
    wikiDataId: "Q2726152",
  },
  {
    id: 134254,
    name: "Bholath",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.12416,
    longitude: 84.36869,
    wikiDataId: "Q988483",
  },
  {
    id: 134255,
    name: "Virar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.39258,
    longitude: 77.11756,
    wikiDataId: "Q934153",
  },
  {
    id: 134256,
    name: "Hasimara",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.02746,
    longitude: 79.52347,
    wikiDataId: "Q1805082",
  },
  {
    id: 134257,
    name: "Bhojudih",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 33,
    longitude: 75.16667,
    wikiDataId: "Q1947311",
  },
  {
    id: 134258,
    name: "Avinashi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.73355,
    longitude: 75.97514,
    wikiDataId: "Q2718780",
  },
  {
    id: 134259,
    name: "Gaddi Annaram",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.58806,
    longitude: 77.24779,
    wikiDataId: "Q2718780",
  },
  {
    id: 134260,
    name: "Yelbarga",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.5,
    longitude: 74.87,
    wikiDataId: "Q1483337",
  },
  {
    id: 134261,
    name: "Baruipur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.79681,
    longitude: 80.32093,
    wikiDataId: "Q2231173",
  },
  {
    id: 134262,
    name: "Basanti",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.00311,
    longitude: 79.00821,
    wikiDataId: "Q2717168",
  },
  {
    id: 134263,
    name: "Akalkot",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.41667,
    longitude: 75.5,
    wikiDataId: "Q892641",
  },
  {
    id: 134264,
    name: "Kolasib",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.04828,
    longitude: 94.35883,
    wikiDataId: "Q735101",
  },
  {
    id: 134265,
    name: "Baddi",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.51124,
    longitude: 75.87823,
    wikiDataId: "Q735101",
  },
  {
    id: 134266,
    name: "Akkalkot",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.97102,
    longitude: 80.46625,
    wikiDataId: "Q2720304",
  },
  {
    id: 134267,
    name: "Vita, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.21667,
    longitude: 73.15,
    wikiDataId: "Q460222",
  },
  {
    id: 134268,
    name: "Yellapur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.80569,
    longitude: 74.86058,
    wikiDataId: "Q2738747",
  },
  {
    id: 134269,
    name: "Vite",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.83841,
    longitude: 76.62331,
    wikiDataId: "Q1109355",
  },
  {
    id: 134270,
    name: "Akluj",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.52473,
    longitude: 80.83716,
    wikiDataId: "Q1109355",
  },
  {
    id: 134271,
    name: "Akola",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.52874,
    longitude: 80.83054,
    wikiDataId: "Q1109355",
  },
  {
    id: 134272,
    name: "Wadala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.60144,
    longitude: 77.68878,
    wikiDataId: "Q2718792",
  },
  {
    id: 134273,
    name: "Umarkot",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.66529,
    longitude: 82.20629,
    wikiDataId: "Q2718792",
  },
  {
    id: 134274,
    name: "Wadgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.85396,
    longitude: 79.32466,
    wikiDataId: "Q2544787",
  },
  {
    id: 134275,
    name: "Una",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.69881,
    longitude: 73.11561,
    wikiDataId: "Q2724380",
  },
  {
    id: 134276,
    name: "Akolner",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.51056,
    longitude: 78.93667,
    wikiDataId: "Q2724380",
  },
  {
    id: 134277,
    name: "Un",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.84353,
    longitude: 71.80305,
    wikiDataId: "Q2725836",
  },
  {
    id: 134278,
    name: "Unjha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.88745,
    longitude: 71.76975,
    wikiDataId: "Q2725833",
  },
  {
    id: 134279,
    name: "Badgam",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.46493,
    longitude: 76.26914,
    wikiDataId: "Q2317314",
  },
  {
    id: 134280,
    name: "Upleta",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.82318,
    longitude: 71.03795,
    wikiDataId: "Q1622928",
  },
  {
    id: 134281,
    name: "Bansgaon",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.32781,
    longitude: 92.00377,
    wikiDataId: "Q1622928",
  },
  {
    id: 134282,
    name: "Akot",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.33794,
    longitude: 75.55931,
    wikiDataId: "Q2546487",
  },
  {
    id: 134283,
    name: "Ayakudi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15336,
    longitude: 75.21523,
    wikiDataId: "Q2546487",
  },
  {
    id: 134284,
    name: "Utran",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.80366,
    longitude: 72.39101,
    wikiDataId: "Q767916",
  },
  {
    id: 134285,
    name: "Begampur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.5,
    longitude: 80.5,
    wikiDataId: "Q939610",
  },
  {
    id: 134286,
    name: "Vadgam",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.74015,
    longitude: 70.28256,
    wikiDataId: "Q2734950",
  },
  {
    id: 134287,
    name: "Babugarh",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.40577,
    longitude: 78.55911,
    wikiDataId: "Q2734950",
  },
  {
    id: 134288,
    name: "Upper Siang",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.83355,
    longitude: 94.91806,
    wikiDataId: "Q15465",
  },
  {
    id: 134289,
    name: "Upper Subansiri",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.3,
    longitude: 94,
    wikiDataId: "Q15464",
  },
  {
    id: 134290,
    name: "Gadwal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.26356,
    longitude: 78.5139,
    wikiDataId: "Q2715639",
  },
  {
    id: 134291,
    name: "Wadner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.87813,
    longitude: 72.93924,
    wikiDataId: "Q1113891",
  },
  {
    id: 134292,
    name: "Uravakonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.94348,
    longitude: 77.25494,
    wikiDataId: "Q736330",
  },
  {
    id: 134293,
    name: "Bokaro",
    state_id: 4029,
    state_code: "JK",
    state_name: "Jammu and Kashmir",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 34.08064,
    longitude: 74.05088,
    wikiDataId: "Q2226434",
  },
  {
    id: 134294,
    name: "Beldanga",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.79796,
    longitude: 79.23763,
    wikiDataId: "Q2717176",
  },
  {
    id: 134295,
    name: "Ghatkesar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.96936,
    longitude: 77.78621,
    wikiDataId: "Q2541109",
  },
  {
    id: 134296,
    name: "Berhampore",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.31933,
    longitude: 82.41872,
    wikiDataId: "Q2717708",
  },
  {
    id: 134297,
    name: "Vadnagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.23333,
    longitude: 72.86667,
    wikiDataId: "Q2231121",
  },
  {
    id: 134298,
    name: "Gopalur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.80701,
    longitude: 77.32718,
    wikiDataId: "Q2231121",
  },
  {
    id: 134299,
    name: "Uttar Bastar Kanker",
    state_id: 4040,
    state_code: "CT",
    state_name: "Chhattisgarh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.2,
    longitude: 81.1,
    wikiDataId: "Q2231121",
  },
  {
    id: 134300,
    name: "Adur",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.88333,
    longitude: 74.58333,
    wikiDataId: "Q579205",
  },
  {
    id: 134301,
    name: "Hindusthan Cables Town",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.72986,
    longitude: 78.44342,
    wikiDataId: "Q1949097",
  },
  {
    id: 134302,
    name: "Hyderabad",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.61433,
    longitude: 79.75748,
    wikiDataId: "Q17075659",
  },
  {
    id: 134303,
    name: "Jangaon",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.31549,
    longitude: 78.15141,
    wikiDataId: "Q490708",
  },
  {
    id: 134304,
    name: "Jangoan",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.38286,
    longitude: 77.61221,
    wikiDataId: "Q490708",
  },
  {
    id: 134305,
    name: "Jayashankar Bhupalapally",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.69786,
    longitude: 77.51916,
    wikiDataId: "Q490708",
  },
  {
    id: 134306,
    name: "Jogulamba Gadwal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.44081,
    longitude: 78.09784,
    wikiDataId: "Q682755",
  },
  {
    id: 134307,
    name: "Alappuzha",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.29724,
    longitude: 77.80184,
    wikiDataId: "Q2449175",
  },
  {
    id: 134308,
    name: "Vadlapudi",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.31119,
    longitude: 79.8043,
    wikiDataId: "Q2449175",
  },
  {
    id: 134309,
    name: "Vaghodia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78593,
    longitude: 72.63893,
    wikiDataId: "Q2570726",
  },
  {
    id: 134310,
    name: "Vaghodia INA",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.29941,
    longitude: 73.20812,
    wikiDataId: "Q11909",
  },
  {
    id: 134311,
    name: "Vagator",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.59766,
    longitude: 73.74496,
    wikiDataId: "Q11909",
  },
  {
    id: 134312,
    name: "Walgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.92672,
    longitude: 74.7275,
    wikiDataId: "Q2451016",
  },
  {
    id: 134313,
    name: "Ajaigarh",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.74858,
    longitude: 76.39637,
    wikiDataId: "Q2477208",
  },
  {
    id: 134314,
    name: "Vaishali",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.75,
    longitude: 85.41667,
    wikiDataId: "Q100130",
  },
  {
    id: 134316,
    name: "Kamareddi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.89012,
    longitude: 79.39322,
    wikiDataId: "Q2715648",
  },
  {
    id: 134317,
    name: "Kamareddy",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.92094,
    longitude: 79.58239,
    wikiDataId: "Q2715474",
  },
  {
    id: 134318,
    name: "Valsad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.53333,
    longitude: 72.9,
    wikiDataId: "Q2234110",
  },
  {
    id: 134319,
    name: "Karimnagar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.71988,
    longitude: 79.05981,
    wikiDataId: "Q2231085",
  },
  {
    id: 134320,
    name: "Khammam",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.32691,
    longitude: 76.95116,
    wikiDataId: "Q282137",
  },
  {
    id: 134321,
    name: "Valpoy",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.53239,
    longitude: 74.13671,
    wikiDataId: "Q2734533",
  },
  {
    id: 134322,
    name: "Vansda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.5,
    longitude: 73.08333,
    wikiDataId: "Q302654",
  },
  {
    id: 134323,
    name: "Kodar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.8924,
    longitude: 80.08079,
    wikiDataId: "Q2541049",
  },
  {
    id: 134324,
    name: "Koratla",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.50429,
    longitude: 79.60556,
    wikiDataId: "Q2465640",
  },
  {
    id: 134325,
    name: "Kothapet",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.68162,
    longitude: 78.62014,
    wikiDataId: "Q282212",
  },
  {
    id: 134326,
    name: "Vasa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.37175,
    longitude: 72.90493,
    wikiDataId: "Q2087696",
  },
  {
    id: 134327,
    name: "Bhadreswar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.31668,
    longitude: 83.01041,
    wikiDataId: "Q79980",
  },
  {
    id: 134328,
    name: "Warora",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.01767,
    longitude: 75.91042,
    wikiDataId: "Q7915443",
  },
  {
    id: 134329,
    name: "Varca",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.23237,
    longitude: 73.94311,
    wikiDataId: "Q2558931",
  },
  {
    id: 134330,
    name: "Akodia",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.7333,
    longitude: 76.7167,
    wikiDataId: "Q1012951",
  },
  {
    id: 134331,
    name: "Vasavad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.73947,
    longitude: 72.06553,
    wikiDataId: "Q2738371",
  },
  {
    id: 134332,
    name: "Vaso",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.66079,
    longitude: 72.75519,
    wikiDataId: "Q2738371",
  },
  {
    id: 134333,
    name: "Vasco da Gama",
    state_id: 4009,
    state_code: "GA",
    state_name: "Goa",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.39585,
    longitude: 73.81568,
    wikiDataId: "Q978780",
  },
  {
    id: 134334,
    name: "Washim",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.40844,
    longitude: 73.26285,
    wikiDataId: "Q7916821",
  },
  {
    id: 134335,
    name: "Kottapalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.16069,
    longitude: 77.75883,
    wikiDataId: "Q978780",
  },
  {
    id: 134336,
    name: "Alampur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.71158,
    longitude: 76.33888,
    wikiDataId: "Q978780",
  },
  {
    id: 134337,
    name: "Kukatpalli",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.37208,
    longitude: 79.85095,
    wikiDataId: "Q2541264",
  },
  {
    id: 134338,
    name: "Kyathampalle",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.53102,
    longitude: 77.95019,
    wikiDataId: "Q2541116",
  },
  {
    id: 134339,
    name: "Vijapur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.69021,
    longitude: 73.56299,
    wikiDataId: "Q2558274",
  },
  {
    id: 134340,
    name: "Lakshettipet",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.68333,
    longitude: 79.83333,
    wikiDataId: "Q1510885",
  },
  {
    id: 134341,
    name: "Mahabubabad",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.86,
    longitude: 79.035,
    wikiDataId: "Q15206",
  },
  {
    id: 134342,
    name: "Lal Bahadur Nagar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.15804,
    longitude: 80.10634,
    wikiDataId: "Q15206",
  },
  {
    id: 134343,
    name: "Mahbubnagar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.10825,
    longitude: 78.00113,
    wikiDataId: "Q2541007",
  },
  {
    id: 134344,
    name: "Bachhraon",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.46523,
    longitude: 78.86894,
    wikiDataId: "Q2541007",
  },
  {
    id: 134345,
    name: "Malkajgiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.89911,
    longitude: 80.169,
    wikiDataId: "Q2541007",
  },
  {
    id: 134346,
    name: "Yeola",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.86125,
    longitude: 73.63182,
    wikiDataId: "Q2445434",
  },
  {
    id: 134347,
    name: "Venkatagiri",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.96005,
    longitude: 79.58032,
    wikiDataId: "Q719838",
  },
  {
    id: 134348,
    name: "Vepagunta",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.77844,
    longitude: 83.21577,
    wikiDataId: "Q720888",
  },
  {
    id: 134349,
    name: "Vinchhiya",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.9077,
    longitude: 70.36786,
    wikiDataId: "Q518791",
  },
  {
    id: 134350,
    name: "Vetapalem",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.78502,
    longitude: 80.30663,
    wikiDataId: "Q2563456",
  },
  {
    id: 134351,
    name: "Mancheral",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.56207,
    longitude: 76.91305,
    wikiDataId: "Q2563456",
  },
  {
    id: 134352,
    name: "Mandamarri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.10769,
    longitude: 79.24516,
    wikiDataId: "Q2540924",
  },
  {
    id: 134353,
    name: "Alirajpur",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.71742,
    longitude: 76.72582,
    wikiDataId: "Q2540924",
  },
  {
    id: 134354,
    name: "Akrani",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.91667,
    longitude: 78,
    wikiDataId: "Q1815253",
  },
  {
    id: 134355,
    name: "Manthani",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.2453,
    longitude: 77.50066,
    wikiDataId: "Q369303",
  },
  {
    id: 134356,
    name: "Vijayawada",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.50745,
    longitude: 80.6466,
    wikiDataId: "Q200017",
  },
  {
    id: 134357,
    name: "Vinchia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.5623,
    longitude: 72.74848,
    wikiDataId: "Q2234286",
  },
  {
    id: 134358,
    name: "Manuguru",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.0369,
    longitude: 79.54595,
    wikiDataId: "Q2234286",
  },
  {
    id: 134359,
    name: "Bachhrawan",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.3381,
    longitude: 77.90441,
    wikiDataId: "Q568778",
  },
  {
    id: 134360,
    name: "Hooghly district",
    state_id: 4016,
    state_code: "UT",
    state_name: "Uttarakhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.46944,
    longitude: 77.77275,
    wikiDataId: "Q2552200",
  },
  {
    id: 134361,
    name: "Medak",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.13227,
    longitude: 78.16635,
    wikiDataId: "Q2552200",
  },
  {
    id: 134362,
    name: "Medchal",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.99,
    longitude: 79.37,
    wikiDataId: "Q15205",
  },
  {
    id: 134363,
    name: "Virpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.21027,
    longitude: 71.37967,
    wikiDataId: "Q15205",
  },
  {
    id: 134364,
    name: "Vinukonda",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.0531,
    longitude: 79.73964,
    wikiDataId: "Q2566502",
  },
  {
    id: 134365,
    name: "Visnagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.1892,
    longitude: 73.47987,
    wikiDataId: "Q2773235",
  },
  {
    id: 134366,
    name: "Mirialguda",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.45,
    longitude: 77.92,
    wikiDataId: "Q15209",
  },
  {
    id: 134368,
    name: "Mayang Imphal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.45591,
    longitude: 72.81136,
    wikiDataId: "Q2487341",
  },
  {
    id: 134369,
    name: "Visakhapatnam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.68009,
    longitude: 83.20161,
    wikiDataId: "Q200016",
  },
  {
    id: 134371,
    name: "Wadhai",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.69855,
    longitude: 72.5521,
    wikiDataId: "Q2452712",
  },
  {
    id: 134372,
    name: "Phek",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.27343,
    longitude: 74.53792,
    wikiDataId: "Q7936679",
  },
  {
    id: 134373,
    name: "Vizianagaram",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.11692,
    longitude: 83.41148,
    wikiDataId: "Q3619248",
  },
  {
    id: 134374,
    name: "Vizianagaram District",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.41102,
    longitude: 83.37677,
    wikiDataId: "Q15392",
  },
  {
    id: 134375,
    name: "Nagar Karnul",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.5183,
    longitude: 79.32411,
    wikiDataId: "Q15392",
  },
  {
    id: 134376,
    name: "Bhatpara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.58105,
    longitude: 77.69662,
    wikiDataId: "Q688281",
  },
  {
    id: 134377,
    name: "Vuyyuru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.36307,
    longitude: 80.84406,
    wikiDataId: "Q3418817",
  },
  {
    id: 134378,
    name: "Wadhwan",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.11079,
    longitude: 73.39365,
    wikiDataId: "Q1964842",
  },
  {
    id: 134379,
    name: "Wagholi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.65347,
    longitude: 73.14811,
    wikiDataId: "Q4007184",
  },
  {
    id: 134380,
    name: "Kagaznagar",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.08481,
    longitude: 77.96113,
    wikiDataId: "Q1964842",
  },
  {
    id: 134381,
    name: "Vallabh Vidyanagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.30505,
    longitude: 73.40016,
    wikiDataId: "Q1935461",
  },
  {
    id: 134384,
    name: "Kottagudem",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.24171,
    longitude: 77.41177,
    wikiDataId: "Q2541738",
  },
  {
    id: 134385,
    name: "Medchal Malkajgiri",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.47613,
    longitude: 78.73553,
    wikiDataId: "Q2541738",
  },
  {
    id: 134386,
    name: "Alwaye",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.19644,
    longitude: 75.80512,
    wikiDataId: "Q2541738",
  },
  {
    id: 134387,
    name: "Vyara",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.33954,
    longitude: 70.74966,
    wikiDataId: "Q2452720",
  },
  {
    id: 134388,
    name: "Tamenglong",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.7392,
    longitude: 73.63945,
    wikiDataId: "Q2719142",
  },
  {
    id: 134389,
    name: "Ambala",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.77048,
    longitude: 73.50074,
    wikiDataId: "Q2719142",
  },
  {
    id: 134390,
    name: "Yairipok",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.95276,
    longitude: 73.89058,
    wikiDataId: "Q1748687",
  },
  {
    id: 134391,
    name: "Nalgonda",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.9251,
    longitude: 79.36626,
    wikiDataId: "Q2716022",
  },
  {
    id: 134392,
    name: "Narayanpet",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.79041,
    longitude: 79.82358,
    wikiDataId: "Q2541273",
  },
  {
    id: 134393,
    name: "East Khasi Hills",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.05507,
    longitude: 78.95313,
    wikiDataId: "Q1748341",
  },
  {
    id: 134394,
    name: "Baghpat",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.36738,
    longitude: 78.06889,
    wikiDataId: "Q2563513",
  },
  {
    id: 134395,
    name: "Baghra",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18,
    longitude: 79.83333,
    wikiDataId: "Q15399",
  },
  {
    id: 134396,
    name: "Mairang",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.73933,
    longitude: 78.59784,
    wikiDataId: "Q1772819",
  },
  {
    id: 134397,
    name: "Mankachar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.22885,
    longitude: 79.00277,
    wikiDataId: "Q584146",
  },
  {
    id: 134398,
    name: "Nongpoh",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.47101,
    longitude: 78.26965,
    wikiDataId: "Q2546677",
  },
  {
    id: 134399,
    name: "Nongstoin",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.2,
    longitude: 77.2,
    wikiDataId: "Q2299018",
  },
  {
    id: 134400,
    name: "Alot",
    state_id: 4028,
    state_code: "KL",
    state_name: "Kerala",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.605,
    longitude: 76.083,
    wikiDataId: "Q1364427",
  },
  {
    id: 134401,
    name: "Birpara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.21145,
    longitude: 79.05665,
    wikiDataId: "Q2542626",
  },
  {
    id: 134402,
    name: "Narsingi",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.36552,
    longitude: 76.78442,
    wikiDataId: "Q1749236",
  },
  {
    id: 134403,
    name: "Ayyampettai",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.0186,
    longitude: 77.17636,
    wikiDataId: "Q1749236",
  },
  {
    id: 134404,
    name: "West Delhi",
    state_id: 4021,
    state_code: "DL",
    state_name: "Delhi",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.65655,
    longitude: 77.10068,
    wikiDataId: "Q549807",
  },
  {
    id: 134405,
    name: "Chinna Salem",
    state_id: 4034,
    state_code: "SK",
    state_name: "Sikkim",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.33333,
    longitude: 88.25,
    wikiDataId: "Q549807",
  },
  {
    id: 134406,
    name: "Wokha",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.56794,
    longitude: 90.22447,
    wikiDataId: "Q2329181",
  },
  {
    id: 134407,
    name: "West Godavari",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17,
    longitude: 81.16667,
    wikiDataId: "Q15404",
  },
  {
    id: 134408,
    name: "Zunheboto",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.5,
    longitude: 92.25,
    wikiDataId: "Q13181190",
  },
  {
    id: 134409,
    name: "West Kameng",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.4,
    longitude: 92.35,
    wikiDataId: "Q15459",
  },
  {
    id: 134410,
    name: "Angul",
    state_id: 4006,
    state_code: "ML",
    state_name: "Meghalaya",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.54776,
    longitude: 91.26957,
    wikiDataId: "Q2064752",
  },
  {
    id: 134411,
    name: "West Siang",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.4,
    longitude: 94.55,
    wikiDataId: "Q15453",
  },
  {
    id: 134412,
    name: "Bansi",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.91667,
    longitude: 91.5,
    wikiDataId: "Q1947570",
  },
  {
    id: 134413,
    name: "Bhawanipatna",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.16667,
    longitude: 94.25,
    wikiDataId: "Q1947570",
  },
  {
    id: 134414,
    name: "Angamali",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.05183,
    longitude: 76.99048,
    wikiDataId: "Q2247593",
  },
  {
    id: 134415,
    name: "Kolasib district",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.58921,
    longitude: 94.06386,
    wikiDataId: "Q1320773",
  },
  {
    id: 134416,
    name: "Asandh",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.61198,
    longitude: 70.94379,
    wikiDataId: "Q2006496",
  },
  {
    id: 134417,
    name: "Waris Aliganj",
    state_id: 4037,
    state_code: "BR",
    state_name: "Bihar",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.0172,
    longitude: 85.64047,
    wikiDataId: "Q2006496",
  },
  {
    id: 134418,
    name: "Alandi",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.76184,
    longitude: 80.04301,
    wikiDataId: "Q2449438",
  },
  {
    id: 134420,
    name: "Aroor",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.73,
    longitude: 76.94,
    wikiDataId: "Q1786949",
  },
  {
    id: 134421,
    name: "Lawngtlai",
    state_id: 4010,
    state_code: "MN",
    state_name: "Manipur",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.67792,
    longitude: 94.04767,
    wikiDataId: "Q2563956",
  },
  {
    id: 134422,
    name: "Banjar",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.23644,
    longitude: 77.30498,
    wikiDataId: "Q1873644",
  },
  {
    id: 134424,
    name: "Yanam",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.73308,
    longitude: 82.21364,
    wikiDataId: "Q1807929",
  },
  {
    id: 134425,
    name: "Dera Baba Nanak",
    state_id: 4011,
    state_code: "PY",
    state_name: "Puducherry",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.73463,
    longitude: 82.21773,
    wikiDataId: "Q2126598",
  },
  {
    id: 134426,
    name: "Yanamalakuduru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.48531,
    longitude: 80.66746,
    wikiDataId: "Q2126598",
  },
  {
    id: 134427,
    name: "Shillong",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.15,
    longitude: 78.35,
    wikiDataId: "Q280709",
  },
  {
    id: 134429,
    name: "Arukutti",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.10073,
    longitude: 77.59632,
    wikiDataId: "Q720231",
  },
  {
    id: 134430,
    name: "Attingal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 12.04629,
    longitude: 77.03034,
    wikiDataId: "Q2475035",
  },
  {
    id: 134431,
    name: "Avanoor",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.61545,
    longitude: 76.01184,
    wikiDataId: "Q2552616",
  },
  {
    id: 134432,
    name: "Bah",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.59064,
    longitude: 80.32146,
    wikiDataId: "Q721480",
  },
  {
    id: 134433,
    name: "Azhikkal",
    state_id: 4026,
    state_code: "KA",
    state_name: "Karnataka",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 14.9637,
    longitude: 74.70929,
    wikiDataId: "Q2340055",
  },
  {
    id: 134434,
    name: "South Garo Hills",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.0424,
    longitude: 74.48944,
    wikiDataId: "Q2445508",
  },
  {
    id: 134435,
    name: "Bandipore",
    state_id: 4020,
    state_code: "HP",
    state_name: "Himachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 32.16423,
    longitude: 76.19622,
    wikiDataId: "Q2637314",
  },
  {
    id: 134437,
    name: "Yarada",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.65872,
    longitude: 83.27419,
    wikiDataId: "Q564781",
  },
  {
    id: 134438,
    name: "Ri-Bhoi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.16772,
    longitude: 75.69762,
    wikiDataId: "Q564781",
  },
  {
    id: 134439,
    name: "Budge Budge",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.69867,
    longitude: 82.73354,
    wikiDataId: "Q564781",
  },
  {
    id: 134440,
    name: "Baheri",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.68138,
    longitude: 77.60743,
    wikiDataId: "Q564781",
  },
  {
    id: 134441,
    name: "Canning",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.83093,
    longitude: 81.32929,
    wikiDataId: "Q2543268",
  },
  {
    id: 134442,
    name: "Chakapara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.41961,
    longitude: 83.55786,
    wikiDataId: "Q919920",
  },
  {
    id: 134443,
    name: "Bhindar",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.96853,
    longitude: 74.99106,
    wikiDataId: "Q2201222",
  },
  {
    id: 134444,
    name: "Ziro",
    state_id: 4024,
    state_code: "AR",
    state_name: "Arunachal Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.59497,
    longitude: 93.83854,
    wikiDataId: "Q663062",
  },
  {
    id: 134445,
    name: "Bhuban",
    state_id: 4018,
    state_code: "NL",
    state_name: "Nagaland",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26,
    longitude: 94.5,
    wikiDataId: "Q663062",
  },
  {
    id: 134446,
    name: "Saraikela",
    state_id: 4025,
    state_code: "JH",
    state_name: "Jharkhand",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.3,
    longitude: 85.41667,
    wikiDataId: "Q663062",
  },
  {
    id: 134447,
    name: "pedda nakkalapalem",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.8668,
    longitude: 80.16202,
    wikiDataId: "Q663062",
  },
  {
    id: 134448,
    name: "vadlamuru",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.09545,
    longitude: 82.16565,
    wikiDataId: "Q663062",
  },
  {
    id: 134449,
    name: "Bhiwadi",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.59365,
    longitude: 72.71756,
    wikiDataId: "Q695221",
  },
  {
    id: 134450,
    name: "Bhuma",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.48012,
    longitude: 72.78186,
    wikiDataId: "Q490659",
  },
  {
    id: 134451,
    name: "Naspur",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.5,
    longitude: 78.5,
    wikiDataId: "Q15211",
  },
  {
    id: 134452,
    name: "Adoni",
    state_id: 4017,
    state_code: "AP",
    state_name: "Andhra Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.62788,
    longitude: 77.27495,
    wikiDataId: "Q490906",
  },
  {
    id: 134454,
    name: "Nirmal",
    state_id: 4012,
    state_code: "TG",
    state_name: "Telangana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.87987,
    longitude: 78.13352,
    wikiDataId: "Q606343",
  },
  {
    id: 134455,
    name: "Desur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 10.3606,
    longitude: 78.98492,
    wikiDataId: "Q589741",
  },
  {
    id: 134456,
    name: "Devadanappatti",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 8.86404,
    longitude: 77.49937,
    wikiDataId: "Q584054",
  },
  {
    id: 134457,
    name: "Devakottai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.59895,
    longitude: 79.71893,
    wikiDataId: "Q584054",
  },
  {
    id: 134458,
    name: "Bahraigh",
    state_id: 4038,
    state_code: "TR",
    state_name: "Tripura",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.936,
    longitude: 91.85436,
    wikiDataId: "Q584054",
  },
  {
    id: 134459,
    name: "Amli",
    state_id: 4033,
    state_code: "DH",
    state_name: "Dadra and Nagar Haveli and Daman and Diu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.28333,
    longitude: 73.01667,
    wikiDataId: "Q367624",
  },
  {
    id: 134460,
    name: "Elumalai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 9.99797,
    longitude: 77.62097,
    wikiDataId: "Q367624",
  },
  {
    id: 134461,
    name: "Ballard Estate",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.02681,
    longitude: 74.71487,
    wikiDataId: "Q367624",
  },
  {
    id: 134462,
    name: "Balaghat",
    state_id: 4039,
    state_code: "MP",
    state_name: "Madhya Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.38109,
    longitude: 77.41739,
    wikiDataId: "Q581765",
  },
  {
    id: 134463,
    name: "Baner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.99585,
    longitude: 78.22914,
    wikiDataId: "Q1935271",
  },
  {
    id: 134464,
    name: "Bahadurgarh",
    state_id: 4007,
    state_code: "HR",
    state_name: "Haryana",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.52119,
    longitude: 76.60552,
    wikiDataId: "Q1935271",
  },
  {
    id: 134465,
    name: "Birmitrapur",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.61114,
    longitude: 84.65998,
    wikiDataId: "Q588678",
  },
  {
    id: 134466,
    name: "Chechat",
    state_id: 4014,
    state_code: "RJ",
    state_name: "Rajasthan",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.7342,
    longitude: 74.33278,
    wikiDataId: "Q589902",
  },
  {
    id: 134467,
    name: "Bolanikhodan",
    state_id: 4013,
    state_code: "OR",
    state_name: "Odisha",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.51999,
    longitude: 85.62965,
    wikiDataId: "Q589902",
  },
  {
    id: 134468,
    name: "Harur",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.53272,
    longitude: 78.05363,
    wikiDataId: "Q585898",
  },
  {
    id: 134469,
    name: "Ilampillai",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.1147,
    longitude: 80.10981,
    wikiDataId: "Q584494",
  },
  {
    id: 134471,
    name: "Bawali",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.58479,
    longitude: 77.2554,
    wikiDataId: "Q1637459",
  },
  {
    id: 134472,
    name: "Jagtial",
    state_id: 4035,
    state_code: "TN",
    state_name: "Tamil Nadu",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 11.16892,
    longitude: 77.45431,
    wikiDataId: "Q1637459",
  },
  {
    id: 141852,
    name: "Howrah",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83009,
    longitude: 87.68661,
    wikiDataId: "Q584907",
  },
  {
    id: 141853,
    name: "Ichapur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.18,
    longitude: 88.58,
    wikiDataId: "Q585675",
  },
  {
    id: 141854,
    name: "Indpur community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.38576389,
    longitude: 77.27225278,
    wikiDataId: "Q4701468",
  },
  {
    id: 141855,
    name: "Ingraj Bazar",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.49136,
    longitude: 89.52796,
    wikiDataId: "Q4701468",
  },
  {
    id: 141857,
    name: "Jaigaon",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.57333333,
    longitude: 88.01611111,
    wikiDataId: "Q25247439",
  },
  {
    id: 141858,
    name: "Jalpaiguri",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.22,
    longitude: 88.17,
    wikiDataId: "Q388212",
  },
  {
    id: 141859,
    name: "Jamuria",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.6,
    longitude: 87.2,
    wikiDataId: "Q766990",
  },
  {
    id: 141860,
    name: "Jangipur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.88,
    longitude: 87.78,
    wikiDataId: "Q4783988",
  },
  {
    id: 141861,
    name: "Jaynagar Majilpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.68333333,
    longitude: 86.96666667,
    wikiDataId: "Q202139",
  },
  {
    id: 141862,
    name: "Jejur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.833,
    longitude: 88.633,
    wikiDataId: "Q583568",
  },
  {
    id: 141863,
    name: "Jhalida",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.28,
    longitude: 88.53,
    wikiDataId: "Q4840991",
  },
  {
    id: 141864,
    name: "Jhargram",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.74,
    longitude: 88.79,
    wikiDataId: "Q585014",
  },
  {
    id: 141865,
    name: "Jhilimili",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.699804,
    longitude: 88.319392,
    wikiDataId: "Q4841529",
  },
  {
    id: 141866,
    name: "Kakdwip",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.47,
    longitude: 87.97,
    wikiDataId: "Q627013",
  },
  {
    id: 141867,
    name: "Kalaikunda",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.335,
    longitude: 88.644,
    wikiDataId: "Q4842168",
  },
  {
    id: 141868,
    name: "Kaliaganj",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.657419,
    longitude: 87.195974,
    wikiDataId: "Q627065",
  },
  {
    id: 141869,
    name: "Kalimpong",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.79,
    longitude: 88.32,
    wikiDataId: "Q625394",
  },
  {
    id: 141870,
    name: "Kalna",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.8832,
    longitude: 87.37265,
    wikiDataId: "Q3350684",
  },
  {
    id: 141871,
    name: "Kalyani",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.09714,
    longitude: 86.22292,
    wikiDataId: "Q3350684",
  },
  {
    id: 141872,
    name: "Kamarpukur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.65,
    longitude: 88.34,
    wikiDataId: "Q601897",
  },
  {
    id: 141873,
    name: "Kanchrapara",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.21666667,
    longitude: 88.76666667,
    wikiDataId: "Q633291",
  },
  {
    id: 141874,
    name: "Kandi",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.17,
    longitude: 88.335,
    wikiDataId: "Q4853295",
  },
  {
    id: 141875,
    name: "Karimpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.65,
    longitude: 89.81666667,
    wikiDataId: "Q4854849",
  },
  {
    id: 141876,
    name: "Katwa",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.07,
    longitude: 88.82,
    wikiDataId: "Q622612",
  },
  {
    id: 141877,
    name: "Kenda",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.63,
    longitude: 88.3,
    wikiDataId: "Q637079",
  },
  {
    id: 141878,
    name: "Keshabpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25,
    longitude: 87.06666667,
    wikiDataId: "Q1949030",
  },
  {
    id: 141879,
    name: "Kharba",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.97,
    longitude: 88.4,
    wikiDataId: "Q622607",
  },
  {
    id: 141880,
    name: "Khardaha",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.4,
    longitude: 88.4167,
    wikiDataId: "Q4857141",
  },
  {
    id: 141881,
    name: "Khatra",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.36,
    longitude: 86.65,
    wikiDataId: "Q637804",
  },
  {
    id: 141882,
    name: "Kirnahar",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.64,
    longitude: 88.37,
    wikiDataId: "Q712504",
  },
  {
    id: 141883,
    name: "Kolkata",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23333333,
    longitude: 88.45,
    wikiDataId: "Q685315",
  },
  {
    id: 141884,
    name: "Konnagar",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25,
    longitude: 87.85,
    wikiDataId: "Q709267",
  },
  {
    id: 141885,
    name: "Krishnanagar",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.43333333,
    longitude: 87.28333333,
    wikiDataId: "Q712475",
  },
  {
    id: 141886,
    name: "Krishnapur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.75,
    longitude: 88.36666667,
    wikiDataId: "Q709298",
  },
  {
    id: 141887,
    name: "Kshirpai",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.35,
    longitude: 88.44,
    wikiDataId: "Q708616",
  },
  {
    id: 141888,
    name: "Kulpi",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.1891534,
    longitude: 88.6705685,
    wikiDataId: "Q4866245",
  },
  {
    id: 141889,
    name: "Kultali",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.65722222,
    longitude: 88.89416667,
    wikiDataId: "Q712515",
  },
  {
    id: 141890,
    name: "Kurseong",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.74,
    longitude: 88.24,
    wikiDataId: "Q757843",
  },
  {
    id: 141891,
    name: "Lalgarh",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.08,
    longitude: 87.35,
    wikiDataId: "Q4882099",
  },
  {
    id: 141892,
    name: "Lalgola",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.93,
    longitude: 88.25,
    wikiDataId: "Q2341938",
  },
  {
    id: 141893,
    name: "Loyabad",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.33333333,
    longitude: 87.21666667,
    wikiDataId: "Q759158",
  },
  {
    id: 141894,
    name: "Madanpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.1,
    longitude: 88.25,
    wikiDataId: "Q633530",
  },
  {
    id: 141895,
    name: "Madhyamgram",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.82,
    longitude: 88.35,
    wikiDataId: "Q2453308",
  },
  {
    id: 141896,
    name: "Mahiari",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.62,
    longitude: 88.21,
    wikiDataId: "Q759149",
  },
  {
    id: 141897,
    name: "Mahishadal community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.86666667,
    longitude: 88.41666667,
    wikiDataId: "Q854914",
  },
  {
    id: 141898,
    name: "Mainaguri",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24,
    longitude: 87.58333,
    wikiDataId: "Q2088440",
  },
  {
    id: 141899,
    name: "Manikpara",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.5,
    longitude: 89.5,
    wikiDataId: "Q4916936",
  },
  {
    id: 141900,
    name: "Masila",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.38,
    longitude: 88.27,
    wikiDataId: "Q3640429",
  },
  {
    id: 141901,
    name: "Mathabhanga",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.67,
    longitude: 87.72,
    wikiDataId: "Q858304",
  },
  {
    id: 141902,
    name: "Matiali community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.47,
    longitude: 88.17,
    wikiDataId: "Q858488",
  },
  {
    id: 141903,
    name: "Kamarhati",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.36482,
    longitude: 87.55304,
    wikiDataId: "Q858488",
  },
  {
    id: 141904,
    name: "Kulti",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.42563,
    longitude: 88.19336,
    wikiDataId: "Q858488",
  },
  {
    id: 141905,
    name: "Matigara community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.32,
    longitude: 88.67,
    wikiDataId: "Q4886236",
  },
  {
    id: 141906,
    name: "Medinipur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.63,
    longitude: 88.35,
    wikiDataId: "Q857735",
  },
  {
    id: 141907,
    name: "Mejia community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.08,
    longitude: 88.52,
    wikiDataId: "Q857581",
  },
  {
    id: 141908,
    name: "Memari",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.83,
    longitude: 87.96,
    wikiDataId: "Q5069871",
  },
  {
    id: 141909,
    name: "Mohanpur community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.8,
    longitude: 88.37,
    wikiDataId: "Q857746",
  },
  {
    id: 141910,
    name: "Monoharpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.86666667,
    longitude: 88.38333333,
    wikiDataId: "Q695591",
  },
  {
    id: 141911,
    name: "Muragacha",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.73,
    longitude: 87.52,
    wikiDataId: "Q859119",
  },
  {
    id: 141912,
    name: "Muri",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.87,
    longitude: 86.87,
    wikiDataId: "Q277935",
  },
  {
    id: 141913,
    name: "Murshidabad",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.78,
    longitude: 87.08,
    wikiDataId: "Q5118335",
  },
  {
    id: 141914,
    name: "Mirik",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.40026,
    longitude: 88.49209,
    wikiDataId: "Q5118335",
  },
  {
    id: 141915,
    name: "Nabadwip",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.78,
    longitude: 87.75,
    wikiDataId: "Q1192104",
  },
  {
    id: 141916,
    name: "Nabagram",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.31666667,
    longitude: 89.43333333,
    wikiDataId: "Q313640",
  },
  {
    id: 141917,
    name: "Nadia district",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.12,
    longitude: 88.28,
    wikiDataId: "Q675531",
  },
  {
    id: 141918,
    name: "Nagarukhra",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.22,
    longitude: 88.76,
    wikiDataId: "Q533839",
  },
  {
    id: 141919,
    name: "Nagrakata",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.87577,
    longitude: 87.84009,
    wikiDataId: "Q533839",
  },
  {
    id: 141920,
    name: "Naihati",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.63333333,
    longitude: 88.42277778,
    wikiDataId: "Q26789881",
  },
  {
    id: 141921,
    name: "Naksalbari",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.04166667,
    longitude: 88.26305556,
    wikiDataId: "Q169997",
  },
  {
    id: 141922,
    name: "Nalhati",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.32605,
    longitude: 88.32989,
    wikiDataId: "Q169997",
  },
  {
    id: 141923,
    name: "Nalpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.68333333,
    longitude: 88.28333333,
    wikiDataId: "Q5247818",
  },
  {
    id: 141924,
    name: "Namkhana community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.25029,
    longitude: 88.61824,
    wikiDataId: "Q5247818",
  },
  {
    id: 141925,
    name: "Nandigram",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.97,
    longitude: 88.1,
    wikiDataId: "Q5268978",
  },
  {
    id: 141926,
    name: "Nangi",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.58222222,
    longitude: 88.17111111,
    wikiDataId: "Q26792208",
  },
  {
    id: 141927,
    name: "Nayagram community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.68,
    longitude: 87.97,
    wikiDataId: "Q1265617",
  },
  {
    id: 141928,
    name: "North 24 Parganas district",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.6,
    longitude: 89.02,
    wikiDataId: "Q1259697",
  },
  {
    id: 141929,
    name: "Odlabari",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.2,
    longitude: 88.2,
    wikiDataId: "Q1264213",
  },
  {
    id: 141930,
    name: "Paikpara",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.68,
    longitude: 87.55,
    wikiDataId: "Q5275680",
  },
  {
    id: 141931,
    name: "Panagarh",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.13,
    longitude: 89.47,
    wikiDataId: "Q1264946",
  },
  {
    id: 141932,
    name: "Panchla",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.64,
    longitude: 88.22,
    wikiDataId: "Q677375",
  },
  {
    id: 141933,
    name: "Panchmura",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.8,
    longitude: 87.38,
    wikiDataId: "Q656516",
  },
  {
    id: 141934,
    name: "Pandua",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.55,
    longitude: 87.32,
    wikiDataId: "Q5088",
  },
  {
    id: 141935,
    name: "Panihati",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.9,
    longitude: 87.53,
    wikiDataId: "Q1525290",
  },
  {
    id: 141936,
    name: "Panskura",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.53,
    longitude: 89.2,
    wikiDataId: "Q1525281",
  },
  {
    id: 141937,
    name: "Parbatipur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.81667,
    longitude: 87.9,
    wikiDataId: "Q1525281",
  },
  {
    id: 141938,
    name: "Paschim Medinipur district",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.50444444,
    longitude: 88.18333333,
    wikiDataId: "Q26789993",
  },
  {
    id: 141939,
    name: "Patiram",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.93,
    longitude: 88.73,
    wikiDataId: "Q5517019",
  },
  {
    id: 141940,
    name: "Patrasaer",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.68,
    longitude: 89.03,
    wikiDataId: "Q1525244",
  },
  {
    id: 141941,
    name: "Patuli",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.7604,
    longitude: 88.2218,
    wikiDataId: "Q19891544",
  },
  {
    id: 141942,
    name: "Pujali",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.4,
    longitude: 88.52,
    wikiDataId: "Q1656762",
  },
  {
    id: 141943,
    name: "Puncha community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.63333333,
    longitude: 88.4,
    wikiDataId: "Q26789439",
  },
  {
    id: 141944,
    name: "Purba Medinipur district",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.82,
    longitude: 88.37,
    wikiDataId: "Q515118",
  },
  {
    id: 141945,
    name: "Purulia",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.67,
    longitude: 87.72,
    wikiDataId: "Q1707341",
  },
  {
    id: 141946,
    name: "Raghudebbati",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.51666667,
    longitude: 88.06666667,
    wikiDataId: "Q5564250",
  },
  {
    id: 141947,
    name: "Raiganj",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.87,
    longitude: 88.76,
    wikiDataId: "Q1707370",
  },
  {
    id: 141948,
    name: "Rajmahal",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.59306,
    longitude: 88.09139,
    wikiDataId: "Q1707370",
  },
  {
    id: 141949,
    name: "Ramchandrapur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.22,
    longitude: 88.15,
    wikiDataId: "Q2733364",
  },
  {
    id: 141950,
    name: "Rajnagar community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.61845,
    longitude: 88.75119,
    wikiDataId: "Q2733364",
  },
  {
    id: 141951,
    name: "Ramjibanpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.97,
    longitude: 88.7,
    wikiDataId: "Q5586957",
  },
  {
    id: 141952,
    name: "Rampur Hat",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.16,
    longitude: 88.8,
    wikiDataId: "Q5587119",
  },
  {
    id: 141953,
    name: "Ranaghat",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15,
    longitude: 89.36666667,
    wikiDataId: "Q5587133",
  },
  {
    id: 141954,
    name: "Raniganj",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.73472222,
    longitude: 88.75944444,
    wikiDataId: "Q26792202",
  },
  {
    id: 141955,
    name: "Raypur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.49277778,
    longitude: 87.73472222,
    wikiDataId: "Q26788928",
  },
  {
    id: 141956,
    name: "Rishra",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.83,
    longitude: 88.63,
    wikiDataId: "Q1708439",
  },
  {
    id: 141957,
    name: "Sahapur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.06046,
    longitude: 88.10975,
    wikiDataId: "Q1708439",
  },
  {
    id: 141958,
    name: "Sainthia",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.33,
    longitude: 88.77,
    wikiDataId: "Q919568",
  },
  {
    id: 141959,
    name: "Salanpur community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.95,
    longitude: 88.42,
    wikiDataId: "Q1707695",
  },
  {
    id: 141960,
    name: "Sankarpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.03333333,
    longitude: 88.31666667,
    wikiDataId: "Q15223037",
  },
  {
    id: 141961,
    name: "Sankrail",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.95,
    longitude: 88.57,
    wikiDataId: "Q15223039",
  },
  {
    id: 141962,
    name: "Santipur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.93611,
    longitude: 88.23194,
    wikiDataId: "Q15223039",
  },
  {
    id: 141963,
    name: "Santoshpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 89.35,
    wikiDataId: "Q14217868",
  },
  {
    id: 141964,
    name: "Santuri community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.82,
    longitude: 86.9,
    wikiDataId: "Q1708886",
  },
  {
    id: 141965,
    name: "Sarenga",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.91,
    longitude: 88.39,
    wikiDataId: "Q548518",
  },
  {
    id: 141966,
    name: "Serampore",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.57688,
    longitude: 88.31857,
    wikiDataId: "Q205697",
  },
  {
    id: 141967,
    name: "Serpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.80499,
    longitude: 88.37088,
    wikiDataId: "Q15227795",
  },
  {
    id: 141968,
    name: "Shyamnagar, West Bengal",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.1667,
    longitude: 86.9333,
    wikiDataId: "Q6026767",
  },
  {
    id: 141969,
    name: "Siliguri",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.00447,
    longitude: 88.14573,
    wikiDataId: "Q6026767",
  },
  {
    id: 141970,
    name: "Singur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.27,
    longitude: 88.2,
    wikiDataId: "Q1753900",
  },
  {
    id: 141971,
    name: "Sodpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.32,
    longitude: 88.23,
    wikiDataId: "Q1753775",
  },
  {
    id: 141972,
    name: "Solap",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.86666667,
    longitude: 89.38333333,
    wikiDataId: "Q1026903",
  },
  {
    id: 141973,
    name: "Sonada",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.51666667,
    longitude: 88.73333333,
    wikiDataId: "Q1949125",
  },
  {
    id: 141974,
    name: "Sonamukhi",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.7,
    longitude: 87.08,
    wikiDataId: "Q2286850",
  },
  {
    id: 141975,
    name: "Sonarpur community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.47001,
    longitude: 88.07659,
    wikiDataId: "Q2286850",
  },
  {
    id: 141976,
    name: "South 24 Parganas district",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.17722222,
    longitude: 88.42583333,
    wikiDataId: "Q2330643",
  },
  {
    id: 141977,
    name: "Srikhanda",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.88,
    longitude: 88.12,
    wikiDataId: "Q6176572",
  },
  {
    id: 141978,
    name: "Srirampur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.36541,
    longitude: 85.97581,
    wikiDataId: "Q26788218",
  },
  {
    id: 141979,
    name: "Suri",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.45,
    longitude: 86.98,
    wikiDataId: "Q2287060",
  },
  {
    id: 141980,
    name: "Swarupnagar community development block",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.8167,
    longitude: 86.6167,
    wikiDataId: "Q6191068",
  },
  {
    id: 141981,
    name: "Takdah",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.8833,
    longitude: 88.1833,
    wikiDataId: "Q6349333",
  },
  {
    id: 141982,
    name: "Taki",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.33916667,
    longitude: 87.22638889,
    wikiDataId: "Q26788257",
  },
  {
    id: 141983,
    name: "Tamluk",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.63,
    longitude: 88.32,
    wikiDataId: "Q2330594",
  },
  {
    id: 141984,
    name: "Tarakeswar",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.07059,
    longitude: 88.47529,
    wikiDataId: "Q500877",
  },
  {
    id: 141985,
    name: "Titagarh",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.22,
    longitude: 88.37,
    wikiDataId: "Q2733606",
  },
  {
    id: 141986,
    name: "Tufanganj",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.975,
    longitude: 88.43444444,
    wikiDataId: "Q2734589",
  },
  {
    id: 141987,
    name: "Tulin",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.67,
    longitude: 88.37,
    wikiDataId: "Q68953",
  },
  {
    id: 141988,
    name: "Uchalan",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.91,
    longitude: 87.65,
    wikiDataId: "Q6356067",
  },
  {
    id: 141989,
    name: "Ula",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.97,
    longitude: 88.43,
    wikiDataId: "Q2088474",
  },
  {
    id: 141990,
    name: "Uluberia",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.95,
    longitude: 88.03,
    wikiDataId: "Q1464395",
  },
  {
    id: 141991,
    name: "Uttar Dinajpur district",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.96666667,
    longitude: 88.61666667,
    wikiDataId: "Q2330631",
  },
  {
    id: 141992,
    name: "Uttarpara Kotrung",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.65,
    longitude: 88.13,
    wikiDataId: "Q2138465",
  },
  {
    id: 142112,
    name: "Jafarpur",
    state_id: 4853,
    state_code: "WB",
    state_name: "West Bengal",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.84616,
    longitude: 87.33559,
    wikiDataId: "Q2317215",
  },
  {
    id: 142125,
    name: "Bagasara",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.48333333,
    longitude: 70.95,
    wikiDataId: "Q589286",
  },
  {
    id: 142126,
    name: "Dahod",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.52,
    longitude: 74.15,
    wikiDataId: "Q1262380",
  },
  {
    id: 142127,
    name: "Dangs (India)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75,
    longitude: 73.75,
    wikiDataId: "Q26780106",
  },
  {
    id: 142128,
    name: "Delwada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.7833,
    longitude: 71.05,
    wikiDataId: "Q16999192",
  },
  {
    id: 142129,
    name: "Jalalpore",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.9491,
    longitude: 72.9136,
    wikiDataId: "Q2013180",
  },
  {
    id: 142130,
    name: "Jodia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.71667,
    longitude: 70.28333,
    wikiDataId: "Q2758600",
  },
  {
    id: 142131,
    name: "Kavant",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.09282,
    longitude: 74.05078,
    wikiDataId: "Q18011653",
  },
  {
    id: 142132,
    name: "Khambhalia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.2,
    longitude: 69.65,
    wikiDataId: "Q2120093",
  },
  {
    id: 142133,
    name: "Kutch district",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.915,
    longitude: 70.367,
    wikiDataId: "Q1063417",
  },
  {
    id: 142134,
    name: "Mandvi (Surat)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25526,
    longitude: 73.30412,
    wikiDataId: "Q2768275",
  },
  {
    id: 142135,
    name: "Mehsana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.6,
    longitude: 72.4,
    wikiDataId: "Q1922283",
  },
  {
    id: 142136,
    name: "Morva (Hadaf)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.90469,
    longitude: 73.83912,
    wikiDataId: "Q2769626",
  },
  {
    id: 142137,
    name: "Panchmahal district",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.75,
    longitude: 73.6,
    wikiDataId: "Q1781463",
  },
  {
    id: 142138,
    name: "Petlad",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.34472,
    longitude: 73.84093,
    wikiDataId: "Q26788214",
  },
  {
    id: 142139,
    name: "Sikka",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.9167,
    longitude: 72.3833,
    wikiDataId: "Q2572203",
  },
  {
    id: 142140,
    name: "Thasra",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.56666667,
    longitude: 71.18333333,
    wikiDataId: "Q2231030",
  },
  {
    id: 142141,
    name: "Vanala",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.8878,
    longitude: 71.8795,
    wikiDataId: "Q1534153",
  },
  {
    id: 142142,
    name: "Vapi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.45,
    longitude: 73.22,
    wikiDataId: "Q2724780",
  },
  {
    id: 142143,
    name: "Vejalpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.66079,
    longitude: 72.75519,
    wikiDataId: "Q2772372",
  },
  {
    id: 142144,
    name: "Viramgam",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.21027,
    longitude: 71.37967,
    wikiDataId: "Q2773382",
  },
  {
    id: 142145,
    name: "Waghai",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.76666667,
    longitude: 73.48333333,
    wikiDataId: "Q2771789",
  },
  {
    id: 142146,
    name: "Wankaner",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.7,
    longitude: 71.68333333,
    wikiDataId: "Q1023682",
  },
  {
    id: 143761,
    name: "Ishanpur",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.54277778,
    longitude: 75.5075,
    wikiDataId: "Q26791353",
  },
  {
    id: 143762,
    name: "Khamanon",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.69636,
    longitude: 74.85246,
    wikiDataId: "Q31788667",
  },
  {
    id: 143763,
    name: "Jandiala",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.5872,
    longitude: 76.8428,
    wikiDataId: "Q1191548",
  },
  {
    id: 143764,
    name: "Nangal",
    state_id: 4015,
    state_code: "PB",
    state_name: "Punjab",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.63929,
    longitude: 76.11761,
    wikiDataId: "Q25623605",
  },
  {
    id: 147426,
    name: "Bareilly",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.13333333,
    longitude: 79.45,
    wikiDataId: "Q4696659",
  },
  {
    id: 147427,
    name: "Barhalganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.9,
    longitude: 79.43333333,
    wikiDataId: "Q4699054",
  },
  {
    id: 147428,
    name: "Barkhera Kalan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.06547,
    longitude: 77.88084,
    wikiDataId: "Q12413088",
  },
  {
    id: 147429,
    name: "Barwar (Lakhimpur Kheri)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.45,
    longitude: 81.85,
    wikiDataId: "Q162442",
  },
  {
    id: 147430,
    name: "Bela",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.01666667,
    longitude: 80.3,
    wikiDataId: "Q4740641",
  },
  {
    id: 147431,
    name: "Bhargain",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.917,
    longitude: 80.033,
    wikiDataId: "Q4784467",
  },
  {
    id: 147432,
    name: "Bharthana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.68333333,
    longitude: 79.85,
    wikiDataId: "Q4803454",
  },
  {
    id: 147433,
    name: "Bidhuna",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.97638889,
    longitude: 79.22138889,
    wikiDataId: "Q585773",
  },
  {
    id: 147434,
    name: "Bilhaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.46666667,
    longitude: 77.58333333,
    wikiDataId: "Q4841910",
  },
  {
    id: 147435,
    name: "Bisauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.76666667,
    longitude: 78.01666667,
    wikiDataId: "Q4849550",
  },
  {
    id: 147436,
    name: "Bisenda Buzurg",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.16666667,
    longitude: 80.35,
    wikiDataId: "Q4849912",
  },
  {
    id: 147437,
    name: "Bishunpur Urf Maharajganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.41666667,
    longitude: 77.81666667,
    wikiDataId: "Q637824",
  },
  {
    id: 147438,
    name: "Chandpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.9172,
    longitude: 79.8174,
    wikiDataId: "Q4857215",
  },
  {
    id: 147439,
    name: "Chharra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.28333333,
    longitude: 83.5,
    wikiDataId: "Q709260",
  },
  {
    id: 147440,
    name: "Chhata",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.45,
    longitude: 79.8,
    wikiDataId: "Q712381",
  },
  {
    id: 147441,
    name: "Chhitauni",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.91666667,
    longitude: 81.96666667,
    wikiDataId: "Q4862074",
  },
  {
    id: 147442,
    name: "Chillupar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.50583333,
    longitude: 79.13972222,
    wikiDataId: "Q2544454",
  },
  {
    id: 147443,
    name: "Chopan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.12694444,
    longitude: 83.89138889,
    wikiDataId: "Q26791369",
  },
  {
    id: 147444,
    name: "Dadri",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.66027778,
    longitude: 82.12416667,
    wikiDataId: "Q2442700",
  },
  {
    id: 147445,
    name: "Daraganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.23333333,
    longitude: 78.96666667,
    wikiDataId: "Q4901255",
  },
  {
    id: 147446,
    name: "Daranagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.615,
    longitude: 79.14416667,
    wikiDataId: "Q2442034",
  },
  {
    id: 147447,
    name: "Daurala",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.91666667,
    longitude: 79.21666667,
    wikiDataId: "Q4901469",
  },
  {
    id: 147448,
    name: "Dayal Bagh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.34444444,
    longitude: 79.56305556,
    wikiDataId: "Q795774",
  },
  {
    id: 147449,
    name: "Deoranian",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.24,
    longitude: 79.8,
    wikiDataId: "Q4901882",
  },
  {
    id: 147450,
    name: "Derapur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.33333333,
    longitude: 80.28333333,
    wikiDataId: "Q4904163",
  },
  {
    id: 147451,
    name: "Dhampur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.35,
    longitude: 80.68,
    wikiDataId: "Q796734",
  },
  {
    id: 147452,
    name: "Etawah",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.09416667,
    longitude: 77.40083333,
    wikiDataId: "Q4913927",
  },
  {
    id: 147453,
    name: "Etmadpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.38333333,
    longitude: 80.16666667,
    wikiDataId: "Q4913929",
  },
  {
    id: 147454,
    name: "Farah",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.3833,
    longitude: 83.1167,
    wikiDataId: "Q4916036",
  },
  {
    id: 147455,
    name: "Faridnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.53,
    longitude: 86.25,
    wikiDataId: "Q858315",
  },
  {
    id: 147456,
    name: "Farrukhabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.41666667,
    longitude: 80.61666667,
    wikiDataId: "Q859094",
  },
  {
    id: 147457,
    name: "Ganj Dundwara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.45,
    longitude: 78.77,
    wikiDataId: "Q858672",
  },
  {
    id: 147458,
    name: "Ghaziabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.11666667,
    longitude: 83.98333333,
    wikiDataId: "Q5094912",
  },
  {
    id: 147459,
    name: "Gohand",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.2,
    longitude: 80.9,
    wikiDataId: "Q2341590",
  },
  {
    id: 147460,
    name: "Gola Gokarannath",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.65,
    longitude: 83.1,
    wikiDataId: "Q1190845",
  },
  {
    id: 147461,
    name: "Gosainganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.43333333,
    longitude: 81.88333333,
    wikiDataId: "Q5222034",
  },
  {
    id: 147462,
    name: "Goshainganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.6816208,
    longitude: 81.3497579,
    wikiDataId: "Q15212833",
  },
  {
    id: 147463,
    name: "Gursarai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.526,
    longitude: 78.238,
    wikiDataId: "Q955652",
  },
  {
    id: 147464,
    name: "Handia",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.41666667,
    longitude: 79.8,
    wikiDataId: "Q5261353",
  },
  {
    id: 147465,
    name: "Harchandpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.55419444,
    longitude: 83.00806111,
    wikiDataId: "Q5268957",
  },
  {
    id: 147466,
    name: "Hardoi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.38333333,
    longitude: 79.96666667,
    wikiDataId: "Q5269171",
  },
  {
    id: 147467,
    name: "Hata",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.819,
    longitude: 79.149,
    wikiDataId: "Q5284685",
  },
  {
    id: 147468,
    name: "Hathras",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.22,
    longitude: 82.67,
    wikiDataId: "Q5289933",
  },
  {
    id: 147469,
    name: "Indergarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.23971944,
    longitude: 78.2,
    wikiDataId: "Q1524692",
  },
  {
    id: 147470,
    name: "Jahangirpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.17,
    longitude: 81.22,
    wikiDataId: "Q1524065",
  },
  {
    id: 147471,
    name: "Jalesar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.53333333,
    longitude: 79.96666667,
    wikiDataId: "Q5516924",
  },
  {
    id: 147472,
    name: "Janghai",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.497,
    longitude: 83.822,
    wikiDataId: "Q5516927",
  },
  {
    id: 147473,
    name: "Jansath",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.21,
    longitude: 79.16,
    wikiDataId: "Q5517286",
  },
  {
    id: 147474,
    name: "Jasrana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.73277778,
    longitude: 78.94166667,
    wikiDataId: "Q1654851",
  },
  {
    id: 147475,
    name: "Jhansi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.18,
    longitude: 83.82,
    wikiDataId: "Q1656897",
  },
  {
    id: 147476,
    name: "Jhinjhak",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.206,
    longitude: 81.69,
    wikiDataId: "Q15207942",
  },
  {
    id: 147477,
    name: "Kalinagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.8333,
    longitude: 77.8833,
    wikiDataId: "Q6195954",
  },
  {
    id: 147478,
    name: "Kampil",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.77,
    longitude: 81.12,
    wikiDataId: "Q1707530",
  },
  {
    id: 147479,
    name: "Kasganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.9,
    longitude: 79.7,
    wikiDataId: "Q15222978",
  },
  {
    id: 147480,
    name: "Kaushambi District",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.36,
    longitude: 81.17,
    wikiDataId: "Q5654953",
  },
  {
    id: 147481,
    name: "Khalilabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 83.74,
    wikiDataId: "Q3783627",
  },
  {
    id: 147482,
    name: "Khargupur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.69027778,
    longitude: 77.36027778,
    wikiDataId: "Q5935531",
  },
  {
    id: 147483,
    name: "Khatauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.01027778,
    longitude: 83.16861111,
    wikiDataId: "Q2441663",
  },
  {
    id: 147484,
    name: "Khurja",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.91299444,
    longitude: 74.32284167,
    wikiDataId: "Q1753716",
  },
  {
    id: 147485,
    name: "Kirakat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.33,
    longitude: 78.72,
    wikiDataId: "Q2725852",
  },
  {
    id: 147486,
    name: "Kithor",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.6333,
    longitude: 79.8,
    wikiDataId: "Q3806140",
  },
  {
    id: 147487,
    name: "Kundarki",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.55,
    longitude: 82.31666667,
    wikiDataId: "Q15229325",
  },
  {
    id: 147488,
    name: "Kurebhar,saidkhanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.65,
    longitude: 82.51666667,
    wikiDataId: "Q6161281",
  },
  {
    id: 147489,
    name: "Lalitpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.86,
    longitude: 75.28,
    wikiDataId: "Q6190760",
  },
  {
    id: 147490,
    name: "Lucknow",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15,
    longitude: 83.33,
    wikiDataId: "Q2441673",
  },
  {
    id: 147491,
    name: "Mahaban",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.01666667,
    longitude: 77.85,
    wikiDataId: "Q6346377",
  },
  {
    id: 147492,
    name: "Mahroni",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.51912222,
    longitude: 80.24980556,
    wikiDataId: "Q6355006",
  },
  {
    id: 147493,
    name: "Manikpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.93,
    longitude: 83.72,
    wikiDataId: "Q2544447",
  },
  {
    id: 147494,
    name: "Manjhanpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.7,
    longitude: 81.35,
    wikiDataId: "Q6367451",
  },
  {
    id: 147495,
    name: "Marahra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.2,
    longitude: 80.5,
    wikiDataId: "Q6368973",
  },
  {
    id: 147496,
    name: "Mariahu",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.28,
    longitude: 81.93,
    wikiDataId: "Q6368995",
  },
  {
    id: 147497,
    name: "Mau Aimma",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.84305556,
    longitude: 79.60333333,
    wikiDataId: "Q2565866",
  },
  {
    id: 147498,
    name: "Mawar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.35,
    longitude: 78.53,
    wikiDataId: "Q2119908",
  },
  {
    id: 147499,
    name: "Muhammadabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.2,
    longitude: 77.28333333,
    wikiDataId: "Q6416553",
  },
  {
    id: 147500,
    name: "Musafirkhana",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.68333333,
    longitude: 77.95,
    wikiDataId: "Q6441481",
  },
  {
    id: 147501,
    name: "Muzaffarnagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.81666667,
    longitude: 79.4,
    wikiDataId: "Q6441665",
  },
  {
    id: 147502,
    name: "Nagram",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.683,
    longitude: 78.785,
    wikiDataId: "Q2236973",
  },
  {
    id: 147503,
    name: "Nakur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.43333333,
    longitude: 82.11666667,
    wikiDataId: "Q6445846",
  },
  {
    id: 147504,
    name: "Nanpara",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.11666667,
    longitude: 79.28333333,
    wikiDataId: "Q2230673",
  },
  {
    id: 147505,
    name: "Narauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.36666667,
    longitude: 79.41666667,
    wikiDataId: "Q6448420",
  },
  {
    id: 147506,
    name: "Nawabganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.15,
    longitude: 82.21,
    wikiDataId: "Q6481469",
  },
  {
    id: 147507,
    name: "Nawabganj (Bareilly)",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.11666667,
    longitude: 77.76666667,
    wikiDataId: "Q2244269",
  },
  {
    id: 147508,
    name: "Niwari",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.68,
    longitude: 82.42,
    wikiDataId: "Q2233483",
  },
  {
    id: 147509,
    name: "Palia Kalan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.26666667,
    longitude: 83.11666667,
    wikiDataId: "Q2606051",
  },
  {
    id: 147510,
    name: "Paras Rampur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.38333333,
    longitude: 81.28333333,
    wikiDataId: "Q692633",
  },
  {
    id: 147511,
    name: "Parichhatgarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.13333333,
    longitude: 82.81666667,
    wikiDataId: "Q6734974",
  },
  {
    id: 147512,
    name: "Patiali",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.03333333,
    longitude: 79.41666667,
    wikiDataId: "Q15243946",
  },
  {
    id: 147513,
    name: "Pawayan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.90222222,
    longitude: 79.97805556,
    wikiDataId: "Q6738719",
  },
  {
    id: 147514,
    name: "Payagpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.26755,
    longitude: 79.94489,
    wikiDataId: "Q15244118",
  },
  {
    id: 147515,
    name: "Pinahat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.7,
    longitude: 81.91666667,
    wikiDataId: "Q2544231",
  },
  {
    id: 147516,
    name: "Puranpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.43,
    longitude: 80.88,
    wikiDataId: "Q656717",
  },
  {
    id: 147517,
    name: "Purwa",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.30416667,
    longitude: 79.92111111,
    wikiDataId: "Q15252203",
  },
  {
    id: 147518,
    name: "Rabupura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.98333333,
    longitude: 83.11666667,
    wikiDataId: "Q2544297",
  },
  {
    id: 147519,
    name: "Ramkola",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.6,
    longitude: 81.91,
    wikiDataId: "Q3633973",
  },
  {
    id: 147520,
    name: "Robertsganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.4722,
    longitude: 79.6479,
    wikiDataId: "Q3634952",
  },
  {
    id: 147521,
    name: "Rudarpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.65,
    longitude: 82.18,
    wikiDataId: "Q2542953",
  },
  {
    id: 147522,
    name: "Rudauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 30.06741389,
    longitude: 80.23856111,
    wikiDataId: "Q14226032",
  },
  {
    id: 147523,
    name: "Rura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.83888889,
    longitude: 78.77694444,
    wikiDataId: "Q330643",
  },
  {
    id: 147524,
    name: "Sadat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.45,
    longitude: 81.8,
    wikiDataId: "Q2542407",
  },
  {
    id: 147525,
    name: "Sahaswan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.01,
    longitude: 79.51,
    wikiDataId: "Q2552187",
  },
  {
    id: 147526,
    name: "Sambhal",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.19666667,
    longitude: 78.38138889,
    wikiDataId: "Q2441434",
  },
  {
    id: 147527,
    name: "Samthar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.7,
    longitude: 79.65,
    wikiDataId: "Q6981102",
  },
  {
    id: 147528,
    name: "Sandi",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.16666667,
    longitude: 79.78333333,
    wikiDataId: "Q6981316",
  },
  {
    id: 147529,
    name: "Sant Ravi Das Nagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.94,
    longitude: 81.19,
    wikiDataId: "Q608154",
  },
  {
    id: 147530,
    name: "Sarai Akil",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.54,
    longitude: 79.633,
    wikiDataId: "Q1748351",
  },
  {
    id: 147531,
    name: "Sarai Ekdil",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.76666667,
    longitude: 79.28333333,
    wikiDataId: "Q7016573",
  },
  {
    id: 147532,
    name: "Sarauli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 79.15,
    wikiDataId: "Q7034115",
  },
  {
    id: 147533,
    name: "Shamli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.78333333,
    longitude: 77.53333333,
    wikiDataId: "Q29156637",
  },
  {
    id: 147534,
    name: "Shergarh",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 31.32388889,
    longitude: 75.67472222,
    wikiDataId: "Q7135859",
  },
  {
    id: 147535,
    name: "Shikohabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.84441,
    longitude: 78.74037,
    wikiDataId: "Q12437584",
  },
  {
    id: 147536,
    name: "Siddhaur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.4,
    longitude: 81.8,
    wikiDataId: "Q16896504",
  },
  {
    id: 147537,
    name: "Sidhpura",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.53333333,
    longitude: 81.86666667,
    wikiDataId: "Q7180664",
  },
  {
    id: 147538,
    name: "Sikandra",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.08333333,
    longitude: 78.35,
    wikiDataId: "Q7181445",
  },
  {
    id: 147539,
    name: "Sikandrabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.33333333,
    longitude: 79.31666667,
    wikiDataId: "Q7190634",
  },
  {
    id: 147540,
    name: "Siswa Bazar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.71196944,
    longitude: 77.65445556,
    wikiDataId: "Q2718583",
  },
  {
    id: 147541,
    name: "Soron",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.18,
    longitude: 83,
    wikiDataId: "Q2718111",
  },
  {
    id: 147542,
    name: "Talgram",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.55,
    longitude: 77.26666667,
    wikiDataId: "Q7261323",
  },
  {
    id: 147543,
    name: "Terha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.78333333,
    longitude: 79.06666667,
    wikiDataId: "Q7265776",
  },
  {
    id: 147544,
    name: "Thana Bhawan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.52444444,
    longitude: 77.49027778,
    wikiDataId: "Q2037248",
  },
  {
    id: 147545,
    name: "Tori-Fatehpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.9,
    longitude: 77.21666667,
    wikiDataId: "Q7293071",
  },
  {
    id: 147546,
    name: "Unnao",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.36666667,
    longitude: 78.76666667,
    wikiDataId: "Q7309780",
  },
  {
    id: 147547,
    name: "Usawan",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.2,
    longitude: 83.03,
    wikiDataId: "Q2566510",
  },
  {
    id: 147548,
    name: "Utraula",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.55,
    longitude: 83.71666667,
    wikiDataId: "Q7313882",
  },
  {
    id: 147549,
    name: "Walterganj",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.75,
    longitude: 81.75,
    wikiDataId: "Q2551318",
  },
  {
    id: 147550,
    name: "Yusufpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.176049,
    longitude: 79.42012,
    wikiDataId: "Q6116430",
  },
  {
    id: 147551,
    name: "Zafarabad",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.371165,
    longitude: 80.1123,
    wikiDataId: "Q7396692",
  },
  {
    id: 147552,
    name: "Bhowali",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.68333333,
    longitude: 77.4,
    wikiDataId: "Q7399612",
  },
  {
    id: 147553,
    name: "Birbhaddar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.43527778,
    longitude: 78.14138889,
    wikiDataId: "Q2717572",
  },
  {
    id: 147554,
    name: "Chamoli",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.95472222,
    longitude: 79.2275,
    wikiDataId: "Q586504",
  },
  {
    id: 147555,
    name: "Clement Town",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.3,
    longitude: 83.91666667,
    wikiDataId: "Q2542939",
  },
  {
    id: 147556,
    name: "Kalagarh Project Colony",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 29.03333333,
    longitude: 77.23333333,
    wikiDataId: "Q7424739",
  },
  {
    id: 147557,
    name: "Ranikhet",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.5,
    longitude: 79.98333333,
    wikiDataId: "Q7496066",
  },
  {
    id: 147558,
    name: "Roorkee",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.28,
    longitude: 78.02,
    wikiDataId: "Q3959470",
  },
  {
    id: 147559,
    name: "Tanakpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.85722222,
    longitude: 79.115,
    wikiDataId: "Q2717929",
  },
  {
    id: 147560,
    name: "Udham Singh Nagar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.76944444,
    longitude: 81.41805556,
    wikiDataId: "Q2717475",
  },
  {
    id: 147561,
    name: "Amlagora",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.449525,
    longitude: 77.69415278,
    wikiDataId: "Q2717646",
  },
  {
    id: 147562,
    name: "Bally",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.8333,
    longitude: 80.25,
    wikiDataId: "Q7702356",
  },
  {
    id: 147563,
    name: "Baneswar",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 28.51083333,
    longitude: 77.23888889,
    wikiDataId: "Q2734513",
  },
  {
    id: 147564,
    name: "Bansihari community development block",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.3333,
    longitude: 80.4167,
    wikiDataId: "Q4881491",
  },
  {
    id: 147565,
    name: "Barasat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.45,
    longitude: 79.1333,
    wikiDataId: "Q11952191",
  },
  {
    id: 147566,
    name: "Basirhat",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.33333333,
    longitude: 79.25,
    wikiDataId: "Q2717180",
  },
  {
    id: 147567,
    name: "Belda",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.81583333,
    longitude: 79.34861111,
    wikiDataId: "Q2717451",
  },
  {
    id: 147568,
    name: "Beliatore",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 27.2,
    longitude: 83.11666667,
    wikiDataId: "Q7902015",
  },
  {
    id: 147569,
    name: "Bhandardaha",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.1667,
    longitude: 82.5,
    wikiDataId: "Q7932336",
  },
  {
    id: 147570,
    name: "Birbhum district",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 26.8667,
    longitude: 82.7167,
    wikiDataId: "Q7966562",
  },
  {
    id: 147571,
    name: "Bolpur",
    state_id: 4022,
    state_code: "UP",
    state_name: "Uttar Pradesh",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.6667,
    longitude: 83.4667,
    wikiDataId: "Q8062013",
  },
  {
    id: 147572,
    name: "Agol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.15,
    longitude: 72.26666667,
    wikiDataId: "Q4693323",
  },
  {
    id: 147573,
    name: "Akrund",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.28333333,
    longitude: 73.11666667,
    wikiDataId: "Q2721831",
  },
  {
    id: 147574,
    name: "Amod,",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.991,
    longitude: 72.871,
    wikiDataId: "Q2724262",
  },
  {
    id: 147575,
    name: "Bakharla",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.731517,
    longitude: 69.635296,
    wikiDataId: "Q15196194",
  },
  {
    id: 147576,
    name: "Balagam",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.36666667,
    longitude: 70.1,
    wikiDataId: "Q4849800",
  },
  {
    id: 147577,
    name: "Balasinor",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.955891,
    longitude: 73.336499,
    wikiDataId: "Q624405",
  },
  {
    id: 147578,
    name: "Balisana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.816436,
    longitude: 72.257536,
    wikiDataId: "Q2765975",
  },
  {
    id: 147579,
    name: "Bamanbore",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.41666667,
    longitude: 71.01666667,
    wikiDataId: "Q2765812",
  },
  {
    id: 147580,
    name: "Bandia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.39604,
    longitude: 69.01155,
    wikiDataId: "Q625706",
  },
  {
    id: 147581,
    name: "Bhadran",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.3593,
    longitude: 72.9005,
    wikiDataId: "Q2767005",
  },
  {
    id: 147582,
    name: "Bhandu",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.7,
    longitude: 72.36666667,
    wikiDataId: "Q2766842",
  },
  {
    id: 147583,
    name: "Bhatha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.18333333,
    longitude: 72.76666667,
    wikiDataId: "Q4901401",
  },
  {
    id: 147584,
    name: "Bhildi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.18333333,
    longitude: 72.03333333,
    wikiDataId: "Q2767371",
  },
  {
    id: 147585,
    name: "Bhojpur Dharampur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.25,
    longitude: 69.67,
    wikiDataId: "Q798382",
  },
  {
    id: 147586,
    name: "Chaloda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.8,
    longitude: 72.45,
    wikiDataId: "Q5069035",
  },
  {
    id: 147587,
    name: "Champaner",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.4859,
    longitude: 73.5371,
    wikiDataId: "Q11913591",
  },
  {
    id: 147588,
    name: "Chuda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.48333333,
    longitude: 71.68333333,
    wikiDataId: "Q724951",
  },
  {
    id: 147589,
    name: "Dabhoda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.16666667,
    longitude: 72.73333333,
    wikiDataId: "Q5207476",
  },
  {
    id: 147590,
    name: "Dandi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.32988,
    longitude: 72.62484,
    wikiDataId: "Q1026197",
  },
  {
    id: 147591,
    name: "Danta",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.18861111,
    longitude: 72.76583333,
    wikiDataId: "Q3015704",
  },
  {
    id: 147592,
    name: "Detroj",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.33333333,
    longitude: 72.18333333,
    wikiDataId: "Q2761011",
  },
  {
    id: 147593,
    name: "Dhansura",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.35,
    longitude: 73.2,
    wikiDataId: "Q5269024",
  },
  {
    id: 147594,
    name: "Dharasana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.68333333,
    longitude: 72.91666667,
    wikiDataId: "Q15213874",
  },
  {
    id: 147595,
    name: "Dhasa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.8,
    longitude: 71.51666667,
    wikiDataId: "Q5269418",
  },
  {
    id: 147596,
    name: "Dholera",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.248,
    longitude: 72.195,
    wikiDataId: "Q2761956",
  },
  {
    id: 147597,
    name: "Dumkhal",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.7396,
    longitude: 73.8449,
    wikiDataId: "Q2759470",
  },
  {
    id: 147598,
    name: "Ghodasar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.45,
    longitude: 71.85,
    wikiDataId: "Q5556817",
  },
  {
    id: 147599,
    name: "Gorwa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.3301,
    longitude: 73.1611,
    wikiDataId: "Q5586976",
  },
  {
    id: 147600,
    name: "Halenda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.088185,
    longitude: 71.05171,
    wikiDataId: "Q647446",
  },
  {
    id: 147601,
    name: "Harsol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.36,
    longitude: 73.02,
    wikiDataId: "Q2776931",
  },
  {
    id: 147602,
    name: "Hathuran",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.5,
    longitude: 72.97,
    wikiDataId: "Q5681558",
  },
  {
    id: 147603,
    name: "Idar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.83,
    longitude: 73,
    wikiDataId: "Q1755548",
  },
  {
    id: 147604,
    name: "Jakhau",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.21861111,
    longitude: 68.71694444,
    wikiDataId: "Q6125153",
  },
  {
    id: 147605,
    name: "Jalia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.8,
    longitude: 70.25,
    wikiDataId: "Q2758137",
  },
  {
    id: 147606,
    name: "Jambuda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.51666667,
    longitude: 70.21666667,
    wikiDataId: "Q2757726",
  },
  {
    id: 147607,
    name: "Jarod,",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.433,
    longitude: 73.333,
    wikiDataId: "Q2757535",
  },
  {
    id: 147608,
    name: "Jetalpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.88333333,
    longitude: 72.6,
    wikiDataId: "Q15229532",
  },
  {
    id: 147609,
    name: "Jetpur (Navagadh)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.75482,
    longitude: 70.62347,
    wikiDataId: "Q968713",
  },
  {
    id: 147610,
    name: "Jhalod",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.10027778,
    longitude: 74.15611111,
    wikiDataId: "Q3808247",
  },
  {
    id: 147611,
    name: "Jodhpur (Ahmedabad)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.88,
    longitude: 70.03,
    wikiDataId: "Q1609645",
  },
  {
    id: 147612,
    name: "Kachholi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83333333,
    longitude: 72.96666667,
    wikiDataId: "Q2726489",
  },
  {
    id: 147613,
    name: "Kandla port",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.03,
    longitude: 70.22,
    wikiDataId: "Q581480",
  },
  {
    id: 147614,
    name: "Kariana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.88333333,
    longitude: 71.35,
    wikiDataId: "Q2727236",
  },
  {
    id: 147615,
    name: "Karjan",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.05304167,
    longitude: 73.12351389,
    wikiDataId: "Q2295886",
  },
  {
    id: 147616,
    name: "Kayavarohan",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.067,
    longitude: 73.25,
    wikiDataId: "Q2723783",
  },
  {
    id: 147617,
    name: "Kerwada",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.9,
    longitude: 72.85,
    wikiDataId: "Q2728727",
  },
  {
    id: 147618,
    name: "Khavda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.85,
    longitude: 69.72,
    wikiDataId: "Q6401016",
  },
  {
    id: 147619,
    name: "Khedoi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.05927778,
    longitude: 69.91895556,
    wikiDataId: "Q6401200",
  },
  {
    id: 147620,
    name: "Kherali",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.68333333,
    longitude: 71.6,
    wikiDataId: "Q2730823",
  },
  {
    id: 147621,
    name: "Kothara",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.133,
    longitude: 68.932,
    wikiDataId: "Q6434019",
  },
  {
    id: 147622,
    name: "Kotharia",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.23,
    longitude: 70.81,
    wikiDataId: "Q2729203",
  },
  {
    id: 147623,
    name: "Kukarmunda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.51666667,
    longitude: 74.13333333,
    wikiDataId: "Q2728479",
  },
  {
    id: 147624,
    name: "Kukma",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.217822,
    longitude: 69.777922,
    wikiDataId: "Q6442533",
  },
  {
    id: 147625,
    name: "Lakhpat",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.82611111,
    longitude: 68.77694444,
    wikiDataId: "Q2771159",
  },
  {
    id: 147626,
    name: "Langhnaj",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.45,
    longitude: 72.48333333,
    wikiDataId: "Q6486089",
  },
  {
    id: 147627,
    name: "Limkheda",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.81666667,
    longitude: 73.98333333,
    wikiDataId: "Q2771499",
  },
  {
    id: 147628,
    name: "Ladol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.61666667,
    longitude: 72.73333333,
    wikiDataId: "Q6711592",
  },
  {
    id: 147629,
    name: "Madhavpur Ghed",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.29929167,
    longitude: 70.02513889,
    wikiDataId: "Q2723937",
  },
  {
    id: 147630,
    name: "Madhi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.1,
    longitude: 69.1,
    wikiDataId: "Q19810113",
  },
  {
    id: 147631,
    name: "Mahisa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.85,
    longitude: 73.05,
    wikiDataId: "Q2768107",
  },
  {
    id: 147632,
    name: "Mahuva",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.0833,
    longitude: 71.8,
    wikiDataId: "Q1924998",
  },
  {
    id: 147633,
    name: "Mahuva (Surat)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.02,
    longitude: 73.15,
    wikiDataId: "Q2768146",
  },
  {
    id: 147634,
    name: "Mangrol (Junagadh)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.12,
    longitude: 70.12,
    wikiDataId: "Q2577440",
  },
  {
    id: 147635,
    name: "Mithapur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.41,
    longitude: 69,
    wikiDataId: "Q2738453",
  },
  {
    id: 147636,
    name: "Nadiad,",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.7,
    longitude: 72.87,
    wikiDataId: "Q219039",
  },
  {
    id: 147637,
    name: "Nagwa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 25.69621,
    longitude: 84.2359,
    wikiDataId: "Q15260788",
  },
  {
    id: 147638,
    name: "Naldhara",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.96666667,
    longitude: 73.16666667,
    wikiDataId: "Q6960849",
  },
  {
    id: 147639,
    name: "Nargol",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.233,
    longitude: 72.75,
    wikiDataId: "Q2763089",
  },
  {
    id: 147640,
    name: "Nikora",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.78586,
    longitude: 73.13968,
    wikiDataId: "Q15262336",
  },
  {
    id: 147641,
    name: "Nizar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.47727,
    longitude: 74.19595,
    wikiDataId: "Q26928895",
  },
  {
    id: 147642,
    name: "Odadar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.56666667,
    longitude: 69.66666667,
    wikiDataId: "Q2726106",
  },
  {
    id: 147643,
    name: "Palanswa",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.46666667,
    longitude: 70.93333333,
    wikiDataId: "Q7126721",
  },
  {
    id: 147644,
    name: "Paliyad (Bhavnagar)",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.25757,
    longitude: 71.56024,
    wikiDataId: "Q15132884",
  },
  {
    id: 147645,
    name: "Palsana",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.08,
    longitude: 72.98,
    wikiDataId: "Q2763782",
  },
  {
    id: 147646,
    name: "Piplod",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.96666667,
    longitude: 71.56666667,
    wikiDataId: "Q1343195",
  },
  {
    id: 147647,
    name: "Porbandar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.81666667,
    longitude: 73.9,
    wikiDataId: "Q7197451",
  },
  {
    id: 147648,
    name: "Prantij",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.88808,
    longitude: 70.40129,
    wikiDataId: "Q7237228",
  },
  {
    id: 147649,
    name: "Radhanpur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.43841944,
    longitude: 72.85718056,
    wikiDataId: "Q2724388",
  },
  {
    id: 147650,
    name: "Rapar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.3667,
    longitude: 71.75,
    wikiDataId: "Q2770373",
  },
  {
    id: 147651,
    name: "Roha",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.151725,
    longitude: 69.750086,
    wikiDataId: "Q7309730",
  },
  {
    id: 147652,
    name: "Sanand",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.3034,
    longitude: 70.50688,
    wikiDataId: "Q7408505",
  },
  {
    id: 147653,
    name: "Sardoi",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.05,
    longitude: 73.0833,
    wikiDataId: "Q7423512",
  },
  {
    id: 147654,
    name: "Sarkhej",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.5667,
    longitude: 73.2667,
    wikiDataId: "Q2774649",
  },
  {
    id: 147655,
    name: "Savarkundla",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.169125,
    longitude: 73.32661667,
    wikiDataId: "Q7426123",
  },
  {
    id: 147656,
    name: "Sayla",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.56666667,
    longitude: 73.21666667,
    wikiDataId: "Q7428379",
  },
  {
    id: 147657,
    name: "Surendranagar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.762,
    longitude: 70.378,
    wikiDataId: "Q2775816",
  },
  {
    id: 147658,
    name: "Tankara",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.8928,
    longitude: 70.465,
    wikiDataId: "Q2775790",
  },
  {
    id: 147659,
    name: "Umreth",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.45,
    longitude: 73.5,
    wikiDataId: "Q2725556",
  },
  {
    id: 147660,
    name: "Vadodara",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 24.08333333,
    longitude: 72.48333333,
    wikiDataId: "Q2771862",
  },
  {
    id: 147661,
    name: "Vallabhipur",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.3,
    longitude: 73.3833,
    wikiDataId: "Q1935461",
  },
  {
    id: 147662,
    name: "Vanthli",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.45,
    longitude: 71.98333333,
    wikiDataId: "Q7913948",
  },
  {
    id: 147663,
    name: "Vartej",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.4833,
    longitude: 70.3333,
    wikiDataId: "Q16902519",
  },
  {
    id: 147664,
    name: "Vataman",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.82657,
    longitude: 71.02436,
    wikiDataId: "Q2773098",
  },
  {
    id: 147665,
    name: "Veraval",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 22.53,
    longitude: 72.42,
    wikiDataId: "Q2771843",
  },
  {
    id: 147666,
    name: "Visavadar",
    state_id: 4030,
    state_code: "GJ",
    state_name: "Gujarat",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 23.12,
    longitude: 72.03,
    wikiDataId: "Q2451172",
  },
  {
    id: 147667,
    name: "Alibag",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.21666667,
    longitude: 75.45,
    wikiDataId: "Q4680894",
  },
  {
    id: 147668,
    name: "Alkuti",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.173692,
    longitude: 72.988533,
    wikiDataId: "Q4691267",
  },
  {
    id: 147669,
    name: "Allapalli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.41166667,
    longitude: 80.00388889,
    wikiDataId: "Q3606967",
  },
  {
    id: 147670,
    name: "Ambajogai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.08333333,
    longitude: 74.73333333,
    wikiDataId: "Q223517",
  },
  {
    id: 147671,
    name: "Amravati",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.51666667,
    longitude: 76.2,
    wikiDataId: "Q239809",
  },
  {
    id: 147672,
    name: "Amravati Division",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.88333333,
    longitude: 75.01666667,
    wikiDataId: "Q4701451",
  },
  {
    id: 147673,
    name: "Andura",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98333333,
    longitude: 74.66666667,
    wikiDataId: "Q4701525",
  },
  {
    id: 147674,
    name: "Anjarle",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.82423611,
    longitude: 74.21687222,
    wikiDataId: "Q2721836",
  },
  {
    id: 147675,
    name: "Arag",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.17,
    longitude: 74.12,
    wikiDataId: "Q4714044",
  },
  {
    id: 147676,
    name: "Ardhapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.05,
    longitude: 74.23,
    wikiDataId: "Q4727727",
  },
  {
    id: 147677,
    name: "Assaye",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.209,
    longitude: 73.186,
    wikiDataId: "Q584008",
  },
  {
    id: 147678,
    name: "Astagaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.11666667,
    longitude: 73.73333333,
    wikiDataId: "Q4741194",
  },
  {
    id: 147679,
    name: "Aundh, Satara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.65,
    longitude: 79.95,
    wikiDataId: "Q4745985",
  },
  {
    id: 147680,
    name: "Badnapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.11916667,
    longitude: 72.84694444,
    wikiDataId: "Q12413015",
  },
  {
    id: 147681,
    name: "Badnera",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.88333333,
    longitude: 76.86666667,
    wikiDataId: "Q4760315",
  },
  {
    id: 147682,
    name: "Balapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.85,
    longitude: 73.09,
    wikiDataId: "Q4765785",
  },
  {
    id: 147683,
    name: "Ballalpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.78,
    longitude: 74.8,
    wikiDataId: "Q4783707",
  },
  {
    id: 147684,
    name: "Ballarpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.28333333,
    longitude: 77.38333333,
    wikiDataId: "Q4788035",
  },
  {
    id: 147685,
    name: "Banda, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.778999,
    longitude: 73.64553,
    wikiDataId: "Q4682444",
  },
  {
    id: 147686,
    name: "Barsi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.03333333,
    longitude: 75.6,
    wikiDataId: "Q4807547",
  },
  {
    id: 147687,
    name: "Basmat",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.24512778,
    longitude: 75.88739722,
    wikiDataId: "Q739902",
  },
  {
    id: 147688,
    name: "Basmath",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.66666667,
    longitude: 74.5,
    wikiDataId: "Q4810511",
  },
  {
    id: 147689,
    name: "Bavdhan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.54583333,
    longitude: 74.375,
    wikiDataId: "Q15194005",
  },
  {
    id: 147690,
    name: "Bhandara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.86666667,
    longitude: 75.73333333,
    wikiDataId: "Q2878729",
  },
  {
    id: 147691,
    name: "Bhandardara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.85463056,
    longitude: 77.72931111,
    wikiDataId: "Q4841063",
  },
  {
    id: 147692,
    name: "Bhandup",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.29,
    longitude: 74.6,
    wikiDataId: "Q4841558",
  },
  {
    id: 147693,
    name: "Bhigvan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.66612,
    longitude: 76.77386,
    wikiDataId: "Q2315612",
  },
  {
    id: 147694,
    name: "Bhiwapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.95,
    longitude: 72.84,
    wikiDataId: "Q4851507",
  },
  {
    id: 147695,
    name: "Bhokar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.83333333,
    longitude: 79.35,
    wikiDataId: "Q633427",
  },
  {
    id: 147696,
    name: "Bhokardan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.81273611,
    longitude: 73.86132778,
    wikiDataId: "Q4854281",
  },
  {
    id: 147697,
    name: "Bhoom",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.05444444,
    longitude: 72.84055556,
    wikiDataId: "Q257622",
  },
  {
    id: 147698,
    name: "Bhor",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.56,
    longitude: 73.79027778,
    wikiDataId: "Q4801087",
  },
  {
    id: 147699,
    name: "Bhudgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.98333333,
    longitude: 73.05,
    wikiDataId: "Q4856435",
  },
  {
    id: 147700,
    name: "Bhusawal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.3167,
    longitude: 77.1667,
    wikiDataId: "Q648370",
  },
  {
    id: 147701,
    name: "Bid",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.53527778,
    longitude: 73.78277778,
    wikiDataId: "Q15197602",
  },
  {
    id: 147702,
    name: "Bijur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.08333333,
    longitude: 76.71666667,
    wikiDataId: "Q4873756",
  },
  {
    id: 147703,
    name: "Biloli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.16666667,
    longitude: 75.23333333,
    wikiDataId: "Q2766664",
  },
  {
    id: 147704,
    name: "Borgaon Manju",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.52916667,
    longitude: 73.75138889,
    wikiDataId: "Q281031",
  },
  {
    id: 147705,
    name: "Borivali",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.14,
    longitude: 72.93,
    wikiDataId: "Q4900993",
  },
  {
    id: 147706,
    name: "Buldana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83555556,
    longitude: 79.50111111,
    wikiDataId: "Q4901861",
  },
  {
    id: 147707,
    name: "Buldhana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.217803,
    longitude: 77.669392,
    wikiDataId: "Q4901919",
  },
  {
    id: 147708,
    name: "Byculla",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.27,
    longitude: 75.77,
    wikiDataId: "Q796505",
  },
  {
    id: 147709,
    name: "Chakan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.45972222,
    longitude: 75.66083333,
    wikiDataId: "Q796520",
  },
  {
    id: 147710,
    name: "Chanda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.501284,
    longitude: 73.750161,
    wikiDataId: "Q15198984",
  },
  {
    id: 147711,
    name: "Chandrapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.05,
    longitude: 75.77,
    wikiDataId: "Q796650",
  },
  {
    id: 147712,
    name: "Chandur Bazar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 13.84388889,
    longitude: 74.63972222,
    wikiDataId: "Q4907289",
  },
  {
    id: 147713,
    name: "Chausala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.98333,
    longitude: 74.03333,
    wikiDataId: "Q4907558",
  },
  {
    id: 147714,
    name: "Chichondi Patil",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.71944444,
    longitude: 77.15277778,
    wikiDataId: "Q4945014",
  },
  {
    id: 147715,
    name: "Chikhli (Buldhana)",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.23,
    longitude: 72.86,
    wikiDataId: "Q4945504",
  },
  {
    id: 147716,
    name: "Chimur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.6084,
    longitude: 79.8559,
    wikiDataId: "Q2546796",
  },
  {
    id: 147717,
    name: "Chinchani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.967,
    longitude: 72.805,
    wikiDataId: "Q4959100",
  },
  {
    id: 147718,
    name: "Chiplun",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.53333333,
    longitude: 76.18333333,
    wikiDataId: "Q47934",
  },
  {
    id: 147719,
    name: "Chopda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98,
    longitude: 72.835,
    wikiDataId: "Q5003979",
  },
  {
    id: 147720,
    name: "Dabhol",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.51305556,
    longitude: 76.87527778,
    wikiDataId: "Q5068446",
  },
  {
    id: 147721,
    name: "Dahivel",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15,
    longitude: 74,
    wikiDataId: "Q5071026",
  },
  {
    id: 147722,
    name: "Daulatabad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.708692,
    longitude: 75.691595,
    wikiDataId: "Q5088367",
  },
  {
    id: 147723,
    name: "Daund",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.05871111,
    longitude: 72.89969444,
    wikiDataId: "Q251170",
  },
  {
    id: 147724,
    name: "Deglur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19,
    longitude: 74.91666667,
    wikiDataId: "Q5096066",
  },
  {
    id: 147725,
    name: "Deolali",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.02193,
    longitude: 75.78544,
    wikiDataId: "Q87192562",
  },
  {
    id: 147726,
    name: "Deolapar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.49694444,
    longitude: 79.37666667,
    wikiDataId: "Q5099456",
  },
  {
    id: 147727,
    name: "Deulgaon Raja",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98333333,
    longitude: 72.83333333,
    wikiDataId: "Q5100316",
  },
  {
    id: 147728,
    name: "Dharavi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.91,
    longitude: 72.81,
    wikiDataId: "Q3632559",
  },
  {
    id: 147729,
    name: "Dharur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.06666667,
    longitude: 74.43333333,
    wikiDataId: "Q5207937",
  },
  {
    id: 147730,
    name: "Dhule",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.06666667,
    longitude: 74.16666667,
    wikiDataId: "Q5208849",
  },
  {
    id: 147731,
    name: "Dhulia",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.75888889,
    longitude: 73.18555556,
    wikiDataId: "Q5221789",
  },
  {
    id: 147732,
    name: "Dombivli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.5184,
    longitude: 73.8406,
    wikiDataId: "Q4991859",
  },
  {
    id: 147733,
    name: "Dondaicha",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.54777778,
    longitude: 77.57722222,
    wikiDataId: "Q1192446",
  },
  {
    id: 147734,
    name: "Durgapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.58611111,
    longitude: 79.36944444,
    wikiDataId: "Q5259979",
  },
  {
    id: 147735,
    name: "Erandol",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.26388889,
    longitude: 77.08222222,
    wikiDataId: "Q5260026",
  },
  {
    id: 147736,
    name: "Fort",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.065,
    longitude: 73.61583333,
    wikiDataId: "Q5267962",
  },
  {
    id: 147737,
    name: "Gadhinglaj",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.05,
    longitude: 72.86667,
    wikiDataId: "Q649632",
  },
  {
    id: 147738,
    name: "Ganpatipule",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.16666667,
    longitude: 74.51666667,
    wikiDataId: "Q5269466",
  },
  {
    id: 147739,
    name: "Ghatanji",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.8862,
    longitude: 79.9342,
    wikiDataId: "Q15298095",
  },
  {
    id: 147740,
    name: "Ghugus",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.2,
    longitude: 73.83305556,
    wikiDataId: "Q5278010",
  },
  {
    id: 147741,
    name: "Girgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.17333333,
    longitude: 72.99166667,
    wikiDataId: "Q5283881",
  },
  {
    id: 147742,
    name: "Gorai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.28333333,
    longitude: 72.78333333,
    wikiDataId: "Q5296011",
  },
  {
    id: 147743,
    name: "Hadapsar, Pune",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20,
    longitude: 79.3,
    wikiDataId: "Q3716506",
  },
  {
    id: 147744,
    name: "Harangul",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.935,
    longitude: 72.8359,
    wikiDataId: "Q5470698",
  },
  {
    id: 147745,
    name: "Hirapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.1448,
    longitude: 73.2666,
    wikiDataId: "Q5521478",
  },
  {
    id: 147746,
    name: "Hotgi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.748684,
    longitude: 74.636734,
    wikiDataId: "Q15221606",
  },
  {
    id: 147747,
    name: "Igatpuri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.08,
    longitude: 72.91,
    wikiDataId: "Q3309774",
  },
  {
    id: 147748,
    name: "Jaitapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.953,
    longitude: 72.813,
    wikiDataId: "Q1931371",
  },
  {
    id: 147749,
    name: "Jakhangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.45,
    longitude: 80.2,
    wikiDataId: "Q1707857",
  },
  {
    id: 147750,
    name: "Jalgaon Jamod",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.250057,
    longitude: 72.782021,
    wikiDataId: "Q4932603",
  },
  {
    id: 147751,
    name: "Jamkhed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.49666667,
    longitude: 73.94166667,
    wikiDataId: "Q5637596",
  },
  {
    id: 147752,
    name: "Janephal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.16666667,
    longitude: 74.46666667,
    wikiDataId: "Q5642513",
  },
  {
    id: 147753,
    name: "Jaoli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.9,
    longitude: 76.66666667,
    wikiDataId: "Q5654144",
  },
  {
    id: 147754,
    name: "Jategaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.385,
    longitude: 73.735,
    wikiDataId: "Q15223544",
  },
  {
    id: 147755,
    name: "Jejuri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.53,
    longitude: 79.77,
    wikiDataId: "Q1707583",
  },
  {
    id: 147756,
    name: "Jogeshwari",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.58333333,
    longitude: 75.98333333,
    wikiDataId: "Q13122399",
  },
  {
    id: 147757,
    name: "Kadus",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.59,
    longitude: 73.35,
    wikiDataId: "Q3160598",
  },
  {
    id: 147758,
    name: "Kagal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.64639,
    longitude: 74.32111,
    wikiDataId: "Q6125136",
  },
  {
    id: 147759,
    name: "Kalamb, Osmanabad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.62916667,
    longitude: 77.18111111,
    wikiDataId: "Q6126876",
  },
  {
    id: 147760,
    name: "Kalamnuri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.72,
    longitude: 75.32,
    wikiDataId: "Q510832",
  },
  {
    id: 147761,
    name: "Kalas",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.1,
    longitude: 76.6,
    wikiDataId: "Q6148130",
  },
  {
    id: 147762,
    name: "Kali(DK)",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.29361111,
    longitude: 76.57583333,
    wikiDataId: "Q15229313",
  },
  {
    id: 147763,
    name: "Kalmeshwar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.9,
    longitude: 76.4,
    wikiDataId: "Q6156428",
  },
  {
    id: 147764,
    name: "Kalundri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.05,
    longitude: 75.21666667,
    wikiDataId: "Q6164443",
  },
  {
    id: 147765,
    name: "Kalyan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.91666667,
    longitude: 73.65,
    wikiDataId: "Q6164539",
  },
  {
    id: 147766,
    name: "Kamargaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.78350556,
    longitude: 74.56644167,
    wikiDataId: "Q547588",
  },
  {
    id: 147767,
    name: "Kamthi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.12,
    longitude: 72.85,
    wikiDataId: "Q5456464",
  },
  {
    id: 147768,
    name: "Kandri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.1,
    longitude: 72.83,
    wikiDataId: "Q674362",
  },
  {
    id: 147769,
    name: "Kankavli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.3333,
    longitude: 79.3833,
    wikiDataId: "Q6344728",
  },
  {
    id: 147770,
    name: "Kannad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.3,
    longitude: 74.35,
    wikiDataId: "Q13640586",
  },
  {
    id: 147771,
    name: "Karad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.88333333,
    longitude: 73.81666667,
    wikiDataId: "Q6345782",
  },
  {
    id: 147772,
    name: "Karanja",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.7,
    longitude: 76.08333333,
    wikiDataId: "Q6347714",
  },
  {
    id: 147773,
    name: "Karjat",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.57416667,
    longitude: 76.02166667,
    wikiDataId: "Q3812558",
  },
  {
    id: 147774,
    name: "Karkamb",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.03333,
    longitude: 73.1,
    wikiDataId: "Q5457414",
  },
  {
    id: 147775,
    name: "Kasoda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.938778,
    longitude: 77.732857,
    wikiDataId: "Q6352372",
  },
  {
    id: 147776,
    name: "Khadki",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.5481,
    longitude: 73.9033,
    wikiDataId: "Q6354979",
  },
  {
    id: 147777,
    name: "Khalapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.61666667,
    longitude: 77.5,
    wikiDataId: "Q6356028",
  },
  {
    id: 147778,
    name: "Khallar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.119,
    longitude: 75.8482,
    wikiDataId: "Q6356167",
  },
  {
    id: 147779,
    name: "Khamgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.2333,
    longitude: 79.2,
    wikiDataId: "Q2283184",
  },
  {
    id: 147780,
    name: "Kharakvasla",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.28551111,
    longitude: 73.68446111,
    wikiDataId: "Q2549200",
  },
  {
    id: 147781,
    name: "Kharsundi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.11666667,
    longitude: 75.58333333,
    wikiDataId: "Q6368045",
  },
  {
    id: 147782,
    name: "Khede",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.4833,
    longitude: 77.4833,
    wikiDataId: "Q2317517",
  },
  {
    id: 147783,
    name: "Khoni",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.8653,
    longitude: 75.2964,
    wikiDataId: "Q16893153",
  },
  {
    id: 147784,
    name: "Khuldabad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.6333,
    longitude: 73.4833,
    wikiDataId: "Q2390873",
  },
  {
    id: 147785,
    name: "Kinwat",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.81666667,
    longitude: 75.3,
    wikiDataId: "Q6374615",
  },
  {
    id: 147786,
    name: "Kondalwadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.16666667,
    longitude: 75.63333333,
    wikiDataId: "Q6377849",
  },
  {
    id: 147787,
    name: "Kopar Khairane",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.81666667,
    longitude: 73.26666667,
    wikiDataId: "Q4494967",
  },
  {
    id: 147788,
    name: "Kopargaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.0402869,
    longitude: 77.4656296,
    wikiDataId: "Q6399815",
  },
  {
    id: 147789,
    name: "Koradi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.26111111,
    longitude: 74.70166667,
    wikiDataId: "Q6400170",
  },
  {
    id: 147790,
    name: "Koregaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.05918056,
    longitude: 74.01262778,
    wikiDataId: "Q3595887",
  },
  {
    id: 147791,
    name: "Koynanagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.5,
    longitude: 78.61666667,
    wikiDataId: "Q6400302",
  },
  {
    id: 147792,
    name: "Kurandvad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.63753,
    longitude: 75.47513,
    wikiDataId: "Q6400611",
  },
  {
    id: 147793,
    name: "Kurankhed",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.03614722,
    longitude: 73.06172222,
    wikiDataId: "Q2722343",
  },
  {
    id: 147794,
    name: "Kurduvadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.341585,
    longitude: 74.775996,
    wikiDataId: "Q15236521",
  },
  {
    id: 147795,
    name: "Lakhandur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.91,
    longitude: 74.63,
    wikiDataId: "Q6401184",
  },
  {
    id: 147796,
    name: "Lasalgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.3113,
    longitude: 73.0544,
    wikiDataId: "Q2248426",
  },
  {
    id: 147797,
    name: "Mahad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.15,
    longitude: 78.61666667,
    wikiDataId: "Q6428989",
  },
  {
    id: 147798,
    name: "Mahape",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.10305556,
    longitude: 73.01055556,
    wikiDataId: "Q4934244",
  },
  {
    id: 147799,
    name: "Maindargi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.83,
    longitude: 80.23,
    wikiDataId: "Q6431067",
  },
  {
    id: 147800,
    name: "Malegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.01082222,
    longitude: 79.35242222,
    wikiDataId: "Q6442100",
  },
  {
    id: 147801,
    name: "Malvan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.70194444,
    longitude: 77.24972222,
    wikiDataId: "Q6445605",
  },
  {
    id: 147802,
    name: "Mandangad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.91666667,
    longitude: 74.65,
    wikiDataId: "Q6448221",
  },
  {
    id: 147803,
    name: "Mandhal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.75,
    longitude: 79.88333333,
    wikiDataId: "Q6479500",
  },
  {
    id: 147804,
    name: "Manjlegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.40528056,
    longitude: 73.50626944,
    wikiDataId: "Q989925",
  },
  {
    id: 147805,
    name: "Manwat",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.11776,
    longitude: 73.026938,
    wikiDataId: "Q15243710",
  },
  {
    id: 147806,
    name: "Manwath",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.035,
    longitude: 72.84,
    wikiDataId: "Q674501",
  },
  {
    id: 147807,
    name: "Matheran",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.95,
    longitude: 72.795,
    wikiDataId: "Q6373912",
  },
  {
    id: 147808,
    name: "Matunga",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.18611111,
    longitude: 72.84861111,
    wikiDataId: "Q6740929",
  },
  {
    id: 147809,
    name: "Mhasla",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.98333333,
    longitude: 73.25,
    wikiDataId: "Q15244520",
  },
  {
    id: 147810,
    name: "Mhasvad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.95,
    longitude: 79.46666667,
    wikiDataId: "Q6748015",
  },
  {
    id: 147811,
    name: "Miraj",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.803,
    longitude: 72.882,
    wikiDataId: "Q6748267",
  },
  {
    id: 147812,
    name: "Mohadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.23333333,
    longitude: 73.28333333,
    wikiDataId: "Q6748809",
  },
  {
    id: 147813,
    name: "Mohpa",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.15,
    longitude: 76.23,
    wikiDataId: "Q2546703",
  },
  {
    id: 147814,
    name: "Mokhada taluka",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.35416667,
    longitude: 75.63916667,
    wikiDataId: "Q6750510",
  },
  {
    id: 147815,
    name: "Mora, Maharashtra",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.05,
    longitude: 72.93,
    wikiDataId: "Q4856120",
  },
  {
    id: 147816,
    name: "Mukher",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.3,
    longitude: 76.5,
    wikiDataId: "Q2546880",
  },
  {
    id: 147817,
    name: "Mul",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.1,
    longitude: 78.81666667,
    wikiDataId: "Q15246932",
  },
  {
    id: 147818,
    name: "Mulher",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.08333333,
    longitude: 74.55,
    wikiDataId: "Q6785553",
  },
  {
    id: 147819,
    name: "Mulund",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.4,
    longitude: 74.15,
    wikiDataId: "Q6785717",
  },
  {
    id: 147820,
    name: "Mumbai Suburban",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.01798056,
    longitude: 72.84476389,
    wikiDataId: "Q5226775",
  },
  {
    id: 147821,
    name: "Murbad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.97,
    longitude: 72.85,
    wikiDataId: "Q5121900",
  },
  {
    id: 147822,
    name: "Murtajapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.96666667,
    longitude: 74.63333333,
    wikiDataId: "Q6809474",
  },
  {
    id: 147823,
    name: "Murud (Raigad)",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.15,
    longitude: 76.575,
    wikiDataId: "Q2673732",
  },
  {
    id: 147824,
    name: "Nadgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.83,
    longitude: 74.63,
    wikiDataId: "Q522760",
  },
  {
    id: 147825,
    name: "Nagapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.30833333,
    longitude: 79.675,
    wikiDataId: "Q6890830",
  },
  {
    id: 147826,
    name: "Nagothana",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.8167,
    longitude: 75.6667,
    wikiDataId: "Q6894280",
  },
  {
    id: 147827,
    name: "Nagpur Division",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.93333333,
    longitude: 73.33333333,
    wikiDataId: "Q6895283",
  },
  {
    id: 147828,
    name: "Nala Sopara",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.889,
    longitude: 72.934,
    wikiDataId: "Q6908888",
  },
  {
    id: 147829,
    name: "Nandura",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.78138889,
    longitude: 74.06388889,
    wikiDataId: "Q6933986",
  },
  {
    id: 147830,
    name: "Nandura Buzurg",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.17168056,
    longitude: 72.956,
    wikiDataId: "Q6935217",
  },
  {
    id: 147831,
    name: "Nashik Division",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.77494444,
    longitude: 73.11815,
    wikiDataId: "Q6939780",
  },
  {
    id: 147832,
    name: "Navapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.78777778,
    longitude: 76.47,
    wikiDataId: "Q2546781",
  },
  {
    id: 147833,
    name: "Navi Mumbai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.90101389,
    longitude: 76.01396389,
    wikiDataId: "Q6957648",
  },
  {
    id: 147834,
    name: "Neral",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.87,
    longitude: 76.43,
    wikiDataId: "Q2546917",
  },
  {
    id: 147835,
    name: "Nilanga",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.4154,
    longitude: 72.8613,
    wikiDataId: "Q12435629",
  },
  {
    id: 147836,
    name: "Niphad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.41666667,
    longitude: 76.81666667,
    wikiDataId: "Q6960863",
  },
  {
    id: 147837,
    name: "Nira Narsingpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.72861111,
    longitude: 74.31083333,
    wikiDataId: "Q6962047",
  },
  {
    id: 147838,
    name: "Pabal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.455,
    longitude: 75.84555556,
    wikiDataId: "Q6963358",
  },
  {
    id: 147839,
    name: "Pachora",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.83333333,
    longitude: 76.45972222,
    wikiDataId: "Q546955",
  },
  {
    id: 147840,
    name: "Palghar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.07,
    longitude: 73.97,
    wikiDataId: "Q6965557",
  },
  {
    id: 147841,
    name: "Pali, Raigad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.2,
    longitude: 74.81666667,
    wikiDataId: "Q6965708",
  },
  {
    id: 147842,
    name: "Palso",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.92,
    longitude: 72.83,
    wikiDataId: "Q3632268",
  },
  {
    id: 147843,
    name: "Panchgani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.47,
    longitude: 78.53,
    wikiDataId: "Q938857",
  },
  {
    id: 147844,
    name: "Panhala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.17,
    longitude: 73.78,
    wikiDataId: "Q2722255",
  },
  {
    id: 147845,
    name: "Parel",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 15.98333333,
    longitude: 73.65,
    wikiDataId: "Q6996771",
  },
  {
    id: 147846,
    name: "Parli Vaijnath",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.53333333,
    longitude: 74.93333333,
    wikiDataId: "Q7003750",
  },
  {
    id: 147847,
    name: "Parner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.95972222,
    longitude: 74.27694444,
    wikiDataId: "Q15262323",
  },
  {
    id: 147848,
    name: "Partur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.0833,
    longitude: 73.8,
    wikiDataId: "Q2763226",
  },
  {
    id: 147849,
    name: "Pathardi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.9835,
    longitude: 75.12396,
    wikiDataId: "Q7039851",
  },
  {
    id: 147850,
    name: "Paturda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.83083333,
    longitude: 74.05277778,
    wikiDataId: "Q7121516",
  },
  {
    id: 147851,
    name: "Pauni",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.7,
    longitude: 75.68333333,
    wikiDataId: "Q7124601",
  },
  {
    id: 147852,
    name: "Peint",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.541384,
    longitude: 73.219965,
    wikiDataId: "Q3891768",
  },
  {
    id: 147853,
    name: "Pen",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.76666667,
    longitude: 77.23333333,
    wikiDataId: "Q7128747",
  },
  {
    id: 147854,
    name: "Piliv",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.829052,
    longitude: 73.854745,
    wikiDataId: "Q15265284",
  },
  {
    id: 147855,
    name: "Pimpri-Chinchwad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.99,
    longitude: 72.84,
    wikiDataId: "Q4856263",
  },
  {
    id: 147856,
    name: "Powai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.003,
    longitude: 74.438,
    wikiDataId: "Q7139330",
  },
  {
    id: 147857,
    name: "Prakasha",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.38333333,
    longitude: 79.15,
    wikiDataId: "Q7139955",
  },
  {
    id: 147858,
    name: "Purna",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.95,
    longitude: 76.71666667,
    wikiDataId: "Q7148764",
  },
  {
    id: 147859,
    name: "Pusad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.52416667,
    longitude: 73.61583333,
    wikiDataId: "Q4801215",
  },
  {
    id: 147860,
    name: "Radhanagari",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.78,
    longitude: 79.63,
    wikiDataId: "Q2719440",
  },
  {
    id: 147861,
    name: "Rahimatpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.51166667,
    longitude: 74.7075,
    wikiDataId: "Q7159163",
  },
  {
    id: 147862,
    name: "Rahuri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.25844722,
    longitude: 73.50305278,
    wikiDataId: "Q7160927",
  },
  {
    id: 147863,
    name: "Rajgurunagar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.1,
    longitude: 75.41666667,
    wikiDataId: "Q7188306",
  },
  {
    id: 147864,
    name: "Rajur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.678842,
    longitude: 74.966412,
    wikiDataId: "Q7194065",
  },
  {
    id: 147865,
    name: "Rajura",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.16666667,
    longitude: 73.98333333,
    wikiDataId: "Q7194685",
  },
  {
    id: 147866,
    name: "Ralegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.71666667,
    longitude: 76.43333333,
    wikiDataId: "Q7194687",
  },
  {
    id: 147867,
    name: "Ramtek",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.62777778,
    longitude: 73.81305556,
    wikiDataId: "Q119219",
  },
  {
    id: 147868,
    name: "Renapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.016905,
    longitude: 72.828649,
    wikiDataId: "Q5456385",
  },
  {
    id: 147869,
    name: "Renavi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.51666667,
    longitude: 74.31666667,
    wikiDataId: "Q7238200",
  },
  {
    id: 147870,
    name: "Sailu",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.75,
    longitude: 74.63333333,
    wikiDataId: "Q7260576",
  },
  {
    id: 147871,
    name: "Sakol",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.45,
    longitude: 73.36666667,
    wikiDataId: "Q7260809",
  },
  {
    id: 147872,
    name: "Samudrapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.41388889,
    longitude: 73.99777778,
    wikiDataId: "Q7280215",
  },
  {
    id: 147873,
    name: "Sangameshwar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.71666667,
    longitude: 74.48333333,
    wikiDataId: "Q7283322",
  },
  {
    id: 147874,
    name: "Sangole",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.05,
    longitude: 73.73333333,
    wikiDataId: "Q7285054",
  },
  {
    id: 147875,
    name: "Sasvad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.25,
    longitude: 79.81,
    wikiDataId: "Q7286873",
  },
  {
    id: 147876,
    name: "Saswad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.03333333,
    longitude: 74.35,
    wikiDataId: "Q7289479",
  },
  {
    id: 147877,
    name: "Satpati",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.51666667,
    longitude: 76.6,
    wikiDataId: "Q7312547",
  },
  {
    id: 147878,
    name: "Savantvadi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.26972222,
    longitude: 74.61055556,
    wikiDataId: "Q7312752",
  },
  {
    id: 147879,
    name: "Savlaj",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.55,
    longitude: 72.93333333,
    wikiDataId: "Q7317762",
  },
  {
    id: 147880,
    name: "Sevagram",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.47,
    longitude: 76.47,
    wikiDataId: "Q2718737",
  },
  {
    id: 147881,
    name: "Sewri",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.2825,
    longitude: 76.88444444,
    wikiDataId: "Q15274503",
  },
  {
    id: 147882,
    name: "Shahada",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.08,
    longitude: 79.98,
    wikiDataId: "Q7403046",
  },
  {
    id: 147883,
    name: "Shahapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.99027778,
    longitude: 74.31444444,
    wikiDataId: "Q13121881",
  },
  {
    id: 147884,
    name: "Shedbal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.65,
    longitude: 78.96666667,
    wikiDataId: "Q2774601",
  },
  {
    id: 147885,
    name: "Shegaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.18694444,
    longitude: 73.55305556,
    wikiDataId: "Q7417805",
  },
  {
    id: 147886,
    name: "Shirala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.43944444,
    longitude: 75.19388889,
    wikiDataId: "Q2544948",
  },
  {
    id: 147887,
    name: "Shirdi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.03,
    longitude: 76.68,
    wikiDataId: "Q7418104",
  },
  {
    id: 147888,
    name: "Shirgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.08133056,
    longitude: 79.78286111,
    wikiDataId: "Q7420857",
  },
  {
    id: 147889,
    name: "Shirpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.433273,
    longitude: 74.526421,
    wikiDataId: "Q7423132",
  },
  {
    id: 147890,
    name: "Shirwal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.55,
    longitude: 74,
    wikiDataId: "Q2544796",
  },
  {
    id: 147891,
    name: "Sindewahi",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.71666667,
    longitude: 72.7,
    wikiDataId: "Q7426445",
  },
  {
    id: 147892,
    name: "Sindkheda",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.1,
    longitude: 74.78,
    wikiDataId: "Q7428375",
  },
  {
    id: 147893,
    name: "Sinnar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16,
    longitude: 73.75,
    wikiDataId: "Q1130165",
  },
  {
    id: 147894,
    name: "Sironcha",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.73499167,
    longitude: 78.66256944,
    wikiDataId: "Q3630222",
  },
  {
    id: 147895,
    name: "Sirur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19,
    longitude: 72.86,
    wikiDataId: "Q4933909",
  },
  {
    id: 147896,
    name: "Sonala",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.68916667,
    longitude: 74.75425556,
    wikiDataId: "Q7492294",
  },
  {
    id: 147897,
    name: "Songir",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.35,
    longitude: 75.23333333,
    wikiDataId: "Q7495714",
  },
  {
    id: 147898,
    name: "Sonvad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.69361111,
    longitude: 74.13805556,
    wikiDataId: "Q15277258",
  },
  {
    id: 147899,
    name: "Srivardhan",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.98401111,
    longitude: 74.12415278,
    wikiDataId: "Q7498256",
  },
  {
    id: 147900,
    name: "Talbid",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.73333333,
    longitude: 74.6,
    wikiDataId: "Q7498928",
  },
  {
    id: 147901,
    name: "Talegaon Dhamdhere",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.71666667,
    longitude: 74.9,
    wikiDataId: "Q7498993",
  },
  {
    id: 147902,
    name: "Tasgaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.28333333,
    longitude: 79.65,
    wikiDataId: "Q2775412",
  },
  {
    id: 147903,
    name: "Thane",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.26666667,
    longitude: 74.73333333,
    wikiDataId: "Q2773905",
  },
  {
    id: 147904,
    name: "Trombay",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.04,
    longitude: 72.86,
    wikiDataId: "Q7525343",
  },
  {
    id: 147905,
    name: "Tuljapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.83,
    longitude: 79.96,
    wikiDataId: "Q7530566",
  },
  {
    id: 147906,
    name: "Udgir",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.75722222,
    longitude: 82.08861111,
    wikiDataId: "Q13010473",
  },
  {
    id: 147907,
    name: "Umarga",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.11666667,
    longitude: 76.73333333,
    wikiDataId: "Q15278521",
  },
  {
    id: 147908,
    name: "Umred",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.08333333,
    longitude: 74.78333333,
    wikiDataId: "Q7561272",
  },
  {
    id: 147909,
    name: "Uran",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.08333333,
    longitude: 75.35,
    wikiDataId: "Q7562416",
  },
  {
    id: 147910,
    name: "Vadner",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.6167,
    longitude: 74.8,
    wikiDataId: "Q7678449",
  },
  {
    id: 147911,
    name: "Vaijapur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.35,
    longitude: 74.13,
    wikiDataId: "Q7679000",
  },
  {
    id: 147912,
    name: "Valsang",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.6678,
    longitude: 74.1536,
    wikiDataId: "Q16901085",
  },
  {
    id: 147913,
    name: "Varangaon",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.56074167,
    longitude: 74.83,
    wikiDataId: "Q2570716",
  },
  {
    id: 147914,
    name: "Vasind",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.97,
    longitude: 72.81,
    wikiDataId: "Q7685715",
  },
  {
    id: 147915,
    name: "Velneshwar",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 21.25,
    longitude: 74.9667,
    wikiDataId: "Q7709740",
  },
  {
    id: 147916,
    name: "Vikhroli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19,
    longitude: 72.9,
    wikiDataId: "Q3595610",
  },
  {
    id: 147917,
    name: "Wadwani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.48333333,
    longitude: 74.13333333,
    wikiDataId: "Q7901522",
  },
  {
    id: 147918,
    name: "Wai",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.7486,
    longitude: 73.641,
    wikiDataId: "Q2719142",
  },
  {
    id: 147919,
    name: "Wakad",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.25,
    longitude: 74.0333,
    wikiDataId: "Q7908334",
  },
  {
    id: 147920,
    name: "Walki",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.05,
    longitude: 75.8,
    wikiDataId: "Q7908830",
  },
  {
    id: 147921,
    name: "Wani",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.6,
    longitude: 76.0833,
    wikiDataId: "Q7912608",
  },
  {
    id: 147922,
    name: "Wardha",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.86666667,
    longitude: 72.75,
    wikiDataId: "Q7914756",
  },
  {
    id: 147923,
    name: "Warud",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.08,
    longitude: 73.01,
    wikiDataId: "Q297732",
  },
  {
    id: 147924,
    name: "Worli",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.75,
    longitude: 73.6,
    wikiDataId: "Q7917225",
  },
  {
    id: 147925,
    name: "Yaval",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.9585,
    longitude: 73.0498,
    wikiDataId: "Q7918828",
  },
  {
    id: 147926,
    name: "Yavatmal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.3833,
    longitude: 73.2,
    wikiDataId: "Q7919247",
  },
  {
    id: 147927,
    name: "Bishnupur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 16.5667,
    longitude: 73.3333,
    wikiDataId: "Q14862297",
  },
  {
    id: 147928,
    name: "Churachandpur",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.11,
    longitude: 72.94,
    wikiDataId: "Q5383080",
  },
  {
    id: 147929,
    name: "Imphal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.1,
    longitude: 72.83,
    wikiDataId: "Q3558400",
  },
  {
    id: 147930,
    name: "Kakching",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.11666667,
    longitude: 74.28333333,
    wikiDataId: "Q11954840",
  },
  {
    id: 147931,
    name: "Moirang",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.2711,
    longitude: 74.5378,
    wikiDataId: "Q7936679",
  },
  {
    id: 147932,
    name: "Senapati",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19.02163056,
    longitude: 72.86458889,
    wikiDataId: "Q4856518",
  },
  {
    id: 147933,
    name: "Thoubal",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.84972222,
    longitude: 76.33333333,
    wikiDataId: "Q7959304",
  },
  {
    id: 147934,
    name: "Ukhrul",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.98333333,
    longitude: 76.05,
    wikiDataId: "Q7959361",
  },
  {
    id: 147935,
    name: "Wangjing",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 17.9,
    longitude: 74.083,
    wikiDataId: "Q7959561",
  },
  {
    id: 147936,
    name: "Cherrapunji",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.5993,
    longitude: 73.7625,
    wikiDataId: "Q7960750",
  },
  {
    id: 147937,
    name: "East Garo Hills",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 20.9989,
    longitude: 77.7064,
    wikiDataId: "Q7961875",
  },
  {
    id: 147938,
    name: "East Jaintia Hills",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 18.95,
    longitude: 74.75,
    wikiDataId: "Q7962403",
  },
  {
    id: 147939,
    name: "North Garo Hills",
    state_id: 4008,
    state_code: "MH",
    state_name: "Maharashtra",
    country_id: 101,
    country_code: "IN",
    country_name: "India",
    latitude: 19,
    longitude: 72.815,
    wikiDataId: "Q1934607",
  },
  
];

export default StateCity;
