import {
  Dialog,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputBase,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import React from "react";
import classes from "./Topnav.module.css";
import { MembersAddForm } from "../Views/Members/MembersAddForm";
import { MemberSearchForm } from "../Views/Members/MemberSearchForm";
import NavMenuDropdown from "./../Components/MenuItems/NavMenuDropdown";

const TopNav = () => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        className={classes.topNav}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl className={classes.search} variant="standard">
          {/* <Input
            id="input-with-icon-adornment"
            placeholder="Search"
            sx={{ backgroundColor: "#fff" }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ cursor: "pointer" }}
                onClick={openModal}
              >
                <ArrowDropDownIcon />
              </InputAdornment>
            }
          /> */}
        </FormControl>
        <div style={{ marginRight: "50px" }}>
          <NavMenuDropdown />
        </div>
      </div>
      <Dialog onClose={handleClose} open={open}>
        <div style={{ width: "100%", textAlign: "center", marginTop: "3vh" }}>
          <FormControl variant="standard">
            <Input
              id="input-with-icon-adornment"
              placeholder="Search"
              
              sx={{ backgroundColor: "#F6F6F6" }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer" }}
                  onClick={openModal}
                >
                  <ArrowDropDownIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        {/* pass form  */}
        <MemberSearchForm />
      </Dialog>
    </>
  );
};

export default TopNav;
