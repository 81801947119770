import React, { useState } from "react";
import { SupportHeader } from "./SupportHeader";
import SupportTable from "./SupportsTable";

const Support = () => {
  const option_users = ["App Support", "Profile Support"];
  const [optionVal, setOptionVal] = useState("App Support");

  const filterUsers = async (name) => {
    setOptionVal(name);
  };
  return (
    <>
      <SupportHeader
        option_users={option_users}
        optionVal={optionVal}
        filterUsers={filterUsers}
      />
      <SupportTable />{" "}
    </>
  );
};

export default Support;
