import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import { handleUpload } from "../../../Components/GeneralComponents/GeneralFileUpload";
import Image from "../../Configuration/pinDocs.png";
import DataAcqSelectField from "../DataAcqSelectField";
import React from "react";
import CONSTANTS from "../commons/Utils";

export const QuestionInput = ({
  question,
  fileURL,
  type,
  setQuestion,
  onFileChange,
  questionType,
  optionQuestionType,
  filterUsers,
  setQuestionType,
  isUpdate,
  userRole,
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center"
      style={{ padding: "30px 50px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
          width: "55%",
        }}
      >
        <input
          className="form-control"
          placeholder="Question *"
          value={question}
          disabled={
            isUpdate === true && userRole.toLowerCase() === "staff"
              ? true
              : false
          }
          style={{
            outline: "none",
            border: "none",
            boxShadow: "none",
            width: "100%",
            cursor: "pointer",
          }}
          onChange={(e) => setQuestion(e.target.value)}
        />

        {fileURL?.length > 0 ? (
          <a
            href={`${fileURL}`}
            target={"_blank"}
            style={{
              textAlign: "left",
            }}
          >{`${fileURL?.split("/")[3].substring(13)}`}</a>
        ) : (
          ""
        )}

        {fileURL !== "" &&
        (fileURL?.split(".")[fileURL?.split(".").length - 1].toLowerCase() ===
          "png" ||
          fileURL?.split(".")[fileURL?.split(".").length - 1].toLowerCase() ===
            "jpg" ||
          fileURL?.split(".")[fileURL?.split(".").length - 1].toLowerCase() ===
            "jpeg") ? (
          <>
            {/* <a
              href={`${attachmentUrl}`}
              target={"_blank"}
              style={{
                textAlign: "left",
              }}
            >{`${uploadFile}`}</a> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* {{
                let attachment = attachmentUrl.split(",").length;

              }} */}
              <img src={fileURL} alt="Uploaded Data..." width="50%" />
            </div>
            {/* <video width="320" height="240" controls>
              <source src={attachmentUrl} type="video/mp4" />
              <source src={attachmentUrl} type="video/ogg" />
              Your browser does not support the video tag.
            </video> */}
          </>
        ) : (
          ""
        )}
      </div>

      <div
        // xs={2}
        style={{
          position: "relative",
          height: "22px",
          flex: 1,
        }}
      >
        <input
          type="file"
          accept="*/*"
          disabled={
            isUpdate === true && userRole.toLowerCase() === "staff"
              ? true
              : false
          }
          style={{
            opacity: 0.01,
            position: "absolute",
            cursor: "pointer",
            height: "22px",
            width: "16px",
            textIndent: "-100px",
            // width: "2rem",
            MozOpacity: 0.01,
            zIndex: 10,
            // top: "11px",
            fontSize: 0,
            // height: "2rem",
            // left: "-11px",
          }}
          onChange={onFileChange}
          id="contained-button-file"
        />
        <img
          src={Image}
          srcSet={Image}
          alt="pin"
          loading="lazy"
          style={{
            cursor: "pointer",
            position: "absolute",
            opacity: 7,
            // left: "0px",
            zIndex: 8,
            // top: "13px",
          }}
        />
      </div>

      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          width: "36%",
        }}
      >
        <DataAcqSelectField
          optionVal={questionType}
          option_users={
            CONSTANTS.ACQUISITION_TYPE_BASED_OPTIONS[type] ||
            CONSTANTS.ACQUISITION_TYPE_BASED_OPTIONS[
              CONSTANTS.DATA_ACQUISION_TYPES.APPLICATION
            ]
          }
          filterUsers={filterUsers}
          setOptionVal={setQuestionType}
          isUpdate={isUpdate}
          userRole={userRole}
        />
      </div>
    </div>
  );
};
