import { ShareholdingDistributionHeader } from "./ShareholdingDistributionHeader";
import { useDispatch, useSelector } from "react-redux";
import { createRef, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import ShareholdingDistributionTable from "./ShareholdingDistributionTable";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import { getSayasAccountActionCreater } from "../../../Redux/SayasAccount/sayasAccountActionCreator";
import CustomSearchForReportShareHolding from "../../../Components/CustomSearch/CustomSearchForReportShareHolding";
import { MultiSelectDropdown } from "../../../Components/SelectDropdown/MultiSelectDropdown";
import { reportDataVisibiltyActionCreater } from "../../../Redux/Reports/reportActionCreator";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export const ShareholdingDistribution = () => {
  const [completeData, setCompleteData] = useState(null);
  const [city, setCity] = useState("None");
  const [state, setState] = useState("None");
  const [organisation, setOrganisation] = useState("None");

  const [finalData, setFinalData] = useState([]);
  const [tabValue, setTabValue] = useState("All");
  const [selectValue, setSelectValue] = useState("Last 30 Days");
  const tableRef = createRef();
  const [finalAdvanceSearchData, setFinalAdvanceSearchData] = useState(null);
  const [isAction, setIsAction] = useState(false);
  const [sayasAcc, setSayasAcc] = useState({});
  const [pricePerShare, setPricePerShare] = useState(0);

  // console.log(finalData);

  const getSayasData = useSelector((state) => {
    return state;
  });

  useEffect(async () => {
    setSayasAcc(getSayasData.sayasAccount);
    setPricePerShare(getSayasData.dashboard.pricePerShare);
  }, [getSayasData]);
  const [tableDataForFilter, setTableDataForFilter] = useState({
    city: { None: "None" },
    state: { None: "None" },
    organisation: { None: "None" },
  });

  const [data, setData] = useState({
    startDate: moment().add(-30, "days").format("YYYY MM DD"),
    endDate: moment().format("YYYY MM DD"),
  });
  const dispatch = useDispatch();

  const filterData = () => {
    if (tabValue === "All") {
      const newAllData = completeData
        ? completeData.transactions
          ? completeData.transactions
              .filter((ele) => {
                return (
                  convert(data.startDate) <= convert(ele.createdAt) &&
                  convert(data.endDate) >= convert(ele.createdAt)
                );
              })
              .map((ele) => {
                return {
                  ...ele,
                  memberName: ele.firstName + " " + ele.lastName,
                  avgShares: parseInt(
                    parseInt(ele.totalShare / sayasAcc.totalShares) * 100
                  ).toFixed(5),
                };
              })
          : []
        : [];
      setFinalData(newAllData);
      setFinalAdvanceSearchData(newAllData);
      
    } else if (tabValue === "City") {
      setFinalData(
        completeData?.cityInfo.map((ele) => {
          return {
            ...ele,
            avgShares: parseInt(
              parseInt(ele.totalShares / sayasAcc.totalShares) * 100
            ).toFixed(5),
          };
        })
      );
      // setFinalData(completeData.cityInfo);
    } else if (tabValue === "State") {
      setFinalData(
        completeData?.stateInfo.map((ele) => {
          return {
            ...ele,
            avgShares: parseInt(
              parseInt(ele.totalShares / sayasAcc.totalShares) * 100
            ).toFixed(5),
          };
        })
      );
      // setFinalData(completeData.stateInfo);
    } else {
      setFinalData(
        completeData?.organisationInfo.map((ele) => {
          return {
            ...ele,
            avgShares: parseInt(
              parseInt(ele.totalShares / sayasAcc.totalShares) * 100
            ).toFixed(5),
          };
        })
      );
      // setFinalData(completeData.organisationInfo);
    }

    // dispatch(loaderEndActionCreater());
  };
  // setFinalData(
  //   completeData.filter((ele) => {
  //     // console.log("stratDate", moment(data.startDate).format("YYYY-MM-DD"));
  //     if (tabValue === "All") {
  //       return (
  //         convert(data.startDate) <= convert(ele.updatedAt) &&
  //         convert(data.endDate) >= convert(ele.updatedAt)
  //       );
  //     } else if (tabValue === "City") {
  //       return (
  //         ele.city === city &&
  //         convert(data.startDate) <= convert(ele.updatedAt) &&
  //         convert(data.endDate) >= convert(ele.updatedAt)
  //       );
  //     } else if (tabValue === "State") {
  //       return (
  //         ele.state === state &&
  //         convert(data.startDate) <= convert(ele.updatedAt) &&
  //         convert(data.endDate) >= convert(ele.updatedAt)
  //       );
  //     } else {
  //       return (
  //         ele.organisation_name === organisation &&
  //         convert(data.startDate) <= convert(ele.updatedAt) &&
  //         convert(data.endDate) >= convert(ele.updatedAt)
  //       );
  //     }
  //   })
  // );
  // dispatch(loaderEndActionCreater());

  const filterDataDays = () => {
    let newData = completeData?.transactions
      ?.filter((ele) => {
        if (selectValue === "Last 30 Days") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
              moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
              moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Today") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") ==
              moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") ==
              moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Yesterday") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") ==
              moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") ==
              moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "This Week") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
              moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
              moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Previous Week") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
              moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
              moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Custom") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
              moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
              moment(ele.createdAt).format("YYYY-MM-DD")
          );
        }
      })
      .map((ele) => {
        return {
          ...ele,
          memberName: ele.firstName + " " + ele.lastName,
          avgShares: parseInt(
            parseInt(ele.totalShare / sayasAcc.totalShares) * 100
          ).toFixed(5),
        };
      });

    // console.log("completedata", completeData, data,newData);

    setFinalData(newData);
    // dispatch(loaderEndActionCreater());
  };

  useEffect(() => {
    filterDataDays();
  }, [selectValue, data, completeData, city, state, organisation]);

  useEffect(() => {
    filterData();
  }, [tabValue, data, completeData, city, state, organisation]);

  // console.log(memberList);

  const getShareApplicationMoney = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + `/api/v1/reports/shareHolders`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // "http://localhost:8080/api/v1/reports/shareHolders"

      setCompleteData(response.data);

      ///get list of city, state, organisation:::
      // console.log("response", response.data);
      // let dummyData = { city: {}, state: {}, organisation: {} };

      // response.data.map((ele) => {
      //   let city = ele.city;
      //   let organisation = ele.organisation_name;
      //   let state = ele.state;

      //   dummyData = {
      //     city: {
      //       ...dummyData.city,
      //       [`${city}`]: city,
      //     },
      //     state: {
      //       ...dummyData.state,
      //       [`${state}`]: state,
      //     },
      //     organisation: {
      //       ...dummyData.organisation,
      //       [`${organisation}`]: organisation,
      //     },
      //   };
      // });
      // setTableDataForFilter(response.data);
      // dispatch(loaderEndActionCreater());
      // console.log("Res", response.data.data);
    } catch (err) {
      // console.log(err.response.data.error);
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    getShareApplicationMoney();
    dispatch(getSayasAccountActionCreater(getAuthData));
    dispatch(loaderStartActionCreater());
  }, []);

  useEffect(() => {
    if (completeData) dispatch(loaderEndActionCreater());
  }, [completeData]);

  // console.log("tableDataForFilter",tableDataForFilter);
  const columns = [
    {
      title: "Date",
      field: "createdAt",
      // type: "date",

      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData?.createdAt?.slice(0, -1)).format("DD/MM/yyyy")}
        </div>
      ),
      dateSetting: {
        format: "dd/MM/yyyy",
      },
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.createdAt.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) != -1,
    },
    {
      title: "Membership ID",
      field: "memberId",
      // cellStyle: {
      //   minWidth: 190,
      // },
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.memberId || "").localeCompare(b.memberId || "");
      },
    },
    // {
    //   title: "Transaction ID",
    //   field: "id",
    //   // cellStyle: {
    //   //   minWidth: 190,
    //   // },
    //   headerStyle: {
    //     whiteSpace: "nowrap",
    //   },
    // },
    {
      title: "Member Name",
      field: "memberName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData.firstName} ${rowData.lastName}`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        let fullName = `${rowData.firstName} ${rowData.lastName}`;
        fullName = fullName.toLowerCase();
        return fullName.indexOf(term.toLowerCase()) > -1;
      },
      cellStyle: {
        minWidth: 190,
      },
      customSort: (a, b) => {
        return (a.memberName || "").localeCompare(b.memberName || "");
      },
    },

    {
      title: "State",
      field: "state",
      cellStyle: {
        minWidth: 170,
      },
      customSort: (a, b) => {
        return (a.state || "").localeCompare(b.state || "");
      },
    },
    {
      title: "District",
      field: "district",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        minWidth: 140,
      },
      customSort: (a, b) => {
        return (a.district || "").localeCompare(b.district || "");
      },
    },
    {
      title: "Taluka",
      field: "taluka",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        minWidth: 140,
      },
      customSort: (a, b) => {
        return (a.taluka || "").localeCompare(b.taluka || "");
      },
    },
    {
      title: "City",
      field: "city",
      cellStyle: {
        minWidth: 170,
      },
      customSort: (a, b) => {
        return (a.city || "").localeCompare(b.city || "");
      },
    },
    {
      title: "Pin Code",
      field: "pincode",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      cellStyle: {
        minWidth: 130,
      },
    },
    {
      title: "Organisation",
      field: "organisation_name",
      cellStyle: {
        minWidth: 160,
      },
      customSort: (a, b) => {
        return (a.organisation_name || "").localeCompare(
          b.organisation_name || ""
        );
      },
    },
    {
      title: "Total Share",
      field: "totalShare",
      cellStyle: {
        minWidth: 160,
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.totalShare.toString().indexOf(term) === 0,
    },
    {
      title: "Share Capital",
      field: "shareCapital",
      cellStyle: {
        minWidth: 180,
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.shareCapital.toString().indexOf(term) === 0,
    },
    {
      title: "Share App. Money",
      field: "shareApplicationMoney",
      cellStyle: {
        minWidth: 220,
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.shareApplicationMoney.toString().indexOf(term) === 0,
    },
    {
      title: "% Share Holding",
      field: "avgShares",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            minWidth: "12rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${((rowData.totalShare / sayasAcc.totalShares) * 100).toFixed(5)}%`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        `${((rowData.totalShare / sayasAcc.totalShares) * 100).toFixed(
          5
        )}%`.indexOf(term) === 0,
      customSort: (a, b) => {
        return (
          ((a.totalShare / sayasAcc.totalShares) * 100).toFixed(5) -
          ((b.totalShare / sayasAcc.totalShares) * 100).toFixed(5)
        );
      },
    },
  ];

  const columnsForCity = [
    {
      title: `${tabValue} Name`,
      field: "name",
      // render: (rowData) => (
      //   <div
      //     sx={{ display: "table-cell" }}
      //     style={{
      //       maxWidth: "10rem",
      //       wordBreak: "break-word",
      //       whiteSpace: "nowrap",
      //       whiteSpace: "nowrap",
      //       lineBreak: "auto",
      //       overflowX: "hidden",
      //       textOverflow: "ellipsis",
      //     }}
      //   >
      //     {`${rowData.firstName} ${rowData.lastName}`}
      //   </div>
      // ),
      cellStyle: {
        minWidth: 190,
      },
      customSort: (a, b) => {
        return (a.name || "").localeCompare(b.name || "");
      },
    },
    {
      title: "Total Share",
      field: "totalShares",
      cellStyle: {
        minWidth: 170,
      },
    },
    {
      title: "Share Capital",
      field: "shareCapital",
      cellStyle: {
        minWidth: 180,
      },
    },
    {
      title: "Share App. Money",
      field: "shareApplicationMoney",
      cellStyle: {
        minWidth: 220,
      },
    },
    {
      title: "Total Member",
      field: "totalMember",
      cellStyle: {
        minWidth: 180,
      },
    },

    {
      title: "% Share Holding",
      field: "avgShares",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            minWidth: "12rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${((rowData.totalShares / sayasAcc.totalShares) * 100).toFixed(
            5
          )}%`}
        </div>
      ),
    },
    {
      title: "Regular Members",
      field: "regularMembers",
      cellStyle: {
        minWidth: 210,
      },
    },
    {
      title: "Nominal Members",
      field: "nominalMembers",
      cellStyle: {
        minWidth: 215,
      },
    },
  ];

  const simpleSearchCols = {
    all: [
      ["Membership Id", "memberId"],
      ["First Name", "firstName"],
      ["Last Name", "lastName"],
      ["State", "state"],
      ["District", "district"],
      ["Taluka", "taluka"],
      ["City", "city"],
      ["Organisation Name", "organisation_name"],
    ],
    city: [["City Name", "name"]],
    state: [["State Name", "name"]],
    organisation: [["Organisation Name", "name"]],
  };

  const columnsStatusTransaction = useSelector((state) => {
    // console.log(state.members.columnsStatus);
    return state.reports.shareHoldingTransaction;
  });
  const columnsStatusFilter = useSelector((state) => {
    // console.log(state.members.columnsStatus);
    return state.reports.shareHoldingFilters;
  });

  const setColumnsStatus = (data) => {
    // console.log(data);
    let designation =
      tabValue === "All" ? "SHARE_HOLING_TRANSACTION" : "SHARE_HOLDING_FILTERS";
    dispatch(reportDataVisibiltyActionCreater(data, designation));
  };

  return (
    <>
      <ShareholdingDistributionHeader
        tableRef={tableRef}
        setTabValue={setTabValue}
        finalData={finalData}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        organisation={organisation}
        setOrganisation={setOrganisation}
        tabValue={tabValue}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        setData={setData}
      />
      <div>
        <div
          className="d-flex justify-content-end gap-2 align-items-center mb-3"
          style={{ padding: "0rem 2rem" }}
        >
          {/* {console.log(tableRef ? tableRef?.current?.state?.data : data)} */}
          <CustomSearchForReportShareHolding
            data={
              tabValue === "All"
                ? completeData?.transactions
                : tabValue === "City"
                ? completeData?.cityInfo
                : tabValue === "State"
                ? completeData?.stateInfo
                : completeData?.organisationInfo || []
            }
            // data={tableRef ? tableRef?.current?.state?.data : data}
            ref={tableRef}
            tableData={finalData}
            setTableData={setFinalData}
            finalAdvanceSearchData={finalAdvanceSearchData}
            setFinalAdvanceSearchData={setFinalAdvanceSearchData}
            // rangeSearchCol={[
            //   ["Total Shares", "totalShare"],
            //   ["Share Capital", "shareCapital"],
            //   ["Share Application Money", "shareApplicationMoney"],
            // ]}
            simpleSearchCol={
              tabValue === "All"
                ? simpleSearchCols?.all
                : tabValue === "City"
                ? simpleSearchCols?.city
                : tabValue === "State"
                ? simpleSearchCols?.state
                : simpleSearchCols?.organisation
            }
            columns={tabValue === "All" ? columns : columnsForCity}
            isAction={isAction}
            setIsAction={setIsAction}
            tabValue={tabValue}
          />
          <MultiSelectDropdown
            columns={tabValue === "All" ? columns : columnsForCity}
            columnsStatus={
              tabValue === "All"
                ? columnsStatusTransaction
                : columnsStatusFilter
            }
            setColumnsStatus={setColumnsStatus}
          />
        </div>

        <ShareholdingDistributionTable
          tableRef={tableRef}
          finalData={finalData}
          finalAdvanceSearchData={finalAdvanceSearchData}
          tabValue={tabValue}
          columns={tabValue === "All" ? columns : columnsForCity}
          columnsStatus={
            tabValue === "All" ? columnsStatusTransaction : columnsStatusFilter
          }
        />
      </div>
    </>
  );
};
