import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../member.module.css";
import CSVReader from "react-csv-reader";
import { Button, Tooltip } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import { getTableDetails } from "./../../Uitls/Utils";
import { BASE_URL } from "./../../../Components/URL/Url";
import DialoguePriview from "./../../../Components/Dialogs/Dialogs";
import pincodeDirectory from "india-pincode-lookup";
import Help from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  getDashboardTransfer,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../../Redux/Dashboard/dashboardActionCreator";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import StateCity from "./../../../Components/Data/StateCity";
import { city } from "./../../../Components/Data/maharashtraCity";
import validator from "validator";
import statesDistrictsTalukas from "../../../../src/Components/Data/statesDistrictTalukas.json";

export default function MemberFileUpload(props) {
  const { open, setOpen, setData, memberDataLength, setConflict, setIsAction } =
    props;
  const dispatch = useDispatch();
  const [uploadData, setUploadData] = useState([]);
  const [error, setError] = useState([]);
  const [pinError, setPinError] = useState([]);
  const [downloadFileData, setDownloadFileData] = React.useState();
  const [stateList, setStateList] = useState([]);

  //city and State
  // console.log("city and state", stateList);

  // useEffect(() => {
  //   setStateList(
  //     StateCity.reduce((ans, ele) => {
  //       return ans?.includes(ele.state_name) ? ans : [...ans, ele.state_name];
  //     }, [])
  //   );
  // }, []);

  const states = _.keysIn(statesDistrictsTalukas).map((state) => state);

  const getConfigDataMemberTypeList = useSelector((state) => {
    // console.log(state   );
    return state.configuration.memberType.map((ele) => ele.type.toLowerCase());
  });

  const getConfigDataShareHoldingList = useSelector((state) => {
    return state.configuration.shareholderType.map((ele) =>
      ele.type.toLowerCase()
    );
  });

  // download template imeplementation
  const [downloadURL, setDownloadURL] = useState();
  const getConfigData = useSelector((state) => {
    return state.configuration.uploadTemplate;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    return state.auth.loginToken;
  });

  useEffect(() => {
    let newURL = getConfigData
      ? getConfigData.filter((e) => {
          return e.type === "memberImport";
        })
      : "";

    setDownloadURL(newURL[0] ? newURL[0].fileURL : "");
  }, [getConfigData]);

  const isDistrict2 = (data) => {
    let district = statesDistrictsTalukas[`${data.state}`]?.filter((ele) => {
      // console.log(ele)
      return ele["DISTRICT"] === data.district;
    });

    return district?.length > 0;
  };

  const isTaluka = (data) => {
    let talukas = statesDistrictsTalukas[`${data.state}`]?.filter((ele) => {
      // console.log(ele)
      return ele["DISTRICT"] === data.district;
    });

    // console.log(talukas);

    talukas = talukas?.filter((ele) => {
      return ele["TEHSIL"] === data.taluka;
    });

    // console.log(talukas);

    return talukas?.length > 0;
  };

  // download template imeplementation

  React.useEffect(() => {
    let a = uploadData.map(
      (data) =>
        parseInt(data.phone)?.toString().length == 10 &&
        data?.memberId?.toString()?.length <= 14 &&
        validator.isMobilePhone(data.phone + "", [
          "en-IN",
          { strictMode: true },
        ]) &&
        !/[^a-zA-Z0-9]+/g.test(data.memberId) &&
        data?.pincode?.toString()?.length == 6 &&
        // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email) &&
        !/[^a-zA-Z\s]/.test(data.firstName) &&
        !/[^a-zA-Z\s]/.test(data.middleName) &&
        !/[^a-zA-Z\s]/.test(data.lastName) &&
        !states?.includes(data.state) &&
        !isDistrict2(data) &&
        !isTaluka(data) &&
        !getConfigDataMemberTypeList?.includes(data?.member?.toLowerCase())
      // &&
      // !states.filter((ele) => {
      //   return ele === data.state && ele.name === data.city;
      // })
    );
    setError(a);

    // let b = uploadData.map((data) => {
    //   let checkPin = pincodeDirectory.lookup(data.pincode);
    //   if (checkPin && checkPin[0]?.stateName == data.state.toUpperCase()) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // setPinError(b);
  }, [uploadData]);

  const columns = [
    { title: "Member ID", field: "memberId" },
    { title: "Member", field: "member" },
    { title: "First Name", field: "firstName" },
    { title: "Middle Name", field: "middleName" },
    { title: "Last Name", field: "lastName" },
    { title: "Email", field: "email" },
    { title: "Phone", field: "phone" },
    { title: "Nominee Name", field: "nominee_name" },
    { title: "Organisation Name", field: "organisation_name" },
    { title: "State", field: "state" },
    { title: "City", field: "city" },
    { title: "Pin Code", field: "pincode" },
    { title: "Address Line 1", field: "addressLine_1" },
    { title: "Address Line 2", field: "addressLine_2" },
    {
      title: "Active",
      field: "activityStatus",
      render: (rowData) =>
        rowData.activityStatus === true ? <>Active</> : <>Inactive</>,
    },
    {
      title: "Mobile Status",
      field: "mobileSignUpStatus",
      render: (rowData) =>
        rowData.mobileSignUpStatus === true ? <>Enabled</> : <>Disabled</>,
    },
    { title: "Share Holder", field: "shareholder" },
  ];

  const handleClose = () => {
    setOpen(false);
    setUploadData([]);
  };

  const handleForce = (data, fileInfo) => {
    // console.log("Change File!!");
    setUploadData([]);
    setUploadData(data);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header,
  };
  // console.log("uploadData", uploadData);

  const myFunction = (uploadData) => {
    for (let i = 0; i <= uploadData.length; i++) {
      let checkPin = pincodeDirectory.lookup(uploadData[i]?.pincode);
      if (
        checkPin &&
        checkPin[0]?.stateName !== uploadData[i]?.state.toUpperCase()
      ) {
        dispatch(openSnackbar(`Error: Pincode and State not match`, "error"));
      } else if (
        !(
          uploadData[i].phone.toString().length == 10 &&
          uploadData[i].pincode.toString().length == 6 &&
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            uploadData[i].email
          )
        )
      ) {
        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            uploadData[i].email
          )
        ) {
          dispatch(
            openSnackbar(
              `Error: Email is Invalid ${uploadData[i].email}`,
              "error"
            )
          );
        } else if (uploadData[i].pincode.toString().length !== 6) {
          dispatch(
            openSnackbar(
              `Error: Pincode Length must be 6 digit ${uploadData[i].pincode}`,
              "error"
            )
          );
        } else if (
          !validator.isMobilePhone("" + uploadData[i].phone, [
            "en-IN",
            { strictMode: true },
          ]) &&
          uploadData[i].phone.toString().length !== 10
        ) {
          dispatch(
            openSnackbar(
              `Error: Phone Number Length must be 10 digit  ${uploadData[i].phone}`,
              "error"
            )
          );
        }
        break;
      }
    }
  };

  const handleUploadFile = async () => {
    // console.log("uploadData", uploadData);
    setOpen(false);

    // console.log("table Data", uploadData);
    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email) &&
    let a = uploadData.map(
      (data) =>
        data.memberId?.toString().length <= 14 &&
        !/[^a-zA-Z0-9]+/g.test(data.memberId) &&
        parseInt(data.phone).toString().length == 10 &&
        validator.isMobilePhone("" + data.phone, [
          "en-IN",
          { strictMode: true },
        ]) &&
        data.pincode?.toString().length == 6 &&
        !/[^a-zA-Z\s]/.test(data?.firstName) &&
        !/[^a-zA-Z\s]/.test(data.middleName) &&
        !/[^a-zA-Z\s]/.test(data.lastName) &&
        data.shareHolder?.length > 0 &&
        !/[^a-zA-Z\s]/.test(data.city) &&
        states?.includes(data.state) &&
        isDistrict2(data) &&
        isTaluka(data) &&
        getConfigDataMemberTypeList?.includes(data?.member?.toLowerCase()) &&
        getConfigDataShareHoldingList?.includes(
          data?.shareHolder?.toLowerCase()
        )
      //   let isDistrict =
      //   item.state === "Maharashtra"
      //     ? city.filter((e) => e.name === item.district).length > 0
      //     : true;
      // let isTehsil =
      //   item.state === "Maharashtra"
      //     ? city
      //         .filter((e) => e.name === item.district)[0]
      //         ?.tahasil.includes(item.taluka)
      //     : true;
      // &&
      // (data.state === "Maharashtra"
      //   ? city.filter((e) => e.name === data.district).length > 0
      //     ? city.filter((e) => e.name === data.district)?.district?.includes(data.taluka)
      //       ? true
      //       : false
      //     : false
      //   : false)
      // (data.state === "Maharashtra"
      //   ? city.filter((e) => e.name === data.district).length > 0 &&
      //     city
      //       .filter((e) => e.name === data.district)[0]
      //       ?.tahasil.includes(data.tehsil) &&
      //     !data.city
      //   : data.city?.length > 0 && !data.district && !data.tehsil)
      //   &&
      // StateCity.filter((ele) => {
      //   return ele.state_name === data.state && ele.name === data.city;
      // }).length > 0
    );
    // console.log("mylog a", a);

    let dataUpdate = uploadData.filter((ele, i) => {
      return a[i];
    });

    const remainingDataUpdate = uploadData.filter((ele, i) => {
      return !a[i];
    });

    // console.log("remainingDataUpdate", remainingDataUpdate, dataUpdate);

    const newErrorList = remainingDataUpdate.map((item, i) => {
      let ismail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        item.email
      );
      let isPhone =
        validator.isMobilePhone("" + item.phone, [
          "en-IN",
          { strictMode: true },
        ]) && parseInt(item.phone)?.toString().length === 10
          ? true
          : false;
      let isPinCode = item.pincode?.toString().length === 6 ? true : false;
      let isFirst = !/[^a-zA-Z\s]/.test(item?.firstName) ? true : false;
      let isMid = !/[^a-zA-Z\s]/.test(item?.middleName) ? true : false;
      let isLast = !/[^a-zA-Z\s]/.test(item?.lastName) ? true : false;
      let isMemberID =
        item.memberId.toString().length <= 14 &&
        !/[^a-zA-Z0-9]+/g.test(item.memberId)
          ? true
          : false;

      let isState = states ? states?.includes(item.state) : false;
      let isDistrict = isDistrict2(item);
      // let isDistrict = city.filter((e) => e.name === item.district).length > 0;
      // let isTehsil =
      //   city
      //     .filter((e) => e.name === item.district)[0]
      //     ?.tahasil.includes(item.taluka) || false;
      let allTaluka = [];
      city.forEach((e) => {
        allTaluka.push(...e.tahasil);
      });
      // console.log(allTaluka);

      // let isTehsil =
      //   item.state === "Maharashtra" && item.district
      //     ? city
      //         .filter((e) => e.name === item.district)[0]
      //         ?.tahasil.includes(item.taluka)
      //     : allTaluka.includes(item.taluka);

      let isTehsil = isTaluka(item);

      let isCity = !/[^a-zA-Z\s]/.test(item.city);
      let isMember = getConfigDataMemberTypeList?.includes(
        item?.member?.toLowerCase()
      );
      let isShareHolder = getConfigDataShareHoldingList?.includes(
        item?.shareHolder?.toLowerCase()
      );

      // console.log("checks", isDistrict, isTehsil);

      // let isCityState =
      //   StateCity.filter((ele) => {
      //     return ele.state_name === item.state && ele.name === item.city;
      //   }).length > 0
      //     ? true
      //     : false;

      ///remove   ${!ismail ? "- email address " : "" }
      return {
        ...item,
        [`error`]: `${!isMemberID ? "Please insert a valid MemberId " : ""}${
          !isPhone ? "Please insert a valid phone number " : ""
        }${!isFirst ? "Please insert a valid First Name " : ""}${
          !isMid ? "Please insert a valid Middle Name " : ""
        }${!isLast ? "Please insert a valid Last Name " : ""}${
          !isMember ? "Please insert a valid Member Type " : ""
        }${!isPinCode ? "Please insert a valid PinCode " : ""}${
          !isShareHolder ? "Please insert a valid ShareHolder " : ""
        }${!isState ? "Please insert a valid State " : ""}${
          !isCity ? "Please insert a valid City " : ""
        }${!isDistrict ? "Please insert a valid District " : ""}${
          !isTehsil ? "Please insert a valid Taluka " : ""
        }`,
      };

      // ${!isTaluka2(item) ? "Please insert a valid Taluka " : ""
      // item.error = `Please insert a valid ${!ismail ? "email address" : ""}${
      //   !ismail && !isPhone ? " ,and " : ""
      // }${!ismail ? "phone number" : ""}`;
    });

    // console.log("newErrorList", newErrorList);

    dataUpdate = dataUpdate.map((a) => {
      // console.log("mobile Satteus::",a.mobileStatus);
      return {
        ...a,
        activityStatus:
          a && !!a.activityStatus
            ? a.activityStatus?.toString().toLowerCase() === "active" ||
              a.activityStatus?.toString().toLowerCase() === "true" ||
              a.activityStatus === true
            : false,
        mobileSignUpStatus:
          a.mobileStatus?.toString().toLowerCase() === "active" ||
          a.mobileStatus?.toString().toLowerCase() === "true" ||
          !!a.mobileStatus === true
            ? true
            : false,
      };
    });

    // console.log("dataUpdate", dataUpdate);

    // console.log("newErrorList", newErrorList);

    // const newData = dataUpdate.filter(
    //   (item, i) =>
    //     (item.memberId =
    //       "M" + ("0000000" + (memberDataLength + 1 + i)).slice(-7))
    // );

    // if (!remainingDataUpdate || remainingDataUpdate?.length <= 0) {
    //   dispatch(openSnackbar("Data Not Available For Import", "error"));
    //   return;
    // }

    // if (!a.includes(false) && !b.includes(false)) {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }

      // console.log("Start TRY");

      let dataUp = dataUpdate.map((ele) => {
        return {
          ...ele,
          shareholder: ele.shareHolder,
        };
      });

      // console.log("dataUp", dataUp);

      // console.log("Start API",dataUp);

      const response = await axios.post(
        BASE_URL + `/api/v1/member/addMultiple`,
        dataUp,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log("End API");

      getTableDetails(setData, `/api/v1/members`, getAuthData, dispatch);
      dispatch(openSnackbar("Import Members is completed", "success"));
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      if (setConflict) setConflict(true);

      // console.log("Success////");
      // console.log("newData", newData);
      // console.log("response.data.errUsers", response.data.errMembers);
      ////new file updates

      const JsonFields = [
        "memberId",
        "member",
        "firstName",
        "middleName",
        "lastName",
        "email",
        "phone",
        "nominee_name",
        "organisation_name",
        "state",
        "city",
        "district",
        "taluka",
        "pincode",
        "addressLine_1",
        "addressLine_2",
        "activityStatus",
        "mobileStatus",
        "shareHolder",
        "error",
      ];

      var csvStr = JsonFields.join(",") + "\n";

      [...newErrorList, ...response.data.errMembers].forEach((element) => {
        let memberId = element?.memberId;
        let member = element?.member;
        let firstName = element?.firstName;
        let middleName = element?.middleName;
        let lastName = element?.lastName;
        let email = element?.email;
        let phone = element?.phone;
        let nominee_name = element?.nominee_name;
        let organisation_name = element?.organisation_name;
        let state = element?.state;
        let city = element?.city;
        let district = element?.district;
        let tehsil = element?.taluka;
        let pincode = element?.pincode;
        let addressLine_1 = element?.addressLine_1?.split(",")?.join(";");
        let addressLine_2 = element?.addressLine_2?.split(",")?.join(";");
        let activityStatus = element?.activityStatus;
        let mobileStatus = element?.mobileStatus;
        let shareholder = element?.shareHolder;
        let error = element?.error;

        // console.log({
        //   memberId,
        //   member,
        //   firstName,
        //   middleName,
        //   lastName,
        //   phone,
        //   email,
        //   nominee_name,
        //   shareholder,
        //   mobileSignUpStatus,
        //   organisation_name,
        //   state,
        //   city,
        //   district,
        //   tehsil,
        //   pincode,
        //   addressLine_1,
        //   activityStatus,
        //   error,
        // });

        csvStr +=
          memberId +
          "," +
          member +
          "," +
          firstName +
          "," +
          middleName +
          "," +
          lastName +
          "," +
          email +
          "," +
          phone +
          "," +
          nominee_name +
          "," +
          organisation_name +
          "," +
          state +
          "," +
          city +
          "," +
          district +
          "," +
          tehsil +
          "," +
          pincode +
          "," +
          addressLine_1 +
          "," +
          addressLine_2 +
          "," +
          activityStatus +
          "," +
          mobileStatus +
          "," +
          shareholder +
          "," +
          error +
          "\n";
      });

      // console.log([...newErrorList, ...response.data.errMembers]);
      // console.log(csvStr);

      if ([...newErrorList, ...response.data.errMembers].length > 0) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(csvStr);
        hiddenElement.target = "_blank";
        hiddenElement.download = "Member_Error_Log.csv";
        hiddenElement.click();
      }
    } catch (err) {
      // console.log("Error", err?.response?.data);
      // let errors = err.response.data;
      if (err?.response?.status == 400) {
        dispatch(openSnackbar("Duplicate Field Error", "error"));
      }
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      // dispatch(openSnackbar(`${err.response.data}`, "error"));
    }
    // } else {
    //   myFunction(uploadData);
    //   setUploadData([]);
    // }
    handleClose();
  };

  const SelectFile = () => {
    dispatch(openSnackbar("Select CSV File", "error"));
  };

  // useEffect(async () => {
  //   try {
  //     const response = await axios.get(BASE_URL + `/api/v1/configuration/`);
  //     console.log(response.data.data[0].uploadTemplate[0].fileURL);
  //     setDownloadFile(response.data.data[0].uploadTemplate[0].fileURL);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, []);

  // const getCSVData = async (fileLink) => {
  //   try {
  //     console.log(fileLink);
  //     axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //     var fileData = await axios.get(fileLink, {
  //       responseType: "blob",
  //       withCredentials: false,
  //     });
  //     const newFileData = fileData.data;
  //     console.log(newFileData);
  //     setDownloadFileData(newFileData);
  //     // dispatch(openSnackbar("Data Imported Successfully", "success"));
  //   } catch (err) {
  //     // dispatch(openSnackbar("Data Imported Successfully", "error"));
  //   }
  // };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="m-0 p-2">
          <label id={styles.userLabel}>Upload File</label>
        </DialogTitle>
        <hr className="m-0" />
        <div style={{ paddingLeft: "150px" }}>
          <DialogContent className="pb-0">
            <div className="d-flex align-items-center">
              <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={handleForce}
                parserOptions={papaparseOptions}
              />

              {/* ///download template */}
              {downloadURL === "" ? (
                ""
              ) : (
                <Tooltip
                  title={`Click To Download Template`}
                  arrow
                  placement="top"
                >
                  <IconButton>
                    {/* {console.log(downloadURL ? downloadURL.split("/")[3].substring(13): "")} */}
                    <Help
                      style={{ fontSize: "20px" }}
                      onClick={() => {
                        // window.location.href = downloadURL;
                        // getCSVData(downloadURL);
                        // var csvContent =
                        //   "data:text/csv;charset=utf-8,%EF%BB%BF" +
                        //   encodeURI(downloadFileData);
                        // console.log(downloadURL);
                        // var link = document.createElement("a");
                        // link.setAttribute("href",  downloadURL);
                        // link.setAttribute(
                        //   "download",
                        //   `${
                        //     downloadURL
                        //       ? downloadURL.split("/")[3].substring(13)
                        //       : ""
                        //   }`
                        // );
                        // link.click();
                        window.location.href = downloadURL;
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </DialogContent>

          <DialogContent className="m-0 pt-0">
            {uploadData.length !== 0 ? (
              <div className="mt-2" style={{ paddingLeft: "50px" }}>
                <DialoguePriview
                  data={uploadData}
                  // columns={columns}
                  // stateList={stateList}
                  error={error}
                  pinError={pinError}
                  module="member"
                />
              </div>
            ) : null}
          </DialogContent>
        </div>

        <hr className="m-0" />
        <DialogContent className="p-0 pt-2 pb-3">
          <div className="d-flex justify-content-end align-items-center">
            <Button id={styles.cancelFile} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              id={styles.fileUploadBtn}
              onClick={uploadData.length == 0 ? SelectFile : handleUploadFile}
              sx={{
                bgcolor: "#0096FF",
              }}
            >
              Upload
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
