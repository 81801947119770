function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

const actions = {
  REGISTER_BEGIN: "REGISTER_BEGIN",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",

  registerBegin: (data) => {
    let newData = parseJwt(data);
    // console.log("parsedtoken", newData);
    // console.log(newData);
    return {
      type: "addLogin",
      data: { ...newData, loginToken: data },
    };
  },

  logoutSayas: () => {
    return {
      type: "loginOut",
    };
  },
};

export default actions;
