import React from "react";
import DataTable from "../../Components/Table";

const SupportTable = () => {
  const columns = [
    { title: "Date and Time", field: "date" },
    { title: "Request ID", field: "requestId" },
    { title: "Request Type", field: "requestType" },
    {
      title: "Member Name",
      field: "memberName",
    },
    { title: "Status", field: "status" },
    {
      title: "Asignee",
      field: "dueDate",
    },
    {
      title: "DueDate",
      field: "dueDate",
    },
  ];

  const data = [
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
    {
      status: 1234,
      date: "Rohit",
      requestId: "rohit@gmail.com",
      requestType: "rohit@gmail.com",
      memberName: 9876543210,
      dueDate: 5678,
      certificateNo: "NA",
    },
  ];

  const options = {
    search: true,
    selection: true,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
    },
    maxBodyHeight: "45vh",
    pageSize: 10,
  };

  return (
    <>
      <DataTable title="" columns={columns} data={data} options={options} />
    </>
  );
};

export default SupportTable;
