import actions from "./reportActions";

const { reportDataVisibiltyAction } = actions;

const reportDataVisibiltyActionCreater = (data, key) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    try {
      dispatch(reportDataVisibiltyAction(data, key));
    } catch (err) {
      console.log(err);
    }
  };
};

export { reportDataVisibiltyActionCreater };
