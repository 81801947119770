const actions = {
  REGISTER_BEGIN: "REGISTER_BEGIN",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",

  registerBegin: () => {
    return {
      type: actions.REGISTER_BEGIN,
    };
  },

  registerSuccess: (data) => {
    return {
      type: actions.REGISTER_SUCCESS,
      data,
    };
  },

  CommunicationDataVisibiltyAction: (data) => {
    // console.log("memberDataAction", data);
    return {
      type: "COLUMNSTATUS",
      data,
    };
  },
};

export default actions;
