const actions = {
  getSayasAccountAction: (data) => {
    // console.log("2. addSayasAccountAction", data);
    return {
      type: "getSayasAcc",
      data: data.data[0],
    };
  },

 
};

export default actions;
