import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import {
  addConfiguationActionCreater,
  updateConfiguationActionCreater,
} from "../../Redux/Configuration/configurationActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import "./Configuration.css";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
const style = {
  borderRadius: "10px",
  position: "absolute",
  top: "30%",
  right: "12%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function AddConfigModal(props) {
  const { configType, setConflict, selectedData, setSelectedData } = props;
  // console.log("addCofiguration", configType);
  const initialData = {
    id: "",
    type: "",
    description: "",
    pricePerShare: 0,
  };
  // console.log(selectedData);
  const dispatch = useDispatch();

    //auth token fetch from selector
    const getAuthData = useSelector((state) => {
      // console.log(state.auth);
      return state.auth.loginToken;
    });
  const [data, setData] = useState({
    id: "",
    type: "",
    description: "",
    pricePerShare: 0,
  });
  const [open, setOpen] = useState(selectedData.id ? true : false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setSelectedData({});
    setData(Object.create(selectedData));
    setOpen(false);
  };
  const addNewConfiguration = async (val) => {
    try {
      val.data.id = new Date().valueOf();
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/v1/configuration",
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log(response);
      // alert(response.statusText + " Add New Configuration Successfully");
      dispatch(addConfiguationActionCreater(val));
      dispatch(openSnackbar("New field added successfully.", "success"));
      handleClose();
      setData(initialData);
    } catch (e) {
      dispatch(openSnackbar("New field addition failed", "error"));
      dispatch(loaderEndActionCreater());

      // alert(e);
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const updateConfiguration = async (id, val) => {
    try {
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/v1/configuration" + `/${id}`,
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      dispatch(updateConfiguationActionCreater(id, val));
      dispatch(openSnackbar("Field updated successfully.", "success"));
      // alert(response.statusText + " Update Configuration Successfully");
      setSelectedData({});
      setData(initialData);
      handleClose();
    } catch (e) {
      console.log(e);
      dispatch(openSnackbar("Field updatation failed", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      // alert(e);
    }
  };
  // console.log(data);

  const handleSubmit = () => {
    // data.id = 1234
    if (configType.dataKey === "shareType") {
      if (
        data.pricePerShare === "" ||
        parseInt(data?.pricePerShare) <= 0 ||
        data.pricePerShare === 0
      ) {
        dispatch(openSnackbar("Please add Price Per Share", "error"));
        return;
      }
    }

    if (data.type === "" || data.description === "") {
      dispatch(openSnackbar("Please fill all field", "error"));
      return;
    } else {
      dispatch(loaderStartActionCreater());
      const newData = {
        type: configType.dataKey,
        data,
      };
      selectedData.id
        ? updateConfiguration(selectedData.id, newData)
        : addNewConfiguration(newData);
      // console.log(newData);
      setConflict(true);
    }
  };

  useEffect(() => {
    if (selectedData.id) {
      setOpen(true);
      setData(Object.create(selectedData));
    }
  }, [selectedData]);

  return (
    <div>
      <Button
        className="addBtn"
        variant="contained"
        sx={{
          mr: 2,
          height: "40px",
          display: "flex",
          borderRadius: "5px",
          justifyContent: "center",
          bgcolor: "#0096FF!important",
          color: "#FFFFFF",
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "20px",
        }}
        onClick={handleOpen}
      >
        Add Field
      </Button>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={() => {
          setData(initialData);
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ padding: "40px 30px" }}>
          <div>
            <GeneralInputField
              data={data}
              setData={setData}
              place={"Type"}
              dataKey={"type"}
              // value = {configType}
              // disabled
            />

            {configType.dataKey === "shareType" ? (
              <GeneralInputField
                data={data}
                setData={setData}
                disabled={selectedData?.id ? true : false}
                place={"Price Per Share"}
                dataKey={"pricePerShare"}
                onlyNumber
              />
            ) : (
              ""
            )}

            <GeneralInputField
              data={data}
              setData={setData}
              place={"Description"}
              dataKey={"description"}
              multiline
            />
          </div>
          <div
            align="right"
            className="pt-4"
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              alignContent: "center",
            }}
            // sx={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <Button
              className="addBtn"
              variant="contained"
              sx={{
                // mr: 2,
                height: "25px",
                display: "flex",
                padding: 2,
                borderRadius: "5px",
                justifyContent: "center",
                bgcolor: "#0096FF!important",
                color: "#FFFFFF",
                fontFamily: "Mulish",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
              }}
              onClick={handleSubmit}
            >
              {selectedData.id ? "Update" : "Add Field"}
            </Button>
            <Button
              className="addBtn"
              varient="text"
              // className="p-4"
              style={{
                background: "none",
                color: "#828282",
                fontFamily: "Mulish",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
                lineHeight: "25px",
                marginRight: "10px",
              }}
              onClick={handleClose}
              // onClick={() => {
              //   setSelectedData({});
              //   setOpen(false);
              //   setData({ ...oldData, description : oldData.description });
              //   // dispatch(
              //   //   openSnackbar(
              //   //     `${
              //   //       selectedData.id ? "update" : "Add New"
              //   //     }  Configuration Cancelled`,
              //   //     "error"
              //   //   )
              //   // );
              // }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
