import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./MultiSelect.module.css";
import { loaderStartActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch } from "react-redux";

export default function MaterialSelect(props) {
  const { optionVal, option_users, filterUsers, filter, val } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    if (filterUsers === null) return;
    filterUsers(item);
    // dispatch(loaderStartActionCreater());
  };

  return (
    <div
      style={{
        // background: "#0096FF",
      }}
    >
      <button
        id={styles.selBtn}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          // background: "#0096FF!important",
        }}
      >
        {val || optionVal}
        {!val ? (
          open ? (
            <i
              className="fa fa-caret-up"
              style={{ fontSize: 30, marginLeft: "10px", color: "#c4c4c4" }}
            />
          ) : (
            <i
              className="fa fa-caret-down"
              style={{ fontSize: 30, marginLeft: "10px", color: "#c4c4c4" }}
            />
          )
        ) : null}
      </button>

      {!val ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {option_users.map((item) => (
            <MenuItem
              key={item}
              id={styles.selectBtn}
              onClick={() => {
                handleClose();
                handleSelect(item);
                // console.log(item);
              }}
            >
              {item}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        ""
      )}
    </div>
  );
}
