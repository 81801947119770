import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function InActiveTextboxWithLabel(props) {
  const { label, value, error } = props;

  // console.log(label);

  return (
    <Box
      component="form"
      sx={{
        // "& > :not(style)": { m: 1, width: "25ch" },
        width: "12rem",
        m: 1,
        dispaly: "flex",
        flexDirection: "row",
        // width: "12rem",
        alignItems: "cenetr",
        justifyContent: "center",
        // textAlign: "center",
      }}
      noValidate
      autoComplete="off"
    >
      {/* <TextField
        id="outlined-basic"
        label={label}
        value={value}
        variant="outlined"
        disabled
        // error = {error}
        color={error === true ? "error" : "success"}
         
      /> */}
      <label
        style={{
          marginBottom: "-2px",
          textTransform: "capitalize",
          fontWeight: "bold",
          fontSize: "18px",
        }}
      >
        {label}
      </label>
      <Box
        sx={{
          // width: 50,
          height: 35,
          // backgroundColor: "success.main",
          // '&:hover': {
          //   backgroundColor: 'primary.main',
          //   opacity: [0.9, 0.8, 0.7],
          // },
          // dispaly: "flex",
          // flexDirection: "row",
          // alignItems: "cenetr",
          // justifyContent: "center",
          // textAlign: "center",
        }}
        style={{
          background: `${
            label === "Balance Shares"
              ? error === true
                ? "red"
                : "green"
              : "rgb(227 227 227)"
          }`,
          width: "100%",
          // color: "black",
          color: `${label === "Balance Shares" ? "white" : "black"}`,
          fontSize: "20px",
          // fontWeight: "bold",
          dispaly: "flex",
          flexDirection: "row",
          alignItems: "cenetr",
          justifyContent: "center",
          textAlign: "center",
          textTransform: "none",
          padding: "4px",
        }}
      >
        {value}
      </Box>
    </Box>
  );
}
