// import React from "react";
// import MaterialTable from "material-table";
import DataTable from "../Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// const Tables = () => {
//   return (
//     <div style={{ maxWidth: "50%" }}>
//       <p
//         style={{
//           fontFamily: "Mulish",
//           fontStyle: "normal",
//           fontWeight: 600,
//           fontSize: "25px",
//           lineHeight: "31px",
//           color: "#737373",
//         }}
//       >
//         Top 10 shareholders
//       </p>
//       <MaterialTable
//         columns={[
//           { title: "Membership ID", field: "memId" },
//           { title: "MEMBER NAME", field: "memName" },
//           { title: "SHARE CAPITAL", field: "shareCapital" },
//           { title: "%SHARE HOLDING", field: "shareHolder" },
//           { title: "TOTAL SHARES", field: "total", type: "numeric" },
//         ]}
//         data={[
//           {
//             memId: "ID73273",
//             memName: "Member Name",
//             shareCapital: "Member Name",
//             shareHolder: "Member Name",
//             total: 353,
//           },
//           {
//             memId: "ID73273",
//             memName: "Member Name",
//             shareCapital: "Member Name",
//             shareHolder: "Member Name",
//             total: 353,
//           },
//           {
//             memId: "ID73273",
//             memName: "Member Name",
//             shareCapital: "Member Name",
//             shareHolder: "Member Name",
//             total: 353,
//           },
//           {
//             memId: "ID73273",
//             memName: "Member Name",
//             shareCapital: "Member Name",
//             shareHolder: "Member Name",
//             total: 353,
//           },
//           {
//             memId: "ID73273",
//             memName: "Member Name",
//             shareCapital: "Member Name",
//             shareHolder: "Member Name",
//             total: 353,
//           },
//           {
//             memId: "ID73273",
//             memName: "Member Name",
//             shareCapital: "Member Name",
//             shareHolder: "Member Name",
//             total: 353,
//           },
//         ]}
//       />
//     </div>
//   );
// };

// export default Tables;

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EFEFEF",
    fontStyle: "normal",
    fontFamily: "Mulish",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "21px",
    textTransform: "uppercase",
    color: "#5B5B5B",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   //   "&:nth-of-type(odd)": {
//   //     backgroundColor: theme.palette.action.hover,
//   //   },
//   // hide last border
//   "&:last-child td, &:last-child th": {
//     border: 0,
//   },
// }));

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData("ID73273", "Member Name", "Member Name", "Member Name", 353),
//   createData("ID73273", "Member Name", "Member Name", "Member Name", 353),
//   createData("ID73273", "Member Name", "Member Name", "Member Name", 353),
//   createData("ID73273", "Member Name", "Member Name", "Member Name", 353),
//   createData("ID73273", "Member Name", "Member Name", "Member Name", 353),
//   createData("ID73273", "Member Name", "Member Name", "Member Name", 353),
// ];

export default function Tables(props) {
  const { topShareHolder } = props;
  // console.log(topShareHolder);
  const [pricePerShare, setPricePerShare] = useState(0);
  const [sayasAcc, setSayasAcc] = useState({});

  const getSayasData = useSelector((state) => {
    return state;
  });

  useEffect(async () => {
    setSayasAcc(getSayasData.sayasAccount);
    setPricePerShare(getSayasData.dashboard.pricePerShare);
  }, [getSayasData]);

  // console.log(sayasAcc);

  const columns = [
    {
      title: "Membership ID",
      field: "memberId",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            fontSize: "13px",
          }}
        >
          {rowData.memberId}
        </div>
      ),
      customSort: (a, b) => {
        return (a.memberId || "").localeCompare(b.memberId || "");
      },
    },
    {
      title: "Member Name",
      field: "firstName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            fontSize: "13px",
          }}
        >
          {`${rowData.firstName} ${rowData.lastName}`}
        </div>
      ),
      customSort: (a, b) => {
        return (a.firstName || "").localeCompare(b.firstName || "");
      },
    },
    {
      title: "City",
      field: "city",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.city || "").localeCompare(b.city || "");
      },
    },
    {
      title: "District",
      field: "district",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.district || "").localeCompare(b.district || "");
      },
    },
    {
      title: "Taluka",
      field: "taluka",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.taluka || "").localeCompare(b.taluka || "");
      },
    },
    {
      title: "Pin Code",
      field: "pincode",
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Share Capital",
      field: "shareCapital",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            fontSize: "13px",
            // marginLeft: '10px',
          }}
        >
          {rowData.shareCapital}
        </div>
      ),
    },
    {
      title: "%Share Holding",
      field: "shareholder",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            // minWidth: "12rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            fontSize: "13px",
          }}
        >
          {`${
            sayasAcc.totalShares
              ? ((rowData.totalShare / sayasAcc.totalShares) * 100).toFixed(5)
              : 0
          }%`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        return (
          `${((rowData.totalShare / sayasAcc.totalShares) * 100).toFixed(
            5
          )}%`.indexOf(term) === 0
        );
      },
      customSort: (a, b) => {
        return (
          ((a.totalShare / sayasAcc.totalShares) * 100).toFixed(5) -
          ((b.totalShare / sayasAcc.totalShares) * 100).toFixed(5)
        );
      },
    },
    {
      title: "Total Shares",
      field: "totalShare",
      type: "numeric",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            fontSize: "13px",
          }}
        >
          {rowData.totalShare}
        </div>
      ),
    },
  ];

  const options = {
    search: true,
    selection: false,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "500",
      fontSize: 15,
      fontFamily: "Mulish",
      textTransform: "capitalize",
    },
    maxBodyHeight: "64vh",
    pageSize: 10,
  };

  return (
    <div className="shareholders-container">
      <p style={{ fontSize: "21px", textTransform: "capitalize" }}>
        Top 10 Shareholders
      </p>

      <DataTable
        title=""
        columns={columns}
        data={topShareHolder}
        options={options}
      />

      {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: "100%" }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Membership ID</StyledTableCell>
              <StyledTableCell>MEMBERS NAME</StyledTableCell>
              <StyledTableCell>SHARE CAPITAL</StyledTableCell>
              <StyledTableCell>% SHARE HOLDING</StyledTableCell>
              <StyledTableCell>TOTAL SHARES</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.calories}</StyledTableCell>
                <StyledTableCell>{row.fat}</StyledTableCell>
                <StyledTableCell>{row.carbs}</StyledTableCell>
                <StyledTableCell>{row.protein}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
}
