import axios from "axios";
import { LogoutSayasActionCreate } from "../Authontication/authonticationActionCreator";
import actions from "./dashboardActions";
const {
  getDashboardTransferAction,
  getPricePerShareAction,
  getTotalShareHoldersMembersAction,
  getTop10ShareHoldersAction,
  getPieChartDataAction,
} = actions;

const getShareTransefer = async (dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/v1/dashboard`,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // "http://localhost:8080/api/v1/dashboard"

    return response?.data;

    // dispatch(loaderEndActionCreater());
    // console.log("Res", response.data.data);
  } catch (err) {
    // console.log(err?.response?.data?.error);
    // let errors = err.response.data.error;
    // dispatch(openSnackbar(errors, "error"));
    // dispatch(loaderEndActionCreater());
    if (err.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

const getTopShareHolder = async (dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/v1/dashboard/topShareHolders`,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // "http://localhost:8080/api/v1/dashboard"

    return response?.data;

    // dispatch(loaderEndActionCreater());
    // console.log("Res", response.data.data);
  } catch (err) {
    // console.log(err?.response?.data?.error);
    // let errors = err.response.data.error;
    // dispatch(openSnackbar(errors, "error"));
    // dispatch(loaderEndActionCreater());
    if (err.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

const geTotalShareHolderMember = async (dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/v1/dashboard/totalShareHolders`,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // "http://localhost:8080/api/v1/dashboard/totalShareHolders"

    return response?.data;

    // dispatch(loaderEndActionCreater());
    // console.log("Res", response.data.data);
  } catch (err) {
    // console.log(err?.response?.data?.error);
    // let errors = err.response.data.error;
    // dispatch(openSnackbar(errors, "error"));
    // dispatch(loaderEndActionCreater());
    if (err.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

const getPieChartData = async (dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/v1/dashboard/pieChart`,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // "http://localhost:8080/api/v1/dashboard/pieChart"

    return response?.data;

    // dispatch(loaderEndActionCreater());
    // console.log("Res", response.data.data);
  } catch (err) {
    // console.log(err?.response?.data?.error);
    // let errors = err.response.data.error;
    // dispatch(openSnackbar(errors, "error"));
    // dispatch(loaderEndActionCreater());
    if (err.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

export const getDashboardTransfer = (token) => {
  return async (dispatch) => {
    let data = await getShareTransefer(dispatch, token);
    try {
      // console.log("1. getDashboardTransfer",  data);
      dispatch(getDashboardTransferAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const getTop10ShareHolders = (token) => {
  return async (dispatch) => {
    let data = await getTopShareHolder(dispatch, token);
    try {
      // console.log("1. getTop10ShareHolders",  data);
      dispatch(getTop10ShareHoldersAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const getTotalShareHoldersMembers = (token) => {
  return async (dispatch) => {
    let data = await geTotalShareHolderMember(dispatch, token);
    try {
      // console.log("1. geTotalShareHolderMember", data);
      dispatch(getTotalShareHoldersMembersAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const getPricePerShareActionCreater = (data) => {
  return async (dispatch) => {
    try {
      // console.log("1. getPricePerShare");
      dispatch(getPricePerShareAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};

export const getPieChartDataActionCreater = (token) => {
  return async (dispatch) => {
    let data = await getPieChartData(dispatch, token);
    try {
      // console.log("1. getPieChartDataActionCreater",  data);
      dispatch(getPieChartDataAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};
