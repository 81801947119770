import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./DataAcq.module.css";

export default function TypeSelect(props) {
  const { type, optionType, filterUsers, setType } = props;
  // console.log(type);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    setType(item);
  };

  return (
    <div>
      <Button
        id={styles.typeBtn}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled = {props.disabled || false}
      >
        {type ? type : ""}
        {open ? (
          <i
            className="fa fa-caret-up"
            style={{ fontSize: 30, marginLeft: "10px", color: "#c4c4c4" }}
          />
        ) : (
          <i
            className="fa fa-caret-down"
            style={{ fontSize: 30, marginLeft: "10px", color: "#c4c4c4" }}
          />
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {optionType.map((item) => (
          <MenuItem
            key={item}
            id={styles.selectBtn}
            onClick={() => {
              handleClose();
              handleSelect(item);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
