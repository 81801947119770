import { FormControlLabel, Switch } from "@material-ui/core";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import React from "react";

export const QuestionHeader = ({
  required,
  saveCopy,
  setRequired,
  isUpdate,
  userRole,
  onDelete = () => {},
}) => {
  return (
    <div
      className="d-flex align-items-center justify-content-end pt-1 pb-1"
      style={{ borderBottom: "1px solid #E5E5E5", width: "100%" }}
    >
      <div style={{ marginRight: "10px" }}>
        <FormControlLabel
          label="Required"
          labelPlacement="start"
          disabled={
            isUpdate === true && userRole.toLowerCase() === "staff"
              ? true
              : false
          }
          control={
            <Switch
              checked={required}
              color="primary"
              onChange={(e) => setRequired(e.target.checked)}
            />
          }
        />
      </div>
      <div>
        <FilterNoneIcon
          style={{ marginRight: "20px", cursor: "pointer" }}
          onClick={() => {
            if (isUpdate === true && userRole.toLowerCase() === "staff") {
            } else {
              saveCopy();
            }
          }}
        />
        <DeleteOutlineRoundedIcon
          style={{ marginRight: "30px", fontSize: "30px" }}
          onClick={() => {
            if (isUpdate === true && userRole.toLowerCase() === "staff") {
            } else {
              onDelete();
            }
          }}
        />
      </div>
    </div>
  );
};
