import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

function valuetext(value) {
  return value;
}

export const GeneralRangeField = (props) => {
  const [value, setValue] = React.useState([20, 137]);
  const PlaceHolder = props.lable;
  // console.log(PlaceHolder);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let newData = props.data;
    newData[`${props.dataKey}`] = newValue;
    props.setData(newData);
  };

  return (
    <>
      <Box
        component="form"
        fullWidth
        margin= {`${props.marginTop ? "1.3rem .1rem" : ".7rem .1rem" }`}
        sx={{ width: "47%" }}
      >
        <Typography
          sx={{ fontSize: "14px", overflow: "hidden", whiteSpace: "nowrap" }}
        >
          {PlaceHolder}
        </Typography>
        <Slider
          getAriaLabel={() => "Minimum distance"}
          value={value}
          max={props.maxValue}
          onChange={handleChange}
          //   valueLabelDisplay="on"
          valueLabelDisplay="down"
          aria-labelledby="bottom"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>
    </>
  );
};
