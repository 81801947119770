import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import styles from "./MenuDropdown.module.css";

import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { useHistory } from "react-router-dom";

const initialData = {
  avatalName: "",
  userName: "",
  userId: "",
  userMail: "",
};

export default function NavMenuDropdown() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userId, setUserId] = useState("");
  const [profileData, setProfileData] = useState(initialData);
  const [completeData, setCompleteData] = useState({});
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const history = useHistory();

  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth;
  });

  useEffect(() => {
    // console.log(getAuthData);
    setUserId(getAuthData?.user || "");
  }, [getAuthData]);

  // console.log(userId);

  const getUserDetail = async (id) => {
    // console.log(data);

    try {
      let token1 = window.localStorage.getItem("sayasLoginToken");
      // let token1 = getAuthData.loginToken;

      if (!token1) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/users/" + `${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token1}`,
          },
        }
      );
      // console.log(response.data);
      setCompleteData(response.data);
    } catch (e) {
      // console.log("login Error Message ", e.response.data.message);
      if (e?.response?.data?.message)
        dispatch(openSnackbar(e?.response?.data?.message, "error"));
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    if (userId !== "") {
      // console.log("userId", userId);
      getUserDetail(userId);
    }
    // getUserDetail("61ea92fc8ef17363c4c50791");
  }, [userId]);

  useEffect(() => {
    if (completeData.id) {
      let userName = "" + completeData.firstName + " " + completeData.lastName;
      let userId = completeData.userId;
      let userMail = completeData.email;
      let matches = userName.match(/\b(\w)/g);
      let avatalName = matches.join("");

      setProfileData({
        ...profileData,
        userName,
        userId,
        userMail,
        avatalName,
      });
    }
  }, [completeData]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    window.localStorage.removeItem("sayasLoginToken");
    dispatch(LogoutSayasActionCreate());
    setTimeout(() => {
      history.push("/login");
    }, 200);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, borderRadius: "4px", px: 2 }}
        >
          {profileData.userName}
          {open ? (
            <i className="fa fa-caret-up" style={{ marginLeft: 10 }} />
          ) : (
            <i className="fa fa-caret-down" style={{ marginLeft: 10 }} />
          )}
        </IconButton>
        <Avatar sx={{ width: 32, height: 32, marginLeft: 3 }}>
          {profileData.avatalName}
        </Avatar>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 3.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 5,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "none",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem id={styles.menuBtn}>
          <div
            style={{
              width: "228px",
              height: "196px",
              right: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <label>
              <Avatar sx={{ width: 45, height: 45, marginBottom: "10px" }}>
                {profileData.avatalName}
              </Avatar>
            </label>
            <label id={styles.menuLabel}>{profileData.userName}</label>
            <label id={styles.menuLabel}>{profileData.userId}</label>
            <label id={styles.menuLabel}>{profileData.userMail}</label>
            <div className="d-flex justify-content-around align-items-center mt-2">
              <Button
                id={styles.LogProfBtn}
                style={{ borderRight: "3px solid #acacac", borderRadius: 0 }}
              >
                Profile
              </Button>

              <Button id={styles.LogProfBtn} onClick={handleLogout}>
                Logout
              </Button>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
