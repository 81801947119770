import React from "react";
import DataTable from "../../Components/Table";
import { useHistory, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";
import { memberActionCreator } from "../../Redux/Members/actionCreator";
import Loader from "../../Components/Loader/Loader";

const MembersTable = (props) => {
  const { data, tableRef, columns, columnsStatus, setId } = props;
  // console.log("in table : ", props);
  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  // console.log("In mamber Table : ");

  const options = {
    search: false,
    selection: true,
    // filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "bold",
      fontSize: 18,
      fontFamily: "Mulish",
      padding: "8px 5px",
    },
    cellStyle: {
      padding: "10px",
    },
    maxBodyHeight: "60vh",
    pageSize: 10,
    searchFieldStyle: {
      marginRight: "6vw",
      width: "15vw",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
    pageSizeOptions:[10,25,50,100]
  };

  return (
    <>
      {/* {data.length === 0 ? (
        <Loader />
      ) : ( */}
      
      <DataTable
        tableRef={tableRef}
        title=""
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={data}
        options={options}
        onRowClick={(event, rowData) => {
          // console.log("Row Data", rowData);
          dispatch(memberActionCreator(rowData));
          history.push(`${path}/member-info/${rowData._id}`);
          setId(rowData._id);
        }}
      />
      {/* )} */}
    </>
  );
};

export default MembersTable;
