import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPieChartDataActionCreater } from "../../Redux/Dashboard/dashboardActionCreator";
import SelectDropdown from "./../SelectDropdown/SelectDropdown";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieCharts = (props) => {
  const [pieChartFilter, setPieChartFilter] = React.useState("Organisation");
  const [pieChartsData, setPieChartsData] = useState({});
  const [revealCount, setRevealCount] = useState(0);
  const dispatch = useDispatch();

  // console.log(pieChartFilter, pieChartsData);

  const dashboardData = useSelector((state) => {
    return state.dashboard.pieChartDetails;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    dispatch(getPieChartDataActionCreater(getAuthData));
  }, []);

  useEffect(() => {
    setPieChartsData(dashboardData);
  }, [dashboardData]);

  const data =
    pieChartFilter === "City"
      ? pieChartsData?.cityInfo?.slice(0, 10).map((ele, i) => {
          return {
            name: ele.name,
            y: ele.totalShares,
          };
        })
      : pieChartFilter === "State"
      ? pieChartsData?.stateInfo?.slice(0, 10).map((ele, i) => {
          return {
            name: ele.name,
            y: ele.totalShares,
          };
        })
      : pieChartsData?.organisationInfo?.slice(0, 10).map((ele) => {
          return {
            name: ele.name,
            y: ele.totalShares,
          };
        });

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 400,
      width: 400,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        // style: {
        //   height: "40%",
        //   width: "40%",
        // },
        allowPointSelect: true,
        cursor: "pointer",
        depth: 30,
        dataLabels: {
          enabled: true,
          format: "{point.name} {point.percentage:.2f}%",

          // format: '<b>{point.name}</b>: <br /> {point.percentage:.1f} %'
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            // maxWidth: 500,
            width: "50%",
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
              enabled: false,
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: 0,
              },
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
    title: {
      text: null,
    },
    series: [
      {
        name: "Shares",
        colorByPoint: true,
        //   dataLabels: {
        //     style: {
        //       fontSize: '13px',
        //       fontWeight: '600'
        //     },
        // },
        data: data,
        sliced: true,
        selected: true,
      },
    ],
  };

  return (
    <div>
      <p style={{ fontSize: "21px", textTransform: "capitalize" }}>
        Shareholding distribution
      </p>

      <div
        className="d-flex flex-column-reverse align-items-center"
        style={{
          border: "1px solid #F2F2F2",
          borderRadius: "5px",
          width: "100%",
          height: "480px",
          paddingTop: "20px",
          padding: "5px",
          overflow: "hidden",
        }}
      >
        <div>
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
        <div className="align-self-start">
          <SelectDropdown
            pieChartFilter={pieChartFilter}
            setPieChartFilter={setPieChartFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default PieCharts;
