import React, { useEffect } from "react";
import PropTypes from "prop-types";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DataTable from "./../Table";
import styles from "./Dialog.module.css";
import { Button, IconButton } from "@mui/material";
import StateCity from "../Data/StateCity";
import { city } from "../Data/maharashtraCity";
import validator from "validator";
import { useSelector } from "react-redux";
import statesDistrictsTalukas from "../Data/statesDistrictTalukas.json";
import _ from "lodash";

const states = _.keysIn(statesDistrictsTalukas).map((state) => state);

function toFixedMemberId(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}
const isPhone = (val) => {
  return parseInt(val)?.toString().length == 10 &&
    validator.isMobilePhone(val + "", ["en-IN", { strictMode: true }])
    ? true
    : false;
};
const isPin = (val) => {
  return val?.toString().toString().length === 6 ? true : false;
};

const isMemberId = (val) => {
  return val?.toString().length <= 14 && !/[^a-zA-Z0-9]+/g.test(val)
    ? true
    : false;
};

const isEmail = (val) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)
    ? true
    : false;
};

const isAlpha = (val) => {
  return !/[^a-zA-Z\s]/.test(val) ? true : false;
};

const isAmount = (val) => {
  return isNaN(val) ? false : val <= 0 ? false : val % 1 === 0 ? true : false;
};

const isDistrict2 = (data) => {
  // console.log(
  //   "In District check before",
  //   data.state,
  //   data.district,
  //   statesDistrictsTalukas[`${data.state}`]
  // );
  let district;
  if (statesDistrictsTalukas[`${data.state}`]) {
    district = statesDistrictsTalukas[`${data.state}`]?.filter((ele) => {
      // console.log("In District check in", ele);
      return ele["DISTRICT"] === data.district;
    });
  } else {
    district = [];
  }
  // console.log(district.length > 0)

  return district.length > 0;
};

// const isDistrict2 = (data) => {
//   let district = statesDistrictsTalukas[`${data.state}`]?.filter((ele) => {
//     // console.log(ele)
//     return ele["DISTRICT"] === data.district;
//   });

//   return district?.length > 0;
// };

// const isTaluka = (data) => {
//   let talukas = statesDistrictsTalukas[`${data.state}`]?.filter((ele) => {
//     // console.log(ele)
//     return ele["DISTRICT"] === data.district;
//   });

const isTaluka2 = (data) => {
  let isTaluk = false;
  if (data.state === "Maharashtra") {
    let Dis =
      city.filter((e) => e.name === data.district).length > 0
        ? city.filter((e) => e.name === data.district)
        : {};
    isTaluk = Dis[0]?.tahasil?.includes(data.taluka);
  } else {
    isTaluk = true;
  }
  // console.log("Is view:", isTaluk, data);
  return !isTaluk;
};

const isTaluka = (data) => {
  let district = statesDistrictsTalukas[`${data.state}`]?.filter((ele) => {
    // console.log(ele)
    return ele["DISTRICT"] === data.district;
  });

  // console.log(district);

  district = district?.filter((ele) => {
    return ele["TEHSIL"] === data.taluka;
  });

  // console.log(district);

  return district?.length > 0;
};

// let allTaluka = [];
// city.forEach((e) => {
//   allTaluka.push(...e.tahasil);
// });

function SimpleDialog(props) {
  const {
    onClose,
    selectedValue,
    open,
    data,
    // columns,
    stateList,
    module,
    error,
    pinError,
    setOpen,
  } = props;
  const [tableData, setTableData] = React.useState([]);

  const getConfigDataMemberTypeList = useSelector((state) => {
    return state.configuration.memberType.map((ele) => ele.type.toLowerCase());
  });
  const getConfigDataShareHoldingList = useSelector((state) => {
    return state.configuration.shareholderType.map((ele) =>
      ele.type.toLowerCase()
    );
  });
  const getConfigDataRoles = useSelector((state) => {
    return state.configuration.roles?.map((e) => {
      return e.type;
    });
  });
  const getConfigDataTeams = useSelector((state) => {
    return state.configuration.teams?.map((e) => {
      return e.type;
    });
  });

  // console.log({ getConfigDataMemberTypeList, getConfigDataShareHoldingList });

  let isState = (val) => {
    return !states?.includes(val) ? true : false;
  };

  // useEffect(() => {
  //   isState = (val) => {
  //     return !stateList?.includes(val) ? true : false;
  //   };
  // }, [stateList]);

  React.useEffect(() => {
    setTableData(data);
  }, []);

  const handleClose = () => {
    onClose(selectedValue);
    setTableData(data);
  };

  const columns = [
    // { title: "User ID", field: "userId" },
    // { title: "User", field: "user" },
    {
      title: "First Name",
      field: "firstName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isAlpha(rowData.firstName) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isAlpha(rowData.firstName) && rowData.firstName
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.firstName}
        </div>
      ),
      customSort: (a, b) => {
        return (a.firstName || "").localeCompare(b.firstName || "");
      },
    },
    {
      title: "Middle Name",
      field: "middleName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isAlpha(rowData.middleName) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isAlpha(rowData.middleName) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.middleName}
        </div>
      ),
      customSort: (a, b) => {
        return (a.middleName || "").localeCompare(b.middleName || "");
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isAlpha(rowData.lastName) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isAlpha(rowData.lastName) && rowData.lastName
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.lastName}
        </div>
      ),
      customSort: (a, b) => {
        return (a.lastName || "").localeCompare(b.lastName || "");
      },
    },
    {
      title: "Email",
      field: "email",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            // maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isEmail(rowData.email) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isEmail(rowData.email) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.email}
        </div>
      ),
      customSort: (a, b) => {
        return (a.email || "").localeCompare(b.email || "");
      },
    },
    {
      title: "Phone",
      field: "phone",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",

            color: `${isPhone(rowData.phone) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isPhone(rowData.phone) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.phone}
        </div>
      ),
    },
    // { title: "Nominee Name", field: "nomineeName" },
    // { title: "Organisation Name", field: "organisationName" },
    // { title: "State", field: "state" },
    // { title: "City", field: "city" },
    // { title: "Pin Code", field: "pincode" },
    // { title: "Address Line 1", field: "addressLine1" },
    // { title: "Address Line 2", field: "addressLine2" },
    {
      title: "Role",
      field: "role",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",

            color: `${
              getConfigDataRoles?.includes(rowData?.role)
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              getConfigDataRoles?.includes(rowData?.role)
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.role}
        </div>
      ),
      customSort: (a, b) => {
        return (a.role || "").localeCompare(b.role || "");
      },

      // if(data[i] && data[i].role && !["admin", "staff","manager"].includes(data[i].role.toLowerCase())){
      //   errUsers.push({ ...data[i], error: "Invalid Role "+data[i].role});
      // }
    },
    {
      title: "Teams",
      field: "teams",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",

            color: `${
              rowData.teams.split(",").filter((ele) => {
                return getConfigDataTeams?.includes(ele);
              }).length === rowData.teams.split(",").length
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              rowData.teams.split(",").filter((ele) => {
                return getConfigDataTeams?.includes(ele);
              }).length === rowData.teams.split(",").length
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData && rowData.teams ? rowData.teams.split(",").join(",") : ""}
        </div>
      ),

      customSort: (a, b) => {
        return (
          (a && a.teams ? a.teams.split(",").join(",") : "") || ""
        ).localeCompare(
          (b && b.teams ? b.teams.split(",").join(",") : "") || ""
        );
      },
    },
    // { title: "Access", field: "access" },
    // {
    //   title: "Date",
    //   field: "date",
    //   type: "date",
    //   dateSetting: {
    //     format: "dd/MM/yyyy",
    //   },
    // },
    {
      title: "Activity Status",
      field: "activityStatus",
      render: (rowData) => rowData.activityStatus,
    },
  ];

  const columnsMember = [
    {
      title: "Member ID",
      field: "memberId",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isMemberId(rowData.memberId) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isMemberId(rowData.memberId) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.memberId}
        </div>
      ),
      customSort: (a, b) => {
        return (a.memberId || "").localeCompare(b.memberId || "");
      },
    },
    {
      title: "Member",
      field: "member",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",

            color: `${
              getConfigDataMemberTypeList?.includes(
                rowData?.member?.toLowerCase()
              )
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              getConfigDataMemberTypeList?.includes(
                rowData?.member?.toLowerCase()
              )
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.member}
        </div>
      ),
      customSort: (a, b) => {
        return (a.member || "").localeCompare(b.member || "");
      },
    },
    {
      title: "First Name",
      field: "firstName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${
              isAlpha(rowData.firstName) && rowData.firstName
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              isAlpha(rowData.firstName) && rowData.firstName
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.firstName}
        </div>
      ),
      customSort: (a, b) => {
        return (a.firstName || "").localeCompare(b.firstName || "");
      },
    },
    {
      title: "Middle Name",
      field: "middleName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${
              isAlpha(rowData.middleName) && rowData.middleName
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              isAlpha(rowData.middleName) && rowData.middleName
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.middleName}
        </div>
      ),
      customSort: (a, b) => {
        return (a.middleName || "").localeCompare(b.middleName || "");
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${
              isAlpha(rowData.lastName) && rowData.lastName
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              isAlpha(rowData.lastName) && rowData.lastName
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.lastName}
        </div>
      ),
      customSort: (a, b) => {
        return (a.lastName || "").localeCompare(b.lastName || "");
      },
    },
    {
      title: "Email",
      field: "email",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            // maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            // color: `${isEmail(rowData.email) ? "#000000" : "#ffffff"}`,
            // backgroundColor: `${
            //   isEmail(rowData.email) ? "#21252900" : "#e34646"
            // }`,
          }}
        >
          {rowData.email}
        </div>
      ),
      customSort: (a, b) => {
        return (a.email || "").localeCompare(b.email || "");
      },
    },
    {
      title: "Phone",
      field: "phone",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isPhone(rowData.phone) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isPhone(rowData.phone) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.phone}
        </div>
      ),
    },
    {
      title: "Nominee Name",
      field: "nominee_name",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",

            color: `${
              isAlpha(rowData.nominee_name) && rowData.nominee_name
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              isAlpha(rowData.nominee_name) && rowData.nominee_name
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.nominee_name}
        </div>
      ),
      customSort: (a, b) => {
        return (a.nominee_name || "").localeCompare(b.nominee_name || "");
      },
    },
    { title: "Organisation Name", field: "organisation_name" },

    {
      title: "State",
      field: "state",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isState(rowData.state) ? "#ffffff" : "#000000"}`,
            backgroundColor: `${
              isState(rowData.state) ? "#e34646" : "#21252900"
            }`,
          }}
        >
          {rowData.state}
        </div>
      ),
      customSort: (a, b) => {
        return (a.state || "").localeCompare(b.state || "");
      },
    },
    {
      title: "District",
      field: "district",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${!isDistrict2(rowData) ? "#ffffff" : "#000000"}`,
            backgroundColor: `${
              !isDistrict2(rowData) ? "#e34646" : "#21252900"
            }`,
          }}
        >
          {rowData.district}
        </div>
      ),
      customSort: (a, b) => {
        return (a.district || "").localeCompare(b.district || "");
      },
    },
    {
      title: "Taluka",
      field: "taluka",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${!isTaluka(rowData) ? "#ffffff" : "#000000"}`,
            backgroundColor: `${!isTaluka(rowData) ? "#e34646" : "#21252900"}`,
          }}
        >
          {rowData.taluka}
        </div>
      ),
      customSort: (a, b) => {
        return (a.taluka || "").localeCompare(b.taluka || "");
      },
    },
    {
      title: "City",
      field: "city",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${
              !/[^a-zA-Z\s]/.test(rowData.city) ? "#000000" : "#ffffff"
            }`,
            backgroundColor: `${
              !/[^a-zA-Z\s]/.test(rowData.city) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.city}
        </div>
      ),
      customSort: (a, b) => {
        return (a.city || "").localeCompare(b.city || "");
      },
    },
    // {
    //   title: "Share Capital",
    //   field: "shareCapital",
    //   headerStyle: {
    //     whiteSpace: "nowrap",
    //   },
    // },
    // {
    //   title: "Total Shares",
    //   field: "totalShare",
    //   headerStyle: {
    //     whiteSpace: "nowrap",
    //   },
    // },
    // {
    //   title: "Share Application Money",
    //   field: "shareApplicationMoney",
    //   headerStyle: {
    //     whiteSpace: "nowrap",
    //   },
    // },

    {
      title: "Pin Code",
      field: "pincode",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isPin(rowData.pincode) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isPin(rowData.pincode) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.pincode}
        </div>
      ),
    },
    {
      title: "Address Line 1",
      field: "addressLine_1",
      customSort: (a, b) => {
        return (a.addressLine_1 || "").localeCompare(b.addressLine_1 || "");
      },
    },
    {
      title: "Address Line 2",
      field: "addressLine_2",
      customSort: (a, b) => {
        return (a.addressLine_2 || "").localeCompare(b.addressLine_2 || "");
      },
    },
    // { title: "Role", field: "role" },
    // { title: "Team", field: "team" },
    // { title: "Access", field: "access" },
    // {
    //   title: "Date",
    //   field: "date",
    //   type: "date",
    //   dateSetting: {
    //     format: "dd/MM/yyyy",
    //   },
    // },
    {
      title: "Activity Status",
      field: "activityStatus",
      render: (rowData) =>
        rowData.activityStatus === true ? <>Active</> : <>Inactive</>,
    },
    {
      title: "Mobile Status",
      field: "mobileStatus",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) =>
        rowData.mobileStatus === true ? <>Enabled</> : <>Disabled</>,
    },
    {
      title: "Share Holder",
      field: "shareHolder",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",

            color: `${
              getConfigDataShareHoldingList?.includes(
                rowData?.shareHolder?.toLowerCase()
              )
                ? "#000000"
                : "#ffffff"
            }`,
            backgroundColor: `${
              getConfigDataShareHoldingList?.includes(
                rowData?.shareHolder?.toLowerCase()
              )
                ? "#21252900"
                : "#e34646"
            }`,
          }}
        >
          {rowData.shareHolder}
        </div>
      ),
    },
  ];

  const columnsMemberShareApplication = [
    {
      title: "Member ID",
      field: "memberId",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isMemberId(rowData.memberId) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isMemberId(rowData.memberId) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.memberId}
        </div>
      ),
      customSort: (a, b) => {
        return (a.memberId || "").localeCompare(b.memberId || "");
      },
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            // maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            padding: "2px 10px",
            textOverflow: "ellipsis",
            color: `${isAmount(rowData.amount) ? "#000000" : "#ffffff"}`,
            backgroundColor: `${
              isAmount(rowData.amount) ? "#21252900" : "#e34646"
            }`,
          }}
        >
          {rowData.amount}
        </div>
      ),
    },
  ];

  const options = {
    search: true,
    selection: false,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
    },
    // rowStyle: (rowData, i) => {
    //   if (!error[i]) {
    //     return { background: "#FEDEDE" };
    //   }
    // },
    maxBodyHeight: "45vh",
    pageSize: 5,
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg">
      {/* <DialogTitle>
        <div
          style={{
            position: "absolute",
            right: "2%",
            top: "10%",
            zIndex: 100,
          }}
          onClick={() => setOpen(false)}
        >
          <i
            class="fa fa-times"
            style={{
              color: "#000",
              fontSize: 25,
              color: "#afafaf",
              cursor: "pointer",
            }}
          ></i>
        </div>
      </DialogTitle> */}
      <div>
        <DataTable
          title="File Preview"
          columns={
            module === "member"
              ? columnsMember
              : module === "shareApplication"
              ? columnsMemberShareApplication
              : columns
          }
          data={tableData}
          options={options}
          // onRowClick={(event, rowData) => {
          //   dispatch(userActionCreator(rowData));
          //   history.push(`${path}/user-info`);
          // }}
        />
      </div>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DialoguePriview(props) {
  // const { stateList } = props;
  const [open, setOpen] = React.useState(false);

  // console.log(props.error);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  return (
    <div className="mt-3">
      <div
        onClick={handleClickOpen}
        style={{ cursor: "pointer" }}
        className="d-flex"
      >
        <label id={styles.viewBtn}>View File</label>
        <div>
          <i className="fa fa-eye" id={styles.icon} aria-hidden="true" />
        </div>
      </div>
      <div>
        <SimpleDialog
          //   selectedValue={selectedValue}
          // stateList={stateList}
          open={open}
          onClose={handleClose}
          data={props.data}
          columns={props.columns}
          error={props.error}
          pinError={props.pinError}
          setOpen={setOpen}
          module={props.module}
        />
      </div>
    </div>
  );
}
