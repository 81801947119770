const actions = {
  MEMBER_CREATE: "MEMBER_CREATE",
  SELECTED_MEMBER: "SELECTED_MEMBER",
  GET_MEMBER_DATA: "GET_MEMBER_DATA",
  CLEAR_SELECTION: "CLEAR_SELECTION",

  getMemberAction: (data) => {
    // console.log("getMemberAction", data);
    return {
      type: actions.GET_MEMBER_DATA,
      data,
    };
  },

  createMemberAction: (data) => {
    // console.log("check", data);
    return {
      type: actions.MEMBER_CREATE,
      data,
    };
  },
  selectedMemberAction: (data, compData) => {
    // console.log("check", data);
    return {
      type: actions.SELECTED_MEMBER,
      data,
      compData,
    };
  },

  clearSelectedMemberAction: (data) => {
    // console.log("check", data);
    return {
      type: actions.CLEAR_SELECTION,
      data,
    };
  },

  memberDataAction: (data) => {
    // console.log("memberDataAction", data);
    return {
      type: "memberData",
      data,
    };
  },

  memberDataVisibiltyAction: (data) => {
    // console.log("memberDataAction", data);
    return {
      type: "COLUMNSTATUS",
      data,
    };
  },
};

export default actions;
