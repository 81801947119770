import React, { useState, useEffect } from "react";
import { MemberHeader } from "./MemberHeader";
import { useDispatch, useSelector } from "react-redux";
import MembersTable from "./MembersTable";
import {
  memberActionCreator,
  memberDataActionCreater,
  memberDataVisibiltyActionCreater,
  selectedMemberRows,
} from "../../Redux/Members/actionCreator";
import axios from "axios";
import { openSnackbar } from "./../../Redux/Snackbar/snackbarStore";
import { MultiSelectDropdown } from "../../Components/SelectDropdown/MultiSelectDropdown";
import { BASE_URL } from "./../../Components/URL/Url";
import {
  loaderStartActionCreater,
  loaderEndActionCreater,
} from "./../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { getPricePerShareActionCreater } from "../../Redux/Dashboard/dashboardActionCreator";
import CustomSearch from "../../Components/CustomSearch/CustomSearch";

const MembersHome = (props) => {
  const { setMemberDataLength, memberDataLength, setId } = props;
  const [data, setData] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [finalAdvanceSearchData, setFinalAdvanceSearchData] = useState(null);
  const [conflict, setConflict] = useState(false);
  const selectedMembers = useSelector((state) => state.members.selectedRow);
  const dispatch = useDispatch();
  const tableRef = React.createRef();
  const [isAction, setIsAction] = useState(false);

  const [option_users, setOption_users] = useState([
    "All Members",
    "Nominal Members",
    "Regular Members",
    "Mobile App Enabled",
    "Active Members",
    "Inactive Members",
    "Mobile App Disabled",
  ]);
  const [option_action_1, setOption_action_1] = useState([
    "Import Members",
    "Export Members",
    "Import Share Application",
    "Share Transfer",
  ]);
  const [option_action_2, setOption_action_2] = useState([
    "Activate Member",
    "Enable Mobile App",
    "Disable Mobile App",
    "Update Member Type",
    "Issue Shares",
    "Deactivate Member",
  ]);

  const [sayasAcc, setSayasAcc] = useState({});

  const dashboardData = useSelector((state) => {
    // console.log("In sayas account fetch, Member :");
    return state.sayasAccount;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    // console.log("dashboardData", dashboardData);
    setSayasAcc(dashboardData);
  }, [dashboardData]);

  // console.log("Loding... Member...");

  useEffect(() => {
    // console.log("sayasAcc", sayasAcc);

    dispatch(getPricePerShareActionCreater(sayasAcc?.pricePerShare));
  }, [sayasAcc]);

  // const option_users = [
  //   "All Members",
  //   "Nominal Members",
  //   "Regular Members",
  //   "Mobile App Enabled",
  //   "Active Members",
  //   "Inactive Members",
  //   "Mobile App Disabled",
  // ];
  // const option_action_1 = [
  //   "Import Members",
  //   "Export Members",
  //   "Import Share Application",
  //   "Share Transfer",
  // ];

  // const option_action_2 = [
  //   "Activate Member",
  //   "Enable Mobile App",
  //   "Disable Mobile App",
  //   "Update Member Type",
  //   "Issue Shares",
  //   "Deactivate Member",
  // ];

  //add permission
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    // console.log("in auth fetch , member");
    return state.auth.role;
  });
  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  useEffect(() => {
    // console.log("userRole", userRole);
    if (userRole !== "") {
      if (userRole.toLowerCase() === "staff") {
        setOption_action_1([]);
        setOption_action_2([]);
      }
    }
  }, [userRole]);

  // const selectedUsers = useSelector((state) => state.members.selectedRow);

  // const [options, setOptions] = useState(option_action_1);

  const [optionVal, setOptionVal] = useState("All Members");

  // useEffect(() => {
  //   console.log("all Rows", selectedUsers.length);
  // }, [selectedUsers]);

  const getTableDetails = async () => {
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(BASE_URL + `/api/v1/member`, {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      });

      // console.log(response.data.data);

      setData(
        response.data.data.map((ele) => {
          return {
            ...ele,
            shareCapital: ele.shareCapital > 0 ? ele.shareCapital : "0",
            totalShare: ele.totalShare > 0 ? ele.totalShare : "0",
            shareApplicationMoney:
              ele.shareApplicationMoney > 0 ? ele.shareApplicationMoney : "0",
            activity: ele.activityStatus === true ? "Active" : "Inactive",
            mobileStatus:
              ele.mobileSignUpStatus === true ? "Enabled" : "Disabled",
          };
        })
      );
      setFinalData(
        response.data.data.map((ele) => {
          return {
            ...ele,
            shareCapital: ele.shareCapital > 0 ? ele.shareCapital : "0",
            totalShare: ele.totalShare > 0 ? ele.totalShare : "0",
            shareApplicationMoney:
              ele.shareApplicationMoney > 0 ? ele.shareApplicationMoney : "0",
            activity: ele.activityStatus === true ? "Active" : "Inactive",
            mobileStatus:
              ele.mobileSignUpStatus === true ? "Enabled" : "Disabled",
          };
        })
      );
      setMemberDataLength(response.data.count);
      dispatch(loaderEndActionCreater());
      setIsAction(!isAction);
    } catch (err) {
      // console.log(err);
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      dispatch(loaderEndActionCreater());
    }
  };

  // .map((ele) => {
  //   return {
  //     ...ele,
  //     shareCapital: ele.shareCapital > 0 ? ele.shareCapital : "0",
  //     totalShare: ele.totalShare > 0 ? ele.totalShare : "0",
  //     totalShare: ele.totalShare > 0 ? ele.totalShare : "0",
  //     activity: ele.activityStatus === true ? "Active" : "Inactive",
  //   };
  // })

  const handleActivateStatus = async (status, selectedUsers, setOptions) => {
    dispatch(loaderStartActionCreater());
    let memberArray = selectedUsers?.map((user) => user.id);
    if (status == "Activate Member") {
      const memberData = { memberIds: memberArray, activityStatus: true };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/member/updateStatus`, memberData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getTableDetails();
        dispatch(openSnackbar("Member Activated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Deactivate Member") {
      const memberData = { memberIds: memberArray, activityStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/member/updateStatus`, memberData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getTableDetails();
        dispatch(openSnackbar("Member Deactivated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Enable Mobile App") {
      const memberData = { memberIds: memberArray, mobileSignUpStatus: true };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(
          BASE_URL + `/api/v1/member/updateMobileStatus`,
          memberData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        getTableDetails();
        dispatch(openSnackbar("Mobile App Enabled Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err.request.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Disable Mobile App") {
      const memberData = { memberIds: memberArray, mobileSignUpStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(
          BASE_URL + `/api/v1/member/updateMobileStatus`,
          memberData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        getTableDetails();
        dispatch(openSnackbar("Mobile App Disabled Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    }
    setOptions(option_action_1);
    dispatch(memberActionCreator([]));
    // setOptionVal("All Members");
    setIsAction(true);
    dispatch(loaderEndActionCreater());
  };

  const filterUsers = async (name) => {
    setOptionVal(name);
    dispatch(loaderStartActionCreater());
    if (name == "Active Members") {
      // let newData = data.filter((item) => item.activityStatus == true);
      let newData = finalAdvanceSearchData?.filter(
        (item) => item.activityStatus == true
      );
      // console.log("active", active);
      setFinalData(newData);
      dispatch(
        openSnackbar("Active Members Filtered Successfully!", "success")
      );
    } else if (name == "Inactive Members") {
      // let newData = data.filter((item) => item.activityStatus !== true);
      let newData = finalAdvanceSearchData?.filter(
        (item) => item.activityStatus !== true
      );
      setFinalData(newData);
      dispatch(
        openSnackbar("Active Members Filtered Successfully!", "success")
      );
    } else if (name == "Mobile App Enabled") {
      // let newData = data.filter((item) => item.mobileSignUpStatus == true);
      let newData = finalAdvanceSearchData?.filter(
        (item) => item.mobileSignUpStatus == true
      );
      setFinalData(newData);
      dispatch(
        openSnackbar(
          "Mobile App Enabled Members Filtered Successfully!",
          "success"
        )
      );
    } else if (name == "Mobile App Disabled") {
      // let newData = data.filter((item) => item.mobileSignUpStatus !== true);
      let newData = finalAdvanceSearchData?.filter(
        (item) => item.mobileSignUpStatus !== true
      );
      setFinalData(newData);
      dispatch(
        openSnackbar(
          "Mobile App Disabled Members Filtered Successfully!",
          "success"
        )
      );
    } else if (name == "Nominal Members") {
      // let newData = data.filter(
      //   (item) => item?.member?.toLowerCase() === "nominal"
      // );
      let newData = finalAdvanceSearchData?.filter(
        (item) => item?.member?.toLowerCase() === "nominal"
      );
      setFinalData(newData);
      dispatch(
        openSnackbar("Nominal Members Filtered Successfully!", "success")
      );
    } else if (name == "Regular Members") {
      // let newData = data.filter(
      //   (item) => item?.member?.toLowerCase() === "regular"
      // );
      let newData = finalAdvanceSearchData?.filter(
        (item) => item?.member?.toLowerCase() === "regular"
      );
      setFinalData(newData);
      dispatch(
        openSnackbar("Regular Members Filtered Successfully!", "success")
      );
    } else if (name == "All Members") {
      setFinalData(data);
    }
    dispatch(loaderEndActionCreater());
  };

  useEffect(() => {
    if (getAuthData) {
      // dispatch(loaderEndActionCreater());
      getTableDetails();
    }
  }, [getAuthData]);

  useEffect(() => {
    if (finalData) dispatch(loaderEndActionCreater());
  }, [finalData]);

  useEffect(() => {
    if (getAuthData) {
      // console.log("in sude conflict",conflict);
      setConflict(false);
      dispatch(loaderStartActionCreater());
      getTableDetails();
      setIsAction(false);
    }
  }, [conflict, getAuthData]);
  // console.log("out sude conflict",conflict);

  useEffect(() => {
    if (finalData && selectedMembers?.length) {
      let myData = finalData ? [...finalData] : [];

      let selectedEl = selectedMembers[selectedMembers?.length - 1];
      // console.log("selectedEl", selectedEl);
      let index = myData.findIndex((el) => el.memberId === selectedEl.memberId);

      let topEl = myData.splice(index, 1);
      // console.log("topEl", topEl);
      myData.unshift(topEl[0]);
      // console.log("myData", myData, index);
      setFinalData([...myData]);
    }
  }, [selectedMembers]);

  const groupFilter = (target, search) => {
    return target !== null
      ? search
          .split(",")
          .filter((t) => t.trim() !== "")
          .some((t) =>
            typeof target === "string"
              ? target.toLowerCase().indexOf(t.trim().toLowerCase()) > -1
              : target.toString().indexOf(t.trim()) === 0
          )
      : false;
  };

  const initialColumnStatus = {
    memberId: false,
    member: false,
    firstName: true,
    middleName: false,
    lastName: true,
    email: false,
    phone: true,
    nominee_name: false,
    organisation_name: false,
    state: true,
    city: true,
    district: true,
    taluka: true,
    pincode: true,
    addressLine_1: false,
    addressLine_2: false,
    role: false,
    team: false,
    date: false,
    totalShare: true,
    shareApplicationMoney: true,
    activityStatus: false,
    mobileSignUpStatus: false,
  };

  const columns = [
    {
      title: "Member ID",
      field: "memberId",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.memberId, term),
      customSort: (a, b) => {
        return (a.memberId || "").localeCompare(b.memberId || "");
      },
    },
    {
      title: "Member",
      field: "member",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.member, term),
      customSort: (a, b) => {
        return (a.member || "").localeCompare(b.member || "");
      },
    },
    {
      title: "First Name",
      field: "firstName",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.firstName, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.firstName || "").localeCompare(b.firstName || "");
      },
    },
    {
      title: "Middle Name",
      field: "middleName",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.middleName, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.middleName || "").localeCompare(b.middleName || "");
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.lastName, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.lastName || "").localeCompare(b.lastName || "");
      },
    },
    {
      title: "Email",
      field: "email",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.email, term),
      customSort: (a, b) => {
        return (a.email || "").localeCompare(b.email || "");
      },
    },
    {
      title: "Phone",
      field: "phone",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.phone, term),
    },
    {
      title: "Nominee Name",
      field: "nominee_name",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.nominee_name, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.nominee_name || "").localeCompare(b.nominee_name || "");
      },
    },
    {
      title: "Organisation Name",
      field: "organisation_name",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.organisation_name, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.organisation_name || "").localeCompare(
          b.organisation_name || ""
        );
      },
    },
    {
      title: "State",
      field: "state",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.state, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.state || "").localeCompare(b.state || "");
      },
    },
    {
      title: "District",
      field: "district",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.district, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.district || "").localeCompare(b.district || "");
      },
    },
    {
      title: "Taluka",
      field: "taluka",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.taluka, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.taluka || "").localeCompare(b.taluka || "");
      },
    },
    {
      title: "City",
      field: "city",
      customFilterAndSearch: (term, rowData) => groupFilter(rowData.city, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.city || "").localeCompare(b.city || "");
      },
    },
    {
      title: "Share Capital",
      field: "shareCapital",
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.shareCapital, term),
      headerStyle: {
        whiteSpace: "nowrap",
      },
      //   customFilterAndSearch: (term, rowData) =>
      //     rowData.shareCapital.toString().indexOf(term) === 0,
    },
    {
      title: "Total Shares",
      field: "totalShare",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.totalShare, term),
    },
    {
      title: "Share Application Money",
      field: "shareApplicationMoney",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.shareApplicationMoney, term),
    },
    {
      title: "% Shareholding",
      field: "totalShare",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) =>
        `${
          sayasAcc?.totalShares
            ? ((rowData?.totalShare / sayasAcc?.totalShares) * 100).toFixed(5)
            : 0
        }%`,
      customFilterAndSearch: (term, rowData) =>
        groupFilter(
          `${((rowData?.totalShare / sayasAcc?.totalShares) * 100).toFixed(
            5
          )}%`,
          term
        ),
    },
    {
      title: "Pin Code",
      field: "pincode",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.pincode, term),
    },
    {
      title: "Address Line 1",
      field: "addressLine_1",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.addressLine_1, term),
      customSort: (a, b) => {
        return (a.addressLine_1 || "").localeCompare(b.addressLine_1 || "");
      },
    },
    {
      title: "Address Line 2",
      field: "addressLine_2",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.addressLine_2, term),
      customSort: (a, b) => {
        return (a.addressLine_2 || "").localeCompare(b.addressLine_2 || "");
      },
    },
    {
      title: "Activity Status",
      field: "activity",
      // render: (rowData) =>
      //   rowData.activityStatus === true ? <>Active</> : <>Inactive</>,
    },
    {
      title: "Mobile App",
      field: "mobileStatus",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      // render: (rowData) =>
      //   rowData.mobileSignUpStatus === true ? <>Enabled</> : <>Disabled</>,
    },
    {
      title: "Share Holder",
      field: "shareholder",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        groupFilter(rowData.shareholder, term),
      customSort: (a, b) => {
        return (a.shareholder || "").localeCompare(b.shareholder || "");
      },
    },
  ];
  // useEffect(() => {
  //   //  console.log("hi");
  // }, [data, finalData]);
  // console.log("Im member");
  // const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  const columnsStatus = useSelector((state) => {
    // console.log(state.members.columnsStatus);
    return state.members.columnsStatus;
  });

  const setColumnsStatus = (data) => {
    // console.log(data);
    dispatch(memberDataVisibiltyActionCreater(data));
  };

  // useEffect(() => {
  //   if (tableRef) console.log(tableRef);
  //   if (tableRef && tableRef.current !== null) {
  //     console.log(tableRef.current.state.data);
  //     setCounter(counter + 1);
  //   }
  // }, [tableRef]);

  useEffect(() => {
    dispatch(selectedMemberRows(undefined, []));
  }, []);

  return (
    <>
      <MemberHeader
        tableRef={tableRef}
        option_users={option_users}
        option_action_1={option_action_1}
        option_action_2={option_action_2}
        // options={options}
        // setOptions={setOptions}
        setConflict={setConflict}
        handleActivateStatus={handleActivateStatus}
        // selectedUsers={selectedUsers}
        filterUsers={filterUsers}
        optionVal={optionVal}
        setData={setData}
        data={data}
        finalData={finalData}
        memberDataLength={memberDataLength}
        columns={columns}
        columnsStatus={columnsStatus}
        setIsAction={setIsAction}
      />

      <div>
        <div
          className="d-flex justify-content-end gap-2 align-items-center mb-3"
          style={{ padding: "0rem 2rem" }}
        >
          {/* {console.log(tableRef ? tableRef?.current?.state?.data : data)} */}
          <CustomSearch
            data={data || []}
            // data={tableRef ? tableRef?.current?.state?.data : data}
            ref={tableRef}
            setOptionVal={setOptionVal}
            tableData={finalData}
            setTableData={setFinalData}
            finalAdvanceSearchData={finalAdvanceSearchData}
            setFinalAdvanceSearchData={setFinalAdvanceSearchData}
            rangeSearchCol={[
              ["Total Shares", "totalShare"],
              ["Share Capital", "shareCapital"],
              ["Share Application Money", "shareApplicationMoney"],
            ]}
            simpleSearchCol={[
              ["State", "state"],
              ["District", "district"],
              ["Taluka", "taluka"],
              ["City", "city"],
              ["Organisation Name", "organisation_name"],
              ["Member Type", "member"],
              ["Share Holder", "shareholder"],
              ["Mobile App", "mobileStatus"],
              ["Activity Status", "activity"],
              ["Pin Code", "pincode"],
            ]}
            columns={columns}
            isAction={isAction}
            setIsAction={setIsAction}
          />
          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>
        <MembersTable
          tableRef={tableRef}
          data={finalData || []}
          setMemberDataLength={setMemberDataLength}
          columns={columns}
          columnsStatus={columnsStatus}
          setId={setId}
        />
      </div>
    </>
  );
};

export default MembersHome;
