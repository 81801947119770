import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { memberDataActionCreater } from "../../Redux/Members/actionCreator";
import styles from "./DataAcq.module.css";
import moment from "moment";
import axios from "axios";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { BASE_URL } from "../../Components/URL/Url";
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
const DataAcqCard = (props) => {
  const { selectedData, dataAquisition } = props;
  const [totalMember, setTotalMember] = useState(0);
  const dispatch = useDispatch();

  // const getSayasData = useSelector((state) => {
  //   // console.log(state);
  //   return state.members;
  // });
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  // console.log(parseInt(selectedData?.totalRes), totalMember, 100);
  const getTableDetails = async () => {
    try {
      dispatch(loaderStartActionCreater());

      let token = window.localStorage.getItem("sayasLoginToken");
      // let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(BASE_URL + `/api/v1/member`, {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      });

      // console.log(response.data.count);
      setTotalMember(response.data.count);

      dispatch(loaderEndActionCreater());
    } catch (err) {
      // console.log(err);
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      dispatch(loaderEndActionCreater());
    }
  };
  useEffect(() => {
    getTableDetails();
    // console.log(getSayasData?.selectedRow, 25);
    // setTotalMember(getSayasData?.selectedRow[0]?.count);
  }, []);

  const cardValue = [
    {
      title: "Title",
      val: `${dataAquisition?.title || "..."}`,
    },
    {
      title: "Total Response",
      val: `${
        dataAquisition?.title === null ? "0" : selectedData?.totalRes || 0
      }`,
    },
    {
      title: "Total % Responce",
      val: `${
        dataAquisition?.title === null
          ? "0"
          : isNaN(totalMember) || isNaN(parseInt(selectedData?.totalRes))
          ? 0
          : (
              (parseInt(selectedData?.totalRes) / parseInt(totalMember)) *
              100
            ).toFixed(5)
      }%`,
    },
    {
      title: "Status",
      val: `${
        dataAquisition.standing
          ? dataAquisition.standing === "save" ||
            (new Date(dataAquisition.start) > new Date() &&
              dataAquisition.standing !== "closed" &&
              dataAquisition.standing !== "cancelled")
            ? "Draft"
            : dataAquisition.standing === "cancelled"
            ? "Cancelled"
            : new Date(dataAquisition.end) >= new Date() &&
              new Date(dataAquisition.start) <= new Date() &&
              dataAquisition?.standing?.toLowerCase() === "launch"
            ? "Open"
            : "Closed"
          : "..."
      }`,
    },
  ];

  const borderLine = (i) => {
    return i < 3 ? "2px solid #cccccc" : "none";
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-around"
        style={{ margin: "0px 10px" }}
      >
        {cardValue.map((item, i) => (
          <div
            className="d-flex justify-content align-items-center"
            style={{ flex: 1 }}
          >
            <div
              style={{
                borderRight: borderLine(i),
                paddingLeft: "20px",
                paddingRight: "10px",
                margin: "20px 0",
                width: "100%",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ marginBottom: "-5px" }}
              >
                <p className="m-0" id={styles.cardlabel}>
                  {item.title}
                </p>
              </div>
              <div>
                <p className="m-0" id={styles.cardValue}>
                  {item.val}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div>
        <hr className="m-0" style={{ height: "1px" }} />
      </div>
    </div>
  );
};

export default DataAcqCard;
