import React, { useState } from "react";
import styles from "./Configuration.module.css";
const ConfigList = (props) => {
  const { configData, setConfigType } = props;
  const [select, setSelect] = useState("memberType");
  // console.log(configData);

  const withBackground = {
    cursor: "pointer",
    background: "#F3F3F3",
  };

  return (
    <div>
      {configData.map((item) => (
        <div
          onClick={() => {
            setSelect(item.dataKey);
            setConfigType({ label: item.label, dataKey: item.dataKey });
          }}
          style={
            item.dataKey === select
              ? withBackground
              : {
                  cursor: "pointer",
                  fontFamily: "Mulish",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontHeight: "25px",
                  color: "#000000",
                }
          }
        >
          <h6 className={styles.configListItem}>{item.label}</h6>
          <hr className="m-0" />
        </div>
      ))}
    </div>
  );
};

export default ConfigList;
