import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./member.module.css";
import MemberShareCards from "./MemberShareCards";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { useEffect, useState } from "react";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { getSayasAccountActionCreater } from "../../Redux/SayasAccount/sayasAccountActionCreator";
import {
  getDashboardTransfer,
  getPieChartDataActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../Redux/Dashboard/dashboardActionCreator";
import {
  getShareForMemberWithId,
  selectedMemberRows,
} from "../../Redux/Members/actionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import CloseIcon from "@mui/icons-material/Close";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { useHistory } from "react-router-dom";
import { GeneralSelectField } from "../../Components/GeneralComponents/GeneralSelectField";
import BulkIssueShareConfirmationModel from "./MembersForm/bulkIssueShareConfirmation";
export default function MemberIssueShares(props) {
  const {
    setConflict2,
    setUpdate,
    totalShareApplicationMoney,
    pricePerShare,
    open,
    tableData,
    memberIds,
    setOpen,
    setIsAction,
  } = props;

  // console.log("memberIds", memberIds, tableData);

  // find member ID
  const params = useParams();
  // console.log(params);

  const history = useHistory();
  const dispatch = useDispatch();
  const [issuableShare, setIssuableShare] = useState(0);
  const [shareType, setShareType] = useState("");

  const [shareSeriesId, setShareSeriesId] = useState(""); // to handle Series Id value for bulk share issue
  const [shareSeriesIds, setShareSeriesIds] = useState([]); // to store all Ids Series Ids value for bulk share issue
  const [minValue, setMinValue] = useState(""); // to handle min value for bulk share issue
  const [maxValue, setMaxValue] = useState(""); // to handle max value for bulk share issue
  const [openConfirm, setOpenConfirm] = useState(false); // to handle open confirm window
  const [inputVal, setInputVal] = useState(0);
  const [allShareDetail, setAllShareDetail] = useState({});

  const [data, setData] = useState([
    { number: totalShareApplicationMoney, name: "Share App Money" },
    {
      number:
        parseInt(totalShareApplicationMoney / issuableShare?.pricePerShare) <
        issuableShare?.amount
          ? parseInt(
              totalShareApplicationMoney / issuableShare?.pricePerShare
            ) || 0
          : issuableShare?.amount || 0,
      name: "Issuable Shares",
    },
  ]);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  // useEffect(() => {
  //   setData([
  //     { number: totalShareApplicationMoney, name: "Share App Money" },
  //     { number: issuableShare, name: "Issuable Shares" },
  //   ]);
  // }, [params, totalShareApplicationMoney, pricePerShare, issuableShare]);

  useEffect(() => {
    setData([
      { number: totalShareApplicationMoney, name: "Share App Money" },
      {
        number:
          parseInt(totalShareApplicationMoney / issuableShare?.pricePerShare) <
          issuableShare?.amount
            ? parseInt(
                totalShareApplicationMoney / issuableShare?.pricePerShare
              ) || 0
            : issuableShare?.amount || 0,
        name: "Issuable Shares",
      },
    ]);
  }, [params, totalShareApplicationMoney, issuableShare]);

  const [shareTypes, setShareTypes] = useState([]);
  /// fetch config data
  const getConfigData = useSelector((state) => {
    // console.log("getConfigData", state.configuration);
    return state.configuration.shareType;
  });

  useEffect(() => {
    // console.log("getConfigData", getConfigData);
    setShareTypes(
      getConfigData?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData, params.id]);

  const getAllsharesDetails = async (val) => {
    try {
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/shareCapital/sharesAcc",
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // "http://localhost:8080" + "/api/v1/shareCapital/sharesAcc",

      // console.log(response.data);
      setAllShareDetail(response?.data);
      // dispatch(loaderEndActionCreater());
    } catch (e) {
      // dispatch(openSnackbar(`${e.response.data.error}`, "error"));
      dispatch(loaderEndActionCreater());
      if (e?.response?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    getAllsharesDetails();
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);

  const allowOnlyPositiveNumber = (e) => {
    if (window.event) {
      var charCode = window.event.keyCode;
    } else if (e) {
      var charCode = e.which;
    } else {
      return true;
    }
    if (charCode >= 48 && charCode <= 57) return true;
    else {
      alert("Please enter only positive Integer Number");
      e.preventDefault();
      return false;
    }
  };

  const addIssueShare = async (val) => {
    try {
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/issueShares",
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // let response = await axios.post(
      //   "http://localhost:8080" + "/api/v1/member/" + `${type}`,
      //   val
      // );
      dispatch(getShareForMemberWithId(params.id, getAuthData));
      dispatch(getPieChartDataActionCreater(getAuthData));
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      getAllsharesDetails(getAuthData);
      dispatch(
        openSnackbar(`Add Issue ${shareType} Share Successfully`, "success")
      );
      // console.log(response.data);
      dispatch(loaderEndActionCreater());
      setUpdate(true);
      dispatch(selectedMemberRows(undefined, []));
      if (setConflict2 || !setConflict2) setConflict2(true);
      if (setIsAction) setIsAction(true);
      // history.push("admin/members");
      handleClose();
    } catch (e) {
      // console.log(e.response);
      // console.log(e.request);
      dispatch(loaderEndActionCreater());
      if (e?.response?.data?.error)
        dispatch(openSnackbar(`${e?.response?.data?.error}`, "error"));
      if (e?.response?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const addIssueBulkShare = async (val) => {
    try {
      // console.log("In bulk issue share:", val);
      ////checktoken

      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      // let response = await axios.post(
      //   process.env.REACT_APP_BASE_URL + "/api/v1/member/issueBulkShares",
      //   val,
      //   {
      //     headers: {
      //       Authorization: `Bearer ` + `${token}`,
      //     },
      //   }
      // );

      // let response = await axios.post(
      //   "http://localhost:8080" + "/api/v1/member/" + `${type}`,
      //   val
      // );
      // dispatch(getShareForMemberWithId(params.id,getAuthData));
      dispatch(getPieChartDataActionCreater(getAuthData));
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      getAllsharesDetails(getAuthData);
      dispatch(
        openSnackbar(
          `Add Bulk Issue ${shareType} Share Successfully`,
          "success"
        )
      );
      dispatch(loaderEndActionCreater());
      dispatch(selectedMemberRows(undefined, []));
      setUpdate(true);
      if (setConflict2 || !setConflict2) setConflict2(true);
      if (setIsAction) setIsAction(true);
      handleClose();
    } catch (e) {
      // console.log(e);
      dispatch(selectedMemberRows(undefined, []));
      history.push("/admin/members");

      // if (setIsAction) setIsAction(true);
      dispatch(loaderEndActionCreater());
      // if (e?.response?.data?.error)
      //   dispatch(openSnackbar(`${e?.response?.data?.error}`, "error"));
      if (e?.response?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const getTableDetails = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/shareCapital",
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log(response?.data?.data);
      // let newData = response?.data?.data?.reduce((ans, ele) => {
      //   return {
      //     ...ans,
      //     [`${ele.typesOfShare}`]:
      //       ans[`${ele.typesOfShare}`]?.length > 0
      //         ? [...ans[`${ele.typesOfShare}`], ele.shareCapitalId]
      //         : [ele.shareCapitalId],
      //   };
      // }, {});

      let newData = response?.data?.data?.reduce((ans, ele) => {
        return {
          ...ans,
          [`${ele.typesOfShare}`]:
            ans[`${ele.typesOfShare}`]?.length > 0
              ? [
                  ...ans[`${ele.typesOfShare}`],
                  {
                    shareCapitalId: ele.shareCapitalId,
                    balanceShare: ele.remainingShare,
                    pricePerShare: ele.pricePerShare,
                    id: ele._id,
                    typeOfShare: ele.typesOfShare,
                  },
                ]
              : [
                  {
                    shareCapitalId: ele.shareCapitalId,
                    balanceShare: ele.remainingShare,
                    pricePerShare: ele.pricePerShare,
                    id: ele._id,
                    typeOfShare: ele.typesOfShare,
                  },
                ],
        };
      }, {});
      // console.log(newData);
      setShareSeriesIds(newData);
    } catch (e) {
      // console.log(e);
      // dispatch(openSnackbar("Shares Capital fetch failed", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };
  // console.log(shareSeriesIds);

  useEffect(() => {
    if (getAuthData) getTableDetails();
  }, [getAuthData]);

  // console.log(props.open);
  const handleClose = () => {
    setShareType("");
    setShareSeriesId("");
    setMinValue("");
    setMaxValue("");
    setOpen(false);
    dispatch(selectedMemberRows(undefined, []));
    // setConflict(true);
    // setUpdate(true);
    if (setConflict2 || !setConflict2) setConflict2(true);
    if (setIsAction) setIsAction(true);

    // history.push("/admin/members");
  };

  // handle select share Type
  const handleSelect = (event) => {
    setShareType(event.target.value);
    setShareSeriesId("");
  };
  // handle select series Id
  const handleSelectShareSeriesId = (event) => {
    setShareSeriesId(event.target.value);
  };

  const handleSelectMin = (event) => {
    setMinValue(event.target.value);
  };

  const handleSelectMax = (event) => {
    setMaxValue(event.target.value);
  };

  const handleChange = (e) => {
    // console.log(e.target.value);
    if (e.target.value < 0) {
      e.preventDefault();
      e.target.value = 0;
      alert("Please Enter Only Positive Number");
      return;
    }
    setInputVal(e.target.value);
  };

  const handleSubmit = () => {
    if ((shareType === "" || inputVal <= 0) && !memberIds) {
      if (shareType === "") {
        dispatch(openSnackbar("Please Select Type of Share", "error"));
      } else {
        dispatch(openSnackbar("Please Enter Value Greater than 0", "error"));
      }
      return;
    }

    const newCheck =
      issuableShare?.amount <=
      parseInt(totalShareApplicationMoney / issuableShare?.pricePerShare)
        ? issuableShare?.amount
        : parseInt(totalShareApplicationMoney / issuableShare?.pricePerShare);

    // if (newCheck >= parseInt(inputVal) || memberIds?.length) {
    //   if (newCheck >= parseInt(inputVal)) handleClose();
    // } else {

    if (!memberIds?.length) {
      if (
        parseInt(inputVal) * issuableShare?.pricePerShare >
          parseInt(totalShareApplicationMoney) ||
        parseInt(issuableShare?.amount) < parseInt(inputVal)
      ) {
        dispatch(openSnackbar("Insufficient Shares in Sayas Account", "error"));
        return;
      }
      // else if (
      //   issuableShare?.amount <
      //   parseInt(totalShareApplicationMoney / issuableShare?.pricePerShare)
      // ) {
      //   // dispatch(openSnackbar("Insufficient Money", "error"));
      //   dispatch(openSnackbar("Insufficient Funds", "error"));
      //   return;
      // }
    }

    let newData = {};

    let finalList = [];
    console.log("memberIDs:", memberIds);

    if (memberIds) {
      finalList = memberIds.filter((ele) => {
        let member = tableData.find((e) => {
          return e.id === ele;
        });
        return member?.activityStatus === true;
      });
    }
    console.log("finalList:", finalList);

    if (memberIds) {
      if (finalList.length <= 0) {
        dispatch(openSnackbar("Member is Inactive", "error"));
        return;
      }
    }
    // console.log("finalList", finalList, memberIds);

    // removing the member which have low shareApplication money
    // if (memberIds) {
    //   finalList = finalList.filter((ele) => {
    //     let member = tableData.find((e) => {
    //       return e.id === ele;
    //     });
    //     return (
    //       member?.shareApplicationMoney >=
    //       parseInt(inputVal) * parseInt(pricePerShare)
    //     );
    //   });
    // }

    // console.log("finalList", finalList, memberIds, tableData);

    // checkout if list is emplty removing the member which have low shareApplication money

    // if (memberIds) {
    //   if (finalList.length <= 0) {
    //     dispatch(
    //       openSnackbar(
    //         "Bulk Share Issue failed due to insufficient share application money",
    //         "error"
    //       )
    //     );
    //     return;
    //   }
    // }

    dispatch(loaderStartActionCreater());

    if (memberIds?.length > 0) {
      if (shareType === "") {
        // console.log("Please Select Type of Share");
        dispatch(openSnackbar("Please Select Type of Share", "error"));
        return;
      } else if (shareSeriesId === "") {
        // console.log("Please Select Series of Share Type");
        dispatch(openSnackbar("Please Select Series of Share Type", "error"));
        return;
      } else if (minValue === "") {
        dispatch(openSnackbar("Please Enter Minimum Shares", "error"));
        return;
      } else if (maxValue === "") {
        dispatch(openSnackbar("Please Enter Maximum Shares", "error"));
        return;
      } else if (minValue <= 0) {
        dispatch(
          openSnackbar("Please Enter Minimum Shares Greater then 0", "error")
        );
        return;
      } else if (parseInt(minValue) > parseInt(maxValue)) {
        dispatch(
          openSnackbar("Please enter Max Value Greater then Min Value", "error")
        );
        return;
      } else {
        dispatch(openSnackbar("Please Confirm your Data", "success"));
      }

      newData = {
        memberIds: finalList,
        amount: parseInt(inputVal), //total share issue
        typeOfShare: shareType,
        min: parseInt(minValue),
        max: parseInt(maxValue),
        seriesId: shareSeriesId,
        pricePerShare: parseInt(pricePerShare),
      };

      // console.log("In Submit!!", newData);
      setOpenConfirm(true);
      // addIssueBulkShare(newData);
    } else {
      handleClose();

      newData = {
        memberId: params.id,
        amount: parseInt(inputVal),
        typeOfShare: shareType,
        pricePerShare: parseInt(pricePerShare),
      };
      addIssueShare(newData);
    }

    // console.log(newData.typeOfShare, newData);
  };

  // console.log("allShareDetail", allShareDetail);

  useEffect(() => {
    setIssuableShare(allShareDetail[`${shareType}`]);
  }, [shareType]);

  return (
    <div>
      <Dialog
        open={open}
        // open={true}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
          }}
        >
          <label
            style={{ width: "24vw" }}
            sx={{ fontFamily: "Mulish", fontWeight: "bold" }}
            id={styles.lab}
          >
            Issue Shares
          </label>
          {/* <CloseIcon
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          /> */}
        </DialogTitle>
        <DialogContent>
          {memberIds || memberIds?.length > 0 ? (
            ""
          ) : (
            <DialogContentText>
              <hr className="m-0" />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                {data.map((item, i) => (
                  <React.Fragment key={i}>
                    <MemberShareCards item={item} i={i} />
                  </React.Fragment>
                ))}
              </div>
            </DialogContentText>
          )}
          <DialogContentText className="mt-4">
            <FormControl
              variant="standard"
              className="w-100"
              fullWidth
              sx={{ m: 1, minWidth: 120 }}
              style={{ fontFamily: "Mulish", textTransform: "capitalize" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Share Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={shareType}
                onChange={handleSelect}
                label="Share Type"
                fullWidth
              >
                {shareTypes?.map((e, i) => {
                  return <MenuItem value={e}>{e}</MenuItem>;
                })}
              </Select>
              {memberIds || memberIds?.length > 0 ? (
                <>
                  <FormControl
                    variant="standard"
                    className="w-100"
                    fullWidth
                    // sx={{ mt: 1, minWidth: 120 }}
                    style={{
                      fontFamily: "Mulish",
                      textTransform: "capitalize",
                    }}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Series Id
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select"
                      value={shareSeriesId}
                      onChange={handleSelectShareSeriesId}
                      label="Series ID"
                      fullWidth
                    >
                      {shareSeriesIds[`${shareType}`]
                        ?.map((ele) => {
                          return ele.shareCapitalId;
                        })
                        ?.map((e, i) => {
                          return <MenuItem value={e}>{e}</MenuItem>;
                        })}
                    </Select>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <TextField
                        variant="standard"
                        label="Minimum Shares"
                        type="number"
                        style={{
                          fontFamily: "Mulish",
                          textTransform: "capitalize",
                          width: "48%",
                        }}
                        // className="w-48"
                        value={minValue}
                        onKeyPress={allowOnlyPositiveNumber}
                        onChange={handleSelectMin}
                      />
                      <TextField
                        variant="standard"
                        label="Maximum Shares"
                        type="number"
                        style={{
                          fontFamily: "Mulish",
                          textTransform: "capitalize",
                          width: "48%",
                        }}
                        // className="w-48"
                        value={maxValue}
                        onKeyPress={allowOnlyPositiveNumber}
                        onChange={handleSelectMax}
                      />
                    </div>
                  </FormControl>
                </>
              ) : (
                <TextField
                  variant="standard"
                  label="Issue Shares"
                  type="number"
                  style={{ fontFamily: "Mulish", textTransform: "capitalize" }}
                  className="w-100"
                  onKeyPress={allowOnlyPositiveNumber}
                  onChange={handleChange}
                />
              )}
              {/* <TextField
                variant="standard"
                label="Issue Shares"
                type="number"
                style={{ fontFamily: "Mulish", textTransform: "capitalize" }}
                className="w-100"
                onKeyPress={allowOnlyPositiveNumber}
                onChange={handleChange}
              /> */}
            </FormControl>
          </DialogContentText>
          <DialogContentText
            align="center"
            className="mt-4"
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button
              onClick={handleSubmit}
              id={styles.issueBtn}
              sx={{
                // mr: "2rem",
                fontSize: "15px",
                fontWeight: "bold",
                bgcolor: "#0096FF",
              }}
            >
              Issue
            </Button>
            <Button
              onClick={handleClose}
              varient="text"
              sx={{
                // color: "black",
                mr: "1rem",
              }}
            >
              Cancel
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {openConfirm ? (
        <BulkIssueShareConfirmationModel
          openConfirm={openConfirm}
          setOpenConfirm={setOpenConfirm}
          tableData={tableData}
          memberIds={memberIds}
          shareType={shareType}
          shareSeriesId={shareSeriesId}
          shareSeriesIds={shareSeriesIds}
          minValue={minValue}
          maxValue={maxValue}
          getAllsharesDetails={getAllsharesDetails}
          setUpdate={setUpdate}
          setConflict2={setConflict2}
          handleClose2={handleClose}
          // setIsAction={setIsAction}
        />
      ) : (
        ""
      )}
    </div>
  );
}
