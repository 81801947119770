import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import Button from "@restart/ui/esm/Button";
import { Link, useRouteMatch } from "react-router-dom";
import MenuDropdown from "./../../Components/MenuItems/MenuDropdown";
import { useDispatch, useSelector } from "react-redux";
import { selectedMemberRows } from "../../Redux/Members/actionCreator";
import styles from "./member.module.css";
import SelectColumns from "./../../Components/SelectDropdown/SelectColumns";
import MaterialSelect from "./../../Components/SelectDropdown/MaterialSelect";

export const MemberHeader = (props) => {
  const dispatch = useDispatch();

  const {
    option_users,
    option_action_1,
    option_action_2,
    // options,
    // setOptions,
    handleActivateStatus,
    filterUsers,
    optionVal,
    setData,
    setConflict,
    data,
    finalData,
    columnsStatus,
    memberDataLength,
    tableRef,
    setIsAction,
  } = props;

  const selectedUsers = useSelector((state) => state.members.selectedRow);
  const [options, setOptions] = useState(option_action_1);

  // console.log("selectedUsers", selectedUsers);
  const handleTabValue = (e) => {
    filterUsers(e.target.value);
    // setTabValue(e.target.value);
  };

  useEffect(() => {
    if (selectedUsers?.length !== 0) {
      setOptions(option_action_2);
    } else {
      setOptions(option_action_1);
    }
  }, [selectedUsers, option_action_1, option_action_2]);

  const { path } = useRouteMatch();

  useEffect(() => {
              dispatch(selectedMemberRows(undefined, []));
              // dispatch(selectedMemberRows(null));
  }, []);

  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ margin: "20px 30px" }}
      >
        <div className="w-75">
          <MaterialSelect
            optionVal={optionVal}
            option_users={option_users}
            filterUsers={filterUsers}
          />
        </div>
        <div className="d-flex align-items-center justify-content-around gap-3">
          <Link to={`${path}/add-member`}>
            <button id={styles.headerBtn}>Add Member</button>
          </Link>
          {options?.length > 0 ? (
            <MenuDropdown
              tableRef={tableRef}
              options={options}
              finalData={finalData}
              handleActivateStatus={handleActivateStatus}
              setOptions={setOptions}
              setData={setData}
              data={data}
              setConflict={setConflict}
              memberDataLength={memberDataLength}
              columnsStatus={columnsStatus}
              selectedUsers={selectedUsers}
              module={"member"}
              setIsAction={setIsAction}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
