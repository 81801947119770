import React, { useState, useEffect } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import { Link, useRouteMatch } from "react-router-dom";
import "../Users/userHeader.css";
import MaterialSelect from "./../../Components/SelectDropdown/MaterialSelect";
import MenuDropdown from "./../../Components/MenuItems/MenuDropdown";
import styles from "./DataAcq.module.css";
import CsvDownload from "react-json-to-csv";
import ExportTable from "./../../Components/Export/ExportTable";
import axios from "axios";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { elementTypeAcceptingRef } from "@mui/utils";
import moment from "moment";
import exportFromJSON from "export-from-json";
const fileName = "download";
const exportType = exportFromJSON.types.csv;

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

const DataAccquisitionHeader = (props) => {
  const {
    data,
    setData,
    option_users,
    options,
    optionVal,
    filterUsers,
    columnsStatus,
    setColumnsStatus,
    selectedUsers,
    setConflict,
    tableRef,
  } = props;
  const [tabValue, setTabValue] = useState("Application");
  const [back, setBack] = useState("");
  const [exportData, setExportData] = React.useState([]);
  const [selectValue, setSelectValue] = useState(10);
  const [activeDataAcq, setActiveDataAcq] = useState(null);
  const dispatch = useDispatch();

  // add permission
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  const handleTabValue = (e) => {
    setTabValue(e.target.value);
    // console.log(e.target.value);
  };

  // console.log(data);

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const backColor = (e) => {
    if (e == 0) {
      // setBack();
      return "#F8EE00";
    } else if (e == 1) {
      // setBack();
      return "#33F3FF";
    } else {
      // setBack("#C3FE47");
      return "#C3FE47";
    }
  };

  const getTableDetails = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/dataAcquisition/data/dashboard`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(response.data);

      let dummy = {
        applications: response?.data?.applications?.filter(
          (e) =>
            new Date(e?.endDate) >= new Date() &&
            new Date(e?.startDate) <= new Date() &&
            e?.standing?.toLowerCase() === "launch"
        ).length,
        surveys: response?.data?.surveys?.filter(
          (e) =>
            new Date(e?.endDate) >= new Date() &&
            new Date(e?.startDate) <= new Date() &&
            e?.standing?.toLowerCase() === "launch"
        ).length,
        polls: response?.data?.polls?.filter(
          (e) =>
            new Date(e?.endDate) >= new Date() &&
            new Date(e?.startDate) <= new Date() &&
            e?.standing?.toLowerCase() === "launch"
        ).length,
      };

      // Object.keys(response?.data).map((ele) => {
      //   let total = 0;
      //   response?.data[`${ele}`].map((e) => {
      //     // console.log(e);
      //     if (
      //       new Date(e?.endDate) < new Date() ||
      //       new Date(e?.startDate) > new Date() ||
      //       e?.standing !== "launch"
      //     ) {
      //     } else total++;
      //   });
      //   dummy = { ...dummy, [`${ele}`]: total };
      //   total = 0;
      // });

      // console.log("dummy", dummy);

      let total = {
        applications: response.data.applications.length,
        surveys: response.data.surveys.length,
        polls: response.data.polls.length,
      };

      setActiveDataAcq({ total, active: dummy });

      // dispatch(loaderEndActionCreater());
      // console.log("Res", response.data.data);
    } catch (err) {
      // console.log(err);
      let errors = err?.response?.data?.error;
      // dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };
  useEffect(() => {
    if (getAuthData) {
      getTableDetails();
    }
    if (data && activeDataAcq) {
      dispatch(loaderEndActionCreater());
    }
  }, [data, getAuthData]);

  // console.log(activeDataAcq);
  const handleActivateStatus = async (status) => {};

  const cardData = [
    {
      goal: "Application",
      val: activeDataAcq?.active?.applications || 0,
      number: activeDataAcq?.total?.applications || 0,
    },
    {
      goal: "Survey",
      val: activeDataAcq?.active?.surveys || 0,
      number: activeDataAcq?.total?.surveys || 0,
    },
    {
      goal: "Poll",
      val: activeDataAcq?.active?.polls || 0,
      number: activeDataAcq?.total?.polls || 0,
    },
  ];

  useEffect(() => {
    // var colsKeys = Object.keys(columnsStatus);
    // const mappedArrayData = data?.map((e) => {
    //   let data = colsKeys.map((ele) => {
    //     if (columnsStatus[ele] === true) {
    //       return e[ele];
    //     }
    //   });
    //   let finalAns = data.filter((e) => {
    //     return e != undefined;
    //   });
    //   return finalAns;
    // });
    // // console.log(mappedArrayData);

    // mappedArrayData?.unshift(
    //   colsKeys.filter((e) => {
    //     return columnsStatus[e] === true;
    //   })
    // );
    // // let cleanData = mappedArrayData?.map((item) =>
    // //   item.map((e, i) => {
    // //     return e == true ? "True" : e == false ? "False" : e;
    // //   })
    // // );
    // console.log("header Data!! : ", data);

    setExportData(
      data.map((ele) => {
        // console.log(ele);
        return {
          ID: ele.dataAcquisitionId,
          Title: ele.title,
          Type: ele.type,
          Open: moment(ele.startDate?.slice(0, -1)).format("DD/MM/yyyy"),
          Close: moment(ele.endDate?.slice(0, -1)).format("DD/MM/yyyy"),
          Response: ele.members.length > 0 ? ele.members.length : "0",
          Status: `${
            ele.standing
              ? ele.standing === "save" ||
                (new Date(ele.startDate) > new Date() &&
                  ele.standing !== "closed" &&
                  ele.standing !== "cancelled")
                ? "Draft"
                : ele.standing === "cancelled"
                ? "Cancelled"
                : new Date(ele.endDate) >= new Date() &&
                  new Date(ele.startDate) <= new Date() &&
                  ele?.standing?.toLowerCase() === "launch"
                ? "Open"
                : "Closed"
              : "..."
          }`,
        };
      })
    );
  }, [data]);

  const { path } = useRouteMatch();
  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center gap-3"
        style={{ margin: "20px 30px" }}
      >
        <MaterialSelect
          optionVal={optionVal}
          option_users={option_users}
          filterUsers={filterUsers}
        />

        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "52%", gap: "20px" }}
        >
          {cardData.map((e, i) => {
            return (
              <Card
                // sx={{  }}
                style={{
                  width: "144px",
                  // height: "110px",
                  // display: "flex",
                  backgroundColor: "#F6F6F6",
                  borderRadius: "10px",
                  boxShadow: "none",
                  gap: 20,
                }}
              >
                <CardContent
                  style={{
                    margin: "0",
                    padding: "0",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label className={styles.headerCardLabel}>{e.goal}</label>
                  <div align="center" className={styles.valueStatus}>
                    <label className={styles.headerCardValue}>{e.val}</label>
                    <label className={styles.headerCardStatus}>Active</label>
                  </div>
                  <div
                    style={{
                      backgroundColor: "#0096FF",
                      width: "100%",
                      height: "100%",
                      // paddingTop: "6px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "14px",
                        marginLeft: "10px",
                        height: "14px",
                        borderRadius: "50%",
                        backgroundColor: backColor(i),
                      }}
                    ></div>
                    <div>
                      <label
                        className={styles.headerCardTotal}
                        style={{ color: "#ffffff" }}
                      >
                        Total: {e.number}
                      </label>
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          })}
        </div>

        <div
          className="d-flex align-items-center justify-content-around"
          style={{ gap: "20px" }}
        >
          {/* <Link to={`${path}/add-form`}>
            <button id={styles.submitBtn}>Create New</button>
          </Link> */}

          {userRole.toLowerCase() !== "staff" ? (
            <>
              <MenuDropdown
                options={["Application", "Survey", "Poll"]}
                label={"create"}
              />

              <MenuDropdown
                options={options}
                // setAllOptions={setAllOptions}
                handleActivateStatus={handleActivateStatus}
                // setData={setData}
                // data={data}
                // dataLength={dataLength}
                setConflict={setConflict}
                selectedUsers={selectedUsers}
                columnsStatus={columnsStatus}
              />
            </>
          ) : (
            ""
          )}
          {/* <ExportTable data={exportData} /> */}
          <Grid
            sx={{
              background: "#0096FF!important",
              borderRadius: "5px",
            }}
          >
            <div
              className="exportBtn"
              style={{
                background: "#0096FF!important",
              }}
              onClick={() => {
                exportFromJSON({
                  data: tableRef.current.state.data,
                  fileName,
                  exportType,
                });
              }}
            >
              <i
                className="fa fa-download"
                style={{
                  color: "#ffffff",
                  fontSize: "22px",
                  background: "#0096FF!important",
                }}
              ></i>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DataAccquisitionHeader;
