import actions from "./configurationActions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { LogoutSayasActionCreate } from "../Authontication/authonticationActionCreator";
const {
  getConfigurationAction,
  addConfigurationAction,
  updateConfigurationAction,
  deleteConfigurationAction,
} = actions;


const getConfigData = async (dispatch,token) => {
  try {
    ////checktoken
    let token = window.localStorage.getItem("sayasLoginToken");
    // let token = getAuthData;
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/v1/configuration",
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );

    // console.log("configuration data", response.data)

    return response?.data;
  } catch (e) {
    if (e.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

export const getConfiguationActionCreater = (token) => {
  return async (dispatch) => {
    let configData = await getConfigData(dispatch,token);
    // console.log("getConfiguationActionCreater", configData?.data[0]);
    try {
      // console.log("1. getConfiguationActionCreater",  data);
      dispatch(getConfigurationAction(configData?.data[0]));
    } catch (err) {
      console.log(err.response);
    }
  };
};
export const addConfiguationActionCreater = (data) => {
  return async (dispatch) => {
    try {
      // console.log("1. addConfiguationActionCreater", data);
      dispatch(addConfigurationAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};
export const updateConfiguationActionCreater = (id, data) => {
  return async (dispatch) => {
    try {
      // console.log("1. updateConfiguationActionCreater", id, data);
      dispatch(updateConfigurationAction(id, data));
    } catch (err) {
      console.log(err.response);
    }
  };
};
export const deleteConfiguationActionCreater = (id, data) => {
  return async (dispatch) => {
    try {
      // console.log("1. deleteConfiguationActionCreater", id, data);
      dispatch(deleteConfigurationAction(id, data));
    } catch (err) {
      console.log(err.response);
    }
  };
};
