import axios from "axios";

export const handleUpload = (files) => {
  return handleImage2(files[0]);
};

const generalFileUpload = async (formData) => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/v1/s3/upload",
      formData
    );
    // console.log(data);
    return data;
  } catch (error) {
    if (error && error.response && error.response.data)
      return console.log(error.response.data);
    console.log(error.message);
  }
};

const reader = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
};

const handleImage2 = async (file) => {
  try {
    const data = await reader(file);
    const values = {
      name: file.name,
      type: file.type,
      encoding: "base64",
      imageData: data,
    };
    const { url } = await generalFileUpload(values);
    // alert(url)
    // console.log(url);
    return url;
  } catch (error) {
    console.log(error?.message);
  }
};
