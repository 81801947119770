import { setISODay } from "date-fns/esm";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { selectedDataAcqRows } from "../../Redux/DataAcquisition/actionCreator";
import moment from "moment";
import { loaderStartActionCreater } from "../../Redux/Loader/LoaderActionCreator";

const BulletItems = (props) => {
  const { data, selectedData, setSelectedData, setId, id } = props;

  const dispatch = useDispatch();
  const date = data?.startDate?.split("T");

  return (
    <div>
      <div
        className="d-flex justify-content-start align-items-center pt-3 pb-3"
        style={{ background: id == data.id ? "rgba(0, 146, 232, 0.08)" : "" }}
      >
        <div style={{ margin: "0 20px" }}>
          <input
            type="radio"
            checked={data.id == id}
            style={{ width: "25px", height: "25px" }}
          />
        </div>
        <div
          style={{ cursor: "pointer", width: "100%" }}
          onClick={() => {
            // dispatch(loaderStartActionCreater());
            dispatch(selectedDataAcqRows(data));
            setId(data.id);
            setSelectedData(data);
            if (selectedData.id !== data.id)
              dispatch(loaderStartActionCreater());
            // console.log(data);
          }}
        >
          <h5 style={{ fontSize: 18 }}>{`${data.title}`}</h5>
          <p style={{ marginBottom: 0 }}>
            Created - {date ? moment(date[0]).format("DD/MM/YYYY") : null}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BulletItems;
