import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./DataAcq.module.css";

export default function DataAcqSelectField(props) {
  const {
    option_users,
    filterUsers,
    optionVal,
    setOptionVal,
    isUpdate,
    userRole,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (item) => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    setOptionVal(item);
    filterUsers(item);
  };

  return (
    <div style={{ width: "100%" }}>
      <Button
        id={styles.selBtn}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={
          isUpdate === true && userRole.toLowerCase() === "staff" ? true : false
        }
        className="m-0 w-100"
      >
        {optionVal}
        {open ? (
          <i
            className="fa fa-caret-up"
            style={{ fontSize: 30, color: "#c4c4c4" }}
          />
        ) : (
          <i
            className="fa fa-caret-down"
            style={{ fontSize: 30, color: "#c4c4c4" }}
          />
        )}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {option_users.map((item) => (
          <MenuItem
            key={item}
            id={styles.selectBtn}
            onClick={() => {
              handleClose();
              handleSelect(item);
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
