import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router";
import DataTable from "../../Components/Table";
import { useDispatch } from "react-redux";
import { userActionCreator } from "../../Redux/Users/actionCreator";
import Loader from "../../Components/Loader/Loader";

const UsersTable = (props) => {
  const { data, columnsStatus, columns, setId,tableRef } = props;

  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const options = {
    search: true,
    selection: true,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
      padding: "8px 5px",
      whiteSpace: "nowrap",
    },
    cellStyle: {
      padding: "10px",
    },
    

    maxBodyHeight: "59vh",
    pageSize: 10,
    searchFieldStyle: {
      marginRight: "6vw",
      width: "15vw",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
    
  };

  return (
    <>
      {/* {data.length === 0 ? (
        <Loader />
      ) : ( */}
      <div>
        <DataTable
        tableRef={tableRef}
        title="" 
          columns={columns.filter((e) => {
            return columnsStatus[e.field];
          })}
          data={data}
          options={options}
          onRowClick={(event, rowData) => {
            setId(rowData._id);
            dispatch(userActionCreator(rowData));
            history.push(`${path}/user-info/${rowData._id}`);
          }}
        />
      </div>
      {/* )} */}
    </>
  );
};

export default UsersTable;
