import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./member.module.css";
import MemberShareCards from "./MemberShareCards";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { useEffect, useState } from "react";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";

import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import CloseIcon from "@mui/icons-material/Close";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { useHistory } from "react-router-dom";
export default function UpdateMemberType(props) {
  const { open, setOpen, memberIds, setConflict, selectedUsers, selectedData,setIsAction } =
    props;
  const [memberType, setMemberType] = useState("");
  const dispatch = useDispatch();
  const [memberTypes, setMemberTypes] = useState([]);
  const history = useHistory();

  /// fetch config data
 //auth token fetch from selector
 const getAuthData = useSelector((state) => {
  // console.log(state.auth);
  return state.auth.loginToken;
});
  // console.log("memberIds", memberIds, selectedData);

  const getConfigData = useSelector((state) => {
    return state.configuration.memberType;
  });

  // console.log("getConfigData", getConfigData);
  useEffect(() => {
    setMemberTypes(
      getConfigData?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  const updateBulkMemberType = async (type, val) => {
    try {
      //   console.log(val, type);

      let newData = {
        memberIds: val,
      };

      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/member/membertype/" +
          `${type}`,
        newData,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // let response = await axios.post(
      //   "http://localhost:8080" + "/api/v1/member/" + `${type}`,
      //   val
      // );
      history.push("/admin/members");
      dispatch(
        openSnackbar(`Selected members updated successfully`, "success")
      );
      // console.log(response.data);
      handleClose();
      if (setIsAction) setIsAction(false);
      //   history.push("/admin/members");
      dispatch(loaderEndActionCreater());
    } catch (e) {
      // console.log(e);
      dispatch(loaderEndActionCreater());
      if (e?.response?.data?.error)
        dispatch(openSnackbar(`Selected members updatation Failed`, "error"));
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const handleSelect = (event) => {
    setMemberType(event.target.value);
  };

  const handleClose = () => {
    setMemberType("");
    setOpen(false);
    if (setConflict) setConflict(true);
    if (setIsAction) setIsAction(false);
  };

  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);

  const handleSubmit = () => {
    if (memberType === "") {
      dispatch(openSnackbar(`Please Select Member type`, "error"));
      return;
    }
    handleClose();
    dispatch(loaderStartActionCreater());

    // console.log(memberType, memberIds);

    updateBulkMemberType(memberType, memberIds);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
          }}
        >
          <label
            style={{ width: "24vw" }}
            sx={{ fontFamily: "Mulish", fontWeight: "bold" }}
            id={styles.lab}
          >
            Update Member Type
          </label>
          <CloseIcon
            onClick={handleClose}
            style={{
              cursor: "pointer",
            }}
          />
        </DialogTitle>
        <DialogContent>
          {/* {memberIds?.length > 0 ? (
            ""
          ) : (
            <DialogContentText>
              <hr className="m-0" />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              ></div>
            </DialogContentText>
          )} */}
          <DialogContentText className="mt-4">
            <FormControl
              variant="standard"
              className="w-100"
              fullWidth
              sx={{ m: 1, minWidth: 120 }}
              style={{ fontFamily: "Mulish", textTransform: "capitalize" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Member Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={memberType}
                onChange={handleSelect}
                label="Member Type"
                fullWidth
              >
                {memberTypes?.map((e, i) => {
                  return <MenuItem value={e}>{e}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </DialogContentText>
          <DialogContentText
            align="center"
            className="mt-4"
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
            }}
          >
            <Button onClick={handleSubmit} id={styles.issueBtn} >
              Update
            </Button>
            <Button
              onClick={handleClose}
              varient="text"
              sx={{
                color: "black",
                mr: "1rem",
              }}
            >
              Cancel
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
