import React, { useState, useEffect } from "react";
import BulletItems from "./../../Components/ListItems/BulletItem";
import MaterialSelect from "./../../Components/SelectDropdown/MaterialSelect";
import Loader from "./../../Components/Loader/Loader";
import { BASE_URL } from "./../../Components/URL/Url";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ApplicationInfo from "./ApplicationInfo";
import DataAcquisitionTable from "./DataAcquisitionTable";
import { selectedDataAcqRows } from "../../Redux/DataAcquisition/actionCreator";
import styles from "./DataAcq.module.css";
import { memberDataActionCreater } from "../../Redux/Members/actionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import moment from "moment";
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
const DataAcquisitionInfo = (props) => {
  const { setDataAcqLength, dataAcqLength, setId, id } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [AllData, setAllData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [optionVal, setOptionVal] = useState("All");
  const [statusOfID, setStatusOfId] = useState(false);

  // const [id, setId] = useState("");

  // console.log(id, selectedData);

  const option_users = [
    "All",
    "Application",
    "Survey",
    "Poll",
    "Open",
    "Closed",
    "Cancelled",
    "Draft",
  ];
  useEffect(() => {
    setStatusOfId(true);
  }, []);
  // console.log(optionVal, data, id);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  // console.log("id", id === "", id);
  // console.log("selectedData", selectedData);

  const filterUsers = async (name) => {
    // console.log("name", name);
    // console.log("optionVal", optionVal);
    dispatch(loaderStartActionCreater());
    setOptionVal(name);
    if (name == "All") {
      setId(id === "" || id == undefined ? AllData[0]?.id : id);
      setData(AllData);
    } else if (name == "Open" || name == "Closed") {
      const filteredData = AllData.filter((item) => {
        // console.log(item.isActive);
        return name === "Open"
          ? new Date(item.endDate) > new Date() &&
              new Date(item.startDate) < new Date() &&
              item?.standing === "launch"
          : (new Date(item.endDate) < new Date() ||
              new Date(item.startDate) > new Date() ||
              item?.standing === "closed") &&
              item.standing !== "cancelled" &&
              item.standing !== "save" &&
              new Date(item.startDate) <= new Date();
      });
      setData(filteredData);

      if (
        !filteredData.find((ele) => {
          return ele.id === selectedData?.id;
        })
      ) {
        setId(filteredData[0]?.id);
        setSelectedData(filteredData[0]);
      }
    } else if (name == "Cancelled") {
      const filteredData = AllData.filter((item) => {
        // console.log(item.isActive);
        return item.standing === "cancelled";
      });
      setData(filteredData);
      if (
        !filteredData.find((ele) => {
          return ele.id === selectedData?.id;
        })
      ) {
        setId(filteredData[0]?.id);
        setSelectedData(filteredData[0]);
      }
    } else if (name == "Draft") {
      const filteredData = AllData.filter((item) => {
        // console.log(item.isActive);
        return (
          item.standing === "save" ||
          (new Date(item.startDate) > new Date() && item.standing === "launch")
        );
      });
      setData(filteredData);
      if (
        !filteredData.find((ele) => {
          return ele.id === selectedData?.id;
        })
      ) {
        setId(filteredData[0]?.id);
        setSelectedData(filteredData[0]);
      }
    } else {
      const filteredData = AllData.filter((item) => item.type === name);
      setData(filteredData);
      // console.log(filteredData);
      if (filteredData.length > 0) {
        if (
          !filteredData.find((ele) => {
            return ele.id === selectedData?.id;
          })
        ) {
          setId(filteredData[0]?.id);
          setSelectedData(filteredData[0]);
        }
      } else {
        setId(null);
        setSelectedData(null);
      }
    }
    dispatch(loaderEndActionCreater());
  };

  const getTableDetails = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        BASE_URL + `/api/v1/dataAcquisition/data/dashboard`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log(response.data);
      let newData = [
        ...response.data.applications,
        ...response.data.surveys,
        ...response.data.polls,
      ].sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setData(newData);
      setAllData(newData);
      setSelectedData(newData[0]);

      setDataAcqLength(
        response.data.applications.length +
          response.data.surveys.length +
          response.data.polls.length
      );
      // dispatch(loaderEndActionCreater());
      // console.log("Res", response.data.data);
      // filterUsers("All");
    } catch (err) {
      console.log(err);
      dispatch(loaderEndActionCreater());
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      if (err.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    if (getAuthData) {
      dispatch(loaderStartActionCreater());
      getTableDetails();
      dispatch(memberDataActionCreater(getAuthData));
    }
  }, [getAuthData]);

  useEffect(() => {
    filterUsers(optionVal);
  }, [AllData]);

  useEffect(() => {
    // console.log(data, selectedData);
    if (
      data.find(
        (ele) => ele?.dataAcquisitionId === selectedData?.dataAcquisitionId
      )
    ) {
    } else {
      setSelectedData(data[0]);
    }
  }, [data]);

  const initialColumnStatus = {
    dataAcquisitionId: true,
    type: true,
    startDate: true,
    endDate: true,
    response: true,
    isActive: true,
  };

  const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  useEffect(() => {
    dispatch(loaderStartActionCreater());
  }, []);

  return (
    <div>
      <div
        className="row m-0"
        style={{
          height: "88vh",
        }}
      >
        <div
          className="col-md-3 p-0"
          style={{ borderRight: "1px solid #acacac", height: "100%" }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ padding: "40px 10px" }}
          >
            <div>
              <MaterialSelect
                // handleChange={handleChange}
                optionVal={optionVal}
                option_users={option_users}
                filterUsers={filterUsers}
              />
            </div>
          </div>
          <hr className="m-0" />

          {/* {data.length === 0 ? (
            <Loader />
          ) : ( */}

          <div
            id={styles.scrollBar}
            style={{
              height: "80%",
            }}
          >
            {data.map((item, i) => (
              <div key={i}>
                <BulletItems
                  type={item.type}
                  email={item.email}
                  data={item}
                  index={i}
                  allData={data}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setId={setId}
                  id={id}
                />
                <hr className="m-0" />
              </div>
            ))}
          </div>
        </div>
        <div
          className="col-md-9 p-0"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <ApplicationInfo
            data={data}
            optionVal={optionVal}
            setData={setData}
            dataAcqLength={dataAcqLength}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
            id={id}
            setId={setId}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
          />
        </div>
      </div>
    </div>
  );
};

export default DataAcquisitionInfo;
