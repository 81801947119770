import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GenericForm from "./GenericForm/Form"
import StringUtils from "../Uitls/StringUtils"
const DataAcquisitionForms = (props) => {
  const { dataAcqLength } = props;
  const [optionVal, setOptionVal] = useState("");
  const params = useParams();
  useEffect(() => {
    // console.table({
    //   id:params.id
    // })
    setOptionVal(params.id);
  }, []);

  return (
    <div>
      {<GenericForm type={StringUtils.capitalizeFirstLetter(optionVal)} dataAcqLength={dataAcqLength}/>}
    </div>
  );
};

export default DataAcquisitionForms;
