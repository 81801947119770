import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import _ from "lodash";
import { Switch } from "@mui/material";
import DatePickers from "./DatePickers";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import axios from "axios";
import UUID from "uuid-int";
import { useDispatch, useSelector } from "react-redux";
// import { selectedUserRows } from "../../../Redux/Users/actionCreator";
import MemberInput from "./MemberInput";
// import { selectedMemberRows } from "../../../Redux/Members/actionCreator";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import styles from "../member.module.css";
import userStyles from "../../Users/UserInput.module.css";
import UsersCityField from "../../Users/UsersForm/UsersCityField";
import UserStateField from "./../../Users/UsersForm/UserStateField";
import { BASE_URL } from "./../../../Components/URL/Url";
import pincodeDirectory from "india-pincode-lookup";
import MemberSelectField from "./MemberSelectField";
import { useParams } from "react-router-dom";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../../Redux/Configuration/configurationActionCreator";
import { city } from "./../../../Components/Data/maharashtraCity";
// import statesDistrictsTalukas from "../../../constants/statesDistrictTalukas.json";
import statesDistrictsTalukas from "../../../../src/Components/Data/statesDistrictTalukas.json";
import { selectedMemberRows } from "../../../Redux/Members/actionCreator";
// const id = 0;
const generator = UUID(0);
const uuid = generator.uuid();

const initialValue = {
  mobileSignUpStatus: false,
  activityStatus: false,
  date: Date.now(),
  memberId: "",
  member: "",
  shareholder: "",
  firstName: "",
  middleName: "",
  lastName: "",
  phone: "",
  email: "",
  addressLine_1: "",
  addressLine_2: "",
  city: "",
  state: "",
  district: "",
  taluka: "",
  pincode: "",
  nominee_name: "",
  organisation_name: "",
};

const UpdateMemberForm = (props) => {
  const { id, setId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [date, setDate] = useState("");
  const { formData } = useSelector((state) => state.members);
  const [data, setData] = useState([]);
  const [cityName, setCityName] = useState(null);
  const [initialData, setIntialData] = useState(initialValue);

  const [allStates, setAllStates] = useState();
  const [allTalukas, setAllTalukas] = useState();
  const [allDistricts, setAllDistricts] = useState();

  const states = _.keysIn(statesDistrictsTalukas);

  const params = useParams();
  let errors = {};

  // console.log("formData", formData);

  // useEffect(() => {
  //   setIntialData(formData);
  // }, [formData]);

  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    // console.log("User role", userRole);

    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  const formik = useFormik({
    initialValues: initialData,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log("in submit", values);
      // console.log("Values", { ...values, date: date });
      const data = { ...values, date: date };
      try {
        ////checktoken
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }

        // console.log("in submit", data);

        let response = await axios.put(
          BASE_URL + `/api/v1/member/${params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        // console.log(response.data.data);
        dispatch(selectedMemberRows(undefined, []));

        history.push("/admin/members");
        dispatch(openSnackbar("Member Updated Successfully!", "success"));
      } catch (err) {
        let errors = err?.response?.data;
        // console.log("Error", errors);

        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        } else if (err.response.status === 403) {
          // console.log("in status 403");
          if (errors?.message) dispatch(openSnackbar(errors?.message, "error"));
        } else {
          let error =
            "Duplicate Field : " +
            err?.response?.data.split(": {")[1].slice(0, -1);
          if (error) dispatch(openSnackbar(error, "error"));
          // dispatch(openSnackbar(errors, "error"));
        }
      }
    },
    validate: (values) => {
      // console.log("Values", values );
      // console.log("initial", initialValue );

      if (!values.memberId) {
        errors.memberId = "Required";
      }
      if (!values.member) {
        errors.member = "Required";
      }
      if (!values.shareholder) {
        errors.shareholder = "Required";
      }
      // else if (!/^[A-Za-z]+$/.test(values.member)) {
      //   errors.member = "Only Alphabets";
      // }
      // if (!values.organisation_name) {
      //   errors.organisation_name = "Required";
      // }
      // else if (!/^[A-Za-z]+$/.test(values.organisation_name)) {
      //   errors.organisation_name = "Only Alphabets";
      // }
      // if (!values.nominee_name) {
      //   errors.nominee_name = "Required";
      // }
      // else if (!/^[A-Za-z]+$/.test(values.nominee_name)) {
      //   errors.nominee_name = "Only Alphabets";
      // }
      if (!values.state) {
        errors.state = "Required";
      }

      if (values.state === "") {
        errors.state = "Required";
      }

      if (values.district === "") {
        errors.district = "Required";
      }

      if (values.taluka == "") {
        errors.taluka = "Required";
      }
      // else if (!/^[A-Za-z]+$/.test(values.city)) {
      //   errors.city = "Only Alphabets";
      // }

      if (!values.firstName) {
        errors.firstName = "Required";
      }
      // if (!values.middleName) {
      //   errors.middleName = "Required";
      // }
      if (!values.lastName) {
        errors.lastName = "Required";
      }
      // else if (!/^[A-Za-z]+$/.test(values.firstName)) {
      //   errors.firstName = "Only Alphabets";
      // }

      // if (
      //   values.email != "" &&
      //   !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      // ) {
      //   errors.email = "Invalid email";
      // }

      if (!values.phone) {
        errors.phone = "Required";
      } else if (
        !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone)
      ) {
        errors.phone = "Invalid Phone";
      }

      if (!values.addressLine_1) {
        errors.addressLine_1 = "Required";
      }

      if (!values.nominee_name) {
        errors.nominee_name = "Required";
      }

      let checkPin = pincodeDirectory.lookup(values.pincode);
      // console.log(checkPin);

      if (!values.pincode) {
        errors.pincode = "Required";
      } else if (!/^[1-9][0-9]{5}$/.test(values.pincode)) {
        errors.pincode = "Invalid Pin Code";
      }
      // else if (checkPin[0]?.stateName !== values.state.toUpperCase()) {
      //   errors.pincode = "Invalid Pin Code";
      // }
      return errors;
    },
  });

  useEffect(() => {
    setData(formData);
  }, [params.id, id, formData]);

  useEffect(() => {
    setData(formData);
  }, [formData]);

  useEffect(() => {
    // console.log("data", data);
    formik.setValues(data);
    formik.setFieldValue(
      "memberId",
      data.memberId ? data.memberId : uuid.toString().slice(9, 16)
    );
  }, [data]);

  ///fetch config data
  const [memberType, setMemberType] = useState([]);
  const [shareType, setShareType] = useState([]);

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  useEffect(() => {
    setMemberType(
      getConfigData?.memberType?.map((e) => {
        return e.type;
      })
    );
    setShareType(
      getConfigData?.shareholderType?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  useEffect(() => {
    async function fetchData() {
      const url = window.location.href.split("/");
      const id = url[url.length - 1];
      if (getAuthData) {
        try {
          ////checktoken
          // let token = window.localStorage.getItem("sayasLoginToken");
          let token = getAuthData;
          if (!token) {
            dispatch(LogoutSayasActionCreate());
          }
          const response = await axios.get(BASE_URL + `/api/v1/member/${id}`, {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          });
          const data = response.data;
          // console.log({
          //   ...data,
          //   state: data.state.toString().toUpperCase(),
          //   district: data.district.toString().toUpperCase(),
          //   taluka: data.taluka.toString().toUpperCase(),
          // });
          // ?.map((ele) => {
          //   return {
          //     ...ele,
          //     state: ele.state.toString().toUpperCase(),
          //     district: ele.district,
          //     taluka: ele.taluka,
          //   };
          // })

          setData({
            ...data,
            state: data.state.toString().toUpperCase(),
            district: data.district.toString().toUpperCase(),
            taluka: data.taluka.toString().toUpperCase(),
          });

          setDate(response.data.date || response.data.createdAt);
          setId(response.data.id);
        } catch (err) {
          console.log(err);
          if (err.request?.status === 401) {
            dispatch(LogoutSayasActionCreate());
          }
        }
      }
    }
    fetchData();
  }, [id, getAuthData]);

  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);

  // useEffect(() => {
  //   if (formik.values.state) {
  //     const formattedKeysStateObj = statesDistrictsTalukas[
  //       formik.values.state
  //     ]?.map((item) => {
  //       return _.mapKeys(item, (value, key) => v.trim(key));
  //     });

  //     const districtBasedTalukas = formattedKeysStateObj.filter(
  //       (item) => item["DISTRICT"] === formik.values.district
  //     );

  //     const talukas = districtBasedTalukas.map((item) => item["TEHSIL"]);
  //     setAllTalukas(talukas);
  //   }
  // }, [formik.values.state, formik.values.district]);

  useEffect(() => {
    // console.log(data);
    if (formik.values.state === data.state) {
      formik.setFieldValue("district", data.district || "");
      formik.setFieldValue("taluka", data.taluka || "");
    } else {
      formik.setFieldValue("district", "");
      formik.setFieldValue("taluka", "");
    }
  }, [formik.values.state]);

  useEffect(() => {
    // console.log("Hit taluka in advance Search::");
    let talukas;
    if (statesDistrictsTalukas[`${formik.values.state}`]) {
      talukas = statesDistrictsTalukas[`${formik.values.state}`]?.filter(
        (e) => {
          return e["DISTRICT"] === formik.values.district;
        }
      );
    } else {
      talukas = [];
    }

    talukas = talukas?.map((ele) => {
      // console.log("In taluka map::", ele);
      return ele["TEHSIL"];
    });
    // console.log(talukas || []);
    setAllTalukas([...(talukas || [])]);
  }, [formik.values.district]);

  // console.log(allTalukas);

  return (
    <div
      style={{ padding: "20px 10px 0 20px" }}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          // alert("this.state.message");
          // formik.handleSubmit();
        }
        return;
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "25px 30px",
            width: "100%",
          }}
        >
          <DatePickers
            label="Date"
            name="date"
            formik={formik}
            data={date}
            setDate={setDate}
            customStyles={{ height: "38px" }}
          />
          <div className="d-flex align-items-center">
            <label className={styles.switchLabel}>Activity Status</label>
            <Switch
              name="activityStatus"
              checked={formik.values.activityStatus || false}
              onChange={formik.handleChange}
              disabled={userRole.toLowerCase() !== "staff" ? false : true}
            />
          </div>
          <div className="d-flex align-items-center">
            <label className={styles.switchLabel}>Mobile App</label>
            <Switch
              name="mobileSignUpStatus"
              checked={formik.values.mobileSignUpStatus || false}
              onChange={formik.handleChange}
              disabled={userRole.toLowerCase() !== "staff" ? false : true}
            />
          </div>

          <MemberInput label="Member ID" name="memberId" formik={formik} />
          <MemberSelectField
            label="Member"
            name="member"
            formik={formik}
            items={memberType?.length > 0 ? memberType : []}
            disabled={userRole.toLowerCase() !== "staff" ? false : true}
          />
          <MemberSelectField
            label="Shareholder"
            name="shareholder"
            formik={formik}
            items={shareType?.length > 0 ? shareType : []}
            // items={["Individual", "Institutional", "NA"]}
          />
          <MemberInput label="First Name" name="firstName" formik={formik} />
          <MemberInput label="Middle Name" name="middleName" formik={formik} />
          <MemberInput label="Last Name" name="lastName" formik={formik} />
          <MemberInput label="Email" name="email" formik={formik} />
          <MemberInput label="Phone" name="phone" formik={formik} />
          <MemberInput
            label="Nominee Name"
            name="nominee_name"
            formik={formik}
          />
          <MemberInput
            label="Organisation Name"
            name="organisation_name"
            formik={formik}
          />
          <UsersCityField
            label="State"
            name="state"
            formik={formik}
            setCityName={setCityName}
            states={states}
            cityName={cityName}
          />
          {/* {formik.values.state ? (
            <> */}
          <UserStateField
            label="District"
            name="district"
            formik={formik}
            cityName={cityName}
          />
          <MemberSelectField
            label="Taluka"
            name="taluka"
            formik={formik}
            items={
              allTalukas
              // city.filter((el) => el.name === formik.values.district)[0]
              //   ?.tahasil
            }
          />
          <MemberInput label="City/Village" name="city" formik={formik} />
          {/* </>
          ) : (
            <MemberInput label="City" name="city" formik={formik} />
          )} */}

          <MemberInput label="Pin Code" name="pincode" formik={formik} />
          <MemberInput
            label="Address Line 1"
            name="addressLine_1"
            formik={formik}
          />
          <MemberInput
            label="Address Line 2"
            name="addressLine_2"
            formik={formik}
          />
        </div>
        <div align="right" style={{ marginRight: "30px", marginTop: "30px" }}>
          <button
            id={userStyles.cancelBtn}
            onClick={() => {
              dispatch(selectedMemberRows(undefined, []));
              history.push("/admin/members");
            }}
          >
            Cancel
          </button>
          <button id={userStyles.submitBtn} type="submit">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateMemberForm;
