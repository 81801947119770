import * as React from "react";
import TextField from "@mui/material/TextField";
import styles from "./GeneralInputField.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";

export const GeneralInputField = (props) => {
  const {
    conflict,
    setConflict,
    label,
    place,
    dataKey,
    useFor,
    onlyNumber,
    paddingRight,
    submitted,
    multiline,
    dataValue,
    data,
    setData,
    maxRows,
    uploadFile,
    maxVal,
  } = props;
  const PlaceHolder = label;
  const storeValue = dataKey;
  const [value, setValue] = React.useState(
    dataValue ? dataValue : props.data[storeValue]
  );

  const [textVal, setTextVal] = useState("");
  const dispatch = useDispatch();

  // console.log(storeValue, props.data[storeValue], value, data);
  // console.log(maxVal);
  const handleChangeAPI = (event) => {
    setValue(event.target.value);
    if (setConflict) {
      // console.log(conflict);
      setConflict(!conflict);
    }
    if (!data) return;
    let newData = data;
    newData[`${storeValue}`] = textVal;
    setData(newData);
  };

  const handleChange = (event) => {
    if (onlyNumber === true) {
      // console.log(event.target.value < 0);
      if (event.target.value < 0) {
        event.preventDefault();
        event.target.value = 0;
        alert("Please enter only positive Number");
        return;
      }
      // console.log(maxVal);
      if (maxVal && maxVal < event.target.value) {
        event.preventDefault();
        dispatch(
          openSnackbar("Enter the no. less than available shares", "error")
        );
        return
      }
    }
    setValue(event.target.value);
    if (setConflict) {
      // console.log(conflict);
      setConflict(!conflict);
    }
    if (!data) return;
    let newData = data;
    newData[`${storeValue}`] = event.target.value;
    setData(newData);
  };

  React.useEffect(() => {
    setValue(data[storeValue]);
    if (setConflict) {
      // console.log(conflict);
      setConflict(!conflict);
    }
    if (!data) return;
    let newData = data;
    newData[`${storeValue}`] = data[storeValue];
    setData(newData);
  }, [dataKey]);

  const allowOnlyLetters = (e, t) => {
    if (e.target.value.length >= 100) {
      alert("Please enter a Valid Range");
      e.preventDefault();
    }
    if (window.event) {
      var charCode = window.event.keyCode;
    } else if (e) {
      var charCode = e.which;
    } else {
      return true;
    }
    if (
      (charCode > 64 && charCode < 91) ||
      (charCode > 96 && charCode < 123) ||
      (charCode > 47 && charCode < 58) ||
      /^[^!-\/:-@\[-`{-~]+$/.test(charCode) ||
      charCode === 32
    ) {
      // console.log(e.target.value);
      return true;
    } else {
      alert("Please enter only Alphabets Or Number ");
      e.preventDefault();
      return false;
    }
  };

  const allowOnlyPositiveNumber = (e) => {
    if (window.event) {
      var charCode = window.event.keyCode;
    } else if (e) {
      var charCode = e.which;
    } else {
      return true;
    }
    if (charCode >= 48 && charCode <= 57) return true;
    else {
      alert("Please enter only positive Number");
      e.preventDefault();
      return false;
    }
  };

  React.useEffect(() => {
    if (props.value != null) {
      let newData = props.data;
      newData[`${storeValue}`] = props.value;
      props.setData(newData);
      setValue(props.value);
    }
  });

  React.useEffect(() => {
    setValue(dataValue);
  }, [uploadFile]);

  return (
    <div
      style={{
        margin: "1rem .1rem",
        marginBottom: `${props.lowMargin ? "0.1rem" : ".1rem"}`,
        width: props.width ? props.width : "100%",
        textTransform: "capitalize",
      }}
    >
      <TextField
        fullWidth
        type={onlyNumber ? "number" : "text"}
        multiline={multiline ? true : false}
        rows={maxRows || 4}
        maxRows={maxRows}
        error={
          submitted && (value === undefined || value === "" || value === 0)
            ? true
            : false
        }
        // id={styles.userInput}
        paddingRight={`${paddingRight ? paddingRight : "0rem"}`}
        className="form-control"
        disabled={props.disabled ? true : false}
        required={props.required ? true : false}
        value={useFor === "api" ? textVal : value}
        onKeyPress={
          dataKey === "title"
            ? allowOnlyLetters
            : onlyNumber
            ? allowOnlyPositiveNumber
            : () => {}
        }
        min={0}
        onBlur={
          useFor === "api"
            ? (e) => {
                handleChangeAPI(e);
                // console.log("onBur");
              }
            : () => {}
        }
        onChange={
          useFor === "api"
            ? (e) => {
                // console.log(e.target.value);
                setTextVal(e.target.value);
              }
            : handleChange
        }
        label={place ? place : PlaceHolder}
        id="fullWidth"
      />
    </div>
  );
};

// onChange={(e)=>{throttleChange(handleChange(e))()}}
