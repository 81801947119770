import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { PieChartDataAcq } from "./PieChartDataAcq";
import DataTable from "../../Components/Table";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PieChartsData(props) {
  const { allData, columns, tableOptions, tableRef } = props;
  const [value, setValue] = React.useState(0);

  // console.log("pie chart all data::", allData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Response Table" {...a11yProps(0)} />
          <Tab label="Results Summary" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div>
          <DataTable
            tableRef={tableRef}
            title=""
            columns={columns}
            data={allData?.dataAcquisitionInfo?.tableData}
            options={tableOptions}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            width: "97%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            flexWrap: "wrap",
            rowGap: "20px",
          }}
        >
          {allData?.quetionsStatus ? (
            Object.keys(allData?.quetionsStatus).map((ele) => {
              return (
                <PieChartDataAcq
                  que={ele}
                  pieChartsData={allData?.quetionsStatus[`${ele}`]}
                />
              );
            })
          ) : (
            <h4>No Record to display</h4>
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
    </Box>
  );
}
