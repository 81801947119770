import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import frLocale from "date-fns/locale/fr";

export const GeneralDateField = (props) => {
  const { maxCurrentDate, disabled } = props;
  const PlaceHolder = props.label;
  const storeValue = props.dataKey;
  const [value, setValue] = React.useState(props.data[storeValue]);
  // console.log(value,props.data);

  const handleChange = (newValue) => {
    setValue(newValue);
    // let newData = props.data;
    // console.log(props);
    // newData[storeValue] = value;
    props.setData({ ...props.data, date: newValue });
  };

  return (
    <div
      // className = "w-100"
      style={{ margin: "1rem .1rem" }}
      // margin={`${props.marginTop ? "1rem .1rem" : ".1rem .1rem"}`}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={`${PlaceHolder}`}
          value={value}
          disabled={props.disabled ? true : false}
          inputFormat="dd/MM/yyyy"
          maxDate={maxCurrentDate ? Date.now() : null}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField style={{ width: "100%" }} {...params} />
          )}
        />

        {/* <DatePicker
     label={`${PlaceHolder}`}
      inputFormat="dd/MM/yyyy"
      openTo={"day"}
      value={value}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} />}
    /> */}
      </LocalizationProvider>
    </div>
  );
};
