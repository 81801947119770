const maharashtraCityState = [
  {
    postOffice: "Adgaon Bk",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444126,
  },
  {
    postOffice: "Adgaon KD",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444126,
  },
  {
    postOffice: "Adsul",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Agar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Akola City",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444002,
  },
  {
    postOffice: "Akola",
    Type: "H.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444001,
  },
  {
    postOffice: "Akoli Jahangir",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Akolkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Akot Kut",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Akot",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Alanda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Alegaon",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444511,
  },
  {
    postOffice: "Anbhora",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Andura",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Anvi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Apoti",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Assegaon Bazar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Babhulgaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Babhulgaon Jahangir",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444104,
  },
  {
    postOffice: "Bahadura",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Balapur",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444302,
  },
  {
    postOffice: "Balegaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444117,
  },
  {
    postOffice: "Bapori",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Barshitakli",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Batwadi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444302,
  },
  {
    postOffice: "Belkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Bhamberi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Bhandaraj",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Bhar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Bhatori",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Bhendi Mahal",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444407,
  },
  {
    postOffice: "Bhurad",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444002,
  },
  {
    postOffice: "Bordi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Borgaon KD",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444407,
  },
  {
    postOffice: "Borgaon Manju",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Borta",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Bramhi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Chandikapur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Channi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Charangaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444511,
  },
  {
    postOffice: "Chatari",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Chikhalgaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Chittalwadi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Chohatta",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Chondhi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444511,
  },
  {
    postOffice: "Dagadparwa",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Dahihanda",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444111,
  },
  {
    postOffice: "Danori Panori",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Dapura",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444502,
  },
  {
    postOffice: "Deoli",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Deori",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Deulgaon Bramhan",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Dhabha",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Dhotardi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Digras",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444104,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Donwada",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Durgwada",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Gaigaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Gajanan Nagar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444002,
  },
  {
    postOffice: "Gandhigram",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Gandhinagar    Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444004,
  },
  {
    postOffice: "Gawandgaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Ghodegaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Ghota",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Ghungshi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Ghusar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Goregaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Goregaon KD",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Gudadhi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444005,
  },
  {
    postOffice: "Hanwadi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Hata",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Hatgaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Hatola",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Hatrun",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Hingani KD",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Hirpur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Hivara Mana",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Hiverkhed Ruprao",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Jalgaon Nahate",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444126,
  },
  {
    postOffice: "Jamakeshwar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Jambha",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Jampthi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Januna",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Jatherpeth Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444005,
  },
  {
    postOffice: "Jaulkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444111,
  },
  {
    postOffice: "Kajaleshwar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Kajaleshwar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Kalambi Mahagaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Kalwadi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Kanadi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Kanheri Gawali",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444302,
  },
  {
    postOffice: "Kanheri Sarap",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Kanshoni",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Kanzara",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Kanzara",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Kapsi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Karanja Ramzanpur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Kasura",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Kaulkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Kaulkhed",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444004,
  },
  {
    postOffice: "Kawasa",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Kazikhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Keliveli",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444111,
  },
  {
    postOffice: "Khambora",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Kheldeshpande",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Kherda Bhagai",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444407,
  },
  {
    postOffice: "Kherda Vilegaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Khetri",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Khirkund",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Khirpuri",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444302,
  },
  {
    postOffice: "Kinkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Kinkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444111,
  },
  {
    postOffice: "Kolambi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Kothali",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Krishinagar Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444104,
  },
  {
    postOffice: "Kurankhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Kutasa",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Lakhpuri",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Lohagad",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Lohara",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Lonagra",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Madhapuri",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Mahagaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Mahan",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Malegaon Bazar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Malrajura",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Malsoor",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Mana",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Manabda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Manarkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Mangrul Kambe",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Manjari",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Mathodi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Mazod",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Mhaisang",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Mhaispur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Midc Shivar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444104,
  },
  {
    postOffice: "Mohala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Mokha",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Morgaon Bhakre",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Morgaon Kakad",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Morgaon Sadijan",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Morzadi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Mundgaon",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444117,
  },
  {
    postOffice: "Murtizapur City",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Murtizapur",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Ner",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Nimba",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Nimbha",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Nimbhora",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Nimbi Malokar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Nimkarda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Nipana",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Pailpada",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Palso Badhe",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Palsod",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Panaj",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Panchagavan",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Paras",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Pardi Tad",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Pathardi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444117,
  },
  {
    postOffice: "Patkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Patur Nandapur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444407,
  },
  {
    postOffice: "Patur",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Pedgaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Pimpalkhuta",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Pimpri Kd",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444126,
  },
  {
    postOffice: "Pinjar",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444407,
  },
  {
    postOffice: "Pohi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Popatkheda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Punda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Punoti KD",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Rahit",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444407,
  },
  {
    postOffice: "Rail",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444111,
  },
  {
    postOffice: "Rajanda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Rajankhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Rajnapur Kinkinhi",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Ramdaspeth Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444001,
  },
  {
    postOffice: "Ramtek",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Ranpisenagar Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444001,
  },
  {
    postOffice: "Raundala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Ridhora",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444302,
  },
  {
    postOffice: "Rpts Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444002,
  },
  {
    postOffice: "Ruikhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Sanglood",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444102,
  },
  {
    postOffice: "Sangvi Kd",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Sasti",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Saundala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Sawara",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Shelubazar Mana",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Shelubazar",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Shirla Andhare",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Shivaji Park Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Shoni",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444104,
  },
  {
    postOffice: "Sindkhed",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Sirso",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Sirsoli",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444126,
  },
  {
    postOffice: "Sonala Washim",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Sonori",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Sukoda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Tajnapeth Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Talegaon Bazar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Tandali",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Tarhala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Telhara    Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Tilak Road Akola",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444001,
  },
  {
    postOffice: "Titwa",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Tulanga BK",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Ugawa",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Ukali Bazar",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Umara",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Umara Balapur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Umari",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444005,
  },
  {
    postOffice: "Ural",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444311,
  },
  {
    postOffice: "Vadali Deshmukh",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Vadegaon",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444502,
  },
  {
    postOffice: "Vidyutnagar Paras",
    Type: "S.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444109,
  },
  {
    postOffice: "Vivra",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444511,
  },
  {
    postOffice: "Viwara",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444501,
  },
  {
    postOffice: "Vizora",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Vyala",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444302,
  },
  {
    postOffice: "Wadad",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444003,
  },
  {
    postOffice: "Wadi Adampur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Wagha",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },
  {
    postOffice: "Wai Pargane Mana",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444107,
  },
  {
    postOffice: "Wairat Rajapur",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444006,
  },
  {
    postOffice: "Wanoja",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444402,
  },
  {
    postOffice: "Wari",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444103,
  },
  {
    postOffice: "Warud",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444108,
  },
  {
    postOffice: "Warudjaula",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444101,
  },
  {
    postOffice: "Yeranda",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444401,
  },
  {
    postOffice: "Zodga",
    Type: "B.O",
    District: "Akola",
    State: "MAHARASHTRA",
    Pincode: 444405,
  },

  {
    postOffice: "Achalpur City",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Adgaon Navapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Adula Bazar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Alangaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Aloda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Ambada E",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Ambapati",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Ambapeth",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444601,
  },
  {
    postOffice: "Amla",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Amla Vishweshwar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Amner",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Amravati Camp",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Amravati",
    Type: "H.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444601,
  },
  {
    postOffice: "Anjangaon Bari E",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Anjangaon",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Anjansingi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Anjanwati",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Antargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Arala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Asadpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Asegaon Purna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Ashoknagar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Ashti",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Ashtoli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Asra",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Badhli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Badnapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Badnera",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Badnera Town",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Baggi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Bairagad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Bargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Belaj",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Belora",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Benoda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Bhaji Bazar Amravati",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444601,
  },
  {
    postOffice: "Bhamod",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Bhandaraj",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Bhankheda Khurd",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Bhatkkuli Ranak",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Bhatkuli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Bhemdi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Bhilona",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Bhokarbardi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Bhugaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Bijudhawadi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Bobdo",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Bodform",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Bopapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Borala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Borala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Borda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Bordi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Borgan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Borgaon Dhande",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Borgaon Peth",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Bori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Brahmanwada Gov",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Brahmanwada Thadi",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444720,
  },
  {
    postOffice: "Chakarda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Chamak BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Chandas Wathoda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Chandur Bazar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Chandur Railway",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Chatwabod",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Chausala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Chendakapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Chhota Bazar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444805,
  },
  {
    postOffice: "Chikhal Savangi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Chikhaldara",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Chikhali Vaidya",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Chincholi BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Chincholi Gawal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Chincholi Kale",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Chincholi Shinghane",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Chinchpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Chourakund",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Churni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Congress Nagar    Amravati",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444606,
  },
  {
    postOffice: "Cotton Market",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Dabha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Dabhada",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Dabida",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Dabka",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Dadhi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Dahenda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Dahigaon Reche",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Dahigaonpura",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Dapori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Dapori Khurd",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Darapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Daryapur",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444803,
  },
  {
    postOffice: "Daryapur Town",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444803,
  },
  {
    postOffice: "Dawargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Deogaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Deurwada",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Dewara Antora",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Dewara",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Dhamak",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Dhamangaon Gadhi",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Dhamangaon R.S.",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Dhamangaon Town",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Dhamodi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Dhamori Kasba",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Dhanegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Dhanodi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Dhanodi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444710,
  },
  {
    postOffice: "Dhanora Fasi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Dhanora Gurav",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Dhanora Mhali",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Dhanora Mogal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Dharan Mahu",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Dharni",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Dhoter Kheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Dhulghat Rly",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Dhulghat Road",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Dighi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444711,
  },
  {
    postOffice: "Diya",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Doma",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Duni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Ekdara",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Gadegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Gaiwadi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Gangarkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Ganoja",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Ganori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Gaurkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Gaurkheda Bazar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Gavandkund",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Gawandgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Gawhan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Ghatladki",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444720,
  },
  {
    postOffice: "Ghuikhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Gokul Market",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444606,
  },
  {
    postOffice: "Gurukunj Ashram",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "H. V. Nagar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444605,
  },
  {
    postOffice: "Hantoda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Haram",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Harisal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Hartala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Hatru",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Haturna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Haturna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Hirabambai",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Hirpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Hirur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Hiwara BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Hiwarkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Isambari",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Itki Antargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Ittamgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Jainpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Jalka Hirapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Jalka Jagtap",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Jalka Patache",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Jamgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Jamli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Jamthi Ganeshpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Januna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Jarud",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Jasapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Jawala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Jawala BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Jawala Shahpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Juna Dhamangaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Kajna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Kakda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Kalamgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Kalamkhar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Kalashi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Kalgawhan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Kamalpur Taroda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Kamnapur Ghusal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Kandli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Kanholi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Kapustalni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Kapustalni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Karajgaon  Gadlighat",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Karajgaon",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444809,
  },
  {
    postOffice: "Karanja Bahiram",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444809,
  },
  {
    postOffice: "Karla",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Karla",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Kasarkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Kathora",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Kati",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Katkumbha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Katpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Katsur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Kawadgavan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Kawali",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Kawatha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Kawatha Kadu",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Kawathal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Kawitha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Kekatpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Khairi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Khairi Tembhru",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Khalkoni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Khallar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444803,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Khaparkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Kharala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Kharpi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444809,
  },
  {
    postOffice: "Khartalegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Khaspur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Khed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Kholapur",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Khondgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Khopada",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Kohana",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Kokarda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444803,
  },
  {
    postOffice: "Kolha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Kolvihir",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Kulangana",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Kumbhargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Kural",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Kurha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444809,
  },
  {
    postOffice: "Kurha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Kushta BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Kusumkot",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Kutanga",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Ladki",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Lahegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Lakhad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Lehegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Linga",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Loni Takli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Lotwada",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Madhan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Maheriam",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Mahuli Chor",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Mahuli Dhande",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Mahuli Jahagir",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Malhara",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Malkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Mandwa",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Mangrul Bhilasp",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Mangrul Chawala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Mangrul Dastgir",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444711,
  },
  {
    postOffice: "Mangruli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Manjarkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Manjri Masla",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Marda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Mardi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Markanda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Mhaispur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Mokhad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Morgad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Morshi",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Motha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Mozri",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Murha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Murtizapur Taro",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Nalwada",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Nanded BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Nandgaon Khandeshwar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Nandgaon Peth",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Nandrun",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Nandsawangi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Nandura Bl",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Nanduri",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Narayanpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Naya Akola",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Nerpingalai",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Nimbha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Nimbhari",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Nimbhi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Nimbhora Bodkhe",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444711,
  },
  {
    postOffice: "Nimbhora Delwad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Nimboli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444711,
  },
  {
    postOffice: "Nimgavan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Nimkhed Bazar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Nirul",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Old Cotton Market",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444601,
  },
  {
    postOffice: "Pala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Palaskhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Palasmandal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Pandhari",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Papal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Paplam",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Parasapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Paratwada",
    Type: "H.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444805,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Pathrot",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Pauni",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Phubgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Phulamala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Pimpal Vihir",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Pimpalgaon Nipa",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Pimpalkhuta",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Pimpalkhuta",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Pimpalkhuta",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Pimplod",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Pimpri Nipani",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Pohara",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Porgawhan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Purna Nagar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Pusda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Pusla",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Rahatgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Rahu",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Raipur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Rajapeth",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444601,
  },
  {
    postOffice: "Rajna Purna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Rajura",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Rajura Bazar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Rajurwadi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Rama",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Ramtirth",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Ranitamboli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Rasegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Ratnapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Rewasa",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Rithpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "Roshankheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Rukhmini Nagar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444606,
  },
  {
    postOffice: "Sadrabadi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Sai Nagar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444607,
  },
  {
    postOffice: "Salepur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Salod",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Salona",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Salora Khurd",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Samda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Sanglood",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Sant GadgebabaAmravatiUniversity",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Sarfapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444809,
  },
  {
    postOffice: "Sarsi Kothoda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Sasan Ramapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Satargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Satargaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Sategaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Satephal",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Satnoor",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444907,
  },
  {
    postOffice: "Saur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Sawalapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Sawalikheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Sawaner",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Sawanga",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444908,
  },
  {
    postOffice: "Sawangi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Sawangi Magrapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Sawarkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Sayat",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Semadoh",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Shahapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444807,
  },
  {
    postOffice: "Shendola BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Shendola KH",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Shendurjana Bazar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Shendurjana Dha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444710,
  },
  {
    postOffice: "Shendurjana Ghat",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444907,
  },
  {
    postOffice: "Shingnapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Shingori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Shirala",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Shirkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Shirpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Shivaji Nagar    Amravati",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444603,
  },
  {
    postOffice: "Shivangaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Shivani Rasulpu",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Shivar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Sindi BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Sirajgaon Band",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Sirasgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Sirasgaon Kasba",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444810,
  },
  {
    postOffice: "Sirasgaon Korde",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Sirasgaon Mozar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Sonapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Songaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Sonora BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444904,
  },
  {
    postOffice: "Sonori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444704,
  },
  {
    postOffice: "SRPF Camp",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Sultanpur Nandu",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Surali",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444720,
  },
  {
    postOffice: "Surji   Anjangaon",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Surli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Susarda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Takarkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Takarkheda More",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Takli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Takli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Takli BK",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Talegaon Dashasar",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444710,
  },
  {
    postOffice: "Talegaon Mohana",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Talegaon Thakur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444902,
  },
  {
    postOffice: "Talni Purna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Talwel",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Tambursonda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Tapovan",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Taroda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Tarubanda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444813,
  },
  {
    postOffice: "Tawlar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Tembhurkheda",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Tembli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Teosa Ghat",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444907,
  },
  {
    postOffice: "Thilori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Tiosa",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Titamba",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444702,
  },
  {
    postOffice: "Tiwara",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Tolkhari",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Tondgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Tonglabad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Tuljapur Gadhi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444723,
  },
  {
    postOffice: "Turkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Udapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Udkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Umri Mamadabad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Uttamsara",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "V.M.V.  Amravati",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444604,
  },
  {
    postOffice: "Veni Ganeshpur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Verha",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Vichori",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Vihigaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444705,
  },
  {
    postOffice: "Virshi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Virul Ronghe",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Vishnora",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444707,
  },
  {
    postOffice: "Vishroli",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444720,
  },
  {
    postOffice: "Wadgaon Fatepur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444808,
  },
  {
    postOffice: "Wadgaon Mahore",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444602,
  },
  {
    postOffice: "Wadgaon Rajdi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444709,
  },
  {
    postOffice: "Wadhona",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444710,
  },
  {
    postOffice: "Wadhona Ramnath",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Wadnergangai",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Wadura",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Waigaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Waki Raipur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Walgaon Road Tso Amravati",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444601,
  },
  {
    postOffice: "Walgaon",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444801,
  },
  {
    postOffice: "Wani",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444720,
  },
  {
    postOffice: "Warud Bk",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Warud",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444906,
  },
  {
    postOffice: "Wasni Bzk",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Wastapur",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444717,
  },
  {
    postOffice: "Wathoda Naya",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Wathoda Sh.",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444802,
  },
  {
    postOffice: "Werkhed",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444903,
  },
  {
    postOffice: "Yawali",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444901,
  },
  {
    postOffice: "Yawali Dongar",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Yenas",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Yeoda",
    Type: "S.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444706,
  },
  {
    postOffice: "Yeota",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444806,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Yerad",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444708,
  },
  {
    postOffice: "Yerandgaon",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444701,
  },
  {
    postOffice: "Yerla",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444905,
  },
  {
    postOffice: "Yesurna",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444804,
  },
  {
    postOffice: "Zada",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444711,
  },
  {
    postOffice: "Zilpi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 444719,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Amravati",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },

  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Adgaon BK",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Adgaon Sarak",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Adul Budruk",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Agarwadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431110,
  },
  {
    postOffice: "Airport, Chikalthana",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Ajantha Caves",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431118,
  },
  {
    postOffice: "Ajantha",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431117,
  },
  {
    postOffice: "Akoli Wadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Aland",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Amba  Upla",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Ambelohal",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Ambhai",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Amthana",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Andhaner",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Andhari",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431151,
  },
  {
    postOffice: "Antarwali Khandi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Anwa",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Anwi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Apegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Assegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Aurala",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Aurangabad  MH",
    Type: "H.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Aurangabad Cantonment",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Aurangabad City",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Aurangabad Railway Station",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431005,
  },
  {
    postOffice: "Babhulgaon  Pirachi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Babra",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431151,
  },
  {
    postOffice: "Badnapur",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Badod Bazar",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Badod Pan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Bahirgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Bahulkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431120,
  },
  {
    postOffice: "Bajaj Nagar Midc Waluj",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431136,
  },
  {
    postOffice: "Balanagar",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Balapur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431117,
  },
  {
    postOffice: "Balegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Bankinhola",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Banoti",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431150,
  },
  {
    postOffice: "Banshendra",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Basadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Begumpura",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431004,
  },
  {
    postOffice: "Bhaggaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Bhagur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Bhakarwadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Bhalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Bharadi",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Bharamba",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Bhendala",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431110,
  },
  {
    postOffice: "Bhivgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Bhokardan",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Bidkingaon",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Bihamandwa",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431137,
  },
  {
    postOffice: "Bilda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Biloni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Bodhegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431151,
  },
  {
    postOffice: "Bodkha",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Bokud Jalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Borgaon Arj",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Borgaon Bazar",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Borgaon Kasari",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Borsar BK",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Borsar Bhingi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Bramhani",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Chandgon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Changatpuri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Chapaner",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Charner",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Chikalthana Industrial Area",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431006,
  },
  {
    postOffice: "Chikalthana",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Chikatgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Chikhalthan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Chinchadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Chincholi Limbaji",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Chitegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Chittepimpalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Chorwaghalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Chouka",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Cidco Colony    Aurangabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431003,
  },
  {
    postOffice: "Dahegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Dahegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Daulatabad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Dawarwadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Debhegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Deogaon Rangari",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Deulgaon Bazar",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Devlana",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Dhkephal",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Dhondalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Dhorkingaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Dhupkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Dr. B.A. Marathwada University",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431004,
  },
  {
    postOffice: "Dudhad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Ektuni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Ellora Caves",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Ellora",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Eta",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Fazalpura",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Gadana",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Gadhejalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Gadhepimpalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Gajgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Galle Borgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Gangapur  Aurangabad-MH",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431109,
  },
  {
    postOffice: "Ganori",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Garaj",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Garkheda",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431009,
  },
  {
    postOffice: "Gavli Tanda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Gawali Shivra",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Georai Kuber",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Gevarai",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Gevarai BK",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Gevrai Shami",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Ghanegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431136,
  },
  {
    postOffice: "Ghardon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Gharegaon Ektuni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Ghat Shendra",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Ghatnanadra",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Ghodegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Golatgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431150,
  },
  {
    postOffice: "Halda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Hanumantkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431150,
  },
  {
    postOffice: "Harshi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Harsool",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Hasnabad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Hasta",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Hatnoor",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Hatti",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "High Court Building    Aurangabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431005,
  },
  {
    postOffice: "Hingoni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Hiradpuri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431137,
  },
  {
    postOffice: "Issarwadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431148,
  },
  {
    postOffice: "Jaitapur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Jaitkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Jalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Jalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Jambhargaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Jamdi Jahangir",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Jamthi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431118,
  },
  {
    postOffice: "Janephal",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Jarandi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431120,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Jatwada",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Jehur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Jikthan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Kachner",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Kadethan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Kagazipura",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Kaigaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431110,
  },
  {
    postOffice: "Kaigaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Kalandrabad  Kasabkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Kalimath",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Kanhori",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Kankori",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Kannad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Karanjgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Karanjkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Karkin",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Karmad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Kasoda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Katepimpalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Katpur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431148,
  },
  {
    postOffice: "Kelgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Kerhala",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Khadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Khamgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431151,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Khirdi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Khodegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Khuldabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Khumbhephal",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Kingaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Kolhi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Kolwadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Kranti Chowk",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431005,
  },
  {
    postOffice: "Ladgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Ladsawangi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Lakhani",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Lakhegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Lamangaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Lasur Station",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Lasurgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Liha Khedi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Loni KH",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Lonwadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431151,
  },
  {
    postOffice: "Madni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Mahalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Mahora",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Maleghogargaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Mali Wadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Maliwada",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Malunja",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431109,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Manjri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431109,
  },
  {
    postOffice: "Manur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Marsawali",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Medical College    Aurangabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Mehaboobkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Modha BK",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Mulani Wadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Mundwadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Nachanwel",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Nagad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Nagamthan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Nagapur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Naigavhan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Nakshtrawadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Nalegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Nander",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Nandra",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Narayangaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431148,
  },
  {
    postOffice: "Naregaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Nathnagar North",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431106,
  },
  {
    postOffice: "Navgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431137,
  },
  {
    postOffice: "Nevargaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431110,
  },
  {
    postOffice: "Nevpur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Nidhona",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431151,
  },
  {
    postOffice: "Nilajgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Nillod",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Nimbhora",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Nizamganj",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431003,
  },
  {
    postOffice: "Osmanpura",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431005,
  },
  {
    postOffice: "Pachod",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Padali",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Padegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Paithan",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Paithan Sakhar Karkhana",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431148,
  },
  {
    postOffice: "Pal",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Palaskheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431118,
  },
  {
    postOffice: "Palaswadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Palkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Palod",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Palshi  Shahar",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Palshi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Palshi BK",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Paradh",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Parala",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Parsoda",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Parsoda Sk",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Parundi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Pathri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Pendapur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Phardapur",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431118,
  },
  {
    postOffice: "Phulmbri",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Pimpalgaon Diwashi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Pimpalgaon Gangdev",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Pimpalwadi  Pirachi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431148,
  },
  {
    postOffice: "Pimparkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Pimpri Raja",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431007,
  },
  {
    postOffice: "Pir Bawada",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Pishore",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Pokhari",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Porgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Raghunath Nagar    Aurangabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431110,
  },
  {
    postOffice: "Rahatgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Rahegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Rahemabad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Rail",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Ranjagaon Pol",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Ranjangaon Pan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Relgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Rotegaon R.S.",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Saigavan",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Samarthnagar",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Saraswati Colony",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Sarola",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431104,
  },
  {
    postOffice: "Satara",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Satara Parisar",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431010,
  },
  {
    postOffice: "Savandgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Sawaladbara",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431118,
  },
  {
    postOffice: "Sawangi Bazar",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Sawkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Session Court    Aurangabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431005,
  },
  {
    postOffice: "Shahaganj    Aurangabad",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431001,
  },
  {
    postOffice: "Shekta",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Shekta",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Shendra MIDC Area",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Shendurwada",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Sheoga",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Shevta",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431105,
  },
  {
    postOffice: "Shirodi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Shivar",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Shivna",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Shivrai",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423703,
  },
  {
    postOffice: "Sidhanath Wadgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Sillegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Sillod",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Sillod Sakhar Karkhana",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Singhi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431109,
  },
  {
    postOffice: "Sirasgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Soegaon",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431120,
  },
  {
    postOffice: "Solegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Sultanpur",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Tadpimpalgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431115,
  },
  {
    postOffice: "Takali Ambad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431137,
  },
  {
    postOffice: "Takali Kadim",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431002,
  },
  {
    postOffice: "Takali Raje Rai",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Talni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Talpimpri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Talwada",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Tanda Bazar",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Tapargaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Tembhapuri",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Thergaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Tidka",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431150,
  },
  {
    postOffice: "Tisgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431102,
  },
  {
    postOffice: "Tuljapur Sawangi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431008,
  },
  {
    postOffice: "Tunki",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Turkabad",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Umarkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Undangaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431112,
  },
  {
    postOffice: "Upali",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Vaijapur",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Vaijapur Town",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Veergaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Wadali",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Wadgaon Tigji",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431150,
  },
  {
    postOffice: "Wadner",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431103,
  },
  {
    postOffice: "Wadwali",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431107,
  },
  {
    postOffice: "Wahegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431148,
  },
  {
    postOffice: "Wahegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431109,
  },
  {
    postOffice: "Wakla",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431116,
  },
  {
    postOffice: "Wakod",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431147,
  },
  {
    postOffice: "Wakulni",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Waladgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431136,
  },
  {
    postOffice: "Waluj",
    Type: "S.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431133,
  },
  {
    postOffice: "Wanegaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Wangi Khurd",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431113,
  },
  {
    postOffice: "Wanjargaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423701,
  },
  {
    postOffice: "Waregaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431111,
  },
  {
    postOffice: "Warkheda",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431109,
  },
  {
    postOffice: "Warud KH",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431135,
  },
  {
    postOffice: "Warudkazi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Warzadi",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431154,
  },
  {
    postOffice: "Yesgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 431101,
  },
  {
    postOffice: "Yesgaon",
    Type: "B.O",
    District: "Aurangabad",
    State: "MAHARASHTRA",
    Pincode: 423702,
  },
  {
    postOffice: "Adas",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Aherwahegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Amalner    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413207,
  },
  {
    postOffice: "Ambajogai",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Ambasakhar",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Ambewadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Ambhora",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Anandgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Anjandoha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Anjanwati",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Antarwali BK",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Antharwan Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Apegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Ardha Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Arvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Asardoha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Ashta Harinarayan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Ashti    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Badibadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Bagpimpalgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Bangali Pimpla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Bansorala",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431518,
  },
  {
    postOffice: "Bardapur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Beed",
    Type: "H.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431122,
  },
  {
    postOffice: "Beed Sangvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Beed ST Stand",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Belamba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Belgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Belura",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Belura",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Bensur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Bhalwani",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Bhendtakli",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Bhogalgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Bhogalwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Borgaon BK",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Borisawargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431518,
  },
  {
    postOffice: "Borkhed",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Borphadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Brahmagaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Chakarwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431125,
  },
  {
    postOffice: "Chaklamba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Chanai",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Chandan Sawargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Chardari",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Chartha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Chichala",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Chikhalbeed",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413207,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Chinchwan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Chondi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Choramba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Chousala",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Court Road",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431122,
  },
  {
    postOffice: "Dadahari Wadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Dadegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Dahiphal Wadmauli",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Daithan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Daskhed",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Deo Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Deodahiphal",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Deogaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Deola",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Deolali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Deulgaon Ghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Devala",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Devi Babhulgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Devinimgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Dhanegaon Camp",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431518,
  },
  {
    postOffice: "Dhangarjawalka",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Dharmapuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Dhekanmoha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "DHONDIPURA",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431122,
  },
  {
    postOffice: "Dhondrai",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Dighol Amba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Dindrood",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Doithan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Domri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Dongar Patoda",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Dongar Pimpla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Dongargan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Dongarkinhi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413229,
  },
  {
    postOffice: "Gangadevi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Gangamasla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Gevrai",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Ghagarwada",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Ghatapimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Ghatnandur",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Ghatsawali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Ghatshilpargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Ghogas Pargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Girwali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Gomalwada",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Goverdhan Hiwara",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Gulaj",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Halam",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Harichanra Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Harkinnimgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Hatola",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Hingni BK",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Hingni BK",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Hirapur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Hiwara",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Hiwara",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Hoal",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Jamgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Jatnandur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413229,
  },
  {
    postOffice: "Jawalban",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431518,
  },
  {
    postOffice: "Jawalgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "JBSSK GADI",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Jeba Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Jiwachiwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Jogeswari Pargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Kada    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Kadasakhar",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Kadiwadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Kaij",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Kalamamba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Kalegoanghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Kalsambar",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431125,
  },
  {
    postOffice: "Kamkheda",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Kanherwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Karegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413229,
  },
  {
    postOffice: "Karhewadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Kari",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Kauthali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Kawadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Kekat Pangri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Kelsangvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Kerul",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Kesapuri Camp",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431142,
  },
  {
    postOffice: "Khadkat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Khadkighat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Khalapuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Khalegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Khalwatnimgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Khandvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Khilad",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Khodwa Sawargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Khokarmoha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Kille Dharur",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431124,
  },
  {
    postOffice: "Kittiadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431142,
  },
  {
    postOffice: "Kolgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Kotan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413207,
  },
  {
    postOffice: "Kumbephal",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Kuntephal Pundi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Kuppa",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Kurla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Kuslamb",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413207,
  },
  {
    postOffice: "Ladewadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Lahuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Laul",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Limbaganesh",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Lokhandi Sawargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Lonighat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "M.GANJ",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431122,
  },
  {
    postOffice: "Madalmohi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Mahalas Jawala",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Majalgaon",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Malapuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Malegaon KHD",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Manarsumba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431125,
  },
  {
    postOffice: "Mandva",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Mangwadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Manjrath",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Manur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "MARKET YARD    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431122,
  },
  {
    postOffice: "Massajog",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Matawali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Mategoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Matkuli",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Matori",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Mauj",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "MEDICAL COLLEGE",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Mirwat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Moha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Mohkhed",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Morgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Muggaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Murshadpur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Nagapur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Nagtala",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Naholi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431518,
  },
  {
    postOffice: "Naiknoor",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431125,
  },
  {
    postOffice: "Nalwandi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Nandagaul",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Nandurghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Nathapur",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Nehru chowk",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Nimgaon Choba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Nimgaon Mayamba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Nirgudi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Nithrud",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Pachangri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Pachegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Padali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Padalsingi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Pahadi Pargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Pali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Palshingan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Palwan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Pangri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413229,
  },
  {
    postOffice: "Pangri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Parbhani Kesapuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Pargaon Ghumra",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Pargaon Shiras",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Parli Tps",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431520,
  },
  {
    postOffice: "Parli Vaijinath",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431515,
  },
  {
    postOffice: "Parner",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Pathrood",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Patoda    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Patsara",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Pattiwadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Phulepimpalgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431142,
  },
  {
    postOffice: "Phulsangvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Pimpalgaon Dhas",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413229,
  },
  {
    postOffice: "Pimpalgaon Ghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Pimpalgoan Gadhe",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Pimpalner",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413207,
  },
  {
    postOffice: "Pimpalner",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Pimpalwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Pimpalwandi    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413229,
  },
  {
    postOffice: "Pimpla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Pimpla Dhaiguda",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Pimpri BK",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Pipmarkhed",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Pohner",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Poos",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Pothra",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Purshottampuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Radi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Raimoha",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Raj Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Rajegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Rajewadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Rajuri Navgan",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Rakashasbhuwan Shani",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Rakshas Bhuwan Tamba",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Rampuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Ranjani",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Reoki",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Rohatwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Ruinalkol",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Ruithal",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Sadola",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Saigaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Sakshas Pimpri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414205,
  },
  {
    postOffice: "Salegaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Salunkwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Sandas Chincholi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Saratewadgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Sarni Sangvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Sautada",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Sawaleswar Paithan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431518,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Sawargaon Ghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413207,
  },
  {
    postOffice: "Shekta",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Shindi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Shiral",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414208,
  },
  {
    postOffice: "Shirasdevi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Shirasmarg",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Shirur    Beed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Shirurghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Shivni",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Sirsala",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Somnath Borgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Sonasangvi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Sonnathadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Sonwala",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Suggaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431523,
  },
  {
    postOffice: "Suleman Deola",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Sushi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431143,
  },
  {
    postOffice: "Tadsonna",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431153,
  },
  {
    postOffice: "Takalsingi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "Takarwan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Takli Amiya",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Takli",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Talkhed",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Talnewadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Talwada",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431127,
  },
  {
    postOffice: "Tambarajuri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Tambwa",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Telgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431131,
  },
  {
    postOffice: "Therla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Tintarwani",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Tokwadi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Ujani",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431519,
  },
  {
    postOffice: "Umapur",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431130,
  },
  {
    postOffice: "Umri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Undri",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Upali",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Vaidyakinhi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Vida",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Vssk Pangri",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431530,
  },
  {
    postOffice: "Wadgaon Daula",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Wadwani",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431144,
  },
  {
    postOffice: "Waghebabhulgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431126,
  },
  {
    postOffice: "Waghira",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414204,
  },
  {
    postOffice: "Waghluj",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Waghora",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Wahegaon Amla",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Wahira",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414202,
  },
  {
    postOffice: "Wangi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431128,
  },
  {
    postOffice: "Warapgoan",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Warni",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Warola",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431129,
  },
  {
    postOffice: "Warwati",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Welturi",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 414203,
  },
  {
    postOffice: "YCC",
    Type: "S.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Yelda",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Yellamb Bhrahmanath",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 413249,
  },
  {
    postOffice: "Yellambghat",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431125,
  },
  {
    postOffice: "Yeota",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431123,
  },
  {
    postOffice: "Yusuf Wadgaon",
    Type: "B.O",
    District: "Beed",
    State: "MAHARASHTRA",
    Pincode: 431517,
  },
  {
    postOffice: "Adyar    Bhandara",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Akot",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Alesure",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Amgaon Adarsh Pauni",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Amgaon BK",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Amgaon Dighori",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Andhalgaon",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441914,
  },
  {
    postOffice: "Asgaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Ashti",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Bampewada",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Bapera",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441915,
  },
  {
    postOffice: "Barwa",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441805,
  },
  {
    postOffice: "Bela",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441906,
  },
  {
    postOffice: "Belati",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Betara",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Bhagdi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Bhandara",
    Type: "H.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441904,
  },
  {
    postOffice: "Bhandara Of",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441906,
  },
  {
    postOffice: "Bhandara Road",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Bhandara Town",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441904,
  },
  {
    postOffice: "Bhawad",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Bhendala",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Bhugaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Bhuyar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Chandori",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Chandori",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Channa",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Chikhala",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Chikhla Basti",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Chinchal",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Chincholi  Tumsar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Chulhad",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441915,
  },
  {
    postOffice: "Dawadipar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Devada Bzk",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441913,
  },
  {
    postOffice: "Dhargaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Dighori",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441805,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441909,
  },
  {
    postOffice: "Dongri BK",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Erli Khandar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Gadegaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Garra Bageda",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Gobarwahi",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Gosi BK",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Gunthara",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Gurdha",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Hardoli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441909,
  },
  {
    postOffice: "Hardoli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441805,
  },
  {
    postOffice: "Irli BK",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Itan",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441914,
  },
  {
    postOffice: "Kanalgaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441909,
  },
  {
    postOffice: "Kandri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441914,
  },
  {
    postOffice: "Kanhalgaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Kanheri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Kardha",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Kardi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Kesalwada",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Khapa",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Khapri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Kharashi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441805,
  },
  {
    postOffice: "Kitadi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Kodurli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Koka",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Kolari",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Konda Kosra",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Kothurna",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Kudegaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Kumbhali",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Kurza",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Lakhandur",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Lakhni",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Lakhori",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Lawri Umri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Lendezari",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Lobhi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Madgi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441913,
  },
  {
    postOffice: "Malutola",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Mandesar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441909,
  },
  {
    postOffice: "Mandvi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Manegaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Mangli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Masal",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Matora",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Minshi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Miregaon",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Mitewani",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Mohadi",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441909,
  },
  {
    postOffice: "Moharna",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Mohdura",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441906,
  },
  {
    postOffice: "Mohgaon Devi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Mohgaon Khadan",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441915,
  },
  {
    postOffice: "Mundri BK",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Murmadi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Nakadongri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441907,
  },
  {
    postOffice: "Navegaon BK",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Neri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Nerla",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Opera",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Pahela",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Palandur Chauras",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Palora",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441905,
  },
  {
    postOffice: "Palora",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441805,
  },
  {
    postOffice: "Pauni",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Pimpalgaon Kohli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Pimpalgaon Nipani",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441903,
  },
  {
    postOffice: "Pimpalgaon Sadak",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Pindkepar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Pipri",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441906,
  },
  {
    postOffice: "Pohra",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Rengepar",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Sakoli",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Salaikhurd",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441914,
  },
  {
    postOffice: "Salebhata",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441804,
  },
  {
    postOffice: "Sangadi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Sarandi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441908,
  },
  {
    postOffice: "Sasra",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Satalwada",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Sawarla",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Seloti",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Sendurwafa",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Shahpur",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441906,
  },
  {
    postOffice: "Shioni",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441809,
  },
  {
    postOffice: "Sihora    Bhandara",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441915,
  },
  {
    postOffice: "Silli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441924,
  },
  {
    postOffice: "Soni Chapral",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441803,
  },
  {
    postOffice: "Thana Petrol Pump",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441906,
  },
  {
    postOffice: "Tumsar Road",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441913,
  },
  {
    postOffice: "Tumsar",
    Type: "S.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Umarwada",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Virshi",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Wadad",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Wahani",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441915,
  },
  {
    postOffice: "Walni",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441910,
  },
  {
    postOffice: "Yekodi  Sakoli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441802,
  },
  {
    postOffice: "Yerli",
    Type: "B.O",
    District: "Bhandara",
    State: "MAHARASHTRA",
    Pincode: 441912,
  },
  {
    postOffice: "Adgaon Raja",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Adol BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Adsul",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Advihir",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Alampur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Aland",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Alsana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Ambashi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Ambetakli",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Amboda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Amdapur",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Ancharwadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Andhera",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Anjani",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Anjani Khurd",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Antraj",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Antri Deshmukh",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Antri Khedekar",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Aregaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Asalgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Atali",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Awar",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Awha Yunuspur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Banoda Eklara",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444204,
  },
  {
    postOffice: "Bawanbir",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444204,
  },
  {
    postOffice: "Belad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Belgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Bhadgani",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Bhadgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Bhadola",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Bhajisath Nandura",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Bhalegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Bhalegaon Bazar",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Bharosa",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Bhenwad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Bhongaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Bhosa",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Bhota",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Bhumrala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Bibi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Bodkha",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444202,
  },
  {
    postOffice: "Borakhedi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Borakhedi Bawra",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Borgaon Kakde",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Bori Adgaon",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Borjawala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Botha Kazi",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Bramhapuri",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Buldana",
    Type: "H.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443001,
  },
  {
    postOffice: "Chaigaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Chaitanya Wadi",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Chandol",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Chandur Biswa",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443401,
  },
  {
    postOffice: "Changephal",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Chaora",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Chikhli    Buldhana",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Chincholi Bore",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Chincholi Karfarma",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Chinchpur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Chitoda",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Cotton Market Chikhli",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Dabhadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Dadulgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Dahid",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Dahigaon M",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Daregaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Dasarkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Datala",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Dawargaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Deodhaba",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Deulgaon Dhangar",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Deulgaon Kol",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Deulgaon Mahi",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Deulgaon Mali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Deulgaon Raja",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Deulgaon Sakharsha",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Deulghat",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Dhad",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Dhalsawangi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Dhamangaon Badhe",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443104,
  },
  {
    postOffice: "Dhanora Mahasidha",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Dhanora Vitali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443401,
  },
  {
    postOffice: "Dharangaon    Buldhana",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Dhorapgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Dhotra Bhangoji",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Dhotra Naik",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Dighi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443401,
  },
  {
    postOffice: "Digras BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Dongaon",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Dongar Khandala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Dongarshewali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Dudha",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Dudhalgaon BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Dusarbid",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Eklara",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "G.S.College Khamgaon",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Gadegaon Khurd",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Gandhari",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Ganeshpur",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Gangalgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Garadgaon",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Gawhan",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Ghanegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Gharod",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Ghatbori",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Ghirni",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Godri",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Gohogaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Gondhanapur",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Gummi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Gunj",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Harankhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Harsoda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Hatedi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Hatni",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Hingna Karegaon",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Hirdao",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Hiwara BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Hiwara Khurd",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Hiwarkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Hiwarkhed",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Isoli",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Isrul",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Jaipur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Jalamb",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Jalgaon",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Jalka Bhadang",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Jambhora",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Jambhul",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Jambhuldhaba",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Jamod",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Janephal",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Janori Nipana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Jastgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444201,
  },
  {
    postOffice: "Jawala BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Jigaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443401,
  },
  {
    postOffice: "Kalambeshwar",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Kalegaon",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Kalyana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Kankanwada",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444202,
  },
  {
    postOffice: "Karwand",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Katel Kolad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444204,
  },
  {
    postOffice: "Kavthal",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444201,
  },
  {
    postOffice: "Kawhala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Kelanagar",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Kelwad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Kesapur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Khaira",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Khalegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Khallad Gavhan",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Khamgaon",
    Type: "H.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Khamkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Khandala Makardhwaj",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Khandvi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Kharbadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Kherda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Kherda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Khumgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Kingaon Jattu",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Kingaon Raja",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Kinhi Mahadeo",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Kinhola",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Kodri",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444201,
  },
  {
    postOffice: "Kolara",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Kolwad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Korhala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443104,
  },
  {
    postOffice: "Kothali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Ladnapur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444204,
  },
  {
    postOffice: "Lakhanwada",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Lanjud",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Lasura Khurd",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Lawhala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Liha BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Lokhanda",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Lonar",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Loni Gaoli",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Loni Gurao",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "M.I.D.C.  Khamgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Madakhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Madh",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Makodi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Malkapur City",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Malkapur Pangra",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Malkapur    Buldhana",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Mamulwadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443401,
  },
  {
    postOffice: "Manasgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Mangrul Navghare",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Masrul",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Matergaon",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444304,
  },
  {
    postOffice: "Mehkar",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Mehuna",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Mendgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Mendhali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Mera BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Mera Khurd",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Mhaiswadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Mhasla BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Mola",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Mominabad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443401,
  },
  {
    postOffice: "Motala",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Nagzari",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Naigaon Duttapur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Nalgangapur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Nandra",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Nandra Koli",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Nandura",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Nandura Town",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Narkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Narvel",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Nimgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Nimkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Nipana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Nipana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Niwana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Padali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Pahurjira",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Palaskhed Daulat",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Palshi BK",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Palsi Supo",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Palsi Zanshi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444202,
  },
  {
    postOffice: "Pangarkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Pangra Dole",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Pangri",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Panhera",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443104,
  },
  {
    postOffice: "Panhera Wadoda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Parkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Paturda",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444201,
  },
  {
    postOffice: "Peth",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Pimpalgaon BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Pimpalgaon Devi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Pimpalgaon Kale",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443403,
  },
  {
    postOffice: "Pimpalgaon Nath",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Pimpalgaon Raja",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Pimpalgaon Sarai",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Pimpalgaon Unda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Pimpri Deshmukh",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Pimpri Gaoli",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Pimpri Gawali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Pimpri Khandare",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Pimpri Mali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Pophali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Poraj",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444306,
  },
  {
    postOffice: "Raheri BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Raipur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Rajegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Rajur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Rohana",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Rohinkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Rokdiya Nagar",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Rudhana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Sagoda",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Sagwan",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Sakegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Sakhali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Sakhar Kherda",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Sangrampur    Buldhana",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444202,
  },
  {
    postOffice: "Sao",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Saokhed Bhoi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Saokhed Tejan",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Sarafa Khamgaon",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Saramba",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Sarola Maroti",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Sarshiv",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Satgaon Bhusari",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Satgaon Mhasla",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Savkhed Nagare",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Sawadad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Sawana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Sawangi Tekale",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443206,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Sawargaon Dukare",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Shahapur",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Shara",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Shegaon",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Shelapur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Shelgaon Atol",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443201,
  },
  {
    postOffice: "Shelgaon Bazar",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Shelgaon Deshmukh",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Shelgaon Mukund",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Shelsur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Shemba",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Shendla",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Shendurjan",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Shioni Pisa",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Shioni Taka",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Shiradhon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Shirala Nemane",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Shirpur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Sindi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Sindkhed Lapali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Sindkhed Raja",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Singaon Jahagir",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Somthana",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Sonala",
    Type: "S.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444204,
  },
  {
    postOffice: "Sonati",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Sonoshi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Sultanpur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Sundarkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Sungaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Sutala BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444312,
  },
  {
    postOffice: "Tadshioni",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Tadulwadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Takarkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443103,
  },
  {
    postOffice: "Takarkhed Helga",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Takli Viro",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Talkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Talni",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Tambola",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Tembhurna",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Tivtivi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Toranwada",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444301,
  },
  {
    postOffice: "Tuljapur",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Tunki",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444204,
  },
  {
    postOffice: "Ukali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443301,
  },
  {
    postOffice: "Umali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Umbarkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443204,
  },
  {
    postOffice: "Umrad",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443203,
  },
  {
    postOffice: "Undri",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Uti",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Veni",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Vihigaon",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Vishvi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443303,
  },
  {
    postOffice: "Vivara",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Wadaji",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Wadgaon Mali",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Wadgaon Tejan",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Wadhao",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443302,
  },
  {
    postOffice: "Wadi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Wadner Bholaji",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443101,
  },
  {
    postOffice: "Wadshingi",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443402,
  },
  {
    postOffice: "Waghala",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Wankhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444201,
  },
  {
    postOffice: "Wardadi BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443308,
  },
  {
    postOffice: "Warkhed",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443102,
  },
  {
    postOffice: "Warna",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Warud",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443106,
  },
  {
    postOffice: "Warwand",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443304,
  },
  {
    postOffice: "Warwand",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Warwat Bakal",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444202,
  },
  {
    postOffice: "Warwat Khanderao",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444201,
  },
  {
    postOffice: "Wasadi BK",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Wazar",
    Type: "B.O directly a/w Head Office",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444303,
  },
  {
    postOffice: "Yelgaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443002,
  },
  {
    postOffice: "Yerli",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443404,
  },
  {
    postOffice: "Zadegaon",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 444203,
  },
  {
    postOffice: "Zodga",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443112,
  },
  {
    postOffice: "Zotinga",
    Type: "B.O",
    District: "Buldhana",
    State: "MAHARASHTRA",
    Pincode: 443202,
  },
  {
    postOffice: "Adegaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Adyal Tekadi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Aheri",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Aksapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Alwahi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Amadi Beguide",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Ambenri",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Amgaon Buti",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Anandwan",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442914,
  },
  {
    postOffice: "Antargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Antargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Antargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442917,
  },
  {
    postOffice: "Arher Nawargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Arjuni",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Arvi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Ashta",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Awalapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442917,
  },
  {
    postOffice: "Awalgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Awalpur Cement Project",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442917,
  },
  {
    postOffice: "Babupeth",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442403,
  },
  {
    postOffice: "Bakhardi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Balapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Ballarpur Paper Mill",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442901,
  },
  {
    postOffice: "Ballarpur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Baradkinhi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Belgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Bembal",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Bhadrawati",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Bhari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Bhatala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442914,
  },
  {
    postOffice: "Bhedoda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Bhejgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Bhendala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Bhisi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Bhoygaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Bond",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Borda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Bormala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Bothali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Bothali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Bramhapuri",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Cementnagar",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442502,
  },
  {
    postOffice: "Chakdarur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Chandankheda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Chandanwahi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Chandrapur City",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442402,
  },
  {
    postOffice: "Chandrapur",
    Type: "H.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442401,
  },
  {
    postOffice: "Chandrapur Ord Fact",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442501,
  },
  {
    postOffice: "Chandrapur TPS",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442404,
  },
  {
    postOffice: "Chandur    Chandrapur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Chargaon BK",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Chargaon KD",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Charli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Charur Khati",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Chaugaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Chichpalli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Chikani",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441212,
  },
  {
    postOffice: "Chikhli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Chimda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Chimur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Chinchala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Chinora",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442914,
  },
  {
    postOffice: "Chintaldhaba",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Chiroli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Chora",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Chunala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Dabgaon Maktha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442918,
  },
  {
    postOffice: "Dahegaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Dewada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Dewada Khurd",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442918,
  },
  {
    postOffice: "Dhaba",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Dhanoli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Dhanora Pipri",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442505,
  },
  {
    postOffice: "Dighori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Doma",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441212,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Dongargaon BK",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Dongargaon Khadi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Dudholi Bamini",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Durgapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442404,
  },
  {
    postOffice: "Ekarjuna",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Fiskuti",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Futana",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Gadbori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Gangalwadi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Gewara BK",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Gewardha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Ghatkul",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Ghodpeth",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Ghugus",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442505,
  },
  {
    postOffice: "Ghugus Colliery",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442505,
  },
  {
    postOffice: "Girgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Gogaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Gojoli Maktha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Gondpipri",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Govindpur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Gowardhan",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Gowari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Gramsevak Trg",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Gulgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Gunjewahi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "H.L.C.",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442507,
  },
  {
    postOffice: "Halda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Haramba",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Hardoli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Hirapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Jambhulghat",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Jamgaon Komti",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Jamni",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Jamsala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Jamtukam",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442918,
  },
  {
    postOffice: "Janampalli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Jena",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Jibgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Jiwati",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Jugnala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Junona",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442403,
  },
  {
    postOffice: "Kacharala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Kadholi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Kadholi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Kahali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Kajalsar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Kalamgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Kalamgaon Ganna",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Kanhalgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Kanhalgaon Sonule",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Kanpa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Kapsi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Karanji",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Kasargatta",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442918,
  },
  {
    postOffice: "Kawathi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Kelzar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442918,
  },
  {
    postOffice: "Keroda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Kewada Mokasa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Khadsangi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Khambada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Khambada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Khatgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Khed",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Khemjai",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442914,
  },
  {
    postOffice: "Khirdi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Khutala Mokasa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Kinhi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Kinhi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Kirmitimendha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Kitali Bormal",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Kodsi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Kokdi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Kolara Tukum",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Kolsa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Kondali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Kondha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442503,
  },
  {
    postOffice: "Koregaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Korpana",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Kosarsar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Kotgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Kothada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Kothari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Kuchana",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442503,
  },
  {
    postOffice: "Kurud",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Kurumwelli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442710,
  },
  {
    postOffice: "Kurza",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Ladbori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Lakhadkot",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Lakhampur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Lathi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Likithwada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Londholi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Madhali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Madnapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Mahalgaon Kalu",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Malewada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Mandwa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Mangalgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Mangali Arab",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Manora",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Marda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442402,
  },
  {
    postOffice: "Maroda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Masal",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Matha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Maushi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Meha BK",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Mel",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442402,
  },
  {
    postOffice: "Mendki",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Metepar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Mhasali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "MIDC  P",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442406,
  },
  {
    postOffice: "Mindhala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Mohali Mokasa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Mohali Naleshwar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Mohorli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442404,
  },
  {
    postOffice: "Mokhala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Motegaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Mudholi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Mudza",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Mul",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Nachanbhatti",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Nagbhid",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Nagri",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Nalfadi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Nanda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442917,
  },
  {
    postOffice: "Nanded",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Nandgaon Podey",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442507,
  },
  {
    postOffice: "Nandori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Nandpa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Nanhori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Naranda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Navegaon Hundeshwari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Navegaon Lonkhairi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441212,
  },
  {
    postOffice: "Navegaon Pandav",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Navkhala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Navtala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Nawargaon",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Nawegaon More",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Neri",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Nifandra",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Nilaj",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Nilsani Pedgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Nimgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Nokewada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Pachgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Padmapur Rayatwari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442404,
  },
  {
    postOffice: "Padoli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442406,
  },
  {
    postOffice: "Paharni",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Palasgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Palasgaon Jat",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Palasgaon Khurd",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Palasgaon Piparda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Palebarsa",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Pandharpauni",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Panjurni",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Paradgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Patala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442503,
  },
  {
    postOffice: "Patan",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Pathanpura",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442402,
  },
  {
    postOffice: "Pathari    Chandrapur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Payali Bhatali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442404,
  },
  {
    postOffice: "Pedgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441212,
  },
  {
    postOffice: "Pendhari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Pendhari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Pendhari Maktha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Pimpalneri",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Pipri  Naranda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Pirli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Pombhurna",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442918,
  },
  {
    postOffice: "Pudiyal Mohada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Rajgad",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Rajoli    Chandrapur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441212,
  },
  {
    postOffice: "Rajura  M",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Ratnapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Rupapet",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Sagra",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Sakhara Rajapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Sakhari",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Sakharwahi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Salori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442914,
  },
  {
    postOffice: "Samda Buzruk",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Saoli",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Saori",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Saradpar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441212,
  },
  {
    postOffice: "Sasti",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Sathgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Sawangi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Segaon KD",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Segaon    Chandrapur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Sembal",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Serja",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Shankarpur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Shashtrinagar",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442401,
  },
  {
    postOffice: "Shengaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Shengaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442505,
  },
  {
    postOffice: "Sheoni",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Shiora",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Shivjinagar",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442503,
  },
  {
    postOffice: "Sindewahi",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Sindhi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Sirpur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Soit",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Somnath Prakala",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441224,
  },
  {
    postOffice: "Sonapur Deshpande",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Sondo",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Sonurli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Sonurli BK",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Subai",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Sumthana",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442902,
  },
  {
    postOffice: "Surla",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441226,
  },
  {
    postOffice: "Tadali",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442406,
  },
  {
    postOffice: "Talodhi Balapur",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Talodhi Bhangaram",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Talodhi Naik",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Tambegadimendha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Tekamandava",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Tembhurda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Tembhuwahi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Tohogaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Umari Potdar",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Uparwahi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Ushral Mendha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Vansadi",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Vihirgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Vihirgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Vihirgaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441215,
  },
  {
    postOffice: "Vilam",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441205,
  },
  {
    postOffice: "Viloda",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Vir R.S.",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Virur Gadegaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442917,
  },
  {
    postOffice: "Visapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442701,
  },
  {
    postOffice: "Visora",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Vithalwada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Vyahad BK",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Vyhad Khurd",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441225,
  },
  {
    postOffice: "Wadadha",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Wadegaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Wadholi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Wadhona",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441221,
  },
  {
    postOffice: "Wadkuli",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442702,
  },
  {
    postOffice: "Wadsi",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442904,
  },
  {
    postOffice: "Waghnakh",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Wahangaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Waigaon",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441206,
  },
  {
    postOffice: "Waigaon Tukum",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442906,
  },
  {
    postOffice: "Waneri",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441222,
  },
  {
    postOffice: "Warora",
    Type: "S.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442907,
  },
  {
    postOffice: "Warud Road",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Wasera",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 441223,
  },
  {
    postOffice: "Yellapur",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442908,
  },
  {
    postOffice: "Yergavhan",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442916,
  },
  {
    postOffice: "Yergawhan  Dewada",
    Type: "B.O",
    District: "Chandrapur",
    State: "MAHARASHTRA",
    Pincode: 442905,
  },
  {
    postOffice: "Agriculture College",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424004,
  },
  {
    postOffice: "Ahilyapur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Aichale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Ajande BK",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425403,
  },
  {
    postOffice: "Ajande KH",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425404,
  },
  {
    postOffice: "Ajang",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424301,
  },
  {
    postOffice: "Akhade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Ambe",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Ambode",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Amkhel",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Amrale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Arthe",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Arvi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424006,
  },
  {
    postOffice: "Ashtane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Babhalaj",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Babhulvadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Balde",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Balhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Balkuwa",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Balsane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Bamhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Behed",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Betawad",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425403,
  },
  {
    postOffice: "Bhadane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424303,
  },
  {
    postOffice: "Bhadne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Bhadne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Bhamer",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Bhamte",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Bhatpure",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Bhorkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Bodgaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Boradi",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Boris",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Borkund",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Borvihir",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Brahamanvel",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Burzad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Chadvel",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Chaugaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Chaupale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Chhail",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Chikase",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Chilane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Chimthana",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Chinchkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424301,
  },
  {
    postOffice: "Chinchkhede",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Chinchwar",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Chinoda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Dabhashi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Dahivad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Dahivel",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Dangshirvade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Dangurne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Datarti",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Daul",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Dayane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Deobhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Deour-bk",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Deshshirwade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Devjipada",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Dhadne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Dhadre",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424006,
  },
  {
    postOffice: "Dhamane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Dhamnar",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Dhandarne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Dhanur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Dhule Chaini Road",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424001,
  },
  {
    postOffice: "Dhule City",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424001,
  },
  {
    postOffice: "Dhule Collectorate",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424001,
  },
  {
    postOffice: "Dhule Deopur",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Dhule",
    Type: "H.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424001,
  },
  {
    postOffice: "Dhule Jaihind Colony",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Dhule Market Yard",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424004,
  },
  {
    postOffice: "Dhule Midc",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424006,
  },
  {
    postOffice: "Dhule Pramod Nagar",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Dhule Station Road",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424001,
  },
  {
    postOffice: "Dhule Vidya Nagari",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424005,
  },
  {
    postOffice: "Dighave",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Divi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Dondaicha",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Dondaicha-town",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Dusane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Fagana",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424301,
  },
  {
    postOffice: "Ganeshpur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Gartad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Ghodade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Gidhade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Gondur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Gorane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424309,
  },
  {
    postOffice: "Hatti-kh",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Hendrun",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Hisale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Hol",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Holnantha",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Indave",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Jakhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Jatode",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Jebapur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Jogshelu",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Junawane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Kadane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Kakani",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Kalambhir",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Kalkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424301,
  },
  {
    postOffice: "Kamkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425404,
  },
  {
    postOffice: "Kapadna",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Karle",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Karvand",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Kasara    Dhule",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Katharde Digar",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425422,
  },
  {
    postOffice: "Kavthe",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Khalane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Khambale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Khamkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Khandalai",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424303,
  },
  {
    postOffice: "Kharadbari",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Kharde-BK",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Kharde-KH",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Kheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Khori",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Khudane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Kodade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Kodid",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Kudashi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Kumar Nagar",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Kurkwade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Kusumba",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Lakdya-hanuman",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Laling",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424006,
  },
  {
    postOffice: "Lamkani",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Lauki",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Lonkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Lonkhedi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424303,
  },
  {
    postOffice: "Mahalpur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Maikatar",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Malpur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Malpur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Mandi-shirpur",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Manjrod",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Mauje-chadvel",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Mehergaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Methi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Mhasale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Mhasdi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Modalpada",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Moghan",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Mohadi Laling",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Mohadi-dangri",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Morane-laling",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Morane-pner",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Mordad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Mudawad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425403,
  },
  {
    postOffice: "Mukti",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424301,
  },
  {
    postOffice: "Nadse",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Nagaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424005,
  },
  {
    postOffice: "Nakane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Nala",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Nalgavhan",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Nandale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Nandane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Nandre",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Nardana",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425404,
  },
  {
    postOffice: "Narvhal",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Naval Nagar",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Navapada",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Navara-navari",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Ner",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424303,
  },
  {
    postOffice: "Nihali",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Nihalod",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Nimdale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Nimgul",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Nimgul",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Nizampur    Dhule",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Old Dhule",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Padalda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Padhavad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425403,
  },
  {
    postOffice: "Palasner",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Pankheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Pashta",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425404,
  },
  {
    postOffice: "Patan",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Perejpur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Phohare",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Piloda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Pimpalenr",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Pimparkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424309,
  },
  {
    postOffice: "Pinjarzadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Pratappur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Purmepada",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424006,
  },
  {
    postOffice: "Rainpada",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Rajale",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Rami",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424307,
  },
  {
    postOffice: "Rami",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Ramwadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Ranjane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Rohane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Rohini",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Rohod",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Runmaili",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "S.P. Bhadne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Sadgaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424006,
  },
  {
    postOffice: "Sakri  Dhule",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Salve",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Samode",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Sangvi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Satarne",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Savaimukti",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Savalde",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Shanimandal",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Shendwad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Shenpur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Shevade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Shevge",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Shewali",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Shindkheda",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Shirdane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Shirdhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424303,
  },
  {
    postOffice: "Shirpur",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Shirsole",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Shirud",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424308,
  },
  {
    postOffice: "Shivre",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Sonewadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Songir",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424309,
  },
  {
    postOffice: "Sukapur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Sukwad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Sule",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Suray",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Talvade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Tamasvadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Tamthare",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425407,
  },
  {
    postOffice: "Tarhadi T.T.",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Tarhadkasbe",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Tavkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Tekvade",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Tembhe",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Tembhe",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Thalner",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Tikhi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Titane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Tonde",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Udane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424302,
  },
  {
    postOffice: "Umarde",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Umarpatta",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Umbharti",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Umtavad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Uppr Shirpur",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Vadjai",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Vaijali",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425422,
  },
  {
    postOffice: "Vaindane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Valvadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Vani",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Vardhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Varkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424301,
  },
  {
    postOffice: "Varpada",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Varshi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425404,
  },
  {
    postOffice: "Varud",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425404,
  },
  {
    postOffice: "Varul",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Varul",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Varzadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Vaskhedi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Vasmar",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424304,
  },
  {
    postOffice: "Vehergaon",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424305,
  },
  {
    postOffice: "Velhane",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Vikharan  Shirpur",
    Type: "S.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425427,
  },
  {
    postOffice: "Vikhran",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425408,
  },
  {
    postOffice: "Vinchur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424311,
  },
  {
    postOffice: "Virdel",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425406,
  },
  {
    postOffice: "Vishvanath",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424318,
  },
  {
    postOffice: "Vitai",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424310,
  },
  {
    postOffice: "Wadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Waghadi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Waghadi-bk",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424309,
  },
  {
    postOffice: "Wakvad",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Walkheda",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424309,
  },
  {
    postOffice: "War",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424002,
  },
  {
    postOffice: "Warsa",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424306,
  },
  {
    postOffice: "Wasardi",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425405,
  },
  {
    postOffice: "Wathode",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425421,
  },
  {
    postOffice: "Waypur",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 424309,
  },
  {
    postOffice: "Zende-anjan",
    Type: "B.O",
    District: "Dhule",
    State: "MAHARASHTRA",
    Pincode: 425428,
  },
  {
    postOffice: "Adapalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Aheri",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Allapalli",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442703,
  },
  {
    postOffice: "Ambeshioni",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Amboli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442903,
  },
  {
    postOffice: "Amgaon  Mal",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Amirza",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Andhali",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Angara",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Ankhoda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Ankisa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Arda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Armori",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Armurkasa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Arsoda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Asaralli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Ashti    Gadchiroli",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Bamni",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Bamni",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Bedgaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Bethakathi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Bhadbhidi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Bhakrondi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Bhamragadh",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442710,
  },
  {
    postOffice: "Bhendala",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Bodhali",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Bori",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Botekasa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Burgi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Chakpetala",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Challewada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Chamorshi",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Chandagad",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Chandankhedi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Chapalwada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Charbhatti",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Chatgaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Chaudampalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Chavela",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Chittaranjan",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Churchura",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Churmura",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Dampur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Dechalipeta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Delanwadi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Deloda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Desaiganj",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441207,
  },
  {
    postOffice: "Deulgaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Devalmari",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Dhamrancha",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Dhanora",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Dudhmala",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Elgur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442703,
  },
  {
    postOffice: "Ettapalli",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Gadchiroli Coll",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Gadchiroli",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Ganpur Rayatwari",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Gatta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Gatta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Geda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Ghati",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Ghot",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Ghotsur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Gilgaon zamindari",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Gothangaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Govindpur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442703,
  },
  {
    postOffice: "Gudigudam",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Gundapalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Guruwala",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Heti",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Indaram",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Jairampur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Jambhali",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Jarawandi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Jepra",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Jimalgatta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Jinganur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Jivangatta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Jogisakhara",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Kadholi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Kalinagar",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Kamlapur    Gadchiroli",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Kandoli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442710,
  },
  {
    postOffice: "Kaneri",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Karwafa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Kasansur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Khamancheru",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Khudirampalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Konsari",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Koparalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Korchi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Korla Mal",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Kotgul",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Kotra",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Kunghada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Kurkheda",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Kurud",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Kurundi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Lagam",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Lakhamapur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Mahagaon BK",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Makepalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Malewada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Manapur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Manne Rajaram",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442710,
  },
  {
    postOffice: "Markanda Deo",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Markhanda  K",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Marpalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Masali",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Maushi Khamb",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Medpalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442703,
  },
  {
    postOffice: "Mendhatola",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Mohali",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Moyabinpetta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Mudholi Chak No. Ii",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Mudza",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Mulchera",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Muramgaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Murkhada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Murkhada Chak",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Murmadi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Murmuri",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Nagram",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Nagrinavegaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Nanhi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Narayanpur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Navegaon",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Navegaon Rayatwari",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Palasgad",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Pardikupi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Pavimuranda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Permelli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442710,
  },
  {
    postOffice: "Porla",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Potegaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Purada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Rajaram",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442709,
  },
  {
    postOffice: "Ramgarh",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Rangaapalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Rangi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Regadi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Regunta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Sakhara",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Sawalkheda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Shioni BK",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Sironcha Mal",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Sironcha",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Sirshi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Sonapur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Sonsari",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Sukala",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Sursundi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Tadgaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442710,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Talodhi Mokasa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Tekada",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442504,
  },
  {
    postOffice: "Thakri",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Thanegaon",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Todsa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Udera",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442704,
  },
  {
    postOffice: "Vairagadh",
    Type: "S.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441217,
  },
  {
    postOffice: "Venkatraopetta",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442705,
  },
  {
    postOffice: "Vikaspalli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442604,
  },
  {
    postOffice: "Vishnupur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Wadadha",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Wagholi",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Walsara",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442603,
  },
  {
    postOffice: "Wasa",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Wasala",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441208,
  },
  {
    postOffice: "Yenapur",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442707,
  },
  {
    postOffice: "Yengalkheda",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 441209,
  },
  {
    postOffice: "Yeoli",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442605,
  },
  {
    postOffice: "Yerkad",
    Type: "B.O",
    District: "Gadchiroli",
    State: "MAHARASHTRA",
    Pincode: 442606,
  },
  {
    postOffice: "Adasi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Alewada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Amgaon",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Anjora",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Arjuni  Tirora",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Arjuni Morgaon",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Asoli",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Bakti",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Bamni",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Banathar",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Barabhati",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Batana",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Berdipar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Bhasgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Bhivkhidki",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Bijepar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Boda",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Bondegaon  Surban",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Bondgaon Devi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Bopabodi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Borkanhar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Channa",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Chichgarh",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Chipiya",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Chirchal Bandh",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Chopa",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Chutiya",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Dandegaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Darekasa",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Dasgaon Khurd",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Datora",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Dawaniwada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Dawki",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Dawwa",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Deori    Gondia",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Dhabetekdi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Dhakni",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Dhanoli",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Dhapewada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Dongargaon Sadak",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Etikheda",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Futana",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Gangazari",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Gangla",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Gankhaira",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Garra",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Ghonadi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Ghoti",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Gidhadi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Girola",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Gond Mohadi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Gondia Bazar",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Gondia City",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Gondia",
    Type: "H.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Gondia Khurd",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Gondumri",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Goregaon    Gondia",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Gothangaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Gumdhawada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Hirapur",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Hirdamali",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Indora Khurd",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Kachewani",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Kadikasa",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Kalimati",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Kalimati",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Kamtha",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Karanja",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Karti BK",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Katangi BK",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Kati  Birsola",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Kattipar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Kawalewada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Kawarabandh",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Kawlewada",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Kesalwada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Kesori",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Khajari Dongargaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Khamari",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Khatiya",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Khodseoni",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Kodelohara",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Kokna",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Kokodi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Korambitola",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Kosamthondi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Kotra",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Kudwa",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Kurhadi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Lakhegaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Latori",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Lohara",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Lohara",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Makkatola",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Mohadi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Mohgaon BK",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Mohgaon R.S.",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Mundikota",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Mundipar  Ishwar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Mundipar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Murdada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Murdoli",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Nagpura",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Nagra",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Nagrakatangi",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Navegaon Bandh",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Navegaon Kalan",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Navezari",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Nilaj",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Palandur Zamindari",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Palasgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Palasgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Pandharbodi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Pandhari",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Pandharwani",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Parastola",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Paraswada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Paraswada",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Parsodi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Parsodi Rayatwadi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Pathri  Gondia",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Pauldauna",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Phukimeta",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Phulchur",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Pimpalgaon Khambi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Pipariya",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Pratapgarh",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Purada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Rajegaon",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Rajori  Bharnoli",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Ratnara",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Rawanwadi",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Sadak Arjuni",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Salekasa",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Saoli Dongargaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Sarandi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Sarkartola",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Satgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Satona",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Sawri",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Shejgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Shenda",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Shirpurbagh Colony",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441901,
  },
  {
    postOffice: "Sitepar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Soni",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Sonpuri",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Soundad",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441806,
  },
  {
    postOffice: "Sukhali Dakaram",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Suplipar",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Tanda",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Tedha",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Thana",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Thanegaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Tigaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441902,
  },
  {
    postOffice: "Tilli Mohgaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441807,
  },
  {
    postOffice: "Timezari",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441801,
  },
  {
    postOffice: "Tirkhedi",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441916,
  },
  {
    postOffice: "Tirora",
    Type: "S.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Tukum Narayan",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441702,
  },
  {
    postOffice: "Tumkheda Khurd",
    Type: "B.O directly a/w Head Office",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441601,
  },
  {
    postOffice: "Wadegaon",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441911,
  },
  {
    postOffice: "Wadegaon R.S.",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Yekodi  Gondia",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441614,
  },
  {
    postOffice: "Zarpada",
    Type: "B.O",
    District: "Gondia",
    State: "MAHARASHTRA",
    Pincode: 441701,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Adol",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Ajegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Akhada Balapur",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Amba",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Aral",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Aundha Nagnath",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Babhali",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Babhulgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Basamba",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Basmathnagar",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Basmathnagar Town",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Bhandegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Bhankheda",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Bhatsawangi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Bolda",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Borisawant",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Bour",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Chondi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Darefal",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Datala",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Daudgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Dhawanda Kasba",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Digras",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Dongargaon Pool",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Dongarkada",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Ganjapur",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Gaul",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Ghoda",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Girgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Gojegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Golegaon Camp",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Goregaon    Hingoli",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Gunda",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Hatala",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Hatta",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Hatta",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Hayatnagar",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Hingani",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Hingoli",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Jaipur",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Jawala Bazar",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Jawala BK",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Jawala Panchal",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Kadoli",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Kalamnuri",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Kandali",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Kanergaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Karanjala",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Kautha",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Kautha",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Kendra BK",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Khanapur Chitta",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Khandegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Kharwad",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Khudaj",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Kille Wadgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Kondhur",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Kurtadi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Kurunda",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Lakh",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Lohara BK",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Malhiwara",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Mangalwara Hingoli",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Masod",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Mazod",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Mssk Dongarkada",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Murtijapur Sawangi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Nahad",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Nandapur",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Narsi Namdev",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Paheni",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Palsi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Pangra Shinde",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Pankanhergaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Pardi BK",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Pedgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Phalegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Pimpaldari",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Potra",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Purjal",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Pusegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Rameshwar Tanda",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Sakhra",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Salna",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Salva",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Sandas",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Sapatgaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Sawad",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Sawana",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431703,
  },
  {
    postOffice: "Sawangi BK",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Sendursena",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Sengaon",
    Type: "S.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Shegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Shewala",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Shirad Shahapur",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Siddheshwar",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Siddheshwar Camp",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Sindagi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Sindagikhamba",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Sirli",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Sirsam BK",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431513,
  },
  {
    postOffice: "Sukli Veer",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Undegaon",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Wakodi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Wapti",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Waranga",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Warud Chakrapani",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431542,
  },
  {
    postOffice: "Yedsi",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Yehlegaon Solanke",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431705,
  },
  {
    postOffice: "Yelegaon  T",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431702,
  },
  {
    postOffice: "Yelegaon Gawali",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Yelki",
    Type: "B.O",
    District: "Hingoli",
    State: "MAHARASHTRA",
    Pincode: 431701,
  },
  {
    postOffice: "Abhoda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Achegaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Adavad",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425303,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Ahirwadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Ainpur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425507,
  },
  {
    postOffice: "Ajnad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Akulkheda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Amoda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425524,
  },
  {
    postOffice: "Anjale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425304,
  },
  {
    postOffice: "Anturli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Anwarde K H",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Atraval",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Balwadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425507,
  },
  {
    postOffice: "Bamnod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Betawad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Bhalod    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425304,
  },
  {
    postOffice: "Bhokari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Bhusawal Athawade Bazar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425201,
  },
  {
    postOffice: "Bhusawal",
    Type: "H.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425201,
  },
  {
    postOffice: "Bhusawal Kutchery",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425201,
  },
  {
    postOffice: "Bhusawal Ordnance Factory",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425203,
  },
  {
    postOffice: "Bhusawal Shivaji Nagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425201,
  },
  {
    postOffice: "Bidgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425303,
  },
  {
    postOffice: "Bodwad",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Borkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425304,
  },
  {
    postOffice: "Chahardi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Changdeo",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Charthana",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425327,
  },
  {
    postOffice: "Chikali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425304,
  },
  {
    postOffice: "Chinawal",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425505,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Chincholi Pimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424208,
  },
  {
    postOffice: "Chinkhol",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Chopda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Chorwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Chunchale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Chunchale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Dambhurni",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Dasnoor",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425506,
  },
  {
    postOffice: "Deepnagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425307,
  },
  {
    postOffice: "Deulgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424208,
  },
  {
    postOffice: "Devgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425303,
  },
  {
    postOffice: "Devpimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Dhamodi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425507,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425303,
  },
  {
    postOffice: "Dondwade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Duskheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425203,
  },
  {
    postOffice: "Engaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Faizpur",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Fattepur",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424208,
  },
  {
    postOffice: "Fekari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425307,
  },
  {
    postOffice: "Fulgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Ganpur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Garkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Gate",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425501,
  },
  {
    postOffice: "Ghadvel",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Ghankheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Ghodasgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Ghodgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Godri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424208,
  },
  {
    postOffice: "Gojore",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Gondedaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Gondkhed",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Gorgavale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Hambardi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Harankheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Hartala",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Hated",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Hingona",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Hiwarkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Jalgaon K H",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425309,
  },
  {
    postOffice: "Jamner",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Jamthi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Kahurkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425308,
  },
  {
    postOffice: "Kalamsare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Kandari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425203,
  },
  {
    postOffice: "Kanhale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425307,
  },
  {
    postOffice: "Kapuswadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Karkee",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Kasali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Kasba Pimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424208,
  },
  {
    postOffice: "Kathora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Kazipura",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Kekat Nimbhora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Khachane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Khadgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Khadke",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425307,
  },
  {
    postOffice: "Khadki",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Kherala",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Khirdi",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425507,
  },
  {
    postOffice: "Khiroda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Khirwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Kingaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Kinhi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Kochur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425502,
  },
  {
    postOffice: "Kolambe",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Kolhadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Korpavalli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Kothali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Kumbharkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425505,
  },
  {
    postOffice: "Kurha Hardo",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Kurha",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Kurha-kakoda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425327,
  },
  {
    postOffice: "Kurvel",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Kusumbe B K",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Lahasar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Lasur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Lihedigar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Lohare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Londhari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424207,
  },
  {
    postOffice: "M.S.S.K.Faizpur",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425524,
  },
  {
    postOffice: "Maldabhadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Malkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Mamalde",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Mandeve K H",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424207,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Manje Hingona",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Manur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Manvel",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Marul",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Maskawad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425502,
  },
  {
    postOffice: "Melane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Melsangve",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Mengaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Mohadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Moharala",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Mondhale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Morgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Moygaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Moykheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Muktainagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Muktal",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Nachankheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Nadgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Nagalwadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Nandra Haveli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Nashirabad",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425309,
  },
  {
    postOffice: "Neri BK",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Nhavi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425524,
  },
  {
    postOffice: "Nimbhora",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425506,
  },
  {
    postOffice: "Nimdya",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Nimgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425304,
  },
  {
    postOffice: "Nimgavhan",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Nimkhedi BK",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425327,
  },
  {
    postOffice: "Nimkhedi KH",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Old Muktainagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Padala",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Padalsa",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Pahur",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424205,
  },
  {
    postOffice: "PAL",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Palaskheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Palaskheda Mirache",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Paldhi Pahur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424205,
  },
  {
    postOffice: "Panchak",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425303,
  },
  {
    postOffice: "Parambi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425327,
  },
  {
    postOffice: "Patondi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Pimpalgaon K H",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Pimprala",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425327,
  },
  {
    postOffice: "Pimpri Nandu",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Punkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Raipur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425203,
  },
  {
    postOffice: "Ranjani",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Rasalpur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Raver",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Rotwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Rozoda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Ruikheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Sakali",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Sakari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425307,
  },
  {
    postOffice: "Sakegaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425201,
  },
  {
    postOffice: "Salshingi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Samrod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Sangvi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425304,
  },
  {
    postOffice: "Sanpule",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Satod Kolwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Satrasen",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Savda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425502,
  },
  {
    postOffice: "Savkheda B K",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Shahpur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Shelwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425310,
  },
  {
    postOffice: "Shendurni",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424204,
  },
  {
    postOffice: "Shengole",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424207,
  },
  {
    postOffice: "Shindi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Sunasgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425309,
  },
  {
    postOffice: "Sunasgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425114,
  },
  {
    postOffice: "Surwade B K",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Tahakali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425308,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Talvel",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Tandalwadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425502,
  },
  {
    postOffice: "Tawase",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Therola",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Thorgavan",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425501,
  },
  {
    postOffice: "Tondapur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424207,
  },
  {
    postOffice: "Tornala",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424208,
  },
  {
    postOffice: "Uchande",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425306,
  },
  {
    postOffice: "Udhali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425501,
  },
  {
    postOffice: "Umarti",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Utkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425504,
  },
  {
    postOffice: "Vadgaon B K",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425303,
  },
  {
    postOffice: "Vadhoda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425327,
  },
  {
    postOffice: "Vadhode",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Vadri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Vadti",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Vaijpur Settelement",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Varadsim",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Varangaon Ord.Factory",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425308,
  },
  {
    postOffice: "Varangaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Vardi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Vazarkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Vele",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Velhale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425305,
  },
  {
    postOffice: "Velode",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Viravalli K H",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Viroda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425503,
  },
  {
    postOffice: "Virwade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Vishnapur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425107,
  },
  {
    postOffice: "Vitve",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425507,
  },
  {
    postOffice: "Vivra",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Waghari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424206,
  },
  {
    postOffice: "Waghod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425508,
  },
  {
    postOffice: "Waghoda B K",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425502,
  },
  {
    postOffice: "Waghoda K H",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425502,
  },
  {
    postOffice: "Waghur Dam Colony",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425311,
  },
  {
    postOffice: "Waghzira",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425302,
  },
  {
    postOffice: "Wakadi",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424207,
  },
  {
    postOffice: "Walaki",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425108,
  },
  {
    postOffice: "Warkod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424205,
  },
  {
    postOffice: "Yawal",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425301,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425110,
  },
  {
    postOffice: "Akhatwade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Amadade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Amalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Amalner Pratap Nagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Amalner    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Amba Pimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Amoda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Anjanvihire",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Anjanvihire",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Anturli  -  Ii",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Anturli  -- IIi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Anturli --i",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Asankheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Asoda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425101,
  },
  {
    postOffice: "Audyogik Vasahat Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Avhane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Badarkha Hardo",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Bahadarpur    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425113,
  },
  {
    postOffice: "Bahal",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424107,
  },
  {
    postOffice: "Bahute",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Balad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Bambhori",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Bambrud Raniche",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Bamrud Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Beldarwadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Bhadgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Bhadli BK",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425102,
  },
  {
    postOffice: "Bharwas",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Bhatkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Bhaur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Bhavarkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Bhilali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425113,
  },
  {
    postOffice: "Bhokar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Bholane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425113,
  },
  {
    postOffice: "Bholane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425102,
  },
  {
    postOffice: "Bhone",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Bhoras Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Bilakhed",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Bohare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425402,
  },
  {
    postOffice: "Bole",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Borgaon BK",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Borkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Borkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425104,
  },
  {
    postOffice: "Bornar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425116,
  },
  {
    postOffice: "Bramhanshevage",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "C.T. Mill Road",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Chalisgaon Adarsha Nagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424101,
  },
  {
    postOffice: "Chalisgaon",
    Type: "H.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424101,
  },
  {
    postOffice: "Chalisgaon R S",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424101,
  },
  {
    postOffice: "Chalisgaon Town",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424101,
  },
  {
    postOffice: "Chandsar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Chaubari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Chinchpure",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424203,
  },
  {
    postOffice: "Choragaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Chorwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425116,
  },
  {
    postOffice: "Dahiwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Dahiwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Dangar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Dangari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425402,
  },
  {
    postOffice: "Daskebardi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424107,
  },
  {
    postOffice: "Deogaon    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425112,
  },
  {
    postOffice: "Deoli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Devgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Dhanwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Dhar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Dharangaon    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Dheku",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Dholi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Dhul Pimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Dodhwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Eklahare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Erandol",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Farkande",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425110,
  },
  {
    postOffice: "Galan",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Galwade BK",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Gandhli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Ganeshpur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Ghodegaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "Girad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Gondgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Goradkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Gudhe",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424107,
  },
  {
    postOffice: "Hanmantkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Hatgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "Hatle",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Hedave",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Hingona Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425104,
  },
  {
    postOffice: "Hingone Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424102,
  },
  {
    postOffice: "Hirapur",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "Hol Pimrpi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Idgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Jaitpeer",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Jalgaon Bazar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Jalgaon Collectorate",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Jalgaon",
    Type: "H.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Jalgaon Kawarnagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Jalgaon Peth",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Jalgaon Premnagar",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Jalke",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425116,
  },
  {
    postOffice: "Jalod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Jambhore",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Jamda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Janve",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Javkhede",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Jirali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425113,
  },
  {
    postOffice: "Juvardi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424107,
  },
  {
    postOffice: "Kadgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Kadholi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Kajgaon    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Kalali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Kalamsare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425402,
  },
  {
    postOffice: "Kalmadu",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Kanalda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Kandari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Karankheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425402,
  },
  {
    postOffice: "Kargaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Karmad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Kasampura",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Kasoda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425110,
  },
  {
    postOffice: "Kathora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Kavpimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Khadakdeola",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Khadke Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Khadke Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Khakdesim",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Kharchi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Kharde",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Kharjai",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Khedgaon Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Khedgaon Nandiche",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Khedgaon    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424107,
  },
  {
    postOffice: "Kherde",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Kinod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Kol Pimpri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Kolegaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Kunjhar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Kurangi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Kurhad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424202,
  },
  {
    postOffice: "Kurhe Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Kusumba",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Lohare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Lohari Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424202,
  },
  {
    postOffice: "Lohtar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Lon BK",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Lon Pirache",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Londhave",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Londhe",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Lonje",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "M.J.College Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Maheji",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Mahindale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Malshevage",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "Mamurabad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Mandal",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Marwad",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425402,
  },
  {
    postOffice: "Mathgavan",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Meharun",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Mehunbare",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Mhasave",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Mhasawad    Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425116,
  },
  {
    postOffice: "Mohadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Mondhale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Mudi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Mundane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "N M University Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425004,
  },
  {
    postOffice: "Nagardeola R S",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Nagardeola",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Nagav",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Nanded",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Nandra",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Nandra Pachora",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Navi Peth Jalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425001,
  },
  {
    postOffice: "Neri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Nimb",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425402,
  },
  {
    postOffice: "Nimbhora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Nimbhora",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Nimbhori",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Nipane",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425110,
  },
  {
    postOffice: "Ozar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Pachora",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Padmalaya",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Pahan",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Palaskheda Sim",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Paldhi",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Pardhade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Parola",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Pashtane Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Pathri",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Patnadevi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Patonda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Patonda",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424102,
  },
  {
    postOffice: "Phafore",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Phupnagari",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Picharde",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Pilkhod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Pilode",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Pimpale Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424203,
  },
  {
    postOffice: "Pimpali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425420,
  },
  {
    postOffice: "Pimpalkotha",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Pimpri Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Pimpri Khurd",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425104,
  },
  {
    postOffice: "Pohara",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424107,
  },
  {
    postOffice: "Rajwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Ranaiche",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Ranjangaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Ravanje",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Ringangaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Rohini",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "Rotwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Sadavan",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Sakare",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Salve",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Samner",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Sangavi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Sarbete",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Sarole",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Sarve",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Satgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Satkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425104,
  },
  {
    postOffice: "Savde",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Savkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Savkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Savkheda Hol",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Saygaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Shahpur",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Shelave",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Shevge",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425113,
  },
  {
    postOffice: "Shindad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424202,
  },
  {
    postOffice: "Shindi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Shindi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Shirasgaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Shirasmani",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Shirsale",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Shirsoli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425002,
  },
  {
    postOffice: "Shirud",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Shivani",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Shivare Digar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Sonwad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425104,
  },
  {
    postOffice: "Takarkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425105,
  },
  {
    postOffice: "Takli P.D.",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Talai",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425110,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424108,
  },
  {
    postOffice: "Talki Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424103,
  },
  {
    postOffice: "Talonde Digar",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Tamaswadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Tambole Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424102,
  },
  {
    postOffice: "Tarkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Tarsod",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425003,
  },
  {
    postOffice: "Tarwade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Taskheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Tehu",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Titvi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Toli",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425112,
  },
  {
    postOffice: "Toli Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Umbarkhed",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Undirkhede",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425111,
  },
  {
    postOffice: "Upkhed",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Utran",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Vadale Vadali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424102,
  },
  {
    postOffice: "Vadali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Vadgaon Ambe",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424202,
  },
  {
    postOffice: "Vadgaon Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Vadgaon Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424104,
  },
  {
    postOffice: "Vadgaon Lambe",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Vadji",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424105,
  },
  {
    postOffice: "Vankothe",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425110,
  },
  {
    postOffice: "Varad",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Varad Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425115,
  },
  {
    postOffice: "Varkhede Budruk",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424106,
  },
  {
    postOffice: "Varkhedi",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424202,
  },
  {
    postOffice: "Veruli Khurd",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Vikhran",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425109,
  },
  {
    postOffice: "Vitner",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425116,
  },
  {
    postOffice: "Vivekanand nagar Pachora",
    Type: "S.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Wade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Wadi",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424201,
  },
  {
    postOffice: "Waghali",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424102,
  },
  {
    postOffice: "Waghdu",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 424119,
  },
  {
    postOffice: "Wavdade",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425116,
  },
  {
    postOffice: "Wavde",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425401,
  },
  {
    postOffice: "Zurkheda",
    Type: "B.O",
    District: "Jalgaon",
    State: "MAHARASHTRA",
    Pincode: 425103,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Akola  Niklak",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Akola",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431208,
  },
  {
    postOffice: "Ambad Bazar",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Ambad",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Ambika Market Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431203,
  },
  {
    postOffice: "Ankush Nagar",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Anterwali Tembhi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Avana",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Babhulgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Bawne Pangri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Bharaj",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Bhatepuri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Bodkha BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Chandai Eco",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Chikangaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Chinchkheda",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Chitili Putli",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Collectorate Complex Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Dabhadi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Dahipuri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Dahyala",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431137,
  },
  {
    postOffice: "Danapur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Delegavan",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Deogaon Kusali",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Dhakalgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431205,
  },
  {
    postOffice: "Dhakephal",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Dhasala",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Dhavda",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Dudhna Kelgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Georai Bazar",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Ghansawangi",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Ghungurde Hadgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Golapangri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Gondi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Gunj BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Guru Pimpri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Hastepimpalgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Hastepokhari",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Hiswan",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Hivra  Kabli",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431208,
  },
  {
    postOffice: "Hivra Roshangaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Jaffrabad",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Jalna",
    Type: "H.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431203,
  },
  {
    postOffice: "Jalna Industrial Area",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431203,
  },
  {
    postOffice: "Jalna Mondha",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431203,
  },
  {
    postOffice: "Jalna Railway Station",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Jam Samarth",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Jamkhed",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Jamwadi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Janephal",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Javkheda BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Jiradgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Kajala",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Kandharai Partur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Kandhari  Bk",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Karadgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Karla",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Kathora",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Kedarkheda",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Keligavahan",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Khamgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Khapardev Hivra",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Khasgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Kosgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Kumbhar Pimpalgaon",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Kumbharzari",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431208,
  },
  {
    postOffice: "Longaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Machindra Chincholi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Mahakala",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Mahora",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Manegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Maujpuri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Midc Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Nalewadi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431205,
  },
  {
    postOffice: "Nalni",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Nanegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Navha",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Ner",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Old Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Pachan Wadgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Pahtepur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Panewadi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Paradggaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Parner",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Parundi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Patharwala",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Pathrud",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Pimalgaon Kolte",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Pimpalgaon R",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Pimpalgaon Saste",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Pimparkheda  Bk",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Pir Pimpalgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Pirkalyan",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Raja Takli",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431211,
  },
  {
    postOffice: "Rajur",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Rakhiv Police Bal Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431203,
  },
  {
    postOffice: "Ramasgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Ramnagar",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Rani Unchegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Ranjani    Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431207,
  },
  {
    postOffice: "Revgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Rohilagad",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431121,
  },
  {
    postOffice: "Roshangaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Saigaon  Dongargaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Sawangi A",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Sawargaon Hadap",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Seoli",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Shahagad",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431212,
  },
  {
    postOffice: "Shahapur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431205,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Shelud",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Sheoga",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Shindekelgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Sipora Ambhora",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Sipora",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Soegaon Devi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Somthana",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431202,
  },
  {
    postOffice: "Sonakpimpalgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431205,
  },
  {
    postOffice: "Sukhapuri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431205,
  },
  {
    postOffice: "Surangali",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Tadegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Tadhadgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Takli Kolta",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431134,
  },
  {
    postOffice: "Tapowan Gondhan",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431208,
  },
  {
    postOffice: "Tembhurni    Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431208,
  },
  {
    postOffice: "Tirthpuri",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431209,
  },
  {
    postOffice: "Utwad",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431214,
  },
  {
    postOffice: "Wadi BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Wadigodri",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431205,
  },
  {
    postOffice: "Waghrul",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Wakhari",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431213,
  },
  {
    postOffice: "Walsa Khalsa",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431215,
  },
  {
    postOffice: "Walsa Wadala",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Walsawangi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Warud BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431114,
  },
  {
    postOffice: "Warud BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Warud KH",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431206,
  },
  {
    postOffice: "Warud Tangde",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431132,
  },
  {
    postOffice: "Zirpi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431204,
  },
  {
    postOffice: "Akani",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Amba",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431512,
  },
  {
    postOffice: "Anandwadi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Ashti    Jalna",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "Babultara",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Bellora",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Dahifal",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Devgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "Hatdi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Helas",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Idoli",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Jaipur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "K. Hadgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "K. Sawangi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Kendhali",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Khandavi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431502,
  },
  {
    postOffice: "Kirla",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Lingasa",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "Mantha",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Mapegaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431502,
  },
  {
    postOffice: "Osmanpur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431502,
  },
  {
    postOffice: "P. Dhamangaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "Pande Pokhari",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "Pangri BK",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Pangri Gosavi",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Partur",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Patoda Mav",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Pimparkheda",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Raigavan",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431507,
  },
  {
    postOffice: "Rohina",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Satona",
    Type: "S.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431502,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431502,
  },
  {
    postOffice: "Shrishti",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Talni",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Uswad",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431504,
  },
  {
    postOffice: "Vaidya Wadgaon",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Warphal",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "Watur",
    Type: "B.O",
    District: "Jalna",
    State: "MAHARASHTRA",
    Pincode: 431501,
  },
  {
    postOffice: "5 Star MIDC Kagal",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416236,
  },
  {
    postOffice: "Adkur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Adoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Ainapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Ajara",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Akanur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Akiwat",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Akiwate Industrial Estate",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416144,
  },
  {
    postOffice: "Akurle",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Alas",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416104,
  },
  {
    postOffice: "Alate",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416109,
  },
  {
    postOffice: "Ambap",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Ambarde",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Ambavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416201,
  },
  {
    postOffice: "Ambewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416552,
  },
  {
    postOffice: "Ambewadi    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Amroli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Amshi Dumala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416204,
  },
  {
    postOffice: "Anap Khurd",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Anur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Arale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Aralgundi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Ardal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Are",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Arjuni",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Arjunwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416120,
  },
  {
    postOffice: "Arjunwada",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Arjunwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Arul",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Asandoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Aslaj",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Atigre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416118,
  },
  {
    postOffice: "Atyal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Aurwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416104,
  },
  {
    postOffice: "Awali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Awali BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Awali KH",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Babujamal",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416012,
  },
  {
    postOffice: "Bachani",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Bachani",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Badyachiwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Bahireshwar",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416011,
  },
  {
    postOffice: "Bahirewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Bahubali",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416110,
  },
  {
    postOffice: "Bajar Bhogaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Baleghol",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Balinge",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416010,
  },
  {
    postOffice: "Bamani",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416232,
  },
  {
    postOffice: "Bambavada",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Banage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Bandiwade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Baradwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Barve",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Basarge",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Basarge BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Bastavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Bastwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Batkangale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Beed",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416011,
  },
  {
    postOffice: "Belawale BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Belewadi Kalamma",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Bhadale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Bhadgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Bhadole",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Bhadvan",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Bhamate",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416204,
  },
  {
    postOffice: "Bhativade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "Bhedasgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Bhendegalli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416002,
  },
  {
    postOffice: "Bhendwade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Bhuye",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Bidri",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Bolavi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Boravade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Borbet",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Borivade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Borpadale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Bubnal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416104,
  },
  {
    postOffice: "Bugadikatti",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Buvache Vathar",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Chandgad",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Chandoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Chandur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416116,
  },
  {
    postOffice: "Channe Kuppi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Charan",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Chavare",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Chimane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Chinchwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416120,
  },
  {
    postOffice: "Chinchwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416119,
  },
  {
    postOffice: "Chipri",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Chokak",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416118,
  },
  {
    postOffice: "Chuye",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Dajipur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Danoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Danwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416107,
  },
  {
    postOffice: "Date",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416552,
  },
  {
    postOffice: "Dattanagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416120,
  },
  {
    postOffice: "Dattawad",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416107,
  },
  {
    postOffice: "Daulat Sugar Factory",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416552,
  },
  {
    postOffice: "Dewale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Dhamane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Dhamod",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Dharangutti",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416102,
  },
  {
    postOffice: "Dholgarwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Dhundwade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Dindnerli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Dudhganganagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416231,
  },
  {
    postOffice: "Dukkarwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Dundage",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Durgamanwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Ekondi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416232,
  },
  {
    postOffice: "Gadhinglaj",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Gadhinglaj Shivaji Chowk",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Gadhinglaj Tilakpath",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Gadmudshingi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416119,
  },
  {
    postOffice: "Gaganbavada",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Gajapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Gajargaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Gandhinagar    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416119,
  },
  {
    postOffice: "Ganeshwadi    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416105,
  },
  {
    postOffice: "Ganganagar Ichalkaranji",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416116,
  },
  {
    postOffice: "Gangapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Gargoti",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Gariwade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Gavase",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Gavase",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Ghalwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416103,
  },
  {
    postOffice: "Ghanavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Ghosarwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416107,
  },
  {
    postOffice: "Ghotavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Ghunki",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Gijawane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Girgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416013,
  },
  {
    postOffice: "Gogave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Gorambe",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Gudal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Gur Market Yard",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416005,
  },
  {
    postOffice: "Hadalage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Hajgoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Haldi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Halewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Halkarni  Chandgad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416552,
  },
  {
    postOffice: "Halkarni",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Halsawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416202,
  },
  {
    postOffice: "Hamidwada",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Hanimnal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Hanmantwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416010,
  },
  {
    postOffice: "Harali BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Haroli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416102,
  },
  {
    postOffice: "Hasur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416103,
  },
  {
    postOffice: "Hasur Champu",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Hasur Dumala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Hasur Khurd",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Hasur Sasgiri",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Hativade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Hatkalangada",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416109,
  },
  {
    postOffice: "Hebbal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Hebbal Jaldyal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Hedawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "Hera",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Herla",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416005,
  },
  {
    postOffice: "Herwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Hindgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Hiralge",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Hitni",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Honyali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Hosur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Hupari",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416203,
  },
  {
    postOffice: "Ibrahimpur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Ichalkaranji",
    Type: "H.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416115,
  },
  {
    postOffice: "ICO Spinning Mills",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416143,
  },
  {
    postOffice: "Inchnal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Ingali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416202,
  },
  {
    postOffice: "Isapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Ispurli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Jaisingpur R.S.",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Jakhale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416113,
  },
  {
    postOffice: "Jambhali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416121,
  },
  {
    postOffice: "Jangamhatti",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Jarali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416551,
  },
  {
    postOffice: "Jatharwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Jawaharnagar Ichalkaranji",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416115,
  },
  {
    postOffice: "Jaysingpur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "June Pargaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416113,
  },
  {
    postOffice: "Kabnur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416116,
  },
  {
    postOffice: "Kadalage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Kadave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Kadgaon Gadhinglaj",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Kadgaon Gargoti",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Kagal",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Kakhe",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Kalamba",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416007,
  },
  {
    postOffice: "Kale    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Kalkundri",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Kalnakwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Kalvikatti",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Kanadewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Kandgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416011,
  },
  {
    postOffice: "Kaneri  Panhala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Kaneri",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416234,
  },
  {
    postOffice: "Kanoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Kanur Khurd",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Kanwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416103,
  },
  {
    postOffice: "Kapadmarket Ichalkaranji",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416115,
  },
  {
    postOffice: "Kapshi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Kapshi",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Karadwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Karambali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Karanjivane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Karanjphen",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Karanjphen",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Kardyal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Karnur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Karungale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Karve",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Kasar Kandgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Kasari",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Kasarwada",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Kasba Bavada",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416006,
  },
  {
    postOffice: "Kasba Sangaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Kasba Thane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Katali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Kate Bhogaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Kaulav",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Kavane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Kavatheguland",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416105,
  },
  {
    postOffice: "Kavathesar",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416111,
  },
  {
    postOffice: "Kenavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Keravade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Kerle",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Kerli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Khadakewada",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Khandal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416551,
  },
  {
    postOffice: "Khatangale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416204,
  },
  {
    postOffice: "Khebavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Khidrapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416108,
  },
  {
    postOffice: "Khochi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416110,
  },
  {
    postOffice: "Khupire",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Kine",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Kini",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Kini",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Kisrul",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Kitawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Kodavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Kodoli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Koge",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416010,
  },
  {
    postOffice: "Kolhapur City",
    Type: "H.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416012,
  },
  {
    postOffice: "Kolhapur",
    Type: "H.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416003,
  },
  {
    postOffice: "Kolhapur RS",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Kolik",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Kolindre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Kololi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Kolwan",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "Kondoshi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Koor",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Koparde",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416204,
  },
  {
    postOffice: "Korochi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416109,
  },
  {
    postOffice: "Kothali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Kothali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Kotoli  Warna",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Kotoli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Koulage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Koulage",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Kowad",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Kowade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Kuditre",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416204,
  },
  {
    postOffice: "Kudnur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Kumbhoj",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416111,
  },
  {
    postOffice: "Kurani Buzawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Kurukali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Kurundwad",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Kutwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416103,
  },
  {
    postOffice: "Lakudwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Lat",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416143,
  },
  {
    postOffice: "Latwade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Laxmipuri",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416002,
  },
  {
    postOffice: "Lingnur Dumala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Madilage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Madilage BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Madyal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Mahagaon  Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Mahagond",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Mahalewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Majale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416109,
  },
  {
    postOffice: "Majgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Malage BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Malapude",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Malatwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Male",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Male",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416118,
  },
  {
    postOffice: "Maligre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Malkapur    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Malyachi Shiroli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Man",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Manapadale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Manawale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Mandukali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Mangalwar Peth    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416012,
  },
  {
    postOffice: "Mangaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Mangaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416118,
  },
  {
    postOffice: "Mangnoor",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Mangnoor T/F Sawantwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Manjarewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Manwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Masewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Mathgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Mhakave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Mhalsavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Mhalunge",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Mhalunge Khalsa",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Mhasrang",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Mhasurli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Mhasve",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "MIDC Gokul Shirgaon",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416234,
  },
  {
    postOffice: "MIDC Shiroli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Minche",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416109,
  },
  {
    postOffice: "Minche BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "Minche KH",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "Mohare",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Molawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Mouje Haladi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Mouje Sangaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Mudhal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Mugali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416551,
  },
  {
    postOffice: "Mugali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Mumewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Mungurwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Murgud",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Mutnal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Nadhavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Naganwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Nagaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Nagave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Nandani",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416102,
  },
  {
    postOffice: "Nandanwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Nandavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Nangnoor",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416551,
  },
  {
    postOffice: "Narande",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416110,
  },
  {
    postOffice: "Narewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Narsobawadi",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416104,
  },
  {
    postOffice: "Nartavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Navale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Navkud",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Nej",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416110,
  },
  {
    postOffice: "Nerli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416234,
  },
  {
    postOffice: "Nesari",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Nidhori",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Nigave Dumala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Nigave Khalasa",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416207,
  },
  {
    postOffice: "Nilji",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416501,
  },
  {
    postOffice: "Nilpan",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416223,
  },
  {
    postOffice: "Nimshirgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Ningudage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Nittur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Nool",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416551,
  },
  {
    postOffice: "P. Shiroli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Pachgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416013,
  },
  {
    postOffice: "Padal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Padali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Padali Khurd",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416010,
  },
  {
    postOffice: "Pal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Palshivane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Panhala",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416201,
  },
  {
    postOffice: "Panori",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416231,
  },
  {
    postOffice: "Panuandre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Panutre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Parali Ninai",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Pargaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416113,
  },
  {
    postOffice: "Parite",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Parkhandale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Parkhandale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Parvati Industrial Estate",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416146,
  },
  {
    postOffice: "Pat Panhala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Patgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Patne",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416552,
  },
  {
    postOffice: "Pattankodoli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416202,
  },
  {
    postOffice: "Pernoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Phanaswadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Phejiwade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Phulewadi",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416010,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Pimpalgaon BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Pimpalgaon KH",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Piral",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Pisatri",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Pishavi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Pohale T/F Borgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Pohale Turf Alate",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Porle Turf Thane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Pratibhanagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416008,
  },
  {
    postOffice: "Punal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Pungaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "R K Nagar Ichalkaranji",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416121,
  },
  {
    postOffice: "R K Nagar    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416013,
  },
  {
    postOffice: "Radhanagari",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Rajagoli KH",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Rajapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Rajapurwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416108,
  },
  {
    postOffice: "Rajarampuri",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416008,
  },
  {
    postOffice: "Rakshi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416201,
  },
  {
    postOffice: "Randivewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416203,
  },
  {
    postOffice: "Rangoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416143,
  },
  {
    postOffice: "Rankala",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416012,
  },
  {
    postOffice: "Rashivade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Rendal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416203,
  },
  {
    postOffice: "Rethare",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Rhe Works",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416116,
  },
  {
    postOffice: "Ruikar Colony",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416005,
  },
  {
    postOffice: "Rukadi",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416118,
  },
  {
    postOffice: "Sadashivnagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Sadoli Khalsa",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Sajani",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416116,
  },
  {
    postOffice: "Sake",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Salshi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Salvan",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Sambre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Sane Guruji Vasahat",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416011,
  },
  {
    postOffice: "Sangashi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Sangavade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416202,
  },
  {
    postOffice: "Sangrul",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Sarambalwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Sarawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Sarud",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Satavane",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Satave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Savarde",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416109,
  },
  {
    postOffice: "Savarde BK",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Savarde Dumala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Savarde Patankar",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Savarde T/F Satave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416114,
  },
  {
    postOffice: "Save",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Shahapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416121,
  },
  {
    postOffice: "Shahupuri",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416001,
  },
  {
    postOffice: "Shahuwadi",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Shaniwar Peth    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416002,
  },
  {
    postOffice: "Shankarwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416232,
  },
  {
    postOffice: "Shedshal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416105,
  },
  {
    postOffice: "Sheloli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Shenawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Shendur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416232,
  },
  {
    postOffice: "Shengaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Shimpe",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Shingnapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416010,
  },
  {
    postOffice: "Shinoli KH",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Shippur T/F Nesari",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Shipur T/F Ajara",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Shiradwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416143,
  },
  {
    postOffice: "Shirala",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Shirale T/Ff Warun",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Shirdhon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416121,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Shirol",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416103,
  },
  {
    postOffice: "Shiroli  Sonyachi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Shirse",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Shirti",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416103,
  },
  {
    postOffice: "Shittur T/F Warun",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Shittur Turf Malkapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Shivaji Peth",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416012,
  },
  {
    postOffice: "Shivaji Udyam Nagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416008,
  },
  {
    postOffice: "Shivaji University",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416004,
  },
  {
    postOffice: "Shivaji Veedyapeeth Marg",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416008,
  },
  {
    postOffice: "Shivajinagar Ichalkaranji",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416115,
  },
  {
    postOffice: "Shivanage Lakkikatte",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Shivdav",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Shiye",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Shukrawar Peth    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416002,
  },
  {
    postOffice: "Sidhnerli",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416232,
  },
  {
    postOffice: "Sohale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Solankur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416212,
  },
  {
    postOffice: "Sonage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Sonali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Sonarwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Sondoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Sule",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Sule",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416503,
  },
  {
    postOffice: "Sulkud",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Sundi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Supatre",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Surupali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416235,
  },
  {
    postOffice: "Surute",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Takali",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416108,
  },
  {
    postOffice: "Takaliwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416108,
  },
  {
    postOffice: "Takawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416121,
  },
  {
    postOffice: "Talandage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416236,
  },
  {
    postOffice: "Talasande",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Talashi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Tamadalage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Tambale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Tambyachiwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Tamnakwada",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Tarale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Tardal",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416121,
  },
  {
    postOffice: "Tarsambale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416211,
  },
  {
    postOffice: "Tasgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Tekoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Terni",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Terwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416106,
  },
  {
    postOffice: "Teurwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416508,
  },
  {
    postOffice: "Thergaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416213,
  },
  {
    postOffice: "Thikpurli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Tikkewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Tillarinagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416527,
  },
  {
    postOffice: "Tilwani",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416116,
  },
  {
    postOffice: "Tirpan",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Tisangi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416206,
  },
  {
    postOffice: "Titave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Top",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416122,
  },
  {
    postOffice: "Tudiye",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Turambe",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Turkewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416507,
  },
  {
    postOffice: "Uchgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416005,
  },
  {
    postOffice: "Udgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Udyog Nagar Ichalkaranji",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416115,
  },
  {
    postOffice: "Ujlaiwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416004,
  },
  {
    postOffice: "Ukhalu",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Ukoli",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416215,
  },
  {
    postOffice: "Umalwad",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416101,
  },
  {
    postOffice: "Umgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416509,
  },
  {
    postOffice: "Undarwadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416208,
  },
  {
    postOffice: "Uttur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Vadange",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Vadarage Majare",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416502,
  },
  {
    postOffice: "Vadgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416218,
  },
  {
    postOffice: "Vadgaon    Kolhapur",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Vakare",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416526,
  },
  {
    postOffice: "Valivade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416119,
  },
  {
    postOffice: "Vandur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Varange Padali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Vasagade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416202,
  },
  {
    postOffice: "Vathar T/F vadgaon",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416112,
  },
  {
    postOffice: "Vengrul",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Vesarde",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416210,
  },
  {
    postOffice: "Vetawade",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Vhanali",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Vhannur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416216,
  },
  {
    postOffice: "Virale",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416214,
  },
  {
    postOffice: "Vishalgad Amba",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Vite",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Wadi Ratnagiri",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416229,
  },
  {
    postOffice: "Waghapur",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416209,
  },
  {
    postOffice: "Waghave",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416230,
  },
  {
    postOffice: "Walwa Bk",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Walwa KH",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416221,
  },
  {
    postOffice: "Warnanagar",
    Type: "S.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416113,
  },
  {
    postOffice: "Warul",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Washi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416011,
  },
  {
    postOffice: "Watangi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416504,
  },
  {
    postOffice: "Yadrav",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416146,
  },
  {
    postOffice: "Yalgud",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416236,
  },
  {
    postOffice: "Yamage",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416219,
  },
  {
    postOffice: "Yavluj",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416205,
  },
  {
    postOffice: "Yelvan Jugai",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 415101,
  },
  {
    postOffice: "Yenechavandi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416506,
  },
  {
    postOffice: "Yerandol",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416505,
  },
  {
    postOffice: "Zulpewadi",
    Type: "B.O",
    District: "Kolhapur",
    State: "MAHARASHTRA",
    Pincode: 416220,
  },
  {
    postOffice: "Abulga BK",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Ahmedpur    Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Almala",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Ambegaon Masalga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413530,
  },
  {
    postOffice: "Anjansonda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Ashiv",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Atnoor",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Atola",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Aurad Shahajani",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Ausa",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Awalkonda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Badur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Banshelki",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Belkund",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Bhada",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Bheta",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Bholegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Bolegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Boral",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Borgaon    Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Borphal",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Borsuri",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Budhada",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Chakur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Chapoli",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Chavani Hipperga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Chikurda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Chincholi Ballalnath",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Chinchondi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Daithana",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Dawan Hipperga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Dawangaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "De Vtala",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Deoni",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Deulwadi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Devarjan",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Dhanegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Dhondi Hipperga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Dongarshelki",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Ekurga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Gadwad",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Ganga Hipperga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Gategaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Ghansargaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Gharani",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Gharsoli",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Ghonshi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Gondgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Gudsur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Gunjarga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Gurnal",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Gutti",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Halgara",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Halli",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Halsi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Handargulli",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Hangarga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Haregaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Harewadi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Hari Javalga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Hasegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Hatte Road Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413512,
  },
  {
    postOffice: "Helamb",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Her",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Hipalner",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Hippalgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Hippalgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Hipperga Kajala",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Honali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Hosur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Jalkot",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Janwal",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413529,
  },
  {
    postOffice: "Javalga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Javali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Jawala  BK",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Jawalga Pomodevi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "JJJKSSK Nalegaon",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413581,
  },
  {
    postOffice: "Kaban Sangvi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Kallur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Kalmugali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Karadkhed",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Kasarsirsi",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Kate Javalga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413530,
  },
  {
    postOffice: "Kawathali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Kelgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413530,
  },
  {
    postOffice: "Khandali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Kharola",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Khuntegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Killari S F",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Killari",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Kingaon",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Kini Yeladevi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Kokalgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Korangala",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Kumtha KH",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Labour Colony Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413512,
  },
  {
    postOffice: "Lambota",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413530,
  },
  {
    postOffice: "Lamjana",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Lasona",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Latur Bazar",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413512,
  },
  {
    postOffice: "Latur",
    Type: "H.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413512,
  },
  {
    postOffice: "Latur Rs",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413512,
  },
  {
    postOffice: "Lohara",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Mahalangra",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413581,
  },
  {
    postOffice: "Makani",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Mal Hipperga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Malewadi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Manjara SK",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Matola",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "MIDC Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Mogha",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Mohnal",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Morwad",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Motegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Murud Akola",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Murud",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413510,
  },
  {
    postOffice: "Nagalgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Nagarsoga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Nalegaon",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Nalgir",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Nandurga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Nawani",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Nevali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Nilanga",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Nitoor",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413530,
  },
  {
    postOffice: "Omerga Court",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Pakharsangvi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Pangaon    Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 431522,
  },
  {
    postOffice: "Parchanda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Ramegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Rameshwar",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Ramnagar Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413512,
  },
  {
    postOffice: "Rapka",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Ravankola",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Rawangaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Renapur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Sambhu Omerga",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Sangam",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Sanghvi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Sarola",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Sawargaon Rokda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Sawari",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Selu",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Shirala",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Shirol Janapur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Shirol Wanjarwada",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413530,
  },
  {
    postOffice: "Shirur Anantpal",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Shirur Tajband",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Shivankhed",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413529,
  },
  {
    postOffice: "Shivli",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Sindala Shivpur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Somnathpur",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Sondgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Sugaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Sugaon Mahalang",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413529,
  },
  {
    postOffice: "Tadmugali",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413522,
  },
  {
    postOffice: "Taka",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Tambhaia",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Tandulja",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Telgaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413513,
  },
  {
    postOffice: "Thergaon",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Tilaknagar Latur",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Tirth",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413515,
  },
  {
    postOffice: "Tiruka",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Togri",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Tondar",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Tondchira",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Tungi BK",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Udgir",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Udgirganj",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Ujani",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Uti  BK",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413520,
  },
  {
    postOffice: "Wadhona",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Wadmurambi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Wadwal Nagnath",
    Type: "S.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413529,
  },
  {
    postOffice: "Wagholi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Wakadi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Walandi",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413519,
  },
  {
    postOffice: "Wanjarkheda",
    Type: "B.O",
    District: "Latur",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "Antop Hill",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400037,
  },
  {
    postOffice: "B P T Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400037,
  },
  {
    postOffice: "B.P.Lane",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400003,
  },
  {
    postOffice: "BEST STaff Quarters",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "C G S Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400037,
  },
  {
    postOffice: "Chamarbaug",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "Chinchbunder",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400009,
  },
  {
    postOffice: "Cotton Exchange",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400033,
  },
  {
    postOffice: "Dadar Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400014,
  },
  {
    postOffice: "Dadar",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400014,
  },
  {
    postOffice: "Dockyard Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400010,
  },
  {
    postOffice: "Haffkin Institute",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "Kalachowki",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400033,
  },
  {
    postOffice: "Kidwai Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400031,
  },
  {
    postOffice: "L B S N E collage",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400033,
  },
  {
    postOffice: "Lal Baug",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "Mandvi    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400003,
  },
  {
    postOffice: "Masjid",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400003,
  },
  {
    postOffice: "Mazgaon Dock",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400010,
  },
  {
    postOffice: "Mazgaon Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400010,
  },
  {
    postOffice: "Mazgaon",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400010,
  },
  {
    postOffice: "Naigaon    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400014,
  },
  {
    postOffice: "Noor Baug",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400009,
  },
  {
    postOffice: "Null Bazar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400003,
  },
  {
    postOffice: "Parel Naka",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "Parel Rly Work Shop",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "Parel City",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400012,
  },
  {
    postOffice: "Princess Dock",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400009,
  },
  {
    postOffice: "Reay Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400033,
  },
  {
    postOffice: "Sewri",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400015,
  },
  {
    postOffice: "Tank Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400033,
  },
  {
    postOffice: "V J B Udyan",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400027,
  },
  {
    postOffice: "V K Bhavan",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400010,
  },
  {
    postOffice: "Wadala Rs",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400031,
  },
  {
    postOffice: "Wadala",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400031,
  },
  {
    postOffice: "Wadala Truck Terminal",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400037,
  },
  {
    postOffice: "A I Staff Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400029,
  },
  {
    postOffice: "Airport    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400099,
  },
  {
    postOffice: "Andheri East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400069,
  },
  {
    postOffice: "Andheri",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400053,
  },
  {
    postOffice: "Andheri Railway Station",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400058,
  },
  {
    postOffice: "Audit Bhavan",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400051,
  },
  {
    postOffice: "Azad Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400053,
  },
  {
    postOffice: "B.N. Bhavan",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400051,
  },
  {
    postOffice: "Bandra West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400050,
  },
  {
    postOffice: "Bandra East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400051,
  },
  {
    postOffice: "Chakala Midc",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400093,
  },
  {
    postOffice: "Danda",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400052,
  },
  {
    postOffice: "Government Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400051,
  },
  {
    postOffice: "H.M.P. School",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400058,
  },
  {
    postOffice: "Hanuman Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400057,
  },
  {
    postOffice: "International Airport",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400099,
  },
  {
    postOffice: "IRLA",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400056,
  },
  {
    postOffice: "J.B. Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400059,
  },
  {
    postOffice: "Juhu",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400049,
  },
  {
    postOffice: "Khar Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400052,
  },
  {
    postOffice: "Khar Delivery",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400052,
  },
  {
    postOffice: "Kherwadi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400051,
  },
  {
    postOffice: "Madh",
    Type: "B.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400061,
  },
  {
    postOffice: "Marol Bazar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400059,
  },
  {
    postOffice: "Marol Naka",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400059,
  },
  {
    postOffice: "Nagardas Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400069,
  },
  {
    postOffice: "Sahar P and T Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400099,
  },
  {
    postOffice: "Sahargaon",
    Type: "B.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400099,
  },
  {
    postOffice: "Santacruz Central",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400054,
  },
  {
    postOffice: "Santacruz Pandt Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400029,
  },
  {
    postOffice: "Santacruz East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400055,
  },
  {
    postOffice: "Santacruz West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400054,
  },
  {
    postOffice: "Seepz",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400096,
  },
  {
    postOffice: "V.P. Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400052,
  },
  {
    postOffice: "Vakola",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400055,
  },
  {
    postOffice: "Vesava",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400061,
  },
  {
    postOffice: "Vidyanagari",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400098,
  },
  {
    postOffice: "Vileeparle  East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400057,
  },
  {
    postOffice: "Vileparle Railway Station",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400057,
  },
  {
    postOffice: "Vileparle West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400056,
  },
  {
    postOffice: "Anushakti Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400094,
  },
  {
    postOffice: "BARC",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400085,
  },
  {
    postOffice: "Barve Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400084,
  },
  {
    postOffice: "Best Staff Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400075,
  },
  {
    postOffice: "Bhandup Complex",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400082,
  },
  {
    postOffice: "Bhandup East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400042,
  },
  {
    postOffice: "Bhandup Ind. Estate",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400078,
  },
  {
    postOffice: "Bhandup West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400078,
  },
  {
    postOffice: "Chembur Extension",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400074,
  },
  {
    postOffice: "Chembur",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400071,
  },
  {
    postOffice: "Chembur Rs",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400089,
  },
  {
    postOffice: "Chunabhatti",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400022,
  },
  {
    postOffice: "D.M. Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400043,
  },
  {
    postOffice: "FCI",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400074,
  },
  {
    postOffice: "Ghatkopar West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400086,
  },
  {
    postOffice: "Govandi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400088,
  },
  {
    postOffice: "J.M. Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400078,
  },
  {
    postOffice: "Kannamwar Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400083,
  },
  {
    postOffice: "Kurla North",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400070,
  },
  {
    postOffice: "Kurla",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400070,
  },
  {
    postOffice: "Mahul Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400074,
  },
  {
    postOffice: "Matunga",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400019,
  },
  {
    postOffice: "Mhada Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400081,
  },
  {
    postOffice: "Mulund Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400082,
  },
  {
    postOffice: "Mulund Dd Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400080,
  },
  {
    postOffice: "Mulund East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400081,
  },
  {
    postOffice: "Mulund West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400080,
  },
  {
    postOffice: "Nahur",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400080,
  },
  {
    postOffice: "Nehru Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400024,
  },
  {
    postOffice: "Nehru Road    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400080,
  },
  {
    postOffice: "Netajinagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400070,
  },
  {
    postOffice: "NITIE",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400087,
  },
  {
    postOffice: "P.H. Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400078,
  },
  {
    postOffice: "Pant Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400075,
  },
  {
    postOffice: "Powai Iit",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400076,
  },
  {
    postOffice: "Psm Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400079,
  },
  {
    postOffice: "R.A.Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400075,
  },
  {
    postOffice: "Rajawadi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400077,
  },
  {
    postOffice: "Raoli Camp",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400022,
  },
  {
    postOffice: "Rifle Range",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400086,
  },
  {
    postOffice: "S.B. Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400080,
  },
  {
    postOffice: "Sahakar Bhavan",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400086,
  },
  {
    postOffice: "Sakinaka",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400072,
  },
  {
    postOffice: "Shivaji Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400043,
  },
  {
    postOffice: "Sindhi Society",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400071,
  },
  {
    postOffice: "Sion",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400022,
  },
  {
    postOffice: "T.F.Donar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400088,
  },
  {
    postOffice: "Tagore Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400083,
  },
  {
    postOffice: "Tilak Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400089,
  },
  {
    postOffice: "Transit Camp",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400022,
  },
  {
    postOffice: "Trombay",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400088,
  },
  {
    postOffice: "Usha Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400078,
  },
  {
    postOffice: "Vihar Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400072,
  },
  {
    postOffice: "Vikhroli",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400079,
  },
  {
    postOffice: "Aareymilk Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400065,
  },
  {
    postOffice: "Bangur Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400104,
  },
  {
    postOffice: "Borivali East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400066,
  },
  {
    postOffice: "Borivali",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400091,
  },
  {
    postOffice: "Borivali West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400092,
  },
  {
    postOffice: "Charkop",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400067,
  },
  {
    postOffice: "Dahisar RS",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400068,
  },
  {
    postOffice: "Dahisar City",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400068,
  },
  {
    postOffice: "Daulat Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400066,
  },
  {
    postOffice: "Goregaon East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400063,
  },
  {
    postOffice: "Goregaon RS",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400104,
  },
  {
    postOffice: "Goregaon    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400104,
  },
  {
    postOffice: "Ins Hamla",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400095,
  },
  {
    postOffice: "Jogeshwari East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400060,
  },
  {
    postOffice: "Jogeshwari West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400102,
  },
  {
    postOffice: "Kandivali East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400101,
  },
  {
    postOffice: "Kandivali RS",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400067,
  },
  {
    postOffice: "Kandivali West",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400067,
  },
  {
    postOffice: "Ketkipada",
    Type: "B.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400068,
  },
  {
    postOffice: "Kharodi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400095,
  },
  {
    postOffice: "Liberty Garden",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400064,
  },
  {
    postOffice: "Magthane",
    Type: "B.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400066,
  },
  {
    postOffice: "Malad East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400097,
  },
  {
    postOffice: "Malad",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400064,
  },
  {
    postOffice: "Malad West Dely",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400064,
  },
  {
    postOffice: "Mandapeshwar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400103,
  },
  {
    postOffice: "Motilal Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400104,
  },
  {
    postOffice: "Nagari Niwara",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400065,
  },
  {
    postOffice: "Orlem",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400064,
  },
  {
    postOffice: "Oshiwara",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400102,
  },
  {
    postOffice: "Rajendra Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400066,
  },
  {
    postOffice: "Rani Sati Marg",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400097,
  },
  {
    postOffice: "S R P F Camp",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400065,
  },
  {
    postOffice: "S. K.Nagar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400066,
  },
  {
    postOffice: "Sharma Estate",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400063,
  },
  {
    postOffice: "Ambewadi    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400004,
  },
  {
    postOffice: "Asvini",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400005,
  },
  {
    postOffice: "Bazargate",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "Central Building",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400020,
  },
  {
    postOffice: "Charni Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400004,
  },
  {
    postOffice: "Chaupati",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400004,
  },
  {
    postOffice: "Churchgate",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400020,
  },
  {
    postOffice: "Colaba Bazar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400005,
  },
  {
    postOffice: "Colaba",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400005,
  },
  {
    postOffice: "Girgaon",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400004,
  },
  {
    postOffice: "High Court Building    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400032,
  },
  {
    postOffice: "Holiday Camp",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400005,
  },
  {
    postOffice: "Kalbadevi",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400002,
  },
  {
    postOffice: "M.P.T.",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "Madhavbaug",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400004,
  },
  {
    postOffice: "Mantralaya    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400032,
  },
  {
    postOffice: "Marine Lines",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400020,
  },
  {
    postOffice: "Nariman Point",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400021,
  },
  {
    postOffice: "New Yogakshema",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400021,
  },
  {
    postOffice: "Opera House",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400004,
  },
  {
    postOffice: "Ramwadi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400002,
  },
  {
    postOffice: "S. C. Court",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400002,
  },
  {
    postOffice: "Secretariate",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400032,
  },
  {
    postOffice: "Stock Exchange",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "Tajmahal",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "Thakurdwar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400002,
  },
  {
    postOffice: "Town Hall    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "V.W.T.C.",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400005,
  },
  {
    postOffice: "Agripada",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400011,
  },
  {
    postOffice: "Bharat Nagar    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400007,
  },
  {
    postOffice: "Bhawani Shankar Rd",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400028,
  },
  {
    postOffice: "Bhawani Shankar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400028,
  },
  {
    postOffice: "BPC  Jacob Circle",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400011,
  },
  {
    postOffice: "Century Mill",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400030,
  },
  {
    postOffice: "Chinchpokli",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400011,
  },
  {
    postOffice: "Cumballa Hill",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400026,
  },
  {
    postOffice: "Cumballa Sea Face",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400026,
  },
  {
    postOffice: "Delisle Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400013,
  },
  {
    postOffice: "Dharavi Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400017,
  },
  {
    postOffice: "Dharavi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400017,
  },
  {
    postOffice: "Dr Deshmukh Marg",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400026,
  },
  {
    postOffice: "Falkland Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400008,
  },
  {
    postOffice: "Gokhale Road    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400028,
  },
  {
    postOffice: "Gowalia Tank",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400026,
  },
  {
    postOffice: "Grant Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400007,
  },
  {
    postOffice: "Haines Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400011,
  },
  {
    postOffice: "Haji Ali",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400034,
  },
  {
    postOffice: "J.J.Hospital",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400008,
  },
  {
    postOffice: "Jacob Circle",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400011,
  },
  {
    postOffice: "Kamathipura",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400008,
  },
  {
    postOffice: "Kapad Bazar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400016,
  },
  {
    postOffice: "M A Marg",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400008,
  },
  {
    postOffice: "Mahim Bazar",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400016,
  },
  {
    postOffice: "Mahim East",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400016,
  },
  {
    postOffice: "Mahim",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400016,
  },
  {
    postOffice: "Malabar Hill",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400006,
  },
  {
    postOffice: "Mori Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400016,
  },
  {
    postOffice: "Mumbai Central",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400008,
  },
  {
    postOffice: "N.S.Patkar Marg",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400007,
  },
  {
    postOffice: "New Prabhadevi Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400025,
  },
  {
    postOffice: "Prabhadevi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400025,
  },
  {
    postOffice: "Rajbhavan    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400035,
  },
  {
    postOffice: "Ranade Road",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400028,
  },
  {
    postOffice: "S V Marg",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400007,
  },
  {
    postOffice: "S V S Marg",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400028,
  },
  {
    postOffice: "Shivaji Park    Mumbai",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400028,
  },
  {
    postOffice: "Tardeo",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400007,
  },
  {
    postOffice: "Tulsiwadi",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400034,
  },
  {
    postOffice: "Worli Colony",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400030,
  },
  {
    postOffice: "Worli Naka",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400018,
  },
  {
    postOffice: "Worli Police Camp",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400030,
  },
  {
    postOffice: "Worli",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400018,
  },
  {
    postOffice: "Worli Sea Face",
    Type: "S.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400030,
  },
  {
    postOffice: "Mumbai G.P.O.",
    Type: "H.O",
    District: "Mumbai",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "Abhyankar Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440010,
  },
  {
    postOffice: "Ajni",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440003,
  },
  {
    postOffice: "Ayodhya Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440024,
  },
  {
    postOffice: "Bagadganj",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440008,
  },
  {
    postOffice: "BESA Road",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440037,
  },
  {
    postOffice: "Bezonbagh",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440014,
  },
  {
    postOffice: "Borgaon Road",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440013,
  },
  {
    postOffice: "Bureau Of Mines",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "C.R.P.F. Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440019,
  },
  {
    postOffice: "Coal Estate",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "Congress Nagar    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440012,
  },
  {
    postOffice: "Dhantoli",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440012,
  },
  {
    postOffice: "Dighori Naka",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440024,
  },
  {
    postOffice: "Dr.Ambedkar Marg",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440017,
  },
  {
    postOffice: "Ganjipeth",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440018,
  },
  {
    postOffice: "Giripeth",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440010,
  },
  {
    postOffice: "Gokulpeth",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440010,
  },
  {
    postOffice: "Hanuman Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440024,
  },
  {
    postOffice: "Hitavada",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440012,
  },
  {
    postOffice: "Imamwada",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440003,
  },
  {
    postOffice: "Jaitala",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440036,
  },
  {
    postOffice: "Jaripatka",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440014,
  },
  {
    postOffice: "Kalmna Market Yard",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440035,
  },
  {
    postOffice: "Kasturchand Park",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "Katolroad",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440013,
  },
  {
    postOffice: "Khamla",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440025,
  },
  {
    postOffice: "Khare Town",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440010,
  },
  {
    postOffice: "Laxmi Nagar    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440022,
  },
  {
    postOffice: "Mahal    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440032,
  },
  {
    postOffice: "Mahatma Fule Bazar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440018,
  },
  {
    postOffice: "Manewada Road",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440024,
  },
  {
    postOffice: "Mankapur    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440030,
  },
  {
    postOffice: "Medical College    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440003,
  },
  {
    postOffice: "Mhalginagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440034,
  },
  {
    postOffice: "Mohan Nagar    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "Mominpura",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440018,
  },
  {
    postOffice: "Nadt Campus",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440030,
  },
  {
    postOffice: "Nagpur Airport",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440005,
  },
  {
    postOffice: "Nagpur City",
    Type: "H.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440002,
  },
  {
    postOffice: "Nagpur GPO",
    Type: "H.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "Nandavan Colony",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440024,
  },
  {
    postOffice: "Narendra Nagar    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440015,
  },
  {
    postOffice: "Nayapura",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440002,
  },
  {
    postOffice: "Neeri",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440020,
  },
  {
    postOffice: "Netaji Market",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440012,
  },
  {
    postOffice: "Panchsheel Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440017,
  },
  {
    postOffice: "Parvati Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440027,
  },
  {
    postOffice: "Patwardhan Ground",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440012,
  },
  {
    postOffice: "Raje Raghujinagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440024,
  },
  {
    postOffice: "Ranapratap Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440022,
  },
  {
    postOffice: "Ravi Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "Sadar Bazar    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440001,
  },
  {
    postOffice: "Samartha Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440015,
  },
  {
    postOffice: "Seminary Hills",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440006,
  },
  {
    postOffice: "Shankar Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440010,
  },
  {
    postOffice: "Shradhananpeth",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440022,
  },
  {
    postOffice: "Sitabuldi",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440012,
  },
  {
    postOffice: "SRPF",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440016,
  },
  {
    postOffice: "Trimurti Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440022,
  },
  {
    postOffice: "Ujwal Nagar    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440025,
  },
  {
    postOffice: "University Campus    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440033,
  },
  {
    postOffice: "Uppalwadi",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440026,
  },
  {
    postOffice: "V R C E",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440010,
  },
  {
    postOffice: "Vayusena Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440007,
  },
  {
    postOffice: "Vishwakarma Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440027,
  },
  {
    postOffice: "Vivekanand Nagar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440015,
  },
  {
    postOffice: "A.D. Project",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440021,
  },
  {
    postOffice: "Adam",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Adasa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Adegaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Adyar Dupache",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Ajni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Ambadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Ambhora",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Amgaon Deori",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Aptur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Aroli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Ashta",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Babdeo",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Badegaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Bamhni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Bamhni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Bazargaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Bela",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Belda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Belona",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441304,
  },
  {
    postOffice: "Besur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441214,
  },
  {
    postOffice: "Bhagwanpur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Bhandarbodi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Bhandewadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440035,
  },
  {
    postOffice: "Bharsingi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Bhishnoor",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Bhiwapur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Bhondewada",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Bhugaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Bichwa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Bina",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441102,
  },
  {
    postOffice: "Borda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Bori    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Borkhedi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Chacher",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Champa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441113,
  },
  {
    postOffice: "Champa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Channa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Chapegadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Chirwa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Dahegaon Joshi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Dahegaon Rangari",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441113,
  },
  {
    postOffice: "Datewadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Dattawadi",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Dawha",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Dawlameti",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Dawsa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Deolapar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Deoli Kalan",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Deoli Kalbande",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Dhamna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Dhanla",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Dhapewada",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Dhawlapur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Dighori Kale",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Digras BK",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Dodma",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Donger Mauda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Dorli Bhandwalkar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Dorli Bhingare",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441502,
  },
  {
    postOffice: "Dudhala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441103,
  },
  {
    postOffice: "Dudhala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Fegad",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Fetri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Gaimukh Nanda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Ghorad",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Godhani",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Godhani",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441123,
  },
  {
    postOffice: "Gohna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441404,
  },
  {
    postOffice: "Gondkhairi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Gothangaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Gowri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Gumgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441122,
  },
  {
    postOffice: "Gumthala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Gumthi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441111,
  },
  {
    postOffice: "Hingna Barabhai",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441102,
  },
  {
    postOffice: "Hingna",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Hiwra Bazar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Hiwra Hiwri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441214,
  },
  {
    postOffice: "Indl.Area Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440016,
  },
  {
    postOffice: "Indora",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Industrial Area Butibori",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441122,
  },
  {
    postOffice: "Isapur Khurd",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Itgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Jalalkheda",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Jamgaon BK",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Jaoli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Jiwnapur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Kachari Sawanga",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441103,
  },
  {
    postOffice: "Kachurwahi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Kalambi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Kalamna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Kalmeshwar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Kamthi City SO",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441001,
  },
  {
    postOffice: "Kamthi Colliery",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441404,
  },
  {
    postOffice: "Kamthi",
    Type: "H.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441001,
  },
  {
    postOffice: "Kandri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Kanhan Pipri",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Kanholibara",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Kapsi BK",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440035,
  },
  {
    postOffice: "Kargaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Karwahi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Katol",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Kavikulguru Nagar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Kawdas",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Kelod",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441112,
  },
  {
    postOffice: "Khairgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441303,
  },
  {
    postOffice: "Khairi Akashwani",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440026,
  },
  {
    postOffice: "Khairi Bijewada",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Khairi Dhalgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441112,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Khangaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Khangaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Khapa Ghudan",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Khapa",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Khaperkheda",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441102,
  },
  {
    postOffice: "Khapri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Kharbadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Kharsoli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441304,
  },
  {
    postOffice: "Khat",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Khubala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Khumari",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Khursapar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441103,
  },
  {
    postOffice: "Kodamendhi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Kodegaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Kohli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441502,
  },
  {
    postOffice: "Kondhali",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441103,
  },
  {
    postOffice: "Koradi Tps",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441111,
  },
  {
    postOffice: "Kothurna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Kuhi",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Kujba",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Ladgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Lava",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Lohari Sawanga",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Lohgad",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Lonkhairi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441111,
  },
  {
    postOffice: "Mahadula  Ramtek",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Mahalgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441214,
  },
  {
    postOffice: "Mahuli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Mahurzari",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Makardhokda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Malewada",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Mandhal",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Manegaon Tek",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Mangrud",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Mangsa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441112,
  },
  {
    postOffice: "Mansar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Marni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Marodi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Masod",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441103,
  },
  {
    postOffice: "Masora",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Mathni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Matkazari",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Mauda",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Maywadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Menda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Mendhala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Mendhepathar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Metpanjra",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Midc Kalmeshwar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "MIDC Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440016,
  },
  {
    postOffice: "Mohadi Dalvi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441304,
  },
  {
    postOffice: "Mohgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Mohgaon Bhadade",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Mohpa",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441502,
  },
  {
    postOffice: "Mokhebardi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Mondha",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Morgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Mowad",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441303,
  },
  {
    postOffice: "Murti",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Musalgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Musewadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Nagalwadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Nagardhan",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Nand",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Narkher",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441304,
  },
  {
    postOffice: "Narsala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440034,
  },
  {
    postOffice: "Navegaon Chinchghat",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Navegaon Isapur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Navegaon Khairi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Navegaon Sadhu",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Naxi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Nayakund",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Niharwani",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Nimkheda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Pachgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Pachkhedi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Padri Thana",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Pahani Chinchala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Paradsinga",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Pardi Deshmukh",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441502,
  },
  {
    postOffice: "Parseoni",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441105,
  },
  {
    postOffice: "Patansaongi",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441113,
  },
  {
    postOffice: "Patharai",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Pauni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Pipariya",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Pipla",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440034,
  },
  {
    postOffice: "Pipla Dakbanglow",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441113,
  },
  {
    postOffice: "Pipla Kewalram",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Pipla Kinkhede",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441502,
  },
  {
    postOffice: "Pipra",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Rajola",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Rama",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Ramtek",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Ramthi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Ranala",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Raulgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441502,
  },
  {
    postOffice: "Rewral",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Ridhora",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Ridhora",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441103,
  },
  {
    postOffice: "Rohna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Salaidhabha",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441122,
  },
  {
    postOffice: "Salaigodhni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Salva",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Salwa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Saoner",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Satak",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Sawangi Asola",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441108,
  },
  {
    postOffice: "Sawargaon",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Seo",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Seoni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Shirpur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Shiwa Sawanga",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Shiwapur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Sillewara Project",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441109,
  },
  {
    postOffice: "Silli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Sindhi Umri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Sirsi Navegaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Sirsi    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441214,
  },
  {
    postOffice: "Sonoli",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Surgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Takalghat",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441122,
  },
  {
    postOffice: "Takli Bhansali",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441113,
  },
  {
    postOffice: "Tamaswadi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441102,
  },
  {
    postOffice: "Tangla",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Tarna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Tarsa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441106,
  },
  {
    postOffice: "Tas",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Tekepar",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Telgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Telkamathi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Temsana",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Thadipauni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Thaturwada",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Thugaon Deo",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441301,
  },
  {
    postOffice: "Thutanbori",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Tinkheda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441304,
  },
  {
    postOffice: "Titur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441202,
  },
  {
    postOffice: "Ubali",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Udasa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Umred Bazar",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Umred Project",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Umred",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441203,
  },
  {
    postOffice: "Umri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Umri Wagh",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Ungaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Uparwahi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Veltur",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Vihirgaon",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441204,
  },
  {
    postOffice: "Virkhandi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441201,
  },
  {
    postOffice: "Vyahad Bzk",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Wadamba",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441401,
  },
  {
    postOffice: "Waddhamna",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Wadi    Nagpur",
    Type: "S.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 440023,
  },
  {
    postOffice: "Wadoda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441104,
  },
  {
    postOffice: "Wagh",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441210,
  },
  {
    postOffice: "Waghoda",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441107,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441113,
  },
  {
    postOffice: "Wakodi",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441101,
  },
  {
    postOffice: "Walni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441501,
  },
  {
    postOffice: "Walni Colliery",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441102,
  },
  {
    postOffice: "Wanadongri",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441110,
  },
  {
    postOffice: "Yenikoni",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441306,
  },
  {
    postOffice: "Yenwa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Yerla  Dhote",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441305,
  },
  {
    postOffice: "Zilpa",
    Type: "B.O",
    District: "Nagpur",
    State: "MAHARASHTRA",
    Pincode: 441302,
  },
  {
    postOffice: "Adampur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Aithala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431808,
  },
  {
    postOffice: "Alandi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Alur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Ambadi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Ambulga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Ambulga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Andbori",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Andbori",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Anjankhed",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Anmal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Apparaopeth",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Ardhapur",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Arjapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431711,
  },
  {
    postOffice: "Arli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Ashoknagar    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Ashta",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Ashti",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Ashtoor",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Asoli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Asrjan",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431606,
  },
  {
    postOffice: "Atkali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Bachoti",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Bahadarpura",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Balegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Ballur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Bamni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Banchincholi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Barad",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431745,
  },
  {
    postOffice: "Baradshewala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Barahali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Barasgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431745,
  },
  {
    postOffice: "Barbada",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Barul",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Bdur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Belkoni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Belkoni BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Bembari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Bendi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Berli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Berli BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Betmogara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Betsangavi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Bhategaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Bhayegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Bhendegaon KH",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Bhishi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Bhokar",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Bhorad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Bhoshi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431745,
  },
  {
    postOffice: "Bhutan Hiparga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Bijegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Bijur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Bilali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Biloli",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Bitnal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Bodhadi KH",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Bodhadi",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Bolka",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431742,
  },
  {
    postOffice: "Bolsa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Bolsa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431808,
  },
  {
    postOffice: "Bomnali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Bondgavan",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Bori BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Bothi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Chabhara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Chaitanynagar Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Chandola",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Chauphala",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431604,
  },
  {
    postOffice: "Chikana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431808,
  },
  {
    postOffice: "Chikhala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Chikhli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Chinchala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Chinchgavan",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Chinchkhed",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Chondi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Choramba",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Chuncha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Cidco Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431603,
  },
  {
    postOffice: "Dabhad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431745,
  },
  {
    postOffice: "Dagadgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Dahegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Daheli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Dahelitanda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Dahikalamba",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "Dapaka G",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Dapka Raja",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431716,
  },
  {
    postOffice: "Daregaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Darsangvi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Darsangvi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Dattamanjari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Degaon BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Degloor",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Degloor Town",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Delub",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Deoshi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Deothana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Dhanegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431603,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431808,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Dharmabad",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Dhoki",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Digdi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Digras",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Digras",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431742,
  },
  {
    postOffice: "Dogargaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Dongaon KH",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Dudhad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Dugaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Dundara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Ekalara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Gadga",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Gaglegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Gaul",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Ghodaj",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Ghogari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Ghoti",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Ghungarala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Gojegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Gokunda",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431731,
  },
  {
    postOffice: "Gonar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Gondjavli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Gondmahagaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Gondwadsa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Gorlegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Gortha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Guntur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431742,
  },
  {
    postOffice: "Hadgaon",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Hadoli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431742,
  },
  {
    postOffice: "Hadoli J",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Hadsani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Halda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Halda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Halli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Halni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Hanegaon",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Hardaf",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Hardaf",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Hastara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Hatral",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Hawarga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Himayatnagar",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Hingani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Honwadaj",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Hottal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Hungunda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431711,
  },
  {
    postOffice: "Hussa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Iregaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Islapur",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Itwara Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431604,
  },
  {
    postOffice: "Ivaleshwar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Jahur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Jaldhara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Jamb B K",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431716,
  },
  {
    postOffice: "Jambhala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Jarikot",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431808,
  },
  {
    postOffice: "Jarur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Jawalgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Jawarala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Joshisangavi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "Kahala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Kakandi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Kalambar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Kalambar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Kamari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Kamjalga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431716,
  },
  {
    postOffice: "Kamtha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Kamthala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Kanchali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Kandali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Kandali KH",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Kandhar",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Kangti",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Kanki",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Kapsi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Karala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Karala Kalam",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Karanji",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Kardkhed",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Karegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Karimabad Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431604,
  },
  {
    postOffice: "Karkheli",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431808,
  },
  {
    postOffice: "Kasarali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Kasarkheda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Kasrali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Katkalamba",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Kaudgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Kautha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Kawalgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Kawana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Kerur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Khadki",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Khambala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Kharbkhandgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Khatgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Khndgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Khtgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Kinala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Kini",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Kinwat",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Kiwala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "Koklegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Koklegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Kolahari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Kolambi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Kolheborgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Koli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Kopara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Kosmet",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Kothari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Kothari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Krushnur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Kundalwadi",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431711,
  },
  {
    postOffice: "Kundarala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Kunmarpalli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Kuntur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Kupati",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Kupati",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Kurula",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431742,
  },
  {
    postOffice: "Laghul",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Laglud",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Lahan",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Lakhamapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Lakshminar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Limbgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Lingi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Loha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Loha",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Lohrala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Machandrapardi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Machnur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431711,
  },
  {
    postOffice: "Madnapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Mahore    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Malakaguda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Malakjamb",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Malakoli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Malborgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Malegaon Makata",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Malegaon",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431750,
  },
  {
    postOffice: "Malkapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Malkautha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Manatha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Mandhava",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Mandvi    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Mangalsangavi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Mangnali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Manjram",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Manoor",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Manoor",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Manur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Maralak",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Maratala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Maregaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Markhel",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Martoli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Marwali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Matul",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Mendka",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "MIDC",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431603,
  },
  {
    postOffice: "Millgate Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431601,
  },
  {
    postOffice: "Moghali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Mohapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Motarga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Mudkhed Station",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Mugaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Mugat",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Mukaramabad",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Mukhed",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Mulzara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Mungashi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Nagapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Nagzari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Naigaon    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Nanded",
    Type: "H.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431601,
  },
  {
    postOffice: "Nanded Town",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431601,
  },
  {
    postOffice: "Nandgaon Tanda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Nandur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Nandusa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Narangal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Narsi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Navandi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Nayamondha Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Nehrunagar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431742,
  },
  {
    postOffice: "Nerli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Newgha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Nichpur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Nimgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Nirala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Niwagha",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Osmannagar    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "P. Sawargaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431716,
  },
  {
    postOffice: "Padsa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Pala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Palaj",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Palsa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Palsi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Panbhoshi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Pandhara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Pandhurana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Pangara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Panshewadi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Papalwadi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Pardi KH",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Pardi Makata",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431704,
  },
  {
    postOffice: "Paroti",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Patharad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Pathari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Pawana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Penoor",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Pethwadj",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Pewa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Phulwal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431711,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Pota BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Punegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Rahati",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Raher",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Raipurtanda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Raiwadi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Rajgad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Rajura BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Ramtirth",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Ratoli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Ravangaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Ravankola",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Ravi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Risangaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Ritha",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Rohipimpalgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431806,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Rui BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Sachkhand Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431601,
  },
  {
    postOffice: "Sagroli",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431731,
  },
  {
    postOffice: "Saiphal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Sakrunail Tanda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Salegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Sangvi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Sarkhani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Sarsam",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Sattiguda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Sawaleshwar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Sawali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Sawali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Sawana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Sawarmal",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431719,
  },
  {
    postOffice: "Selgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Shahapur    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Shankarnagar",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Shekapur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Shemboli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431745,
  },
  {
    postOffice: "Sheniwarpeth",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431811,
  },
  {
    postOffice: "Shewadi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Shewala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Shikara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Shilvani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Shirad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Shiradhone",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "Shiranjani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Shirur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Shirur Dabhade",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Shivajinagar    Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Shivani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Sindgi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Sindhi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Sindkhed",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Singarwadi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Singoda",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Somthana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Sonari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Sonkhed",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "SRTMU Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431606,
  },
  {
    postOffice: "Sugaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Sugaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Sujlegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Sunegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431708,
  },
  {
    postOffice: "Tadkhel",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431717,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431736,
  },
  {
    postOffice: "Talang",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Talani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Talhari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Talni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431710,
  },
  {
    postOffice: "Tamlur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Tamsa",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Taroda BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Taroda Road Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Telki",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "Tembhurni",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431722,
  },
  {
    postOffice: "Thadihiparga",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431731,
  },
  {
    postOffice: "Thara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431810,
  },
  {
    postOffice: "Theraban",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431801,
  },
  {
    postOffice: "Totamba",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Tulshi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Tuppa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431603,
  },
  {
    postOffice: "Umara",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431707,
  },
  {
    postOffice: "Umaraj",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431714,
  },
  {
    postOffice: "Umardari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Umari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Umari",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Umari J",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Unakdev",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431805,
  },
  {
    postOffice: "Unchegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431743,
  },
  {
    postOffice: "Unchegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Undari",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Vasantnagar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Veersani",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431802,
  },
  {
    postOffice: "Vishnupuri",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431606,
  },
  {
    postOffice: "Wadepuri",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431606,
  },
  {
    postOffice: "Wadgaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Wadi BK",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431605,
  },
  {
    postOffice: "Wadoli",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Wadsa",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Waghala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431807,
  },
  {
    postOffice: "Waghi",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Wai",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431721,
  },
  {
    postOffice: "Waipana",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431713,
  },
  {
    postOffice: "Wajegaon",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Waka",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431709,
  },
  {
    postOffice: "Wakad",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431745,
  },
  {
    postOffice: "Walag",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431718,
  },
  {
    postOffice: "Walki",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431712,
  },
  {
    postOffice: "Walki",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431803,
  },
  {
    postOffice: "Wannali",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431723,
  },
  {
    postOffice: "Wanola",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431804,
  },
  {
    postOffice: "Wartala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431716,
  },
  {
    postOffice: "Warwnt",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431746,
  },
  {
    postOffice: "Wasur",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Wazar",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431741,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431715,
  },
  {
    postOffice: "Yeshwantnagar  Nanded",
    Type: "S.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431602,
  },
  {
    postOffice: "Yetala",
    Type: "B.O",
    District: "Nanded",
    State: "MAHARASHTRA",
    Pincode: 431809,
  },
  {
    postOffice: "Akkalkuwa",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425415,
  },
  {
    postOffice: "Ambapur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Amlad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Amoni",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Anarad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425410,
  },
  {
    postOffice: "Anjane",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Ankushvihir",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425415,
  },
  {
    postOffice: "Arditara",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Ashte",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Aslod",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Bahyane",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425411,
  },
  {
    postOffice: "Bamangaon",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Bamkheda T.T.",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Bandhare",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Bhade",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Bhadvad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Bhagdari",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Bhaler",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Bhandarpada",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Bhangaranpani",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Bharadu",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Bhone",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Bijari",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Biladi T.S",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425410,
  },
  {
    postOffice: "Borad",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Borale",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Borchek",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Brahmanpuri",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Chandsaily",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Chaupale",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425411,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Chikhali-BK",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Chinchpada",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Chirkan",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Chitvi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Chulvad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Dab",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Damrkheda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425422,
  },
  {
    postOffice: "Davlipada",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Dhadgaon",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Dhaite",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Dhamdai",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Dhanaje",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Dhanarat",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Dhaonra",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Dhekwad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Dhong",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Dhulwad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Dhurkheda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Dogegaon",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425424,
  },
  {
    postOffice: "Fattepur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Fes",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Gadat",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Gangapur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Gavhali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Gogapur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425424,
  },
  {
    postOffice: "Gujarbhavali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Haldani",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Jainagar",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Jamane",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425415,
  },
  {
    postOffice: "Javde-TB",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Javde-TH",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "June Mohide",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Kaharvad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Kahatul",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Kakadkut",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Kakarda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Kakarda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Kalamboo",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425410,
  },
  {
    postOffice: "Kalmadi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Kalsadi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Kansai",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Karajkupa",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Karanjali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Karanji-KH",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Karjot",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Katari",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Kathi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Kavlith",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425424,
  },
  {
    postOffice: "Khadki",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Khandbara",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Khapar",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Khekada",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Khokse",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Kholvohir",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Khondamali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Khuntamodi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Kolda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Kolde",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Kondhaval",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Koparli",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425411,
  },
  {
    postOffice: "Korit",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Kothali KH",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Kudhavad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Kukavel",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Lahan-shahada",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Lakhapur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Lokadkot",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Londhare",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Lonkheda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Mandana",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Mandvi-BK",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Manjare",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425411,
  },
  {
    postOffice: "Mhasawad    Nandurbar",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "M-mohida",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Mod",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Mograni",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Mohida",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Mohida TH",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Molgi",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Moramba",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Mothi-kadvan",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Mubarakpur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Mundalvad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Nagziri",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Nandarkhe",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Nandarkheda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425422,
  },
  {
    postOffice: "Nandurbar Bazar",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Nandurbar Kutchery",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Nandurbar",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Natavad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Navagaon",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Navapur",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Nizampur-digar",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Pachorabari",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Padalda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Palashi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Patibedaki",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Patonda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Pimpalkutha",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Pimplod",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Prakasha",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425422,
  },
  {
    postOffice: "Pratappur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Purushottam Nagar",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425424,
  },
  {
    postOffice: "Radikalam",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Raikhed",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Raingaon",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Raipur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425418,
  },
  {
    postOffice: "Raisingpur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Raj Mohi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425415,
  },
  {
    postOffice: "Rajabardi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Rajvira",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Rampur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Ranala",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425411,
  },
  {
    postOffice: "Ranipur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Samsherpur",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Sarangkheda",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425410,
  },
  {
    postOffice: "Shahada",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Shahada Town",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Shahane",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Shegave",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Shehi",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Shelti",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Shinde",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Shirud-digar",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Sidgavan",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Sisa",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Sonare Digar",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425417,
  },
  {
    postOffice: "Songirpada",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Sonwad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Srawani",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425416,
  },
  {
    postOffice: "Sudarde Digar",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Sulvade",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Survani",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Sutare",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Talai",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425414,
  },
  {
    postOffice: "Talamba",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425419,
  },
  {
    postOffice: "Taloda",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Talve",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Thanepada",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Toranmal",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425432,
  },
  {
    postOffice: "Torkheda",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Tulja",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425442,
  },
  {
    postOffice: "Umarde-KH",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Vadali    Nandurbar",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425423,
  },
  {
    postOffice: "Vadfali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425452,
  },
  {
    postOffice: "Vadfali",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Vadgaon",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425444,
  },
  {
    postOffice: "Vanyavihir",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425413,
  },
  {
    postOffice: "Vardhe",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425409,
  },
  {
    postOffice: "Vasalai",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Velli",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425415,
  },
  {
    postOffice: "Vikharan",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Visarwadi",
    Type: "S.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425426,
  },
  {
    postOffice: "Waghale",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425412,
  },
  {
    postOffice: "Wavad",
    Type: "B.O",
    District: "Nandurbar",
    State: "MAHARASHTRA",
    Pincode: 425411,
  },
  {
    postOffice: "Abhona",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Aghar BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423201,
  },
  {
    postOffice: "Aghar KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Ahergaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Ajmer Saundana",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Akhatwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Aliyabad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Ambasan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Andarsul",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423402,
  },
  {
    postOffice: "Angangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Angulgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423402,
  },
  {
    postOffice: "Ankai",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Antapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Arai",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Askheda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423303,
  },
  {
    postOffice: "Astane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Athambe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Aundane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Bahaduri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Bangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Bej",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Bhalur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Bharam",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Bhatgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Bhatgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Bhaur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Bhayale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423117,
  },
  {
    postOffice: "Bhilwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Bhutayane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Bokte",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423402,
  },
  {
    postOffice: "Bolthan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Bopegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Borale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Bordaivat",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Brahmangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423213,
  },
  {
    postOffice: "Bramanpade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423303,
  },
  {
    postOffice: "Chandanpuri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Chandora",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 424109,
  },
  {
    postOffice: "Chandwad",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Chankapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Chaugaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Chaundana",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Chikhalohal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Chinchave",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423110,
  },
  {
    postOffice: "Chinchave Galane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Chinchgavan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Chinchkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Chinchondi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Chirai",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Dabhadi",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423201,
  },
  {
    postOffice: "Dahiwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Dahiwal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Dalwat",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Dangsaundane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Daraswadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Daregaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Daswel",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Dawachwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Deogaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Deoghat",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Deopur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422308,
  },
  {
    postOffice: "Desgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Deshmane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Desrane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Devargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Devla",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Dhamode",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Dhandri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423213,
  },
  {
    postOffice: "Dhavaleshwar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423201,
  },
  {
    postOffice: "Dheku KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Dhodamba",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423117,
  },
  {
    postOffice: "Dhondgavan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Dhrangaon Veer",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Dhulgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Dighwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423110,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Dongrale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Dugaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Dyane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Erandgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Erandgaon Jeur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "G.S.S.K.",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423202,
  },
  {
    postOffice: "Gajarwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Galane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Ganore",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Ganur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Girnadam",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Girnare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423110,
  },
  {
    postOffice: "Golwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Gosrane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Harnul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Hatti",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423117,
  },
  {
    postOffice: "Hiswal BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Hiswal KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Hiwarkheda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Jaidhar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Jaikheda",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423303,
  },
  {
    postOffice: "Jalgaon Galane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423202,
  },
  {
    postOffice: "Jalgaon KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 424109,
  },
  {
    postOffice: "Jalgaon Neur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Jalgaon Nimbayati",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Jamdhari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Jawalkewani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Jopul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Jopul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Jorandigar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "K.K.Nagar    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422308,
  },
  {
    postOffice: "Kalamdari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Kalwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Kalwan",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Kanadgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Kanashi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Kandane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Kanmandale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423117,
  },
  {
    postOffice: "Kapshi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Karanjad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Karanjgavan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Karhe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Karsul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Kasari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Kashti",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Katarni",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Kathargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Kaulana",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Kaulana",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Kazisangvi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Kelzar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Kersane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Khadak Malegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422304,
  },
  {
    postOffice: "Khadakjamb",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423117,
  },
  {
    postOffice: "Khadgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Khadki",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Khakurdi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Khalap",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Khamkheda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Khamtane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Kharde Wakhari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Khayde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Khedgaon    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Khedlezung",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Khirdisathe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Khirmani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Khuntewadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Kikwari KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Kotamgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Kotbel",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Kothare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Kothure",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Kukane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Kumbharde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423110,
  },
  {
    postOffice: "Kumbhardi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422308,
  },
  {
    postOffice: "Kundalgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Kundane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Kundegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Kusmadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Kusur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Lakhamapur",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423213,
  },
  {
    postOffice: "Lasalgaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Lohoner",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Lonwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Mahalpatane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423213,
  },
  {
    postOffice: "Malegaon Camp",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Malegaon City",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423203,
  },
  {
    postOffice: "Malegaon",
    Type: "H.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423203,
  },
  {
    postOffice: "Malegaon Karyat",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Malegaon Nayapura Ward",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423203,
  },
  {
    postOffice: "Malgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Malwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Mamdapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Mandwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Manmad",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Manmad Shiwaji Chouk",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Manur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Market Yard Malegaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423203,
  },
  {
    postOffice: "Matane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Materewadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Matulthan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Mehune",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Meshi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Mohegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Mokbhangi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Morenagar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Mukhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Mukhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Mulane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Mulher",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Mungse",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423201,
  },
  {
    postOffice: "Munjwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Nagada",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Nagapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Nagarsul",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Naitale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Nampur",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Nandgaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Nandur Madhmeshwar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Nandurdi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422308,
  },
  {
    postOffice: "Nanduri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Narul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Naydongri",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 424109,
  },
  {
    postOffice: "Neminagar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Nimbayati",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Nimgaon Madh",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Nimgaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Nimon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Niphad",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Nitane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Nivane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Otur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Ozar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Pachore BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Pachorewani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Padalde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Palasdare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Pale BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Palkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Pardhadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 424109,
  },
  {
    postOffice: "Pate",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Patne",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423201,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Pilkos",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423202,
  },
  {
    postOffice: "Pimpalgaon Baswant",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Pimpalgaon Jalal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Pimpalgaon Wakhari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Pimpalkotha",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Pimpalnare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423111,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 424109,
  },
  {
    postOffice: "Pimplad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Pimple KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423502,
  },
  {
    postOffice: "Pohane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Pokhari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Puri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Raipur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Rajapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423403,
  },
  {
    postOffice: "Rajapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Rajmane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Rameshwar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Ranwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422308,
  },
  {
    postOffice: "Ravalgaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423108,
  },
  {
    postOffice: "Ravalji",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Redgaon BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423111,
  },
  {
    postOffice: "Redgaon KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Ronzane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Sakora",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Sakore",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Sakorewani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Sakuri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Salher",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Sangmeshwar Malegaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423203,
  },
  {
    postOffice: "Sapthshringigarh",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Sarde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Sarolethadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Satana Market",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Satana",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Saundana",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Savargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Savargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Savkarwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Savki  Lohoner",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Saygaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423402,
  },
  {
    postOffice: "Sayne BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Shelu",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Shemli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423213,
  },
  {
    postOffice: "Sherul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Shindwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Shingve",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Shirasgaon Lauki",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Shirasmani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Shirpurwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Shirsondi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Shirwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423111,
  },
  {
    postOffice: "Shivare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422303,
  },
  {
    postOffice: "Sogras",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Sompur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423303,
  },
  {
    postOffice: "Somthandesh",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Sonaj",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Sonjamb",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Soygaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Suregaon Rasta",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423402,
  },
  {
    postOffice: "Taharabad",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Takali KH",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Talegaon Rui",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Talwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423108,
  },
  {
    postOffice: "Talwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Talwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Talwadedigar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423303,
  },
  {
    postOffice: "Tehare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Tembhe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Thangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Thengoda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Tilwan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Tingri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Tisgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Tisgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423110,
  },
  {
    postOffice: "Tokade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Ugaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422304,
  },
  {
    postOffice: "Umbarkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422209,
  },
  {
    postOffice: "Umrana",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423110,
  },
  {
    postOffice: "Undirwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423402,
  },
  {
    postOffice: "Uswad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Utrane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423204,
  },
  {
    postOffice: "Vadali Bhoi",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423117,
  },
  {
    postOffice: "Vadali BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Vadbare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Vadel",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Vadgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Vadgaon Pangu",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Vadner Bhairav",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423111,
  },
  {
    postOffice: "Vadner Malegaon",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Vahegaon Sal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422306,
  },
  {
    postOffice: "Vajirkheda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423105,
  },
  {
    postOffice: "Valwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Vanasgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422304,
  },
  {
    postOffice: "Vanjarwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423104,
  },
  {
    postOffice: "Vanoli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Varkheda",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422205,
  },
  {
    postOffice: "Vasol",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Vaygaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423108,
  },
  {
    postOffice: "Vehelgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Vikharni",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Vinchur",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Virane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423206,
  },
  {
    postOffice: "Virgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Visapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423501,
  },
  {
    postOffice: "Vitave",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423101,
  },
  {
    postOffice: "Vithewadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423301,
  },
  {
    postOffice: "Waghamba",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423302,
  },
  {
    postOffice: "Wajgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Wakad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422305,
  },
  {
    postOffice: "Wakhari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Wakhari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423106,
  },
  {
    postOffice: "Warwandi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423102,
  },
  {
    postOffice: "Yeola Kutcheri Road",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Yeola",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423401,
  },
  {
    postOffice: "Yesgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423212,
  },
  {
    postOffice: "Zadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423208,
  },
  {
    postOffice: "Zodga",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 423205,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Agaskhind",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Ahiwantwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Akrale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Alangun",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Alwand",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Amba  Peint",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Amba",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Ambad A.S.",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422010,
  },
  {
    postOffice: "Ambegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Ambode",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Amboli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Ambupada",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Amlon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Anjneri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Asarbari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Ashewadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Ashok Nagar    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422012,
  },
  {
    postOffice: "Aswali Harsh",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Aswali R.S",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Awankhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "B.S. Nagar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422301,
  },
  {
    postOffice: "Baragaonpimpri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Barhe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Barnes High School",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422401,
  },
  {
    postOffice: "Belatgavhan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422401,
  },
  {
    postOffice: "Belgaon Tarhale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Berwal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Bhagur",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Bhanwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Bharvir BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Bhatode",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Bhavali Khurd",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Bhendali",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422210,
  },
  {
    postOffice: "Bhojapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Bhonsala Military School",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422005,
  },
  {
    postOffice: "Bhormal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Bhuvan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Borwat",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Boy's Town",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Brahmanwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Brahmanwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Bubli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Budhwar Peth",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422001,
  },
  {
    postOffice: "Chachadgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Chandori",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422201,
  },
  {
    postOffice: "Chas",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Chatori",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422210,
  },
  {
    postOffice: "Chausale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Chinchohol",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Chinchwade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Chitegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Cidco Colony    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422009,
  },
  {
    postOffice: "Dahiwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Dalpatpur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Dapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Dari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Darnasangvi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Datli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422112,
  },
  {
    postOffice: "Deodongra",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Devargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Devgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422214,
  },
  {
    postOffice: "Devlali Air Force",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422501,
  },
  {
    postOffice: "Devlali Bazar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422401,
  },
  {
    postOffice: "Devlali",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422401,
  },
  {
    postOffice: "Devpur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Devsane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Devthan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Dhakambe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Dhondbhar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Dhondmal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Dindori    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Dixi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422302,
  },
  {
    postOffice: "Dodi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Dondegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Dubera",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Dugaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Dwarka Corner",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422011,
  },
  {
    postOffice: "Gandhi Nagar  Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422006,
  },
  {
    postOffice: "Gandole",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Gangamhalungi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Gangapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422222,
  },
  {
    postOffice: "Ghoti",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Girnara",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Gole Colony",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422002,
  },
  {
    postOffice: "Gonde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Gonde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Gondedumala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Gulvanch",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422112,
  },
  {
    postOffice: "H P T College",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422005,
  },
  {
    postOffice: "Harsul",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Haste",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Haste",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Hatgad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Hatrundi  Peint",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Hatrundi  Surgana",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Hatti",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "I.A. Musalgaon Sinnar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422112,
  },
  {
    postOffice: "Idgaon Devlali",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422101,
  },
  {
    postOffice: "Igatpuri",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Ind. Est. Malegaon Sinnar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422113,
  },
  {
    postOffice: "India Security Press",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422101,
  },
  {
    postOffice: "Indira Nagar    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422009,
  },
  {
    postOffice: "Indore",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Industrial Estate    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Jahule",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Jaigaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Jakhori",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Jalkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Jambutke",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Janori",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Jategaon BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Jaulke",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Jeevan Prakash",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422002,
  },
  {
    postOffice: "Jogalthembi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Kadwanagar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Kalaram Mandir",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Kalmuste",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Kaluste",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Kankuri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Karanjali",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Karanjaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422210,
  },
  {
    postOffice: "Karanjwan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Kaudasar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Kavnai",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Kazimale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Khadak Sukena",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Khadked",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Khairgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Khambala Anjeri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Khambale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Kharshet",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Kharwal",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Khatwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Khed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Khedle",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Kherwadi R.S",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422201,
  },
  {
    postOffice: "Khirad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Khopadi BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422112,
  },
  {
    postOffice: "Kochargaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Kohor",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Kokangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Konambe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Kopurli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Korahte",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Korapgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Koshimbe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Kotamgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422105,
  },
  {
    postOffice: "Kukudane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Kulwandi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Kumbhale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Lahavit",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Lakhalgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Lakhmapur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Lonarwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Madhmeshwar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Madkijamb",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Madsangvi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Mahaje",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Mahiravani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Makhmalabad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Maledumala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Malegaon  Peint",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Manegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Mangone",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Mani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Mankhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Marhal BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Matori",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Mavdi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Meri Colony",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Mhalsakore",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Mhasgan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Mhasrul",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Mithsagare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Mohadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Mohpada",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "MUHS",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Mukane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Mundhegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Mungsara",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Murambi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Musalgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422112,
  },
  {
    postOffice: "Nachlondi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Nalwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Nalwadpada",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Nanashi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Nandurshingote",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Nanegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Nashik City",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422001,
  },
  {
    postOffice: "Nashik",
    Type: "H.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422001,
  },
  {
    postOffice: "Nashik Main Road",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422001,
  },
  {
    postOffice: "Nashik Road Camp",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Nashik Road",
    Type: "H.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422101,
  },
  {
    postOffice: "Nehru Nagar    Nashik",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422101,
  },
  {
    postOffice: "Nigdol",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Nilwandi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Nimgaon  Sinnar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Nirgude",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Nirhale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422606,
  },
  {
    postOffice: "Odha",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Ojhar A.F. Stn.",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422221,
  },
  {
    postOffice: "Ojhar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422206,
  },
  {
    postOffice: "Ojhar T.S.",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Ojharkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Ojharkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Oney",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422302,
  },
  {
    postOffice: "Pade",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Pahuchibari",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Palasvihir",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Palkhed Bandhara",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Palsan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Palse",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Panchale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Panchvati",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Pandane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Pandurli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Pangarne",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Pangri BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Paregaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Paste",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422113,
  },
  {
    postOffice: "Pathardi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422010,
  },
  {
    postOffice: "Pathare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Patole",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Peint",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Pimpale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Pimpalgaon Bahula",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Pimpalgaon Dukra",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Pimpalgaon Garudeshwar",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Pimpalgaon Khamb",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422010,
  },
  {
    postOffice: "Pimpalgaon Nipani",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Pimpalnare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Pimplad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422010,
  },
  {
    postOffice: "Pimplus",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422301,
  },
  {
    postOffice: "Pimpri -achala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422301,
  },
  {
    postOffice: "Pimpri-sayyed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Police Training School",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Puria Park",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Rakshasbhuvan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Rasegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Rohile",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Sakur",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Salbhoye",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Samundi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Sanjegaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Sapte",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Sarad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Satkhamb",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Satpur Township",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Sawarkar Nagar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422013,
  },
  {
    postOffice: "Sayale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Saykheda",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422210,
  },
  {
    postOffice: "Shaha",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Shenit",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Shevgedang",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Shinde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Shingwe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422210,
  },
  {
    postOffice: "Shirasgaon  Peint",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Shirasgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Shivde",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Shivre",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Shribhuvan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Sinnar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Somthane",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Sonambe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Sukena",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422302,
  },
  {
    postOffice: "Surgana  Peint",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Surgana",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Taked BK",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Talegaon  Igatpuri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Talegaon  Trimbak",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Talegaon Anjneri",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Talegaon Dindori",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422004,
  },
  {
    postOffice: "Tamaswadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Thana  Peint",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422204,
  },
  {
    postOffice: "Thangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422007,
  },
  {
    postOffice: "Thangaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Titwe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Torangan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "TP Station Eklahre",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422105,
  },
  {
    postOffice: "Trimbak",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Trimbak Vidya Mandir",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422213,
  },
  {
    postOffice: "Trimurti Chowk",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422008,
  },
  {
    postOffice: "Tringalwadi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Udhoji Maratha Boarding",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422005,
  },
  {
    postOffice: "Umbarpada",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Umbarthan",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422211,
  },
  {
    postOffice: "Umrala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Upnagar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422006,
  },
  {
    postOffice: "Usthale",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422208,
  },
  {
    postOffice: "Vadangli",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422103,
  },
  {
    postOffice: "Vadgaon Pingala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Vadner Dumala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422401,
  },
  {
    postOffice: "Vadzire",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422102,
  },
  {
    postOffice: "Vaitarna Nagar",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422214,
  },
  {
    postOffice: "Vani Khurd",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Vani",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422215,
  },
  {
    postOffice: "Velunje",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Vilholi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422010,
  },
  {
    postOffice: "Vilwandi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Vinchuri-dalvi",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422502,
  },
  {
    postOffice: "Wadala",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422006,
  },
  {
    postOffice: "Wadali Nazik",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422207,
  },
  {
    postOffice: "Wadgaon",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Wadivarhe",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Waghare",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422403,
  },
  {
    postOffice: "Waghdad Dam",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422003,
  },
  {
    postOffice: "Waghera",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422203,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Walkhed",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Ware",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422202,
  },
  {
    postOffice: "Wasali",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422402,
  },
  {
    postOffice: "Wavi Harsh",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422214,
  },
  {
    postOffice: "Wavi",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422104,
  },
  {
    postOffice: "Ycm Open Univesity",
    Type: "S.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422222,
  },
  {
    postOffice: "Zarwad",
    Type: "B.O",
    District: "Nashik",
    State: "MAHARASHTRA",
    Pincode: 422212,
  },
  {
    postOffice: "Achler",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Ajni  BK",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Akulga Rani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Alani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Aloor",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Ambejavalge",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Ambhi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Anala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Anandnagar    Osmanabad",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Andora",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Andora",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Andori",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Ankulga Sayed",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Ansarwada",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Ansurda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Antargaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Antarwali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Apsinga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Arali Bk.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Arali Khurd",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Arangaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Arani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Ashta  Kasar",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Ashta",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Ashta",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413581,
  },
  {
    postOffice: "Asu",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Aurad",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Awad Shirpura",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413528,
  },
  {
    postOffice: "Babhalgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Balsur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Bamni",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Bamniwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Barul",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Bavi  YRM",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Bavi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Bawachi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Bedga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Belamb",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Bembli",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Bhandarwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Bhatangali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Bhatangli",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Bhatsirpura",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Bhavani Mandir-Tuljapur",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Bhokrama",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Bhoom",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Bhusani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Bhutmugali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Bittergaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 431522,
  },
  {
    postOffice: "Bokangaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Borda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Borgaon  NLD",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Borgaon Dargi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Bori",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Bukanwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Chera",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Chilwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Chincali  S",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Chincholi Bhuyar",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Chincholi Jahagir",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Chincholi Pan",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Chincholi Tapse",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Chinchpur Bk.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Chinchpur Dhage",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Chivari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Chorakhali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413405,
  },
  {
    postOffice: "Dahiphal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Dalimb",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Darphal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Dasmegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Dastapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Deodhanora",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Deokurali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Deolali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Deolali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Deolali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Deosinga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Devangra",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413581,
  },
  {
    postOffice: "Dhalegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Dhanuri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Dharur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Dhoki",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Dhorala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Diggi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Dighoi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Domgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Donja",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Ekurga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Gad  Sonegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Gandhora",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Ganegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Gangapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Ganjur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413581,
  },
  {
    postOffice: "Gaur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Ghargaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413528,
  },
  {
    postOffice: "Ghatangri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Ghatpimpri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413534,
  },
  {
    postOffice: "Ghodki",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Girwali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413526,
  },
  {
    postOffice: "Gojwada",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Gour",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Govindpur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Gunjoti",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Hadga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Hadolti",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Hadongri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Hagloor",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Halki",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Hangarga  NLD",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Harangai BK",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Harangul KH",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Harwargaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Hasori BK",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Hatola",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413526,
  },
  {
    postOffice: "Hatterga Halsi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Hingalajwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Hipparga Rava",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Hipperga Sayad",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Hismabad",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Holi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Horti",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Ida-Pida",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Ieet",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413534,
  },
  {
    postOffice: "Indapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Irla",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Itkal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Itkur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Izora",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Jagaji",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Jagalpur  BK",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Jakekur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Jalkot  NLD",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Jawala  Nizamudin",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Jawala Kh.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Jewali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Jewali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Jewari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Kadaknathwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Kadepur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 431522,
  },
  {
    postOffice: "Kadher",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Kajala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Kakramba",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Kaldeo Nimbala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Kallam",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Kallambgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Kamegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Kamkheda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 431522,
  },
  {
    postOffice: "Kandari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Kanegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Kangara",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Kanherwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Kapilapuri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Karajkheda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Karanjkalla",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Karla",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Kasagi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Kasarkheda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Kasasr Belkunda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Kasbe Tadvale",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413405,
  },
  {
    postOffice: "Kasti Bk.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Katgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Katgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Kati",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413624,
  },
  {
    postOffice: "Kawa",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Kawatha  OMG",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Kesar Javalga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Keshegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Keshegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Khadgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Khamaswadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Khamgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Kharosa",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Khasapuri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Khasgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Khed",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Khudawadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Kilaj",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Kini",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Kini Thot",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Kodli",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413532,
  },
  {
    postOffice: "Kolsur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Kond",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Koral",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Korali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Koshtgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Kothala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413528,
  },
  {
    postOffice: "Kothali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Kukadgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Kumbhari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Kumtha  BK",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Kunhali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Kunthalgiri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Lakadjawalga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Lakhangaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413526,
  },
  {
    postOffice: "Lanjeshwar",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413534,
  },
  {
    postOffice: "Lodga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Lohara",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Lohata  East",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Madaj",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Madansuri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Mahapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Makani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Malgi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Malkapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Malkaranja",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Malumbra",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Mandva",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Mangrool  TJR",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Mangrool",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Mankeshwar",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Mardi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Masla Khurd",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413624,
  },
  {
    postOffice: "Massa Khandeshwari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Medsinga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Mendha",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Mogara",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Moha",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Morta",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Mudgad Ramling",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Mulaj",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Murum",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Nadi Hatterga",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Nagur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Nai Chakur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413510,
  },
  {
    postOffice: "Naldurg",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Nanand",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Nanduri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Narangwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Nelwad",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Nilegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Nipani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413510,
  },
  {
    postOffice: "Nipani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413534,
  },
  {
    postOffice: "Nitali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Omerga Bazar",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Omerga Chivari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Omerga",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Osmanabad",
    Type: "H.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413501,
  },
  {
    postOffice: "Osmanabad Town",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413501,
  },
  {
    postOffice: "Pach Pimpla",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Padoli",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Padoli",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413510,
  },
  {
    postOffice: "Pakhrud",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413534,
  },
  {
    postOffice: "Palsap",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Pangaon  YRM",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Para",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Paranda",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Pargaon",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413526,
  },
  {
    postOffice: "Pat Sangvi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Patharwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 431522,
  },
  {
    postOffice: "Pathrud",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Peth",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Peth Sangvi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Pimpalgaon  Kothawale",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Pimpalgaon Kamleshwari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413526,
  },
  {
    postOffice: "Pimpalgaon Lingi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Pimpalwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Pimpla Khurd",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Poharegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Pohner",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Ranjani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413528,
  },
  {
    postOffice: "Rathoda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Ratnapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Rohina",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Rohkal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Ruibhar",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Sakol",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Salegaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Salgara Divti",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Salgara Maddi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Samudrawani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Sangvi Kati",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Sangvi Mardi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Sanja",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Sapnai",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Saramkundi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Sarola Bk",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Sarola Mandva",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Sastur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Satala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413523,
  },
  {
    postOffice: "Satephal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413405,
  },
  {
    postOffice: "Sawargaon  Shiradhon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413528,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Sawargaon Kati",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413624,
  },
  {
    postOffice: "Sawargaon Thot",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Selu",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413507,
  },
  {
    postOffice: "Shahapur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Shedol",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Shelgaon Sonari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Shingoli",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Shiradhon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Shiradhon",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413528,
  },
  {
    postOffice: "Shirala",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Shivani BK",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Shivani Kothal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Shivani Lakh",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Sihdhakhed",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Sindgaon  NLD",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Sindphal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Sirsav",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Sonari    Osmanabad",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Songiri",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Sonwati",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Sukani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413518,
  },
  {
    postOffice: "Sukta",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Sumbha",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Sundarwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413605,
  },
  {
    postOffice: "Suratgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Surdi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413517,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Takviki",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413506,
  },
  {
    postOffice: "Talani",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413516,
  },
  {
    postOffice: "Talikhed",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Talmod",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Talni",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 431522,
  },
  {
    postOffice: "Tamalwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Tandulwadi  Sonari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Tandulwadi  Vasi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Tavshigad",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Tawarajkheda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413511,
  },
  {
    postOffice: "TBSSK Naldurg",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Ter",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Terkheda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Ternanagar",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413580,
  },
  {
    postOffice: "Tirth Bk.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Tirth kh.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Toramba",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Trikoli",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Tugaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Tugaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413508,
  },
  {
    postOffice: "Tuljapur",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Turori",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413606,
  },
  {
    postOffice: "Ujlamb",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413524,
  },
  {
    postOffice: "Uloop",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Upla  M",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Usturi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413607,
  },
  {
    postOffice: "Vashi    Osmanabad",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413503,
  },
  {
    postOffice: "Vidyanagar    Osmanabad",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413501,
  },
  {
    postOffice: "Wadgaon  G",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413608,
  },
  {
    postOffice: "Wadgaon  S",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Wadgaon Deo.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Wadgaon Jahagir",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413405,
  },
  {
    postOffice: "Wadgaon Kati",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413623,
  },
  {
    postOffice: "Wadgaon Lakh",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413601,
  },
  {
    postOffice: "Wagdari  NLD",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413602,
  },
  {
    postOffice: "Wagholi  YDS",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413405,
  },
  {
    postOffice: "Wagholi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Waigaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Walsangi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Walwad",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Wanewadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Wangdhari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413527,
  },
  {
    postOffice: "Wangi Bk.",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413502,
  },
  {
    postOffice: "Wanjarwada",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413514,
  },
  {
    postOffice: "Wanjarwadi",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Ware Wadgaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413504,
  },
  {
    postOffice: "Waruda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413582,
  },
  {
    postOffice: "Wasangaon",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413531,
  },
  {
    postOffice: "Watephal",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413505,
  },
  {
    postOffice: "Wathoda",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413510,
  },
  {
    postOffice: "Yedshi",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413405,
  },
  {
    postOffice: "Yelnoor",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413521,
  },
  {
    postOffice: "Yenegur",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413604,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413603,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413509,
  },
  {
    postOffice: "Yermala",
    Type: "S.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413525,
  },
  {
    postOffice: "Yerola",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413544,
  },
  {
    postOffice: "Zari",
    Type: "B.O",
    District: "Osmanabad",
    State: "MAHARASHTRA",
    Pincode: 413529,
  },

  {
    postOffice: "Aherborgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Aherwadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Ambarwadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Arvi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },
  {
    postOffice: "Asegaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Asola",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Badwani",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Ballur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Bamni",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Banvas",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Bhahulgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Bharaswada",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Bhogaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431537,
  },
  {
    postOffice: "Bhogaon Devi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Bhosi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Bobde Takli",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },
  {
    postOffice: "Borda",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Bori    Parbhani",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Borkini",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Borwand BK",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Brahmangaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Chandaj",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Charthana",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Chatori",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Chikalthana",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Chincholi Niloba",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Chudawa",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Daithana",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Dasala",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Devalgaon Awchar",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Devalgaon Dudhate",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Devalgaon Gat",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Dhangar Takli",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Dhar",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Dharasur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Dhasadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Dhengali Pimpalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Dighol Islampur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Dongarpimpla",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Dudhgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },
  {
    postOffice: "Erandeshwar",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Gadadgavan",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Gangakhed",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Gaundgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Gaur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Ghantangra",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Gomewakdi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Gugali Dhamangaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Gunj KH",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Hadgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Hamdapur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Harangul",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Hatnoor",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Isad",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Itoli",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431537,
  },
  {
    postOffice: "Jamb BK",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Jintur",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Jogwada",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "K Adgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Kalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Kanegaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Kansur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Karanji",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Karegaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Kasapuri",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Katneshwar",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Kausadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Kavi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Kawada",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Kawalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Kehal",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Kekar Jawala",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Kerwadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Khadka",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Khali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Khavane Pimpri",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Kinhola",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Kodri",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Kok",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Kolha",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Kothala",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Kranti Chowk-Parbhani",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431401,
  },
  {
    postOffice: "Kundi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Kupta",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Limba",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Mahatpuri",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Majlapur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Makhani",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Malewadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Mandakhali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431537,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Manoli",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Manwath",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Manwath Town",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Mardasgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Mategaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Mau Parbhani",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Mirkhel",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Moregaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Mudgal",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Muli",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Naikota",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Nandapur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },
  {
    postOffice: "Nandkheda",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Narwadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Nathra",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Nava",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Naya Mondha Parbhani",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431401,
  },
  {
    postOffice: "Neel",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Nivali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Pachalegaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Pachegaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Palam",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Palodi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Pangri",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Parbhani",
    Type: "H.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431401,
  },
  {
    postOffice: "Parbhani Town",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431401,
  },
  {
    postOffice: "Pathargavan",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Pathri",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Pedgaon",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431537,
  },
  {
    postOffice: "Pethpimpalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Pethshivani",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Pharkanda",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Pimpaldari",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431537,
  },
  {
    postOffice: "Pimpalgaon Gayke",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Pimpalgaon Kajale",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Pimpla Lokhande",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Pimpri Deshmukh",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Pimprizola",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Pingali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Pokharni",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Pungala",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Purna",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431511,
  },
  {
    postOffice: "Rametakli",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Rampuri BK",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Rampuri KH",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Ranisawargaon",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Raorajura",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Rawalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Renakhali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Renapur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Rudhi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Sadegaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },
  {
    postOffice: "Sailu",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Sailu",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Sailu Town",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Salapuri",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Sawali BK",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Sawangi Bhambale",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Shewadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Shikh Rajura",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431720,
  },
  {
    postOffice: "Shivajinagar Parbhani",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431401,
  },
  {
    postOffice: "Simangaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Singanapur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Sirsam",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Sirshi BK",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Sonna",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431503,
  },
  {
    postOffice: "Sonpeth",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431516,
  },
  {
    postOffice: "Station Parbhani",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431401,
  },
  {
    postOffice: "Suppa",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Tadborgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Tadkalas",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Takli Kumbhakarna",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Taroda",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431402,
  },
  {
    postOffice: "Ukhali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Ukhali",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Ukkadgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431536,
  },
  {
    postOffice: "Ukkalgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Umari",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431521,
  },
  {
    postOffice: "Vassa",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Vita",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Wadgaon",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Wadi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431506,
  },
  {
    postOffice: "Waghala",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431541,
  },
  {
    postOffice: "Waghi",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Waghi Bobde",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431508,
  },
  {
    postOffice: "Warud",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431509,
  },
  {
    postOffice: "Wazar",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Wazur",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431514,
  },
  {
    postOffice: "Yeldari",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431510,
  },
  {
    postOffice: "Zari",
    Type: "B.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431505,
  },
  {
    postOffice: "Zari",
    Type: "S.O",
    District: "Parbhani",
    State: "MAHARASHTRA",
    Pincode: 431540,
  },

  {
    postOffice: "9 DRD",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411014,
  },
  {
    postOffice: "AFMC",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411040,
  },
  {
    postOffice: "Airport    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411032,
  },
  {
    postOffice: "Akurdi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411035,
  },
  {
    postOffice: "Ammunition Factory Khadki",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411003,
  },
  {
    postOffice: "Aundh Camp",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411027,
  },
  {
    postOffice: "Bhosari I.E.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411026,
  },
  {
    postOffice: "Bhosarigoan",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411039,
  },
  {
    postOffice: "Bibvewadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411037,
  },
  {
    postOffice: "C D A  O",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "C M E",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411031,
  },
  {
    postOffice: "Chikhali",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411062,
  },
  {
    postOffice: "Chinchwad East",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411019,
  },
  {
    postOffice: "Chinchwadgaon",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411033,
  },
  {
    postOffice: "Dapodi Bazar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411012,
  },
  {
    postOffice: "Dapodi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411012,
  },
  {
    postOffice: "Dhanori",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411015,
  },
  {
    postOffice: "Dighi Camp",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411015,
  },
  {
    postOffice: "Dr.B.A. Chowk",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Dukirkline",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411014,
  },
  {
    postOffice: "East Khadki",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411003,
  },
  {
    postOffice: "Ghorpuri Bazar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Gondhale Nagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411028,
  },
  {
    postOffice: "H.E. Factory",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411003,
  },
  {
    postOffice: "Hadapsar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411028,
  },
  {
    postOffice: "Hadpsar I.E.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411013,
  },
  {
    postOffice: "Iaf Station",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411032,
  },
  {
    postOffice: "Indrayaninagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411026,
  },
  {
    postOffice: "Infotech  Park  Hinjawadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411057,
  },
  {
    postOffice: "Jambhe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411033,
  },
  {
    postOffice: "Kalewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411017,
  },
  {
    postOffice: "Kasarwadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411034,
  },
  {
    postOffice: "Khadki Bazar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411003,
  },
  {
    postOffice: "Khadki",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411003,
  },
  {
    postOffice: "Khondhwa KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411048,
  },
  {
    postOffice: "Kondhwa BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411048,
  },
  {
    postOffice: "Kondhwa Lh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411048,
  },
  {
    postOffice: "Lohogaon",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411047,
  },
  {
    postOffice: "M.Phulenagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411019,
  },
  {
    postOffice: "Market Yard    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411037,
  },
  {
    postOffice: "Marunji",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411057,
  },
  {
    postOffice: "Masulkar Colony",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411018,
  },
  {
    postOffice: "Mohamadwadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411060,
  },
  {
    postOffice: "Mundhva AV",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411036,
  },
  {
    postOffice: "Mundhva",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411036,
  },
  {
    postOffice: "N I B M",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411048,
  },
  {
    postOffice: "N.W. College",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Nehrunagar    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411018,
  },
  {
    postOffice: "P.C.N.T.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411044,
  },
  {
    postOffice: "Pimple Gurav",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411061,
  },
  {
    postOffice: "Pimpri Colony",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411017,
  },
  {
    postOffice: "Pimpri P F",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411018,
  },
  {
    postOffice: "Pimpri Waghire",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411017,
  },
  {
    postOffice: "Punawale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411033,
  },
  {
    postOffice: "Pune Cantt East",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Pune",
    Type: "H.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Pune New Bazar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Rupeenagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411062,
  },
  {
    postOffice: "Sachapir Street",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411001,
  },
  {
    postOffice: "Salisbury Park",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411037,
  },
  {
    postOffice: "Sangavi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411027,
  },
  {
    postOffice: "Sasanenagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411028,
  },
  {
    postOffice: "Srpf",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411022,
  },
  {
    postOffice: "T.V. Nagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411037,
  },
  {
    postOffice: "Talwade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411062,
  },
  {
    postOffice: "Thathawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411033,
  },
  {
    postOffice: "Thergaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411033,
  },
  {
    postOffice: "Vadgaon Sheri",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411014,
  },
  {
    postOffice: "Vidyanagar    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411032,
  },
  {
    postOffice: "Viman nagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411014,
  },
  {
    postOffice: "Vishrantwadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411015,
  },
  {
    postOffice: "Wakad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411057,
  },
  {
    postOffice: "Wanowarie",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411040,
  },
  {
    postOffice: "Yamunanagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411044,
  },
  {
    postOffice: "Yerwada",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411006,
  },
  {
    postOffice: "Yerwada T.S.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411006,
  },
  {
    postOffice: "A.R. Shala",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411004,
  },
  {
    postOffice: "Ambegaon BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411046,
  },
  {
    postOffice: "Anandnagar    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411051,
  },
  {
    postOffice: "Armament",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411021,
  },
  {
    postOffice: "Aundh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411007,
  },
  {
    postOffice: "Aundh T.S.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411007,
  },
  {
    postOffice: "Bajirao Road",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411002,
  },
  {
    postOffice: "Baner Gaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411045,
  },
  {
    postOffice: "Baner Road",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411008,
  },
  {
    postOffice: "Bavdhan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411021,
  },
  {
    postOffice: "Bhavani Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411042,
  },
  {
    postOffice: "Bhusari Colony",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411038,
  },
  {
    postOffice: "Botanical Garden    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411020,
  },
  {
    postOffice: "Congress House Road",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411005,
  },
  {
    postOffice: "Deccan Gymkhana",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411004,
  },
  {
    postOffice: "Dhankawadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411043,
  },
  {
    postOffice: "Dhayari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411041,
  },
  {
    postOffice: "Ex. Serviceman Colony",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411038,
  },
  {
    postOffice: "Film Institute",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411004,
  },
  {
    postOffice: "Ganeshkhind",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411007,
  },
  {
    postOffice: "Ghorpade Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411042,
  },
  {
    postOffice: "Gokhalenagar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411016,
  },
  {
    postOffice: "Govt. Polytechnic",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411016,
  },
  {
    postOffice: "Guruwar Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411042,
  },
  {
    postOffice: "Kapad Ganj",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411002,
  },
  {
    postOffice: "Karvenagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411052,
  },
  {
    postOffice: "Kasba Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411011,
  },
  {
    postOffice: "Katraj",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411046,
  },
  {
    postOffice: "Khadakwasla R.S.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411024,
  },
  {
    postOffice: "Kothrud",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411038,
  },
  {
    postOffice: "Kudje",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411023,
  },
  {
    postOffice: "Lokmanyanagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411030,
  },
  {
    postOffice: "Mandvi BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411023,
  },
  {
    postOffice: "Mangalwar Peth    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411011,
  },
  {
    postOffice: "Model Colony",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411016,
  },
  {
    postOffice: "N.C.L. Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411008,
  },
  {
    postOffice: "N.D.A. Khadakwasla",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411023,
  },
  {
    postOffice: "N.I.A.",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411045,
  },
  {
    postOffice: "Nana Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411002,
  },
  {
    postOffice: "Nanded",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411041,
  },
  {
    postOffice: "Narayan Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411030,
  },
  {
    postOffice: "Navsahyadri",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411052,
  },
  {
    postOffice: "Parvati Gaon",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411009,
  },
  {
    postOffice: "Parvati",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411009,
  },
  {
    postOffice: "Pune City",
    Type: "H.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411002,
  },
  {
    postOffice: "Range Hills",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411020,
  },
  {
    postOffice: "Rashtra Bhasha Bhavan",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411030,
  },
  {
    postOffice: "Rasta Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411011,
  },
  {
    postOffice: "Raviwar Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411002,
  },
  {
    postOffice: "S.P. College",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411030,
  },
  {
    postOffice: "S.S.C.Exam Board",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411005,
  },
  {
    postOffice: "Sadashiv Peth",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411030,
  },
  {
    postOffice: "Shaniwar Peth    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411030,
  },
  {
    postOffice: "Shivaji Housing Society",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411016,
  },
  {
    postOffice: "Shivajinagar    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411005,
  },
  {
    postOffice: "Shivane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411023,
  },
  {
    postOffice: "Shukrawar Peth    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411002,
  },
  {
    postOffice: "Sinhgad Technical Education Society",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411041,
  },
  {
    postOffice: "Sus",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411021,
  },
  {
    postOffice: "Swargate Chowk",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411042,
  },
  {
    postOffice: "Swargate",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411042,
  },
  {
    postOffice: "Uttamnagar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411023,
  },
  {
    postOffice: "Vadgaon Budruk",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411041,
  },
  {
    postOffice: "Warje",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411058,
  },
  {
    postOffice: "Adhale BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Adivare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Agoti",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413132,
  },
  {
    postOffice: "Ala    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412411,
  },
  {
    postOffice: "Alande",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Alandi Chorachi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412201,
  },
  {
    postOffice: "Alandi Devachi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Alegaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Alegaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Alephata",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412411,
  },
  {
    postOffice: "Ambade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Ambale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Ambarvet",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Ambavade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Ambavane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410401,
  },
  {
    postOffice: "Ambavane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Ambeghar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Ambethan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Ambi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Amble",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Amboli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Amboli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Amdabad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412218,
  },
  {
    postOffice: "Amondi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412408,
  },
  {
    postOffice: "Anantnagar Nigade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Andgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Andhalgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Ane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412410,
  },
  {
    postOffice: "Anjanavale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Anthurne",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Antroli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412212,
  },
  {
    postOffice: "Aptale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Apti",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Arvi    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412401,
  },
  {
    postOffice: "Arvi Haveli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Asane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Ashtapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Audar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Avasari BK",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412406,
  },
  {
    postOffice: "Avhat",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Avsari KH",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412405,
  },
  {
    postOffice: "Babhurdi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412204,
  },
  {
    postOffice: "Bahul",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Ballalwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Baramati Court",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Baramati MIDC",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413133,
  },
  {
    postOffice: "Baramati",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Barhanpur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Bari BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Bawada",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Bazarwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Beam Wirelee Station",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Bebedhol",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Belawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Belawadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Belha",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412410,
  },
  {
    postOffice: "Belsar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Bet Narayan Maharaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Bhadas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Bhamburde",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Bhandgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Bhandgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Bhavaninagar    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Bhigwan R S",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413105,
  },
  {
    postOffice: "Bhigwan",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Bhimashankar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Bhivadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Bhivari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Bhodani",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Bhondave Wadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412204,
  },
  {
    postOffice: "Bhongavali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Bhor",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Bhorghar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Bhorgiri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Bhose",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Bhoyare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Bhugaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Bhukum",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Bhutonde",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Bibi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410513,
  },
  {
    postOffice: "Bijawadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Bopgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Bori Aindi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Bori",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Bori BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412411,
  },
  {
    postOffice: "Bori Paradhi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Boribyal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Burunjwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Chakan",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Chambli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Chandkhed",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Chandoli BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410503,
  },
  {
    postOffice: "Charoli BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Chas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410515,
  },
  {
    postOffice: "Chas    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410513,
  },
  {
    postOffice: "Chavand",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Chikhalgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Chilewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Chimbli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Chinchani Camp",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412218,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Chinchoshi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Cod Dehu Road",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412101,
  },
  {
    postOffice: "Dahitane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Dalaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413132,
  },
  {
    postOffice: "Dapode",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Dasve Lavasa",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412112,
  },
  {
    postOffice: "Daund",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Daundaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Dawadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Dawanewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412311,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Dehane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Dehu Road Cantt",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412101,
  },
  {
    postOffice: "Dehu",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412109,
  },
  {
    postOffice: "Delwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Deulgaonraja",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Devale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Dhakale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Dhamane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Dhamani",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410508,
  },
  {
    postOffice: "Dhamari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412403,
  },
  {
    postOffice: "Dhangavadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Dhanore",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Dhanore",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Dhekalwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Dholwad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Dimbhe Colony",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Dingore",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Diwa",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Donda",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Donje",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411025,
  },
  {
    postOffice: "Dorlewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Eklahare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Fulgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412216,
  },
  {
    postOffice: "Gadad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Galandwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Ganegaon Dumala",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Ganegaon Khalasa",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Gangapur KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410516,
  },
  {
    postOffice: "Garade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Gawadewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412405,
  },
  {
    postOffice: "Ghodegaon    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412408,
  },
  {
    postOffice: "Ghotavade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Ghotawadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Ghotondi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413120,
  },
  {
    postOffice: "Girawali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412408,
  },
  {
    postOffice: "Girim",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Godre",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Gogalwadi E",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Gohe BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Gopalwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Gulani",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Gulunche",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412102,
  },
  {
    postOffice: "Gunawadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Gunjalwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412401,
  },
  {
    postOffice: "Gunjawane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Gurholi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Hargude",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412311,
  },
  {
    postOffice: "Harnas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Harni",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Hatve BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Hingangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Hingniberdi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Hirdoshi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Hivare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Hivare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Hivare BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Hivare KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Hol",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412306,
  },
  {
    postOffice: "I.A.T",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411025,
  },
  {
    postOffice: "Inamgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Indapur Bazar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Indapur",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Induri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Inglun",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Inglun",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Ins Shivaji Lonavale",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410402,
  },
  {
    postOffice: "Jalgaon Kadepathar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Jambhali BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Jambhori",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Jambhut",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Jategaon BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Javalarjun",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Jejuri",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Jeur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Jhargadwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Jogwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Junnar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Kadadhe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410513,
  },
  {
    postOffice: "Kadam Wak Wasti",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412201,
  },
  {
    postOffice: "Kadethan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412215,
  },
  {
    postOffice: "Kadus",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Kadve",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Kaivalyadham",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410403,
  },
  {
    postOffice: "Kalamb",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Kalamb    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410515,
  },
  {
    postOffice: "Kalas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413105,
  },
  {
    postOffice: "Kalashi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Kaldari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412311,
  },
  {
    postOffice: "Kalewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Kalthan No 1",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Kalus",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Kalyan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Kambaleshwar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Kambegi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Kamdhenunagar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Kamshet",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Kamthadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Kanakwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Kandali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412412,
  },
  {
    postOffice: "Kangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412219,
  },
  {
    postOffice: "Kanhe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Kanhersar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Kanhur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412218,
  },
  {
    postOffice: "Kapurhol",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Karandi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Karandi BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Karandi Khedebare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Karanjawane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Karanjawane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Karanje",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412306,
  },
  {
    postOffice: "Karanjgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Karawaghaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Karda",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Karegaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412220,
  },
  {
    postOffice: "Karegaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410512,
  },
  {
    postOffice: "Karhati",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412204,
  },
  {
    postOffice: "Kari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Karkhel",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Karla",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Karnawad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Karunj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Kasari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Kasarsai",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Kashedi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Kashing",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Kasurdi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Katfal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413133,
  },
  {
    postOffice: "Kati",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413120,
  },
  {
    postOffice: "Katyachiwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Kawtha",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412218,
  },
  {
    postOffice: "Kazad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Kedgaon    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Kelawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Kendur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412403,
  },
  {
    postOffice: "Kenjal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Kesnand",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Ketkavale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Khadaki",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Khadkumbe Exptl",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Khalad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Khalumbre",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Khamboli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Khamgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Khamgaon BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Khamundi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Khanapur Haveli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411025,
  },
  {
    postOffice: "Khandaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413116,
  },
  {
    postOffice: "Khandala",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410301,
  },
  {
    postOffice: "Khandi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Khandobachi Wadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412102,
  },
  {
    postOffice: "Khangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Khanote",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413105,
  },
  {
    postOffice: "Kharoshi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Khechare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Khed Shivapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Khed Shivapur Baug",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Khodad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Khopodi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Khor",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Khorochi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Khubi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Khutbhav",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Kikvi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Kiwale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Kiwale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412101,
  },
  {
    postOffice: "Kodapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412212,
  },
  {
    postOffice: "Kodit",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Kohinde BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Kolawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Kolvadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412412,
  },
  {
    postOffice: "Kolvihire",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Kolwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Kolwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412110,
  },
  {
    postOffice: "Kolwan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Kondhanpur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Kondhapuri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Kondhawale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Kondhwal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Koregaon Bhima",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412216,
  },
  {
    postOffice: "Koregaon Bhivar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Koregaon Mul",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Korhale BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412103,
  },
  {
    postOffice: "Korhale KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412103,
  },
  {
    postOffice: "Kothale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Koyali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Kude BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Kule",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Kunjirwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412201,
  },
  {
    postOffice: "Kuran",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410511,
  },
  {
    postOffice: "Kuran BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Kuravali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Kurkumbh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413802,
  },
  {
    postOffice: "Kurkundi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Kuruli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Kurungwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Kurwande",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410402,
  },
  {
    postOffice: "Kurwandi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410512,
  },
  {
    postOffice: "Kusgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412219,
  },
  {
    postOffice: "Kusgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Kusgaon BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410401,
  },
  {
    postOffice: "Kushire BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Kusur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Lakhangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412406,
  },
  {
    postOffice: "Lakhewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Landewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410503,
  },
  {
    postOffice: "Lasurna",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Late",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412103,
  },
  {
    postOffice: "Lawale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Lonavala Bazar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410401,
  },
  {
    postOffice: "Lonavala",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410401,
  },
  {
    postOffice: "Loni Bhapkar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Loni Deokar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413132,
  },
  {
    postOffice: "Loni Dhamani",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410510,
  },
  {
    postOffice: "Loni Kalbhor",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412201,
  },
  {
    postOffice: "Lonikand",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412216,
  },
  {
    postOffice: "Madanwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Madh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Madhukarnagar Patas",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412219,
  },
  {
    postOffice: "Mahalunge Ingale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Mahalunge Padwal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410515,
  },
  {
    postOffice: "Mahude BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Mahur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412311,
  },
  {
    postOffice: "Malad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Malad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Malawali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Male",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Male",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Malegaon BK",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413115,
  },
  {
    postOffice: "Malegaon IRR Colony",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413115,
  },
  {
    postOffice: "Malewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Malshiras",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Malthan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Malthan",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412218,
  },
  {
    postOffice: "Manappawasti",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Manchar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410503,
  },
  {
    postOffice: "Mandave",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Mandavgan Farata",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Mandhar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412311,
  },
  {
    postOffice: "Mandki",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Mangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412410,
  },
  {
    postOffice: "Manikdoh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Manjal Asani",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Manjari BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412307,
  },
  {
    postOffice: "Manjari Farm",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412307,
  },
  {
    postOffice: "Margasni",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Markal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Mavdi Kade Pathar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Medad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Mekhali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Midc Kurkumbh",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413802,
  },
  {
    postOffice: "Midc Ranjangaon Ganpati",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412220,
  },
  {
    postOffice: "Morgaon",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412304,
  },
  {
    postOffice: "Mose KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Moshi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Mudhale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Mukhai",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Murti Modhave",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412304,
  },
  {
    postOffice: "Murum",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412306,
  },
  {
    postOffice: "Muthe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Naiphad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Nalavane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412410,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Nane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Nangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Nanvij",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Narayangaon",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Narodi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410503,
  },
  {
    postOffice: "Nasrapur",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Natambi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Nathachi Wadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Naygaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412110,
  },
  {
    postOffice: "Naygaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Nazare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Nazare Kade Pathar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Nere",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Netwad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Nhavare",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Nhavi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413132,
  },
  {
    postOffice: "Nhavi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Nighoje",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Nimbut",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412102,
  },
  {
    postOffice: "Nimgaon Bhogi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412220,
  },
  {
    postOffice: "Nimgaon Ketki",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413120,
  },
  {
    postOffice: "Nimgaon Khed",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Nimgaon Mahalungi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Nimgaon Sava",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Nimgiri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Nimone",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Nimsakhar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Nira R S",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412102,
  },
  {
    postOffice: "Nirawaghaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Nirgude",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Nirgude",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Nirgudsar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412406,
  },
  {
    postOffice: "Nirvi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Nirwangi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Ordanance Dep Talegaon Dabhade",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Ordnance Factory Dehu Road",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412101,
  },
  {
    postOffice: "Osade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411025,
  },
  {
    postOffice: "Otur",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Ozar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Pabal",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412403,
  },
  {
    postOffice: "Pabe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412212,
  },
  {
    postOffice: "Padvi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Pait",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Palasdeo",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413132,
  },
  {
    postOffice: "Palu",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Panawadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Pandare",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Pandheshwar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Pangare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Pangari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Panshet",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Pargaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412203,
  },
  {
    postOffice: "Pargaon Memane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Pargaon Tarfe Ala",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Pargaon Tarfe Avasari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412406,
  },
  {
    postOffice: "Pargaon Tarfe Khed",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410512,
  },
  {
    postOffice: "Pargaon Tarfe Madh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Parinche",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412311,
  },
  {
    postOffice: "Parunde",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Parwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Pasure",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Patas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412219,
  },
  {
    postOffice: "Paud",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Pawananagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410406,
  },
  {
    postOffice: "Perne",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412216,
  },
  {
    postOffice: "Peth    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410512,
  },
  {
    postOffice: "Phulawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Phursungi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412308,
  },
  {
    postOffice: "Pimpalgaon Joga",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Pimpalgaon Mahalunge",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410503,
  },
  {
    postOffice: "Pimpalgaon Tarfa Narayangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412401,
  },
  {
    postOffice: "Pimpalgaon Tarfe Chakan",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Pimpalgaon Tarfe Ghoda",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412408,
  },
  {
    postOffice: "Pimpali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Pimpalwandi    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412412,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Pimple",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Pimpri BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Pimpri BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Pimpri Pendhar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Pimpri Sandas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412216,
  },
  {
    postOffice: "Pingori",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Pirangut",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Pisarve",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Pisavare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Pokhari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Pomgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "R P T S Khandala",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410302,
  },
  {
    postOffice: "Rahu",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Rahu Pimpalgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Rajegaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413105,
  },
  {
    postOffice: "Rajewadi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Rajgurunagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Rajpur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Rajur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412411,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Rakh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Rangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Ranjane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411025,
  },
  {
    postOffice: "Ranjangaon Ganpati",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Ranjangaon Sandas",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Ranjani",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Ranmala",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Rase",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Ravangaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Ravet",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412101,
  },
  {
    postOffice: "Rayari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Redni",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Retwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Rihe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Rohokadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Roti",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412219,
  },
  {
    postOffice: "Rule",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "S R P F Daund",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Saburdi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Sadalgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Sadumbre",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412109,
  },
  {
    postOffice: "Sahajpurwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Sakhar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Sakore",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410515,
  },
  {
    postOffice: "Sakori Tarfe Belha",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412410,
  },
  {
    postOffice: "Sakurde",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Sal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412408,
  },
  {
    postOffice: "Salumbre",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412101,
  },
  {
    postOffice: "Sanaswadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Sandbhorwadi Exptl",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Sangamner",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Sangavi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Sangrun",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Sangvi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Sansar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Sapkalwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Sarati",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Sarole",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Saswad",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Sate",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Saval",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413133,
  },
  {
    postOffice: "Savargaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412401,
  },
  {
    postOffice: "Savindane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410510,
  },
  {
    postOffice: "Saygaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Shelgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Shere",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412108,
  },
  {
    postOffice: "Shetfalgade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413130,
  },
  {
    postOffice: "Shetphal Haveli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Shiavane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Shikrapur",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Shind",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Shindavane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Shindewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Shinoli",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410516,
  },
  {
    postOffice: "Shirapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413801,
  },
  {
    postOffice: "Shirasane",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413110,
  },
  {
    postOffice: "Shirasgaonkata",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Shiravali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Shirkoli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Shiroli B K",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410511,
  },
  {
    postOffice: "Shiroli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Shirsatwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Shirsuphal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Shirur    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412210,
  },
  {
    postOffice: "Shivajinagar",
    Type: "H.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411005,
  },
  {
    postOffice: "Shivali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410406,
  },
  {
    postOffice: "Shivare",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Shivari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Shive",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Shivnagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413116,
  },
  {
    postOffice: "Shriprayagdham",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Solu",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412105,
  },
  {
    postOffice: "Someshwarnagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412306,
  },
  {
    postOffice: "Sonari",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Songaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Sopannagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Supa    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412204,
  },
  {
    postOffice: "Surwad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413103,
  },
  {
    postOffice: "Takali Bhima",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Takave KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Takawe BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Taklihaji",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412218,
  },
  {
    postOffice: "Talegaon Dabhade",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Talegaon Dhamdhere",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Talegaon G H",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Taleghar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Taleran",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Tambad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Tambe",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Tandali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Taparewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Tav",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412107,
  },
  {
    postOffice: "Tavshi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Tekawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Telewadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Theur",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412110,
  },
  {
    postOffice: "Thugaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410512,
  },
  {
    postOffice: "Tirpad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410509,
  },
  {
    postOffice: "Titeghar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Tiwai Hills",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410513,
  },
  {
    postOffice: "Tokawade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Tondal",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412312,
  },
  {
    postOffice: "Udapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412409,
  },
  {
    postOffice: "Udhat",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413104,
  },
  {
    postOffice: "Umbraj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412412,
  },
  {
    postOffice: "Umbre Navlakh",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Undawadi K P",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Undawadi Supa",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413102,
  },
  {
    postOffice: "Uralgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Uravade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412115,
  },
  {
    postOffice: "Urse",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410506,
  },
  {
    postOffice: "Uruli Devachi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412308,
  },
  {
    postOffice: "Urulikanchan",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412202,
  },
  {
    postOffice: "Utchil",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Utroli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Vada    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412402,
  },
  {
    postOffice: "Vadaj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Vadapuri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413106,
  },
  {
    postOffice: "Vadebholhai",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Vadeshwar",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Vadgaon Anand",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412411,
  },
  {
    postOffice: "Vadgaon Dalache",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Vadgaon Ghenanad",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Vadgaon Kandali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412412,
  },
  {
    postOffice: "Vadgaon Kashimbeg",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410503,
  },
  {
    postOffice: "Vadgaon Nimbalkar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412103,
  },
  {
    postOffice: "Vadgaon Peer",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410510,
  },
  {
    postOffice: "Vadgaon Rasai",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412211,
  },
  {
    postOffice: "Vadgaon    Pune",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412106,
  },
  {
    postOffice: "Vadgaon Sahani",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412401,
  },
  {
    postOffice: "Vadhu BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412216,
  },
  {
    postOffice: "Vadki",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412308,
  },
  {
    postOffice: "Vagholi",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Valati",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Valha",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412305,
  },
  {
    postOffice: "Vanpuri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412301,
  },
  {
    postOffice: "Vaphgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Varale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Vardade",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 411025,
  },
  {
    postOffice: "Varkute KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413120,
  },
  {
    postOffice: "Varodi KH",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Varoti BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412212,
  },
  {
    postOffice: "Varude",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },
  {
    postOffice: "Varude",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412209,
  },
  {
    postOffice: "Varvand",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412215,
  },
  {
    postOffice: "Varye BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Vasuli",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Veer",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412312,
  },
  {
    postOffice: "Vehergaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410405,
  },
  {
    postOffice: "Velhe",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412212,
  },
  {
    postOffice: "Velu",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412205,
  },
  {
    postOffice: "Velvand",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Venavadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Vetale",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Vishnupuri",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410507,
  },
  {
    postOffice: "Vithalwadi",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412208,
  },
  {
    postOffice: "Vyahali",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413120,
  },
  {
    postOffice: "Waghapur",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412104,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412306,
  },
  {
    postOffice: "Waki BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410501,
  },
  {
    postOffice: "Walchandnagar",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413114,
  },
  {
    postOffice: "Walki",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412207,
  },
  {
    postOffice: "Walunj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412303,
  },
  {
    postOffice: "Wangani",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Washere",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412404,
  },
  {
    postOffice: "Watluj",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 413105,
  },
  {
    postOffice: "Winzer",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412213,
  },
  {
    postOffice: "Yavat R S",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Yavat",
    Type: "S.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 412214,
  },
  {
    postOffice: "Yedgaon",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410504,
  },
  {
    postOffice: "Yenere",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410502,
  },
  {
    postOffice: "Yeniye BK",
    Type: "B.O",
    District: "Pune",
    State: "MAHARASHTRA",
    Pincode: 410505,
  },

  {
    postOffice: "Elephanta Caves Po",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400001,
  },
  {
    postOffice: "Adai",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Ajivali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Ambivali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Apta",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410207,
  },
  {
    postOffice: "Awre",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Barapada",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Beed Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Bhivpuri Camp",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Bokadvira",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Chanje",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Chawk",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Chikhale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Chinchwali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Chirner",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Dahivali T.Need",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Dahivali T.Varedi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Devichapada",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Dighode",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Donwat",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410203,
  },
  {
    postOffice: "Gaulwadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Gaurkamath",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Gavan",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Gulsunde",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410207,
  },
  {
    postOffice: "Gundge",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Humgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Jagdish Nagar",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410216,
  },
  {
    postOffice: "Jambrung",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Jasai",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Jaskhar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400707,
  },
  {
    postOffice: "Jci Kamothe",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Jnpt",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400707,
  },
  {
    postOffice: "Jui",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Kadav",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Kalamb",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Kalamboli Node",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410218,
  },
  {
    postOffice: "Karanja",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Karjat    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Kashele",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Kegaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Kelevane",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Khalapur",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410202,
  },
  {
    postOffice: "Khambewadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410203,
  },
  {
    postOffice: "Khanda Colony",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Khandas",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Kharghar",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410210,
  },
  {
    postOffice: "Khopoli Power House",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410204,
  },
  {
    postOffice: "Khopoli",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410203,
  },
  {
    postOffice: "Kirwali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Kondivade",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Koproli",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Koshane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Koynavale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Kundevahal",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Lodhivali",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Mandavane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Mangaon T.Varedi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Manivali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Matheran",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410102,
  },
  {
    postOffice: "Mazgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410220,
  },
  {
    postOffice: "Mohopada",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410222,
  },
  {
    postOffice: "Mora",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400704,
  },
  {
    postOffice: "Morbe",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "N.S.Karanja",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400704,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Nasarapur",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Navde",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Navghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400707,
  },
  {
    postOffice: "Neral",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Nere",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Nhava",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Ongc Complex,panvel",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "ONGC-dronagiri",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Padghe",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Palasdari",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Palaspe",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Panvel City",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Panvel",
    Type: "H.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Pargaon",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Patalganga",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410220,
  },
  {
    postOffice: "Pathraj",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Poshir",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Poyanje",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Rasayani",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410207,
  },
  {
    postOffice: "Sai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Sajgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410203,
  },
  {
    postOffice: "Salokh T.Need",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Shelu",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Sheva",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Shilphata",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410203,
  },
  {
    postOffice: "Shirdhon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410221,
  },
  {
    postOffice: "Somatane",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Sugawe",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Taloja A.V.",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Taloja",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Tiware",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Tondra",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Ulwa",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Uran",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 400702,
  },
  {
    postOffice: "Vahal",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Vaje",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Vakas",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410101,
  },
  {
    postOffice: "Vasheni",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Vashivali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410220,
  },
  {
    postOffice: "Vavoshi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410203,
  },
  {
    postOffice: "Vengaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410201,
  },
  {
    postOffice: "Wavandhal",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Wavarle",
    Type: "B.O directly a/w Head Office",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410206,
  },
  {
    postOffice: "Wawanje",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410208,
  },
  {
    postOffice: "Achloli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Adavale Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Adgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402403,
  },
  {
    postOffice: "Adhi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Agardanda",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Agrav",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Ainghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402126,
  },
  {
    postOffice: "Akshi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402204,
  },
  {
    postOffice: "Alibag",
    Type: "H.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402201,
  },
  {
    postOffice: "Ambavade",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402307,
  },
  {
    postOffice: "Ambeghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Ambet",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402101,
  },
  {
    postOffice: "Ambivali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Amtem",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402106,
  },
  {
    postOffice: "Aravi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402113,
  },
  {
    postOffice: "Ashtami",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Atone",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Awas",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Bagmandla",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402114,
  },
  {
    postOffice: "Balavali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Bamangaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402204,
  },
  {
    postOffice: "Bamnoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Bapale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Barasgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402306,
  },
  {
    postOffice: "Bense",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402106,
  },
  {
    postOffice: "Bhairav",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Bhale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402120,
  },
  {
    postOffice: "Bhalgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Bharadkhol",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402404,
  },
  {
    postOffice: "Bharje",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Bhave",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Bhira    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402308,
  },
  {
    postOffice: "Bhonang",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Bhoste",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Birwadi",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Borghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Borghar Haveli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Borlimandla",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402202,
  },
  {
    postOffice: "Borlipanchatan",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402403,
  },
  {
    postOffice: "Borwadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402120,
  },
  {
    postOffice: "Chambhargani",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Chandgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Chandhave Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Chandore",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402122,
  },
  {
    postOffice: "Chanera",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Chaul",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Chikhlap",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Chikhlap",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402113,
  },
  {
    postOffice: "Chimbhave",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 415213,
  },
  {
    postOffice: "Chinchoti",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Chinchwali Tarfe Atone",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402304,
  },
  {
    postOffice: "Chive",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Chochinde",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Chondhi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Chordhe",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Dabhol",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402102,
  },
  {
    postOffice: "Dadar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Dahivad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Dahivali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402117,
  },
  {
    postOffice: "Dandguri",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402404,
  },
  {
    postOffice: "Dapoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Dasgaon",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402102,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402117,
  },
  {
    postOffice: "Deopur",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Devali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Devkanhe",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402304,
  },
  {
    postOffice: "Devla",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Dhamandevi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Dhamansai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Dhamne",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Dharavali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Dhokawade",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Dighi",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402402,
  },
  {
    postOffice: "Diveagar",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402404,
  },
  {
    postOffice: "Divil",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Dolvi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Gadab",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Gagode Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Galsure",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Ghosale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Ghotawade",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Golegani",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Gondale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Gondghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Goregaon    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Govele",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402122,
  },
  {
    postOffice: "Hamrapur",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Hareshwar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Harkol",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Hashiware",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Hatond",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Hodgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Jambhulpada",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Jamgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402308,
  },
  {
    postOffice: "Janjira Murud",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Jasawali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Jite",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402120,
  },
  {
    postOffice: "Jite",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Jite",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Johe",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Jui Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402115,
  },
  {
    postOffice: "Kalavali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Kamarle",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Kamarli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Kamble Tarfe Birwadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402309,
  },
  {
    postOffice: "Kamble Tarfe Mahad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402309,
  },
  {
    postOffice: "Kandale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Kapade Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Karanjadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Kashid",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Kasu",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Kavale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402307,
  },
  {
    postOffice: "Kavil Vahal",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Kelte",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Kemburli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Khaire Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Khamb",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402304,
  },
  {
    postOffice: "Khamgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Khandale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Khandpoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Kharavali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Khardi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Khargaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Kharpale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Kharsai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Khavali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Kihim",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Kinjaloli Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Kokban",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Kolad",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402304,
  },
  {
    postOffice: "Kolmandla",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402114,
  },
  {
    postOffice: "Kolose",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Kolwat",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Kondhavi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Konzar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Koproli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Korlai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402202,
  },
  {
    postOffice: "Koste Khurd",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402120,
  },
  {
    postOffice: "Kotheri",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Kotwal Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Kudgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402402,
  },
  {
    postOffice: "Kudli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402308,
  },
  {
    postOffice: "Kumbheshivthar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402306,
  },
  {
    postOffice: "Kurdus",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402108,
  },
  {
    postOffice: "Kurle",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Kurul",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Kurul RCF Colony",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Kusgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402309,
  },
  {
    postOffice: "Kusumbale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402108,
  },
  {
    postOffice: "Limpniwave",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402101,
  },
  {
    postOffice: "Lohare",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Lonere",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Lonshi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Mahad",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Mahagaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402112,
  },
  {
    postOffice: "Mahagaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Mahal Mirya Dongar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Mahargul",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Majgaon Tamhane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Malyan",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Mandad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Mandle",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Mangaon Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Mangaon    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Mangarul",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Mangrun",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Manjaravane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402122,
  },
  {
    postOffice: "Mapgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Mazeri",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402306,
  },
  {
    postOffice: "Mazgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Medha",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Mendadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Mhasla",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Midc Mahad",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402309,
  },
  {
    postOffice: "Mohot",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Morba",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402117,
  },
  {
    postOffice: "Mumurshi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Muthavali Tarfe Tala",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402112,
  },
  {
    postOffice: "Nadsur",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Nagaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Nagaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Nagaon",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402204,
  },
  {
    postOffice: "Nagloli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402404,
  },
  {
    postOffice: "Nagothane",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402106,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Nandvi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Narangi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Narvan",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402115,
  },
  {
    postOffice: "Nate",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Natondi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Navadar Navagaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Navghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Navipeth Mahad",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Nhave",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Nigade",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Nizampur    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402120,
  },
  {
    postOffice: "Ombali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Pabal",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Pabhre",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Pachad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Pachhapur",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Padvi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402306,
  },
  {
    postOffice: "Paithan",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Palas",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402106,
  },
  {
    postOffice: "Palasgaon Khurd",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Palchil",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Pali    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Pangari",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402307,
  },
  {
    postOffice: "Pangloli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402101,
  },
  {
    postOffice: "Panhalghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Parali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Patnus",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402308,
  },
  {
    postOffice: "PCTS Nagothane",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402125,
  },
  {
    postOffice: "Pen",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Pen Tarfe Tale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Pigonde",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402106,
  },
  {
    postOffice: "Pingalsai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Pipenagar",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402126,
  },
  {
    postOffice: "Pitsai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Poladpur",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Poynad",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402108,
  },
  {
    postOffice: "Pugaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402304,
  },
  {
    postOffice: "Purar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Rahatad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Rajapuri",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Rajewadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402309,
  },
  {
    postOffice: "Ramraj",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Ranawali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Ransai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Ratwad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402112,
  },
  {
    postOffice: "Ravalje",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Ravdhal",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402115,
  },
  {
    postOffice: "Rave",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "RCF Thal",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Revdanda",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402202,
  },
  {
    postOffice: "Revtale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Roha AV",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402116,
  },
  {
    postOffice: "Roha",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Roth Khurd",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402116,
  },
  {
    postOffice: "Sadavali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Sai",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402122,
  },
  {
    postOffice: "Saigaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Sakhar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Salvadnaka Mahad No Dly.",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Salvinde",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Sanderi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402101,
  },
  {
    postOffice: "Sandoshi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Sanegaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Saral",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Sarsoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Sarve",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402403,
  },
  {
    postOffice: "Sasawane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },
  {
    postOffice: "Sav",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Savli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Savrat",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Sawad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Sawarsai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Shahabaj",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402108,
  },
  {
    postOffice: "Shahapur",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402108,
  },
  {
    postOffice: "Shedsai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Shekhadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402113,
  },
  {
    postOffice: "Shenwai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Shighre",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Shirawali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402120,
  },
  {
    postOffice: "Shirki",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Shriwardhan",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Siddheshwar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Sonkhar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Sudkoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Tala    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Talashet",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402112,
  },
  {
    postOffice: "Talavade",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Talawali Terfe Diwali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402304,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "Talegaon Tarfe Goregaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Taliye",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402306,
  },
  {
    postOffice: "Taloshi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Tambadi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Tamhane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402307,
  },
  {
    postOffice: "Tarankhop",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Tempale",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Tetghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Thal    Raigarh MH",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402207,
  },
  {
    postOffice: "Tiware",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 410205,
  },
  {
    postOffice: "Tokarde",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402111,
  },
  {
    postOffice: "TolBudruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402102,
  },
  {
    postOffice: "Turbhe Khurd",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Underi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Unegaon",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Upper Tudul",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402115,
  },
  {
    postOffice: "Usar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402203,
  },
  {
    postOffice: "Usar Khurd",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402104,
  },
  {
    postOffice: "Usarghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Usroli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Vadghar",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Vadhav",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Vadkhal",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Vadshetvave",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402110,
  },
  {
    postOffice: "Varandh",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402306,
  },
  {
    postOffice: "Varandoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Varsai",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Varsoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Varvatane",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402105,
  },
  {
    postOffice: "Veer",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402102,
  },
  {
    postOffice: "Velas",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402403,
  },
  {
    postOffice: "Veshvi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402209,
  },
  {
    postOffice: "Vihoor",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402401,
  },
  {
    postOffice: "Vikram Baug",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402202,
  },
  {
    postOffice: "Vile",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402308,
  },
  {
    postOffice: "Vinhere",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402307,
  },
  {
    postOffice: "Virjoli",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Wadvali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402103,
  },
  {
    postOffice: "Wadwali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402403,
  },
  {
    postOffice: "Wagheri",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402305,
  },
  {
    postOffice: "Wagholi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Wahoor",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402301,
  },
  {
    postOffice: "Wakan",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402303,
  },
  {
    postOffice: "Wakanfata",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402106,
  },
  {
    postOffice: "Waki Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Walan Budruk",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Walan Khurd",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402302,
  },
  {
    postOffice: "Walang",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402115,
  },
  {
    postOffice: "Wali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402109,
  },
  {
    postOffice: "Walke",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402202,
  },
  {
    postOffice: "Walwati",
    Type: "S.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402113,
  },
  {
    postOffice: "Washi",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402107,
  },
  {
    postOffice: "Wavediwali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402112,
  },
  {
    postOffice: "Wighawali",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402112,
  },
  {
    postOffice: "Zirad",
    Type: "B.O",
    District: "Raigarh MH ",
    State: "MAHARASHTRA",
    Pincode: 402208,
  },

  {
    postOffice: "Abaloli",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Adavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415802,
  },
  {
    postOffice: "Ade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415714,
  },
  {
    postOffice: "Adivare",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416707,
  },
  {
    postOffice: "Adkhaltari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415714,
  },
  {
    postOffice: "Adre",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Adur    Ratnagiri",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415705,
  },
  {
    postOffice: "Agarwaingani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Agave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Agave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Ajiwali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Akale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Alore",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415603,
  },
  {
    postOffice: "Alsure",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Ambav",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Ambav",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Ambavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Ambavali",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Ambaye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Ambdas",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Ambed Budruk",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415637,
  },
  {
    postOffice: "Ambitgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Ambolgad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415806,
  },
  {
    postOffice: "Amdave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Anari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Angale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Angavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Anjanari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Anjanvel",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415634,
  },
  {
    postOffice: "Anjarla",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415714,
  },
  {
    postOffice: "Ansure",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Antravali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Aravali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Are",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Argaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Asage",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Asgani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415708,
  },
  {
    postOffice: "Asgoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Ashti",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Asond",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Asore",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Astan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Asud",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415713,
  },
  {
    postOffice: "Asurda",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Avashi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415722,
  },
  {
    postOffice: "Ayani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415708,
  },
  {
    postOffice: "Bagave Wadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Bahiravali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Bamnoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Bamnoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Bankot",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Bapere",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Basani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415629,
  },
  {
    postOffice: "Belari Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Beni Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Bhade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Bhadkamba",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Bhalavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Bhambed",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Bharane",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415621,
  },
  {
    postOffice: "Bhatgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Bhatye",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Bhelsai",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Bhile",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Bhoke",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Bhom",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Bhoo",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416713,
  },
  {
    postOffice: "Bhopan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Bijghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Biwali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Boraj",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Burambad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Burambe Wadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Burondi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415720,
  },
  {
    postOffice: "Chafavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415807,
  },
  {
    postOffice: "Chafe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Chandor",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Charveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415619,
  },
  {
    postOffice: "Chave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Chickalgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415609,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Chinchavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415640,
  },
  {
    postOffice: "Chinchghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Chinchghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Chinchghari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Chinchkhari",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Chindravale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415728,
  },
  {
    postOffice: "Chiplun",
    Type: "H.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Chirni",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Chiveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Choravane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Choravane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Dabhil",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Dabhol",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415706,
  },
  {
    postOffice: "Dabhole",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Dahagaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Dahivali Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Dale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415805,
  },
  {
    postOffice: "Dalvatane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Dande Adom",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Dapoli",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Dasur",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416713,
  },
  {
    postOffice: "Dayal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415710,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Dehen",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Deole Ghera Prachitgad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Dervan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Deud",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Devache Gothane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Devade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Devadhe",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Devale",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415807,
  },
  {
    postOffice: "Devghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Devhare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Devihasol",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416713,
  },
  {
    postOffice: "Devrukh",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Dhakmoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Dhamanand",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Dhamandevi",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Dhamani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Dhamani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Dhamanse",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Dhamapur",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Dhamnavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Dhaulwali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Dhopave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Dhopeshwar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Dhutroli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Dingni",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Dodavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415728,
  },
  {
    postOffice: "Donavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Dongar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Donivade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Dorle",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415626,
  },
  {
    postOffice: "Durgawadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Fungus",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Furus",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Furus",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415710,
  },
  {
    postOffice: "Ganeshgule",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Ganpatipule",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415615,
  },
  {
    postOffice: "Gavade Ambere",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415626,
  },
  {
    postOffice: "Gavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Gavkhadi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415626,
  },
  {
    postOffice: "Gharadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Ghativale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Ghera Rasalgad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Ghodavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Ghude",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Gimvi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Golap",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Golavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Golawashi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Gondhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Gothane Doniwade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Gothe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Goval",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Govil",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Guhagar",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Gunade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415722,
  },
  {
    postOffice: "Haral",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Harche",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Harcheri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Hardakhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Harnai",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415713,
  },
  {
    postOffice: "Harpude",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Hasol Tarf Saundal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Hatade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Hativ",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Hatkhamba",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415619,
  },
  {
    postOffice: "Hedali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Hedavi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415728,
  },
  {
    postOffice: "Holi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415805,
  },
  {
    postOffice: "Humbri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Ibrahimpattan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Ilne",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415714,
  },
  {
    postOffice: "Indavati",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Isavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Jaigad",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415614,
  },
  {
    postOffice: "Jaitapur",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415805,
  },
  {
    postOffice: "Jalgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Jamage",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Jamage",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Jambhari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415613,
  },
  {
    postOffice: "Jamsud",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Javade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Jawalethar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Juwathi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Kadavali",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Kadivali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Kadwad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Kadwai",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415609,
  },
  {
    postOffice: "Kajarikar Mohalla",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Kajirda",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Kajurli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Kalamani Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415621,
  },
  {
    postOffice: "Kalamani Khurd",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Kalambaste",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Kalambaste",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Kalambat",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Kalambat",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Kalambushi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Kalamundi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Kalbadevi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415629,
  },
  {
    postOffice: "Kalkavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Kalsavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Kalsur Koundhar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Kaluste",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Kalzondi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Kamathe",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Kaneri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Kangwai",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Kanhe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Kankadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Kapad Gaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415619,
  },
  {
    postOffice: "Kapre",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Kapsal",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Karak",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Karambavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Karambele Tarf Devale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Karanjani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Karanjgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415720,
  },
  {
    postOffice: "Karavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Karbhatale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Karbude",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Karde",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415713,
  },
  {
    postOffice: "Karji",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Karjuve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Karla",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Karli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Karul",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Kasai",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Kasar Kolwan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Kasarveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415629,
  },
  {
    postOffice: "Kasba    Ratnagiri",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Kase",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Kashedi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415640,
  },
  {
    postOffice: "Kasheli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416707,
  },
  {
    postOffice: "Katali Sagave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416709,
  },
  {
    postOffice: "Katavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Katroli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Kaundhar Tamhane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Kelambe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Kelavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Kelawali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Kelne",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Kelshi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415717,
  },
  {
    postOffice: "Kelye",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Kespuri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Khadpoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415603,
  },
  {
    postOffice: "Khalgaon",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Khanavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Khanu",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Kharvate",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Kharvate",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Khavadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Khavati",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415640,
  },
  {
    postOffice: "Khed  RTG",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Khed City",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Khedashi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Kherdi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Kherdi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Khodade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Khopad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Khopi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Khorninko",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Kirbet",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Kirduve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Kochari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415802,
  },
  {
    postOffice: "Kodavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Kokare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Kolamb",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Kolambe",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Kolambe S",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415637,
  },
  {
    postOffice: "Kolbandre",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Koldhe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Kolisare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Kolke Wadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415603,
  },
  {
    postOffice: "Kolthare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Kolvande",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Kolwan Chuna",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Kolwan Khadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Kondge",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Kondivali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Kondivare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Kondmala",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Kondsur Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416707,
  },
  {
    postOffice: "Kondye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Kondye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Kondye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Koregaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Korle",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Kosbi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Kosumb",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Kot",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Kotapur",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Kotavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415722,
  },
  {
    postOffice: "Kotawada",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415617,
  },
  {
    postOffice: "Kotluk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Kudali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415613,
  },
  {
    postOffice: "Kudavale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Kulvandi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Kumbale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Kumbhad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Kumbharkhani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Kumbharli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415602,
  },
  {
    postOffice: "Kumbhavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Kundi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Kurang",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Kurdhunda",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Kurne",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Kurtade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Kurwal Jawali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Kutgiri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Kutra",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Kuvarbav",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Kuve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Kuveshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Ladghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Lanja",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Latwan",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415202,
  },
  {
    postOffice: "Lavel",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415708,
  },
  {
    postOffice: "Lavgan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Lote    Ratnagiri",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415722,
  },
  {
    postOffice: "Lowale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Mabhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Madban",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Mahalunge",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Majal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Majgaon",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Makhajan",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Malan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Maldoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Malghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Malgund",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415615,
  },
  {
    postOffice: "Mandangad",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Mandivali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Mandki",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Mandva",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Mani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Manjare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Maral",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Margatamhane",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Markandi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Masrang",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415609,
  },
  {
    postOffice: "Masu",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Math",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Matwan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Mavlange",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Meghi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415807,
  },
  {
    postOffice: "Mervi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Mhapral",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "MIDC  RTG",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Miland",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Miravane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Mirjole",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Mirya",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Mithgavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Mohane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Moor",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Moravane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Morde",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Moroshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Morvande",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Mosam",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Muchari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Mudhe Tarf Sawarda",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Mugij",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Mumbake",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Mundhar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Mundhe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415602,
  },
  {
    postOffice: "Murdav",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Murde",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Murshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Murtavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Murud",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415713,
  },
  {
    postOffice: "Musad",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Nachane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Nakhare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Nanar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Nandgaon  CPN",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Nandgaon  Khed",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Nandivase",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Nandivili",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415730,
  },
  {
    postOffice: "Nanij",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Nante",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Narduve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Narvan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415728,
  },
  {
    postOffice: "Nata",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415806,
  },
  {
    postOffice: "Natunagar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415640,
  },
  {
    postOffice: "Nayari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Nayashi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415608,
  },
  {
    postOffice: "Nevare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Nigadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Nigundal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Nilavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415621,
  },
  {
    postOffice: "Nirul",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Nirvhal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Nive Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Niveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Nivendi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415615,
  },
  {
    postOffice: "Nivsar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Niwali  CPN",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Niwali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Omali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Oni",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Oni-navase",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415706,
  },
  {
    postOffice: "Ori",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Oshivale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Ovali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Ozare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Ozare Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Ozare Khurd",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Pachal    Ratnagiri",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Pachambe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Pachavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Pacheri Sada",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Padave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Padve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415214,
  },
  {
    postOffice: "Padve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415613,
  },
  {
    postOffice: "Paj",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415713,
  },
  {
    postOffice: "Palavani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Palgad",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Pali  RTG",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Pali CPN",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Palpene",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Palshet",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Palu",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Palvan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Panchnadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Panderi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415214,
  },
  {
    postOffice: "Pangare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Pangari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415639,
  },
  {
    postOffice: "Pangari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Pangari Tarf Haveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Pangari Tarf Velamb",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Panhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Panhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Panhale Tarf Saundal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Panhalje",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Panval",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415619,
  },
  {
    postOffice: "Parchuri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Parchuri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415637,
  },
  {
    postOffice: "Parshuram",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Parule",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Pate Pillavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Patgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Patpanhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Pawas",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Pedhambe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415603,
  },
  {
    postOffice: "Pedhe",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Pendkhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416713,
  },
  {
    postOffice: "Peve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415214,
  },
  {
    postOffice: "Peve",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Phansavale",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Phansavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Phansop",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Phansu",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Pilavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Pimpar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Pimpli Khurd",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Pimploli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415202,
  },
  {
    postOffice: "Pirandavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415617,
  },
  {
    postOffice: "Pirandavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Pisai",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Pochari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Pomendi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Pomendi Budruk",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Pomendi Khurd",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Pophali",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415601,
  },
  {
    postOffice: "Pophlavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Posare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Posare Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Poynar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415710,
  },
  {
    postOffice: "Prabhanvalli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Prindavan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Punas",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Purnagad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415616,
  },
  {
    postOffice: "Purye T/F Devale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Rai",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Rajapur    Ratnagiri",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Rajivili",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Rajwadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416707,
  },
  {
    postOffice: "Rajwadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Rampur    Ratnagiri",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Ranpat",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Ratnagiri Collectorate",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Ratnagiri",
    Type: "H.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Raypatan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "RGPPL Anjanvel",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415634,
  },
  {
    postOffice: "Ringane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Roon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Sada Jambhari",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415726,
  },
  {
    postOffice: "Sadavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Sagave",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416709,
  },
  {
    postOffice: "Saitavada",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415613,
  },
  {
    postOffice: "Sakhar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Sakhar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Sakhari Agar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415729,
  },
  {
    postOffice: "Sakhari Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Sakhari Trishul",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Sakharpa",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Sakhloli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Sakhroli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Salpe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415802,
  },
  {
    postOffice: "Sangave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Sanglat",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Sangmeshwar",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Sapirili",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Sarang",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Sasale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Satavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Satere Tarf Natu",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Sathare Bambar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Satvingaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415708,
  },
  {
    postOffice: "Saundal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Savdav",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Saveni",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Savnas",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Sawarda",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415606,
  },
  {
    postOffice: "Sayale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Sheldi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415722,
  },
  {
    postOffice: "Shembavane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Shingri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Shipole",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Shiposhi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415802,
  },
  {
    postOffice: "Shir",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Shiral",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Shirambavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Shirambe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Shiravali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Shirgaon  CPN",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415602,
  },
  {
    postOffice: "Shirgaon  RTG",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415629,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Shirkhal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Shirshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415727,
  },
  {
    postOffice: "Shirsoli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Shiv Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Shivaji Nagar  RTG",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Shivane Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416713,
  },
  {
    postOffice: "Shivane Khurd",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Shivar Ambere",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415626,
  },
  {
    postOffice: "Shivtar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Shringarpur",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Shringartali",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Solgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Soliwade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Someshwar",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Sonavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Sondeghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Songaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415722,
  },
  {
    postOffice: "Soveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Sukivili",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415621,
  },
  {
    postOffice: "Sukondi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415714,
  },
  {
    postOffice: "Susheri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Tadil",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Takede",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Tala Mandva",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Talavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Talavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Talavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Talavali",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415719,
  },
  {
    postOffice: "Talekante",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415637,
  },
  {
    postOffice: "Talgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Talsar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415602,
  },
  {
    postOffice: "Talsure",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Talvat",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Tambedi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Tamhane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Tamhane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Tamhanmala",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415628,
  },
  {
    postOffice: "Tanali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Tangar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Taral",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Tarval",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Tavsal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415613,
  },
  {
    postOffice: "Tembye",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Teru",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Terye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Tetavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Tide",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415202,
  },
  {
    postOffice: "Tike",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Tilak Memorial  RTG",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Tisangi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Tise",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415709,
  },
  {
    postOffice: "Tiware",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Tiware",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Tiware Ghera Prachitgad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Tiware Medhe Tarfe Devale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415801,
  },
  {
    postOffice: "Tonade",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415612,
  },
  {
    postOffice: "Tondali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Tulsani",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Tulshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415203,
  },
  {
    postOffice: "Tulshi Khurd",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415640,
  },
  {
    postOffice: "Tural",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415609,
  },
  {
    postOffice: "Turambav",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Ubhale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Udhale Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415640,
  },
  {
    postOffice: "Ujgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Ukshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415637,
  },
  {
    postOffice: "Umare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415610,
  },
  {
    postOffice: "Umbarghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415706,
  },
  {
    postOffice: "Umbarle",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Umrath",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415728,
  },
  {
    postOffice: "Umroli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Umroli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },
  {
    postOffice: "Upale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Upale",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Vadad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Vadad Hasol",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416713,
  },
  {
    postOffice: "Vadgaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Vahal",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Vakavali",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Valote",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415202,
  },
  {
    postOffice: "Vandri",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415637,
  },
  {
    postOffice: "Vangule",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Vanzole",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Varawade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415620,
  },
  {
    postOffice: "Varnoshi Tarf Panchanadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Varveli",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Veer",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415641,
  },
  {
    postOffice: "Vehele",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415604,
  },
  {
    postOffice: "Velamb",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415724,
  },
  {
    postOffice: "Velas",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Veldur",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415703,
  },
  {
    postOffice: "Velvand",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415619,
  },
  {
    postOffice: "Velvi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415712,
  },
  {
    postOffice: "Veral",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415621,
  },
  {
    postOffice: "Veral",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Veravali Budruk",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Veravali Khurd",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Veshvi",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415208,
  },
  {
    postOffice: "Vetoshi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415617,
  },
  {
    postOffice: "Vhel",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Vilavade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Vilye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Visapur",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415716,
  },
  {
    postOffice: "Wadavali",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416705,
  },
  {
    postOffice: "Waghambe",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415728,
  },
  {
    postOffice: "Waghangaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Waghivare",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415701,
  },
  {
    postOffice: "Waghrat",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416712,
  },
  {
    postOffice: "Waingane",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Waked",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Walope",
    Type: "B.O directly a/w Head Office",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415605,
  },
  {
    postOffice: "Walye",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416702,
  },
  {
    postOffice: "Wanke",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415803,
  },
  {
    postOffice: "Washi Tarf Devrukh",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415804,
  },
  {
    postOffice: "Washi Tarf Sangmeshwar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415611,
  },
  {
    postOffice: "Watad",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415613,
  },
  {
    postOffice: "Watul",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415643,
  },
  {
    postOffice: "Wave",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415718,
  },
  {
    postOffice: "Wavghar",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415711,
  },
  {
    postOffice: "Welneshwar",
    Type: "S.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415729,
  },
  {
    postOffice: "Yegaon",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415607,
  },
  {
    postOffice: "Yelvan",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Yerdav",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Zapade",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 416701,
  },
  {
    postOffice: "Zare Wadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415619,
  },
  {
    postOffice: "Zombadi",
    Type: "B.O",
    District: "Ratnagiri",
    State: "MAHARASHTRA",
    Pincode: 415702,
  },

  {
    postOffice: "Agalgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Agran Dhulgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416411,
  },
  {
    postOffice: "Ainwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Aitawade BK",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415401,
  },
  {
    postOffice: "Aitawade Khurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Alkud   S",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416411,
  },
  {
    postOffice: "Alkud  M",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416419,
  },
  {
    postOffice: "Alsund",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Amanapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Amarapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Ambak",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Andhali",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416310,
  },
  {
    postOffice: "Anjani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Ankalagi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Ankale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416402,
  },
  {
    postOffice: "Ankali",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Ankalkhop",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416316,
  },
  {
    postOffice: "Antral",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Antri Budruk",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Arag",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416401,
  },
  {
    postOffice: "Arala",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415415,
  },
  {
    postOffice: "Arawade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Arewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Asangi J",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Asangi K",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Ashte",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Atpadi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415301,
  },
  {
    postOffice: "Audumbar",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416303,
  },
  {
    postOffice: "Aundhi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Avandhi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Awalai",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415315,
  },
  {
    postOffice: "Bagani",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416302,
  },
  {
    postOffice: "Bahadurwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415411,
  },
  {
    postOffice: "Bahe",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Baj",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416402,
  },
  {
    postOffice: "Balagavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Balavadi  KHN",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415307,
  },
  {
    postOffice: "Balavadi Bhalavani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416309,
  },
  {
    postOffice: "Balgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Bamani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Bambavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416310,
  },
  {
    postOffice: "Bambavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415410,
  },
  {
    postOffice: "Bamnoli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416436,
  },
  {
    postOffice: "Banali",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Banewadi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Banpuri",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415306,
  },
  {
    postOffice: "Banur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Basargi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Bastawade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Bavachi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Bedag",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Belanki",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Belavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Bellondagi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Bendri",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Bevnur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Bhadkimbe",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416302,
  },
  {
    postOffice: "Bhalavani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Bhatshirgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Bhavaninagar    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415302,
  },
  {
    postOffice: "Bhendavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415310,
  },
  {
    postOffice: "Bhikavadi BK",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415310,
  },
  {
    postOffice: "Bhikawadi Kurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Bhilavadi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416303,
  },
  {
    postOffice: "Bhilwadi R.S.",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416303,
  },
  {
    postOffice: "Bhood",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415309,
  },
  {
    postOffice: "Bhose",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416420,
  },
  {
    postOffice: "Bichud",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415302,
  },
  {
    postOffice: "Bilashi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415402,
  },
  {
    postOffice: "Billur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Biranwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Bisur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416304,
  },
  {
    postOffice: "Biur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Boblad Jadar",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Boblad K",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Bolwad",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Borgaon  T",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416419,
  },
  {
    postOffice: "Borgaon    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415413,
  },
  {
    postOffice: "Borgi Khurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Brahmanal",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Brahmanpuri Miraj",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Budhgaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416304,
  },
  {
    postOffice: "Burli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Charan",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Chikhalhol",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415310,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Chikurde",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415412,
  },
  {
    postOffice: "Chinchani  T",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Chinchani Ambak",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Chinchani Mangrul",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Chorochi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Dahiwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Daphalapur",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416402,
  },
  {
    postOffice: "Daribadachi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Deshing",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Devikhindi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415309,
  },
  {
    postOffice: "Devrashtra",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Devwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415412,
  },
  {
    postOffice: "Dhagewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415401,
  },
  {
    postOffice: "Dhalewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Dhalgaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Dhamavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Dhavadwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Dhavaleshwar",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Dhavali  T",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Dhavali",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416302,
  },
  {
    postOffice: "Dhavali",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Dhulgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416420,
  },
  {
    postOffice: "Dighanchi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415315,
  },
  {
    postOffice: "Digraj Kasaba Agri School",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416305,
  },
  {
    postOffice: "Digraj Kasaba",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416305,
  },
  {
    postOffice: "Dongarsoni",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Dongarwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416407,
  },
  {
    postOffice: "Dorli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Dudhgaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416315,
  },
  {
    postOffice: "Dudhondi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Factory Area Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Gajanan Mills Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Ganpati Peth Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Gardi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Gaurgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Gavan",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416408,
  },
  {
    postOffice: "Ghanand",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415308,
  },
  {
    postOffice: "Gharniki",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415308,
  },
  {
    postOffice: "Ghatnandre",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Ghogaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416309,
  },
  {
    postOffice: "Ghorpadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Ghoti Khurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Girgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Gomewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415306,
  },
  {
    postOffice: "Gotkhindi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Govt. Colony",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416415,
  },
  {
    postOffice: "Govt.Medical College Miraj",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Gugwad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Gundewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416407,
  },
  {
    postOffice: "Guruwar Peth,tasgaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Hanmant Vadiye",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Haripur",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Hatnoli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416314,
  },
  {
    postOffice: "Hatnur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416314,
  },
  {
    postOffice: "Hingangade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Hingangaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Hingangaon Bk",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Hingangaon Khurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Hivare",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415307,
  },
  {
    postOffice: "Hivtad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415306,
  },
  {
    postOffice: "Inam Dhamni",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Ingrul",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Irali",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Itkare",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415403,
  },
  {
    postOffice: "Jadhavwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Jaigavan",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416419,
  },
  {
    postOffice: "Jakhapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Jarandi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Jath",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Jirgyal",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416402,
  },
  {
    postOffice: "Jondhalkhindi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415309,
  },
  {
    postOffice: "Junekhed",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416313,
  },
  {
    postOffice: "Kadegaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415304,
  },
  {
    postOffice: "Kadepur",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Kalambi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Kalamwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415406,
  },
  {
    postOffice: "Kalundre",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415415,
  },
  {
    postOffice: "Kameri",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415403,
  },
  {
    postOffice: "Kanchanpur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416306,
  },
  {
    postOffice: "Kande",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415412,
  },
  {
    postOffice: "Kandur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Kanegaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415411,
  },
  {
    postOffice: "Kapuskhed",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Karandwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Karanguli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415415,
  },
  {
    postOffice: "Karanjawade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415401,
  },
  {
    postOffice: "Karanje",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Kargani",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415306,
  },
  {
    postOffice: "Karmala",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Karnal",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Karoli  T",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Karoli  M",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416408,
  },
  {
    postOffice: "Karve",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415401,
  },
  {
    postOffice: "Karve",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Kasegaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415404,
  },
  {
    postOffice: "Kautholi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415301,
  },
  {
    postOffice: "Kavaji Khotwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416304,
  },
  {
    postOffice: "Kavalapur",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416306,
  },
  {
    postOffice: "Kavathe Ekand",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416307,
  },
  {
    postOffice: "Kavathe Mahankal",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Kavathe Piran",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416417,
  },
  {
    postOffice: "Kerewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Khalati",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416402,
  },
  {
    postOffice: "Khalsa Dhamni",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416314,
  },
  {
    postOffice: "Khambale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Khanapur    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415307,
  },
  {
    postOffice: "Khanbhag,sangli At Midc Miraj",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Khanderajuri",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Kharshing",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Kharsundi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415308,
  },
  {
    postOffice: "Khatav",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416401,
  },
  {
    postOffice: "Kherade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Khujgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Khujgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Killemachindragad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415302,
  },
  {
    postOffice: "Kirloskarwadi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Kognoli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416418,
  },
  {
    postOffice: "Kokale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Kokrud",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Koregaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416302,
  },
  {
    postOffice: "Kosari",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Kuchi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Kuktoli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416418,
  },
  {
    postOffice: "Kumatha",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416306,
  },
  {
    postOffice: "Kumbhari",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Kundal    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416309,
  },
  {
    postOffice: "Kundalapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Kundalwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415411,
  },
  {
    postOffice: "Kunikonnur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Kupwad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416436,
  },
  {
    postOffice: "Kupwad MIDC Area",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416436,
  },
  {
    postOffice: "Kurlap",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415411,
  },
  {
    postOffice: "Ladegaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415401,
  },
  {
    postOffice: "Landgewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416419,
  },
  {
    postOffice: "Langarpeth",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Lengare",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415309,
  },
  {
    postOffice: "Limb",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Lingiware",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415315,
  },
  {
    postOffice: "Lingnoor",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416401,
  },
  {
    postOffice: "Lode",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Lonarwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416411,
  },
  {
    postOffice: "Madgule",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415301,
  },
  {
    postOffice: "Madgyal",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Madhavnagar",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416406,
  },
  {
    postOffice: "Mahuli    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415310,
  },
  {
    postOffice: "Malangaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416419,
  },
  {
    postOffice: "Malgaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416407,
  },
  {
    postOffice: "Mallewadi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Malwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416315,
  },
  {
    postOffice: "Manerajuri",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416408,
  },
  {
    postOffice: "Mangale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415412,
  },
  {
    postOffice: "Mangalwar Peth Miraj",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415402,
  },
  {
    postOffice: "Manikwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415407,
  },
  {
    postOffice: "Manjarde",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Mardwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Market Yard Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Masuchiwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415413,
  },
  {
    postOffice: "Mendhigiri",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Meni",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Mhaisal",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416409,
  },
  {
    postOffice: "Miraj Audyogik Vasahat",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Miraj",
    Type: "H.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Miraj Station Road",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Mirajwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Mohi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415307,
  },
  {
    postOffice: "Morale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416310,
  },
  {
    postOffice: "Mouje Digraj",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Muchandi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Nagaj",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416403,
  },
  {
    postOffice: "Nagaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416302,
  },
  {
    postOffice: "Nagarale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Nagewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Nagthane",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416313,
  },
  {
    postOffice: "Nandre",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Nangole",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Narsinhpur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Narwad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416409,
  },
  {
    postOffice: "Nathawade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Natoli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Nelkaranji",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415308,
  },
  {
    postOffice: "Nerla",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415406,
  },
  {
    postOffice: "Nerli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415304,
  },
  {
    postOffice: "Nevari",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Nigadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Nigdi Khurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Nimani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Nimbavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415301,
  },
  {
    postOffice: "Nimblak",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Ozarde",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415407,
  },
  {
    postOffice: "Pachumbri",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415410,
  },
  {
    postOffice: "Padali",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416314,
  },
  {
    postOffice: "Padalichiwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Padmale",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Palashi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415307,
  },
  {
    postOffice: "Palus",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416310,
  },
  {
    postOffice: "Panumbre Turf Shirala",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415410,
  },
  {
    postOffice: "Panumbre Turf Warun",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Pare",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Payappachiwadi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Ped",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Peth    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415407,
  },
  {
    postOffice: "Phuphire",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Pimpri Budruk",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415308,
  },
  {
    postOffice: "Pokharni",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Punadi  T",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Punadi T/F Walwa",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Punvat",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "R K Extension Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Rajapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416310,
  },
  {
    postOffice: "Ramanandnagar",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416308,
  },
  {
    postOffice: "Ramapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Ranjani    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416411,
  },
  {
    postOffice: "Red",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415407,
  },
  {
    postOffice: "Renavi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Rethare Dharan",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415407,
  },
  {
    postOffice: "Rethare Harnaksha",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415302,
  },
  {
    postOffice: "Revangaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Revnal",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Rile",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "S S K Sakharale",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415414,
  },
  {
    postOffice: "S S K Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Sagaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Saholi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Sakharale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415414,
  },
  {
    postOffice: "Salgare",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416418,
  },
  {
    postOffice: "Salshinge",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Samdolii",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Sangalwadi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Sangli City",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Sangli",
    Type: "H.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Sankh",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Sanmadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Savarde",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416408,
  },
  {
    postOffice: "Savlaj",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Sawantpur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416310,
  },
  {
    postOffice: "Shalgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415304,
  },
  {
    postOffice: "Shaniwar Peth Miraj",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Shegaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Shelakbav",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Shetphale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415306,
  },
  {
    postOffice: "Shigaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416302,
  },
  {
    postOffice: "Shindewadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416401,
  },
  {
    postOffice: "Shinganapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Shinganhalli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Shipur",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Shirala",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Shirasgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Shirashi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Shirdhon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416419,
  },
  {
    postOffice: "Shirte",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Shivajinagar",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415304,
  },
  {
    postOffice: "Shivani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Siddhewadi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Siddhewadi Savalaj",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Sidhnath",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Sindur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Sonavade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415415,
  },
  {
    postOffice: "Soni",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416420,
  },
  {
    postOffice: "Sonkire",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Sonsal",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Sonyal",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Sordi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Subhashnagar",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416437,
  },
  {
    postOffice: "Sultangade",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415307,
  },
  {
    postOffice: "Surul",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415407,
  },
  {
    postOffice: "Suslad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Tadavale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Tadawale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415306,
  },
  {
    postOffice: "Tadsar",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415304,
  },
  {
    postOffice: "Takari",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415313,
  },
  {
    postOffice: "Takave",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415410,
  },
  {
    postOffice: "Tambave",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415404,
  },
  {
    postOffice: "Tanang",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415411,
  },
  {
    postOffice: "Tasgaon M.D.G",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Tavadarwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416303,
  },
  {
    postOffice: "Thanapude",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415412,
  },
  {
    postOffice: "Tikundi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416412,
  },
  {
    postOffice: "Tisangi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416405,
  },
  {
    postOffice: "Tondoli",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Tujarpur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415403,
  },
  {
    postOffice: "Tung",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416301,
  },
  {
    postOffice: "Tupari",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415313,
  },
  {
    postOffice: "Turchi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Umadi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Umarani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Upalavi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416306,
  },
  {
    postOffice: "Upale Mayani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Urun Islampur",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Utagi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Vaddi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Vadgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416311,
  },
  {
    postOffice: "Vadgaon Mohite",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415303,
  },
  {
    postOffice: "Vadiye Raibag",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Vajrawad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Valkhad",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415310,
  },
  {
    postOffice: "Valsang",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Valuj",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415309,
  },
  {
    postOffice: "Valvan",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415308,
  },
  {
    postOffice: "Vangi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415305,
  },
  {
    postOffice: "Vasgade",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Vasumbe  T",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Vasumbe",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415309,
  },
  {
    postOffice: "Vejegaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415310,
  },
  {
    postOffice: "Vhaspet",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416413,
  },
  {
    postOffice: "Vibhutwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415301,
  },
  {
    postOffice: "Vihapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415304,
  },
  {
    postOffice: "Visapur    Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416314,
  },
  {
    postOffice: "Vite City",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Vite",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Waiphale",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Wakurde BK",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Wakurde Khurd",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415408,
  },
  {
    postOffice: "Walekhindi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Walwa",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416313,
  },
  {
    postOffice: "Wanlsswadi",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416414,
  },
  {
    postOffice: "Warnavati Vasahat",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415415,
  },
  {
    postOffice: "Washan",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416402,
  },
  {
    postOffice: "Wategaon",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415410,
  },
  {
    postOffice: "Willingdon College Sangli",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416415,
  },
  {
    postOffice: "Yamgarwadi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416312,
  },
  {
    postOffice: "Yedemachindra",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415409,
  },
  {
    postOffice: "Yedenipani",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415403,
  },
  {
    postOffice: "Yelapur",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415405,
  },
  {
    postOffice: "Yelavi",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416416,
  },
  {
    postOffice: "Yelur",
    Type: "S.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415411,
  },
  {
    postOffice: "Yelvi",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416404,
  },
  {
    postOffice: "Yerandoli",
    Type: "B.O directly a/w Head Office",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 416410,
  },
  {
    postOffice: "Yetgaon",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415311,
  },
  {
    postOffice: "Zare",
    Type: "B.O",
    District: "Sangli",
    State: "MAHARASHTRA",
    Pincode: 415301,
  },

  {
    postOffice: "Shindi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Uchat",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415715,
  },
  {
    postOffice: "Bhatghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412206,
  },
  {
    postOffice: "Abhepuri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Adarki BK",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415537,
  },
  {
    postOffice: "Adarki Khurd",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415537,
  },
  {
    postOffice: "Adul",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Agashivnagar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Ahir",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Ahire",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Akoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Alewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Ambale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Ambavade S Wagholi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Ambavawde KHD",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Ambawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Ambawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Ambawade S Koregaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Ambedare Bk",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Ambheri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Ambheri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Ambrag",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Ambrule",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Anapatwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Andali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Andarud",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Andhari",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Andori",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Anewadi",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Angapur Vandan",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415019,
  },
  {
    postOffice: "Antavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Apashinge  Rahimatpur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Apashinge",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415518,
  },
  {
    postOffice: "Apati",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Aradgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Arale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Are",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Arewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Arvi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415116,
  },
  {
    postOffice: "Asagaon Vasahat",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Asale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415513,
  },
  {
    postOffice: "Asangaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Asani",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Asare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Asawali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Asia Plato",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412805,
  },
  {
    postOffice: "Asu",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Atake",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Atit",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Atit",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Aundh",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415510,
  },
  {
    postOffice: "Avarde",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Bahule",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Baleghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Bamanoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Bambawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Bamnoli Tarf Kudal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Banavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Banavady Colony",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Banghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Banpuri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Banpuri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Barad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Bavadhan",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412804,
  },
  {
    postOffice: "Bawada",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Beladare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Belawade BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Belawade Hawali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Belawade KH",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Bhadale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Bhade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415526,
  },
  {
    postOffice: "Bhaktawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415010,
  },
  {
    postOffice: "Bhanang",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Bharatgaon Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Bhatmarli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Bhilar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412805,
  },
  {
    postOffice: "Bhiwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Bhogaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Bholi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415526,
  },
  {
    postOffice: "Bhosare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Bhose",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Bhuinj",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415515,
  },
  {
    postOffice: "Bhurkawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415505,
  },
  {
    postOffice: "Bibhavi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Bibi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415537,
  },
  {
    postOffice: "Bibi Patan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Bichukale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Bidal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Bijawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Biramani",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Birwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Bombale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Bondri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Bopardi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Bopoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415207,
  },
  {
    postOffice: "Boposhi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Boragaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Boregaon  Agricultural School",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Borgaon BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Borjaiwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Borkhal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Budh",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Budhawar Naka Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Chanchali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Chandak",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415536,
  },
  {
    postOffice: "Chaphal",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Charegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Chavan Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Chavanwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Chchegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415122,
  },
  {
    postOffice: "Chikhaewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Chikhali Masur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Chimangaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Chinchaner S. Nimb",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Chinchaner Vandan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Chindhawali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415513,
  },
  {
    postOffice: "Chitali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415538,
  },
  {
    postOffice: "Chopadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Chorade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415538,
  },
  {
    postOffice: "Chore",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Dadholi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Dahivad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Dahivadi",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Dapavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412805,
  },
  {
    postOffice: "Dare Khurd",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Daruj",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415505,
  },
  {
    postOffice: "Daulat Nagar    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415211,
  },
  {
    postOffice: "Degaon Shirgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Degaon Tambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Dervan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Deur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Devapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Dhamner",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Dhamni",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Dharpudi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415505,
  },
  {
    postOffice: "Dhavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Dhaval",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Dhawad Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Dhawadshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Dhebewadi",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Dhom",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Dhondewadi  K. Khatav",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Dhondewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415104,
  },
  {
    postOffice: "Dhoroshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Dhudhebavi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Dhuldev",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Dhumalwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415010,
  },
  {
    postOffice: "Diskal Mol",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415504,
  },
  {
    postOffice: "Divad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Divashi BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Divashi Khrud",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Dixi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Dund",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Ekambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Eksare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Gajavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Galmewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Gamewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Ganje",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Garwade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Gavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Gavdoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Ghadagewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415537,
  },
  {
    postOffice: "Gharewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Ghogaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Ghosatawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Ghot",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Girvi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Gogave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Gojegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Gokhali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Gondawale BK",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415540,
  },
  {
    postOffice: "Gondawale KHD",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415540,
  },
  {
    postOffice: "Gopuj",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415510,
  },
  {
    postOffice: "Goregaon Vangi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Govare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Govare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415207,
  },
  {
    postOffice: "Gove",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415015,
  },
  {
    postOffice: "Gudhe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Gulumb",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415536,
  },
  {
    postOffice: "Gunavare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Gurusale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Hajarmachi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Harchandi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Hateghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Hatlot",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Helagaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Helwak",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415207,
  },
  {
    postOffice: "Hingangaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Hivare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Hol",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415522,
  },
  {
    postOffice: "Holichagaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415538,
  },
  {
    postOffice: "Humgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Indoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Injabav",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "ITI Satara",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Jaigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415510,
  },
  {
    postOffice: "Jakatwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Jakhangaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415505,
  },
  {
    postOffice: "Jalagewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Jalav",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Jalgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415010,
  },
  {
    postOffice: "Jamb  Kikali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415530,
  },
  {
    postOffice: "Jamb Budurk",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Jamb Khurd",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Jamb Khatav",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415505,
  },
  {
    postOffice: "Janugade Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Javale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412801,
  },
  {
    postOffice: "Javli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Jihe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Jinti  Ond",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Jinti",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415522,
  },
  {
    postOffice: "Jinti.",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Kadavebuduruk",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Kadegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Kadhane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Kalagaon Umbraj",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Kalaj",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415528,
  },
  {
    postOffice: "Kalambi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Kalawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Kale    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415104,
  },
  {
    postOffice: "Kaledhon",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415312,
  },
  {
    postOffice: "Kalgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Kameri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415019,
  },
  {
    postOffice: "Kanher Khed",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Kanher-akale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Kanheri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Kanur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412804,
  },
  {
    postOffice: "Kapil Goleshwar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Kapshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415537,
  },
  {
    postOffice: "Karad",
    Type: "H.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415110,
  },
  {
    postOffice: "Karadcity",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415110,
  },
  {
    postOffice: "Karandi  Kudal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Karandi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Karanjkhop",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Karavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Kari",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Karnawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Karve",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Kasarshirambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Kashil",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Kasur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Katahapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Katarkhatav",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Katgun",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415502,
  },
  {
    postOffice: "Kavathe",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415516,
  },
  {
    postOffice: "Kavathe.",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Kedambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Kelaghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Kelambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Kelembe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Keloli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Kenjal",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415536,
  },
  {
    postOffice: "Keral",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Khale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Khanapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Khandala Bavada",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Kharshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Kharshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Khatagun",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415502,
  },
  {
    postOffice: "Khatav",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415505,
  },
  {
    postOffice: "Khed  Nandgiri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415010,
  },
  {
    postOffice: "Khed BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Khed Wadhe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415003,
  },
  {
    postOffice: "Khengar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412805,
  },
  {
    postOffice: "Khodoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Khojewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415518,
  },
  {
    postOffice: "Khubi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415108,
  },
  {
    postOffice: "Khunte",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Kidgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415015,
  },
  {
    postOffice: "Kikali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415530,
  },
  {
    postOffice: "Kindawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Kinhai",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Kiroli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415116,
  },
  {
    postOffice: "Kisanveernagar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415530,
  },
  {
    postOffice: "Kival",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Kodoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Kodoli Karad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Kokarale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Kokisare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Kole    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Kolewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Kolki    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Kondhav Satara",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Konegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Koparde  Lonand",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Koparde",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415518,
  },
  {
    postOffice: "Koprde Hawali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415115,
  },
  {
    postOffice: "Koregaon City",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Koregaon",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Korivale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Koriwale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Koyana",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415207,
  },
  {
    postOffice: "Krti",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Kshatra Mahuli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415003,
  },
  {
    postOffice: "Kshetra Mahabaleshwar.",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Kudal    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Kukudwad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Kulakjai",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Kumathe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Kumathe Nagache",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Kumbhargaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Kumbharoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Kumthe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Kuroli Sidheshwar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Kuroshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Kusawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Kusumbi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Kusurund",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Kuthare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Ladegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Lahasurne",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Lalgun",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Limbgove",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415015,
  },
  {
    postOffice: "Loham",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Lonand",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415526,
  },
  {
    postOffice: "Machutar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Mahabaleshwar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Mahasave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415513,
  },
  {
    postOffice: "Mahathe BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Mahawashi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Mahawshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Mahiamngad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415023,
  },
  {
    postOffice: "Mahigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Mahind",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Majgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Majgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415518,
  },
  {
    postOffice: "Malagaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Malakapur Karad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Malashi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Malawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Maldan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Malharpeth Karad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Mamuradi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Mandave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Mandhar Deo",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Mandhave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Mandrul Haveli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Mandrulkole",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Mane Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Mangalwar Peth Karad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415110,
  },
  {
    postOffice: "Mangalwar Peth Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Marali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415211,
  },
  {
    postOffice: "Marali Chore",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Mardhe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Mardi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Mariechi Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Market yarad Phaltan",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Market Yard Karad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415110,
  },
  {
    postOffice: "Marul",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Marul Haveli",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415212,
  },
  {
    postOffice: "Masur    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Mayani",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415102,
  },
  {
    postOffice: "Medha",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Menavali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Mendh",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Mendhoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Met Gutad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Mhaopre",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Mhasoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Mhasurne",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415538,
  },
  {
    postOffice: "Mhaswad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "MIDC Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Mohat.",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Mohi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Mol  Diskal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415504,
  },
  {
    postOffice: "Morale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415102,
  },
  {
    postOffice: "Morawale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Morghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Morgiri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Morve",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Mouje Tasgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Munawale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Mundhe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Murud",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Nade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Nadoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Nagewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415015,
  },
  {
    postOffice: "Nagthane",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Nagzari",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415116,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412801,
  },
  {
    postOffice: "Nandal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415528,
  },
  {
    postOffice: "Nandalapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Nandawal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Nandgaon  Ond",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415107,
  },
  {
    postOffice: "Nandoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415510,
  },
  {
    postOffice: "Nanegaon BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Nanegaon KH",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Naravane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415540,
  },
  {
    postOffice: "Narayanwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Natoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Navadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Navaja",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415207,
  },
  {
    postOffice: "Ner",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415502,
  },
  {
    postOffice: "Nhavi BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Nidhal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415023,
  },
  {
    postOffice: "Nigadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Nigadi Masur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Nigadi Traf",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415004,
  },
  {
    postOffice: "Nimbhore",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415528,
  },
  {
    postOffice: "Nimblak",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Nimsode",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415538,
  },
  {
    postOffice: "Nirgudi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Nisarale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Nisare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Nitral",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Nune",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Nune",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Ogalewadi",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Ond",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Ozarde",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Pachvad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415513,
  },
  {
    postOffice: "Padal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Padali  Lonand",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Padali Masur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Padali Ninam",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Padaloshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415520,
  },
  {
    postOffice: "Padegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Padegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Pal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Palasgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Palashi  Man",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415540,
  },
  {
    postOffice: "Palashi  Vaduth",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Palashi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Palshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412801,
  },
  {
    postOffice: "Panchagani",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412805,
  },
  {
    postOffice: "Panchwad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415312,
  },
  {
    postOffice: "Pande",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415515,
  },
  {
    postOffice: "Pangari",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Paparde",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Parali",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Parayanti",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Parkhandi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Parle",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Pasarni",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Patan    Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Patkhal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Patole Khadaki",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Pawar Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Perale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Petpar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Phaltan",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Phulenagar Wai",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Pimpaloshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Pimpari  K.Khatav",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Pimpari  Man",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415540,
  },
  {
    postOffice: "Pimpari",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Pimpode BK",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Pimpode KH",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Pimprad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Pimpre BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Pingali BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Potale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Pratapgad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Prtri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Pulkoti",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Pusegaon",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415502,
  },
  {
    postOffice: "Pusesawali",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Rahimatpur",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Rahude",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Raigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Rajachekurale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Rajale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Rajapuri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Rajapuri Budh",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Ranand",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Ranashingwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Randullahabad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Ranjani",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412805,
  },
  {
    postOffice: "Rasati",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415207,
  },
  {
    postOffice: "Rautwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Rawadi BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415528,
  },
  {
    postOffice: "Rethare BK",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415108,
  },
  {
    postOffice: "Rethare KH",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415108,
  },
  {
    postOffice: "Revadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Riswad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Rohot",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Sadawaghapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Saidapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Saigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Saikade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Sajjangad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Sajur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Sakharwadi",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415522,
  },
  {
    postOffice: "Sakurdi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Salave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Salpe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Salshirambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Sangamnagar Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415003,
  },
  {
    postOffice: "Sangavi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Sangvad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Sanpane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Sap",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Saratale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415513,
  },
  {
    postOffice: "Sarde",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Sarjapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Sarkalwadi.",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Saskal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Saspade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Sasurve",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Saswad  Lonand",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Satara",
    Type: "H.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415001,
  },
  {
    postOffice: "Sataracity",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Satararoad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415010,
  },
  {
    postOffice: "Savade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Savarghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Senawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415538,
  },
  {
    postOffice: "Shahupuri Satara",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Shamgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Shantinagar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Shapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415115,
  },
  {
    postOffice: "Shendare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Shendurjane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415021,
  },
  {
    postOffice: "Shendurjane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Shere",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415108,
  },
  {
    postOffice: "Sherechiwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Shinde Khurd",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Shindenagar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Shindewadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412801,
  },
  {
    postOffice: "Shinganapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Shirambe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Shirawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415115,
  },
  {
    postOffice: "Shirdhon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Shirgaon Umbraj",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Shirsurwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Shirwal",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412801,
  },
  {
    postOffice: "Shivajinagar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412802,
  },
  {
    postOffice: "Shivanagar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415108,
  },
  {
    postOffice: "Shivthar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Shnoli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415108,
  },
  {
    postOffice: "Shri Plavan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Shukarwar Peth Phaltan",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Snboor",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Somanthali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Somardi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Sonaichi Wadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415209,
  },
  {
    postOffice: "Sonake",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Sonat",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Sonavadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Sonawade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415211,
  },
  {
    postOffice: "Songaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Songaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Songaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Sukhed",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Supane",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Surali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Surawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415528,
  },
  {
    postOffice: "Suruli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Surur",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415517,
  },
  {
    postOffice: "Tadavale S. Koregaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415010,
  },
  {
    postOffice: "Tadavale Vaduj",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Tadawale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Talbid",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Taldeo",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Taliye",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Talmavale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Tambave  Lonand",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415521,
  },
  {
    postOffice: "Tambave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Tamkane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Tandulawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Tapola",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Taradgaon",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415528,
  },
  {
    postOffice: "Tarale",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Tardaf",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Targaon",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415107,
  },
  {
    postOffice: "Tarukha",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Tasavade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Tembu",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Tetali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Thathavada",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Thomase",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Thoseghar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415013,
  },
  {
    postOffice: "Tilak Path Karad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415110,
  },
  {
    postOffice: "Tondale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Tondhoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Trimali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415510,
  },
  {
    postOffice: "Triputi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Tulsan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Udatare",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415513,
  },
  {
    postOffice: "Umarkanchan",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Umbraj",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Undale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Upalave",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Urul",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Vadagaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415109,
  },
  {
    postOffice: "Vadagaon Haweli",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Vadajal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Vadale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Vadgaon J.S",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Vadoli Bhikeshwar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415106,
  },
  {
    postOffice: "Vadoli Nileshwar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415115,
  },
  {
    postOffice: "Vaduj",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Vaduj Town",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Vaduth",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Vahagaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Vaigaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Vajroshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415014,
  },
  {
    postOffice: "Vakhari",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Valuth",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415514,
  },
  {
    postOffice: "Vanzole",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415112,
  },
  {
    postOffice: "Vardhangad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415501,
  },
  {
    postOffice: "Varkute Malawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Varkute Mhaswad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415509,
  },
  {
    postOffice: "Varne",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415019,
  },
  {
    postOffice: "Varoshi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415012,
  },
  {
    postOffice: "Varud",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415527,
  },
  {
    postOffice: "Varungji",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Varye",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415015,
  },
  {
    postOffice: "Vasantgad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Vasole",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Vathar BK",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415526,
  },
  {
    postOffice: "Vathar KH",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415539,
  },
  {
    postOffice: "Vathar Kiroli",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415116,
  },
  {
    postOffice: "Vathar Mimbalkar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Vathar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Vavarhire",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415508,
  },
  {
    postOffice: "Vechale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415519,
  },
  {
    postOffice: "Velang",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Velapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },
  {
    postOffice: "Vele  Kamthi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Vele",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415517,
  },
  {
    postOffice: "Velu",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415511,
  },
  {
    postOffice: "Venegaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415518,
  },
  {
    postOffice: "Vennanagar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415022,
  },
  {
    postOffice: "Vetane",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415503,
  },
  {
    postOffice: "Vidani",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415523,
  },
  {
    postOffice: "Vidyanagar Karad",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415124,
  },
  {
    postOffice: "Vihe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Vihe Vasahat",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415114,
  },
  {
    postOffice: "Vikhale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415524,
  },
  {
    postOffice: "Vikhale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415312,
  },
  {
    postOffice: "Virali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415540,
  },
  {
    postOffice: "Viravade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Virmade",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415020,
  },
  {
    postOffice: "Visapur",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415502,
  },
  {
    postOffice: "Vyahali",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Vyajawadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Wadhe",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415011,
  },
  {
    postOffice: "Wagheri",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415105,
  },
  {
    postOffice: "Wagholi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415525,
  },
  {
    postOffice: "Wai City",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Wai",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412803,
  },
  {
    postOffice: "Wakeshwar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415506,
  },
  {
    postOffice: "Wing  Shirwal",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412801,
  },
  {
    postOffice: "Wing",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415122,
  },
  {
    postOffice: "Yadogophal Peth",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Yashwantnagar",
    Type: "S.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415115,
  },
  {
    postOffice: "Yelagaon",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Yeliv",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415512,
  },
  {
    postOffice: "Yenape",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Yenke",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415103,
  },
  {
    postOffice: "Yenkul",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415111,
  },
  {
    postOffice: "Yerad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415206,
  },
  {
    postOffice: "Yeralwadi",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415507,
  },
  {
    postOffice: "Yeraphale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415205,
  },
  {
    postOffice: "Yeravale",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415122,
  },
  {
    postOffice: "Yevateshwar",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 415002,
  },
  {
    postOffice: "Zanzwad",
    Type: "B.O",
    District: "Satara",
    State: "MAHARASHTRA",
    Pincode: 412806,
  },

  {
    postOffice: "Jambhavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Nerle",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416704,
  },
  {
    postOffice: "Achara",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Achirne",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Adavli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Adeli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Ajgaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Akeri",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Akhavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Ambegaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Amberi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416605,
  },
  {
    postOffice: "Amboli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Ambrad",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416628,
  },
  {
    postOffice: "Anav",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Andurla",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Ansur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Aravli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Are",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Aronda",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416513,
  },
  {
    postOffice: "Aros",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Arule",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Asalda",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416610,
  },
  {
    postOffice: "Asoli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Asolipal",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Asrondi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Avalegaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Ayee",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Aynal",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Bambuli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Banda    Sindhudurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Bandivade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Baparde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416613,
  },
  {
    postOffice: "Bav",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Belnekhurd",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Bhadgaon Budruk",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Bhalaval",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Bharni",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Bharni",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416632,
  },
  {
    postOffice: "Bhirvande",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Bhogave",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416523,
  },
  {
    postOffice: "Bhuibavda",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Bibavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Bidwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Bilwas",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Bordave",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Budhavale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Burambavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Chafekhol",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Charatha",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Chendavan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416524,
  },
  {
    postOffice: "Chindar",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Chindar Bazar",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Chouke",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416605,
  },
  {
    postOffice: "Choukul",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Dabhachiwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Dabhole",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Dabholi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Dahibav",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416630,
  },
  {
    postOffice: "Damre",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Danoli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Dariste",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Degave",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Deulwada",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Deulwada Nerur",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416525,
  },
  {
    postOffice: "Devbag",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Devgad",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416613,
  },
  {
    postOffice: "Devli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Devsu",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Dhakore",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Dhamapur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416605,
  },
  {
    postOffice: "Digas",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Digavle",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Dingane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Dodamarg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Dukanwad",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Gadhitamhane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Gadmath",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Gaudwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Gavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Gavrai",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416534,
  },
  {
    postOffice: "Ghavanale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Ghotage",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Ghotage",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416632,
  },
  {
    postOffice: "Ghotagewadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416549,
  },
  {
    postOffice: "Ghousari",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Girye",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416806,
  },
  {
    postOffice: "Golvan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Gopuri",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Gothane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Gothos",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Goveri",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Hadi",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416626,
  },
  {
    postOffice: "Hadpid",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416610,
  },
  {
    postOffice: "Halval",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Harkul Budruk",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Harkul Khurd",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Hedul",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Het",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Hindale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416630,
  },
  {
    postOffice: "Hirlewadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Hirlok",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Hiwale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Hodavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416529,
  },
  {
    postOffice: "Humarmala",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Humbrat",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Ilye",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Industrial Area Pinguli",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416528,
  },
  {
    postOffice: "Insuli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Jambhavade",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416632,
  },
  {
    postOffice: "Jamsande",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Janavali",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Kadaval",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Kalane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Kaleli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Kalmath",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Kalmist",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416531,
  },
  {
    postOffice: "Kalse",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416605,
  },
  {
    postOffice: "Kalsuli",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416620,
  },
  {
    postOffice: "Kandalgaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Kankavli",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Karanje",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Karivade",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Karliwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416523,
  },
  {
    postOffice: "Karul",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Karulbavada",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Kas",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Kasal",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Kasaral",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Kasarde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Kasavan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Katta",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Katta",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Kavathani",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Kavathi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416524,
  },
  {
    postOffice: "Kelus",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Ker",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416549,
  },
  {
    postOffice: "Keravade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Khaida",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Khambale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Khanoli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Kharepatan",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Khavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416522,
  },
  {
    postOffice: "Khotale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Khudi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Khutvalwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Kinjavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Kirlos",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Kochara",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416522,
  },
  {
    postOffice: "Kokisara",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Kolamb",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Kolgaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Koloshi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416610,
  },
  {
    postOffice: "Kolzar",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Konal",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416549,
  },
  {
    postOffice: "Konalkatta Colony",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416549,
  },
  {
    postOffice: "Korla",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Kotkamte",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Kudal Audyogik Samuha",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416550,
  },
  {
    postOffice: "Kudal    Sindhudurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Kudase",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Kumbharmath",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Kumbhavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Kumbhavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Kumbral",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Kunde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416628,
  },
  {
    postOffice: "Kunkavale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Kunkavan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Kunkeri",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Kunkeshwar",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Kupavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416632,
  },
  {
    postOffice: "Kurangavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Kurli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Kusur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Kuvale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416610,
  },
  {
    postOffice: "Lore-i",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Lore-II",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Madkhol",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Madura",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Madyachiwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Mahadevache Kervade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Mahalunge",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Main",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Malewad",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Malgaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Malgaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Malond",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Malvan",
    Type: "H.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Manche",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Mandkuli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Maneri",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Mangaon    Sindhudurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Mangavli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Mangeli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Masde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Masura",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Math",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Math Budruk",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Matond",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416529,
  },
  {
    postOffice: "Mazgaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Mhapan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416522,
  },
  {
    postOffice: "Mithbav",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416615,
  },
  {
    postOffice: "Mithmumbari",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Mogarne",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Mond",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416623,
  },
  {
    postOffice: "Morgaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Mulde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Munge",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416630,
  },
  {
    postOffice: "Mutat",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416803,
  },
  {
    postOffice: "Nad",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Nadan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416805,
  },
  {
    postOffice: "Nadgive",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Nadhavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Nagve",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Nandos",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Nandrukh",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416605,
  },
  {
    postOffice: "Naneli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Nanivade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Napne",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Nardave",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Naringre",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416630,
  },
  {
    postOffice: "Narur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Natal",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Nemle",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Nerur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416525,
  },
  {
    postOffice: "Netarde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Nhaveli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Nigude",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Nileli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Niravade",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Nirom",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Nirukhe",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Nivaje",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Niwatiwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416522,
  },
  {
    postOffice: "Oros",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Osargaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Otav",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Otavane",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Ovaliye",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Ozaram",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Padel",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416804,
  },
  {
    postOffice: "Padve",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416534,
  },
  {
    postOffice: "Palsamb",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Pandur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Pangrad",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Parme",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Parula",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416523,
  },
  {
    postOffice: "Pat",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416522,
  },
  {
    postOffice: "Patgaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Pawashi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Pendhari",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Pendhryachiwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416529,
  },
  {
    postOffice: "Pendur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Phanasgaon",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Phondaghat",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Pikule",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Pinguli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416528,
  },
  {
    postOffice: "Pirawadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Poip",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Pokharan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416628,
  },
  {
    postOffice: "Pomburla",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416811,
  },
  {
    postOffice: "Poyare",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416630,
  },
  {
    postOffice: "Pural",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416805,
  },
  {
    postOffice: "Ramgad",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Ran Bambuli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Rathivade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Redi",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416517,
  },
  {
    postOffice: "Revandi",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Sadure",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Sakedi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Salel",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416605,
  },
  {
    postOffice: "Salgaon",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Salshi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416610,
  },
  {
    postOffice: "Sandave",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Sangeli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416531,
  },
  {
    postOffice: "Sangulwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Sangve",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416609,
  },
  {
    postOffice: "Sarambal",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Sarjekot",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Sarmale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Sasoli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Satarda",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Sateli Traf Bhedshi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Satose",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Savdav",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Sawantwadi",
    Type: "H.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Sherla",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416514,
  },
  {
    postOffice: "Shidavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Shirange",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Shirgaon",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416610,
  },
  {
    postOffice: "Shiroda    Sindhudurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Shirshinge",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416531,
  },
  {
    postOffice: "Shirval",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416620,
  },
  {
    postOffice: "Shirvande",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Shivapur",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Shivdav",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416620,
  },
  {
    postOffice: "Shravan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416616,
  },
  {
    postOffice: "Sindhudurgnagari MDG",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416812,
  },
  {
    postOffice: "Sonavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416632,
  },
  {
    postOffice: "Sonavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Sonurli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Soundale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416804,
  },
  {
    postOffice: "Sukalwadi",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416534,
  },
  {
    postOffice: "Tak",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Talavade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Talavade",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416529,
  },
  {
    postOffice: "Talavane",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416513,
  },
  {
    postOffice: "Talebazar",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Talekhol",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Talera    Sindhudurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Talgaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416534,
  },
  {
    postOffice: "Talkat",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Tambaldeg",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416615,
  },
  {
    postOffice: "Tambuli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Taramumbari",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416613,
  },
  {
    postOffice: "Tarandale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Tarkarli",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Tembavli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416612,
  },
  {
    postOffice: "Tendoli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Terawalewadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416523,
  },
  {
    postOffice: "Terse Bambarde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Tervan Medhe",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416549,
  },
  {
    postOffice: "Thakurwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416804,
  },
  {
    postOffice: "Tiravade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Tiravade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Tirlot",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416807,
  },
  {
    postOffice: "Tithavli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416703,
  },
  {
    postOffice: "Tondavali",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416626,
  },
  {
    postOffice: "Tondavali",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Torsole",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Trimbak",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Tulas",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416515,
  },
  {
    postOffice: "Tulsuli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Ubhadanda",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Umbarde",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Upale",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416813,
  },
  {
    postOffice: "Usap",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Vada    Sindhudurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416805,
  },
  {
    postOffice: "Vadivaravde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Vados",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Vaibhavwadi",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416810,
  },
  {
    postOffice: "Vajarat",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Valivande",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416611,
  },
  {
    postOffice: "Varad",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416604,
  },
  {
    postOffice: "Varavde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416602,
  },
  {
    postOffice: "Varde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416521,
  },
  {
    postOffice: "Vasoli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416519,
  },
  {
    postOffice: "Vazare",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Vengurla Camp",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Vengurla",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Veral",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416608,
  },
  {
    postOffice: "Verle",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416531,
  },
  {
    postOffice: "Vetal Bambarde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416520,
  },
  {
    postOffice: "Vetore",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Vijaydurg",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416806,
  },
  {
    postOffice: "Vilvade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Wadkhol",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416518,
  },
  {
    postOffice: "Wafoli",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },
  {
    postOffice: "Wagheri",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416601,
  },
  {
    postOffice: "Waghivare",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Waghotan",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416804,
  },
  {
    postOffice: "Waingani",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416614,
  },
  {
    postOffice: "Wainganiwadi",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416516,
  },
  {
    postOffice: "Waingavde",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416603,
  },
  {
    postOffice: "Wairy Bhutnath",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416606,
  },
  {
    postOffice: "Walaval",
    Type: "S.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416524,
  },
  {
    postOffice: "Waniwade",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416613,
  },
  {
    postOffice: "Wargaon",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416801,
  },
  {
    postOffice: "Zarapi",
    Type: "B.O directly a/w Head Office",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416510,
  },
  {
    postOffice: "Zarebambar Vasahat",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416512,
  },
  {
    postOffice: "Zolambe",
    Type: "B.O",
    District: "Sindhudurg",
    State: "MAHARASHTRA",
    Pincode: 416511,
  },

  {
    postOffice: "Achakdani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Adhegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413212,
  },
  {
    postOffice: "Ajnale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413308,
  },
  {
    postOffice: "Akluj",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413101,
  },
  {
    postOffice: "Akola Wasud",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Akole KH",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Akulgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Akumbhe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Alegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Ambe",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Anawali",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Andhalgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Anjandoh",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Anjangaon Umate",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Arali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Aran",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413301,
  },
  {
    postOffice: "Awati",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Babhulgaon",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Bagechiwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413101,
  },
  {
    postOffice: "Balwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413308,
  },
  {
    postOffice: "Bardi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413302,
  },
  {
    postOffice: "Barloni",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413252,
  },
  {
    postOffice: "Bavi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413301,
  },
  {
    postOffice: "Bembale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Bhalvani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Bhalvani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Bhalwani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Bhamburdi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413111,
  },
  {
    postOffice: "Bhandishegaon",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Bhimanagar",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413212,
  },
  {
    postOffice: "Bhogewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413223,
  },
  {
    postOffice: "Bhosa  K",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413315,
  },
  {
    postOffice: "Bhosa  M",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413322,
  },
  {
    postOffice: "Bhosare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Bhutashte",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Bitergaon   Wangi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Bitergaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413252,
  },
  {
    postOffice: "Bohali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Bondle",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Borale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Borgaon  Kml",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Bramhapuri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Chakore",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413101,
  },
  {
    postOffice: "Chale",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Chandapuri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Chikhalthan",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Chikmahud",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Chinke",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413308,
  },
  {
    postOffice: "Chopadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413308,
  },
  {
    postOffice: "Dahigaon   Karmala",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Dahiwali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413210,
  },
  {
    postOffice: "Darfal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Degaon  PPR",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Devlali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Dharmapuri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Dhavlas",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Dhyati  E",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Donaj",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Ekhatpur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Ekshiv",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Gadegaon",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Gaigavan",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Gardi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Gaudwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413314,
  },
  {
    postOffice: "Gherdi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Ghoti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413251,
  },
  {
    postOffice: "Ghoti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Girvi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413111,
  },
  {
    postOffice: "Gopalpur",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Gulsade",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Gunjegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Gursale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Gursale",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Hangirge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Hatid",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Hole",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413315,
  },
  {
    postOffice: "Huljanti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Hunnur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413322,
  },
  {
    postOffice: "Islampur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413107,
  },
  {
    postOffice: "Jainwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Jambhood",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Jamgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Jawala    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Jeur    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Jinti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Junoni",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Kadlas",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Kamalapur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Kandar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Kanhapuri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Karanje",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Karkam",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413302,
  },
  {
    postOffice: "Karmala",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Karmala Town   Karmala",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Kasegaon",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Katphal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Kavhe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413252,
  },
  {
    postOffice: "Kedgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Kem",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413223,
  },
  {
    postOffice: "Kettur-II",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413206,
  },
  {
    postOffice: "Khandali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Khardi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Khavaspur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Khudus",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Kole    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413314,
  },
  {
    postOffice: "Kolegaon   E",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Kolegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Kondbhav",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413101,
  },
  {
    postOffice: "Kondhej",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Korti",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Korti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Kugaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Kumbhargaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Kumbhej",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Kurbavi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Kurdu",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Kurduvadi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Laul",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Lavang",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Laxmi-dahiwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Lendave Chinchali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Lonand",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Lonvire",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Lotewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Madha",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Mahadawar  Pandharpur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Mahalung",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Mahatpur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Mahim",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Mahisgaon",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413250,
  },
  {
    postOffice: "Mahud",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Malewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413101,
  },
  {
    postOffice: "Malinagar",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413108,
  },
  {
    postOffice: "Malkhambi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Maloli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Malsiras",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413107,
  },
  {
    postOffice: "Mandave",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413111,
  },
  {
    postOffice: "Mandki",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413107,
  },
  {
    postOffice: "Mangalvedha",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Mangalvedha Town",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Mangi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Manishanagar Pandharpur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Manjari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Marapur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Marwade",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Mauje Ujani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413210,
  },
  {
    postOffice: "Medad",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413107,
  },
  {
    postOffice: "Medshingi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Mendhapur",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Methawade",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Modnimb",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413301,
  },
  {
    postOffice: "Morochi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Mundhewadi",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Nandeshwar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413322,
  },
  {
    postOffice: "Nandur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Natepute",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Navi Peth  Pandharpur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Nazara",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413308,
  },
  {
    postOffice: "Nerle",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413251,
  },
  {
    postOffice: "Nevare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Nimbhore",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Nimboni",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413322,
  },
  {
    postOffice: "Nimgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Nimgaon T",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413210,
  },
  {
    postOffice: "Niranarshingpur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Ozewadi",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Pachegaon  BK",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413314,
  },
  {
    postOffice: "Pachegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Padasali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Palashi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Palasmandal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Pande",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Pandharechiwadi",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Pandharpur",
    Type: "H.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Paniv",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Pare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Parite",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Patkhal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Patwardhan Kuroli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413315,
  },
  {
    postOffice: "Phadtari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Phalwani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413306,
  },
  {
    postOffice: "Phisare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Phondshiras",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Phulchincholi",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Piliv",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Pimpalkhunte",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Pimpelner",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413210,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Pothare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Puluj",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Purandawade",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413111,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Ranjani",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Raogaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Redde",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413322,
  },
  {
    postOffice: "Ridhore",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413250,
  },
  {
    postOffice: "Ropla   Kvd",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413252,
  },
  {
    postOffice: "Rople BK",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Sadashivenagar",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413111,
  },
  {
    postOffice: "Sade",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413251,
  },
  {
    postOffice: "Salase",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413251,
  },
  {
    postOffice: "Salgar BK",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Sangavi   BK",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Sangewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Sangola",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Sangola Town  Sangola",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Sapatane   Bhose",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Sapatane T",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413210,
  },
  {
    postOffice: "Sarkoli",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Save",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Sawadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Shelgaon Kekan",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Shelve",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Shetphal   Jeur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Shetphal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Shevate",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413315,
  },
  {
    postOffice: "Shindewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413109,
  },
  {
    postOffice: "Shirbhavi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413317,
  },
  {
    postOffice: "Shirnandgi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413322,
  },
  {
    postOffice: "Shivane",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Shivangi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Shreepur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Siddhapur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413305,
  },
  {
    postOffice: "Siddhewadi",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Sogaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413206,
  },
  {
    postOffice: "Sonand",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Sonka",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Suste",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Tadvale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413208,
  },
  {
    postOffice: "Takli Rasin",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Talsangi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413319,
  },
  {
    postOffice: "Tambave",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413108,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413250,
  },
  {
    postOffice: "Tarangphal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413107,
  },
  {
    postOffice: "Tarapur",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Tawashi",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Tembhurni    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Tulshi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413302,
  },
  {
    postOffice: "Tungat",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Udanwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Ughadewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Umbare  Karkam",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413302,
  },
  {
    postOffice: "Umrad",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Undargaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Upalwate",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413223,
  },
  {
    postOffice: "Upari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413310,
  },
  {
    postOffice: "Uplai Budruk",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Uplai Khurd",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Vadshinge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413209,
  },
  {
    postOffice: "Varkute",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413251,
  },
  {
    postOffice: "Veet",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Velapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413113,
  },
  {
    postOffice: "Vihal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413203,
  },
  {
    postOffice: "Wadhegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Wadshivane",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413223,
  },
  {
    postOffice: "Wagholi E",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413112,
  },
  {
    postOffice: "Wakhari",
    Type: "B.O directly a/w Head Office",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413304,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413309,
  },
  {
    postOffice: "Waki Sangola",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Wangi- IIi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Wangi-i",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Warwade",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413211,
  },
  {
    postOffice: "Washimbe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413206,
  },
  {
    postOffice: "Watambre",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Yelmarmangewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413307,
  },
  {
    postOffice: "Yeshwantnagar    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413118,
  },
  {
    postOffice: "Zare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413202,
  },
  {
    postOffice: "Achegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413228,
  },
  {
    postOffice: "Agalgaon",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Aherwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413215,
  },
  {
    postOffice: "Akkalkot",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Akolekati",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Anagar",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Anjangaon  Khilobache",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Ankalge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Ankoli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Antroli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Arali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Ashok Chowk",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413006,
  },
  {
    postOffice: "Ashti    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413303,
  },
  {
    postOffice: "Auj",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Aundhi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },
  {
    postOffice: "Aurad",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Babalad",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413220,
  },
  {
    postOffice: "Babhulgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Badole BK",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Bagehalli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Bale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Barhanpur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Barshi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Barur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Basalegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Bavi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413403,
  },
  {
    postOffice: "Bazarpeth Mohol",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Belati",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Bhalgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413406,
  },
  {
    postOffice: "Bhandar Kavathe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Bhatambre",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Bhurikavathe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Bitale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Boramani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Boroti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Chapalgaon",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Chare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Chikharde",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Chikkehalli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Chumb",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Chungi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Dahitane",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413412,
  },
  {
    postOffice: "Dahitne",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Daji Peth",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413005,
  },
  {
    postOffice: "Darganhalli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Darphalbibi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Darphalgawadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Dayanand Collage",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Degaon    Valuj",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413410,
  },
  {
    postOffice: "Degaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Deodi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413324,
  },
  {
    postOffice: "Deogaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Dhamangaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413402,
  },
  {
    postOffice: "Dhokbabhulgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Dhotri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413228,
  },
  {
    postOffice: "Dombarjawalge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Dongaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Dudhani",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413220,
  },
  {
    postOffice: "Ekurkhe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Gadegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Gaudgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413227,
  },
  {
    postOffice: "Gaudgaon",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413406,
  },
  {
    postOffice: "Ghanegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413403,
  },
  {
    postOffice: "Ghari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Ghatane",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Ghodeshwar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Gholasgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Gogaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Gormale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Gotewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Gulpoli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Gunjegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Gurunanak Nagar",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413003,
  },
  {
    postOffice: "Hagloor",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Haidra",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413227,
  },
  {
    postOffice: "Halduge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Hanjagi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Hannur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Hatti Kanabus",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Hattij",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413406,
  },
  {
    postOffice: "Hattur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Hiraj",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Hiware",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413324,
  },
  {
    postOffice: "Honmurgi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Honsal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Hotagi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413215,
  },
  {
    postOffice: "Hotgigaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413215,
  },
  {
    postOffice: "Ichgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Indiranagar    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413004,
  },
  {
    postOffice: "Ingalgi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Irle",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413412,
  },
  {
    postOffice: "Jamgaon   Agalgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Jamgaon   Pangari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Javalgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413406,
  },
  {
    postOffice: "Jawaharlal Nehru Vastigrah",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413001,
  },
  {
    postOffice: "Jeur   Akkalkot",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Jule Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "K.V.Nagar Barshi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413411,
  },
  {
    postOffice: "Kadabgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413227,
  },
  {
    postOffice: "Kajikanabus",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Kalamwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413403,
  },
  {
    postOffice: "Kalman",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Kamati Khurd",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Kanbus  Gangadhar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413215,
  },
  {
    postOffice: "Kandalgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Karajagi    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Karamba",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Kardehalli",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413006,
  },
  {
    postOffice: "Kari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Karjal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413228,
  },
  {
    postOffice: "Kasegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Kategaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Kauthali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Kavathe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Kegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Khairao",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413410,
  },
  {
    postOffice: "Khamgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Khandali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413324,
  },
  {
    postOffice: "Khandvi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Khuneshwar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Khvhe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Kini",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Kolegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Kondi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Konheri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },
  {
    postOffice: "Koravali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Korphale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Korsegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Kumathe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413224,
  },
  {
    postOffice: "Kumbhari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413006,
  },
  {
    postOffice: "Kurnoor",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Kurul",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Kuslumb",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Kusur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Lamboti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Limbi Chincholi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413228,
  },
  {
    postOffice: "Londewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Maindargi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413217,
  },
  {
    postOffice: "Malegaon  Vairag",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413406,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Malikpeth",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Malkavathe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Malvandi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Mandegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Mandrup",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Manegaon",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413410,
  },
  {
    postOffice: "Mangalwar Peth    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Mardi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Market Yard Barshi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Market Yard Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413005,
  },
  {
    postOffice: "Masale Chaudhari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Medical College    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413003,
  },
  {
    postOffice: "Mhaisalge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Midc Chincholi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Midc Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413006,
  },
  {
    postOffice: "Mohol",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Motyal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Mouje Tadvale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413412,
  },
  {
    postOffice: "Mugali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413220,
  },
  {
    postOffice: "Mulegaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413006,
  },
  {
    postOffice: "Mundewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Musti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Nagansur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413227,
  },
  {
    postOffice: "Nagore",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413217,
  },
  {
    postOffice: "Nai Zindagi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413224,
  },
  {
    postOffice: "Nanaj    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Nandani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Nari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Narkhed",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Navi Peth Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413001,
  },
  {
    postOffice: "Navindgi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413227,
  },
  {
    postOffice: "Nimbargi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Padsali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Pakani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Pandhari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Pangaon    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413403,
  },
  {
    postOffice: "Pangri",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Papari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413324,
  },
  {
    postOffice: "Pathari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Patkul",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },
  {
    postOffice: "Pennur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Pitapur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413226,
  },
  {
    postOffice: "Pokharapur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },
  {
    postOffice: "Raleras",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413402,
  },
  {
    postOffice: "Ranmasale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Ratanjan",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413406,
  },
  {
    postOffice: "Ruddewadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413220,
  },
  {
    postOffice: "Sadar Bazar    Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413003,
  },
  {
    postOffice: "Safale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Sakat",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413403,
  },
  {
    postOffice: "Sakhar Peth",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413005,
  },
  {
    postOffice: "Salgar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Sangvi Budruk",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Sarole",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413402,
  },
  {
    postOffice: "Sasure",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413402,
  },
  {
    postOffice: "Satan Dudhani",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Savaleshwar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Sayyed Virwade",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Shaval",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Shelgaon  R",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Shendri",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Shetphal",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413324,
  },
  {
    postOffice: "Shingadgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413228,
  },
  {
    postOffice: "Shirale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Shirapur  Solapur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Shirwal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Shivshahi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413224,
  },
  {
    postOffice: "Shripat Pimpari",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Sidheswar Peth",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413001,
  },
  {
    postOffice: "Sinnur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413220,
  },
  {
    postOffice: "Sohale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Solapur City",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413007,
  },
  {
    postOffice: "Solapur",
    Type: "H.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413001,
  },
  {
    postOffice: "Solapur Mkt",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Solapur Vidyapeeth",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413255,
  },
  {
    postOffice: "Sondare",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Soregaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "SRPF Camp Solapur",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Subhash Nagar Barshi",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Suler Jawalage",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413215,
  },
  {
    postOffice: "Surdi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Tadsoundane",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Tadwal   Hotagi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413219,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413008,
  },
  {
    postOffice: "Takali Sikandar",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },
  {
    postOffice: "Tambole",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Tikekarwadi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413224,
  },
  {
    postOffice: "Tirhe",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Tolnur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413227,
  },
  {
    postOffice: "Torni",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413217,
  },
  {
    postOffice: "Tulsidas Nagar",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413412,
  },
  {
    postOffice: "Udagi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Ukadgaon",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413404,
  },
  {
    postOffice: "Ule",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Umarge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413216,
  },
  {
    postOffice: "Umbarge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413409,
  },
  {
    postOffice: "Umedpur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413002,
  },
  {
    postOffice: "Upalai    Thonge",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Upale Dhumala",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413401,
  },
  {
    postOffice: "Vairag",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413402,
  },
  {
    postOffice: "Valsang",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413228,
  },
  {
    postOffice: "Valuj",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Vinchur",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Virvade",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413253,
  },
  {
    postOffice: "Wadala",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413222,
  },
  {
    postOffice: "Wadwal",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Wafale",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Wagadari",
    Type: "S.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413218,
  },
  {
    postOffice: "Wakaw",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413214,
  },
  {
    postOffice: "Wangi",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413221,
  },
  {
    postOffice: "Yavali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413412,
  },
  {
    postOffice: "Yawali",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413213,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Solapur",
    State: "MAHARASHTRA",
    Pincode: 413248,
  },

  {
    postOffice: "Airoli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400708,
  },
  {
    postOffice: "Airoli",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400708,
  },
  {
    postOffice: "Belapur Node-- III",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400614,
  },
  {
    postOffice: "Belapur Node- V",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400614,
  },
  {
    postOffice: "Darave",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400706,
  },
  {
    postOffice: "Ghansoli",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400701,
  },
  {
    postOffice: "K.U.Bazar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Konkan Bhavan",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400614,
  },
  {
    postOffice: "Kopar Khairne",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400709,
  },
  {
    postOffice: "Millenium Business Park",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400710,
  },
  {
    postOffice: "Nerul Node-II",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400706,
  },
  {
    postOffice: "Nerul Node-III",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400706,
  },
  {
    postOffice: "Nerul Sec-48",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400706,
  },
  {
    postOffice: "Sanpada",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Turbhe Market",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Turbhe",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Vashi    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Vashi Sec-26",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Vashi-VII",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400703,
  },
  {
    postOffice: "Additional Ambernath",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421506,
  },
  {
    postOffice: "Aghai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Alyani",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Amane",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Ambarje",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Ambernath",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421501,
  },
  {
    postOffice: "Ambernath South",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421501,
  },
  {
    postOffice: "Andad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Angaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Anjur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Apna Bazar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400610,
  },
  {
    postOffice: "Asangaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Atali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421102,
  },
  {
    postOffice: "Atgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Aware",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Badlapur E.D.",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Balegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Balkum",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400608,
  },
  {
    postOffice: "Bhaji Market",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421002,
  },
  {
    postOffice: "Bhatsanagar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421603,
  },
  {
    postOffice: "Bhiwandi",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421308,
  },
  {
    postOffice: "Birwadi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421603,
  },
  {
    postOffice: "Bus Terminus",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421002,
  },
  {
    postOffice: "Chamble",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Chande",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Chargaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Chimbipada",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Chinchwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Chitalsar Manpada",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400607,
  },
  {
    postOffice: "Chondhe Colony",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Dabhad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Dahisar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400612,
  },
  {
    postOffice: "Dalkhan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Dandekarwadi",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Dawale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400612,
  },
  {
    postOffice: "Dhasai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Dhasai",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Dighashi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Diwa",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400612,
  },
  {
    postOffice: "Dolkhamb",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Dombivali I.A.",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421203,
  },
  {
    postOffice: "Dombivali",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421201,
  },
  {
    postOffice: "Dugad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Dunge",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Dwarli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421306,
  },
  {
    postOffice: "Ganeshwadi    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421306,
  },
  {
    postOffice: "Gegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Gokhale Road    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400602,
  },
  {
    postOffice: "Gunde",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Jambhul",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421505,
  },
  {
    postOffice: "Jekegram",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400606,
  },
  {
    postOffice: "Kalamgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Kalher",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Kalwa",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400605,
  },
  {
    postOffice: "Kalyan City",
    Type: "H.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421301,
  },
  {
    postOffice: "Kalyan D.C.",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421301,
  },
  {
    postOffice: "Kalyan Rs",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421301,
  },
  {
    postOffice: "Kansai",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421501,
  },
  {
    postOffice: "Kasara    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421602,
  },
  {
    postOffice: "Kasarvadavali",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400615,
  },
  {
    postOffice: "Kasegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Katemanivali",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421306,
  },
  {
    postOffice: "Kausa",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400612,
  },
  {
    postOffice: "Khadavali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421605,
  },
  {
    postOffice: "Kharade",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Kharbav",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Khardi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Kharid",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Kharivali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Khoni",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421204,
  },
  {
    postOffice: "Khupri",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Khutal Baragaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Khutghar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Kinholi",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Kishore",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Kolthan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Kon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421311,
  },
  {
    postOffice: "Kondhale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Kopri Colony",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400603,
  },
  {
    postOffice: "Kudus",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Kulgaon",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Kunde",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Lenad Khurd",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Lonad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Mahapoli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Majgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Mal",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Mamnoli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421103,
  },
  {
    postOffice: "Manda",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421605,
  },
  {
    postOffice: "Mangrul",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421306,
  },
  {
    postOffice: "Manivali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421102,
  },
  {
    postOffice: "Manjarli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Manpada",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421204,
  },
  {
    postOffice: "Met",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Mhasa",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Milhe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Mohone",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421102,
  },
  {
    postOffice: "Mokhawane",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421602,
  },
  {
    postOffice: "Moroshi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Mugaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Mumbra",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400612,
  },
  {
    postOffice: "Murbad",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Narivali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Naupada    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400602,
  },
  {
    postOffice: "Nehroli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Netaji Bazar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421505,
  },
  {
    postOffice: "Nilje",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421204,
  },
  {
    postOffice: "O.E.Ambernath",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421502,
  },
  {
    postOffice: "Pachhapur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Padgha Extension Counter",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421101,
  },
  {
    postOffice: "Padgha",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421101,
  },
  {
    postOffice: "Padle",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421204,
  },
  {
    postOffice: "Palsai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Palu",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Patgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Paye",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Phalegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421605,
  },
  {
    postOffice: "Pimpaloli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Pimplas",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421311,
  },
  {
    postOffice: "Pisavli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421306,
  },
  {
    postOffice: "Piwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Ramnagar    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421201,
  },
  {
    postOffice: "Rayate",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421103,
  },
  {
    postOffice: "Rehnal",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Sakadbav",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Sakurli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Sandozbaugh",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400607,
  },
  {
    postOffice: "Saralambe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Saralgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Saravali",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421311,
  },
  {
    postOffice: "Sasne",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Sawaroli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Sayale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Shahad",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421103,
  },
  {
    postOffice: "Shahapur    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Shantinagar E.D.",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Shastrinagar    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Shelar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Shenwa",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Shere",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Shirol",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421602,
  },
  {
    postOffice: "Shiroshi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Shivale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Shrirangnagar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400608,
  },
  {
    postOffice: "Sogaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Station Road Unr-3",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421002,
  },
  {
    postOffice: "Subhash Road    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421301,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Tansa",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Tapasenagar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400608,
  },
  {
    postOffice: "Tembhurli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Thakurli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421201,
  },
  {
    postOffice: "Thane Bazar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400601,
  },
  {
    postOffice: "Thane East",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400603,
  },
  {
    postOffice: "Thane",
    Type: "H.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400601,
  },
  {
    postOffice: "Thane R.S.",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400601,
  },
  {
    postOffice: "Thune",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421403,
  },
  {
    postOffice: "Tilaknagar    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421201,
  },
  {
    postOffice: "Titwala",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421605,
  },
  {
    postOffice: "Tokawade",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Tulai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421401,
  },
  {
    postOffice: "Uchat",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421312,
  },
  {
    postOffice: "Ulhasnagar 1",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421002,
  },
  {
    postOffice: "Ulhasnagar-2",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421002,
  },
  {
    postOffice: "Ulhasnagar-4",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421004,
  },
  {
    postOffice: "Ulhasnagar-5",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421005,
  },
  {
    postOffice: "Vadavali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Vadpe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421302,
  },
  {
    postOffice: "Vaholi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421103,
  },
  {
    postOffice: "Vaishakhare",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421402,
  },
  {
    postOffice: "Vaitarna",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Vangani",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Varap",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421103,
  },
  {
    postOffice: "Vashala",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421602,
  },
  {
    postOffice: "Vashind E.D.",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Vehele",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Vehloli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Vehloli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421601,
  },
  {
    postOffice: "Vidyashram",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421305,
  },
  {
    postOffice: "Vihigaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421602,
  },
  {
    postOffice: "Vishnunagar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421202,
  },
  {
    postOffice: "Wadi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421306,
  },
  {
    postOffice: "Wagle I.E.",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400604,
  },
  {
    postOffice: "Waklan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 400612,
  },
  {
    postOffice: "Yeranjad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421503,
  },
  {
    postOffice: "Abitghar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Abje",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Acchad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Agarwadi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Agashi",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401301,
  },
  {
    postOffice: "Agawan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Akkarpatti",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Alonde",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Arnala",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401302,
  },
  {
    postOffice: "Asangaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401103,
  },
  {
    postOffice: "Ase",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Ashagad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Audhani",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Badapokharan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401601,
  },
  {
    postOffice: "Baliwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Bandhan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Bapugaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Bassein Road",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401202,
  },
  {
    postOffice: "Bassein",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Bategaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Bavada",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401103,
  },
  {
    postOffice: "Beriste",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Bhatane",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Bhayander East",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401105,
  },
  {
    postOffice: "Bhayander West",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401101,
  },
  {
    postOffice: "Bhopoli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Bhuigaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Boisar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Bolinj",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Bordi",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401701,
  },
  {
    postOffice: "Borigaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401701,
  },
  {
    postOffice: "Chandansar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Chandigaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401103,
  },
  {
    postOffice: "Charoti",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Chas",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Chikhal Dongre",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401301,
  },
  {
    postOffice: "Chikhale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Chinchani",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401503,
  },
  {
    postOffice: "Dabhadi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Dabheri",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Dadade",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401605,
  },
  {
    postOffice: "Dahanu Road",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Dahanu",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401601,
  },
  {
    postOffice: "Dahanu TPS",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401608,
  },
  {
    postOffice: "Dahisar Tarapur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401502,
  },
  {
    postOffice: "Dahisar tymanor",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Dahtsar T",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Dandi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Dapchari",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401610,
  },
  {
    postOffice: "Dapoli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Datiware",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Dehere",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Dengachimet",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Dhaniwari",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Dhanoshi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Dhekale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Dhundalwadi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Dohe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Dolhara",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Dongri",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401106,
  },
  {
    postOffice: "Dongri",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Durves",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Edwan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Ganeshpuri",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401206,
  },
  {
    postOffice: "Ganjad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Gargoan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Gass",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401203,
  },
  {
    postOffice: "Ghiwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401502,
  },
  {
    postOffice: "Ghodbander",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401107,
  },
  {
    postOffice: "Gholvad",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401702,
  },
  {
    postOffice: "Girgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Girij",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Gokhiware",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Gomghar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Gorhe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Govade",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Gowane",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401103,
  },
  {
    postOffice: "Hamarapur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Holi Bazar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Jamsar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Jamshet",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Jawhar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Juchandra",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Kainad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Kalamb",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401304,
  },
  {
    postOffice: "Kalambe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Kaman",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Kanchad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Karaj Gaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Karhe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Kasa Budruk",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401607,
  },
  {
    postOffice: "Kasa",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401607,
  },
  {
    postOffice: "Kashi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401107,
  },
  {
    postOffice: "Katale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Kaular Budruk",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Kelthan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401204,
  },
  {
    postOffice: "Kelwa Road",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401401,
  },
  {
    postOffice: "Kelwa    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401401,
  },
  {
    postOffice: "Kelwa-mahim",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401402,
  },
  {
    postOffice: "Kev",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Khaniwade",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Khaniwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401204,
  },
  {
    postOffice: "Khare Ambiwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Kharegaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Kharekuran",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Khariwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Khodale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Khoste",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401605,
  },
  {
    postOffice: "Khuded",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Koch",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Kochai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Kogade",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Kondhan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Kone",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Kophrad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401301,
  },
  {
    postOffice: "Kosbad-hill",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401703,
  },
  {
    postOffice: "Kudan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401502,
  },
  {
    postOffice: "Kumbhawali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Kurze",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Kurze",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Mahagaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Mala",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Malwada",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Mandvi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Maniwali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Manor",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Maswan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Medhi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401605,
  },
  {
    postOffice: "Mira Road",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401107,
  },
  {
    postOffice: "Mira",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401107,
  },
  {
    postOffice: "Modgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Moj",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Mokhada",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Morhanda",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Mulgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Murbe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Nadore",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401207,
  },
  {
    postOffice: "Nala",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401203,
  },
  {
    postOffice: "Nallosapare E",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401209,
  },
  {
    postOffice: "Nanapur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Nandgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Nandgaon Tarapur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Narpad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401601,
  },
  {
    postOffice: "Navghar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Nihe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Nikane",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Nirmal    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401304,
  },
  {
    postOffice: "Nyhale Budruk",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Nyhale Khurdh",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Ondhe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401605,
  },
  {
    postOffice: "Palghar",
    Type: "H.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401404,
  },
  {
    postOffice: "Papdi",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401207,
  },
  {
    postOffice: "Parali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Pargaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Parnali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401504,
  },
  {
    postOffice: "Parol",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Pathardi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Pelhar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Pik",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Pimpalshet",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Poshera",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Posheri",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Rai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401101,
  },
  {
    postOffice: "Rajawali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Rankol",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401607,
  },
  {
    postOffice: "Ranshet",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Saiwan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Saiwan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Sakhare",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Sakharshet",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Saloli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401201,
  },
  {
    postOffice: "Salwad",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401504,
  },
  {
    postOffice: "Sankhare",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Sarawali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Sasun Navghar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Satiwai",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Satpati",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Sawata",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Shigaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Shirgaon",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Shiroshi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Somta",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401403,
  },
  {
    postOffice: "Sonale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Sopara",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401203,
  },
  {
    postOffice: "Sukhasale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401605,
  },
  {
    postOffice: "Suryanagar    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401609,
  },
  {
    postOffice: "Talasari",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Talawada",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401607,
  },
  {
    postOffice: "Tanashi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401503,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Tarapur App",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401504,
  },
  {
    postOffice: "Tarapur    Thane",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401502,
  },
  {
    postOffice: "Tarapur Ti",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401506,
  },
  {
    postOffice: "Tawa",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401607,
  },
  {
    postOffice: "Tembhikhodave",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Tembhode",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Tilher",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Tuse",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Udhale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Udhave",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Umbarpada",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Umela",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401202,
  },
  {
    postOffice: "Umrale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401203,
  },
  {
    postOffice: "Umroli",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401501,
  },
  {
    postOffice: "Uplat",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Urse",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Usarani",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Utawali",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Uttan",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401106,
  },
  {
    postOffice: "Vadhavan",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401503,
  },
  {
    postOffice: "Vajreshwari",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401204,
  },
  {
    postOffice: "Valiv",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Valvande",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Vangaon",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401103,
  },
  {
    postOffice: "Varale",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Varor",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401503,
  },
  {
    postOffice: "Vasai East IE",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401208,
  },
  {
    postOffice: "Vasai Road E",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401202,
  },
  {
    postOffice: "Vashala",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401604,
  },
  {
    postOffice: "Vatar",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401301,
  },
  {
    postOffice: "Vedhe",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401204,
  },
  {
    postOffice: "Vengani",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401502,
  },
  {
    postOffice: "Veti",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401607,
  },
  {
    postOffice: "Veur",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401405,
  },
  {
    postOffice: "Vevji",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Vikramgad",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401605,
  },
  {
    postOffice: "Vilkos",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Virar East",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401305,
  },
  {
    postOffice: "Virar",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401303,
  },
  {
    postOffice: "Virathan Budruk",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401102,
  },
  {
    postOffice: "Wada",
    Type: "S.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401602,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 421303,
  },
  {
    postOffice: "Zap",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401603,
  },
  {
    postOffice: "Zari",
    Type: "B.O",
    District: "Thane",
    State: "MAHARASHTRA",
    Pincode: 401606,
  },
  {
    postOffice: "Agargaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Ajansara",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Akoli",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Allipur    Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442304,
  },
  {
    postOffice: "Andori",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Anji",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Antora",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Arvi Chhoti",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Arvi    Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Ashti    Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Bela",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Bhankheda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442304,
  },
  {
    postOffice: "Bharaswara",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Bhidi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Bhishnoor",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Bokenagar",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Borgaon Hatla",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Borgaon Meghe",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Borgaon Tumni",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Bori Bordharan",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Bothuda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Chikni",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Chincholi Dange",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Chistur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Cottom Market",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442003,
  },
  {
    postOffice: "Cotton Market",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442003,
  },
  {
    postOffice: "Dahegaon Dhande",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Dahegaon Gawande",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Dahegaon Gondi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Dahegaon Gosavi",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Dahegaon Miskin",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Dahegaon Mustafa",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Daroda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Dattapur",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Delwadi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Deoli    Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Deorwara",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Dhanodi Bahaddarpur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Dhanoli",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Dhotra",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442304,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Gaul",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Ghorad",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Girad",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442305,
  },
  {
    postOffice: "Giroli",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Goji",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442304,
  },
  {
    postOffice: "Gopuri",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Hamdapur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Hindinagar",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442003,
  },
  {
    postOffice: "Hinganghat",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Hinganghat Town",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Hingani",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Indira Market-Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Inzala",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Jalgaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Jam",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Jamalpur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Jasapur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Junapani",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Junona",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Juwadi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Kachnoor",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Kajali",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Kajalsara",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Kakda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Kandegaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Kandhali",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Kangaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Kannamwargram",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Kaotha",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Kapsi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Karanja    Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Kasarkheda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Kelzar",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Khadki",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Khangaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Khapari",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Kharangna Gode",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Kharangna",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Khubgaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Kopra",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Kora",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Kotamba",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Lahan Arvi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Madni Karanji",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Mahabala",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Malegaon Theka",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Manas Mandir-Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Mandgaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Mandwa",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Mangrul Dasoda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Manikwara",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Masod",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "MGIHU",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Mozari",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Nachangaon",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Nagzari",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Nalwadi",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Nandora Dafre",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Nandori",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Nandpur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Nara",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Neri Mirzapur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Nimbha",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Nimboli",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Padegaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Paloti",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Panwadi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Pardi  Nagaji",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Pardi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Paunar",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442111,
  },
  {
    postOffice: "Paunoor",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Pimpalgaon Lute",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Pimpalkhuta",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442106,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Pipri Meghe",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Pipri Pargothan",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Pohana",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Pulgaon Camp",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442303,
  },
  {
    postOffice: "Pulgaon",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Ramnagar-Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Rasulabad",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Rehaki Kala",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Rohana",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Rohini",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Sahur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Salaipevath",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Salod Hirapur",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Samudrapur",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442305,
  },
  {
    postOffice: "Sarwadi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Sawali BK",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Sawali Khurd",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Sawali Wagh",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Sawangi Meghe",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Seldoha",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Seloo Kate",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Seloo",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Selsura",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Sewagram",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Shegaon Kund",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Shekapur Bai",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Shirpur",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Sindi",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442105,
  },
  {
    postOffice: "Sindi Vihiri",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Sirasgaon Bazar",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442304,
  },
  {
    postOffice: "Sonegaon Abaji",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Sonora",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Sorta",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Sukali Bai",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Sukli Station",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Surgaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },
  {
    postOffice: "Takarkheda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Talegaon Talatule",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Talegaon SP",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Tarasawanga",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Taroda",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442102,
  },
  {
    postOffice: "Thanegaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Umri",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442203,
  },
  {
    postOffice: "Vijaygopal",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442306,
  },
  {
    postOffice: "Virul",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442302,
  },
  {
    postOffice: "Wabgaon",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "Wadala",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442202,
  },
  {
    postOffice: "Wadhona",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Wadner",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Wagholi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Waifad",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Waigaon Gond",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442305,
  },
  {
    postOffice: "Waigaon Haldya",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442305,
  },
  {
    postOffice: "Waigaon Nipani",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Wardha Ganj",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442003,
  },
  {
    postOffice: "Wardha",
    Type: "H.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Wardha Market",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Wardhamaneri",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442201,
  },
  {
    postOffice: "Washi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442301,
  },
  {
    postOffice: "Watkhed",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442101,
  },
  {
    postOffice: "YAC Wardha",
    Type: "S.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Yelakeli",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Yerala",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442307,
  },
  {
    postOffice: "Zadgaon Gosavi",
    Type: "B.O directly a/w Head Office",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442001,
  },
  {
    postOffice: "Zadsi",
    Type: "B.O",
    District: "Wardha",
    State: "MAHARASHTRA",
    Pincode: 442104,
  },

  {
    postOffice: "Adoli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Amana",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Amkheda",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Anchal",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Ansing",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Arak",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444409,
  },
  {
    postOffice: "Asegaon",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444409,
  },
  {
    postOffice: "Asola KD",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Assegaon Pen",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Bembla",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Bhamb",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Bhatumra",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Bhuli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Chandur",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444004,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Chinchamba Bhar",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Chinchambapen",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Chivara",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Dabha",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Dapura Kd",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Dawha",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Dhamni",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Dhanaj BK",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Dhanaj KD",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Dhanora Ghadge",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Dhanora KD",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444409,
  },
  {
    postOffice: "Dhawanda",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Dhumka",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Dongarkinhi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Falegaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444409,
  },
  {
    postOffice: "Fulumari",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Gaiwal",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Girda",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Giroli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Gobhani",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Gowardhan",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Gurumandir Karanja",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Gwaha",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Haral",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Hinganwadi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Hivara Rohila",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Hivra BK",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Inzori",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Jaipur",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Jaulka",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Kajalamba",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Kalamba Bodkhe",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444409,
  },
  {
    postOffice: "Kalamba Mahali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Kalambeshwar",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Kamargaon    Washim",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Karanja    Washim",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Karanji",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Karkheda",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Karli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Karli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Kasola",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Kata",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Kawatha Naik",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Kawathal",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Kekatumara",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Kenwad",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Keshavnagar",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Khandala Shinde",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Kherda",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Kiniraja",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Kokalgaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Kondoli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Kothari",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Koyali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Kupta",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Kurum",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444106,
  },
  {
    postOffice: "Ladegaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Lehani",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Linga Kotwal",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Loni Arab",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Loni Sm",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Mahagaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Malegaon",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Malkapur",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444004,
  },
  {
    postOffice: "Manabha",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Mandwa",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Mangrulpir",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Mangrulzanak",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Manora    Washim",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Marsul",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Masalapen",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Medshi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Mendra",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Mohari",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Mohoja",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Mop",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Mothegaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Mungla",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Nagardas",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Nagthana",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Nandhana",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Navali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Netansa",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Palodi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Pangra Bandi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Pangri Kute",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Pangri Navghare",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Pardi Asra",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Pardi Takmore",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Parwa",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Pimpalgaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Pimpri Modak",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Poghat",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Poha",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Pohara Devi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Poti",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Rajura",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Risod",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Rithad",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Sakhardoh",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Sawad",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Sawali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Sawargaon Jire",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Shaha",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Shegi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Shelukhadse",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Shendona",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Shendurjana",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444403,
  },
  {
    postOffice: "Sirpur",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Soyata",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Sukali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Talap BK",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Tamsi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Tandali BK",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Tandali Shewai",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Tiwali",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Tondgaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Tornala",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Tornala",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Ukali Jahangir",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Umara Shamshuddin",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Umari",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Umbarda Bazar",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Vilegaon",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444110,
  },
  {
    postOffice: "Vitholi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Vyad",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Wai",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Waigual",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Wakad",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444506,
  },
  {
    postOffice: "Wara Jahangir",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444409,
  },
  {
    postOffice: "Warala",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444507,
  },
  {
    postOffice: "Waroli",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444404,
  },
  {
    postOffice: "Wasari",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Washim City",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Washim",
    Type: "S.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Yawardi",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Yekamba",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444503,
  },
  {
    postOffice: "Yeota",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444504,
  },
  {
    postOffice: "Yeota",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },
  {
    postOffice: "Yeoti",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444510,
  },
  {
    postOffice: "Yeranda",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444505,
  },
  {
    postOffice: "Zodga",
    Type: "B.O",
    District: "Washim",
    State: "MAHARASHTRA",
    Pincode: 444105,
  },

  {
    postOffice: "Adegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Adegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445210,
  },
  {
    postOffice: "Adegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Ajanti",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Akhada Ward Pandharkawada",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Akola Bazar",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Akoli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Akoli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Akpuri",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Amboda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Anji  Nrusinha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Apti Rampur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Arali",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Arambhi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Arjuna",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Arni",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Asegaon Devi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Ashti",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Asola",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Awdhutwadi  Yavatma l",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Ayata",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445106,
  },
  {
    postOffice: "Babhulgaon",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Bangaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Bansi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Belkhed",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Belora",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Belora",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Belura",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Bhalar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Bhamb Raja",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Bhandari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Bhandegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Bhansara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445106,
  },
  {
    postOffice: "Bhari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Bhavani Jahagir",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445230,
  },
  {
    postOffice: "Bhojla",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Bhosa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Bhulai",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Bittergaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Borda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Borgaon  Linga",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Borgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Borgaon Vitholi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Bori Arab",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Bori Khurd",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Both",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Brahmangaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Brahmangaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Brahmanwada",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Buranda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Chahand",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445308,
  },
  {
    postOffice: "Chalbardi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Chanakha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Chatari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Chhoti Gujri  Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Chikani",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Chikhali Camp",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Chikhali Domga",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Chikhali Kanhoba",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Chikhali Kelapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Chikhali Ramnath",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Chikhalwardha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Chikhli Izara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Chikparam",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445307,
  },
  {
    postOffice: "Chinchala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Chinchghat",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Chinchmandal",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Chinchoni Botoni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Chopan",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Dabha  Pahur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Dabha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Dabhadi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Dahegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445308,
  },
  {
    postOffice: "Daheli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Darati",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Darwha",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Datodi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445106,
  },
  {
    postOffice: "Dehni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Deodhari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Deosari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Deurwada",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Deurwadi Lad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Dhamangaon Deo",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Dhanki    Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Dhanora",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Dhanora Sachaldev",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Dhansal",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Dharna",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Dhoki",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Dhotra",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445401,
  },
  {
    postOffice: "Dighi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Digras",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Dongarkharda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Falegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Fetra",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Fubgaon Kharbi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Fulsavangi",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445230,
  },
  {
    postOffice: "Gahuli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Ganori",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Gaul, Khurd",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Gharfal",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Ghatanji",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Ghodkhindi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Ghonsa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Ghoti",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Govt Poly  Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Gujari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Gunj",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Harshi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Harsul",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Haru",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Hatola",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445210,
  },
  {
    postOffice: "Hiwara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Hiwari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Hiwra Majra",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Hudi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Issapur",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445209,
  },
  {
    postOffice: "Jalka",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Jalka Road",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Jamb",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Jamb Bazar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Jamni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Jamni Dhundi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Januna",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Jatra Road Wani",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Jawala    Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Jawalgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Jeoli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Jodmoha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Kalamb    Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445401,
  },
  {
    postOffice: "Kalgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Kali Daulat Khan",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Kamathwada",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Kandali",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Kanha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Kapra Methad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Karajgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Karalgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Karanji",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Kasola",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Katri",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445401,
  },
  {
    postOffice: "Kawatha Bazar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Kayar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Kelapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Khadakdari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Khairi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445308,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Kharadgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Kharda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Kharus BK",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Khedbid",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Khopadi BK",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Kinhi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Kolambi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Kolura",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Kona",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Kondri",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Korta",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Kotamba",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Krishi Samiti  Umarkhed",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Krishnapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445207,
  },
  {
    postOffice: "Kumbha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Kurai",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445307,
  },
  {
    postOffice: "Kurli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445230,
  },
  {
    postOffice: "Kurli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Ladkhed",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Lakh Khind",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Lakh Rayachi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Lakhi Jehangir",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Lasina",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Lewa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Lohara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Lohara Izara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445209,
  },
  {
    postOffice: "Lohi",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445210,
  },
  {
    postOffice: "Lonbehel",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Loni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "M.I.D.C.",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Madkona",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Madni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Mahagaon  Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Mahagaon Kasba",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Mahalungi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Mal Hivara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Malked Khurd",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Malkhed BK",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Mandar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Mandawa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Mang Kinhi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Mangala Devi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Mangurda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Manikdoh",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Manikwada",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Mardi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Maregaon Road",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Marki",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Marlegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Marsul",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Matharjun",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Mendholi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Metikheda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Mhaisdodka",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Mhasola",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Moha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Moha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Mohadi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Mohatoli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445110,
  },
  {
    postOffice: "Mohda",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Mohurli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Mokh",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Mokhad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Mop Jawala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Morath",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Mowada",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Mozar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Mozar Izara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Mudana",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Mukutban",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Mulawa",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445211,
  },
  {
    postOffice: "Munzala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445308,
  },
  {
    postOffice: "Murzadi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Nakapardi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Nandepera",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Nanza",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Narsala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Nawargaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Ner Parsopant",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Nerad Purad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Ningnoor",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445230,
  },
  {
    postOffice: "P.N.C. Pusad",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445216,
  },
  {
    postOffice: "Pachkhed",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Pahapal",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Pahur  Dabha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Pahur Izara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Pahur Naskari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Palsi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445211,
  },
  {
    postOffice: "Palsi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445106,
  },
  {
    postOffice: "Palsoni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Pandharkawda",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Pandhurna Khurd",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Pardi  Sawlapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Pardi Limbi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Pardi Naskari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Parwa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Parwa",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Patan",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Patanbori",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Patapangra",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Patharot",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Pedhi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Pendhari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Pimpalapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445308,
  },
  {
    postOffice: "Pimpalgaon Raja",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445401,
  },
  {
    postOffice: "Pimpalkhuta  R",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Pimpalkhuta",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Pimpri",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Pimpri Buti",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Pimpri Kalga",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Pisgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Pohandul",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Pophali",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Postal Colony  Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Punwat",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Pusad",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Pusad Town   Pusad",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Rajur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Rajurwadi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Ralegaon",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Ramgaon Rameshwar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Rani Amravati",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Rani Dhanora",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Rasa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Rohda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445105,
  },
  {
    postOffice: "Runza",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Sagnapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Saikhada BK",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Saikheda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Sakhara Wai",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Sakharadara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Sakhra Khurd",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Sakhra Kolgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445307,
  },
  {
    postOffice: "Sakur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Salod",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Sandawa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Sarul",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Satefal",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445210,
  },
  {
    postOffice: "Satpalli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Sawali Sadoba",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445106,
  },
  {
    postOffice: "Sawana",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Sawangi Perka",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Sawar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Sawargad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Sawargaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445401,
  },
  {
    postOffice: "Sawargaon Bunglow",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445209,
  },
  {
    postOffice: "Sawargaon Gore",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Sawarkheda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Sayat Kharda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Shelodi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Sheloo",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Shelu BK",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Shembal Pimpri",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445209,
  },
  {
    postOffice: "Shendri  Bk  B..",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Shibala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Shiroli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Shirpur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Shivani",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Sindhola Mines",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445307,
  },
  {
    postOffice: "Singad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Sirasgaon Pandhari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Sonbardi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Sonegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445401,
  },
  {
    postOffice: "Soot Girni",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445215,
  },
  {
    postOffice: "Sukli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445103,
  },
  {
    postOffice: "Sukli Jehangir",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Suknegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Sunna",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445305,
  },
  {
    postOffice: "Tadsawali",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Talegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Talegaon Deshmukh",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445202,
  },
  {
    postOffice: "Talni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445109,
  },
  {
    postOffice: "Tarnoli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445210,
  },
  {
    postOffice: "Taroda",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445211,
  },
  {
    postOffice: "Tembhi",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445230,
  },
  {
    postOffice: "Tilakwadi  Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Tiwari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Tiwasa",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Tuptakli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Ukni",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Umari Road",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Umarkhed",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Umarsara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Umri Kap",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445106,
  },
  {
    postOffice: "Undari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Uttarwadhona",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Vasant Nagar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Velabai",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445307,
  },
  {
    postOffice: "Veni  Kotha",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Veni Kasba",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Vidul",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445206,
  },
  {
    postOffice: "Vithala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445203,
  },
  {
    postOffice: "Wadad",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445205,
  },
  {
    postOffice: "Wadgaon Gadhwe",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445201,
  },
  {
    postOffice: "Wadgaon P.S.",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Wadgaon Road",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Wadhona Bazar",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Wadki",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445308,
  },
  {
    postOffice: "Waghapur",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Waghar Takli",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Wai",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445302,
  },
  {
    postOffice: "Wani Bazar",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Wani",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Wanwarla",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445204,
  },
  {
    postOffice: "Wanzari",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Wardh",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445323,
  },
  {
    postOffice: "Warud JR",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Watfali",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445102,
  },
  {
    postOffice: "Watkhed",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445101,
  },
  {
    postOffice: "Wegaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445303,
  },
  {
    postOffice: "Yavatmal",
    Type: "H.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Yavatmal Town",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445001,
  },
  {
    postOffice: "Yawali",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },
  {
    postOffice: "Yelabara",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445301,
  },
  {
    postOffice: "Zadgaon",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445402,
  },
  {
    postOffice: "Zamkola",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445304,
  },
  {
    postOffice: "Zatala",
    Type: "B.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445306,
  },
  {
    postOffice: "Zillla Parishad  Yavatmal",
    Type: "S.O",
    District: "Yavatmal",
    State: "MAHARASHTRA",
    Pincode: 445002,
  },

  {
    postOffice: "Ac.Depot",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Adhalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Agadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Ahmednagar Camp",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Ahmednagar City",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Ahmednagar",
    Type: "H.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Ahmednagar R.S.",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Ajnuj",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Akhatwade",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Akhegaon Titarfa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Akola",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Akolner",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Alhanwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Alkuti",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414305,
  },
  {
    postOffice: "Alsunde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Amarapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Ambi Jalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Anandibazar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Apdhup",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Arangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Arangaon Dumala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Astagaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Avane BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Babhulgaon Khalsa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Babhurdi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Babulwade",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Baburdi Ghumat",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Bahirobawadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Balamtakali",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414504,
  },
  {
    postOffice: "Baradgaon Sudrik",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Bavi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Belwandi S.F.",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Belwandi",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Benwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Bhalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Bhambora",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Bhandgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Bhangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Bhatkudgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Bhatodi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414201,
  },
  {
    postOffice: "Bhavinimgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Bhawlani",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Bhingar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Bhingar Wes",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Bhorwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Bhoyare Gangarda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Bhoyare Pathar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Bhutetakali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Bitkewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Bodhegaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Brahmni",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Burhan Nagar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Chamburdi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Chanda",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414606,
  },
  {
    postOffice: "Chande BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Chandgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Chapadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Chapadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Chas",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Chichondi Patil",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414201,
  },
  {
    postOffice: "Chichondi Shiral",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Chichpur Pangul",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Chilwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Chimbhale",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Chincholi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Chincholi Kaldat",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Chinchpur Ijde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Chombhut",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414305,
  },
  {
    postOffice: "Chondi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Dahigaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Daithane Gunjal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Darewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Darodi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414305,
  },
  {
    postOffice: "Dehere",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Deodaithan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Deotakli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Deulgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Deulgaon Sidhhi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Devi Bhoyare",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Devrai",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Dhamori",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413704,
  },
  {
    postOffice: "Dhavalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Dhawalpuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Dhondpargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Dhorjalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Dhorje",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Dhotre BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Dighi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Dighol",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413204,
  },
  {
    postOffice: "Diksal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Durgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Erandoli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Fakrabad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Gaikwad Jalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414504,
  },
  {
    postOffice: "Ganjibhoyare",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Gargundi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414303,
  },
  {
    postOffice: "Ghargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Ghatshiras",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Ghodegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Ghodegaon    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414607,
  },
  {
    postOffice: "Ghogargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Ghospuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Ghotan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Ghotvi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Golegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Goregaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Gundegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414101,
  },
  {
    postOffice: "Gunore",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Gunwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Guravpimpri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Halgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Hanga",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Hangewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Hasnapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Hatgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Hingangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Hiradgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Hivare Zare",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Hiware Korda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Jalalpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Jamgaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Jamkhed",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Jatdeola",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413204,
  },
  {
    postOffice: "Jategaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Javala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Jawala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Jawkhede Khalsa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Jeur    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414601,
  },
  {
    postOffice: "Joharapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Jssk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Kalas",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414305,
  },
  {
    postOffice: "Kamargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Kambi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Kangoni",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414607,
  },
  {
    postOffice: "Kanhur",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414303,
  },
  {
    postOffice: "Kapurwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Karajgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Karandi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414303,
  },
  {
    postOffice: "Karanji",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Karjat    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Karjule Hariya",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Karjune Khare",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Kasar Pimpalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Kasba Pathardi",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Kashti",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Katrad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413704,
  },
  {
    postOffice: "Kaudgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Kaudgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Kaundane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Kauthe",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414606,
  },
  {
    postOffice: "Kauthe",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Kendal BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Khadakwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Khadamba",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413704,
  },
  {
    postOffice: "Khampimpri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Khanapur  BO",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Khandala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Khandgaon Lohsar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Khandvi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Kharadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Kharda",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413204,
  },
  {
    postOffice: "Kharwandi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414602,
  },
  {
    postOffice: "Kharwandi Kasar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Khedle Permananda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Khednagar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Khospuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Kinhi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414303,
  },
  {
    postOffice: "Kokangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Kolgaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Kolhar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Kombli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Koradgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Koregoan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Kotul",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Kuldharan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Kusadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Ladjalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Limpangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413726,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414607,
  },
  {
    postOffice: "Loni Haveli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Loni Masadpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Loni Mavala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414305,
  },
  {
    postOffice: "Loni Vyanknath",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "M.G.Udyan",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Madhe Wadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413726,
  },
  {
    postOffice: "Mahalaxmi Hiware",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Mahijalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Majle Chincholi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Maka",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Malangi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Mali Chinchore",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414602,
  },
  {
    postOffice: "Malkup",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Mandavgan",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414101,
  },
  {
    postOffice: "Mandve",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Manikdaundi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Math Pimpari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413726,
  },
  {
    postOffice: "Mehekari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Meherabad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Mhasane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Mhase",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "MIDC  ANR",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Midsangavi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Mintolines",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Mirajgaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "MIRC",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414110,
  },
  {
    postOffice: "Miri",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Moha",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Mohoj BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Mohojdeoda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Mohote",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Mungi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Munguswade",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Nagardeola",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Naigaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Nanaj    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Nandur Nimba Daitya",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Nandur Pathar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Narayan Doho",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414201,
  },
  {
    postOffice: "Narayan Gavan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Nepti",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Nibavi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Nighoj",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Nimbe Nandur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Nimblak",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Nimbodi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Nimbodi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Nimgaon Daku",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Nimgaon Gangarda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Nimgaon Khalu",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Nimgaon Wagha",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Nipani Jalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Nivdunga",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Padali Ale",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414305,
  },
  {
    postOffice: "Padali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Padali Darya",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Padali Ranjangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Pagori Pimpalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Palashi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Palaspur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Palve BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Panoli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Pargaon Sudrik",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Parner",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Parner SSK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Pategaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Patewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Pathardi",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Patoda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Pedgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Pimpalgaon Kauda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Pimpalgaon Malvi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414601,
  },
  {
    postOffice: "Pimpalgaon Pisa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Pimpalgaon Rotha",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414303,
  },
  {
    postOffice: "Pimpalgaon Ujjani",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414601,
  },
  {
    postOffice: "Pimpalgaontappa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Pimpalner",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Pimparkhed",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413205,
  },
  {
    postOffice: "Pimpri Kolandar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Pimprijalsen",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Pisorekhand",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414101,
  },
  {
    postOffice: "Pokhari Kanher",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Punewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Rajapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413204,
  },
  {
    postOffice: "Rakshi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Ralegan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414101,
  },
  {
    postOffice: "Ralegan Siddhi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Ralegan Therpal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Ranegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Ranjangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Ranjangaondevi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414602,
  },
  {
    postOffice: "Rashin",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414403,
  },
  {
    postOffice: "Rastapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414606,
  },
  {
    postOffice: "Rehekuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Rui Chhatrapati",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Ruichattisi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Sade",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413704,
  },
  {
    postOffice: "Sakat",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413201,
  },
  {
    postOffice: "Sakegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Samangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Samsud Erandgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Sandve",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414201,
  },
  {
    postOffice: "Sarjepura",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414001,
  },
  {
    postOffice: "Sarola Kasar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414005,
  },
  {
    postOffice: "Sarolabadhi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414201,
  },
  {
    postOffice: "Sarolasomvanshi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Savargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Savedi Road",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414003,
  },
  {
    postOffice: "Shahar Takali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Shani Shinganapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Shedgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Shekte",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414504,
  },
  {
    postOffice: "Shendi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414601,
  },
  {
    postOffice: "Shevgaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Shinde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Shingave Keshav",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Shingave Naik",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Shingori",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Shiradhon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Shiral",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Shirapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Shrigonda",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Shrigonda SSK",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413726,
  },
  {
    postOffice: "Singave Tukai",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414607,
  },
  {
    postOffice: "Sonai    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Sonegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413204,
  },
  {
    postOffice: "Sultanpur BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Sultanpur KHD",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Supa    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Suregaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Susare",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Tajnapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Takali Dhokeshwar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Takali Kadewalit",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Takali Kazi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414201,
  },
  {
    postOffice: "Takali Khandeshwari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414402,
  },
  {
    postOffice: "Takali Khatgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414103,
  },
  {
    postOffice: "Takali Lonar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Takali Manur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Tamaswadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414602,
  },
  {
    postOffice: "Tandali Dumala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413701,
  },
  {
    postOffice: "Telanghashi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413204,
  },
  {
    postOffice: "Thakur Pimpalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414503,
  },
  {
    postOffice: "Thergaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414401,
  },
  {
    postOffice: "Tisgaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Tondoli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Ukkadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413702,
  },
  {
    postOffice: "Ukkhalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Umbra",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414105,
  },
  {
    postOffice: "Vadalamission",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414602,
  },
  {
    postOffice: "Vadegavan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Vadgaon Gupta",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Vadgaon Tandali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Vadner BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414306,
  },
  {
    postOffice: "Vadner Haveli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Vadule BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Vadzire",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414302,
  },
  {
    postOffice: "Vagholi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414501,
  },
  {
    postOffice: "Vaghunde KH",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Vahannagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Vaiju Babhulgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414106,
  },
  {
    postOffice: "Valvane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414301,
  },
  {
    postOffice: "Vambori",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413704,
  },
  {
    postOffice: "Vangdari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413726,
  },
  {
    postOffice: "Vankute",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Varur BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414502,
  },
  {
    postOffice: "Vasunde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414304,
  },
  {
    postOffice: "Vilad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414111,
  },
  {
    postOffice: "Visapur    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413703,
  },
  {
    postOffice: "Vridheshwar SSK",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414505,
  },
  {
    postOffice: "Wadali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413728,
  },
  {
    postOffice: "Wadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414102,
  },
  {
    postOffice: "Walki",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414006,
  },
  {
    postOffice: "Watephal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414002,
  },
  {
    postOffice: "Yelapane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414701,
  },
  {
    postOffice: "Yeli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414113,
  },
  {
    postOffice: "Agasti SSK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Aklapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Akole",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Ambhaol",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Ambhore",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Ambi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413715,
  },
  {
    postOffice: "Aradgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Ashvi BK",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413714,
  },
  {
    postOffice: "Ashvi KH",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413738,
  },
  {
    postOffice: "Astgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423107,
  },
  {
    postOffice: "Babhleshwar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413737,
  },
  {
    postOffice: "Badgi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422602,
  },
  {
    postOffice: "Baragaon Nandur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Bari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Belapur Badgi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422602,
  },
  {
    postOffice: "Belapur Khd",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413715,
  },
  {
    postOffice: "Belapur",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413715,
  },
  {
    postOffice: "Belpimpalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413725,
  },
  {
    postOffice: "Bhalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Bhamathan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Bhanashivara",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414609,
  },
  {
    postOffice: "Bhandardara",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Bhende SK",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414605,
  },
  {
    postOffice: "Bherdapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413721,
  },
  {
    postOffice: "Bhojade",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413708,
  },
  {
    postOffice: "Bhojdari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422602,
  },
  {
    postOffice: "Bhokar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Bota",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422602,
  },
  {
    postOffice: "Bramhangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Bramhanwada",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Chandanapuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Chandekasare",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Changdeonagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413723,
  },
  {
    postOffice: "Chas",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Chas-nali",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423604,
  },
  {
    postOffice: "Chikalthan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Chikani",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Chikhali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Chinchale",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Chinchavane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Chincholi Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413710,
  },
  {
    postOffice: "Chincholi Gurav",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422611,
  },
  {
    postOffice: "Chinchpur BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413736,
  },
  {
    postOffice: "Chitali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413723,
  },
  {
    postOffice: "Chitalvedhe",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Dadh BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413714,
  },
  {
    postOffice: "Dahigaon Bolka",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Dedgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Deogaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Deogaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Deolali -pravara",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413716,
  },
  {
    postOffice: "Deothan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Derde Korhale",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Dhamangaon Awari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Dhamanvan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Dhamngaon Pat",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Dhamori",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423607,
  },
  {
    postOffice: "Dhandharphal",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422603,
  },
  {
    postOffice: "Dharangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Dhokari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Dhotre",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413708,
  },
  {
    postOffice: "Dolasane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Dongargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Ekdara",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Ekrukha",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413719,
  },
  {
    postOffice: "Fatyabad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413710,
  },
  {
    postOffice: "Galnimb",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413710,
  },
  {
    postOffice: "Galnimb",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Ganeshnagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Ganore",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Gardani",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Georai",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Ghargaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Ghatghar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Ghogargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413725,
  },
  {
    postOffice: "Godhegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Gogalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413736,
  },
  {
    postOffice: "Gondegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413723,
  },
  {
    postOffice: "Guha",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Harigaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413718,
  },
  {
    postOffice: "Hasanapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413736,
  },
  {
    postOffice: "Hivargaon Pathar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Hiwargaon Pawasa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "IE Shrirampur. Shrirampur.",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413709,
  },
  {
    postOffice: "Jalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413723,
  },
  {
    postOffice: "Jalke Khd",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Jambhali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Jambut",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Jawale Baleshwar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Jawale Kadlag",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Jawalke",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423605,
  },
  {
    postOffice: "Jeur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Jeur Kumbhari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Jorve",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Kalas Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422603,
  },
  {
    postOffice: "Kamalpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413725,
  },
  {
    postOffice: "Kanadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Kangar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Kanhegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413708,
  },
  {
    postOffice: "Kanoli",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Karandi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Karanji Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Karegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413717,
  },
  {
    postOffice: "Karegaon Factory",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413717,
  },
  {
    postOffice: "Karwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423604,
  },
  {
    postOffice: "Katalapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Kawathe Kamaleshwar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422611,
  },
  {
    postOffice: "Keli Kotul",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Keli Rumhanwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Kelwad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423107,
  },
  {
    postOffice: "Khandala Nandur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413720,
  },
  {
    postOffice: "Khandgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Kharshinde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413738,
  },
  {
    postOffice: "Khedle Kajali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Khirdi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413725,
  },
  {
    postOffice: "Khirvire",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Khokar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Kohane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Kokamthan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Kokangaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413714,
  },
  {
    postOffice: "Kolewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Kolgaon Thadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Kolhar Khd",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413710,
  },
  {
    postOffice: "Kolhar    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413710,
  },
  {
    postOffice: "Kolhewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Kolpewadi",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Kombhalane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Kondhwad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Kopergaon Bet",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Kopergaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Korhale",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423107,
  },
  {
    postOffice: "Kothe BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Kotul",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Kukana",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Kumbephal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Kumbhari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Kuran",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Kuthe Malkapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Lakh",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413721,
  },
  {
    postOffice: "Lavhali Otur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Laxmiwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423109,
  },
  {
    postOffice: "Lingdeo",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Lohare Mirpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413736,
  },
  {
    postOffice: "Lohgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413712,
  },
  {
    postOffice: "Loni Bk",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413736,
  },
  {
    postOffice: "Loni Kurd",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413713,
  },
  {
    postOffice: "Lonkarwasti",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Madhi BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Mahegaon Dushmukh",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Maldad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422608,
  },
  {
    postOffice: "Malegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Malegaon Thadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Malewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413718,
  },
  {
    postOffice: "Malunja",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Malunja BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413721,
  },
  {
    postOffice: "Malunja Khd",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413721,
  },
  {
    postOffice: "Malvadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Mamdapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413737,
  },
  {
    postOffice: "Mandve Bk.",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Mandve Khd",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Manhere",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Manjari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Manjur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423604,
  },
  {
    postOffice: "Manori",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Matapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413717,
  },
  {
    postOffice: "Maveshi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Maygaon Devi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423607,
  },
  {
    postOffice: "Mhaisgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Mukindpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Mulanagar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413722,
  },
  {
    postOffice: "Murme  Deogad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Murshatpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Musalwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413716,
  },
  {
    postOffice: "Muthalane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Muthkhel",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Nanaj Dumala",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422611,
  },
  {
    postOffice: "Nandur Khandarmal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Nanduri Dumala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422603,
  },
  {
    postOffice: "Narsali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413715,
  },
  {
    postOffice: "Naur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413707,
  },
  {
    postOffice: "Nevasa",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Newasa Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Nilwande",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Nimaj",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Nimbhere",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413711,
  },
  {
    postOffice: "Nimgaon Bhojapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Nimgaon Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422603,
  },
  {
    postOffice: "Nimgaon Jali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413714,
  },
  {
    postOffice: "Nimgaon Khairi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413723,
  },
  {
    postOffice: "Nimgaon Tembhi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Nimon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422611,
  },
  {
    postOffice: "Nipani Vadgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Pachegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413725,
  },
  {
    postOffice: "Padalane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Padhegaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Padhegaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413721,
  },
  {
    postOffice: "Padoshi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Palsunde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Pangari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Panwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413738,
  },
  {
    postOffice: "Paregaon BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422611,
  },
  {
    postOffice: "Pathare Khd",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413721,
  },
  {
    postOffice: "Pathre Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413711,
  },
  {
    postOffice: "Pemgiri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422603,
  },
  {
    postOffice: "Pimpalgaon Depa",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Pimpalgaon Khad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Pimpalgaon Matha",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Pimpalgaon Nakvinda",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Pimpalgaon Nipani",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Pimparane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Pimparkane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Pimplwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423109,
  },
  {
    postOffice: "Pimpri Lauki",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413738,
  },
  {
    postOffice: "Pimpri Nirmal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423107,
  },
  {
    postOffice: "Pohegaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423605,
  },
  {
    postOffice: "Pravaranagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413712,
  },
  {
    postOffice: "Pun Tamba",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413707,
  },
  {
    postOffice: "Rahata",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423107,
  },
  {
    postOffice: "Rahuri Factory",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Rahuri MPKV",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413722,
  },
  {
    postOffice: "Rahuri",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Rahuri Town",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Rajapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Rajur",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Rajuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413737,
  },
  {
    postOffice: "Ramdoh",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Rampur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413710,
  },
  {
    postOffice: "Ranad Bk",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Ranjangaon Deshmukh",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423605,
  },
  {
    postOffice: "Ranjangaon Khurd",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413719,
  },
  {
    postOffice: "Ravande",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Rui",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423109,
  },
  {
    postOffice: "Rumbhodi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Sakarwadi",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413708,
  },
  {
    postOffice: "Sakirwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Sakur",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422622,
  },
  {
    postOffice: "Sakuri",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423107,
  },
  {
    postOffice: "Salabatpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Samesherpur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Samnapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Samvatsar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Sangamner S.K.",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422608,
  },
  {
    postOffice: "Sangamner",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Sangvi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Sangvi Bhusar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Sarole Pathar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Satewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Savali Vihir BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423109,
  },
  {
    postOffice: "Savali Vihir Farm",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423109,
  },
  {
    postOffice: "Savargaon Ghule",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Savargaon Pat",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Savargaon Tal",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Shedgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413738,
  },
  {
    postOffice: "Shenti",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Shinganapur",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Shingave",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413708,
  },
  {
    postOffice: "Shirasgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423603,
  },
  {
    postOffice: "Shirasgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Shirasgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Shirdi",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423109,
  },
  {
    postOffice: "Shrirampur",
    Type: "H.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413709,
  },
  {
    postOffice: "Sonewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423605,
  },
  {
    postOffice: "Songaon",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413711,
  },
  {
    postOffice: "Subhashwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413720,
  },
  {
    postOffice: "Sukewadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Suregaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Tahakari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Taharabad",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Takali",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Takalibhan",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413725,
  },
  {
    postOffice: "Taklimiya",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413716,
  },
  {
    postOffice: "Talegaon Dighe",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422611,
  },
  {
    postOffice: "Tambhere",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Tambhol",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Tandulwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Tarwadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Telkudgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Thugaon BK",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Tilaknagar    Ahmed Nagar",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413720,
  },
  {
    postOffice: "Tirdhe",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422601,
  },
  {
    postOffice: "Tisgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413712,
  },
  {
    postOffice: "Toka",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Udadawane",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Ukkalgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413715,
  },
  {
    postOffice: "Umbargaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413715,
  },
  {
    postOffice: "Undirgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413718,
  },
  {
    postOffice: "Usthal Dumala",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414609,
  },
  {
    postOffice: "Vadala Mahadeo",
    Type: "S.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413739,
  },
  {
    postOffice: "Vadgaon Landaga",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422603,
  },
  {
    postOffice: "Vadgaon Pan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Valan",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Varkhed",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414603,
  },
  {
    postOffice: "Varshinde",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413706,
  },
  {
    postOffice: "Varudi Pathar",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422620,
  },
  {
    postOffice: "Vavrath",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413705,
  },
  {
    postOffice: "Velapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423602,
  },
  {
    postOffice: "Virgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Vithe",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Waghapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422610,
  },
  {
    postOffice: "Waghapur",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422605,
  },
  {
    postOffice: "Wakadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 414604,
  },
  {
    postOffice: "Wakadi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413719,
  },
  {
    postOffice: "Waki",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Waranghushi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 422604,
  },
  {
    postOffice: "Wari",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413708,
  },
  {
    postOffice: "Warwandi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413738,
  },
  {
    postOffice: "Yesgaon",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 423601,
  },
  {
    postOffice: "Zarekathi",
    Type: "B.O",
    District: "Ahmed Nagar",
    State: "MAHARASHTRA",
    Pincode: 413714,
  },
];

module.exports = {
  maharashtraCityState,
};
