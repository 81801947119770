// import React from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ResponsiveContainer,
// } from "recharts";
// import Calender from "./../Calender/Calender";
// import { useState, useEffect } from "react";
// import styles from "./Charts.module.css";
// import Datepicker from "../DatePicker/MaterialDatePicker";

// const data = [
//   {
//     name: "Jan",
//     uv: 10000,
//     pv: 2400,
//     amt: 10000,
//   },
//   {
//     name: "Feb",
//     uv: 8000,
//     pv: 1398,
//     amt: 2000,
//   },
//   {
//     name: "Mar",
//     uv: 2000,
//     pv: 9800,
//     amt: 5000,
//   },
//   {
//     name: "Apr",
//     uv: 1000,
//     pv: 3908,
//     amt: 6000,
//   },
//   {
//     name: "May",
//     uv: 5000,
//     pv: 4800,
//     amt: 2000,
//   },
//   {
//     name: "Jun",
//     uv: 2000,
//     pv: 3800,
//     amt: 9000,
//   },
//   {
//     name: "Jul",
//     uv: 6000,
//     pv: 4300,
//     amt: 8000,
//   },
//   {
//     name: "Aug",
//     uv: 2000,
//     pv: 4300,
//     amt: 7000,
//   },
//   {
//     name: "Sep",
//     uv: 3000,
//     pv: 4300,
//     amt: 2000,
//   },
//   {
//     name: "Oct",
//     uv: 9000,
//     pv: 4300,
//     amt: 2100,
//   },
//   {
//     name: "Nov",
//     uv: 4000,
//     pv: 4300,
//     amt: 2100,
//   },
//   {
//     name: "Dec",
//     uv: 5000,
//     pv: 4300,
//     amt: 2100,
//   },
// ];
// const monthArr = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec",
// ];
// // console.log(Object.keys(data));
// const Charts = (props) => {
//   const { chartData } = props;
//   const [finalData, setFinalData] = useState([]);
//   const [openCalender, serOpenCalender] = useState(false);

//   useEffect(() => {
//     setFinalData(
//       chartData
//         ? chartData.map((ele) => {
//           return { ...ele, ["mon"]: monthArr[ele._id.month - 1] };
//         })
//         : []
//     );
//   }, [chartData]);

//   // console.log(finalData);
//   const togglerCalender = () => {
//     serOpenCalender(!openCalender)
//   }

//   return (
//     <>
//       <p className={styles.chart_heading}>{props.text}</p>

//       <div style={{ border: "1px solid #F2F2F2", borderRadius: "5px", padding: "15px" }}>
//         <div>
//           <div onClick={togglerCalender}>
//             <Calender />
//           </div>
//           <div>{openCalender ? <Datepicker style={{height: "10px"}} /> : ""}</div>
//         </div>
//         <div>
//           <ResponsiveContainer width="95%" height={300}>
//             <BarChart data={finalData}>
//               <XAxis dataKey="mon" stroke="#7B7B7B" fontSize="12.5px" />
//               <YAxis />
//               {/* <Tooltip /> */}
//               <Bar dataKey="sum" fill="#0092E8" barSize={30} />
//             </BarChart>
//           </ResponsiveContainer>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Charts;

import React from "react";
import StackedBarChart from "./LineCharts";
// import { CubeProvider } from "@cubejs-client/react";
// import cubejs from "@cubejs-client/core";
import styles from "./Charts.module.css";

// const cubejsApi = cubejs(
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1OTQ2NjY4OTR9.0fdi5cuDZ2t3OSrPOMoc3B1_pwhnWj4ZmM3FHEX7Aus",
//   {
//     apiUrl:
//       "https://awesome-ecom.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1",
//   }
// );

const Charts = (props) => {
  return (
    <>
      <p className={styles.chart_heading}>{props.text}</p>
      {/* <CubeProvider cubejsApi={cubejsApi}> */}
      <StackedBarChart chartData={props.chartData} />
      {/* </CubeProvider> */}
    </>
  );
};

export default Charts;
