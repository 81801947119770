import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InActiveTextboxWithLabel from "../bulkShareComfirmHelper/inActiveTextboxWithLabel.js";
import InActiveTextboxWithOutValue from "../bulkShareComfirmHelper/inActiveTextboxWithoutValue.js";
import MemberInfoForBulkShareConfirm from "../bulkShareComfirmHelper/memberInfoForBulkShareConfirm.js";
import { useEffect, useState } from "react";
import {
  getDashboardTransfer,
  getPieChartDataActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../../Redux/Dashboard/dashboardActionCreator.js";
import { openSnackbar } from "../../../Redux/Snackbar/snackbarStore.js";
import { loaderEndActionCreater } from "../../../Redux/Loader/LoaderActionCreator.js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator.js";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { selectedMemberRows } from "../../../Redux/Members/actionCreator.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1000,
  width: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BulkIssueShareConfirmationModel(props) {
  const {
    openConfirm,
    setOpenConfirm,
    tableData, //memberData
    memberIds, // selected member List
    shareType, /// selected share type
    shareSeriesId, // selected share type series Id
    shareSeriesIds, // all series Id with share Type
    minValue, // minimum value from form
    maxValue, // maximum value from form
    getAllsharesDetails,
    setUpdate,
    setConflict2,
    handleClose2,
    setIsAction,
    setOpen,
  } = props;
  const handleOpen = () => setOpenConfirm(true);
  const handleClose = () => {
    setOpenConfirm(false);
    // if (setIsAction) setIsAction(true);
  };
  const [data, setData] = useState([]);
  const [seriesData, setSeriesData] = useState(null);
  const [totalNumberOfShare, setTotalNumberOfShare] = useState("");
  const [error, setError] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  // console.log("in confirmation!!", {
  //   memberIds,
  // });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const addIssueBulkShare = async () => {
    try {
      let dataVal = {
        memberIds: data.filter((ele) => {
          return parseInt(ele.numberOfShare) > 0;
        }),
        seriesData,
      };
      // console.log("Bulk Share Issue Final Data:", dataVal);
      ////checktoken

      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/issueBulkShares",
        dataVal,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // let response = await axios.post(
      //   "http://localhost:8080" + "/api/v1/member/" + `${type}`,
      //   val
      // );

      dispatch(getPieChartDataActionCreater(getAuthData));
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      getAllsharesDetails(getAuthData);
      dispatch(openSnackbar(`Bulk Shares Issued Successfully`, "success"));
      dispatch(loaderEndActionCreater());
      if (setUpdate) setUpdate(true);
      if (setConflict2 || !setConflict2) setConflict2(true);
      handleClose();
      handleClose2();
      dispatch(selectedMemberRows(undefined, []));

      history.push("/admin/members");
      // if (setIsAction) setIsAction(true);
      // handleClose();
    } catch (e) {
      // console.log(e);
      history.push("/admin/members");
      dispatch(selectedMemberRows(undefined, []));

      // if (setIsAction) setIsAction(true);
      dispatch(loaderEndActionCreater());
      // if (e?.response?.data?.error)
      //   dispatch(openSnackbar(`${e?.response?.data?.error}`, "error"));
      if (e?.response?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    if (shareSeriesId !== "" && shareSeriesIds) {
      let shareTypeData = shareSeriesIds[`${shareType}`];
      if (shareTypeData?.length > 0) {
        let seriesDataLocal = shareTypeData.find((ele) => {
          return ele.shareCapitalId === shareSeriesId;
        });
        setSeriesData(seriesDataLocal);
      }
    }
  }, [shareSeriesIds, shareSeriesId]);

  useEffect(() => {
    let finalList = [];
    let memberFinalList = [];
    // if (seriesData === null || !minValue || !maxValue) return;
    let pricePerShare = seriesData?.pricePerShare;
    if (memberIds) {
      finalList = memberIds.filter((ele) => {
        let member = tableData.find((e) => {
          return e._id === ele;
        });
        return member?.activityStatus === true;
      });
    }

    if (finalList.length > 0) {
      memberFinalList = finalList.map((ele) => {
        let member = tableData.find((e) => {
          return e._id === ele;
        });
        let numberOfShare = 0;

        if (pricePerShare <= 0) {
          numberOfShare = 0;
        } else {
          numberOfShare = parseInt(
            member?.shareApplicationMoney / pricePerShare
          );
        }

        if (numberOfShare < minValue) {
          numberOfShare = 0;
        } else if (numberOfShare > maxValue) {
          numberOfShare = maxValue;
        }

        return {
          id: member._id,
          memberId: member.memberId,
          firstName: member.firstName,
          midleName: member.midleName,
          lastName: member.lastName,
          shareApplicationMoney: member.shareApplicationMoney,
          numberOfShare: `${numberOfShare}` || "0",
          error: false,
        };
      });
    }
    setData(memberFinalList);
    dispatch(loaderEndActionCreater());
    // console.log("finalList", finalList);
  }, [tableData, seriesData]);

  useEffect(() => {
    let ele = data.find((ele) => ele.error === true);

    if (
      ele ||
      seriesData?.balanceShare <= 0 ||
      totalNumberOfShare > seriesData?.balanceShare
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [data, totalNumberOfShare, seriesData]);

  const OnDelete = (id) => {
    // console.log("onDelete",{data,id})
    if (data?.length > 1) {
      setData(data?.filter((ele) => ele.id !== id));
    } else {
      dispatch(
        openSnackbar("We Can't Delete All Member From the List", "error")
      );
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      let total = 0;
      data.map((ele) => {
        total += parseInt(ele?.numberOfShare || 0);
      });
      setTotalNumberOfShare(total);
    }
  }, [data]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={openConfirm}
        // open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
            //   onClick={handleClose}
            //   style={{
            //     cursor: "pointer",
            //     fontWeight: "bold",
            //   }}
            >
              {/* <KeyboardBackspaceIcon /> */}
            </div>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                fontWeight: "bold",
                fontSize: "1.6rem",
                color: "grey",
                // background : "grey",
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Confirmation Window
            </Typography>
            <div
              onClick={() => {
                // handleClose();
                // if (setConflict2 || !setConflict2) setConflict2(true);
                handleClose2();
                dispatch(selectedMemberRows(undefined, []));

                history.push("/admin/members");
                // if (setIsAction) setIsAction(true);
              }}
              style={{
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, mb: 2, display: "flex", flexDirection: "row" }}
          >
            <InActiveTextboxWithLabel
              error={false}
              label="Share Type"
              value={shareType}
              //   value={"Common"}
            />
            <InActiveTextboxWithLabel
              error={false}
              label="Series Id"
              value={shareSeriesId}
              //   value={"SC0000032"}
            />
            <InActiveTextboxWithLabel
              error={false}
              label="Minimum Shares"
              value={minValue}
              //   value={"SC0000032"}
            />
            <InActiveTextboxWithLabel
              error={false}
              label="Maximum Shares"
              value={maxValue}
              //   value={"SC0000032"}
            />
            <InActiveTextboxWithLabel
              // error={true}
              label="Balance Shares"
              totalNumberOfShare={totalNumberOfShare}
              error={
                seriesData?.balanceShare <= 0 ||
                seriesData?.balanceShare < totalNumberOfShare
              }
              //   value={
              //     shareSeriesIds[shareType]
              //       ? shareSeriesIds[shareType][shareSeriesId]?.balanceShare
              //       : ""
              //   }
              value={seriesData?.balanceShare || 0}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <InActiveTextboxWithOutValue label="Membership Id" />
              <InActiveTextboxWithOutValue label="First Name" />
              <InActiveTextboxWithOutValue label="Last Name" />
              <InActiveTextboxWithOutValue label="No. of Shares" />
              <div
                style={{
                  width: "10ch",
                }}
              ></div>
            </div>
            <div
              style={{
                width: "100%",
                minHeight: "30vh",
                maxHeight: "60vh",
                overflow: "auto",
              }}
            >
              {data?.map((ele) => {
                return (
                  <MemberInfoForBulkShareConfirm
                    memberShipID={ele.memberId}
                    firstName={ele.firstName}
                    lastName={ele.lastName}
                    noOfShare={ele.numberOfShare}
                    error={ele.error}
                    id={ele.id}
                    OnDelete={OnDelete}
                    data={data}
                    setData={setData}
                    minValue={minValue}
                    maxValue={maxValue}
                    pricePerShare={seriesData?.pricePerShare}
                    shareApplicationMoney={ele.shareApplicationMoney}
                  />
                );
              })}
            </div>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: "12px",
              }}
            >
              <Button
                variant="contained"
                disabled={error}
                sx={{
                  mr: "2rem",
                  fontSize: "15px",
                  fontWeight: "bold",
                  bgcolor: "#0096FF",
                }}
                onClick={() => {
                  addIssueBulkShare();
                }}
              >
                Confirm
              </Button>
              <Button
                variant="text"
                onClick={handleClose}
                sx={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                Back
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "80%",
                // marginLeft: "5rem",
              }}
            >
              <label
                style={{
                  marginBottom: "-2px",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Total Shares
              </label>
              <div
                style={{
                  background: "#e3e3e3",
                  marginLeft: "1rem",
                  padding: "4px 7rem",
                  color: "black",
                }}
              >
                {totalNumberOfShare}
              </div>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
