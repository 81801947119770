const actions = {
  getConfigurationAction: (data) => {
    // console.log("2. addConfigurationAction", data);
    return {
      type: "get",
      data,
    };
  },

  addConfigurationAction: (data) => {
    // console.log("2. addConfigurationAction", data);
    return {
      type: "add",
      data,
    };
  },
  updateConfigurationAction: (id, data) => {
    // console.log("2. updateConfigurationAction", id, data);
    return {
      type: "update",
      data,
      id,
    };
  },
  deleteConfigurationAction: (id, data) => {
    // console.log("2. deleteConfigurationAction", id, data);
    return {
      type: "delete",
      data,
      id,
    };
  },

  // updateFormData: (data) => {
  //   // console.log("2 updateFormData Action",data);
  //   return {
  //     type: UPDATE_DATA,
  //     data,
  //   };
  // },

  // updateTableRowData: (data) => {
  //   console.log("2 updateTableRowData Action",data);
  //   return {
  //     type: UPDATE_TABLE_ROW_DATA,
  //     data,
  //   };
  // },
};

export default actions;
