import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Dashboard from "../Views/Dashboard/Dashboard";
import { ShareCapital } from "../Views/ShareCapital/ShareCapital";
import { User } from "../Views/Users/Users";
import MembersHome from "../Views/Members/Members";
import DataAcquisition from "../Views/DataAcquisition/DataAcquisition";
import Reports from "../Views/Report/Report";
import Support from "../Views/Support/Support";
import MembersInfo from "../Views/Members/MembersInfo";
import UsersInfo from "../Views/Users/UsersInfo";
import { ShareCapitalAddForm } from "../Views/ShareCapital/ShareCapitalAddUpdateForm";
import { Communication } from "../Views/Communication/Communication";
import Configuration from "../Views/Configuration/Configuration";
import { ConfigurationUploadTemplate } from "../Views/Configuration/ConfigurationUploadTemplate";
import DataAcquisitionForms from "../Views/DataAcquisition/DataAcquisitionForms";
import { MemberSearchForm } from "../Views/Members/MemberSearchForm";
import { ReportSearchForm } from "../Views/Report/ReportSearchForm";
import { DataAcquisyionSearchForm } from "../Views/DataAcquisition/DataAcquisyionSearchForm";
import { SupportSearchForm } from "../Views/Support/SupportSearchForm";
import { ConfigurationSearchForm } from "../Views/Configuration/ConfigurationSearchForm";
import { ShareCapitalSearchForm } from "../Views/ShareCapital/ShareCapitalSearchForm";
import { UserSearchForm } from "../Views/Users/UserSearchForm";
import { CommunicationForm } from "../Views/Communication/CommunicationForm";
import { NotificationForm } from "../Views/Communication/NotificationForm";
import { ShareIssuance } from "../Views/Report/ShareIssuance/ShareIssuance";
import { ShareApplicationMoney } from "../Views/Report/ShareApplicationMoney/ShareApplicationMoney";
import { ShareTransfer } from "../Views/Report/ShareTransfer/ShareTransfer";
import { ShareholdingDistribution } from "../Views/Report/ShareholdingDistribution/ShareholdingDistribution";
import { ActivityLogs } from "../Views/Report/ActivityLogs/ActivityLogs";
import CreateUserForm from "../Views/Users/UsersForm/CreateUserForm";
import CreateMemberForm from "../Views/Members/MembersForm/CreateMemberForm";
import { ProgressBar } from "../Components/GeneralComponents/ProgressBar";
import Backdrop from "@mui/material/Backdrop";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DataAcquisitionInfo from "./../Views/DataAcquisition/DataAcquisitionInfo";
import UpdateMainForm from "./../Views/DataAcquisition/UpdateForm/UpdateMainForm";
import { getConfiguationActionCreater } from "../Redux/Configuration/configurationActionCreator";
import { getSayasAccountActionCreater } from "../Redux/SayasAccount/sayasAccountActionCreator";
import { LogoutSayasActionCreate } from "../Redux/Authontication/authonticationActionCreator";
import {
  getDashboardIssuance,
  getDashboardTransfer,
  getPricePerShareActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../Redux/Dashboard/dashboardActionCreator";
import { NewMemberExport } from "../Views/memberExport/NewMemberExport";

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  const [isProgress, setIsProgress] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [memberDataLength, setMemberDataLength] = useState(0);
  const [dataAcqLength, setDataAcqLength] = useState(0);
  const dispatch = useDispatch();
  const progressStatus = useSelector((state) => {
    return state.loader.status;
  });

  useEffect(() => {
    setIsProgress(progressStatus);
  }, [progressStatus]);
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  // useEffect(() => {
  //   dispatch(getConfiguationActionCreater(getAuthData));
  //   dispatch(getSayasAccountActionCreater(getAuthData));
  //   dispatch(getDashboardTransfer(getAuthData));
  //   dispatch(getTop10ShareHolders(getAuthData));
  //   dispatch(getTotalShareHoldersMembers(getAuthData));
  // }, []);

  const [id, setId] = useState("");

  return (
    <>
      {isProgress ? (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isProgress}
          >
            <ProgressBar />
          </Backdrop>
        </>
      ) : (
        ""
      )}

      <Switch>
        <Route exact path={`${path}/`}>
          <Redirect to={`${path}/dashboard`} />
        </Route>
        <Route exact path={`${path}/dashboard`}>
          <Dashboard />

          {/* <User/> */}
        </Route>
        <Route exact path={`${path}/share-capital`}>
          <ShareCapital />
          {/* <User/> */}
        </Route>
        <Route exact path={`${path}/users`}>
          <User
            setDataLength={setDataLength}
            dataLength={dataLength}
            setId={setId}
          />
        </Route>
        <Route exact path={`${path}/members`}>
          <MembersHome
            setMemberDataLength={setMemberDataLength}
            memberDataLength={memberDataLength}
            setId={setId}
          />
        </Route>
        <Route exact path={`${path}/data-acquisition`}>
          <DataAcquisition
            dataAcqLength={dataAcqLength}
            setDataAcqLength={setDataAcqLength}
            setId={setId}
          />
        </Route>
        <Route exact path={`${path}/reports`}>
          {/* <Reports /> */}
          <Redirect to={`${path}/reports/share-issuance`} />
        </Route>
        <Route exact path={`${path}/support`}>
          <Support />
        </Route>

        {/* communication Route */}
        <Route exact path={`${path}/communication`}>
          <Communication />
          {/* <ShareCapitalAddForm />   */}
          {/* <ShareCapitalSearchForm/> */}
          {/* <MemberSearchForm/> */}
          {/* <UserSearchForm/> */}
          {/* <ReportSearchForm/> */}
          {/* <ConfigurationSearchForm/> */}
          {/* <DataAcquisyionSearchForm/> */}
          {/* <SupportSearchForm/> */}
          {/* <CommunicationForm/> */}
          {/* <NotificationForm/> */}
        </Route>

        {/* Members route  */}
        <Route exact path={`${path}/members/add-member`}>
          {/* <MembersAddForm /> */}
          <CreateMemberForm memberDataLength={memberDataLength} />
        </Route>
        <Route exact path={`${path}/members/member-info/:id`}>
          <MembersInfo id={id} setId={setId} />
        </Route>

        {/* Users route  */}
        <Route exact path={`${path}/users/add-user`}>
          {/* <CreateUser /> */}
          <CreateUserForm dataLength={dataLength} />
        </Route>
        <Route exact path={`${path}/users/user-info/:id`}>
          <UsersInfo id={id} setId={setId} />
        </Route>

        <Route exact path={`${path}/configuration`}>
          <Configuration />
        </Route>
        <Route exact path={`${path}/configuration/upload-template`}>
          <ConfigurationUploadTemplate />
        </Route>

        {/* Data Acquisition  */}
        <Route exact path={`${path}/data-acquisition/add-form/:id`}>
          <DataAcquisitionForms dataAcqLength={dataAcqLength} />
        </Route>
        <Route exact path={`${path}/data-acquisition/dataAcq-info`}>
          <DataAcquisitionInfo
            setDataAcqLength={setDataAcqLength}
            dataAcqLength={dataAcqLength}
            setId={setId}
            id={id}
          />
        </Route>
        <Route exact path={`${path}/data-acquisition/update-form/:id`}>
          <UpdateMainForm
            dataAcqLength={dataAcqLength}
            setDataAcqLength={setDataAcqLength}
            setId={setId}
            id={id}
          />
        </Route>

        {/* Reports Route */}
        <Route exact path={`${path}/reports/share-issuance`}>
          <ShareIssuance />
        </Route>
        <Route exact path={`${path}/reports/share-application-money`}>
          <ShareApplicationMoney />
        </Route>
        <Route exact path={`${path}/reports/share-transfer`}>
          <ShareTransfer />
        </Route>
        <Route exact path={`${path}/reports/shareholding-distribution`}>
          <ShareholdingDistribution />
        </Route>
        {/* <Route exact path={`${path}/reports/activity-logs`}>
          <ActivityLogs />
        </Route> */}
        <Route exact path={`${path}/newMemberExport`}>
          <NewMemberExport />
        </Route>
      </Switch>
    </>
  );
};

export default DashboardRoutes;
