import React, { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import styles from "./DataAcq.module.css";
// import MinMaxDateRangePicker from "./../../Components/DatePicker/MaterialDatePicker";
import DatePickers from "./DatePickers";
import { GeneralInputField } from "./../../Components/GeneralComponents/GeneralInputField";
import Image from "../../Views/Configuration/pinDocs.png";
import { handleUpload } from "../../Components/GeneralComponents/GeneralFileUpload";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch } from "react-redux";

const TitleForm = (props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setAbout,
    about,
    setTitle,
    title,
    applicationTitle,
    currentRowData,
    attachmentUrl,
    setAttachmentUrl,
    setIsValid,
    userRole,
    isUpdate,
  } = props;
  // console.log("About", about);
  // const [value, setValue] = useState("");
  const [desc, setDesc] = useState("");
  const [abt, setAbt] = useState("");
  // const [applicationTitle, setApplicationTitle] = useState("");
  const dispatch = useDispatch();

  const handleTitle = (e) => {
    setTitle(e.target.value);
    setAbout({ ...about, title: e.target.value });
  };
  const [uploadFile, setUploadFile] = useState("");

  useEffect(() => {
    if (about) {
      // setAttachmentUrl(about.title);
      // setValue(about.title);
      setDesc(about.description);
      setAbt(about.about);
    }
  }, [about]);

  useEffect(() => {
    if (attachmentUrl)
      setUploadFile(attachmentUrl?.split("/")[3]?.substring(13));
  }, [attachmentUrl]);

  // console.log(attachmentUrl);

  return (
    <div className={styles.back} style={{ borderTop: "15px solid #0096FF" }}>
      <div className={styles.content}>
        <Grid
          container
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            position: "relative",
          }}
          className="mt-3"
        >
          <Grid
            xs={12}
            // className="communicationFileName"
            sx={{
              overflow: "auto",
              p: "0px 0px",
              fontWeight: "bold",
            }}
          >
            <TextField
              id={styles.title}
              style={{ fontSize: "40px" }}
              variant="standard"
              className="w-100"
              disabled={
                isUpdate === true && userRole.toLowerCase() === "staff"
                  ? true
                  : false
              }
              onChange={handleTitle}
              placeholder={`${title || ""} *`}
              value={applicationTitle || ""}
              required
            />
          </Grid>
          {/* uncomment when required */}
          {/* {currentRowData.length === 0 ? ( */}
          <Grid
            xs={2}
            style={{
              position: "absolute",
              // zIndex: "1",
              right: "10px",
              bottom: "10px",
              cursor: "pointer",
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "3rem",
              width: "1rem",
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              // accept="* / *"
              disabled={
                isUpdate === true && userRole.toLowerCase() === "staff"
                  ? true
                  : false
              }
              style={{
                opacity: 0,
                position: "absolute",
                cursor: "pointer",
                width: "11px",
                pointerEvents: "auto",
                zIndex: 1,
                fontSize: 0,
                height: "22px",
                textIndent: "-100px",
              }}
              onChange={async (e) => {
                // setUploadFile(e.target.files[0].name);
                dispatch(loaderStartActionCreater());
                let fileURL = await handleUpload(e.target.files);
                setAttachmentUrl(fileURL);
                dispatch(loaderEndActionCreater());
              }}
              id="contained-button-file"
            />
            <img
              style={{ cursor: "pointer" }}
              src={Image}
              srcSet={Image}
              alt="pin"
              loading="lazy"
            />
          </Grid>
          {/* ) : (
            ""
          )} */}
          {/* uncomment when required */}
        </Grid>
        {/* <TextField
          id={styles.title}
          style={{ fontSize: "40px" }}
          variant="standard"
          className="w-100 mb-2"
          placeholder={props.title}
          onChange={(e) => {
            setValue(e.target.value);
            let newVal = about;
            newVal[`title`] = e.target.value;
            setAbout(newVal);
          }}
          value={value}
          
        /> */}

        {uploadFile !== "" ? (
          <>
            <a
              href={`${attachmentUrl}`}
              target={"_blank"}
              style={{
                textAlign: "left",
              }}
            >{`${uploadFile}`}</a>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
           
              <img src={attachmentUrl} alt="Uploaded Data..." width="50%" />
            </div> */}
            {/* <video width="320" height="240" controls>
              <source src={attachmentUrl} type="video/mp4" />
              <source src={attachmentUrl} type="video/ogg" />
              Your browser does not support the video tag.
            </video> */}
          </>
        ) : (
          ""
        )}
        {uploadFile !== "" &&
        (attachmentUrl
          ?.split(".")
          [attachmentUrl.split(".").length - 1].toLowerCase() === "png" ||
          attachmentUrl
            ?.split(".")
            [attachmentUrl.split(".").length - 1].toLowerCase() === "jpg" ||
          attachmentUrl
            ?.split(".")
            [attachmentUrl.split(".").length - 1].toLowerCase() === "jpeg") ? (
          <>
            {/* <a
              href={`${attachmentUrl}`}
              target={"_blank"}
              style={{
                textAlign: "left",
              }}
            >{`${uploadFile}`}</a> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {/* {{
                let attachment = attachmentUrl.split(",").length;

              }} */}
              <img src={attachmentUrl} alt="Uploaded Data..." width="50%" />
            </div>
            {/* <video width="320" height="240" controls>
              <source src={attachmentUrl} type="video/mp4" />
              <source src={attachmentUrl} type="video/ogg" />
              Your browser does not support the video tag.
            </video> */}
          </>
        ) : (
          ""
        )}

        <TextField
          id={styles.description}
          variant="standard"
          className="w-100 mt-3"
          placeholder="Description"
          disabled={
            isUpdate === true && userRole.toLowerCase() === "staff"
              ? true
              : false
          }
          onChange={(e) => {
            setDesc(e.target.value);
            let newVal = about;
            newVal[`description`] = e.target.value;
            setAbout(newVal);
          }}
          value={desc}
        />
        <TextField
          id={styles.description}
          variant="standard"
          className="w-100 mt-3"
          placeholder="About"
          disabled={
            isUpdate === true && userRole.toLowerCase() === "staff"
              ? true
              : false
          }
          onChange={(e) => {
            setAbt(e.target.value);
            let newVal = about;
            newVal[`about`] = e.target.value;

            setAbout(newVal);
          }}
          value={abt}
        />
        <div
          className="mt-4 mb-0 d-flex justify-content-start"
          style={{ gap: "30px" }}
        >
          <div>
            <div align="left" style={{ marginBottom: "-20px" }}>
              <label>Start</label>
            </div>
            <DatePickers
              setStartDate={setStartDate}
              startDate={startDate}
              type="start"
              name="update"
              setIsValid={setIsValid}
              disabled={
                isUpdate === true && userRole.toLowerCase() === "staff"
                  ? true
                  : false
              }

              // isValidStart={isValidStart}
              // setIsValidStart={setIsValidStart}
            />
          </div>
          <div>
            <div align="left" style={{ marginBottom: "-20px" }}>
              <label>Close</label>
            </div>
            <DatePickers
              setEndDate={setEndDate}
              start={startDate}
              endDate={endDate}
              disabled={
                isUpdate === true && userRole.toLowerCase() === "staff"
                  ? true
                  : false
              }
              type="close"
              name="update"
              setIsValid={setIsValid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleForm;
