import actions from "./UserAction";
// import axios from "axios";
// import { useDispatch } from "react-redux";
const { createUserAction, selectedUserAction,usersVisibiltyAction } = actions;

const userActionCreator = (formData) => {
  // console.log("1", formData);
  return async (dispatch) => {
    try {
      dispatch(createUserAction(formData));
    } catch (err) {
      console.log(err);
    }
  };
};

const selectedUserRows = (selectedData) => {
  // console.log("1", selectedData);
  return async (dispatch) => {
    try {
      dispatch(selectedUserAction(selectedData));
    } catch (err) {
      console.log(err);
    }
  };
};

const usrsVisibiltyActionCreater = (data) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    try {
      dispatch(usersVisibiltyAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export { userActionCreator, selectedUserRows ,usrsVisibiltyActionCreater};
