const actions = {
  DATA_ACQ_CREATE: "DATA_ACQ_CREATE",
  SELECTED_DATA_ACQ: "SELECTED_DATA_ACQ",

  createDataAcqAction: (data) => {
    // console.log("check", data);
    return {
      type: actions.DATA_ACQ_CREATE,
      data,
    };
  },
  selectedDataAcqAction: (data) => {
    // console.log("check", data);
    return {
      type: actions.SELECTED_DATA_ACQ,
      data,
    };
  },
  DAQVisibiltyAction : (data) => {
    // console.log("memberDataAction", data);
    return {
      type: "COLUMNSTATUS",
      data,
    };
  },
};

export default actions;
