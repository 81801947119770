const actions = {

  getDashboardTransferAction: (data) => {
    // console.log("2. getDashboardTransferAction", data);
    return {
      type: "getDashTransfer",
      data: data,
    };
  },

  getPricePerShareAction: (data) => {
    // console.log("2. getDashboardTransferAction", data);
    return {
      type: "getPricePerShare",
      data: data,
    };
  },

  getTop10ShareHoldersAction: (data) => {
    // console.log("2. getTop10ShareHoldersAction", data);
    return {
      type: "getTopShareHolders",
      data: data,
    };
  },

  getTotalShareHoldersMembersAction: (data) => {
    // console.log("2. getTop10ShareHoldersAction", data);
    return {
      type: "getTotalShareHolders",
      data: data,
    };
  },
  getPieChartDataAction: (data) => {
    // console.log("2. getTop10ShareHoldersAction", data);
    return {
      type: "getPieCharts",
      data: data,
    };
  },
 
};

export default actions;
