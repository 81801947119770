import { Grid, ListItemButton } from "@mui/material";
import Button from "@restart/ui/esm/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useRouteMatch } from "react-router-dom";
import "../Report/ReportsHeader.css";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { List, ListItem, ListItemText } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import MaterialSelect from "../../Components/SelectDropdown/MaterialSelect";
export const SupportHeader = (props) => {
  const { option_users, optionVal, filterUsers } = props;
  const [tabValue, setTabValue] = useState("App Support");
  const handleTabValue = (e) => {
    setTabValue(e.target.value);
    // console.log(e.target.value);
  };
  // console.log(tabValue);
  // onChange={handleTabValue}

  return (
    <div style={{ margin: "20px 30px" }}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderColor: "grey.500",
        }}
      >
        <Grid md={3}>
          <Grid sx={{ fontSize: "1.4rem" }}>
            <MaterialSelect
              option_users={option_users}
              optionVal={optionVal}
              filterUsers={filterUsers}
            />

            {/* <select className="tag" onChange={handleTabValue}>
              {options.map((item) => (
                <option
                  style={{
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {item}
                </option>
              ))}
            </select> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
