import React, { useState, useEffect } from "react";
import TitleForm from "./../TitleForm";
import FormButton from "./../FormButton";
import UpdateQuestionForm from "./UpdateQuestionForm";
import { BASE_URL } from "./../../../Components/URL/Url";
import axios from "axios";
import { useHistory } from "react-router";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import { useDispatch, useSelector } from "react-redux";
import {
  loginSayas,
  LogoutSayasActionCreate,
} from "../../../Redux/Authontication/authonticationActionCreator";
import CONSTANTS from "../commons/Utils";
import QuestionSaved from "../GenericForm/QuestionSaved";
import QuestionForm from "../GenericForm/QuestionForm";
import { useParams } from "react-router-dom";

const UpdateMainForm = (props) => {
  const { id } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [values, setValues] = useState([]);
  const [questionType, setQuestionType] = useState("Short Answer Text");
  const [count, setCount] = useState(0);
  const [multipleValues, setMultipleValues] = useState([]);
  const [index, setIndex] = useState(0);
  const [onCopy, setOnCopy] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [about, setAbout] = useState({ description: "", about: "", limit: "" });
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [file, setFile] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState(CONSTANTS.DATA_ACQUISION_TYPES.APPLICATION);
  // const [paramId, setParamsId] = useState("");

  // const paramsId = useParams().id;
  // add permission
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  //  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const OnSubmitQuestion = async (standing) => {
    let url = attachmentUrl;
    const userData =
      standing === "launch"
        ? {
            dataAcquisitionId: data.dataAcquisitionId,
            type: data.type,
            isActive: true,
            title,
            startDate: startDate,
            endDate: endDate,
            description: about.description,
            about: about.about,
            attachmentUrl: url,
            questions: values,
            standing,
          }
        : {
            dataAcquisitionId: data.dataAcquisitionId,
            type: data.type,
            isActive: true,
            title,
            startDate: startDate,
            endDate: endDate,
            description: about.description,
            about: about.about,
            attachmentUrl: url,
            questions: values,
          };

      if (new Date(userData.startDate) === new Date(userData.endDate)) {
        dispatch(
          openSnackbar(
            "We can launch on same date but not on same time",
            "error"
          )
        );
        return;
      } else if (new Date(userData.startDate) > new Date(userData.endDate)) {
        dispatch(openSnackbar("Please fill correct Close Time", "error"));
        return;
      } else if (standing === "launch" && new Date(userData.startDate) <= new Date()) {
        dispatch(
          openSnackbar("Please Select Correct Open Date and Time", "error")
        );
        return;
      }

    if (values.length !== 0 && title.length > 0) {
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.put(
          BASE_URL + `/api/v1/dataAcquisition/${data._id}`,
          userData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        history.push("/admin/data-acquisition");
        dispatch(openSnackbar("Form Updated Successfully!", "success"));
      } catch (err) {
        // console.log("error", err.message);
        if (err?.request.status === 404)
          dispatch(openSnackbar(`Data Acquisition Updatation Failed`, "error"));
        if (err.request.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (values.length == 0) {
      dispatch(openSnackbar(`Error: Add Question First`, "error"));
    } else if (title.length == 0) {
      dispatch(openSnackbar(`Error: Title Field Required`, "error"));
    }
  };

  useEffect(async () => {
    const url = window.location.href.split("/");
    const id = url[url.length - 1];

    try {
      let token1 = window.localStorage.getItem("sayasLoginToken");
      // console.log({ token1, getAuthData });
      let token = token1 || getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        BASE_URL + `/api/v1/dataAcquisition/${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      setData(response.data);
      setStartDate(new Date(response.data.startDate));
      setEndDate(new Date(response.data.endDate));
      setValues(response.data.questions);
      setType(response.data.type);

      setAttachmentUrl(response.data.attachmentUrl);
      setAbout({
        description: response.data.description,
        about: response.data.about,
        limit: response.data.limit,
      });
    } catch (err) {
      console.log("error", err);
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  }, [getAuthData, id]);

  // useEffect(async () => {
  //   const url = window.location.href.split("/");
  //   const id = url[url.length - 1];

  //   try {
  //     let token1 = window.localStorage.getItem("sayasLoginToken");
  //     // console.log({ token1, getAuthData });
  //     let token = token1 || getAuthData;
  //     if (!token) {
  //       dispatch(LogoutSayasActionCreate());
  //     }
  //     let response = await axios.get(
  //       BASE_URL + `/api/v1/dataAcquisition/${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ` + `${token}`,
  //         },
  //       }
  //     );
  //     setData(response.data);
  //     setStartDate(new Date(response.data.startDate));
  //     setEndDate(new Date(response.data.endDate));
  //     setValues(response.data.questions);
  //     setType(response.data.type);

  //     setAttachmentUrl(response.data.attachmentUrl);
  //     setAbout({
  //       description: response.data.description,
  //       about: response.data.about,
  //       limit: response.data.limit,
  //     });
  //   } catch (err) {
  //     console.log("error", err);
  //     if (err.request.status === 401) {
  //       dispatch(LogoutSayasActionCreate());
  //     }
  //   }
  // }, [id]);

  useEffect(() => {
    setTitle(data?.title);
  }, [data]);

  return (
    <div style={{ marginTop: "50px" }}>
      <div align="center">
        {" "}
        {/*remove className="container"*/}
        <TitleForm
          title={`${data?.type || ""} Title`}
          applicationTitle={title}
          setTitle={setTitle}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setAbout={setAbout}
          about={about}
          setAttachmentUrl={setAttachmentUrl}
          attachmentUrl={attachmentUrl}
          file={file}
          setFile={setFile}
          isUpdate={true}
          userRole={userRole}
        />
        <div>
          {values?.map((val, i) => (
            <React.Fragment key={i + 1000}>
              <QuestionSaved
                setQuestionType={setQuestionType}
                questionType={questionType}
                setValues={setValues}
                values={values}
                val={val}
                setCount={setCount}
                count={count}
                type={type}
                setIndex={setIndex}
                index={index || i}
                isUpdate={true}
                idx={i}
                userRole={userRole}
              />
            </React.Fragment>
          ))}
        </div>
        {/*Can be generic form*/}
        {userRole.toLowerCase() !== "staff" ? (
          <QuestionForm
            setQuestionType={setQuestionType}
            questionType={questionType}
            setCount={setCount}
            count={count}
            type={type}
            setValues={setValues}
            values={values}
            setIndex={setIndex}
            setAttachmentUrl={setAttachmentUrl}
            index={index}
            setMultipleValues={setMultipleValues}
            multipleValues={multipleValues}
            setOnCopy={setOnCopy}
            onCopy={onCopy}
          />
        ) : (
          ""
        )}
        <FormButton
          OnSubmitQuestion={OnSubmitQuestion}
          name="Update"
          isUpdate={true}
          userRole={userRole}
        />
      </div>
    </div>
  );
};

export default UpdateMainForm;
