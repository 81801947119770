import actions from "./MemberActions";

const { MEMBER_CREATE, SELECTED_MEMBER, GET_MEMBER_DATA, CLEAR_SELECTION } =
  actions;

const initState = {
  user: null,
  loading: false,
  error: null,
  formData: {},
  selectedRow: [],
  columnsStatus: {
    memberId: false,
    member: false,
    firstName: true,
    middleName: false,
    lastName: true,
    email: false,
    phone: true,
    nominee_name: false,
    organisation_name: false,
    state: true,
    city: true,
    district: true,
    taluka: true,
    pincode: true,
    addressLine_1: false,
    addressLine_2: false,
    role: false,
    team: false,
    date: false,
    totalShare: true,
    shareApplicationMoney: true,
    activityStatus: false,
    mobileSignUpStatus: false,
  },
};

const MemberReducers = (state = initState, action) => {
  const { type, data, err, compData } = action;
  // console.log("3", data);
  switch (type) {
    case MEMBER_CREATE:
      return { ...state, formData: data };
    case SELECTED_MEMBER:
      if (data === null) {
        return state;
      } else if (data === undefined) {
        return { ...state, selectedRow: compData };
      } else {
        let oldData = [...state.selectedRow];
        let isData = oldData.filter((ele) => {
          return ele.memberId === data.memberId;
        });
        let finalData =
          isData.length > 0
            ? oldData.filter((ele) => {
                return ele.memberId !== data.memberId;
              })
            : [...oldData, data];
        return { ...state, selectedRow: finalData };
      }
    case CLEAR_SELECTION:
      return { ...state, selectedRow: [] };
    case GET_MEMBER_DATA:
      return { ...state, memberData: data };
    case "memberData":
      return { ...state, AllMemberData: data };
    case "COLUMNSTATUS":
      return {
        ...state,
        columnsStatus: {
          ...state.columnsStatus,
          [`${data}`]: state.columnsStatus[`${data}`]
            ? state.columnsStatus[`${data}`]
              ? false
              : true
            : true,
        },
      };
    default:
      return state;
  }
};
export default MemberReducers;
