import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  ListItemButton,
} from "@mui/material";
import { border, height, width } from "@mui/system";
import Button from "@restart/ui/esm/Button";
import Popover from "@mui/material/Popover";
import { List, ListItem, ListItemText } from "@material-ui/core";
import moment from "moment";
import Typography from "@mui/material/Typography";
import {
  bindTrigger,
  bindPopover,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { GeneralDateRangePicker } from "../../../Components/GeneralComponents/GeneralDateRangePicker";
import MaterialSelect from "../../../Components/SelectDropdown/MaterialSelect";
import ReportsSelectHeader from "../ReportSelectHeader";
import ExportTable from "../../../Components/Export/ExportTable";
import { useDispatch, useSelector } from "react-redux";

import { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import "../ReportsHeader.css";
import exportFromJSON from "export-from-json";
import { GeneralDateRangePickerForReport } from "../../../Components/GeneralComponents/GeneralDateRangePickerForReport";
const fileName = "download";
const exportType = exportFromJSON.types.csv;
export const ShareholdingDistributionHeader = (props) => {
  const {
    setTabValue,
    tabValue,
    selectValue,
    setSelectValue,
    finalData,
    setData,
    city,
    setCity,
    state,
    setState,
    organisation,
    setOrganisation,
    tableRef,
  } = props;
  const [filterDate, setFilterDate] = useState({});

  const handleTabValue = (e) => {
    setTabValue(e.target.value);
    // console.log(e.target.value);
  };
  // console.log(tabValue);

  const [sayasAcc, setSayasAcc] = useState({});
  const [pricePerShare, setPricePerShare] = useState(0);
  const getSayasData = useSelector((state) => {
    return state;
  });

  useEffect(async () => {
    setSayasAcc(getSayasData.sayasAccount);
    setPricePerShare(getSayasData.dashboard.pricePerShare);
  }, [getSayasData]);

  const options = ["All", "City", "State", "Organization"];

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const { path } = useRouteMatch();
  const [secondary, setSecondary] = useState(false);
  const [dense, setDense] = useState(false);

  const ListItemForHeader = [
    "Today",
    "Yesterday",
    "This Week",
    "Previous Week",
    "Last 30 Days",
  ];

  const handleClickOnRightButton = (e) => {
    setSelectValue(e.target.innerText);
    handleClose();
    if (e.target.innerText === "Custom") popupState.open(anchorElD);
    switch (e.target.innerText) {
      case "Today":
        setData({
          startDate: moment().format("YYYY MM DD"),
          endDate: moment().format("YYYY MM DD"),
        });
        break;
      case "Yesterday":
        setData({
          startDate: moment().add(-1, "days").format("YYYY MM DD"),
          endDate: moment().add(-1, "days").format("YYYY MM DD"),
        });
        break;
      case "This Week":
        const today = moment();
        setData({
          startDate: today.startOf("week").format("YYYY MM DD"),
          endDate: today.endOf("week").format("YYYY MM DD"),
        });

        break;
      case "Previous Week":
        const day = moment().add(-7, "days");
        setData({
          startDate: day.startOf("week").format("YYYY MM DD"),
          endDate: day.endOf("week").format("YYYY MM DD"),
        });
        break;
      case "Last 30 Days":
        setData({
          startDate: moment().add(-30, "days").format("YYYY MM DD"),
          endDate: moment().format("YYYY MM DD"),
        });
        break;

      default:
        break;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElD, setAnchorElD] = useState(null);
  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const handlePopOver = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorElD(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseD = () => {
    setAnchorElD(null);
    console.log("close datepicker");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openD = Boolean(anchorElD);
  const idD = openD ? "simple-popover" : undefined;

  return (
    <div style={{ margin: "20px 30px" }}>
      <Grid
        // container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderColor: "grey.500",
        }}
      >
        <Grid md={2}>
          <Grid sx={{ fontSize: "1.4rem" }}>
            {" "}
            <MaterialSelect
              option_users={options}
              optionVal={tabValue}
              filterUsers={setTabValue}
            />
          </Grid>
        </Grid>

        {/* {tabValue !== "All" ? (
          <Grid md={2}>
            <Grid sx={{ fontSize: "1.4rem" }}>
              {" "}
              <MaterialSelect
                option_users={
                  tabValue === "City"
                    ? Object.keys(tableDataForFilter.city)
                    : tabValue === "State"
                    ? Object.keys(tableDataForFilter.state)
                    : tabValue === "Organization"
                    ? Object.keys(tableDataForFilter.organisation)
                    : []
                }
                optionVal={
                  tabValue === "City"
                    ? city
                    : tabValue === "State"
                    ? state
                    : tabValue === "Organization"
                    ? organisation
                    : "Select Filter"
                }
                filterUsers={
                  tabValue === "City"
                    ? setCity
                    : tabValue === "State"
                    ? setState
                    : tabValue === "Organization"
                    ? setOrganisation
                    : null
                }
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )} */}

        <Grid
          spacing={1}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            m: 1,
            mr: 5.2,
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <Grid>
            <>
              {/* <ExportTable
              data={
                finalData
                  ? finalData.map((ele) => {
                      if (tabValue === "All") {
                        return {
                          Date: moment(ele.updatedAt.slice(0, -1)).format(
                            "DD/MM/yyyy"
                          ),
                          membershipId: ele.memberId,
                          transactionId: ele.id,
                          memberName: ele.firstName + " " + ele.lastName,
                          balance: ele.balance || "0",
                          City: ele.city,
                          State: ele.state,
                          Organisation: ele.organisation_name || "",
                          TotalShare: ele.totalShare || "0",
                          ShareCapital: ele.shareCapital || "0",
                          ShareAppMoney: ele.shareApplicationMoney || "0",
                          ShareHoldingPercent: `${
                            (
                              (ele.totalShare / sayasAcc.totalShares) *
                              100
                            ).toFixed(5) || "0"
                          }%`,
                        };
                      } else
                      return {
                        [tabValue]: ele.name,
                        TotalShare: ele.totalShares || "0",
                        ShareCapital: ele.shareCapital || "0",
                          ShareAppMoney: ele.shareApplicationMoney || "0",
                          TotalMember: ele.totalMember || "0",
                          ShareHoldingPercent: `${
                            (
                              (ele.totalShares / sayasAcc.totalShares) *
                              100
                            ).toFixed(5) || "0"
                          }%`,
                          RegularMembers: ele.regularMembers || "0",
                          NominalMembers: ele.nominalMembers || "0",
                        };
                      })
                      : []
                    }
                    /> */}
              <div
                className="exportBtn"
                onClick={() => {
                  exportFromJSON({
                    data: finalData?.map((ele) => {
                      let newData = {};
                      if (tabValue !== "All") {
                        newData[`${tabValue} Name`] = ele["name"]?.trim() || "";
                        // delete newData["name"];
                        newData["totalMember"] = ele["totalMember"];
                        newData["regularMember"] = ele["regularMembers"];
                        newData["nominalMembers"] = ele["nominalMembers"];
                        newData["totalShare"] = ele["totalShares"];
                        newData["shareCapital"] = ele["shareCapital"];
                        newData["shareApplicationMoney"] =
                          ele["shareApplicationMoney"];
                        newData["PercentageShareholding"] = (
                          (ele["totalShares"] / sayasAcc.totalShares) *
                          100
                        ).toFixed(5);
                      } else {
                        newData["memberId"] = ele["memberId"]?.trim();
                        newData["memberType"] = ele["member"]?.trim();
                        newData["shareholder"] = ele["shareholder"]?.trim();
                        newData["firstName"] = ele["firstName"]?.trim();
                        newData["middleName"] = ele["middleName"]?.trim();
                        newData["lastName"] = ele["lastName"]?.trim();
                        newData["phone"] = ele["phone"];
                        newData["addressLine_1"] = ele["addressLine_1"]?.trim();
                        newData["addressLine_2"] = ele["addressLine_2"]?.trim();
                        newData["state"] = ele["state"]?.trim();
                        newData["district"] = ele["district"]?.trim();
                        newData["taluka"] = ele["taluka"]?.trim();
                        newData["city"] = ele["city"]?.trim();
                        newData["activityStatus"] = ele["activityStatus"];
                        newData["mobileStatus"] = ele["mobileSignUpStatus"];
                        newData["totalShare"] = ele["totalShare"];
                        newData["shareCapital"] = ele["shareCapital"];
                        newData["shareApplicationMoney"] =
                          ele["shareApplicationMoney"];
                        // newData["totalShare"] = ele["totalShare"];
                        newData["PercentageShareholding"] = (
                          (ele["totalShare"] / sayasAcc.totalShares) *
                          100
                        ).toFixed(5);
                        newData["memeberAddDate"] = ele["createdAt"];
                      }
                      return newData;
                    }),
                    fileName,
                    exportType,
                  });}}
              >
                <i
                  className="fa fa-download"
                  style={{ color: "#ffffff", fontSize: "22px" }}
                ></i>
              </div>
            </>
          </Grid>

          <div
            style={{
              textTransform: "capitalize",
            }}
          >
            {tabValue !== "All" ? null : (
              <button
                aria-describedby={id}
                className="btnHover"
                onClick={handlePopOver}
                style={{
                  outline: "none",
                  border: "none",
                  fontSize: "20px",
                  background: "#0096FF",
                  borderRadius: "5px",
                  color: "#FFFFFF",
                  padding: "5px 15px",
                  textTransform: "capitalize",
                }}
              >
                {selectValue}
                <i
                  className={open ? "fa fa-caret-up" : "fa fa-caret-down"}
                  style={{ marginLeft: 10 }}
                />
              </button>
            )}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Typography sx={{ p: 2 }}>
                <List dense={dense}>
                  {ListItemForHeader.map((value) => {
                    return (
                      <ListItem>
                        <ListItemButton
                          onClick={handleClickOnRightButton}
                          sx={{
                            textTransform: "capitalize",
                          }}
                        >
                          <ListItemText
                            primary={value}
                            secondary={secondary ? "Secondary text" : null}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                  <ListItem>
                    {/* <PopupState variant="popover" popupId="demo-popup-popover"> */}
                    {/* {(popupState) => ( */}
                    {/* <div> */}
                    <ListItemButton
                      onClick={handleClickOnRightButton}
                      // {...bindTrigger(popupState)}
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      <ListItemText
                        primary={"Custom"}
                        // onClick={handleClickOnRightButton}
                        secondary={secondary ? "Secondary text" : null}
                      />
                    </ListItemButton>
                    {/* </div> */}
                    {/* )} */}
                    {/* </PopupState> */}
                  </ListItem>
                </List>
              </Typography>
            </Popover>
            <Popover
              id={idD}
              open={openD}
              anchorEl={anchorElD}
              onClose={handleCloseD}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography
                sx={{ p: 2 }}
                style={{
                  // height: "85vh",
                  width: "46.5vw",
                  // minHeight: "20vh",
                }}
              >
                {/* <GeneralDateRangePicker
                  setData={setData}
                  handleCloseD={popupState.close}
                /> */}
                <GeneralDateRangePickerForReport
                  setData={setData}
                  handleCloseD={popupState.close}
                  setFilterDate={setFilterDate}
                  filterDate={filterDate}
                />
              </Typography>
            </Popover>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
