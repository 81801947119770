import actions from "./actions";
import axios from "axios";
import { useSelector } from "react-redux";
const { registerBegin,CommunicationDataVisibiltyAction } = actions;

const register = (formData) => {
  return async (dispatch) => {
    try {
      dispatch(registerBegin());
      console.log(formData);
    } catch (err) {
      console.log(err.response);
    }
  };
};

const CommunicationDataVisibiltyActionCreater = (data) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    try {
      dispatch(CommunicationDataVisibiltyAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};
export { register, CommunicationDataVisibiltyActionCreater };
