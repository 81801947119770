import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import DialogComponent from './DialogComponent'
function Widget() {
  const tawkMessengerRef = useRef();
  const [first, setfirst] = useState(false);
  const customStyle = {
    visibility: {
      desktop: {
        xOffset: "15",
        yOffset: "15",
        position: "cr",
      },

      mobile: {
        xOffset: 15,
        yOffset: 15,
        position: "bl",
      },
    },
  };
  let { search } = useLocation();
  const onLoad = () => {
    const query = new URLSearchParams(search);
    try {
      const result = tawkMessengerRef.current.getStatus();
      if (result === "offline") {
        setfirst(true);
        tawkMessengerRef.current.hideWidget();
      } else if (result === "online") {
        setfirst(false);
        tawkMessengerRef.current.maximize();
        const paramFieldMobile = query.get("mobileNo");
        const paramValueEmail = query.get("email");
        tawkMessengerRef.current.setAttributes(
          {
            name: `${paramFieldMobile}`,
            email: `${paramValueEmail}`,
          },
          function (error) {
            console.log(error);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DialogComponent open={first} onClose={() => setfirst(false)} />
      <TawkMessengerReact
        customStyle={customStyle}
        propertyId="62135c15a34c245641275018"
        widgetId="1fsdpve0v"
        ref={tawkMessengerRef}
        onLoad={onLoad}
        onBeforeLoad={() => {
          console.log("onBeforeLoad");
        }}
      />
    </>
  );
}

export default Widget;
