import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/logo.png";
import styles from "./LoginForm.module.css";
import { Button, Modal, Typography } from "@mui/material";
import "../Login/login.css";
import { useHistory } from "react-router";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import {
  loginSayas,
  LogoutSayasActionCreate,
} from "../../Redux/Authontication/authonticationActionCreator";
import { ProgressBar } from "../../Components/GeneralComponents/ProgressBar";
import { Redirect } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ResetPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isProgress, setIsProgress] = useState(true);
  const [local, setLocal] = useState(null);
  const [flag, setFlag] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    confirmPassword: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const params = useParams();
  const token = params.token;
  // console.log(token);

  const progressStatus = useSelector((state) => {
    return state.loader.status;
  });
  const getAuthData = useSelector((state) => {
    return state.auth;
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const resetPassword = async (data) => {
    try {
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/auth/password/reset/" +
          `${token}`,
        data
      );
      dispatch(
        openSnackbar(
          "Password updated successfully! Please login to continue",
          "success"
        )
      );
      window.localStorage.removeItem("sayasLoginToken");
      dispatch(LogoutSayasActionCreate());
      history.push("/login");
    } catch (e) {
      dispatch(
        openSnackbar(
          `Password Reset failed : ${e.response.data.error}`,
          "error"
        )
      );
    }
  };

  useEffect(() => {
    setIsProgress(progressStatus);
  }, [progressStatus]);

  useEffect(async () => {
    dispatch(loaderStartActionCreater());
    setIsAuth(getAuthData.isAuth);
  }, [getAuthData]);

  useEffect(() => {
    setIsAuth(getAuthData.isAuth);
  }, [getAuthData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.classList.remove("MuiButtonBase-root");
    if (password === confirmPassword) {
      setFlag(false);
      dispatch(loaderStartActionCreater());
      let newData = {
        password,
        confirmPassword,
      };
      resetPassword(newData);
      setFlag(true);
    } else if (password === "") {
      // dispatch(
      //   openSnackbar(`Please fill the password Field`, "error")
      // );
      setFlag(true);
    } else if (confirmPassword === "") {
      // dispatch(
      //   openSnackbar(`Please fill the Confirm Password Field`, "error")
      // );
      setFlag(true);
    } else {
      dispatch(
        openSnackbar(`Password and Confirm Password did not Match`, "error")
      );
      setFlag(true);
    }
  };

  const handleClickShowPassword = (id) => {
    if (id === "pass") {
      setValues({
        ...values,
        showPassword: !values.showPassword,
      });
    } else {
      setValues({
        ...values,
        showConfirmPassword: !values.showConfirmPassword,
      });
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    history.push("/login");
  };

  return (
    <div className="limiter">
      <div className={styles.main} align="center">
        <img src={Logo} />
        <div className="d-flex flex-column justify-content-center align-items-center">
          <label className={styles.logoHead}>Sayas</label>
          <label className={styles.cooperative}>cooperative</label>
        </div>
      </div>
      <div className="container-login100">
        <div
          className="wrap-login100"
          style={{
            width: "42vw",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.5rem 3rem",
            }}
          >
            <form className="login100-form validate-form">
              <span
                className="login100-form-title p-b-32"
                style={{
                  color: "#343434",
                  fontFamily: "Mulish",
                  fontWeight: "bold",
                }}
              >
                Reset Password
              </span>

              <div
                className="wrap-input100 mt-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Input
                  // id="standard-adornment-password"
                  fullWidth
                  className="input100"
                  error={flag && password === "" ? true : false}
                  type={values.showPassword ? "text" : "password"}
                  value={password}
                  autoFocus={true}
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      hiddenLabel={true}
                      sx={{
                        outline: "none!important",
                      }}
                    >
                      <IconButton
                        aria-label="password visibility"
                        onClick={() => {
                          handleClickShowPassword("pass");
                        }}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {flag && password == "" ? (
                  <span
                    className="text-danger"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Please input your password!
                  </span>
                ) : null}
              </div>

              <div
                className="wrap-input100 mt-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Input
                  // id="standard-adornment-password"
                  fullWidth
                  className="input100"
                  error={flag && password === "" ? true : false}
                  type={values.showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      hiddenLabel={true}
                      sx={{
                        outline: "none!important",
                      }}
                    >
                      <IconButton
                        aria-label="password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {flag && confirmPassword == "" ? (
                  <span
                    className="text-danger"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Please input your Confirm Password!
                  </span>
                ) : null}
              </div>

              <div className="flex">
                {/* <div className="contact100-form-checkbox mt-2 mb-2">
                  <input
                    className="input-checkbox100"
                    id="ckb1"
                    type="checkbox"
                    name="remember-me"
                  />
                   <label className="label-checkbox100" for="ckb1">
                    Remember me
                  </label> 
                </div> */}
                <Box
                  sx={{
                    height: "40px",
                    marginTop: "1.5rem",
                  }}
                >
                  <button
                    className="btnHoverLogin"
                    style={{
                      color: "#fff",
                      backgroundColor: "#0092E8",
                      fontSize: "18px",
                      textTransform: "capitalize",
                      // backgroundColor: "blue",
                      borderRadius: "3px",
                      width: "100%",
                      height: "100%",
                    }}
                    type="button"
                    onClick={handleSubmit}
                  >
                    Reset
                  </button>
                </Box>

                <Box
                  sx={{
                    height: "40px",
                    marginTop: ".5rem",
                  }}
                >
                  <Button
                    className="btnHoverLogin"
                    style={{
                      color: "#fff",
                      // color: "#000000",
                      backgroundColor: "#d1ccc0",
                      fontSize: "18px",
                      textTransform: "capitalize",
                      // backgroundColor: "blue",
                      borderRadius: "3px",
                      width: "100%",
                      height: "100%",
                    }}
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
