import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import styles from "../member.module.css";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import { GeneralSelectField } from "../../Components/GeneralComponents/GeneralSelectField";
// import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import axios from "axios";
// import {
//   loaderEndActionCreater,
//   loaderStartActionCreater,
// } from "../../Redux/Loader/LoaderActionCreator";
// import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Slide,
  TextField,
} from "@mui/material";
// import {
//   getDashboardTransfer,
//   getPieChartDataActionCreater,
//   getTop10ShareHolders,
//   getTotalShareHoldersMembers,
// } from "../../Redux/Dashboard/dashboardActionCreator";
// import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { GeneralInputField } from "../../../Components/GeneralComponents/GeneralInputField";
import { GeneralSelectField } from "../../../Components/GeneralComponents/GeneralSelectField";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import {
  getDashboardTransfer,
  getPieChartDataActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../../Redux/Dashboard/dashboardActionCreator";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import { openSnackbar } from "../../../Redux/Snackbar/snackbarStore";
import { useHistory } from "react-router-dom";
import { selectedMemberRows } from "../../../Redux/Members/actionCreator";

export default function ConfirmTransferShare(props) {
  const {
    open,
    setOpen,
    newData,
    setOpen2,
    handleClosetransfer,
    setConflict2,
  } = props;
  const [submitted, setSubmitted] = useState(false);
  var [checkVal, setCheckVal] = useState(false);
  var [conflict, setConflict] = useState(false);
  const [shareTypes, setShareTypes] = useState([]);
  const [data, setData] = useState(newData);
  // const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const history = useHistory();

  // useEffect(() => {
  //   setOpenConfirmModal(open);
  // }, [open]);

  const dispatch = useDispatch();
  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  const postMemberTransfer = async (val) => {
    try {
      console.log(val);
      ////checktoken
      handleClosetransfer(false);

      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/transferShares",
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      dispatch(getPieChartDataActionCreater(getAuthData));

      dispatch(
        openSnackbar(
          `${val.noOfShares} Shares Transferred from ${data.fromMemberName} to ${data.toMemberName} successfully`,
          "success"
        )
      );
      setConflict2(true);
    dispatch(selectedMemberRows(undefined, []));

      history.push("/admin/members");

      dispatch(loaderEndActionCreater());
      setOpen2(false);
    } catch (err) {
      // console.log(err.response.data.error);
      // setOpen2(false)
      if (err?.response?.data?.error)
        dispatch(openSnackbar(err?.response?.data?.error, "error"));
      dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    setShareTypes(
      getConfigData.shareType
        ? getConfigData.shareType.map((e) => {
            return e.type;
          })
        : []
    );
  }, [getConfigData]);
  useEffect(() => {
    setData(newData);
  }, [newData]);

  const handleClose = () => {
    setOpen(false);
    setCheckVal(false);
    // setOpenConfirmModal(false);
    setSubmitted(false);
  };

  const handleCheck = () => {
    setCheckVal(!checkVal);
  };

  const handleSubmit = () => {
    if (!checkVal) {
      dispatch(openSnackbar("please check the confirm box.", "error"));
      return;
    }

    let newData = {
      fromMemberId: data.fromMemberIDNew,
      typeOfShare: data.typeOfShare,
      noOfShares: parseInt(data.totalShare),
      toMemberId: data.toMemberIDNew,
    };

    dispatch(loaderStartActionCreater());
    postMemberTransfer(newData);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <label
            id={styles.lab}
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
            }}
          >
            Please Confirm Your Data
          </label>
        </DialogTitle>
        <hr className="m-0" />
        <DialogContent>
          <DialogContentText>
            <div className="row">
              <div className="col">
                <label id={styles.lab}>From</label>
                <div
                  style={{
                    padding: "5px 10px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                    marginBottom: "0rem",
                  }}
                >
                  <GeneralInputField
                    data={data}
                    setData={setData}
                    disabled
                    conflict={conflict}
                    setConflict={setConflict}
                    fullWidth
                    submitted={submitted}
                    value={data.fromMemberID}
                    dataKey={"fromMemberID"}
                    // useFor={"api"}
                    label={"Membership ID"}
                  />

                  <GeneralInputField
                    data={data}
                    setData={setData}
                    disabled
                    value={data.fromMemberName}
                    submitted={submitted}
                    fullWidth
                    dataKey={"fromMemberName"}
                    label={"Member Name"}
                  />
                </div>

                <GeneralSelectField
                  data={data}
                  setData={setData}
                  disabled
                  fullWidth
                  required
                  submitted={submitted}
                  itemVal={data?.typeOfShare}
                  dataKey={"typeOfShare"}
                  place={"Type of Share *"}
                  items={shareTypes.length > 0 ? shareTypes : []}
                />
              </div>
              <div className="col">
                <label id={styles.lab}>To</label>
                <div>
                  <div
                    style={{
                      padding: "5px 10px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      textTransform: "capitalize",
                    }}
                  >
                    <GeneralInputField
                      data={data}
                      setData={setData}
                      disabled
                      conflict={conflict}
                      setConflict={setConflict}
                      fullWidth
                      //   useFor={"api"}
                      required
                      submitted={submitted}
                      value={data.toMemberID}
                      dataKey={"toMemberID"}
                      label={"Membership ID"}
                    />

                    <GeneralInputField
                      data={data}
                      setData={setData}
                      disabled
                      value={data?.toMemberName}
                      submitted={submitted}
                      fullWidth
                      dataKey={"toMemberName"}
                      label={"Member Name"}
                    />
                  </div>
                  <GeneralInputField
                    data={data}
                    setData={setData}
                    disabled
                    fullWidth
                    required
                    submitted={submitted}
                    maxLimit={11}
                    onlyNumber
                    value={data?.totalShare}
                    dataKey={"totalShare"}
                    label={"Number of Share"}
                  />
                </div>
              </div>
            </div>
          </DialogContentText>
          <DialogContentText align="right" className="mt-4">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
                justifyContent: "flex-start",
                // float : "left"
              }}
            >
              <FormControlLabel
                checked={checkVal}
                control={<Checkbox />}
                label="Data Verified"
                onClick={handleCheck}
                sx={{
                  textTransform: "capitalize",
                }}
              />
            </div>
            <Button onClick={handleClose}>Back</Button>
            <Button
              onClick={handleSubmit}
              variant={checkVal ? "contained" : "outlined"}
              sx={{
                bgcolor: "#0096FF",
                marginLeft: "1rem",
              }}
            >
              Confirm
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
