import React, { useEffect, useState } from "react";
import styles from "../DataAcq.module.css";
import { Button, Checkbox, TextField } from "@mui/material";
import { FormControlLabel, Radio } from "@material-ui/core";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import uuid from "react-uuid";
import TypeSelect from "./../TypeSelect";
import { handleUpload } from "../../../Components/GeneralComponents/GeneralFileUpload";
import { useDispatch } from "react-redux";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import CONSTANTS from "../commons/Utils";
import { QuestionHeader } from "../commons/QuestionHeader";
import { QuestionInput } from "../commons/QuestionInput";
import { openSnackbar } from "../../../Redux/Snackbar/snackbarStore";

const QuestionSaved = (props) => {
  const { values, setValues, val, isUpdate, userRole, idx } = props;

  const [count, setCount] = useState(0);
  const [question, setQuestion] = useState(val.question);
  const [questionType, setQuestionType] = useState(val.questionType);
  const [description, setDescription] = useState("");

  const [required, setRequired] = useState(val.required);

  const [type, setType] = useState(val.type);
  const [limit, setLimit] = useState(val.limit);

  const [edit, setEdit] = useState(false);
  const [optionEdit, setOptionEdit] = useState(false);
  const [option, setOption] = useState(val.options);
  const [fileURL, setFileUrl] = useState(val.attachmentUrl || "");
  const dispatch = useDispatch();

  useEffect(() => {
    setQuestion(val.question);
    setQuestionType(val.questionType);
    setRequired(val.required);
    setType(val.type);
    setLimit(val.limit);
  }, [val]);

  const optionTypes = CONSTANTS.optionType;

  // console.log(idx);

  const onDelete = () => {
    props.setValues(props.values.filter((item) => item.id !== val.id));
    setEdit(false);
  };

  const handleUpdate = () => {
    if (CONSTANTS.allowedMultiAnswerQuestionType(questionType)) {
      console.log(questionType, values);
      let tempObj = values.map((item) => {
        var temp = {};
        if (item.id === val.id) {
          temp.id = item.id;
          temp.question = question;
          temp.questionType = questionType;
          temp.required = required;
          temp.options = option;
          temp.type = type;
          temp.attachmentUrl = fileURL;
          return temp;
        }
        return item;
      });
      setValues(tempObj);
      setOptionEdit(false);
    } else {
      let tempObj = values.map((item) => {
        var temp = {};
        if (item.id === val.id) {
          temp.id = item.id;
          temp.question = question;
          temp.questionType = questionType;
          temp.required = required;
          temp.type = type;
          temp.limit = limit;
          temp.options = [];
          temp.attachmentUrl = fileURL;
          return temp;
        }
        return item;
      });
      setValues(tempObj);
      setEdit(false);
    }
  };

  const saveCopy = () => {
    if (CONSTANTS.allowedMultiAnswerQuestionType(questionType)) {
      setCount(count + 1);
      props.setValues([
        ...props.values,
        {
          id: uuid(),
          question: question,
          required: required,
          questionType: questionType,
          attachmentUrl: fileURL,
          options: option,
          type: type,
        },
      ]);
    } else {
      props.setCount(props.count + 1);
      props.setValues([
        ...props.values,
        {
          id: uuid(),
          question: question,
          questionType: questionType,
          type: type,
          required: required,
          attachmentUrl: fileURL,
          limit: limit,
          options: [],
        },
      ]);
    }
  };

  const checkType = (event) => {
    if (type == "Alphabet") {
      if (!/[a-z\b ]/i.test(String.fromCharCode(event.keyCode))) {
        event.preventDefault();
        return false;
      }
    } else if (type == "Number") {
      if (!/[0-9\b ]/i.test(String.fromCharCode(event.keyCode))) {
        event.preventDefault();
        return false;
      }
    } else {
      if (!/[0-9a-z\b ]/i.test(String.fromCharCode(event.keyCode))) {
        event.preventDefault();
        return false;
      }
    }
  };

  function noEdit() {
    // console.log(idx);
    return (
      <div className={styles.saved} onClick={() => setEdit(true)}>
        <TextField
          id={styles.description}
          variant="standard"
          className="w-100 mb-2"
          // value={question}
          value={`${idx+1}. ${question}`}
          disabled
        />
        <TextField
          id={styles.description}
          variant="standard"
          className="w-100 mt-2"
          value={props.description}
          placeholder={questionType}
          disabled
        />
      </div>
    );
  }

  function getHeading() {
    return (
      <div
        className={styles.questionBack}
        style={{ borderLeft: "15px solid #525252" }}
      >
        <QuestionHeader
          required={required}
          saveCopy={saveCopy}
          setRequired={setRequired}
          onDelete={() => onDelete()}
          isUpdate={isUpdate}
          userRole={userRole}
        />

        <div>
          <QuestionInput
            question={question}
            fileURL={fileURL}
            setQuestion={setQuestion}
            onFileChange={async (e) => {
              dispatch(loaderStartActionCreater());
              let fileURL = await handleUpload(e.target.files);
              setFileUrl(fileURL);
              dispatch(loaderEndActionCreater());
            }}
            questionType={questionType}
            optionQuestionType={CONSTANTS.optionQuestionType}
            filterUsers={() => {}}
            type={props.type}
            setQuestionType={setQuestionType}
            isUpdate={isUpdate}
            userRole={userRole}
          />
          {CONSTANTS.allowedMultiAnswerQuestionType(questionType)
            ? renderQuestionBasedOnType(questionType)
            : renderNormalQuestionBasedOnType(questionType)}
          {renderSaveButton()}
        </div>
      </div>
    );
  }

  function renderSaveButton() {
    return (
      <div align="right">
        <Button
          disabled={
            isUpdate === true && userRole.toLowerCase() === "staff"
              ? true
              : false
          }
          onClick={() => {
            // console.log(option);
            if (question === "") {
              dispatch(openSnackbar(`Error: Question Field Required`, "error"));
              return;
            }
            // console.log(questionType);
            if (
              questionType === "Multiple Choice" ||
              questionType === "Check Box"
            ) {
              let optionValid = false;
              option.forEach((ele) => {
                if (ele.option !== "") optionValid = true;
                else optionValid = false;
              });
              if (!optionValid) {
                dispatch(
                  openSnackbar(`Error: Please fill value in options`, "error")
                );
                return;
              }
            }
            return handleUpdate();
          }}
          style={{
            fontFamily: "Mulish",
            color: "#ffffff",
            backgroundColor: "#0096FF",
            marginRight: "20px",
            fontWeight: "600",
            fontSize: "18px",
            padding: "5px",
            margin: "1rem 3rem 2rem 0",
          }}
        >
          Save
        </Button>
      </div>
    );
  }

  function renderActionButton(type) {
    return (
      <Button
        id={styles.addMore}
        label={type}
        disabled={
          isUpdate === true && userRole.toLowerCase() === "staff" ? true : false
        }
        onClick={() => {
          let opt = option;
          opt.push({ id: option.length, option: "" });
          setOption([...opt]);
        }}
      >
        {type}
      </Button>
    );
  }

  function renderQuestionBasedOnType(questionType) {
    return (
      <div
        className="d-flex justify-content-start"
        style={{ padding: "0 0 0 50px", width: "100%" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "57%",
          }}
        >
          {option.map((item, i) => (
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <FormControlLabel
                  control={
                    questionType === CONSTANTS.QUESTION_TYPES.CHECK_BOX ? (
                      <Checkbox disabled />
                    ) : (
                      <Radio disabled />
                    )
                  }
                />
                <input
                  type="text"
                  name={`Option${i + 1}`}
                  value={item.option}
                  placeholder={`Option ${i + 1}`}
                  className={styles.inputBox}
                  disabled={
                    isUpdate === true && userRole.toLowerCase() === "staff"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    let opt = option;
                    opt[i].option = e.target.value;
                    setOption([...opt]);
                  }}
                />
              </div>
              <div>
                <CloseRoundedIcon
                  style={{ fontSize: 30, marginBottom: 10 }}
                  onClick={() => {
                    if (
                      isUpdate === true &&
                      userRole.toLowerCase() === "staff"
                    ) {
                    } else {
                      setOption(option.filter((opt) => opt.id !== i));
                    }
                  }}
                />
              </div>
            </div>
          ))}
          <div align="left">
            {option.length === 0
              ? renderActionButton(CONSTANTS.ACTION_BUTTON_LABELS.ADD_OPTIONS)
              : renderActionButton(CONSTANTS.ACTION_BUTTON_LABELS.ADD_MORE)}
          </div>
        </div>
      </div>
    );
  }

  function renderNormalQuestionBasedOnType(questionType) {
    switch (questionType) {
      case CONSTANTS.QUESTION_TYPES.DATE:
        return <></>;
      case CONSTANTS.QUESTION_TYPES.FILE:
        return <></>;
      default:
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ padding: "0 50px 15px 50px" }}
          >
            <div style={{ width: "60%" }}>
              <TextField
                id={styles.description}
                variant="standard"
                fullWidth
                placeholder={questionType}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled
              />
            </div>

            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "36%" }}
            >
              <div>
                <TypeSelect
                  optionType={optionTypes}
                  setType={setType}
                  type={type}
                  disabled={
                    isUpdate === true && userRole.toLowerCase() === "staff"
                      ? true
                      : false
                  }
                />
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <label className="m-2">Limit</label>
                <TextField
                  id={styles.limit}
                  variant="standard"
                  className="w-25"
                  type="tel"
                  fullWidth
                  min="0"
                  value={limit}
                  disabled={
                    isUpdate === true && userRole.toLowerCase() === "staff"
                      ? true
                      : false
                  }
                  onChange={(e) => setLimit(e.target.value)}
                />
              </div>
            </div>
          </div>
        );
    }
  }

  function renderNoOptionEdit(idx) {
    return (
      <div className={styles.saved} onClick={() => setOptionEdit(true)}>
        <TextField
          id={styles.description}
          variant="standard"
          className="w-100 mb-2"
          // value={question}
          value={`${idx+1}. ${question}`}
          disabled
        />
        {option.map((item, i) => {
          return (
            <div key={item}>
              <FormControlLabel
                control={
                  questionType === CONSTANTS.QUESTION_TYPES.CHECK_BOX ? (
                    <Checkbox disabled />
                  ) : (
                    <Radio disabled />
                  )
                }
                style={{ margin: 0 }}
                disabled
              />
              <input
                type="text"
                value={item.option}
                className={styles.inputBox}
                placeholder={`Option ${i + 1}`}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function renderQuestions(idx) {
    return CONSTANTS.allowedMultiAnswerQuestionType(questionType) ? (
      <div>{!optionEdit ? renderNoOptionEdit(idx) : getHeading()}</div>
    ) : (
      <div>{!optionEdit ? renderNoOptionEdit(idx) : getHeading()}</div>
    );
  }

  return (
    <div>
      {!CONSTANTS.allowedMultiAnswerQuestionType(questionType) ? (
        <div>{!edit ? noEdit() : getHeading()}</div>
      ) : (
        renderQuestions(idx)
      )}
    </div>
  );
};

export default QuestionSaved;
