import ShareApplicationMoneyTable from "./ShareApplicationMoneyTable";
import { ShareApplicationMoneyHeader } from "./ShareApplicationMoneyHeader";
import { useDispatch, useSelector } from "react-redux";
import { createRef, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import CustomSearchForReport from "../../../Components/CustomSearch/CustomSearchForReport";
import { MultiSelectDropdown } from "../../../Components/SelectDropdown/MultiSelectDropdown";
import { reportDataVisibiltyActionCreater } from "../../../Redux/Reports/reportActionCreator";

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
export const ShareApplicationMoney = () => {
  const [completeData, setCompleteData] = useState(null);
  const [memberList, setMemberList] = useState({});
  const [finalData, setFinalData] = useState([]);
  const [tabValue, setTabValue] = useState("All");
  const [selectValue, setSelectValue] = useState("Last 30 Days");
  const [finalAdvanceSearchData, setFinalAdvanceSearchData] = useState(null);
  const [isAction, setIsAction] = useState(false);
  const tableRef = createRef();

  const [data, setData] = useState({
    startDate: moment().add(-30, "days").format("YYYY MM DD"),
    endDate: moment().format("YYYY MM DD"),
  });
  const dispatch = useDispatch();

  // console.log(completeData);

  const filterDataDaysLogic = (pdata) => {
    return pdata
      ? pdata.filter((ele) => {
          if (selectValue === "Last 30 Days") {
            return (
              moment(data.startDate).format("YYYY-MM-DD") <=
                moment(ele.createdAt).format("YYYY-MM-DD") &&
              moment(data.endDate).format("YYYY-MM-DD") >=
                moment(ele.createdAt).format("YYYY-MM-DD")
            );
          } else if (selectValue === "Today") {
            return (
              moment(data.startDate).format("YYYY-MM-DD") ==
                moment(ele.createdAt).format("YYYY-MM-DD") &&
              moment(data.endDate).format("YYYY-MM-DD") ==
                moment(ele.createdAt).format("YYYY-MM-DD")
            );
          } else if (selectValue === "Yesterday") {
            return (
              moment(data.startDate).format("YYYY-MM-DD") ==
                moment(ele.createdAt).format("YYYY-MM-DD") &&
              moment(data.endDate).format("YYYY-MM-DD") ==
                moment(ele.createdAt).format("YYYY-MM-DD")
            );
          } else if (selectValue === "This Week") {
            return (
              moment(data.startDate).format("YYYY-MM-DD") <=
                moment(ele.createdAt).format("YYYY-MM-DD") &&
              moment(data.endDate).format("YYYY-MM-DD") >=
                moment(ele.createdAt).format("YYYY-MM-DD")
            );
          } else if (selectValue === "Previous Week") {
            return (
              moment(data.startDate).format("YYYY-MM-DD") <=
                moment(ele.createdAt).format("YYYY-MM-DD") &&
              moment(data.endDate).format("YYYY-MM-DD") >=
                moment(ele.createdAt).format("YYYY-MM-DD")
            );
          } else if (selectValue === "Custom") {
            return (
              moment(data.startDate).format("YYYY-MM-DD") <=
                moment(ele.createdAt).format("YYYY-MM-DD") &&
              moment(data.endDate).format("YYYY-MM-DD") >=
                moment(ele.createdAt).format("YYYY-MM-DD")
            );
          }
        })
      : [];
  };

  const filterDataCrDrLogic = (pdata) => {
    if (tabValue === "All") {
      return pdata;
    } else if (tabValue === "Credit") {
      return pdata.filter((ele) => {
        // console.log("stratDate", moment(data.startDate).format("YYYY-MM-DD"));
        return ele.particulars === "credit";
      });
    } else if (tabValue === "Debit") {
      return pdata.filter((ele) => {
        return ele.particulars === "debit";
      });
    }
  };

  // console.log("check", finalData);

  const filterData = () => {
    let data = filterDataDaysLogic(finalAdvanceSearchData);
    // console.log(tabValue, data);
    setFinalData(filterDataCrDrLogic(data));
    // dispatch(loaderEndActionCreater());
  };

  const filterDataDays = () => {
    let data = filterDataCrDrLogic(finalAdvanceSearchData);
    // console.log("filtered", data);
    setFinalData(filterDataDaysLogic(data));
    // dispatch(loaderEndActionCreater());
  };

  useEffect(() => {
    filterData();
  }, [tabValue, data, finalAdvanceSearchData]);

  useEffect(() => {
    filterDataDays();
  }, [selectValue, data, finalAdvanceSearchData]);

  useEffect(() => {
    if (finalAdvanceSearchData) {
      // console.log("dispatch loader", finalData);
      dispatch(loaderEndActionCreater());
    }
  }, [finalAdvanceSearchData]);

  // console.log(memberList);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getShareApplicationMoney = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          `/api/v1/reports/shareApplicationMoney`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      let res = response.data
        .filter((ele) => {
          return ele.amount != 0 && ele.memberId !== null;
        })
        .map((ele) => {
          return {
            ...ele,
            toMemberId: ele.memberId,
            toMembershipId: ele?.memberId?.memberId,
            toMembershipName:
              ele?.memberId?.firstName + " " + ele?.memberId?.lastName,
          };
        });

      // console.log(res[0], res[1]);

      setCompleteData(res);
      setFinalAdvanceSearchData(res);
      // dispatch(loaderEndActionCreater());
      // console.log("Res", response.data.data);
    } catch (err) {
      // console.log(err.response.data.error);
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const columns = [
    {
      title: "Membership ID",
      field: "toMembershipId",
      // cellStyle: {
      //   minWidth: 190,
      // },
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.toMemberId?.memberId}`}
        </div>
      ),
      customSort: (a, b) => {
        return (a.toMembershipId || "").localeCompare(b.toMembershipId || "");
      },
    },
    {
      title: "Transaction ID",
      field: "id",
      // cellStyle: {
      //   minWidth: 190,
      // },
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.id || "").localeCompare(b.id || "");
      },
    },
    {
      title: "Member Name",
      field: "toMembershipName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.memberId?.firstName} ${rowData?.memberId?.lastName}`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) => {
        let fullName = `${rowData?.memberId?.firstName} ${rowData?.memberId?.lastName}`;
        fullName = fullName.toLowerCase();
        return fullName.indexOf(term.toLowerCase()) > -1;
      },
      customSort: (a, b) => {
        return (a.toMembershipName || "").localeCompare(
          b.toMembershipName || ""
        );
      },
      // cellStyle: {
      //   minWidth: 190,
      // },
    },
    {
      title: "Balance",
      field: "balance",
      customFilterAndSearch: (term, rowData) =>
        rowData.balance.toString().indexOf(term) === 0,
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            color: `${rowData.particulars === "debit" ? "red" : "green"}`,
          }}
        >
          {`${
            rowData.amount !== 0
              ? rowData.particulars === "debit"
                ? "-"
                : "+"
              : ""
          }${rowData.amount}`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.amount.toString().indexOf(term) === 0,
    },
    {
      title: "Date",
      field: "date",

      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData?.createdAt?.slice(0, -1)).format("DD/MM/yyyy")}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        moment(rowData?.createdAt?.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) > -1,
      dateSetting: {
        format: "dd/MM/yyyy",
      },
    },
    {
      title: "particulars",
      field: "particulars",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData?.particulars === "debit"
            ? "Paid towards sharers"
            : "Contribution added"}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData?.particulars === "debit"
          ? "paid towards sharers".indexOf(term.toLowerCase()) > -1
          : "contribution added".indexOf(term.toLowerCase()) > -1,
    },
    // {
    //   title: "Status",
    //   field: "isActve",
    //   render: (rowData) => (
    //     <div
    //       sx={{ display: "table-cell" }}
    //       style={{
    //         maxWidth: "10rem",
    //         wordBreak: "break-word",
    //         whiteSpace: "nowrap",
    //         whiteSpace: "nowrap",
    //         lineBreak: "auto",
    //         overflowX: "hidden",
    //         textOverflow: "ellipsis",
    //       }}
    //     >
    //       {/* {console.log("mylog", rowData)} */}
    //       {rowData.isActve ? "Active" : "Inactive"}
    //     </div>
    //   ),
    //   customFilterAndSearch: (term, rowData) =>
    //     rowData?.isActve
    //       ? "active".indexOf(term.toLowerCase()) === 0
    //       : "inactive".indexOf(term.toLowerCase()) === 0,
    // },
  ];

  useEffect(() => {
    dispatch(loaderStartActionCreater());
    getShareApplicationMoney();
  }, []);

  const columnsStatus = useSelector((state) => {
    // console.log(state.members.columnsStatus);
    return state.reports.shareApplicationMoney;
  });

  const setColumnsStatus = (data) => {
    // console.log(data);
    dispatch(reportDataVisibiltyActionCreater(data, "SHARE_APPLICATION_MONEY"));
  };

  return (
    <>
      <ShareApplicationMoneyHeader
        tableRef={tableRef}
        setTabValue={setTabValue}
        finalData={finalData}
        tabValue={tabValue}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        setData={setData}
      />
      <div>
        <div
          className="d-flex justify-content-end gap-2 align-items-center mb-3"
          style={{ padding: "0rem 2rem" }}
        >
          {/* {console.log(tableRef ? tableRef?.current?.state?.data : data)} */}
          <CustomSearchForReport
            data={completeData || []}
            // data={tableRef ? tableRef?.current?.state?.data : data}
            ref={tableRef}
            tableData={finalData}
            setTableData={setFinalData}
            finalAdvanceSearchData={finalAdvanceSearchData}
            setFinalAdvanceSearchData={setFinalAdvanceSearchData}
            // rangeSearchCol={[
            //   ["Total Shares", "totalShare"],
            //   ["Share Capital", "shareCapital"],
            //   ["Share Application Money", "shareApplicationMoney"],
            // ]}
            simpleSearchCol={[
              // ["State", "state"],
              ["First Name", "firstName"],
              ["Last Name", "lastName"],
              ["Membership Id", "memberId"],
              // ["District", "district"],
              // ["Taluka", "taluka"],
              // ["City", "city"],
              // ["Organisation Name", "organisation_name"],
            ]}
            columns={columns}
            isAction={isAction}
            setIsAction={setIsAction}
          />
          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>

        <ShareApplicationMoneyTable
          tableRef={tableRef}
          finalData={finalData}
          columns={columns}
          columnsStatus={columnsStatus}
        />
      </div>
    </>
  );
};
