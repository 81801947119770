import actions from "./MemberActions";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LogoutSayasActionCreate } from "../Authontication/authonticationActionCreator";
const {
  createMemberAction,
  selectedMemberAction,
  getMemberAction,
  clearSelectedMemberAction,
  memberDataVisibiltyAction,
} = actions;

const getShareForMember = async (id, dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/v1/member/shareDetails/${id}`,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // "http://localhost:8080/api/v1/dashboard"

    // console.log("response", response);

    return response.data;

    // dispatch(loaderEndActionCreater());
    // console.log("Res", response.data.data);
  } catch (err) {
    // console.log(err.response.data.error);
    // let errors = err.response.data.error;
    // dispatch(openSnackbar(errors, "error"));
    // dispatch(loaderEndActionCreater());
    if (err.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

const getAllMembers = async (dispatch, token) => {
  try {
    // let token = window.localStorage.getItem("sayasLoginToken");
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/v1/member`,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    // "http://localhost:8080/api/v1/dashboard"

    // console.log("response", response);

    return response?.data;

    // dispatch(loaderEndActionCreater());
    // console.log("Res", response.data.data);
  } catch (err) {
    // console.log(err?.response?.data?.error);
    // let errors = err.response.data.error;
    // dispatch(openSnackbar(errors, "error"));
    // dispatch(loaderEndActionCreater());
    if (err.request.status === 401) {
      dispatch(LogoutSayasActionCreate());
    }
  }
};

const getShareForMemberWithId = (id, token) => {
  // console.log("1", id);
  return async (dispatch) => {
    let data = await getShareForMember(id, dispatch, token);
    try {
      dispatch(getMemberAction(data));
    } catch (err) {
      console.log(err.response);
    }
  };
};

const memberActionCreator = (formData) => {
  // console.log("memberActionCreator 1", formData);
  return async (dispatch) => {
    try {
      dispatch(createMemberAction(formData));
    } catch (err) {
      console.log(err.response);
    }
  };
};

const selectedMemberRows = (selectedData,compData) => {
  // console.log("selectedMemberRows 1", selectedData);
  return async (dispatch) => {
    try {
      dispatch(selectedMemberAction(selectedData,compData));
    } catch (err) {
      console.log(err);
    }
  };
};

const clearSelectedMemberRows = () => {
  // console.log("1", selectedData);
  return async (dispatch) => {
    try {
      dispatch(clearSelectedMemberAction());
    } catch (err) {
      console.log(err);
    }
  };
};

const memberDataActionCreater = (token) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    let data = await getAllMembers(dispatch, token);
    try {
      dispatch(selectedMemberAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};

const memberDataVisibiltyActionCreater = (data) => {
  // console.log("1", memberDataActionCreater);
  return async (dispatch) => {
    try {
      dispatch(memberDataVisibiltyAction(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export {
  memberActionCreator,
  selectedMemberRows,
  getShareForMemberWithId,
  memberDataActionCreater,
  clearSelectedMemberRows,
  memberDataVisibiltyActionCreater,
};
