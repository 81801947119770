import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Link, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import { getTableDetails } from "./../../Uitls/Utils";
import { BASE_URL } from "./../../../Components/URL/Url";
import styles from "../users.module.css";
import CSVReader from "react-csv-reader";
import axios from "axios";
import DialoguePriview from "./../../../Components/Dialogs/Dialogs";
import pincodeDirectory from "india-pincode-lookup";
import Help from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import ExportTable from "../../../Components/Export/ExportTable";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import validator from "validator";

export default function FileUploadDialogue(props) {
  const { open, setOpen, setData, dataLength, setConflict } = props;
  const dispatch = useDispatch();

  const [uploadData, setUploadData] = useState([]);
  const [error, setError] = useState([]);
  const [pinError, setPinError] = useState([]);
  const [downloadFile, setDownloadFile] = React.useState(false);
  const [roles, setRoles] = useState([]);

  // download template imeplementation
  const [downloadURL, setDownloadURL] = useState();
  const getConfigData = useSelector((state) => {
    return state.configuration.uploadTemplate;
  });

  const getConfigDataRoles = useSelector((state) => {
    return state.configuration.roles?.map((e) => {
      return e.type;
    });
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    let newURL = getConfigData
      ? getConfigData.filter((e) => {
          return e.type === "userImport";
        })
      : "";

    setDownloadURL(newURL[0] ? newURL[0].fileURL : "");
  }, [getConfigData]);
  // console.log(downloadURL);
  // download template imeplementation

  const handleClose = () => {
    setOpen(false);
    setUploadData([]);
  };

  const handleForce = (data, fileInfo) => {
    // console.log("Change File!!", data);
    setUploadData([]);
    setUploadData(data);
  };

  // console.log(uploadData);

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header,
  };

  // const myFunction = (uploadData) => {
  //   for (let i = 0; i <= uploadData.length; i++) {
  //     let checkPin = pincodeDirectory.lookup(uploadData[i]?.pincode);
  //     if (
  //       checkPin &&
  //       checkPin[0]?.stateName !== uploadData[i]?.state?.toUpperCase()
  //     ) {
  //       dispatch(openSnackbar(`Error: Pincode and State not match`, "error"));
  //     } else if (
  //       !(
  //         uploadData[i].phone?.toString().length == 10 &&
  //         // uploadData[i].pincode?.toString().length == 6 &&
  //         /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
  //           uploadData[i].email
  //         )
  //       )
  //     ) {
  //       if (
  //         !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
  //           uploadData[i].email
  //         )
  //       ) {
  //         dispatch(
  //           openSnackbar(
  //             `Error: Email is Invalid ${uploadData[i].email}`,
  //             "error"
  //           )
  //         );
  //       } else if (!uploadData[i].pincode) {
  //         dispatch(openSnackbar(`Error: Pincode is Required`, "error"));
  //       } else if (uploadData[i].pincode?.toString().length !== 6) {
  //         dispatch(
  //           openSnackbar(
  //             `Error: Pincode Length must be 6 digit ${uploadData[i].pincode}`,
  //             "error"
  //           )
  //         );
  //       } else if (!uploadData[i].phone) {
  //         dispatch(openSnackbar(`Error: Phone Number is Required`, "error"));
  //       } else if (uploadData[i].phone?.toString().length !== 10) {
  //         dispatch(
  //           openSnackbar(
  //             `Error: Phone Number Length must be 10 digit  ${uploadData[i].phone}`,
  //             "error"
  //           )
  //         );
  //       }
  //       break;
  //     }
  //   }
  // };

  const handleUploadFile = async () => {
    setOpen(false);
    let a = uploadData.map(
      (data) =>
        parseInt(data.phone)?.toString().length == 10 &&
        validator.isMobilePhone(data.phone + "", [
          "en-IN",
          { strictMode: true },
        ]) &&
        // data.pincode?.toString().length == 6 &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email) &&
        !/[^a-zA-Z]/.test(data.firstName) &&
        !/[^a-zA-Z]/.test(data.middleName) &&
        !/[^a-zA-Z]/.test(data.lastName) &&
        getConfigDataRoles?.includes(data.role)
    );
    // console.log(a);

    // let b = uploadData.map((data) => {
    //   let checkPin = pincodeDirectory.lookup(data.pincode);

    //   if (checkPin && checkPin[0]?.stateName == data?.state?.toUpperCase()) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    let newUploadData = uploadData.map((ele) => {
      console.log({
        teams: ele.teams ? ele.teams.split(",") : [],
        activityStatus1: ele.activityStatus == "active",
      });
      return {
        ...ele,
        teams: ele.teams ? ele.teams.split(",") : [],
        activityStatus: ele.activityStatus.toString().toLowerCase() == "active",
      };
    });

    const dataUpdate = newUploadData.filter((ele, i) => {
      return a[i];
    });

    const remainingDataUpdate = newUploadData.filter((ele, i) => {
      return !a[i];
    });
    // console.log("dataUpdate", dataUpdate);

    const newErrorList = remainingDataUpdate.map((item, i) => {
      let ismail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        item.email
      );
      let isPhone = validator.isMobilePhone(item.phone + "", [
        "en-IN",
        { strictMode: true },
      ]);
      let isFirst = !/[^a-zA-Z]/.test(item.firstName) ? true : false;
      let isMid = !/[^a-zA-Z]/.test(item.middleName) ? true : false;
      let isLast = !/[^a-zA-Z]/.test(item.lastName) ? true : false;
      return {
        ...item,
        [`error`]: `Please insert a valid ${!ismail ? "email address" : ""}${
          !isPhone ? " - phone number" : ""
        }${!isFirst ? " - First Name" : ""}${!isMid ? " - Middle Name" : ""}${
          !isLast ? " - Last Name" : ""
        }${!getConfigDataRoles?.includes(item.role) ? " - Role" : ""}`,
      };

      // item.error = `Please insert a valid ${!ismail ? "email address" : ""}${
      //   !ismail && !isPhone ? " ,and " : ""
      // }${!ismail ? "phone number" : ""}`;
    });

    // console.log("newErrorList", newErrorList);

    // remainingDataUpdate =
    //   remainingDataUpdate.length > 0
    //     ? remainingDataUpdate.map((ele) => {
    //         let ismail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
    //           ele.email
    //         );
    //         let isPhone = ele.phone?.toString().length === 10 ? true : false;
    //         return {
    //           ...ele,
    //           error: `Please insert a valid ${!ismail ? "email address" : ""}${
    //             !ismail && !isPhone ? " ,and " : ""
    //           }${!ismail ? "phone number" : ""}`,
    //         };
    //       })
    //     : [];

    // console.log("new file data with update", a, dataUpdate);

    let newData = dataUpdate;
    //.filter(
    //   (item, i) =>
    //     (item.userId = "U" + ("0000000" + (dataLength + 1 + i)).slice(-7))
    // );

    // console.log("import data", newData[0].teams.split(","));

    // newData = newData.map((ele) => {
    //   console.log({
    //     teams: ele.teams ? ele.teams.split(",") : [],
    //     activityStatus1: ele.activityStatus == "active",
    //   });
    //   return {
    //     ...ele,
    //     teams: ele.teams ? ele.teams.split(",") : [],
    //     activityStatus: ele.activityStatus == "active",
    //   };
    // });

    // console.log("new file data with update", a, newData);

    ////upload file
    // if (!a.includes(false)) {
    ////checktoken
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      // "http://localhost:8080" + `/api/v1/users/addMultiple`,
      // console.log("Start APi");

      // console.log("newData", newData);

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/v1/users/addMultiple`,
        newData,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log("End APi");

      if (setConflict) setConflict(true);
      getTableDetails(setData, `/api/v1/users`);
      // response.data?.success &&
      dispatch(openSnackbar("Data Imported Successfully", "success"));

      // console.log([...newErrorList, ...response.data.errUsers]);

      const JsonFields = [
        "firstName",
        "middleName",
        "lastName",
        "email",
        "phone",
        "role",
        "teams",
        "activityStatus",
        "error",
      ];

      var csvStr = JsonFields?.join(",") + "\n";

      [...newErrorList, ...response.data.errUsers].forEach((element) => {
        // console.log("element.teams", element.teams);
        let firstName = element.firstName;
        let middleName = element.middleName;
        let lastName = element.lastName;
        let email = element.email;
        let phone = element.phone;
        let role = element.role;
        let teams = element?.teams ? element?.teams?.join(" / ") : ""; // CHANGED FROM STRING TO ARRAY
        let error = element.error;
        let activityStatus = element.activityStatus ? "Active" : "Inactive";

        csvStr +=
          firstName +
          "," +
          middleName +
          "," +
          lastName +
          "," +
          email +
          "," +
          phone +
          "," +
          role +
          "," +
          teams +
          "," +
          activityStatus +
          "," +
          error +
          "\n";
      });

      // console.log("Date");

      // console.log("create csv done", [
      //   ...newErrorList,
      //   ...response.data.errUsers,
      // ]);

      if ([...newErrorList, ...response.data.errUsers]?.length > 0) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(csvStr);
        hiddenElement.target = "_blank";
        hiddenElement.download = "User_Error_Log.csv";
        hiddenElement.click();
      }
    } catch (err) {
      console.log("Error", err);
      // let errors = err.response.data;
      if (err?.response?.status == 400) {
        if (err?.response?.data)
          dispatch(openSnackbar(err?.response?.data, "error"));
      }
      // dispatch(openSnackbar(`${err.response.data}`, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
        // console.log("add multiple users error 401");
      }
    }
    // } else {
    //   myFunction(uploadData);
    //   setUploadData([]);
    // }

    handleClose();
  };

  const SelectFile = () => {
    dispatch(openSnackbar("Select CSV File", "error"));
  };

  React.useEffect(() => {
    // console.log("uploadData", uploadData);

    let a = uploadData.map(
      (data) =>
        parseInt(data.phone)?.toString()?.length == 10 &&
        validator.isMobilePhone(data.phone + "", [
          "en-IN",
          { strictMode: true },
        ]) &&
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email) &&
        !/[^a-zA-Z]/.test(data.firstName) &&
        !/[^a-zA-Z]/.test(data.middleName) &&
        !/[^a-zA-Z]/.test(data.lastName) &&
        getConfigDataRoles?.includes(data.role)
    );
    setError(a);

    // let b = uploadData.map((data) => {
    //   let checkPin = pincodeDirectory.lookup(data.pincode);
    //   if (checkPin && checkPin[0]?.stateName == data?.state?.toUpperCase()) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    // setPinError(b);
  }, [uploadData]);

  // useEffect(async () => {
  //   try {
  //     const response = await axios.get(BASE_URL + `/api/v1/configuration/`);
  //     console.log(response.data.data[0].uploadTemplate[0].fileURL);
  //     setDownloadFile(response.data.data[0].uploadTemplate[0].fileURL);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="m-0 p-2">
          <label id={styles.userLabel}>Upload File</label>
        </DialogTitle>
        <hr className="m-0" />
        <div style={{ paddingLeft: "150px" }}>
          <DialogContent className="pb-0">
            <div className="d-flex align-items-center">
              <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={handleForce}
                // onClick={setUploadData([])}
                // onFileLoaded={(data, fileInfo) => {
                //   console.log("Change File!!");
                //   setUploadData(data);
                // }}
                parserOptions={papaparseOptions}
              />

              {/* ///download template */}
              {downloadURL === "" ? (
                ""
              ) : (
                <Tooltip
                  title={`Click To Download Template`}
                  arrow
                  placement="top"
                >
                  <IconButton>
                    {/* {console.log(downloadURL ? downloadURL.split("/")[3].substring(13): "")} */}
                    <Help
                      style={{ fontSize: "20px" }}
                      onClick={() => {
                        window.location.href = downloadURL;
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </DialogContent>

          <DialogContent className="m-0 pt-0">
            {uploadData.length !== 0 ? (
              <div className="mt-2" style={{ paddingLeft: "50px" }}>
                <DialoguePriview
                  data={uploadData}
                  // columns={columns}
                  error={error}
                  pinError={pinError}
                />
              </div>
            ) : null}
          </DialogContent>
        </div>

        <hr className="m-0" />
        <DialogContent className="p-0 pt-2 pb-3">
          <div className="d-flex justify-content-end align-items-center">
            <Button id={styles.cancelFile} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              id={styles.fileUploadBtn}
              onClick={uploadData.length == 0 ? SelectFile : handleUploadFile}
            >
              Upload
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
