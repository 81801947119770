import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import axios from "axios";
import { Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { loaderEndActionCreater, loaderStartActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { deleteConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";

const ConfigInput = (props) => {
  const { setSelectedData, configType, i, element, setConflict } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  // console.log(configType);
  // console.log(element);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const feature = {
    fontSize: "30px",
    margin: "0 5px",
  };

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const deleteConfiguration = async (id, data,element) => {
    try {
      // console.log("deleteConfiguration", id, data);
       ////checktoken
    // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/configuration/delete" +
          `/${id}`,
        data,{
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // alert(response.statusText + " Delete Configuration Successfully");
      dispatch(openSnackbar("Field deleted successfully", "success"));
      dispatch(deleteConfiguationActionCreater(id,data,element))
      setConflict(true);
    } catch (e) {
      // console.log(e);
      // alert(e);
      dispatch(openSnackbar("Field deletion successfully", "error"));
      dispatch(loaderEndActionCreater());
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }

    }
  };

  useEffect(() => {
    setData(element);
  }, [element, configType]);

  const handleEdit = () => {
    setSelectedData(element);
  };

  const handleDelete = () => {
    let newData = {
      type: configType.dataKey,
    };
    if (window.confirm("Confirm to delete")) {
      // console.log(newData);
      dispatch(loaderStartActionCreater());
      deleteConfiguration(element.id, newData,element);
    }else{
      // dispatch(openSnackbar("Delete Configuration Cancelled", "error"));

    }
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div
      className="d-flex align-items-center"
      style={{
        width: "47%",
      }}
    >
      <GeneralInputField
        data={data}
        fullWidth
        width={"70%"}
        setData={setData}
        dataKey={"title"}
        label={configType.label + " " + (i + 1)}
        // place={configType}
        value={element.type}
        disabled
      />

      <div className="d-flex align-items-center " style={{ width: "30%" }}>
        <div style={{ width: "30%" }} onClick={handleEdit}>
          <IconButton aria-label="delete" size="large">
            <EditTwoToneIcon style={feature} />
          </IconButton>
        </div>
        <div style={{ width: "30%" }} onClick={handleDelete}>
          {" "}
          <IconButton aria-label="delete" size="large">
            <DeleteTwoToneIcon style={feature} />
          </IconButton>
        </div>
        <div
          style={{ width: "30%" }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {" "}
          <IconButton aria-label="delete" size="large">
            <InfoTwoToneIcon style={{ fontSize: "30px" }} />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div
              style={{
                padding: ".5rem",
                fontSize: "1.1rem",
                maxWidth: "20rem",
                fontStyle: "none",
              }}
            >
              {element ? element.description : "data not available"}
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default ConfigInput;
