import {
  ADD_DATA,
  UPDATE_DATA,
  UPDATE_TABLE_ROW_DATA,
} from "./shareCapitalConstants";

const actions = {
  addFormData: (data) => {
    // console.log("2",data);
    return {
      type: ADD_DATA,
      data,
    };
  },

  updateFormData: (data) => {
    // console.log("2 updateFormData Action",data);
    return {
      type: UPDATE_DATA,
      data,
    };
  },

  updateTableRowData: (data) => {
    // console.log("2 updateTableRowData Action", data);
    return {
      type: UPDATE_TABLE_ROW_DATA,
      data,
    };
  },

  shareCapitalVisibiltyAction: (data) => {
    // console.log("memberDataAction", data);
    return {
      type: "COLUMNSTATUS",
      data,
    };
  },
};

export default actions;
