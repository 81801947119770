import React, { useEffect, useState } from "react";
import DataTable from "../../Components/Table";
import { useHistory, useRouteMatch } from "react-router";
import axios from "axios";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
const CommunicationTable = (props) => {
  const {
    openModal,
    setOpenModal,
    setCurrentRowData,
    setTableUpdate,
    tableUpdate,
    tabValue,
    columns,
    columnsStatus,
    setData,
    data,
    tableRef,
  } = props;

  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getCommunicationData = async () => {
    try {
      ////checktoken
      dispatch(loaderStartActionCreater());
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/communication",
        {
          headers: {
            Authorization: `Bearer ` + `${getAuthData}`,
          },
        }
      );
      // console.log(response.data.data);
      setData(response.data.data);
      setTableUpdate(false);
      dispatch(loaderEndActionCreater());
    } catch (e) {
      console.log(e);
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      dispatch(loaderEndActionCreater());
    }
  };

  useEffect(() => {
    if (getAuthData) getCommunicationData();
  }, [tableUpdate, getAuthData]);

  useEffect(() => {
    if (data) dispatch(loaderEndActionCreater());
  }, [data]);

  const options = {
    search: true,
    selection: false,
    filtering: true,
    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 16,
      fontFamily: "Mulish",
      padding: "8px 5px",
    },

    cellStyle: {
      padding: "4px 10px",
    },

    maxBodyHeight: "55vh",

    pageSize: 10,
    searchFieldStyle: {
      marginRight: "6vw",
      width: "15vw",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
  };

  return (
    <>
      <DataTable
        tableRef={tableRef}
        title=""
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={
          data
            ? data.filter((e) => {
                return (
                  tabValue === "All Communication" ||
                  tabValue === e.communicationType
                );
              })
            : []
        }
        options={options}
        onRowClick={(event, rowData) => {
          setOpenModal(true);
          setCurrentRowData(rowData);
        }}
      />
    </>
  );
};

export default CommunicationTable;
