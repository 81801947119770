import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Switch } from "@mui/material";
import DatePickers from "./DatePickers";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import axios from "axios";
import _ from "lodash";
import UUID from "uuid-int";
import { useDispatch, useSelector } from "react-redux";
// import { selectedUserRows } from "../../../Redux/Users/actionCreator";

import MemberInput from "./MemberInput";
// import { selectedMemberRows } from "../../../Redux/Members/actionCreator";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import styles from "../member.module.css";
import userStyles from "../../Users/UserInput.module.css";
import UsersCityField from "./../../Users/UsersForm/UsersCityField";
import UserStateField from "./../../Users/UsersForm/UserStateField";
import { BASE_URL } from "./../../../Components/URL/Url";
import pincodeDirectory from "india-pincode-lookup";
import MemberSelectField from "./MemberSelectField";
import {
  getDashboardTransfer,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../../Redux/Dashboard/dashboardActionCreator";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../../Redux/Configuration/configurationActionCreator";
import { city } from "./../../../Components/Data/maharashtraCity";
import { getStateDistrictList } from "../../../Redux/StateDistrictList/stateDistrictActionCreator";
// import statesDistrictsTalukas from "../../../constants/statesDistrictTalukas.json";
import statesDistrictsTalukas from "../../../../src/Components/Data/statesDistrictTalukas.json";
import { selectedMemberRows } from "../../../Redux/Members/actionCreator";

// const id = 0;
// const generator = UUID(id);
// const uuid = generator.uuid();
// const initialData = {
//   mobileSignUpStatus: false,
//   activityStatus: false,
//   date: Date.now(),
//   memberId: uuid.toString().slice(9, 16),
//   member: "",
//   shareholder: "",
//   firstName: "",
//   middleName: "",
//   lastName: "",
//   phone: "",
//   email: "",
//   addressLine_1: "",
//   addressLine_2: "",
//   city: "Thane",
//   state: "Maharashtra",
//   pincode: "",
//   nominee_name: "",
//   organisation_name: "",
// };

const CreateMemberForm = (props) => {
  const { memberDataLength } = props;
  const [allTalukas, setAllTalukas] = useState();

  const states = _.keysIn(statesDistrictsTalukas);

  const [initialValue, setInitialValue] = useState({
    mobileSignUpStatus: false,
    activityStatus: false,
    date: Date.now(),
    // memberId: "ID" + ("0000000" + (memberDataLength + 1)).slice(-7),
    memberId: "",
    member: "",
    shareholder: "Individual",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    addressLine_1: "",
    addressLine_2: "",
    city: "",
    state: "",
    district: "",
    // taluka: city.filter((e) => e.name === "Thane")[0].tahasil[0],
    taluka: "",
    pincode: "",
    nominee_name: "",
    organisation_name: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  // add List of state District
  // const stateDistrict = useSelector((state) => {
  //   console.log("craete Form ::", state.stateDistrictList);
  //   return state.stateDistrictList;
  // });

  // console.log("stateDistrict In memebr create Form::", stateDistrict);

  // useEffect(async () => {
  //   if (!stateDistrict) await dispatch(getStateDistrictList(dispatch));
  // }, []);

  const [date, setDate] = useState(initialValue.date);
  const [cityName, setCityName] = useState(null);

  ///fetch config data
  const [memberType, setMemberType] = useState([]);
  const [shareType, setShareType] = useState([]);

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  // console.log("date", date);
  let errors = {};
  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,

    onSubmit: async (values) => {
      // console.log("create member form", values);

      const data = { ...values, date: date };

      try {
        ////checktoken
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/member`, data, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        dispatch(selectedMemberRows(undefined, []));

        history.push("/admin/members");
        dispatch(openSnackbar("Member Created Successfully!", "success"));
        dispatch(getDashboardTransfer(getAuthData));
        dispatch(getTop10ShareHolders(getAuthData));
        dispatch(getTotalShareHoldersMembers(getAuthData));
      } catch (err) {
        let errors = err.response.data;
        console.log("duplicate Phone!!", err.response.data);
        let DupPhone = err.response.data;
        if (DupPhone) dispatch(openSnackbar(DupPhone, "error"));
        let error =
          "Duplicate Field : " +
          err?.response?.data?.split(": {")[1].slice(0, -1);
        // console.log("Error", err.response.data);
        if (error) dispatch(openSnackbar(error, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    },
    validate: (values) => {
      // console.log("Values", values );
      // console.log("initial", initialValue );

      if (!values.memberId) {
        errors.memberId = "Required";
      }
      if (!values.member) {
        errors.member = "Required";
      }
      if (!values.shareholder) {
        errors.shareholder = "Required";
      }
      // else if (!/^[A-Za-z]+$/.test(values.member)) {
      //   errors.member = "Only Alphabets";
      // }
      // if (!values.organisation_name) {
      //   errors.organisation_name = "Required";
      // }
      // else if (!/^[A-Za-z]+$/.test(values.organisation_name)) {
      //   errors.organisation_name = "Only Alphabets";
      // }
      // if (!values.nominee_name) {
      //   errors.nominee_name = "Required";
      // }
      // else if (!/^[A-Za-z]+$/.test(values.nominee_name)) {
      //   errors.nominee_name = "Only Alphabets";
      // }
      if (!values.state) {
        errors.state = "Required";
      }

      if (values.state === "") {
        errors.state = "Required";
      }

      if (values.district === "") {
        errors.district = "Required";
      }

      if (values.taluka == "") {
        errors.taluka = "Required";
      }

      // else if (!/^[A-Za-z]+$/.test(values.city)) {
      //   errors.city = "Only Alphabets";
      // }

      if (!values.firstName) {
        errors.firstName = "Required";
      }
      // if (!values.middleName) {
      //   errors.middleName = "Required";
      // }
      if (!values.lastName) {
        errors.lastName = "Required";
      }

      if (!values.addressLine_1) {
        errors.addressLine_1 = "Required";
      }

      if (!values.nominee_name) {
        errors.nominee_name = "Required";
      }

      // else if (!/^[A-Za-z]+$/.test(values.firstName)) {
      //   errors.firstName = "Only Alphabets";
      // }

      // if (
      //   values.email != "" &&
      //   !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      // ) {
      //   errors.email = "Invalid email";
      // }

      if (!values.phone) {
        errors.phone = "Required";
      } else if (
        !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone)
      ) {
        errors.phone = "Invalid Phone";
      }

      // let checkPin = pincodeDirectory.lookup(values.pincode);
      // console.log(checkPin);

      if (!values.pincode) {
        errors.pincode = "Required";
      } else if (!/^[1-9][0-9]{5}$/.test(values.pincode)) {
        errors.pincode = "Invalid Pin Code";
      }

      // else if (checkPin[0]?.stateName !== values.state.toUpperCase()) {
      //   errors.pincode = "Invalid Pin Code";
      // }
      return errors;
    },
  });

  React.useEffect(() => {
    setInitialValue({
      mobileSignUpStatus: false,
      activityStatus: false,
      date: Date.now(),
      // memberId: "ID" + ("0000000" + (memberDataLength + 1)).slice(-7),
      memberId: "",
      member: memberType ? memberType[0] : "",
      shareholder:
        shareType?.lenght > 0
          ? shareType?.includes("Individual")
            ? "Individual"
            : ""
          : "",
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      email: "",
      addressLine_1: "",
      addressLine_2: "",
      city: "",
      district: "",
      state: "",
      taluka: "",
      pincode: "",
      nominee_name: "",
      organisation_name: "",
    });
  }, [memberType, shareType]);

  useEffect(() => {
    // console.log(getConfigData);
    setMemberType(
      getConfigData?.memberType?.map((e) => {
        return e.type;
      })
    );
    setShareType(
      getConfigData?.shareholderType?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  // useEffect(() => {
  //   if (formik.values.district)
  //     formik.setFieldValue(
  //       "taluka",
  //       city.filter((e) => e.name === formik.values.district)[0].tahasil[0]
  //     );
  // }, [formik.values.district]);

  useEffect(() => {
    formik.setFieldValue("district", "");
    formik.setFieldValue("taluka", "");
  }, [formik.values.state]);

  // console.log("district", formik.values.district);

  useEffect(() => {
    setInitialValue({
      ...initialValue,
      member: memberType && memberType.length > 0 ? memberType[0] : "",
      shareholder:
        shareType?.lenght > 0
          ? shareType?.includes("Individual")
            ? "Individual"
            : ""
          : "Individual",
    });

    // console.log("Member Type", memberType);

    // console.log("Share Type", shareType);
  }, [memberType]);

  // console.log(shareType);
  //how to use
  // shareTypes.length > 0 ? shareTypes : []

  ////
  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);

  // const formattedKeysStateObj = statesDistrictsTalukas[
  //   formik.values.state
  // ]?.map((item) => {
  //   return _.mapKeys(item, (value, key) => v.trim(key));
  // });

  // const districtBasedTalukas = formattedKeysStateObj.filter(
  //   (item) => item["DISTRICT"] === formik.values.district
  // );

  // const talukas = districtBasedTalukas.map((item) => item["TEHSIL"]);

  // const talukas = [ ...new Set(formattedKeysStateObj.map((item) => item["TEHSIL"])),
  // ];

  useEffect(() => {
    // console.log("Hit taluka in advance Search::");
    let talukas;
    if (statesDistrictsTalukas[`${formik.values.state}`]) {
      talukas = statesDistrictsTalukas[`${formik.values.state}`]?.filter(
        (e) => {
          return e["DISTRICT"] === formik.values.district;
        }
      );
    } else {
      talukas = [];
    }

    talukas = talukas?.map((ele) => {
      // console.log("In taluka map::", ele);
      return ele["TEHSIL"];
    });
    // console.log(talukas || []);
    setAllTalukas([...(talukas || [])]);
  }, [formik.values.district]);

  // console.log(allTalukas);

  // console.log("formik values", formik.values);

  return (
    <div
      className="m-5"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          // alert("this.state.message");
          formik.handleSubmit();
        }
        return;
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "25px 50px",
            width: "100%",
          }}
        >
          <DatePickers
            label="Date"
            name="date"
            formik={formik}
            setDate={setDate}
          />
          <div className="d-flex align-items-center">
            <label className={styles.switchLabel}>Activity Status</label>
            <Switch
              name="activityStatus"
              checked={formik.values.activityStatus}
              onChange={formik.handleChange}
            />
          </div>
          <div className="d-flex align-items-center">
            <label className={styles.switchLabel}>Mobile App</label>
            <Switch
              name="mobileSignUpStatus"
              checked={formik.values.mobileSignUpStatus}
              onChange={formik.handleChange}
            />
          </div>

          <MemberInput label="Member ID" name="memberId" formik={formik} />

          {/* <MemberInput label="Member" name="member" formik={formik} /> */}
          <MemberSelectField
            label="Member"
            name="member"
            formik={formik}
            items={memberType?.length > 0 ? memberType : []}
          />

          {/* <MemberInput label="Shareholder" name="shareholder" formik={formik} /> */}
          <MemberSelectField
            label="Shareholder"
            name="shareholder"
            formik={formik}
            items={shareType?.length > 0 ? shareType : []}
            // items={["Individual", "Institutional", "NA"]}
          />
          <MemberInput label="First Name" name="firstName" formik={formik} />
          <MemberInput label="Middle Name" name="middleName" formik={formik} />
          <MemberInput label="Last Name" name="lastName" formik={formik} />
          <MemberInput label="Email" name="email" formik={formik} />
          <MemberInput label="Phone" name="phone" formik={formik} />
          <MemberInput
            label="Nominee Name"
            name="nominee_name"
            formik={formik}
          />
          <MemberInput
            label="Organisation Name"
            name="organisation_name"
            formik={formik}
          />
          <UsersCityField
            label="State"
            name="state"
            states={states}
            formik={formik}
            setCityName={setCityName}
          />
          {/* {console.log("cityName", formik.values)} */}

          {/* {
            console.log("State",initialV)
          }
          */}
          {/* {formik.values.state ? (
            <> */}
          <UserStateField
            label="District"
            name="district"
            formik={formik}
            cityName={cityName}
          />
          <MemberSelectField
            label="Taluka"
            name="taluka"
            formik={formik}
            items={allTalukas}
          />
          <MemberInput label="City/Village" name="city" formik={formik} />
          {/* <MemberInput label="City" name="city" formik={formik} />
            </>
          ) : (
            <MemberInput label="City" name="city" formik={formik} />
          )} */}

          <MemberInput label="Pin Code" name="pincode" formik={formik} />
          <MemberInput
            label="Address Line 1"
            name="addressLine_1"
            formik={formik}
          />
          <MemberInput
            label="Address Line 2"
            name="addressLine_2"
            formik={formik}
          />
        </div>
        <div align="right" style={{ marginRight: "45px", marginTop: "40px" }}>
          <button
            id={userStyles.cancelBtn}
            onClick={() => {
              dispatch(selectedMemberRows(undefined, []));
              // dispatch(selectedMemberRows([]));
              history.push("/admin/members");
            }}
          >
            Cancel
          </button>
          <button id={userStyles.submitBtn} type="submit">
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateMemberForm;
