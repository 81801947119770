import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UploadTemplate } from "./UploadTemplate";
export const ConfigurationUploadTemplate = () => {
  const [data, setData] = useState([]);

  const infoMessage = {
    shareTranferMoneyImport: "this is shareTranfer info",
    userImport: "this is User Upload info",
    memberImport: "this is member Upload info",
  };
  const getConfigData = useSelector((state) => {
    return state.configuration.uploadTemplate;
  });

  useEffect(() => {
    setData(getConfigData);
  }, [getConfigData]);

  // console.log(data);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <UploadTemplate
        dataVal={data?.filter((e) => {
          return e.type === "shareApplicationMoney";
        })}
        completeData={data}
        place={"Share application money Import"}
        dataKey={"shareApplicationMoney"}
        infoMes = {infoMessage.shareTranferMoneyImport}
      />
      <UploadTemplate
        dataVal={data?.filter((e) => {
          return e.type === "userImport";
        })}
        completeData={data}
        place={"User Import"}
        dataKey={"userImport"}
        infoMes = {infoMessage.userImport}
      />
      <UploadTemplate
        dataVal={data?.filter((e) => {
          return e.type === "memberImport";
        })}
        completeData={data}
        place={"Member Import"}
        dataKey={"memberImport"}
        infoMes = {infoMessage.memberImport}
      />
    </div>
  );
};
