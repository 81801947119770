import {
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import { Box, Popover } from "@mui/material";
import React, { useEffect, useState, useRef, memo, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loaderStartActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import CustomSlider from "../CustomSlider/CustomSlider";
import { MultipleElasticSearch } from "./multipleAdvanceSearch ";
import { MultipleSearch } from "./multipleSearch";
import StateCity from "../Data/StateCity";
import { city } from "../Data/maharashtraCity";
import _ from "lodash";
import statesDistrictsTalukas from "../../../src/Components/Data/statesDistrictTalukas.json";

const CustomSearch = forwardRef((props, ref) => {
  const {
    data,
    setTableData,
    setFinalAdvanceSearchData,
    rangeSearchCol,
    columns,
    setOptionVal,
    simpleSearchCol,
    tableData,
    moduleName,
    isAction,
    setIsAction,
    finalAdvanceSearchData,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownData, setDropdownData] = useState();
  const [searchData, setSearchData] = useState({});
  const [stateList, setStateList] = useState();
  const [maharashtraDistList, setMaharashtraDistList] = useState();
  const [maharashtraTalukaList, setMaharashtraTalukaList] = useState();
  const [memberType, setMemberType] = useState([]);
  const [shareHolderType, setShareHolderType] = useState([]);
  const states = _.keysIn(statesDistrictsTalukas).map((state) => state);

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  // console.log(ref?.current?.state?.data);
  // console.log("simpleSearchCol!!", simpleSearchCol);
  // console.log("In Custom search::",data);
  //get state list

  const sliderMaxVal = (col) => {
    // let maxValue = data?.reduce((arr, a) => {
    // console.log(ref);
    // console.log(ref?.current?.state?.data);
    // let maxValue = ref?.current?.state?.data?.reduce((arr, a) => {
    let maxValue = tableData?.reduce((arr, a) => {
      return [...arr, parseInt(a[col])];
    }, []);
    // console.log(maxValue);
    if (maxValue) maxValue = Math?.max(...maxValue);
    // console.log(maxValue);
    return maxValue;
  };

  const [allVal, setAllVal] = useState(
    rangeSearchCol?.reduce((stateVal, col) => {
      stateVal[col[0]] = [0, 0];
      return stateVal;
    }, {})
  );

  const [allCheckbox, setAllCheckbox] = useState(
    rangeSearchCol?.reduce((stateVal, col) => {
      stateVal[col[0]] = false;
      return stateVal;
    }, {})
  );

  const searchVal = useRef(null);
  const dispatch = useDispatch();

  const arrowHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const arrowHandleClose = () => {
    setAnchorEl(null);
  };

  const openP = Boolean(anchorEl);
  const id = openP ? "simple-popover" : undefined;

  useEffect(() => {
    // if (ref?.current?.state?.data?.length > 0) {
    if (ref?.current?.state?.data?.length > 0) {
      let myState = rangeSearchCol?.reduce((stateVal, col) => {
        // stateVal[col[0]] = [0, sliderMaxVal(col[1])];
        stateVal[col[0]] = [0, 0];
        return stateVal;
      }, {});

      let dropData = ref?.current?.state?.data?.reduce((ans, val) => {
        let dummyDq = ans;
        simpleSearchCol?.map((ele) => {
          dummyDq[ele[1]] = dummyDq[ele[1]]?.includes(val[ele[1]])
            ? dummyDq[ele[1]]
            : dummyDq[ele[1]]
            ? [...dummyDq[ele[1]], val[ele[1]]]
            : [val[ele[1]]];
        });
        return dummyDq;
      }, {});

      // console.log("dropData", dropData);
      dropData.state = states; // state list
      dropData.district = []; //district
      dropData.taluka = []; //taluka
      dropData.member = memberType; //configuration
      dropData.shareholder = shareHolderType; //configuration
      dropData.mobileStatus = ["Enabled", "Disabled"]; //static
      dropData.activity = ["Active", "Inactive"]; //static
      setDropdownData(dropData);
      // setAllVal(myState);
    }

    // console.log("simpleSearchCol", simpleSearchCol);
    // console.log("ref", ref?.current?.state?.data);
  }, [ref, openP]);

  useEffect(() => {
    // console.log(getConfigData);
    setMemberType(
      getConfigData?.memberType?.map((e) => {
        return e.type;
      })
    );
    setShareHolderType(
      getConfigData?.shareholderType?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  const customSearch = () => {
    if (
      rangeSearchCol
        ?.filter(([colName, realCol]) => allCheckbox[colName])
        .some(([colName, realCol]) => allVal[colName][0] > allVal[colName][1])
    ) {
      dispatch(
        openSnackbar("Minimum value cannot be greater than Maximum", "error")
      );
      // } else if (
      //   rangeSearchCol
      //     .filter(([colName, realCol]) => allCheckbox[colName])
      //     .some(
      //       ([colName, realCol]) => allVal[colName][1] > sliderMaxVal(realCol)
      //     )
      // ) {
      //   dispatch(
      //     openSnackbar(
      //       "Maximum value cannot be greater than Maximum value possible",
      //       "error"
      //     )
      //   );
    } else {
      dispatch(loaderStartActionCreater());
      // console.log(ref);
      // console.log(data);
      let newData = data?.filter((item) =>
        rangeSearchCol
          ?.filter(([colName, realCol]) => allCheckbox[colName])
          .every(
            ([colName, realCol]) =>
              item[realCol] >= allVal[colName][0] &&
              item[realCol] <= allVal[colName][1]
          )
      );

      // console.log("rangeSearchCol", rangeSearchCol, allVal);
      // // advance search
      // console.log("Before filter!!", newData);
      newData = newData?.filter((ele) => {
        // console.log("in filter!!", searchData, ele, Object.keys(searchData));
        return Object.keys(searchData)?.reduce((ans, val) => {
          return (
            (searchData[`${val}`]?.length <= 0 && ans) ||
            (searchData[`${val}`]?.some((e) => {
              // console.log(
              //   "in reduce",
              //   e?.toLowerCase(),
              //   ele[`${val}`]?.toLowerCase()
              // );
              return e?.toLowerCase() === ele[`${val}`]?.toLowerCase();
            }) &&
              ans)
          );
        }, true);
      });

      arrowHandleClose();
      setTableData(newData);
      if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(newData);
      if (setOptionVal) setOptionVal("All Members");
    }
  };

  const allSearch = () => {
    if (searchVal.current?.value.trim() !== "") {
      // let newData = data?.filter((item) => {
      //   return Object.keys(item).some((key) => {
      //     if (!columns.map((col) => col.field).includes(key)) return false;

      //     let searchFun = columns.find(
      //       (col) => col.field === key
      //     )?.customFilterAndSearch;

      //     if (searchFun) console.log("mylog", key, searchFun);

      //     let boolVal = searchFun
      //       ? searchFun(searchVal.current.value, item)
      //       : String(item[key])
      //           .toLowerCase()
      //           .indexOf(searchVal.current.value.toLowerCase()) > -1;
      //     return boolVal;
      //   });
      // });

      // console.log(searchVal.current?.value);
      // let newData = ref?.current?.state?.data.filter((rowData) =>
      let newData = finalAdvanceSearchData?.filter((rowData) =>
        columns.some((col) => {
          if (col.customFilterAndSearch) {
            return col.customFilterAndSearch(searchVal.current.value, rowData);
          } else if (col.render) {
            let renderText = col.render(rowData);
            if (typeof renderText === "object")
              renderText = renderText.props.children;
            return (
              renderText
                .toLowerCase()
                .indexOf(searchVal.current.value.toLowerCase()) > -1
            );
          } else {
            return (
              rowData[col.field]
                ?.toString()
                .toLowerCase()
                .indexOf(searchVal.current.value.toLowerCase()) > -1
            );
          }
        })
      );

      setTableData(newData);
      // if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(newData);
    } else {
      setTableData(finalAdvanceSearchData);
    }
    // else if (searchVal.current?.value === "") {
    //   setTableData(data);
    //   // if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(data);
    //   if (setOptionVal) setOptionVal("All Members");
    //   customSearch();
    // }
  };

  const handleClear = () => {
    // arrowHandleClose();
    setTableData(data);
    if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(data);
    setSearchData({});
    setAllVal(
      rangeSearchCol?.reduce((stateVal, col) => {
        // stateVal[col[0]] = [0, sliderMaxVal(col[1])];
        stateVal[col[0]] = [0, 0];
        return stateVal;
      }, {})
    );
    setAllCheckbox(
      rangeSearchCol?.reduce((stateVal, col) => {
        stateVal[col[0]] = false;
        return stateVal;
      }, {})
    );
  };

  useEffect(() => {
    // console.log("In", isAction);
    customSearch();
    if (isAction) setIsAction(false);
  }, [isAction]);

  // console.log("itemList=", searchData);
  // console.log("maharashtraTalukaList", maharashtraTalukaList);

  useEffect(() => {
    // console.log("Hit state in advance Search::");
    let districts = searchData?.state?.reduce((ans, ele) => {
      return [...ans, ...statesDistrictsTalukas[`${ele}`]];
    }, []);

    districts = districts?.reduce((ans, ele) => {
      return ans?.includes(ele["DISTRICT"] || "")
        ? ans
        : [...ans, ele["DISTRICT"]];
    }, []);

    // console.log(districts || []);
    setDropdownData({ ...dropdownData, district: districts || [] });
  }, [searchData.state]);

  useEffect(() => {
    // console.log("Hit taluka in advance Search::");
    let talukas = searchData?.state?.reduce((ans, ele) => {
      return [...ans, ...statesDistrictsTalukas[`${ele}`]];
    }, []);

    talukas = searchData?.district?.reduce((ans, ele) => {
      return [
        ...ans,
        ...talukas.filter((e) => {
          return e["DISTRICT"] === ele;
        }),
      ];
    }, []);

    talukas = talukas?.map((ele) => {
      return ele["TEHSIL"];
    });
    // console.log(talukas || []);
    setDropdownData({ ...dropdownData, taluka: talukas || [] });
  }, [searchData.district]);

  return (
    <>
      <TextField
        id="outlined-search"
        className="searchBox"
        type="search"
        placeholder="Search"
        inputRef={searchVal}
        onChange={allSearch}
        InputProps={{
          endAdornment: (
            <i
              className="fa fa-caret-down"
              onClick={arrowHandleClick}
              style={{ fontSize: "20px", color: "#787878", cursor: "pointer" }}
            />
          ),
          startAdornment: (
            <i className="fa fa-search" style={{ color: "#858585" }} />
          ),
        }}
        sx={{
          backgroundColor: "#f6f6f6",
          borderRadius: "5px",
          "& input": {
            padding: "10px",
          },
          "& fieldset": {
            display: "none",
          },
        }}
      />

      {openP ? (
        <Popover
          id={id}
          open={openP}
          anchorEl={anchorEl}
          onClose={arrowHandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              width: `${simpleSearchCol ? "60rem" : "50rem"}`,
              // width: "60rem",
              overFlow: "auto",
              maxHeight: "80vh",
            }}
          >
            <div
              style={{
                display: "grid",
                gap: "10px",
                width: "100%",
                padding: "20px 30px 20px",
                alignItems: "center",
                overFlow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  padding: "10px 10px",
                  justifyContent: "space-between",
                }}
              >
                {rangeSearchCol?.map((col) => (
                  <CustomSlider
                    data={data}
                    col={col}
                    ref={ref}
                    allVal={allVal}
                    setAllVal={setAllVal}
                    allCheckbox={allCheckbox}
                    setAllCheckbox={setAllCheckbox}
                    moduleName={moduleName}
                  />
                ))}
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  overflow: "auto",
                }}
              >
                {dropdownData
                  ? simpleSearchCol?.map((ele) => {
                      {
                        /* ele[1] === "district" ? searchData.state.includes("Maharashtra") ? [...maharashtraDistList,...dropdownData[ele[1]].filter((ele) => {
                            return ele != null && ele != "";
                          })}] : regular : ele[1] === "taluka" ? searchData.state.includes("Maharashtra") ? maharashtraTalukaList[`${searchData[ele[1]]}`] : regular : regular */
                      }
                      return (
                        <MultipleElasticSearch
                          title={ele[0]}
                          setSearchData={setSearchData}
                          searchData={searchData}
                          keyValue={ele[1]}
                          // itemList={dropdownData[ele[1]].filter((ele) => {
                          //   return ele != null && ele != "";
                          // })}
                          itemList={dropdownData[ele[1]]?.filter((ele) => {
                            return ele != null && ele != "";
                          })}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>

            <hr className="m-0" />
            <div
              style={{
                padding: "20px 20px 20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={handleClear}
                  sx={{
                    bgcolor: "#0096FF",
                  }}
                >
                  Clear
                </Button>
              </div>
              <div>
                <Button onClick={arrowHandleClose}>Cancel</Button>
                <Button
                  variant="contained"
                  onClick={customSearch}
                  sx={{
                    bgcolor: "#0096FF",
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
          </Box>
        </Popover>
      ) : (
        ""
      )}
    </>
  );
});

export default memo(CustomSearch);
