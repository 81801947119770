import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DataTable from "../../Components/Table";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";

const TransactionTable = (props) => {
  const { shareApplicationMoneyTableData, shareIssueTableData, setConflict } =
    props;
  const [userRole, serUserRole] = useState("");

  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);
  const params = useParams();
  // console.log(
  //   "shareIssueTableData",
  //   shareIssueTableData.map((ele) => {
  //     let newTransaction = ele.transactionId;
  //     if (ele.fromMemberId === null) return ele;
  //     if (ele.toMemberId.id === params.id) {
  //       newTransaction[0].type = "transferred In";
  //       return { ...ele, ["transactionId"]: newTransaction };
  //     } else {
  //       newTransaction[0].type = "transferred Out";
  //       return { ...ele, ["transactionId"]: newTransaction };
  //     }
  //   })
  // );

  // console.log("shareApplicationMoneyTableData", shareApplicationMoneyTableData);
  // console.log("shareIssueTableData", shareIssueTableData);

  useEffect(() => {
    setConflict(true);
  }, []);

  const dispatch = useDispatch();

  const firstColumns = [
    {
      title: "Type",
      field: "transactionId[0].type",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${
            rowData?.fromMemberId && rowData?.fromMemberId?.id === params.id
              ? "Transferred Out"
              : rowData.isSurrendered
              ? "Surrendered"
              : rowData?.transactionId[0]?.type === "issue"
              ? "Issued"
              : "Transferred In"
          }`}
        </div>
      ),
      // cellStyle: {
      //   minWidth: 150,
      //   textTransform: "capitalize",
      // },
    },
    // {
    //   title: "Transaction ID",
    //   field: "id",
    // },
    {
      title: "Date",
      field: "createdAt",
      // type: "date",
      format: "dd/MM/yyyy",

      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData.createdAt)?.format("DD/MM/yyyy")}
        </div>
      ),
      dateSetting: {
        format: "dd/MM/yyyy",
      },
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.createdAt.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) != -1,
    },

    {
      title: "Type Of Shares",
      field: "transactionId[0].typeOfShares",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData?.isSurrendered
            ? "Surrendered"
            : rowData?.transactionId[0]?.typeOfShares}
        </div>
      ),
      customSort: (a, b) => {
        return (
          a?.isSurrendered
            ? "Surrendered"
            : a?.transactionId[0]?.typeOfShares || ""
        ).localeCompare(
          b?.isSurrendered
            ? "Surrendered"
            : b?.transactionId[0]?.typeOfShares || ""
        );
      },
    },

    {
      title: "No. of Share",
      field: "noOfShares",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            color: `${
              (rowData?.fromMemberId &&
                rowData?.fromMemberId?.id === params.id) ||
              rowData?.isSurrendered
                ? "red"
                : "green"
            }`,
          }}
        >
          {`${
            (rowData?.fromMemberId &&
              rowData?.fromMemberId?.id === params.id) ||
            rowData?.isSurrendered
              ? "-"
              : "+"
          }${rowData?.noOfShares}`}

          {/* {rowData.transactionId[0].type === "issue"
            ? rowData.transactionId[0].noOfShares
            : rowData.noOfShares} */}
        </div>
      ),
    },

    // {
    //   title: "Share Nos (From)",
    //   field: "transactionId[0].sharesFrom",
    // },
    // {
    //   title: "Share Nos (To)",
    //   field: "transactionId[0].sharesTo",
    // },
    // {
    //   title: "Share Certificate No.",
    //   field: "transactionId[0].shareCertificationNo",
    // },

    {
      title: "Status",
      field: "transactionId[0].status",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.transactionId[0]?.status ? "Success" : "Failed"}`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData?.transactionId[0]?.status ? "success" : "failed")?.indexOf(
          term?.toLowerCase()
        ) != -1,
    },
  ];

  const secondColumns = [
    {
      title: "Date",
      field: "date",
      // type: "date",
      format: "dd/MM/yyyy",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData.date)?.format("DD/MM/yyyy")}
        </div>
      ),
      dateSetting: {
        format: "dd/MM/yyyy",
      },
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.date.slice(0, -1))
          ?.format("DD/MM/yyyy")
          ?.indexOf(term) != -1,
    },
    {
      title: "Transaction ID",
      field: "id",
    },
    {
      title: "Particulars",
      field: "particulars",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "13rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData?.particulars === "debit"
            ? "Paid towards sharers"
            : "Contribution added"}
        </div>
      ),
    },
    {
      title: "Amount",
      field: "amount",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            color: `${rowData.particulars === "debit" ? "red" : "green"}`,
          }}
        >
          {`${rowData?.particulars === "debit" ? "-" : "+"}${rowData?.amount}`}
        </div>
      ),
    },
    {
      title: "Balance",
      field: "balance",
    },
  ];

  const options = {
    search: true,
    selection: false,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      padding: "10px 16px",
      whiteSpace: "nowrap",
    },

    cellStyle: {
      padding: "12px 16px",
    },

    filterCellStyle: {
      padding: "5px 16px 8px",
    },

    // paging: false,
    maxBodyHeight: "45vh",
  };

  // console.log(
  //   "shareIssueTableData In transaction::",
  //   shareIssueTableData,
  //   shareApplicationMoneyTableData
  // );

  return (
    <div
      style={{
        height: "85vh",
        overflow: "auto",
      }}
    >
      {/* <p>
        Share Transaction <i className="fa fa-caret-up" />
      </p> */}
      <DataTable
        title="Share Transaction"
        columns={firstColumns}
        data={shareIssueTableData || []}
        options={options}
        rowClick={true}
      />
      {/* <p className="pt-4">
        Share Application Money Transactions <i className="fa fa-caret-down" />
      </p> */}
      <DataTable
        title="Share Application Money Transactions"
        columns={secondColumns}
        data={shareApplicationMoneyTableData || []}
        options={options}
      />
    </div>
  );
};

export default TransactionTable;
