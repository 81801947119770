import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./member.module.css";
import MemberShareCards from "./MemberShareCards";
import { Card, CardContent, Typography } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { deleteConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getSayasAccountActionCreater } from "../../Redux/SayasAccount/sayasAccountActionCreator";
import { getDashboardTransfer, getTop10ShareHolders, getTotalShareHoldersMembers } from "../../Redux/Dashboard/dashboardActionCreator";
import { getShareForMemberWithId } from "../../Redux/Members/actionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";

export default function MemberShareApplicationMoneyForm(props) {
  const { open,setUpdate, setOpen, setConflict, totalShareApplicationMoney } = props;
  // console.log("totalShareApplicationMoney", totalShareApplicationMoney);
  // find member ID
  const params = useParams();
  // console.log(params);
  const [data, setData] = React.useState({
    number: totalShareApplicationMoney,
    name: "Share Application Amount",
  });
  const [inputVal, setInputVal] = React.useState(0);
  const dispatch = useDispatch();
  // console.log(props.open);
  const handleClose = () => {
    setOpen(false);
    setInputVal(0);
  };


  useEffect(() => {
    setData({ number: totalShareApplicationMoney, name: "Share App Money" });
  }, [params, totalShareApplicationMoney]);

  // console.log("check Data", data);

   //auth token fetch from selector
   const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const addShareApplication = async (val) => {
    try {
      // console.log(val);
       ////checktoken
    // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/addMoney",
        val,{
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      dispatch(
        openSnackbar("Add Share Application Money Successfully", "success")
      );
      setConflict(true);
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getShareForMemberWithId(params.id,getAuthData));
   
      dispatch(loaderEndActionCreater());
      setUpdate(true)
      handleClose();
    } catch (e) {
      // console.log(e.response.data.error);
      if(e?.response?.data?.error)
      dispatch(openSnackbar(`${e?.response?.data?.error}`, "error"));
      dispatch(loaderEndActionCreater());
      handleClose();
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };
  const allowOnlyPositiveNumber = (e) => {
    if (window.event) {
      var charCode = window.event.keyCode;
    } else if (e) {
      var charCode = e.which;
    } else {
      return true;
    }
    if (charCode >= 48 && charCode <= 57) return true;
    else {
      alert("Please enter only positive Integer Number");
      e.preventDefault();
      return false;
    }
  };
  const handleChange = (e) => {
    // console.log(e.target.value);
    if (e.target.value < 0) {
      e.preventDefault();
      e.target.value = 0;
      alert("Please enter only positive Number");
      return;
    }
    setInputVal(e.target.value);
  };

  const handleSubmit = () => {
    if (inputVal <= 0) {
      dispatch(openSnackbar("please enter value greater than 0", "error"));
      return;
    }
    handleClose();
    dispatch(loaderStartActionCreater());
    let newData = {
      memberId: params.id,
      amount: parseInt(inputVal),
    };

    addShareApplication(newData);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <label
            style={{ width: "24vw" }}
            sx={{ fontFamily: "Mulish", fontWeight: "bold" }}
            id={styles.lab}
          >
            Add Share Application Money
          </label>
        </DialogTitle>
        <hr className="m-0" />
        <DialogContent>
          <DialogContentText
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {
              <React.Fragment key={0}>
                {/* <MemberShareCards item={item} i={i} /> */}
                <Card
                  style={{
                    backgroundColor: "#F9F9F9",
                    borderRadius: "10px",
                    minWidth: "150px",
                    height: "100px",
                  }}
                >
                  <CardContent>
                    <Typography
                      id={styles.cardNumber}
                      style={{ color: "#0092E8" }}
                    >
                      {data.number ? data.number.toLocaleString("en-IN") : ""}
                    </Typography>
                    <Typography id={styles.cardMember}>{data.name}</Typography>
                  </CardContent>
                </Card>
              </React.Fragment>
            }
          </DialogContentText>

          {/* ///input box */}
          <DialogContentText className="mt-4">
            <TextField
              variant="standard"
              type="number"
              label="Add Share Application Money"
              style={{ fontFamily: "Mulish", textTransform: "capitalize" }}
              className="w-100"
              onKeyPress={allowOnlyPositiveNumber}
              onChange={handleChange}
            />
          </DialogContentText>
          <DialogContentText
            align="center"
            className="mt-4"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={() => handleClose()}>cancel</Button>
            <Button onClick={handleSubmit} id={styles.issueBtn}>
              Add
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
