import React, { useState, useEffect } from "react";
import ListItems from "./../../Components/ListItems/ListItems";
import TabPanel from "../../Components/Tabs/TabPanel";
import styles from "./users.module.css";
import { useDispatch, useSelector } from "react-redux";
// import BulletItems from "./../../Components/ListItems/BulletItem";
import MenuDropdown from "./../../Components/MenuItems/MenuDropdown";
import { openSnackbar } from "./../../Redux/Snackbar/snackbarStore";
import axios from "axios";
import Loader from "./../../Components/Loader/Loader";
import MaterialSelect from "./../../Components/SelectDropdown/MaterialSelect";
import { BASE_URL } from "./../../Components/URL/Url";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";

const UsersInfo = (props) => {
  const { id, setId } = props;
  const [data, setData] = useState([]);
  const [dataVarify, setDataVerify] = useState(false);
  const dispatch = useDispatch();
  const [option_users, setOption_users] = useState([
    "All Users",
    "Active Users",
    "Inactive Users",
  ]);
  const [option_action_1, setOption_action_1] = useState([
    "Import User",
    "Export User",
  ]);
  const [option_action_2, setOption_action_2] = useState([
    "Activate User",
    "Deactivate User",
  ]);
  // console.log(data, id);

  // const option_users = ["All Users", "Active Users", "Inactive Users"];
  // const option_action_1 = ["Import User", "Export User"];
  // const option_action_2 = ["Activate User", "Deactivate User"];

  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  useEffect(() => {
    if (userRole !== "") {
      if (userRole.toLowerCase() === "staff") {
        setOption_action_1([]);
        setOption_action_2([]);
      } else if (userRole.toLowerCase() === "manager") {
        setOption_action_1(["Export User"]);
      }
    }
  }, [userRole]);

  const [options, setOptions] = useState(option_action_1);

  const [selectedData, setSelectedData] = useState({});

  const [optionVal, setOptionVal] = useState("All Users");

  const selectedUsers = useSelector((state) => state.users.selectedRow);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getTableDetails = async () => {
    try {
      // console.log(data, id);

      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(BASE_URL + `/api/v1/users`, {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      });
      // console.log(response.data);
      setData(response?.data?.data);
    } catch (err) {
      // console.log(err);
      // console.log(data);

      let errors = err?.response?.data?.error;
      if (errors) dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    if (getAuthData) getTableDetails();
  }, [id, getAuthData]);

  useEffect(() => {
    if (data?.length > 0) setDataVerify(true);
  }, [data]);

  // console.log(data, id);

  useEffect(() => {
    if (selectedUsers.length !== 0) {
      setOptions(option_action_2);
    } else if (selectedUsers?.length == 0) {
      setOptions(option_action_1);
    }
  }, [selectedUsers]);

  useEffect(() => {
    // console.log("selectedData.length", selectedData.length);

    if (Object.keys(selectedData)?.length !== 0) {
      setOptions(option_action_2);
    } else if (Object.keys(selectedData)?.length == 0) {
      setOptions(option_action_1);
    }
  }, [selectedData, option_action_2, option_action_1]);

  const list = [
    {
      title: "User Profile",
    },
    {
      title: "Activity Logs",
    },
  ];

  const handleActivateStatus = async (status) => {
    // console.log("status");

    let userArray = Object.keys(selectedData)?.map((user) => user);
    // console.log(userArray);
    if (status == "Activate User") {
      const userData = { userIds: userArray, activityStatus: true };
      // console.log(userData);
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/users/updateStatus`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getTableDetails();
        dispatch(openSnackbar("User Activated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Deactivate User") {
      const userData = { userIds: userArray, activityStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/users/updateStatus`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getTableDetails();
        dispatch(openSnackbar("User Deactivated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    }
    setOptions(option_action_1);
    setOptionVal("All Users");
    setSelectedData({});
  };

  const filterUsers = async (name) => {
    setOptionVal(name);
    if (name == "Active Users") {
      // console.log(name);
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.get(BASE_URL + `/api/v1/users`, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        let active = response?.data?.data.filter(
          (item) => item.activityStatus == true
        );
        // console.log("active", active);
        dispatch(loaderEndActionCreater());
        setData(active);
        dispatch(openSnackbar("Acive Users Filtered Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        dispatch(loaderEndActionCreater());
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (name == "Inactive Users") {
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.get(BASE_URL + `/api/v1/users`, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        let inActive = response?.data?.data.filter(
          (item) => item.activityStatus !== true
        );
        // console.log("inActive", inActive);
        setData(inActive);
        dispatch(loaderEndActionCreater());
        dispatch(
          openSnackbar("InAcive Users Filtered Successfully!", "success")
        );
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        dispatch(loaderEndActionCreater());
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else {
      getTableDetails();
    }
  };

  return (
    <div>
      <div className="row m-0">
        <div
          className="col-md-4 p-0"
          style={{ borderRight: "1px solid #acacac", height: "100%" }}
        >
          <div
            className="d-flex justify-content-between align-items-center flex-wrap gap-2"
            style={{ padding: "40px 10px" }}
          >
            <div>
              <MaterialSelect
                // handleChange={handleChange}
                optionVal={optionVal}
                option_users={option_users}
                filterUsers={filterUsers}
              />
            </div>
            {options.length > 0 ? (
              <div>
                <MenuDropdown
                  options={options}
                  handleActivateStatus={handleActivateStatus}
                  setData={setData}
                  data={data}
                  tableRef={{ current: { state: { data } } }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className="m-0" />

          {!data || data?.length === 0 ? (
            <div
              style={{
                width: "100%",
                padding: "2rem",
                textAlign: "center",
              }}
            >
              {" "}
              No data
            </div>
          ) : (
            <div id={styles.scrollBar}>
              {data?.map((item, i) => (
                <div key={i}>
                  <ListItems
                    firstName={item.firstName}
                    lastName={item.lastName}
                    email={item.userId}
                    currentVal={item}
                    data={item}
                    index={i}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    id={id}
                    setId={setId}
                  />
                  {/* <BulletItems /> */}
                  <hr className="m-0" />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="col-md-8 p-0">
          <TabPanel data={list} id={id} setId={setId} />
        </div>
      </div>
    </div>
  );
};

export default UsersInfo;
