import { createRef, useState } from "react";
import { CommunicationForm } from "./CommunicationForm";
import { CommunicationHeader } from "./CommunicationHeader";
import CommunicationTable from "./CommunicationTable";
import NotificationsTable from "./NotificationsTable";
import { MultiSelectDropdown } from "./../../Components/SelectDropdown/MultiSelectDropdown";
import moment from "moment";
import Link from "@mui/material/Link";
import { CommunicationDataVisibiltyActionCreater } from "../../Redux/Communications/actionCreator";
import { useDispatch, useSelector } from "react-redux";

export const Communication = () => {
  const [openModal, setOpenModal] = useState(false);
  const [currentRowData, setCurrentRowData] = useState([]);
  const [tableUpdate, setTableUpdate] = useState(false);
  const [tabValue, setTabValue] = useState("All Communication");
  const [data, setData] = useState(null);
  const tableRef = createRef();
  const dispatch = useDispatch();
  // console.log(data);

  const columns = [
    {
      title: "Communication ID",
      field: "communicationId",
      // cellStyle: {
      //   minWidth: 220,
      // },
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Date & Time",
      field: "dateAndTime",
      // type: "datetime",
      // headerStyle: {
      //   whiteSpace: "nowrap",
      // },
      render: (rowData) => (
        <div
          style={{ borderRadius: "50%", width: "8rem" }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <div
            className="table_date"
            style={{
              color: "#000000",
              fontFamily: "Mulish",
              fontStyle: "normal",
              // fontWeight: "400",
              fontSize: "14.4px",
              // lineHeight: "25px",
            }}
          >
            {moment(rowData.dateAndTime).format("DD MMM YYYY")}
          </div>
          <div
            className="table_time"
            style={{
              color: "#757575",
              fontFamily: "Mulish",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: "12.4px",
              // lineHeight: "18px",
            }}
          >
            {moment(rowData.dateAndTime).format("HH:mm:ss")}
          </div>
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.dateAndTime?.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) != -1,
    },
    {
      title: "Title",
      field: "title",
      render: (rowData) => (
        <div
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          style={{
            maxWidth: "10rem",
            lineBreak: "auto",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.title}
        </div>
      ),
      customSort: (a, b) => {
        return (a.title || "").localeCompare(b.title || "");
      },
    },
    {
      title: "Type",
      field: "communicationType",
      customSort: (a, b) => {
        return (a.communicationType || "").localeCompare(
          b.communicationType || ""
        );
      },
    },
    {
      title: "Visible To",
      field: "visibleTo",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          style={{
            maxWidth: "10rem",
            lineBreak: "auto",
            whiteSpace: "nowrap",
            overflowX: "hidden",
            textTransform: "capitalize",
          }}
        >
          {rowData.visibleTo}
        </div>
      ),
      customSort: (a, b) => {
        return (a.visibleTo || "").localeCompare(b.visibleTo || "");
      },
    },
    // {
    //   title: "Visible",
    //   field: "visibleTo",
    // },
    {
      title: "Description",
      field: "description",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.description}
        </div>
      ),
      customSort: (a, b) => {
        return (a.description || "").localeCompare(b.description || "");
      },
    },
    {
      title: "Attachment",
      field: "attachmentUrl",
      render: (rowData) => (
        <div
          style={{
            maxWidth: "10rem",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
          sx={{ display: "flex", flexDirection: "column" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Link href={rowData.attachmentUrl}>
            {rowData.attachmentUrl
              ? rowData.attachmentUrl.split("/")[3].substring(13)
              : ""}
          </Link>
        </div>
      ),
      customSort: (a, b) => {
        return (a.attachmentUrl || "").localeCompare(b.attachmentUrl || "");
      },
    },
  ];

  const initialColumnStatus = {
    communicationId: true,
    dateAndTime: true,
    title: true,
    communicationType: true,
    type: true,
    visibleTo: true,
    description: false,
    attachmentUrl: false,
  };

  // const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  const columnsStatus = useSelector((state) => {
    // console.log(state.communication.columnsStatus);
    return state.communication.columnsStatus;
  });

  const setColumnsStatus = (data) => {
    // console.log(data);
    dispatch(CommunicationDataVisibiltyActionCreater(data));
  };

  return (
    <>
      {/* <CommunicationForm /> */}
      <CommunicationHeader
        tableRef={tableRef}
        tableUpdate={tableUpdate}
        setTableUpdate={setTableUpdate}
        currentRowData={currentRowData}
        setCurrentRowData={setCurrentRowData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        tabValue={tabValue}
        setTabValue={setTabValue}
        columnsStatus={columnsStatus}
        setColumnsStatus={setColumnsStatus}
        data={data}
      />

      <div className="overflow-hidden position-relative">
        <div
          align="right"
          style={{
            position: "absolute",
            top: "2%",
            right: "2%",
            zIndex: "2",
          }}
        >
          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>

        <CommunicationTable
          tableRef={tableRef}
          setCurrentRowData={setCurrentRowData}
          openModal={openModal}
          setTableUpdate={setTableUpdate}
          tableUpdate={tableUpdate}
          setOpenModal={setOpenModal}
          tabValue={tabValue}
          columns={columns}
          columnsStatus={columnsStatus}
          setData={setData}
          data={data}
        />
      </div>
    </>
  );
};
