import React from "react";
import styles from "./DataAcq.module.css";
import { useHistory } from "react-router";

const FormButton = (props) => {
  const { isUpdate, userRole } = props;
  const history = useHistory();
  return (
    <div
      className={`d-flex justify-content-end align-items-center pt-3 pb-5 ${styles.formBtn}`}
      style={{
        paddingRight: "10px",
      }}
    >
      <button
        id={styles.cancelBtn}
        onClick={() =>
          history.push(
            !props.name
              ? "/admin/data-acquisition"
              : "/admin/data-acquisition/dataAcq-info"
          )
        }
      >
        Cancel
      </button>
      {isUpdate === true && userRole.toLowerCase() === "staff" ? (
        ""
      ) : (
        <>
          {" "}
          <button
            id={styles.saveBtn}
            onClick={() => props.OnSubmitQuestion("save")}
          >
            {props.name ? props.name : "Save"}
          </button>
          <button
            id={styles.saveBtn}
            onClick={() => props.OnSubmitQuestion("launch")}
            style={{
              marginRight: "10px",
            }}
          >
            Launch
          </button>
        </>
      )}
    </div>
  );
};

export default FormButton;
