const QUESTION_TYPES = {
  SHORT_ANSWER_TEXT: "Short Answer Text",
  LONG_ANSWER_TEXT: "Long Answer Text",
  MULTIPLE_CHOICE: "Multiple Choice",
  CHECK_BOX: "Check Box",
  DATE:"Date",
  FILE:"File"
};


const DATA_ACQUISION_TYPES = {
  APPLICATION: "Application",
  POLLS: "Poll",
  SURVEY: "Survey",
};



const ACQUISITION_TYPE_BASED_OPTIONS = {
  [DATA_ACQUISION_TYPES.APPLICATION]:[
    QUESTION_TYPES.LONG_ANSWER_TEXT,
    QUESTION_TYPES.SHORT_ANSWER_TEXT,
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.CHECK_BOX,
    QUESTION_TYPES.DATE,
    QUESTION_TYPES.FILE
  ],
  [DATA_ACQUISION_TYPES.SURVEY]:[
    QUESTION_TYPES.LONG_ANSWER_TEXT,
    QUESTION_TYPES.SHORT_ANSWER_TEXT,
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.CHECK_BOX,
    QUESTION_TYPES.DATE,
    QUESTION_TYPES.FILE
  ],
  [DATA_ACQUISION_TYPES.POLLS]:[
    QUESTION_TYPES.MULTIPLE_CHOICE,
    QUESTION_TYPES.CHECK_BOX,
  ],
}
const optionQuestionType = [
  QUESTION_TYPES.LONG_ANSWER_TEXT,
  QUESTION_TYPES.SHORT_ANSWER_TEXT,
  QUESTION_TYPES.MULTIPLE_CHOICE,
  QUESTION_TYPES.CHECK_BOX,
    QUESTION_TYPES.DATE,
    QUESTION_TYPES.FILE
];

const DATA_TYPES = ["Alphabet", "Number", "Alpha-Num"];
const optionType = ["Alphabet", "Number", "Alpha-Num"];

const ACTION_BUTTON_LABELS = {
  ADD_MORE:"Add More",
  ADD_OPTIONS:"Add Options"
}
const allowedMultiAnswerQuestionType = (questionType) => {
  return [QUESTION_TYPES.MULTIPLE_CHOICE, QUESTION_TYPES.CHECK_BOX]?.includes(
    questionType
  );
};
const constants = {
  QUESTION_TYPES,
  optionQuestionType,
  optionType,
  DATA_TYPES,
  DATA_ACQUISION_TYPES,
  ACTION_BUTTON_LABELS,
  ACQUISITION_TYPE_BASED_OPTIONS,
  allowedMultiAnswerQuestionType,
};

export default constants;
