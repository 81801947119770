const actions = {
  SHARE_ISSUANCE: "SHARE_ISSUANCE",
  SHARE_TRANSFER: "SHARE_TRANSFER",
  SHARE_APPLICATION_MONEY: "SHARE_APPLICATION_MONEY",
  SHARE_HOLING_TRANSACTION: "SHARE_HOLING_TRANSACTION",
  SHARE_HOLDING_FILTERS: "SHARE_HOLDING_FILTERS",

  reportDataVisibiltyAction: (data, key) => {
    // console.log("memberDataAction", data);
    switch (key) {
      case actions.SHARE_ISSUANCE:
        return {
          type: actions.SHARE_ISSUANCE,
          data,
        };
      case actions.SHARE_TRANSFER:
        return {
          type: actions.SHARE_TRANSFER,
          data,
        };
      case actions.SHARE_APPLICATION_MONEY:
        return {
          type: actions.SHARE_APPLICATION_MONEY,
          data,
        };
      case actions.SHARE_HOLING_TRANSACTION:
        return {
          type: actions.SHARE_HOLING_TRANSACTION,
          data,
        };
      case actions.SHARE_HOLDING_FILTERS:
        return {
          type: actions.SHARE_HOLDING_FILTERS,
          data,
        };
    }
  },
};

export default actions;
