import { Box, Grid, Button } from "@mui/material";
import { useState } from "react";
import { GeneralDateField } from "../../Components/GeneralComponents/GeneralDateField";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import { GeneralRangeField } from "../../Components/GeneralComponents/GeneralRangeField";
const initialData = {
  name: "",
  memberID: "",
  DateStart: null,
  dateEnd: null,
  nomineeName: "",
  organisationName: "",
  email: "",
  phone: "",
  shareCapital: [],
  ShareApplicationMoney: [],
  state: "",
  city: "",
  pincode: "",
};
export const MemberSearchForm = () => {
  const [data, setData] = useState(initialData);

  const handleClick = () => {
    // console.log(data);
  };
  return (
    <div
      style={{
        padding: "20px",
        width: "40vw",
        border: "1px solid black",
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: 1,
          m: 1,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <GeneralInputField
          data={data}
          dataKey={"name"}
          setData={setData}
          label={"Name"}
        />
        <GeneralInputField
          data={data}
          dataKey={"memberID"}
          setData={setData}
          label={"Member Id"}
        />
        <GeneralInputField
          data={data}
          dataKey={"email"}
          setData={setData}
          label={"Email"}
        />
        <GeneralInputField
          data={data}
          dataKey={"phone"}
          setData={setData}
          label={"Phone"}
        />
        <GeneralDateField
          marginTop
          data={data}
          dataKey={"DateStart"}
          setData={setData}
          label={"Date Start"}
        />
        <GeneralDateField
          marginTop
          data={data}
          dataKey={"dateEnd"}
          setData={setData}
          label={"Date End"}
        />
        <GeneralInputField
          data={data}
          dataKey={"nomineeName"}
          setData={setData}
          label={"Nominee Name"}
        />
        <GeneralInputField
          data={data}
          dataKey={"organisationName"}
          setData={setData}
          label={"Organisation Name"}
        />
        <GeneralRangeField
          data={data}
          dataKey={"shareCapital"}
          setData={setData}
          label={"Share Capital"}
          maxValue={3000}
        />
        <GeneralRangeField
          data={data}
          dataKey={"ShareApplicationMoney"}
          setData={setData}
          label={"Share Application Money"}
          maxValue={5000}
        />
        <GeneralInputField
          data={data}
          dataKey={"state"}
          setData={setData}
          label={"State"}
        />
        <GeneralInputField
          data={data}
          dataKey={"city"}
          setData={setData}
          label={"City"}
        />
        <GeneralInputField
          data={data}
          dataKey={"pincode"}
          setData={setData}
          label={"Pin Code"}
        />
        {/* <GeneralInputField label = {"Share Capital"}/> */}
      </Box>

      {/* Button Secction */}
      <Grid
        fullWidth
        sx={{
          p: 1,
          m: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <Grid xs={7}></Grid>
        <Grid xs={3}>
          <Button variant="text">Cancel</Button>
        </Grid>
        <Grid xs={3}>
          <Button
            onClick={handleClick}
            variant="contained"
            sx={{ bgcolor: "#C4C4C4", color: "white" }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
