import React, { useState, useEffect } from "react";
import TitleForm from "../TitleForm";
import FormButton from "../FormButton";
import QuestionForm from "./QuestionForm";
import QuestionSaved from "./QuestionSaved";
import axios from "axios";
import { useHistory } from "react-router";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import CONSTANTS from "../commons/Utils";

const GenericForm = (props) => {
  const { type, dataAcqLength, id } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [questionType, setQuestionType] = useState("");
  const [count, setCount] = useState(0);
  const [values, setValues] = useState([]);
  const [multipleValues, setMultipleValues] = useState([]);
  const [index, setIndex] = useState(0);
  const [onCopy, setOnCopy] = useState(false);
  const startdate = new Date();
  const [startDate, setStartDate] = useState(startdate);
  let date = new Date();
  let enddate = new Date(date.setDate(date.getDate() + 1));
  const [endDate, setEndDate] = useState(enddate);
  // const [startTime, setStartTime] = useState
  const [about, setAbout] = useState({ title: "", description: "", about: "" });
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [title, setTitle] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isValidStart, setIsValidStart] = useState(true);
  const [isValidEnd, setIsValidEnd] = useState(true);

  useEffect(() => {
    if (!!props.type) {
      setQuestionType(CONSTANTS.ACQUISITION_TYPE_BASED_OPTIONS[props.type][0]);
    }
  }, [props.type]);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const OnSubmitQuestion = async (stand) => {
    let url = attachmentUrl;
    const userData = {
      dataAcquisitionId: "ID" + ("0000000" + (dataAcqLength + 1)).slice(-7),
      type: type,
      isActive: true,
      startDate: startDate,
      endDate: endDate,
      description: about.description,
      about: about.about,
      title,
      attachmentUrl: url,
      questions: values,
      standing: stand,
    };
    // console.log(
    //   "checkinng for same time",
    //   userData,
    //   userData.startDate?.toLocaleString(),
    //   userData.startDate?.toDateString() === userData.closeDate?.toDateString()
    // );

    if (!isValid) {
      dispatch(openSnackbar("Please Enter Valid Date", "error"));
      return;
    } else if (
      new Date(userData.startDate).toLocaleString() ===
      new Date(userData.endDate).toLocaleString()
    ) {
      dispatch(
        openSnackbar("We can submit on same date but not on same time", "error")
      );
      return;
    } else if (new Date(userData.startDate) > new Date(userData.endDate)) {
      dispatch(
        openSnackbar("Please Select Correct Close Date and Time", "error")
      );
      return;
    } else if (new Date(userData.startDate) < new Date(startdate)) {
      dispatch(
        openSnackbar("Please Select Correct Open Date and Time", "error")
      );
      return;
    }
    // else if (
    //   new Date(userData.startDate).toLocaleDateString() <
    //   new Date(startdate).toLocaleDateString()
    // ) {
    //   dispatch(openSnackbar("Please fill correct Open Date", "error"));
    //   return;
    // }
    // else if (
    //   new Date(userData.startDate).toLocaleTimeString() <
    //   new Date(startdate).toLocaleTimeString()
    // ) {
    //   dispatch(openSnackbar("Please fill correct Start Time", "error"));
    //   return;
    // }

    if (values.length !== 0 && title.length > 0) {
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.post(
          process.env.REACT_APP_BASE_URL + `/api/v1/dataAcquisition`,
          userData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        history.push("/admin/data-acquisition");
        dispatch(openSnackbar("Form Created Successfully!", "success"));
      } catch (err) {
        dispatch(openSnackbar(err.message, "error"));
        if (err.request.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (values.length == 0) {
      dispatch(openSnackbar(`Error: Add Question First`, "error"));
    } else {
      dispatch(openSnackbar(`Error: Title Field Required`, "error"));
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <div className="container" align="center">
        <TitleForm
          title={`${props.type} Title`}
          applicationTitle={title}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          attachmentUrl={attachmentUrl}
          setAttachmentUrl={setAttachmentUrl}
          setAbout={setAbout}
          about={about}
          setTitle={setTitle}
          setIsValid={setIsValid}
          isValidStart={isValidStart}
          isValidEnd={isValidEnd}
          setIsValidStart={setIsValidStart}
          setIsValidEnd={setIsValidEnd}
        />
        <div>
          {/* RENDER QUESTION */}
          {values.map((val, i) => (
            <React.Fragment key={i + 1000}>
              <QuestionSaved
                setQuestionType={setQuestionType}
                questionType={questionType}
                setValues={setValues}
                values={values}
                val={val}
                type={props.type}
                setCount={setCount}
                count={count}
                setIndex={setIndex}
                idx={i}
                index={index || i}
              />
            </React.Fragment>
          ))}
        </div>

        {/* CREATE QUESTION */}
        <QuestionForm
          setQuestionType={setQuestionType}
          questionType={questionType}
          setCount={setCount}
          count={count}
          type={props.type}
          setValues={setValues}
          values={values}
          setIndex={setIndex}
          setAttachmentUrl={setAttachmentUrl}
          index={index}
          setMultipleValues={setMultipleValues}
          multipleValues={multipleValues}
          setOnCopy={setOnCopy}
          onCopy={onCopy}
        />

        <FormButton OnSubmitQuestion={OnSubmitQuestion} />
      </div>
    </div>
  );
};

export default GenericForm;
