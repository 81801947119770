import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styles from "./Cards.module.css";

const Cards = (props) => {
  return (
    <Card id={styles.card}>
      <CardContent id={styles.cardInner}>
        <div id={styles.cardHeader}>{props.data.goal}</div>
        <Typography
          id={styles.cardNumber}
          color={`${props.valueColor === "gray" ? "#606060" : "#0096FF"}`}
        >
          {parseInt(props.data.number).toLocaleString("en-IN")}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Cards;
