import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { Switch, Typography } from "@mui/material";
import UserInput from "./UserInput";
import UserSelectField from "./UserSelectField";
import DatePickers from "./DatePickers";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "./../../../Redux/Snackbar/snackbarStore";
import styles from "../UserInput.module.css";
import UsersCityField from "./UsersCityField";
import UserStateField from "./UserStateField";
import { BASE_URL } from "./../../../Components/URL/Url";
import pincodeDirectory from "india-pincode-lookup";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../../Redux/Configuration/configurationActionCreator";
import moment from "moment";
import memberStyles from "../../Members/member.module.css";
import userStyles from "../users.module.css";
import { makeStyles } from "@mui/styles";

const CreateUserForm = (props) => {
  const { dataLength } = props;
  const accessList = ["Admin", "Staff"];
  const [lengthD, setLengthD] = useState(0);

  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getTableDetails = async () => {
    try {
      let token1 = getAuthData;
      if (!token1) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        // BASE_URL + `/api/v1/users`
        BASE_URL + `/api/v1/users`,
        {
          headers: {
            Authorization: `Bearer ` + `${token1}`,
          },
        }
      );

      setLengthD(response.data.count);
      // dispatch(loaderEndActionCreater());
      // console.log(response.data);
    } catch (err) {
      console.log(err);
      // if (err.request.status === 401) {
      //   dispatch(LogoutSayasActionCreate());
      // }
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      // dispatch(loaderEndActionCreater()); // console.log(response.data);
    }
  };

  useEffect(() => {
    getTableDetails();
  }, []);

  const [initialValue, setInitialValue] = useState({
    activityStatus: false,
    userId: "U" + ("0000000" + (lengthD + 1)).slice(-7),
    // user: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    role: "",
    team: [],
    // access: "",
    // addressLine1: "",
    // addressLine2: "",
    // organisationName: "",
    // nomineeName: "",
    // state: "Maharashtra",
    // city: "Thane",
    // pincode: "",
    date: Date.now(),
  });

  const history = useHistory();
  const dispatch = useDispatch();
  ///fetch config data
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });
  const [date, setDate] = useState(initialValue.date);
  const [cityName, setCityName] = useState(null);
  //auth token fetch from selector

  let errors = {};
  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,

    onSubmit: async (values) => {
      // console.log("Values", { ...values, date: date });
      // console.log("Values On submit", values);

      const data = { ...values, data: date };
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      try {
        // "http://localhost:8080" + `/api/v1/users`,

        let response = await axios.post(
          process.env.REACT_APP_BASE_URL + `/api/v1/users`,
          data,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );

        history.push("/admin/users");
        // console.log("After success: ", response);

        dispatch(openSnackbar("User Created Successfully!", "success"));
      } catch (err) {
        // console.log("Error", err.response.data.split(": {")[1].slice(0, -1));
        let error =
          "Duplicate Field : " + err.response.data.split(": {")[1].slice(0, -1);
        dispatch(openSnackbar(error, "error"));
        if (err.request.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    },
    validate: (values) => {
      // console.log("Values on validation", values);
      // if (!values.user) {
      //   errors.user = "Required";
      // }

      // if (!values.organisationName) {
      //   errors.organisationName = "Required";
      // }

      // if (!values.nomineeName) {
      //   errors.nomineeName = "Required";
      // }

      // if (!values.state) {
      //   errors.state = "Required";
      // }

      // if (!values.city) {
      //   errors.city = "Required";
      // }

      if (!values.role) {
        errors.role = "Required";
      }
      if (!values.team) {
        errors.team = "Required";
      }
      // if (!values.access) {
      //   errors.access = "Required";
      // }

      if (!values.firstName) {
        errors.firstName = "Required";
      }

      if (!values.lastName) {
        errors.lastName = "Required";
      }

      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
      ) {
        errors.email = "Invalid email";
      }

      if (!values.phone) {
        errors.phone = "Required";
      } else if (
        !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(values.phone)
      ) {
        errors.phone = "Invalid Phone";
      }

      // let checkPin = pincodeDirectory.lookup(values.pincode);

      // if (!values.pincode) {
      //   errors.pincode = "Required";
      // } else if (!/^[1-9][0-9]{5}$/.test(values.pincode)) {
      //   errors.pincode = "Invalid Pin Code";
      // } else if (checkPin[0]?.stateName !== values.state.toUpperCase()) {
      //   errors.pincode = "Invalid Pin Code";
      // }

      return errors;
    },
    handleMultiChange: (event) => {
      const {
        target: { value },
      } = event;
      setInitialValue({
        ...initialValue,
        team: typeof value === "string" ? value.split(",") : value,
      });
    },
  });

  React.useEffect(() => {
    setInitialValue({
      activityStatus: false,
      userId: "U" + ("0000000" + (lengthD + 1)).slice(-7),
      // user: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      // addressLine1: "",
      // addressLine2: "",
      // organisationName: "",
      // nomineeName: "",
      // state: "Maharashtra",
      // city: "Thane",
      // pincode: "",
      role: roles ? roles[0] : "",
      team: [],
      access: "",
      // date: Date.now(),
    });
  }, [roles, teams, lengthD]);

  useEffect(() => {
    setRoles(
      getConfigData?.roles?.map((e) => {
        return e.type;
      })
    );
    setTeams(
      getConfigData?.teams?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  // useEffect(() => {
  //   console.log("Roles", roles);
  //   console.log("Teams", teams);
  // }, [roles, teams]);

  //how to use
  // teams.length > 0 ? teams : []

  ////

  useEffect(() => {
    if (getAuthData) dispatch(getConfiguationActionCreater(getAuthData));
  }, [getAuthData]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue(
      "team",
      typeof value === "string" ? value.split(",") : value,
      false
    );
  };

  const useStyles = makeStyles({
    root: {
      "& .MuiSelect-select:hover": { backgroundColor: "none" },
    },
  });

  const classes = useStyles();

  return (
    <div
      className="m-5"
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          // alert("this.state.message");
          formik.handleSubmit();
        }
        return;
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "25px 50px",
            width: "100%",
          }}
        >
          <UserSelectField
            label="Role *"
            name="role"
            formik={formik}
            items={roles && roles?.length > 0 ? roles : []}
          />
          {/* <UserSelectField
            label="Team"
            name="team"
            formik={formik}
            items={teams && teams?.length > 0 ? teams : []}
          /> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <label className="mb-1" id={styles.userLabel}>
              Teams
            </label>
            <Select
              className={classes.root}
              sx={{
                height: "2.5rem",
                width: "90%",
                padding: "2px 0px",
                marginTop: "2px",
                textTransform: "capitalize",
                fontWeight: "bold",
                fontFamily: "Mulish",
                // border: "1px solid #ced4da",
              }}
              // sx={{ height: "2.2rem" }}
              label={null}
              multiple
              value={formik.values.team ? formik.values.team : []}
              onChange={handleChange}
              input={
                <OutlinedInput
                  label=" "
                  // sx={{
                  //   // height: "100%",
                  //   border: "none",
                  // }}
                />
              }
              // MenuProps={MenuProps}
            >
              {teams?.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  // style={getStyles(name, personName, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
          {/* class="MuiOutlinedInput-root MuiInputBase-root MuiInputBase-colorPrimary Mui-focused makeStyles-root-66 css-9orlhh-MuiInputBase-root-MuiOutlinedInput-root" */}
          {/* <UserSelectField
            label="Access"
            name="access"
            formik={formik}
            items={accessList && accessList?.length > 0 ? accessList : []}
          /> */}

          <div className="d-flex align-items-center">
            <label className={memberStyles.switchLabel}>Activity Status</label>
            <Switch
              name="activityStatus"
              checked={formik?.values?.activityStatus}
              // value={formik?.values?.activityStatus}
              onChange={formik.handleChange}
            />
          </div>

          <UserInput label="User ID" name="userId" formik={formik} />
          {/* <UserInput label="User" name="user" formik={formik} /> */}

          {/* <DatePickers
            disable={true}
            label="Date"
            name="date"
            formik={formik}
            setDate={setDate}
            customStyles={{ height: "38px" }}
            classProp={userStyles.UserSelectField}
          /> */}
          <UserInput label="Email" name="email" formik={formik} />
          <UserInput label="First Name" name="firstName" formik={formik} />
          <UserInput label="Middle Name" name="middleName" formik={formik} />
          <UserInput label="Last Name" name="lastName" formik={formik} />

          <UserInput label="Phone" name="phone" formik={formik} />
          {/* <UserInput label="Nominee Name" name="nomineeName" formik={formik} />
          <UserInput
            label="Organisation Name"
            name="organisationName"
            formik={formik}
          />
          <UsersCityField
            label="State"
            name="state"
            formik={formik}
            setCityName={setCityName}
          />
          <UserStateField
            label="City"
            name="city"
            formik={formik}
            cityName={cityName}
          /> */}

          {/* <UserInput label="Pin Code" name="pincode" formik={formik} />
          <UserInput
            label="Address Line 1"
            name="addressLine1"
            formik={formik}
          />
          <UserInput
            label="Address Line 2"
            name="addressLine2"
            formik={formik}
          /> */}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography>
            <b>Date:</b> {moment().format("DD/MM/YYYY")}
          </Typography>
        </div>
        <div align="right" style={{ marginRight: "45px" }}>
          <button
            // variant="text"
            id={styles.cancelBtn}
            // style={{
            //   width: 138,
            //   height: 40,
            //   fontFamily: "Mulish",
            //   fontStyle: "normal",
            //   fontWeight: "bold",
            //   fontSize: "20px",
            //   lineHeight: "25px",
            //   textTransform: "uppercase",
            //   color: "#C4C4C4",
            //   marginRight: 10,
            // }}
            onClick={() => {
              history.push("/admin/users");
            }}
          >
            Cancel
          </button>
          <button id={styles.submitBtn} type="submit">
            Create
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateUserForm;
