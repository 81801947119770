import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
// import { useCubeQuery } from "@cubejs-client/react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
// import Loader from "../Loader/Loader";
import Calender from "./../Calender/Calender";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import moment from "moment";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { useDispatch } from "react-redux";
// import { DatePicker } from "@material-ui/pickers";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

function StackedBarChart({ chartData }) {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [dataValues, setDataValues] = useState([]);
  const [newChartData, setNewChartData] = useState([]);
  const dispatch = useDispatch();
  dayjs.extend(isBetween);

  // console.log("chartDataorginal", chartData);

  useEffect(() => {
    let arr = chartData.map((data) => {
      return {
        y: data.sum,
        x: `${dayjs()
          .month(data._id.month - 1)
          .format("MMM")} ${data._id.year}`,
      };
    });
    setNewChartData(arr);
    setDataValues(arr);
  }, [chartData]);

  // const [jsonQuery, setJSONQuery] = useState({
  //   measures: ["Orders.count"],
  //   timeDimensions: [
  //     {
  //       dimension: "Orders.createdAt",
  //       granularity: "month",
  //     },
  //   ],
  //   order: [
  //     ["Orders.count", "desc"],
  //     ["Orders.createdAt", "asc"],
  //   ],
  //   dimensions: ["Orders.status"],
  //   filters: [],
  // });
  // const { resultSet, isLoading, error, progress } = useCubeQuery(jsonQuery);

  // if (error) {
  //   return <p>{error.toString()}</p>;
  // }
  // if (isLoading) {
  //   return (
  //     <div>
  //       {(progress && progress.stage && progress.stage.stage) || <Loader />}
  //     </div>
  //   );
  // }

  // if (!resultSet) {
  //   return null;
  // }

  // const returnedData = resultSet.loadResponse.results[0].data.sort(
  //   (first, second) =>
  //     dayjs(first["Orders.createdAt.month"]).diff(
  //       dayjs(second["Orders.createdAt.month"])
  //     )
  // );

  // const filterOrderStatusBy = (type) =>
  //   returnedData
  //     .filter((order) => order["Orders.status"] === type)
  //     .map((order) => order["Orders.count"]);

  // const ordersProcessing = filterOrderStatusBy("processing");
  // console.log("chartData", ordersProcessing);

  // const orderMonths = [
  //   ...new Set(
  //     returnedData.map((order) => {
  //       return dayjs(order["Orders.createdAt.month"]).format("MMM YYYY");
  //     })
  //   ),
  // ];

  // console.log(orderMonths);

  const options = {
    legend: {
      data: ["Processing Orders count"],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      axisLabel: {
        textStyle: { fontSize: 8 },
        width: "min-content",
      },
      type: "category",
      data: dataValues.map((val) => val.x),
    },
    yAxis: {
      axisLabel: {
        textStyle: { fontSize: 8 },
        width: "min-content",
        rotate: 45,
      },
      axisLine: { show: true },
      axisTick: { show: true },
      // name: "Population",
      // splitLine: {
      //   lineStyle: {
      //     type: "dotted"
      //   }
      // },
      type: "value",
    },
    series: [
      {
        data: dataValues.map((val) => val.y),
        type: "bar",
        color: "#0096FF",
        barMaxWidth: "32px",
        label: { style: { width: "2rem" } },
      },
    ],
  };

  // console.log("dataValues", dataValues);

  const updateDate = (event) => {
    event.preventDefault();
    if (!startDate) {
      dispatch(openSnackbar("Please fill the start Date", "error"));
    } else if (!endDate) {
      dispatch(openSnackbar("Please fill the end Date", "error"));
    } else if (startError) {
      dispatch(openSnackbar("Please Enter valid start Date", "error"));
    } else if (endError) {
      dispatch(openSnackbar("Please Enter valid end Date", "error"));
    } else {
      // setJSONQuery((prevJSONQuery) => {
      //   return {
      //     ...prevJSONQuery,
      //     filters: [
      //       {
      //         member: "Orders.createdAt",
      //         operator: "inDateRange",
      //         values: [startDate, endDate],
      //       },
      //     ],
      //   };
      // });
      setDataValues(
        newChartData.filter((val) =>
          dayjs(val.x).isBetween(
            dayjs(startDate).format("MMM YYYY"),
            dayjs(endDate).format("MMM YYYY"),
            null,
            "[]"
          )
        )
      );
    }
  };

  const togglerCalender = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div
        style={{
          border: "1px solid #F2F2F2",
          borderRadius: "5px",
          padding: "5px",
        }}
      >
        <div>
          <div onClick={togglerCalender}>
            <Calender />
          </div>
          {toggle ? (
            <div className="d-flex align-items-center justify-content-center my-4 mr-2">
              <Form
                onSubmit={updateDate}
                className="d-flex align-items-center  gap-4"
              >
                <div className="gap-2 align-items-center p-2">
                  {/* <div>
                    <label htmlFor="startDate">Start Date</label>
                  </div> */}
                  {/* <Input
                    id="startDate"
                    name="start-date"
                    value={startDate}
                    onChange={({ target }) => {
                      if (
                        moment(target.value).format("YYYY-MM-DD") >
                        moment(Date.now()).format("YYYY-MM-DD")
                      )
                        setStartError(true);
                      else setStartError(false);
                      // console.log(target.value);
                      setStartDate(target.value);
                    }}
                    type="date"
                    error={startError}
                    
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date"
                      value={startDate}
                      maxDate={new Date()}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setStartDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div className="gap-2 align-items-center p-2">
                  {/* <div>
                    <label htmlFor="endDate">End Date</label>
                  </div> */}
                  {/* <Input
                    id="endDate"
                    name="end-date"
                    value={endDate}
                    onChange={({ target }) => {
                      if (
                        moment(target.value).format("YYYY-MM-DD") >
                        moment(Date.now()).format("YYYY-MM-DD")
                      )
                        setEndError(true);
                      else setEndError(false);
                      // console.log(target.value);
                      setEndDate(target.value);
                    }}
                    error={endError}
                    type="date"
                  /> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      inputFormat="dd/MM/yyyy"
                      onChange={(newValue) => {
                        setEndDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    bgcolor: "#0096FF",
                  }}
                >
                  Set date
                </Button>
              </Form>
            </div>
          ) : (
            " "
          )}
        </div>
        <ReactECharts option={options} />
      </div>
    </>
  );
}

export default StackedBarChart;
