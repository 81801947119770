import React, { useEffect } from "react";
import styles from "../member.module.css";
import _ from "lodash";

import statesDistrictsTalukas from "../../../constants/statesDistrictTalukas.json";

const MemberSelectField = (props) => {
  const { formik, label, name, items } = props;
  const { values } = formik;

  // if(name === "shareholder"){
  //   console.log("share holder in select field",formik.values[`${name}`]);

  // }

  useEffect(() => {
    //  console.log(formik);
  }, [name]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label className="mb-1" id={styles.userLabel}>
        {label}
        <span className="text-danger"> *</span>
      </label>
      <select
        name={name}
        value={
          items?.includes(formik.values[`${name}`])
            ? formik.values[`${name}`]
            : ""
        }
        onChange={formik.handleChange}
        disabled={props.disabled || false}
        style={{
          width: "90%",
          textTransform: "capitalize",
          fontWeight: "bold",
          fontFamily: "Mulish",
          // height: "54px",
          border:
            formik.errors[name] && formik.touched[name]
              ? "2px solid red"
              : null,
        }}
        error={formik.errors}
        className={`form-select ${styles.memberSelectField}`}
      >
        {items?.map((taluka, i) => {
          return (
            <option
              value={taluka}
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Mulish",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {taluka}
            </option>
          );
        })}
        <option
          value={""}
          hidden
          selected
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Mulish",
            color: "black",
            textTransform: "capitalize",
          }}
        >
          {""}
        </option>
      </select>
      {/* {formik.errors[`${name}`] ? (
        <span className="text-danger">{formik.errors[`${name}`]}</span>
      ) : null} */}
    </div>
  );
};

export default MemberSelectField;
