import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";

export const ProgressBar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 30,
      }}
    >
      <CircularProgress size="5rem" Backdrop />
    </Box>
  );
};
