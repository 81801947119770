import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
// import DatePicker from "@mui/lab/DatePicker";
import { makeStyles } from "@mui/styles";

const DatePickers = (props) => {
  const { data, setDate, customStyles } = props;

  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setValue(e);
    setDate(new Date(e));
  };

  useEffect(() => {
    setValue(data ? data : Date.now());
  }, [data]);

  const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": customStyles,
      "& input": { padding: ".375rem .75rem" },
    },
  });

  const classes = useStyles();

  return (
    <div style={{ marginTop: 10 }}>
      {/* <label
        style={{
          textTransform: "capitalize",
          fontFamily: "Mulish",
          fontWeight: "bold",
        }}
        className="mb-2"
      >
        Date
      </label> */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={value || null}
          // minDate={Date.now()}
          maxDate={new Date()}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                width: "90%",
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Mulish",
              }}
              label="Date"
              className={classes.root}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DatePickers;
