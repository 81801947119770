import actions from "./authonticationActions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../Snackbar/snackbarStore";
import { loaderEndActionCreater } from "../Loader/LoaderActionCreator";
const { registerBegin, logoutSayas } = actions;

const sendLoginDetails = async (dispatch, data) => {
  // console.log(data);
  try {
    let response = await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/v1/auth/login",
      data
    );
    // dispatch(
    //   openSnackbar("Authorised Shares Addition successfully", "success")
    // );
    // console.log("Login response", response.data);
    let token = response.data.tokens.access.token;
    window.localStorage.setItem("sayasLoginToken", token);

    //delete token when tab close

    return token;
  } catch (e) {
    // console.log("login Error Message ", e.response.data.error);
    dispatch(loaderEndActionCreater());
    if (e?.response?.data?.error)
      dispatch(openSnackbar(e?.response?.data?.error, "error"));
  }
};

const loginSayas = (formData, status) => {
  return async (dispatch) => {
    try {
      if (status === "new") {
        let token = await sendLoginDetails(dispatch, formData);

        // console.log("token", formData?.rememberMe);
        window.localStorage.setItem("sayasLoginToken", token);

        //delete token when tab close
        // if (!formData?.rememberMe){
        //   window.addEventListener("beforeunload", () => {
        //     window.localStorage.removeItem("sayasLoginToken");
        //   });
        // }

        dispatch(registerBegin(token));

        // console.log(formData);
      } else {
        let token = window.localStorage.getItem("sayasLoginToken");
        // console.log(token);
        dispatch(registerBegin(token));
      }
    } catch (err) {
      // console.log(err.response);
    }
  };
};

const loginSayasOnStart = (token) => {
  return async (dispatch) => {
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      // console.log(token);
      dispatch(registerBegin(token));
    } catch (err) {
      console.log(err?.response);
    }
  };
};

const LogoutSayasActionCreate = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutSayas());
      // console.log(formData);
    } catch (err) {
      console.log(err.response);
    }
  };
};

export { loginSayas, LogoutSayasActionCreate, loginSayasOnStart };
