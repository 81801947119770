import { useState } from "react";
import vote from "../../assets/images/vote.png";
import addition from "../../assets/images/addition.png";
import styles from "./member.module.css";
import MemberIssueShares from "./MemberIssueShares";
import MemberShareApplicationMoneyForm from "./MemberShareApplicationMoneyForm";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getSayasAccountActionCreater } from "../../Redux/SayasAccount/sayasAccountActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { useFormControlUnstyled } from "@mui/core";
const MembersCard = (props) => {
  const { setConflict } = props;
  const [issueShareModalShow, setIssueShareModalShow] = useState(false);
  const [shareApplicationMoneyModalShow, setShareApplicationMoneyModalShow] =
    useState(false);
  const [card, setCard] = useState("");
  const [totalShares, setTotalShares] = useState(0);
  const [avgShares, setAvgShares] = useState(0.0);
  const [totalShareApplicationMoney, setTotalShareApplicationMoney] =
    useState(0);
  const [issuableShare, setIssuableShare] = useState(0);
  const [shareCapital, setShareCapital] = useState(0);

  const [pricePerShare, setPricePerShare] = useState(0);
  const params = useParams();

  // console.log(params);
  const [update, setUpdate] = useState(false);

  const [memberData, setMemberData] = useState({});
  const [sayasAcc, setSayasAcc] = useState({});
  /// fetch SayasAccount data

  const dispatch = useDispatch();

  const getMemberData = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/" + `${params.id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log("Member Data", response.data);
      return response.data;
    } catch (e) {
      console.log(e);
      alert(e);
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const getSayasData = useSelector((state) => {
    return state;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  //add user role
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  useEffect(() => {
    setUpdate(false);
    dispatch(getSayasAccountActionCreater(getAuthData));
  }, [update]);

  useEffect(async () => {
    let newData = await getMemberData();
    // console.log("fetchdata", newData);
    setMemberData(newData);
    setSayasAcc(getSayasData.sayasAccount);
    setPricePerShare(getSayasData.dashboard.pricePerShare);
  }, [params.id, update]);

  useEffect(() => {
    setTotalShares(memberData?.totalShare === 0 ? "0" : memberData?.totalShare);
    setTotalShareApplicationMoney(
      memberData?.shareApplicationMoney === 0
        ? "0"
        : memberData?.shareApplicationMoney
    );
    setIssuableShare(
      parseInt(memberData?.shareApplicationMoney / pricePerShare) === 0
        ? "0"
        : parseInt(memberData?.shareApplicationMoney / pricePerShare)
    );
    setAvgShares((memberData?.totalShare / sayasAcc?.totalShares) * 100);

    setShareCapital(memberData?.shareCapital);
  }, [memberData, sayasAcc, pricePerShare]);

  // console.log(
  //   "req Data",
  //   totalShares,
  //   totalShareApplicationMoney,
  //   issuableShare,
  //   avgShares
  // );
  // console.log("Loding..... Member.... Card");
  const cardValue = [
    {
      title: "Total Shares",
      icon: vote,
      val: totalShares || 0,
    },

    {
      title: "Share Capital",
      val: shareCapital || 0,
    },

    {
      title: "% Share Holding",
      val: avgShares || 0,
    },

    {
      title: "Share Application Money",
      icon: addition,
      val: totalShareApplicationMoney || 0,
    },
  ];
  return (
    <div>
      <div className="d-flex align-items-center justify-content-around">
        {cardValue.map((item, i) => (
          <div className="d-flex justify-content-between align-items-start">
            <div style={{ padding: " 0 20px" }}>
              <div className="d-flex align-items-start">
                <p className={styles.cardLabel}>{item.title}</p>
                {(i === 0 || i === 3) && userRole.toLowerCase() !== "staff" ? (
                  <img
                    src={item.icon}
                    alt=""
                    id={styles.cardImg}
                    className={`${
                      i === 0 ? "totalShare" : "shareApplicationMoney"
                    }`}
                    onClick={(e) => {
                      //  console.log(e.currentTarget.className);
                      setCard(e.currentTarget.className);
                      if (e.currentTarget.className === "totalShare") {
                        setIssueShareModalShow(true);
                      } else {
                        setShareApplicationMoneyModalShow(true);
                      }
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <p className={styles.cardValue}>
                  {i === 3 ? <CurrencyRupeeIcon sx={{ fontSize: 30 }} /> : ""}
                  {`${item.val >= 0 ? item.val.toLocaleString("en-IN") : "0"}${
                    i === 2 ? "%" : ""
                  }`}
                </p>
              </div>
            </div>
            {i < 3 ? (
              <div
                style={{
                  borderRight: "2px solid #c4c4c4",
                  height: "80px",
                }}
              ></div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
      <div>
        <hr style={{ height: "2px", marginBottom: "30px" }} />
      </div>
      <MemberIssueShares
        totalShareApplicationMoney={totalShareApplicationMoney}
        open={issueShareModalShow}
        setUpdate={setUpdate}
        setOpen={setIssueShareModalShow}
        pricePerShare={pricePerShare}
        issuableShare={issuableShare}
        setConflict2={setConflict}
        label={card}
      />
      <MemberShareApplicationMoneyForm
        setConflict={setConflict}
        setUpdate={setUpdate}
        totalShareApplicationMoney={totalShareApplicationMoney}
        open={shareApplicationMoneyModalShow}
        setOpen={setShareApplicationMoneyModalShow}
        label={card}
      />
    </div>
  );
};

export default MembersCard;
