var initState = {};

export const dashboardReducer = (state = initState, action) => {
  const { type, data, id } = action;

  // console.log("state", state);

  switch (type) {
    case "getDashTransfer":
      // console.log("getDashTransfer Reducer", data);
      return { ...state, dashTransfer: data };
    case "getPricePerShare":
      // console.log("getPricePerShare Reducer", data);
      return { ...state, pricePerShare: data };
    case "getTopShareHolders":
      // console.log("getTopShareHolders Reducer", data);
      return { ...state, topShareHolders: data };
    case "getTotalShareHolders":
      // console.log("getTotalShareHolders Reducer", data);
      return { ...state, totalShareHoldersMembers: data };
    case "getPieCharts":
      // console.log("getTotalShareHolders Reducer", data);
      return { ...state, pieChartDetails: data };
    default:
      return state;
  }
};
