import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styles from "./member.module.css";

const MemberShareCards = (props) => {
  const { item } = props;
  // console.log("member Card", item);
  return (
    <Card
      style={{
        backgroundColor: "#F9F9F9",
        borderRadius: "10px",
        minWidth: "150px",
        height: "100px",
      }}
    >
      <CardContent>
        <Typography id={styles.cardNumber} style={{ color: "#0092E8" }}>
          {item.number ? item.number.toLocaleString("en-IN") : "0"}
        </Typography>
        <Typography id={styles.cardMember}>{item.name}</Typography>
      </CardContent>
    </Card>
  );
};

export default MemberShareCards;
