import React, { useRef } from "react";
import { useState, useEffect } from "react";
import StateCity from "./../../../Components/Data/StateCity";
import { maharashtraCityState } from "./../../../Components/Data/maharastra";
import { newCityState } from "./../../../Components/Data/newCityState";
import { city } from "./../../../Components/Data/maharashtraCity";
import styles from "../users.module.css";
import _ from "lodash";

import statesDistrictsTalukas from "../../../../src/Components/Data/statesDistrictTalukas.json";
const UserStateField = (props) => {
  const { formik, label, name, cityName } = props;
  const [hep, setHep] = useState([]);
  const { values } = formik;
  // console.log("DISTRICTS", districts);
  // const items = newCityState.filter(
  //   (item) => item?.state == cityName
  // );
  // const items = newCityState.filter(
  //   (item) => item?.state == "Maharashtra"
  // );
  // const hep = items.map((item) => item.name);
  // maharashtraCityState.map((item) => item.postOffice);

  // console.log("member : ", maharashtraCityState.length);

  // useEffect(() => {
  //   if (values.state) {
  //     let list = city.map((item) => item.name);
  //     // console.log(list);
  //     const districtsFormatted = statesDistrictsTalukas[values.state]?.map(
  //       (item) => {
  //         return _.mapKeys(item, (value, key) => v.trim(key));
  //       }
  //     );
  //     const districts = [
  //       ...new Set(districtsFormatted.map((item) => item["DISTRICT"])),
  //     ];
  //     setHep(districts);
  //   }
  // }, [values.state]);

  useEffect(() => {
    // console.log("Hit state in advance Search::");
    let districts;
    if (statesDistrictsTalukas[`${values.state}`]) {
      districts = statesDistrictsTalukas[`${values.state}`]?.reduce(
        (ans, ele) => {
          return ans?.includes(ele["DISTRICT"] || "")
            ? ans
            : [...ans, ele["DISTRICT"]];
        },
        []
      );
    } else {
      districts = [];
    }

    // console.log(districts || []);
    setHep([...(districts || [])]);
  }, [values.state]);

  useEffect(() => {
    if (formik.values[`${name}`] !== formik.values[`${name}`]?.toUpperCase()) {
      formik.values[`${name}`] = "";
    }
  }, [[formik.values[`${name}`]], hep]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label className="mb-2" id={styles.userLabel}>
        {label}
        <span className="text-danger"> *</span>
      </label>
      <select
        name={name}
        value={formik.values[`${name}`] || ""}
        onChange={formik.handleChange}
        style={{
          width: "90%",
          textTransform: "capitalize",
          // height: "54px",
          fontFamily: "Mulish",
          borderRadius: "5px",
          border:
            formik.errors[name] && formik.touched[name]
              ? "2px solid red"
              : null,
        }}
        error={formik.errors}
        className={`form-select ${styles.userSelectField}`}
      >
        {hep?.map((e, i) => {
          return (
            <option
              value={e}
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Mulish",
                margin: "14px 10px",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {e}
            </option>
          );
        })}
        <option
          value={""}
          hidden
          selected
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Mulish",
            color: "black",
            textTransform: "capitalize",
          }}
        >
          {""}
        </option>
      </select>
    </div>
  );
};

export default UserStateField;
