import React from "react";
import CsvDownload from "react-json-to-csv";
import "./ExportTable.css";
const ExportTable = (props) => {
  const { data } = props;

  // console.log("In Export file", data);

  return (
    <div>
      <CsvDownload data={data}>
        <div className="exportBtn">
          <i
            class="fa fa-download"
            style={{ color: "#ffffff", fontSize: "22px" }}
          ></i>
        </div>
      </CsvDownload>
    </div>
  );
};

export default ExportTable;
