import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { city } from "../Data/maharashtraCity";

export const MultipleElasticSearch = (props) => {
  const { title, itemList, setSearchData, searchData, keyValue } = props;
  // const [value, setValue] = useState();

  // console.log("city", itemList);

  const handleChange = (event, newValue) => {
    const {
      target: { value },
    } = event;
    // console.log(event.target.value);
    // console.log(newValue);

    setSearchData({
      ...searchData,
      [`${keyValue}`]: newValue,
    });
  };

  // useEffect(() => {
  //   if (searchData[`${keyValue}`]) setValue(searchData[`${keyValue}`] || []);
  // }, []);

  // console.log(value);

  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={itemList.sort()}
      getOptionLabel={(option) => option}
      defaultValue={[]}
      value={searchData[`${keyValue}`] || []}
      onChange={handleChange}
      sx={{
        width: "48%",
        margin: "0.5rem 0rem",
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={title}
          placeholder=""
        />
      )}
    />
  );
};
