import { combineReducers } from "redux";
import CommunicationReducer from "./Communications/reducers";
import { addCofigurationReducer } from "./Configuration/configurationReducers";
import { loaderReducer } from "./Loader/LoaderReducers";
import MemberReducers from "./Members/MemberReducers";
import ShareCapitalReducer from "./ShareCapital/shareCapitalReducers";
import SnackBarReducer from "./Snackbar/SnackBarReducer";
import UserReducers from "./Users/UserReducers";
import dataAcquisitionReducers from "./DataAcquisition/DataAcqReducers";
import { sayasAccountReducer } from "./SayasAccount/sayasAccountReducers";
import AuthReducer from "./Authontication/authonticationReducers";
import { dashboardReducer } from "./Dashboard/dashboardReducers";
import stateDistrictReducers from "./StateDistrictList/stateDistrictReducers";
import reportReducers from "./Reports/reportReducers";

const rootReducers = combineReducers({
  auth: AuthReducer,
  shareCapital: ShareCapitalReducer,
  communication: CommunicationReducer,
  members: MemberReducers,
  users: UserReducers,
  configuration: addCofigurationReducer,
  snackbar: SnackBarReducer,
  loader: loaderReducer,
  dataAcquisition: dataAcquisitionReducers,
  sayasAccount: sayasAccountReducer,
  dashboard: dashboardReducer,
  stateDistrictList: stateDistrictReducers,
  reports: reportReducers,
});

export default rootReducers;
