import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataTable from "../../../Components/Table";
import { loaderEndActionCreater } from "../../../Redux/Loader/LoaderActionCreator";

export const ShareTransferTable = (props) => {
  const { finalData, tableRef, tabValue, columns, columnsStatus } = props;

  // console.log("2022-01-25T12:10:31.384Z : ",moment("2022-01-25T12:10:31.384Z").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-25T12:10:31.384 : ",moment("2022-01-25T12:10:31.384").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-29T06:28:13.880Z : ",moment("2022-01-29T06:28:13.880Z").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-29T06:28:13.880 : ",moment("2022-01-29T06:28:13.880").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-25T06:54:35.976Z : ",moment("2022-01-25T06:54:35.976Z").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-25T06:54:35.976 : ",moment("2022-01-25T06:54:35.976").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-30T19:08:10.655Z : ",moment("2022-01-30T19:08:10.655Z").format("DD MM yyyy kk mm ss"));
  // console.log("2022-01-30T19:08:10.655 : ",moment("2022-01-30T19:08:10.655").format("DD MM yyyy kk mm ss"));

  const options = {
    search: false,
    // selection: true,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
      padding: "8px 5px",
      whiteSpace: "nowrap",
    },
    cellStyle: {
      padding: "10px",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
    maxBodyHeight: "58vh",
    pageSize: 10,
  };

  return (
    <>
      <DataTable
        tableRef={tableRef}
        title=""
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={finalData}
        options={options}
      />
    </>
  );
};
