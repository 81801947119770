import React, { useState } from "react";
import styles from "../DataAcq.module.css";
import { Button, TextField } from "@mui/material";
import { Checkbox, FormControlLabel, Radio } from "@material-ui/core";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import uuid from "react-uuid";
import TypeSelect from "../TypeSelect";
import { handleUpload } from "../../../Components/GeneralComponents/GeneralFileUpload";
import { openSnackbar } from "../../../Redux/Snackbar/snackbarStore";
import { useDispatch } from "react-redux";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import { QuestionHeader } from "../commons/QuestionHeader";
import CONSTANTS from "../commons/Utils";
import { QuestionInput } from "../commons/QuestionInput";

const QuestionForm = (props) => {
  const { questionType, setQuestionType, setAttachmentUrl } = props;
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");
  const [required, setRequired] = useState(false);
  const [type, setType] = useState("Alphabet");
  const [limit, setLimit] = useState("");
  const [option, setOption] = useState([]);
  const [count, setCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [uploadFile, setUploadFile] = useState("No file chosen");
  const [fileURL, setFileUrl] = useState("");
  const dispatch = useDispatch();

  const optionQuestionType = CONSTANTS.optionQuestionType;
  const optionType = CONSTANTS.DATA_TYPES;

  const handleSaved = () => {
    if (CONSTANTS.allowedMultiAnswerQuestionType(questionType)) {
      setCount(count + 1);
      props.setValues([
        ...props.values,
        {
          id: uuid(),
          question: question,
          required: required,
          questionType: questionType,
          options: option,
          type: type,
          attachmentUrl: fileURL,
        },
      ]);
      setQuestion("");
      setRequired(false);
      setQuestionType(CONSTANTS.ACQUISITION_TYPE_BASED_OPTIONS[props.type][0]);
      setEdit(false);
      setFileUrl("");
      setOption([]);
    } else {
      props.setCount(props.count + 1);
      props.setValues([
        ...props.values,
        {
          id: uuid(),
          question: question,
          questionType: questionType,
          type: type,
          required: required,
          limit: limit,
          attachmentUrl: fileURL,
          options: [],
        },
      ]);
      setQuestion("");
      setDescription("");
      setRequired(false);
      setFileUrl("");
      setLimit("");
      setQuestionType(CONSTANTS.ACQUISITION_TYPE_BASED_OPTIONS[props.type][0]);
      setType("Alphabet");
    }
  };

  const saveCopy = () => {
    if (CONSTANTS.allowedMultiAnswerQuestionType(questionType)) {
      setCount(count + 1);

      props.setValues([
        ...props.values,
        {
          id: uuid(),
          question: question,
          required: required,
          attachmentUrl: fileURL,
          questionType: questionType,
          options: option,
          type: type,
        },
      ]);
    } else {
      props.setCount(props.count + 1);
      props.setValues([
        ...props.values,
        {
          id: uuid(),
          question: question,
          questionType: questionType,
          type: type,
          attachmentUrl: fileURL,
          required: required,
          limit: limit,
          options: [],
        },
      ]);

      props.setIndex(props.index + 1);
    }
  };

  function renderActionButton(type) {
    return (
      <Button
        id={styles.addMore}
        label={type}
        onClick={() => {
          let opt = option;
          opt.push({ id: option.length, option: "" });
          setOption([...opt]);
        }}
      >
        {type}
      </Button>
    );
  }

  function renderQuestionBasedOnType(questionType) {
    switch (questionType) {
      case CONSTANTS.QUESTION_TYPES.CHECK_BOX:
      case CONSTANTS.QUESTION_TYPES.MULTIPLE_CHOICE:
        return (
          <div
            className="d-flex justify-content-start"
            style={{ padding: "0 0 0 50px", width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "57%",
              }}
            >
              {option.map((item, i) => (
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <FormControlLabel
                      control={
                        questionType === CONSTANTS.QUESTION_TYPES.CHECK_BOX ? (
                          <Checkbox disabled />
                        ) : (
                          <Radio disabled />
                        )
                      }
                    />
                    <input
                      type="text"
                      name={`Option${i + 1}`}
                      value={item.option}
                      placeholder={`Option ${i + 1}`}
                      className={styles.inputBox}
                      onChange={(e) => {
                        // console.log("onChange", option);
                        let opt = option;
                        opt[i].option = e.target.value;
                        setOption([...opt]);
                      }}
                    />
                  </div>
                  <div>
                    <CloseRoundedIcon
                      style={{ fontSize: 30, marginBottom: 10 }}
                      onClick={() =>
                        setOption(option.filter((opt) => opt.id !== i))
                      }
                    />
                  </div>
                </div>
              ))}
              <div align="left">
                {option.length === 0
                  ? renderActionButton(
                      CONSTANTS.ACTION_BUTTON_LABELS.ADD_OPTIONS
                    )
                  : renderActionButton(CONSTANTS.ACTION_BUTTON_LABELS.ADD_MORE)}
              </div>
            </div>
          </div>
        );
        break;
      default:
        break;
    }
  }

  function renderNormalQuestionBasedOnType(questionType) {
    switch (questionType) {
      case CONSTANTS.QUESTION_TYPES.DATE:
        return <></>;
      case CONSTANTS.QUESTION_TYPES.FILE:
        return <></>;
      default:
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ padding: "0 50px 15px 50px" }}
          >
            <div style={{ width: "60%" }}>
              <TextField
                id={styles.description}
                variant="standard"
                fullWidth
                placeholder={questionType}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled
              />
            </div>

            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "36%" }}
            >
              <div>
                <TypeSelect
                  optionType={optionType}
                  setType={setType}
                  type={type}
                />
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <label className="m-2">Limit</label>
                <TextField
                  id={styles.limit}
                  variant="standard"
                  className="w-25"
                  type="tel"
                  fullWidth
                  min="0"
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                />
              </div>
            </div>
          </div>
        );
    }
  }

  function renderHandleSaveButton() {
    return (
      <div align="right">
        <AddCircleRoundedIcon
          style={{
            fontSize: 35,
            margin: "0 40px 20px 0",
            cursor: "pointer",
          }}
          onClick={() => {
            // console.log(option);
            if (question === "") {
              dispatch(openSnackbar(`Error: Question Field Required`, "error"));
              return;
            }
            // console.log(questionType);
            if (
              questionType === "Multiple Choice" ||
              questionType === "Check Box"
            ) {
              let optionValid = false;
              option.forEach((ele) => {
                if (ele.option !== "") optionValid = true;
                else optionValid = false;
              });
              if (!optionValid) {
                dispatch(
                  openSnackbar(`Error: Please fill value in options`, "error")
                );
                return;
              }
            }
            return handleSaved();
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={styles.questionBack}
      style={{ borderLeft: "15px solid #525252" }}
    >
      {/*ACTIONS*/}
      <QuestionHeader
        required={required}
        saveCopy={saveCopy}
        setRequired={setRequired}
      />
      <div>
        {/*INPUT FOR QUESTION TYPES*/}
        <QuestionInput
          question={question}
          fileURL={fileURL}
          setQuestion={setQuestion}
          onFileChange={async (e) => {
            dispatch(loaderStartActionCreater());
            let fileURL = await handleUpload(e.target.files);
            setFileUrl(fileURL);
            dispatch(loaderEndActionCreater());
          }}
          questionType={questionType}
          optionQuestionType={CONSTANTS.optionQuestionType}
          filterUsers={() => {}}
          type={props.type}
          setQuestionType={setQuestionType}
        />

        {/* DROP DOWN VALUES*/}
        {!CONSTANTS.allowedMultiAnswerQuestionType(questionType)
          ? renderNormalQuestionBasedOnType(questionType)
          : renderQuestionBasedOnType(questionType)}
        {renderHandleSaveButton()}
      </div>
    </div>
  );
};

export default QuestionForm;
