import React, { useEffect, useState } from "react";
import Logo from "../../assets/sayas-logo.png";
import styles from "./LoginForm.module.css";
import { Button, Modal, Typography } from "@mui/material";
import "../Login/login.css";
import { useHistory } from "react-router";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Backdrop from "@mui/material/Backdrop";
import {
  loginSayas,
  LogoutSayasActionCreate,
} from "../../Redux/Authontication/authonticationActionCreator";
import { ProgressBar } from "../../Components/GeneralComponents/ProgressBar";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  // p: 4,
  padding: "2rem 3rem",
};
const LoginForm = () => {
  const [userName, setUserName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [isProgress, setIsProgress] = useState(true);
  const [local, setLocal] = useState(null);
  const [flag, setFlag] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const progressStatus = useSelector((state) => {
    return state.loader.status;
  });
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth;
  });

  const forgotPasswordSendEmail = async () => {
    const data = { email: emailId, type: "user" };
    try {
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/auth/password/forgot",
        data
      );
      // "http://localhost:8080/api/v1/auth/password/forgot",
      // data
      dispatch(
        openSnackbar(
          "Password Reset Link sent on registered email! ",
          "success"
        )
      );
      handleClose();
    } catch (e) {
      // console.log("login Error Message ", e.response.data.error);
      // dispatch(loaderEndActionCreater());
      if (e?.response?.data?.error)
        dispatch(openSnackbar(e?.response?.data?.error, "error"));
    }
  };

  useEffect(() => {
    setIsProgress(progressStatus);
  }, [progressStatus]);
  // console.log(isProgress);

  useEffect(async () => {
    // console.log(getAuthData);
    dispatch(loaderStartActionCreater());
    setIsAuth(getAuthData.isAuth);
  }, [getAuthData]);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(getAuthData);
    setIsAuth(getAuthData.isAuth);
  }, [getAuthData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.classList.remove("MuiButtonBase-root");

    // console.log(userName, password);

    if (userName === "" && password === "") {
      // dispatch(openSnackbar(`Please fill all the Field`, "error"));
      setFlag(true);
    } else if (password === "") {
      // dispatch(openSnackbar(`Please fill the password field`, "error"));
      setFlag(true);
    } else if (
      userName === "" ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userName)
    ) {
      // dispatch(openSnackbar(`Please fill the username field`, "error"));
      setFlag(true);
    } else {
      setFlag(false);
      dispatch(loaderStartActionCreater());
      let newData = {
        email: userName,
        password,
        type: "user",
        rememberMe,
      };
      // console.log(newData);
      dispatch(loginSayas(newData, "new"));
    }
  };

  // /not requeied in programm
  useEffect(() => {
    let localData = {};
    localData = JSON.parse(window.localStorage.getItem("sayasStorage"));
    setLocal(localData);
  }, []);

  useEffect(() => {
    if (local === null) return;
    if (local.userId === null) return;
    setUserName(local.userId);
    setPassword(local.password);
  }, [local]);
  ///////////

  useEffect(() => {
    dispatch(loaderStartActionCreater());
    if (isAuth === true) {
      history.push("/admin");
    } else if (isAuth === false) {
      dispatch(loaderEndActionCreater());
    }
  }, [isAuth]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="limiter"
      style={{
        overFlow: "hidden",
      }}
    >
      <div className={styles.main} align="center">
        <img src={Logo} height="80" width="340" />
        {/* <div className="d-flex flex-column justify-content-center align-items-center">
          <label className={styles.logoHead}>Sayas</label>
          <label className={styles.cooperative}>cooperative</label>
        </div> */}
      </div>
      <div className="container-login100">
        <div
          className="wrap-login100"
          style={{
            width: "42vw",
            padding : "3rem 3rem"
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.5rem 3rem",
            }}
          >
            <form className="login100-form validate-form">
              <span
                className="login100-form-title p-b-32"
                style={{
                  color: "#343434",
                  fontFamily: "Mulish",
                  fontWeight: "bold",
                }}
              >
                Sign In
              </span>

              <div
                className="wrap-input100 mt-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Input
                  className="input100"
                  type="text"
                  error={flag && userName === "" ? true : false}
                  name="username"
                  autoFocus={true}
                  placeholder="Email"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
                {flag &&
                !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                  userName
                ) ? (
                  <span className="text-danger">Please Input Valid Email!</span>
                ) : null}
              </div>

              <div
                className="wrap-input100 mt-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <input
                className="input100"
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              /> */}

                <Input
                  // id="standard-adornment-password"
                  width="90%"
                  className="input100"
                  error={flag && password === "" ? true : false}
                  type={values.showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      hiddenLabel={true}
                      sx={{
                        outline: "none!important",
                      }}
                    >
                      <IconButton
                        aria-label="password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {flag && password == "" ? (
                  <span
                    className="text-danger"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    Please input your password!
                  </span>
                ) : null}
              </div>

              <div className="flex">
                <div className="contact100-form-checkbox mt-2 mb-2">
                  <input
                    className="input-checkbox100"
                    id="ckb1"
                    type="checkbox"
                    name="remember-me"
                    checked={rememberMe}
                    onClick={() => {
                      setRememberMe(!rememberMe);
                    }}
                  />
                  <label className="label-checkbox100" for="ckb1">
                    Remember me
                  </label>
                </div>
                <Box
                  sx={{
                    height: "40px",
                  }}
                >
                  <button
                    className="btnHoverLogin"
                    style={{
                      color: "#fff",
                      backgroundColor: "#0092E8",
                      fontSize: "18px",
                      textTransform: "capitalize",
                      // backgroundColor: "blue",
                      borderRadius: "3px",
                      width: "100%",
                      height: "100%",
                    }}
                    type="button"
                    onClick={handleSubmit}
                  >
                    Sign In
                  </button>
                </Box>
                <div className="mt-2">
                  <a
                    style={{
                      cursor: "pointer",
                    }}
                    className="txt3"
                    onClick={handleOpen}
                  >
                    Forgot Password?
                  </a>
                </div>
                <Modal
                  disablePortal
                  disableEnforceFocus
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <span
                        className="login100-form-title p-b-32"
                        style={{
                          color: "#343434",
                          fontFamily: "Mulish",
                          fontSize: 25,
                          marginBottom: "2rem",
                          textAlign: "center",
                        }}
                      >
                        Forgot your password ?
                      </span>
                      <span
                        className="login100-form-title p-b-32"
                        style={{
                          color: "#343434",
                          fontFamily: "Mulish",
                          fontSize: 18,
                          marginBottom: "1.5rem",
                        }}
                      >
                        Enter your Registered email address, and we'll send you
                        a password reset link.
                      </span>
                    </div>
                    <div
                      className="wrap-input100 mt-3"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Input
                        className="input100"
                        type="text"
                        error={flag && emailId === "" ? true : false}
                        name="emailId"
                        autoFocus={true}
                        placeholder="Enter Email"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                      {flag && emailId == "" ? (
                        <span className="text-danger">
                          Please input your Email!
                        </span>
                      ) : null}
                    </div>
                    <Box
                      sx={{
                        height: "40px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginTop: "1rem",
                      }}
                    >
                      <button
                        className="btnHoverLogin"
                        style={{
                          color: "#fff",
                          backgroundColor: "#0092E8",
                          fontSize: "18px",
                          textTransform: "capitalize",
                          // backgroundColor: "blue",
                          borderRadius: "3px",
                          width: "20%",
                          height: "100%",
                        }}
                        type="button"
                        onClick={forgotPasswordSendEmail}
                      >
                        Send
                      </button>
                    </Box>
                  </Box>
                </Modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
