
const initState = null;

const stateDistrictReducers = (state = initState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case "GET_DATA":
      return { ...state, data };
    default:
      return state;
  }
};
export default stateDistrictReducers;
