import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import DashboardLayout from "../Layout/DashboardLayout";
import {
  loginSayas,
  loginSayasOnStart,
} from "../Redux/Authontication/authonticationActionCreator";
import { loaderStartActionCreater } from "../Redux/Loader/LoaderActionCreator";
import LoginForm from "../Views/Login/LoginForm";
import ResetPasswordForm from "../Views/Login/ResetPasswordForm";
import Widget from '../Views/Widget/Widget'

const Routes = () => {
  const [isAuth, setIsAuth] = useState(null);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  // let token = window.localStorage.getItem("sayasLoginToken");
  // useEffect(() => {
  //   if (token) dispatch(loginSayasOnStart(token));
  // }, []);

  useEffect(() => {
    setCount(count + 1);
  }, [isAuth]);

  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth;
  });

  useEffect(() => {
    // console.log(getAuthData);
    setIsAuth(getAuthData.isAuth);
  }, [getAuthData]);

  ////private router
  // console.log("isAuth", isAuth);

  function PrivateRoute({ children }) {
    return isAuth ? children : count > 1 ? <Redirect to="/login" /> : children;
  }

  useEffect(() => {
    dispatch(loginSayas());
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/login" component={LoginForm} />
        <Route exact path="/support" component={Widget} />
        <Route
          exact
          path="/resetPassword/:token"
          component={ResetPasswordForm}
        />
        <Route exact path="/">
          <PrivateRoute>
            <Redirect to="/admin" />
          </PrivateRoute>
        </Route>

        <Route path="/admin">
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        </Route>
      </Switch>
    </>
  );
};

export default Routes;
