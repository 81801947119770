import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../Components/Table";
import { loaderEndActionCreater } from "../../../Redux/Loader/LoaderActionCreator";

const ShareholdingDistributionTable = (props) => {
  const {
    finalData,
    tabValue,
    tableRef,
    columns,
    columnsStatus,
    finalAdvanceSearchData,
  } = props;

  const options = {
    search: false,
    // selection: true,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 18,
      fontFamily: "Mulish",
      whiteSpace: "nowrap",
      padding: "8px 5px",
    },
    cellStyle: {
      padding: "10px",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
    maxBodyHeight: "58vh",
    pageSize: 10,
  };

  // console.log("finalData*******", finalData);

  return (
    <>
      <DataTable
        tableRef={tableRef}
        title=""
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={finalData}
        options={options}
      />
    </>
  );
};

export default ShareholdingDistributionTable;
