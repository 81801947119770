import { Button, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";
// import DatePicker from "@mui/lab/DatePicker";
import { DatePicker } from "@mui/x-date-pickers";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NewMemberExportHeader } from "./NewMemberExportHeader";
import axios from "axios";
import { BASE_URL } from "./../../Components/URL/Url";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import ExportTable from "../../Components/Export/ExportTable";
import { Grid } from "@mui/material";
import moment from "moment";
import exportFromJSON from "export-from-json";
const fileName = "export";
const exportType = exportFromJSON.types.csv;

function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export const NewMemberExport = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  // console.log("data");

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  const getMemberData = async () => {
    if (startDate === null && endDate === null) {
      dispatch(openSnackbar("Please enter start and end date", "error"));
      return;
    } else if (endDate === null) {
      dispatch(openSnackbar("Please enter end date", "error"));
      return;
    } else if (startDate === null) {
      dispatch(openSnackbar("Please enter start date", "error"));
      return;
    }
    dispatch(loaderStartActionCreater());
    // console.log(
    //   "date in backend",
    //   startDate.toISOString(),
    //   endDate.toISOString()
    // );
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let newBody = {
        startDate: new Date(
          moment(startDate).startOf("day").utcOffset("+05:30")
        ),
        endDate: new Date(moment(endDate).endOf("day").utcOffset("+05:30")),
      };

      // console.log(newBody);
      // "http://localhost:8080" + `/api/v1/member/export/allMembers`,
      let response = await axios.get(
        BASE_URL + `/api/v1/member/export/allMembers`,
        {
          params: { ...newBody },
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      if (response?.data?.members?.length <= 0) {
        dispatch(openSnackbar("Data not available", "error"));
      }
      setExportData(response?.data?.members);
      // setData(response.data.data);
      dispatch(loaderEndActionCreater());
    } catch (err) {
      let errors = err?.response?.data?.error;
      if (errors) dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      dispatch(loaderEndActionCreater());
    }
  };

  // console.log("data in export new Member : ", exportData);

  // useEffect(() => {
  //   filterData();
  // }, [data]);

  const filterData = () => {
    let newExport = data.filter((ele) => {
      return (
        convert(startDate) <= convert(ele.createdAt) &&
        convert(endDate) >= convert(ele.createdAt)
      );
    });

    setExportData([...newExport]);
    dispatch(loaderEndActionCreater());
    dispatch(openSnackbar("Data fetch succssfully", "success"));
    // console.log("export efect", newExport);
  };

  // useEffect(() => {
  //   filterData();
  // }, [data]);

  console.log("exportData", exportData);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexWrap: "wrap",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "25px",
        padding: "10px 30px",
      }}
    >
      {/* <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "25px",
          padding: "10px 30px",
          borderBottom: "1px solid #3335389e",
        }}
      >
        <h1
          style={{
            fontFamily: "Mulish",
            fontStyle: "normal",
            fontWeight: "bold",
            fontSize: "30px",
            color: "rgb(88, 88, 88)",
            padding: "0px 20px",
          }}
        >
          Export Members
        </h1>
        {startDate && endDate ? (
          <div>
            <ExportTable data={[{ name: "Sandeep" }]} />
          </div>
        ) : (
          ""
        )}
      </div> */}

      <NewMemberExportHeader
        startDate={startDate}
        endDate={endDate}
        exportData={exportData}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "space-around",
          gap: "25px",
          padding: "10px 30px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="Start Date"
            // openTo="year"
            width="100%"
            inputFormat="dd/MM/yyyy"
            // views={["year", "month", "day"]}
            value={startDate}
            onChange={(newValue) => {
              // console.log(newValue, new Date(newValue));
              setStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disableFuture
            label="End Date"
            minDate={startDate}
            // openTo="year"
            inputFormat="dd/MM/yyyy"
            disabled={startDate ? false : true}
            width="100%"
            // views={["year", "month", "day"]}
            value={endDate}
            onChange={(newValue) => {
              // console.log(newValue);
              setEndDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <button
            style={{
              padding: ".4rem 1.5rem",
              backgroundColor: `${
                startDate && endDate ? "#0096FF" : "#0092e83b"
              }`,
              fontSize: "1.2rem",
              color: `${startDate && endDate ? "#fff" : "none"}`,
              fontWeight: 500,
              borderRadius: "5px",
            }}
            onClick={getMemberData}
          >
            Fetch
          </button>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              ml: "1rem",
            }}
          >
            {exportData.length > 0 ? (
              // <div>
              //   <ExportTable
              //     data={exportData.map((e) => {
              //       return {
              //         firstName: e.firstName,
              //         middleName: e.middleName,
              //         lastName: e.lastName,
              //         email: e.email,
              //         dob: e.dob,
              //         gender: e.gender,
              //         religion: e.religion,
              //         caste: e.caste,
              //         commAddressLine_1: e.commAddressLine_1,
              //         commAddressLine_2: e.commAddressLine_2,
              //         commAddressDistrict: e.commAddressCity,
              //         commAddressState: e.commAddressState,
              //         commAddressTaluka: e.taluka,
              //         commAddressVillage: e.village,
              //         commAddressPincode: e.commAddressPincode,
              //         permanentAddressLine_1: e.permanentAddressLine_1,
              //         permanentAddressLine_2: e.permanentAddressLine_2,
              //         permanentAddressDistrict: e.permanentAddressCity,
              //         permanentAddressState: e.permanentAddressState,
              //         permanentAddressTaluka: e.pertaluka,
              //         permanentAddressVillage: e.permanentVillage,
              //         permanentAddressPincode: e.permanentAddressPincode,
              //         contactNo: e.contactNo,
              //         panNumber: e.panNumber,
              //         aadharNumber: e.aadharNumber,
              //         familySize: e.familySize,
              //         nomineeName: e.nomineeName,
              //         nomineeRelation: e.nomineeRelation,
              //         occupationType: e.occupationTypevalue,
              //         organizationName: e.organizationName,
              //         jobDescription: e.jobDescription,
              //         orgAddressLine_1: e.orgAddressLine_1,
              //         orgAddressLine_2: e.orgAddressLine_2,
              //         orgDistrict: e.orgCity,
              //         orgState: e.orgState,
              //         orgTaluka: e.orgTaluka,
              //         orgVillage: e.orgVillage,
              //         orgPincode: e.orgPincode,
              //         incomeRange: e.incomeRange,
              //         idProof: e.idProof,
              //         addressProof: e.addressProof,
              //         paymentProof: e.paymentProof,
              //         otherDocumentProof: e.otherDocumentProof,
              //       };
              //     })}
              //   />
              // </div>
              <div
                className="exportBtn"
                style={{
                  background: "#0096FF!important",
                }}
                onClick={() => {
                  // console.log(tableRef.current.state.data);
                  exportFromJSON({
                    data: exportData.map((e) => {
                      return {
                        firstName: e.firstName,
                        middleName: e.middleName,
                        lastName: e.lastName,
                        email: e.email,
                        dob: e.dob,
                        gender: e.gender,
                        religion: e.religion,
                        caste: e.caste,
                        commAddressLine_1: e.commAddressLine_1,
                        commAddressLine_2: e.commAddressLine_2,
                        commAddressDistrict: e.commAddressCity,
                        commAddressState: e.commAddressState,
                        commAddressTaluka: e.taluka,
                        commAddressVillage: e.village,
                        commAddressPincode: e.commAddressPincode,
                        permanentAddressLine_1: e.permanentAddressLine_1,
                        permanentAddressLine_2: e.permanentAddressLine_2,
                        permanentAddressDistrict: e.permanentAddressCity,
                        permanentAddressState: e.permanentAddressState,
                        permanentAddressTaluka: e.pertaluka,
                        permanentAddressVillage: e.permanentVillage,
                        permanentAddressPincode: e.permanentAddressPincode,
                        contactNo: e.contactNo,
                        panNumber: e.panNumber,
                        aadharNumber: e.aadharNumber,
                        familySize: e.familySize,
                        nomineeName: e.nomineeName,
                        nomineeRelation: e.nomineeRelation,
                        occupationType: e.occupationTypevalue,
                        organizationName: e.organizationName,
                        jobDescription: e.jobDescription,
                        orgAddressLine_1: e.orgAddressLine_1,
                        orgAddressLine_2: e.orgAddressLine_2,
                        orgDistrict: e.orgCity,
                        orgState: e.orgState,
                        orgTaluka: e.orgTaluka,
                        orgVillage: e.orgVillage,
                        orgPincode: e.orgPincode,
                        incomeRange: e.incomeRange,
                        idProof: e.idProof,
                        addressProof: e.addressProof,
                        paymentProof: e.paymentProof,
                        otherDocumentProof: e.otherDocumentProof,
                      };
                    }),
                    fileName,
                    exportType,
                  });
                }}
              >
                <i
                  className="fa fa-download"
                  style={{
                    color: "#ffffff",
                    background: "#0096FF!important",
                    fontSize: "22px",
                  }}
                ></i>
              </div>
            ) : (
              ""
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};
