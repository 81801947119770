import actions from "./DataAcqAction";

const { DATA_ACQ_CREATE, SELECTED_DATA_ACQ } = actions;

const initState = {
  user: null,
  loading: false,
  error: null,
  formData: {},
  selectedRow: [],
  columnsStatus: {
    dataAcquisitionId: true,
    type: true,
    startDate: true,
    endDate: true,
    responces: true,
    status: true,
    title: true,
  },
};

const dataAcquisitionReducers = (state = initState, action) => {
  const { type, data, err } = action;
  // console.log("3", data);
  switch (type) {
    case DATA_ACQ_CREATE:
      return { ...state, formData: data };
    case SELECTED_DATA_ACQ:
      return { ...state, selectedRow: data };
      case "COLUMNSTATUS":
      return {
        ...state,
        columnsStatus: {
          ...state.columnsStatus,
          [`${data}`]: state.columnsStatus[`${data}`]
            ? state.columnsStatus[`${data}`]
              ? false
              : true
            : true,
        },
      };
      default:
      return state;
  }
};
export default dataAcquisitionReducers;
