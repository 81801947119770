import React, { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { number } from "echarts";
const colorList = [
  "#e67e22",
  "#e74c3c",
  "#f39c12",
  "#9b59b6",
  "#95a5a6",
  "#3498db",
  "#55efc4",
  "#74b9ff",
  "#fd79a8",
  "#fdcb6e",
  "#7ed6df",
  "#ffbe76",
  "#686de0",
  "#be2edd",
  "#F79F1F",
  "#A3CB38",
  "#1289A7",
];

export const PieChartDataAcq = (props) => {
  const { pieChartsData, que } = props;

  // console.log(pieChartsData,  Object.keys(pieChartsData));
  const [data, setData] = useState([]);
  const [queNumber, setQueNumber] = useState();

  useEffect(() => {
    let newData = Object.keys(pieChartsData)
      .map((ele, i) => {
        if (ele.title !== "undefined") {
          return {
            name: ele,
            y: pieChartsData[`${ele}`] || 0,
            color: colorList[(i + 6) % colorList.length],
          };
        } else {
          return undefined;
        }
      })
      .filter((e) => {
        return e.title != "undefined";
      });
    setData(newData);
    // console.log("data in DA", newData);
  }, [pieChartsData]);

  // console.log("$$$$$$$$$$$$$$$$", pieChartsData);

  const dispatch = useDispatch();

  const options = {
    chart: {
      // plotBackgroundColor: "lightBlue",
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      width: 700,
      height: 300,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      // percentage: "number",
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        dataLabels: {
          enabled: true,
          format: "{point.name} {point.percentage:.2f}%",
          style: {
            maxWidth: "2rem",
          },
          // format: '<b>{point.name}</b>: <br /> {point.percentage:.1f} %'
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            // maxWidth: 800,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            yAxis: {
              labels: {
                align: "left",
                // x: 0,
                // y: -5,
              },
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
    title: {
      text: null,
    },
    series: [
      {
        name: "",
        colorByPoint: true,
        dataLabels: {
          enabled: true,
          // y: -5,
          style: {
            fontSize: "13px",
            fontWeight: "600",
          },
        },
        data: data.filter(
          (ele) => ele.y > 0 && ele.name !== "sayasQuestionNumber"
        ),
        sliced: true,
        selected: true,
      },
    ],
  };

  // console.log("options", data);

  return (
    <div
      style={{
        // border : "1px solid black",
        // width: "30%",
        border: "1px solid #F2F2F2",
        borderRadius: "5px",
        // width: "100%",
        // height: "310px",
        paddingTop: "20px",
        marginRight: "5px",
      }}
      // className="col-3"
    >
      <h6>{`${pieChartsData.sayasQuestionNumber + 1}. ${que}`}</h6>
      {/* <h6>{que}</h6> */}

      <div
        className="d-flex justify-content-evenly align-items-start"
        style={{
          border: "1px solid #F2F2F2",
          borderRadius: "5px",
          // width: "100%",
          // height: "310px",
          padding: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="pieChartContainer">
          <HighchartsReact highcharts={Highcharts} options={options} />

          <div>
            {Object.keys(pieChartsData).length > 0
              ? Object.keys(pieChartsData).map((ele, i) => {
                  if (
                    ele !== "sayasQuestionNumber" &&
                    pieChartsData[`${ele}`] === 0
                  ) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          margin: "5px 0px",
                        }}
                      >
                        <div
                          style={{
                            height: "1rem",
                            width: "1rem",
                            // backgroundColor: `${
                            //   colorList[(i + 8) % colorList.length]
                            // }`,
                            boxShadow : "0px 0px 2px black",
                            margin: "0px 5px",
                          }}
                        ></div>
                        <div style={{ fontSize: "12px" }}>{ele} - 0%</div>
                      </div>
                    );
                  }
                })
              : "No Record to display"}
          </div>
        </div>
      </div>
    </div>
  );
};
