import { Grid } from "@mui/material";
import Button from "@restart/ui/esm/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { CommunicationForm } from "./CommunicationForm";
import { NotificationForm } from "./NotificationForm";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "./CommunicationForm.css";
import { GeneralSelectFieldForHeader } from "../../Components/GeneralComponents/GeneralSelectFieldForHeader";
import CsvDownload from "react-json-to-csv";
import ExportTable from "../../Components/Export/ExportTable";
import MaterialSelect from "../../Components/SelectDropdown/MaterialSelect";
import moment from "moment";
import exportFromJSON from "export-from-json";
const fileName = "download";
const exportType = exportFromJSON.types.csv;

export const CommunicationHeader = (props) => {
  const {
    setTableUpdate,
    tableUpdate,
    tabValue,
    setTabValue,
    columnsStatus,
    setColumnsStatus,
    data,
    tableRef,
  } = props;
  var [open, setopen] = useState(false);
  const { openModal, setOpenModal, currentRowData, setCurrentRowData } = props;
  const options = [
    "All Communication",
    "Announcement",
    "Notification",
    "Alert",
    "News",
  ];

  // console.log(tabValue);

  const handleTabValue = (e) => {
    setTabValue(e.target.value);
    // console.log(e.target.value);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportData, setExportData] = React.useState([]);

  const handlePopOver = (event) => {
    // setAnchorEl(event.currentTarget);
    // console.log(event.currentTarget);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
    // setTableUpdate(!tableUpdate)
    setCurrentRowData([]);
  };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    var colsKeys = Object.keys(columnsStatus);
    const mappedArrayData = data?.map((e) => {
      let data = colsKeys.map((ele) => {
        if (columnsStatus[ele] === true) {
          return e[ele];
        }
      });
      let finalAns = data.filter((e) => {
        return e != undefined;
      });

      return finalAns;
    });

    mappedArrayData?.unshift(
      colsKeys.filter((e) => {
        return columnsStatus[e] === true;
      })
    );
    // let cleanData = mappedArrayData?.map((item) =>
    //   item.map((e, i) => {
    //     return e == true ? "True" : e == false ? "False" : e;
    //   })
    // );

    setExportData(mappedArrayData);
  }, [data, columnsStatus]);

  return (
    <div style={{ margin: "20px 30px" }}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderColor: "grey.500",
        }}
      >
        <Grid>
          <Grid sx={{ fontSize: "1.4rem" }}>
            <MaterialSelect
              option_users={options}
              optionVal={tabValue}
              filterUsers={setTabValue}
            />
          </Grid>
          {/* <GeneralSelectFieldForHeader
            data={tabValue}
            setData={setTabValue}
            items={options}
            // width = "16rem"
            width={"max-content"}
          /> */}
        </Grid>

        <Grid
          spacing={3}
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            m: 1,
            justifyContent: "space-around",
            gap: "20px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <ExportTable
              data={
                data
                  ? data.map((e) => {
                      return {
                        communicationType: e.communicationType,
                        dateAndTime: moment(e.dateAndTime).format(
                          "DD/MMM/YYYY HH:mm:ss"
                        ),
                        title: e.title,
                        visibleTo: e.visibleTo,
                        description: e.description,
                        attachmentUrl: e.attachmentUrl,
                      };
                    })
                  : []
              }
            /> */}

            <div
              className="exportBtn"
              onClick={() => {
                exportFromJSON({
                  data: tableRef.current.state.data,
                  fileName,
                  exportType,
                });
              }}
            >
              <i
                className="fa fa-download"
                style={{ color: "#ffffff", fontSize: "22px" }}
              ></i>
            </div>
          </Grid>
          <div
            // className="sendBtn"
            style={{
              backgroundColor: "#C4C4C4!important",
              borderRadius: "5px",
              outline: "none",
              height: "40px",
              border: "none",
              // marginRight : "-4rem",
            }}
          >
            <Button
              // aria-describedby={id}
              className="sendBtn"
              variant="contained"
              onClick={handlePopOver}
              sx={{
                backgroundColor: "#0096FF",
              }}
              style={{
                borderRadius: "5px",
                color: "#FFFFFF",
                padding: "5px 15px",
                fontFamily: "Mulish",
                backgroundColor: "#0096FF",
                fontStyle: "noraml",
                fontWeight: "bold",
                textTransform: "uppercase",
                whiteSpace: "nowrap",
              }}
            >
              Create New
            </Button>
            <Popover
              id="simple-popover"
              open={openModal}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              style={{ top: 150, right: 123 }}
            >
              <Typography sx={{ p: 2 }}>
                <CommunicationForm
                  setOpenModal={setOpenModal}
                  currentRowData={currentRowData}
                  setCurrentRowData={setCurrentRowData}
                  tableUpdate={tableUpdate}
                  setTableUpdate={setTableUpdate}
                  totalDataLength={data?.length || 0}
                />
              </Typography>
            </Popover>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
