import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import axios from "axios";
import { BASE_URL } from "../../Components/URL/Url";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import OutlinedInput from "@mui/material/OutlinedInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 5,
  pt: 3,
  pb: 3,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function UpdateUserTeamsModal(props) {
  const {
    open,
    setOpen,
    userIds,
    setOptions,
    option_action_1,
    setOptionVal,
    getTableDetails,
  } = props;

  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOptions(option_action_1);
    setOptionVal("All Users");
    dispatch(loaderEndActionCreater());
    getTableDetails();
  };
  const [value, setValue] = useState([]);
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);

  const selectedUsers = useSelector((state) => state.users.selectedRow);

  // console.log(value);
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  useEffect(() => {
    setRoles(
      getConfigData?.roles?.map((e) => {
        return e.type;
      })
    );
    setTeams(
      getConfigData?.teams?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  const handleBulkUpdate = async () => {
    const userData = { userIds: selectedUsers.map((user) => user.id), team: value };
    dispatch(loaderStartActionCreater());
    setOpen(false);
    try {
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      await axios.post(
        BASE_URL + `/api/v1/users/updateBulkUserTeam`,
        userData,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      getTableDetails();
      dispatch(openSnackbar("Users Teams Successfully Updated!", "success"));
    } catch (err) {
      // console.log(err);
      let errors = err?.response?.data?.error;
      if (errors) dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
    setOptions(option_action_1);
    setOptionVal("All Users");
    dispatch(loaderEndActionCreater());
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
              color: "grey",
              // background : "grey",
              marginBottom: ".5rem",
              width: "100%",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            Select Teams
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Teams</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={value}
              fullWidth
              multiple
              label="Teams"
              input={<OutlinedInput label="Teams" />}
              onChange={handleChange}
              MenuProps={MenuProps}
            >
              <MenuItem value="" hidden selected></MenuItem>
              {teams.map((ele) => {
                return <MenuItem value={ele}>{ele}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              sx={{
                width: "rem",
                marginTop: "10px",
                marginRight: "10px",
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "rem",
                marginTop: "10px",
              }}
              onClick={() => {
                if (value.length <= 0) {
                  dispatch(
                    openSnackbar("PLease select a Teams First.", "error")
                  );
                  return;
                }
                handleBulkUpdate();
              }}
            >
              Update
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
