import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styles from "./MenuDropdown.module.css";
import MemberModal from "./../../Views/Members/MemberModal";
import FileUploadDialogue from "./../../Views/Users/UsersForm/FileUploadDialogue";
import MemberFileUpload from "./../../Views/Members/MembersForm/MemberFileUpload";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import Export from "./../../Views/Users/UsersForm/Export";
import CsvDownload from "react-json-to-csv";
import { useHistory, useRouteMatch } from "react-router";
import MemberShareApplication from "../../Views/Members/MembersForm/MemberShareApplication";
import MemberIssueShares from "../../Views/Members/MemberIssueShares";
import { useDispatch, useSelector } from "react-redux";
import { getSayasAccountActionCreater } from "../../Redux/SayasAccount/sayasAccountActionCreator";
import { clearSelectedMemberRows } from "../../Redux/Members/actionCreator";
// import { clearSelectedMemberRows } from "../../Redux/Members/actionCreator";
import axios from "axios";
import {
  loaderStartActionCreater,
  loaderEndActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import UpdateMemberType from "../../Views/Members/UpdateMemberType";
import exportFromJSON from "export-from-json";
import MemberIssueSharesMemberInfo from "../../Views/Members/MemberIssueSharesMemberInfo";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
const fileName = "download";
const exportType = exportFromJSON.types.csv;
export default function MenuDropdown(props) {
  const {
    options,
    handleActivateStatus,
    setOptions,
    setData,
    data,
    dataAquisition,
    finalData,
    setConflict,
    fianlFilteredData,
    dataLength,
    selectedUsers,
    memberDataLength,
    columnsStatus,
    selectedData,
    id,
    tableRef,
    setIsAction,
    label,
  } = props;

  // console.log("selectedData", selectedData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMember, setOpenMember] = React.useState(null);
  const [openShareApplication, setOpenShareApplication] = React.useState(null);
  const [openFile, setOpenFile] = React.useState(false);
  const [openUpdateMemberType, setOpenUpdateMemberType] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [exportData, setExportData] = React.useState([]);
  const [pricePerShare, setPricePerShare] = useState(0);
  const [issueBulkShareModalShow, setIssueBulkShareModalShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const dashboardData = useSelector((state) => {
    // console.log("In sayas account fetch, Member :");
    return state.sayasAccount;
  });
  const [newSelectedData, setNewSelectedData] = useState([]);

  useEffect(() => {
    setNewSelectedData(selectedData);
  }, [selectedData]);

  const history = useHistory();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getSayasData = useSelector((state) => {
    return state.dashboard;
  });

  // const getSayasData = useSelector((state) => {
  //   return state.dashboard;
  // });

  useEffect(() => {
    if (getAuthData) {
      setUpdate(false);
      dispatch(getSayasAccountActionCreater(getAuthData));
    }
  }, [update, getAuthData]);

  useEffect(() => {
    dispatch(getConfiguationActionCreater());
  }, []);

  useEffect(async () => {
    setPricePerShare(getSayasData.pricePerShare);
  }, [getSayasData]);

  const { path } = useRouteMatch();

  // console.log("In MenuDropDown token", getAuthData);

  const updateSavetoLaunch = async (id, val, name) => {
    try {
      // console.log(id, val, name);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/dataAcquisition/darftToLaunch/" +
          `${id}`,
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      history.push("/admin/data-acquisition");

      // console.log("after api call..");

      // dispatch(openSnackbar("Update Data Aquisition Failed", "success"));

      dispatch(openSnackbar(`Update Data Aquisition Successfully`, "success"));

      // dispatch(openSnackbar(`${name} ${val?.standing}`, "success"));
      dispatch(loaderEndActionCreater());
      // setConflict(true);
      // console.log("after api call.. 2 ");
    } catch (e) {
      // dispatch(openSnackbar("Update Data Aquisition Failed", "error"));
      dispatch(loaderEndActionCreater());
      // alert(e);
      if (e?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const bulkUpdateSavetoLaunch = async (stand, val) => {
    try {
      // console.log(stand);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }

      // console.log("start API...", stand, val);

      const data = {
        standing: stand,
        formIds: val,
      };

      let response = await axios.put(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/dataAcquisition/bulkDarftToLaunch/" +
          `${stand}`,
        data,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log("end API...", response);
      setConflict(true);
      history.push("/admin/data-acquisition");
      dispatch(
        openSnackbar(
          `Selected Data Aquisition ${stand} Successfully`,
          "success"
        )
      );
      dispatch(loaderEndActionCreater());
    } catch (e) {
      dispatch(openSnackbar("Update Data Aquisition Failed", "error"));
      dispatch(loaderEndActionCreater());
      // alert(e);
      if (e?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  // console.log(selectedUsers);

  const handleModal = (event) => {
    // console.log(event);

    if (event === "Survey") {
      history.push(`data-acquisition/add-form/survey`);
    } else if (event === "Poll") {
      history.push(`data-acquisition/add-form/poll`);
    } else if (event === "Application") {
      history.push(`data-acquisition/add-form/application`);
    } else if (event === "Share Transfer") {
      setModalShow(true);
      if (setIsAction) setIsAction(true);
    } else if (event === "Import User") {
      setOpenFile(true);
    } else if (event === "Import Members") {
      setOpenMember(true);
      if (setIsAction) setIsAction(true);
    } else if (event === "Update Member Type") {
      setOpenUpdateMemberType(true);
      if (setIsAction) setIsAction(true);
    } else if (event === "Import Share Application") {
      setOpenShareApplication(true);
      if (setIsAction) setIsAction(true);
    } else if (event === "Edit/View") {
      // console.log("DATA", data);
      history.push(`update-form/${id}`);
    } else if (event === "Issue Shares") {
      setIssueBulkShareModalShow(true);
      if (setIsAction) setIsAction(true);
    } else if (event === "Cancel") {
      if (
        !module &&
        selectedUsers?.length > 0 &&
        selectedUsers[0]?.dataAcquisitionId
      ) {
        bulkUpdateSavetoLaunch(
          "cancelled",
          selectedUsers?.map((ele) => {
            return {
              id: ele.id,
              standing: ele.standing,
              endDate: new Date(),
              date: {
                startDate: ele.startDate,
                endDate: ele.endDate,
              },
            };
          })
        );
      } else if (dataAquisition) {
        updateSavetoLaunch(
          dataAquisition.id,
          {
            standing: "cancelled",
            endDate: new Date(),
          },
          dataAquisition.title
        );
      }
    } else if (event === "Launch") {
      if (
        !module &&
        selectedUsers?.length > 0 &&
        selectedUsers[0]?.dataAcquisitionId
      ) {
        bulkUpdateSavetoLaunch(
          "launch",
          selectedUsers?.map((ele) => {
            return {
              id: ele.id,
              standing: ele.standing,
              date: {
                startDate: ele.startDate,
                endDate: ele.endDate,
              },
            };
          })
        );
        // } else if (selectedUsers?.length > 0 || !dataAquisition) {
        //   dispatch(openSnackbar("Please select valid Data Aquisition", "error"));
      } else if (dataAquisition) {
        updateSavetoLaunch(
          dataAquisition?.id,
          {
            standing: "launch",
          },
          dataAquisition?.title
        );
      }
    } else if (event === "Close") {
      if (
        !module &&
        selectedUsers?.length > 0 &&
        selectedUsers[0]?.dataAcquisitionId
      ) {
        bulkUpdateSavetoLaunch(
          "closed",
          selectedUsers?.map((ele) => {
            return {
              id: ele?.id,
              standing: ele?.standing,
              endDate: new Date(),
              date: {
                startDate: ele.startDate,
                endDate: ele.endDate,
              },
            };
          })
        );
        // } else if (selectedUsers?.length > 0 || !dataAquisition) {
        //   dispatch(openSnackbar("Please select valid Data Aquisition", "error"));
      } else if (dataAquisition) {
        updateSavetoLaunch(
          dataAquisition?.id,
          {
            standing: "closed",
            status: false,
            endDate: new Date(),
          },
          dataAquisition?.title
        );
      }
    } else if (event === "Draft") {
      if (
        !module &&
        selectedUsers.length > 0 &&
        selectedUsers[0].dataAcquisitionId
      ) {
        bulkUpdateSavetoLaunch(
          "save",
          selectedUsers?.map((ele) => {
            return {
              id: ele?.id,
              standing: ele.standing,
            };
          })
        );
      }
      // } else if (selectedUsers?.length > 0 || !dataAquisition) {
      //   dispatch(openSnackbar("Please select valid Data Aquisition", "error"));
    }
  };

  // useEffect(() => {
  //   if (columnsStatus) {
  //     var colsKeys = Object.keys(columnsStatus);
  //     const mappedArrayData = data?.map((e) => {
  //       let data = colsKeys.map((ele) => {
  //         if (columnsStatus[ele] === true) {
  //           return e[ele];
  //         }
  //       });
  //       let finalAns = data.filter((e) => {
  //         return e != undefined;
  //       });
  //       // console.log(finalAns);
  //       return finalAns;
  //     });

  //     mappedArrayData?.unshift(
  //       colsKeys.filter((e) => {
  //         return columnsStatus[e] === true;
  //       })
  //     );

  //     let cleanData = mappedArrayData?.map((item) =>
  //       item.map((e, i) => {
  //         return e == true ? "True" : e == false ? "False" : e;
  //       })
  //     );

  //     console.log("mappedArrayData", cleanData);

  //     setExportData(cleanData);
  //   }
  // }, [columnsStatus, finalData]);

  useEffect(() => {
    if (!issueBulkShareModalShow) {
      clearSelectedMemberRows();
    }
  }, [issueBulkShareModalShow]);

  // console.log("selectedUsers", selectedUsers);

  return (
    <div>
      <Button
        id={styles.btn}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          width: "max-contant",
          bgcolor: "#0096FF!important",
        }}
      >
        {props.label === "create" ? "Create New" : "Action"}
        {open ? (
          <i className="fa fa-caret-up" style={{ marginLeft: 10 }} />
        ) : (
          <i className="fa fa-caret-down" style={{ marginLeft: 10 }} />
        )}
      </Button>
      {/* <Export data={data} /> */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((item) => (
          <MenuItem
            id={styles.menuItem}
            onClick={() => {
              if (props.label !== "create") {
                handleActivateStatus(item, selectedUsers, setOptions);
                handleClose();
              }
              handleModal(item);
            }}
          >
            {item == "Export User" ? (
              <>
                {/* // <CsvDownload
              //   data={
              //     data
              //       ? data.map((e) => {
              //           return {
              //             userId: e.userId,
              //             firstName: e.firstName,
              //             middleName: e.middleName,
              //             lastName: e.lastName,
              //             email: e.email,
              //             phone: e.phone,
              //             role: e.role,
              //             teams: e.team?.join(" / "),
              //             activityStatus: e.activityStatus
              //               ? "Active"
              //               : "Inactive",
              //           };
              //         })
              //       : []
              //   }
              //   id={styles.menuItem}
              // >
              //   Export User
              // </CsvDownload> */}
                <div
                  // className="exportBtn"
                  onClick={() => {
                    exportFromJSON({
                      data: tableRef.current.state.data.map((ele) => {
                        // ele.team = ele.team.join(", ");
                        // console.log("In user Export::", ele);
                        return { ...ele, team: ele.team.join(", ") };
                      }),
                      fileName,
                      exportType,
                    });
                  }}
                >
                  Export User
                </div>
              </>
            ) : item == "Export Members" ? (
              <>
                {/* <CsvDownload
                data={
                  data
                    ? data.map((e) => {
                        return {
                          memberId: e.memberId,
                          member: e.member,
                          shareholder: e.shareholder,
                          firstName: e.firstName,
                          middleName: e.middleName,
                          lastName: e.lastName,
                          email: e.email,
                          phone: e.phone,
                          addressLine_1: e.addressLine_1,
                          addressLine_2: e.addressLine_2,
                          state: e.state,
                          city: e.city,
                          district: e.district,
                          Taluka: e.taluka,
                          pincode: e.pincode,
                          nominee_name: e.nominee_name,
                          organisation_name: e.organisation_name,
                          activityStatus: e.activityStatus
                            ? "Active"
                            : "Inactive",
                          mobileSignUpStatus: e.mobileSignUpStatus
                            ? "Enable"
                            : "Disable",
                          totalShare: e.totalShare > 0 ? e.totalShare : "0",
                          shareCapital:
                            e.shareCapital > 0 ? e.shareCapital : "0",
                          shareApplicationMoney:
                            e.shareApplicationMoney > 0
                              ? e.shareApplicationMoney
                              : "0",
                        };
                      })
                    : []
                }
                id={styles.menuItem}
              >
                Export Members
              </CsvDownload> */}
                <div
                  // className="exportBtn"
                  onClick={() => {
                    exportFromJSON({
                      data: tableRef.current.state.data.map((ele) => {
                        return {
                          transactionDate: ele.createdAt,
                          _id: ele._id,
                          MemberId: ele?.memberId?.trim(),
                          MemberType: ele?.member?.trim(),
                          MemberShareHolder: ele?.shareholder,
                          FirstName: ele?.firstName?.trim(),
                          MiddleName: ele?.middleName?.trim(),
                          LastName: ele?.lastName?.trim(),
                          Phone: ele?.phone,
                          Email: ele?.email?.trim(),
                          AddressLine_1: ele?.addressLine_1.trim(),
                          AddressLine_2: ele?.addressLine_2?.trim(),
                          State: ele?.state?.trim(),
                          District: ele?.district?.trim(),
                          Taluka: ele?.taluka?.trim(),
                          City: ele?.city?.trim(),
                          Pincode: ele?.pincode,
                          OrganisationName: ele?.organisation_name?.trim(),
                          ActivityStatus: ele?.activityStatus,
                          MobileSignUpStatus: ele?.mobileSignUpStatus,
                          TotalShare: ele?.totalShare,
                          ShareCapital: ele?.shareCapital,
                          ShareApplicationMoney: ele?.shareApplicationMoney,
                          PercentageShareholding: (
                            (ele?.totalShare / dashboardData?.totalShares) *
                            100
                          ).toFixed(5),
                        };
                      }),
                      fileName,
                      exportType,
                    });
                  }}
                >
                  Export Members
                </div>
              </>
            ) : (
              item
            )}
          </MenuItem>
        ))}
      </Menu>

      {openMember ? (
        <MemberFileUpload
          setData={setData}
          open={openMember}
          setConflict={setConflict}
          setOpen={setOpenMember}
          memberDataLength={memberDataLength}
          setIsAction={setIsAction}
        />
      ) : (
        ""
      )}

      {openShareApplication ? (
        <MemberShareApplication
          setData={setData}
          open={openShareApplication}
          setOpen={setOpenShareApplication}
          setConflict={setConflict}
          // memberDataLength={memberDataLength}
        />
      ) : (
        ""
      )}
      {openFile ? (
        <FileUploadDialogue
          setData={setData}
          open={openFile}
          setConflict={setConflict}
          setOpen={setOpenFile}
          dataLength={dataLength}
        />
      ) : (
        ""
      )}
      {modalShow ? (
        <MemberModal
          open={modalShow}
          setOpen={setModalShow}
          setConflict2={setConflict}
        />
      ) : (
        ""
      )}
      {issueBulkShareModalShow && label !== "memberInfo" ? (
        <MemberIssueShares
          totalShareApplicationMoney={1000}
          issuableShare={10}
          open={issueBulkShareModalShow}
          setUpdate={setUpdate}
          tableData={data}
          memberIds={
            selectedUsers?.length
              ? selectedUsers.map((ele) => {
                  return ele.id;
                })
              : newSelectedData?.length
              ? newSelectedData
              : []
          }
          setOpen={setIssueBulkShareModalShow}
          pricePerShare={pricePerShare}
          label={"Issue Share"}
          setConflict2={setConflict}
          setIsAction={setIsAction}
        />
      ) : (
        ""
      )}

      {issueBulkShareModalShow && label === "memberInfo" ? (
        <MemberIssueSharesMemberInfo
          totalShareApplicationMoney={1000}
          issuableShare={10}
          open={issueBulkShareModalShow}
          setUpdate={setUpdate}
          tableData={data}
          memberIds={
            selectedUsers?.length
              ? selectedUsers.map((ele) => {
                  return ele.id;
                })
              : newSelectedData?.length
              ? newSelectedData
              : []
          }
          setOpen={setIssueBulkShareModalShow}
          pricePerShare={pricePerShare}
          label={"Issue Share"}
          setConflict2={setConflict}
          setIsAction={setIsAction}
        />
      ) : (
        ""
      )}

      {openUpdateMemberType ? (
        <UpdateMemberType
          open={openUpdateMemberType}
          setOpen={setOpenUpdateMemberType}
          memberIds={
            selectedUsers?.length
              ? selectedUsers.map((ele) => {
                  return ele.id;
                })
              : newSelectedData?.length
              ? newSelectedData
              : []
          }
          setConflict={setConflict}
          setIsAction={setIsAction}
        />
      ) : (
        ""
      )}
    </div>
  );
}
