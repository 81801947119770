import React, { useState, useEffect } from "react";
import ListItems from "./../../Components/ListItems/ListItems";
import TabPanel from "../../Components/Tabs/TabPanel";
// import data from "../../Components/Tabs/TabsData";
// import BulletItems from "./../../Components/ListItems/BulletItem";
// import down from "../../assets/images/caret-down.png";
import styles from "../Members/member.module.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import MenuDropdown from "./../../Components/MenuItems/MenuDropdown";
import {
  getShareForMemberWithId,
  selectedMemberRows,
} from "../../Redux/Members/actionCreator";
import { openSnackbar } from "./../../Redux/Snackbar/snackbarStore";
import Loader from "./../../Components/Loader/Loader";
import MaterialSelect from "./../../Components/SelectDropdown/MaterialSelect";
import { BASE_URL } from "./../../Components/URL/Url";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { getPricePerShareActionCreater } from "../../Redux/Dashboard/dashboardActionCreator";
import { useLocation } from "react-router-dom";

const MembersInfo = (props) => {
  const { id, setId } = props;
  const [data, setData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [sayasAcc, setSayasAcc] = useState({});

  const [conflict, setConflict] = useState(false);
  const [isAction, setIsAction] = useState(false);

  const dashboardData = useSelector((state) => {
    return state;
  });

  const [option_users, setOption_users] = useState([
    "All Members",
    "Nominal Members",
    "Regular Members",
    "Mobile App Enabled",
    "Active Members",
    "Inactive Members",
    "Mobile App Disabled",
  ]);
  const [option_action_1, setOption_action_1] = useState([
    "Import Members",
    "Export Members",
    "Import Share Application",
    "Share Transfer",
  ]);
  const [option_action_2, setOption_action_2] = useState([
    "Activate Member",
    "Enable Mobile App",
    "Disable Mobile App",
    "Update Member Type",
    "Issue Shares",
    "Deactivate Member",
  ]);

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    // console.log(dashboardData.sayasAccount);
    setSayasAcc(dashboardData?.sayasAccount);
  }, [dashboardData]);

  useEffect(() => {
    dispatch(
      getPricePerShareActionCreater(dashboardData?.sayasAccount?.pricePerShare)
    );
  }, [sayasAcc]);

  const memberId = useParams();
  useEffect(() => {
    dispatch(getShareForMemberWithId(memberId.id, getAuthData));
  }, [memberId.id]);

  // const option_users = [
  //   "All Members",
  //   "Nominal Members",
  //   "Regular Members",
  //   "Mobile App Enabled",
  //   "Active Members",
  //   "Inactive Members",
  //   "Mobile App Disabled",
  // ];
  // const option_action_1 = [
  //   "Import Members",
  //   "Export Members",
  //   "Import Share Application",
  //   "Share Transfer",
  // ];
  // const option_action_2 = [
  //   "Activate Member",
  //   "Enable Mobile App",
  //   "Disable Mobile App",
  //   "Update Member Type",
  //   "Issue Shares",
  //   "Deactivate Member",
  // ];

  //add permission
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  useEffect(() => {
    if (userRole !== "") {
      if (userRole.toLowerCase() === "staff") {
        setOption_action_1([]);
        setOption_action_2([]);
      }
    }
  }, [userRole]);

  const [options, setOptions] = useState(option_action_1);

  const [selectedData, setSelectedData] = useState({});

  // const selectedUsers = useSelector((state) => state.members);

  // console.log(Object.keys(selectedData));

  // const [flag, setFlag] = useState(false);
  const [optionVal, setOptionVal] = useState("All Members");
  const location = useLocation();

  const initialColumnStatus = {
    memberId: true,
    member: true,
    firstName: true,
    middleName: true,
    lastName: true,
    email: true,
    phone: true,
    nominee_name: true,
    organisation_name: true,
    state: true,
    city: true,
    pincode: true,
    addressLine_1: true,
    addressLine_2: true,
    role: true,
    team: true,
    date: true,
    activityStatus: true,
    mobileSignUpStatus: true,
  };

  const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  const getTableDetails = async () => {
    try {
      let token = window.localStorage.getItem("sayasLoginToken");
      // let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(BASE_URL + `/api/v1/member`, {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      });

      // console.log("member Detail", response.data.data);

      setData(response.data.data);
      setFinalData(response.data.data);
      dispatch(loaderEndActionCreater());
    } catch (err) {
      // console.log(err);
      let errors = err?.response?.data?.error;
      if (errors) dispatch(openSnackbar(errors, "error"));
      dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  // useEffect(() => {
  //   if (selectedData?.length !== 0) {
  //     setOptions(option_action_2);
  //   } else if (selectedData?.length == 0) {
  //     setOptions(option_action_1);
  //   }
  // }, [selectedUsers]);

  useEffect(() => {
    // console.log("selectedData.length", Object.keys(selectedData).length);
    if (Object.keys(selectedData).length !== 0) {
      setOptions(option_action_2);
    } else if (Object.keys(selectedData).length == 0) {
      setOptions(option_action_1);
    }
  }, [selectedData, option_action_1, option_action_2]);

  const list = [
    {
      title: "Member Profile",
    },
    {
      title: "Share Holdings",
    },
    {
      title: "Transaction",
    },
    {
      title: "Activity Logs",
    },
  ];

  const handleActivateStatus = async (status) => {
    // console.log("item", status);

    if (status === "Update Member Type" || status === "Issue Shares") return;

    let memberArray = Object.keys(selectedData).map((member) => member);

    if (status == "Activate Member") {
      const memberData = { memberIds: memberArray, activityStatus: true };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.post(
          BASE_URL + `/api/v1/member/updateStatus`,
          memberData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        getTableDetails();
        dispatch(openSnackbar("Member Activated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Deactivate Member") {
      const memberData = { memberIds: memberArray, activityStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.post(
          BASE_URL + `/api/v1/member/updateStatus`,
          memberData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        getTableDetails();
        dispatch(openSnackbar("Member Deactivated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Enable Mobile App") {
      const memberData = { memberIds: memberArray, mobileSignUpStatus: true };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.post(
          BASE_URL + `/api/v1/member/updateMobileStatus`,
          memberData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        getTableDetails();
        dispatch(openSnackbar("Mobile App Enabled Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Disable Mobile App") {
      const memberData = { memberIds: memberArray, mobileSignUpStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        let response = await axios.post(
          BASE_URL + `/api/v1/member/updateMobileStatus`,
          memberData,
          {
            headers: {
              Authorization: `Bearer ` + `${token}`,
            },
          }
        );
        getTableDetails();
        dispatch(openSnackbar("Mobile App Disabled Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    }
    setOptions(option_action_1);
    dispatch(selectedMemberRows(null));
    setOptionVal("All Members");
    setSelectedData({});
  };

  // console.log(location.pathname);

  const filterUsers = async (name) => {
    setOptionVal(name);
    dispatch(loaderStartActionCreater());
    if (name == "Active Members") {
      let newData = data.filter((item) => item.activityStatus == true);
      dispatch(loaderEndActionCreater());
      // console.log("active", active);
      setFinalData(newData);
      setSelectedData(newData[0]);
      setId(newData[0]?.id);
      if (newData.length > 0) {
        history.push(`${newData[0]?.id}`);
        dispatch(
          openSnackbar("Active Members Filtered Successfully!", "success")
        );
      } else {
        history.replace("/admin/members");
        dispatch(openSnackbar("Active Members Not Present!", "error"));
      }
    } else if (name == "Inactive Members") {
      let newData = data.filter((item) => item.activityStatus !== true);
      dispatch(loaderEndActionCreater());
      setFinalData(newData);
      setSelectedData(newData[0]);
      setId(newData[0]?.id);
      if (newData.length > 0) {
        history.push(`${newData[0]?.id}`);
        dispatch(
          openSnackbar("Inactive Members Filtered Successfully!", "success")
        );
      } else {
        history.replace("/admin/members");
        dispatch(openSnackbar("Inactive Members Not Present!", "error"));
      }
    } else if (name == "Mobile App Enabled") {
      let newData = data.filter((item) => item.mobileSignUpStatus == true);
      dispatch(loaderEndActionCreater());
      setFinalData(newData);
      setSelectedData(newData[0]);
      setId(newData[0]?.id);
      if (newData.length > 0) {
        history.push(`${newData[0]?.id}`);
        dispatch(
          openSnackbar(
            "Mobile App Enabled Members Filtered Successfully!",
            "success"
          )
        );
      } else {
        history.replace("/admin/members");
        dispatch(
          openSnackbar("Mobile App Enabled Members Not Present!", "error")
        );
      }
    } else if (name == "Mobile App Disabled") {
      let newData = data.filter((item) => item.mobileSignUpStatus !== true);
      dispatch(loaderEndActionCreater());
      setFinalData(newData);
      setSelectedData(newData[0]);
      setId(newData[0]?.id);
      if (newData.length > 0) {
        history.push(`${newData[0]?.id}`);
        dispatch(
          openSnackbar(
            "Mobile App Disabled Members Filtered Successfully!",
            "success"
          )
        );
      } else {
        history.replace("/admin/members");
        dispatch(
          openSnackbar("Mobile App Disabled Members Not Present!", "error")
        );
      }
    } else if (name == "Nominal Members") {
      let newData = data.filter(
        (item) => item?.member?.toLowerCase() === "nominal"
      );
      dispatch(loaderEndActionCreater());
      setFinalData(newData);
      setSelectedData(newData[0]);
      setId(newData[0]?.id);
      if (newData.length > 0) {
        history.push(`${newData[0]?.id}`);
        dispatch(
          openSnackbar("Nominal Members Filtered Successfully!", "success")
        );
      } else {
        history.replace("/admin/members");
        dispatch(openSnackbar("Nominal Members Not Present!", "error"));
      }
    } else if (name == "Regular Members") {
      // console.log(data);
      let newData = data.filter(
        (item) => item?.member?.toLowerCase() === "regular"
      );
      dispatch(loaderEndActionCreater());
      setFinalData(newData);
      setSelectedData(newData[0]);
      setId(newData[0]?.id);
      if (newData.length > 0) {
        history.push(`${newData[0]?.id}`);
        dispatch(
          openSnackbar("Regular Members Filtered Successfully!", "success")
        );
      } else {
        history.replace("/admin/members");
        dispatch(openSnackbar("Regular Members Not Present!", "error"));
      }
    } else if (name == "All Members") {
      setFinalData(data);
    }
    dispatch(loaderEndActionCreater());
  };

  useEffect(() => {
    if (getAuthData) getTableDetails();
  }, [getAuthData]);


  
  return (
    <div>
      <div className="row m-0 flex-nowrap">
        <div
          className={`${styles.memberInfoLeftCol}`}
          style={{ borderRight: "1px solid #acacac", height: "88%" }}
        >
          <div
            className="d-flex justify-content-between align-items-center gap-2"
            style={{ padding: "30px 15px", height: "80%" }}
          >
            <div
              style={{
                lineBreak: "none",
                whiteSpace: "nowrap",
              }}
              // className={styles.leftPanelHead}
            >
              <MaterialSelect
                optionVal={optionVal}
                filter="memberFilter"
                option_users={option_users}
                filterUsers={filterUsers}
              />
            </div>
            {options?.length > 0 ? (
              <div>
                <MenuDropdown
                  // tableRef={{ current: { state: { data } } }}
                  options={options}
                  handleActivateStatus={handleActivateStatus}
                  setOptions = {setOptions}
                  setData={setData}
                  selectedData={Object.keys(selectedData).filter((ele) => {
                    return selectedData[`${ele}`] === true;
                  })}
                  
                  data={data}
                  setConflict={setConflict}

                  columnsStatus={columnsStatus}
                  setColumnsStatus={setColumnsStatus}
                  setIsAction={setIsAction}
                  module={"member"}
                  label = "memberInfo"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <hr className="m-0" />
          {/* {data.length === 0 ? (
            <Loader />
          ) : ( */}
          <div
            id={styles.scrollBar}
            style={{
              // height: "72vh",
              height: "83vh",
            }}
          >
            {finalData.map((item, i) => (
              <div key={i}>
                <ListItems
                  firstName={item.firstName}
                  lastName={item.lastName}
                  email={item.memberId}
                  currentVal={item}
                  data={item}
                  index={i}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  setId={setId}
                  id={id}
                />
                {/* <BulletItems /> */}
                <hr className="m-0" />
              </div>
            ))}
          </div>
          {/* )} */}
        </div>
        <div
          className={`p-0 flex-1 ${styles.memberInfoRightCol} memberInfoRightCol`}
          style={{ flex: 1, height: "80vh!important", overflow: "auto" }}
        >
          <TabPanel
            selectedData={selectedData}
            data={list}
            id={id}
            setId={setId}
            usedFor="member"
          />
        </div>
      </div>
    </div>
  );
};

export default MembersInfo;
