import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ShareHoldingTable from "./../../Views/Members/ShareHoldingTable";
import TransactionTable from "./../../Views/Members/TransactionTable";
import ActivityLogsTable from "./../../Views/Members/ActivityLogsTable";
// import { UpdateUser } from "./../../Views/Users/UpdateUser";
// import { UpdateMembersForm } from "./../../Views/Members/UpdateMemberForm";
import UpdateUserForm from "./../../Views/Users/UsersForm/UpdateUserForm";
import UpdateMemberForm from "./../../Views/Members/MembersForm/UpdateMemberForm";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "./TabPanel.module.css";
import "./TabPanel.css";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { useDispatch } from "react-redux";

var sort = function (prop, arr) {
  prop = prop.split(".");
  var len = prop.length;

  arr.sort(function (a, b) {
    var i = 0;
    while (i < len) {
      a = a[prop[i]];
      b = b[prop[i]];
      i++;
    }
    if (a > b) {
      return -1;
    } else if (a < b) {
      return 1;
    } else {
      return 0;
    }
  });
  return arr;
};

function TabPanel(props) {
  const { children, value, index, list, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const { data, id, setId, selectedData, usedFor } = props;
  const [value, setValue] = React.useState(0);
  const [conflict, setConflict] = React.useState(true);

  // find member ID
  const params = useParams();
  // console.log(params.id);
  // console.log("tab panel", id);

  const [shareApplicationMoneyTableData, setShareApplicationMoneyTableData] =
    React.useState([]);
  const [shareIssueTableData, setShareIssueTableData] = React.useState([]);
  const dispatch = useDispatch();

  const getMemberTransactions = async () => {
    try {
      // console.log(val);
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/member/moneyTransactions/" +
          `${params.id}`
      );

      // console.log(response.data);
      setShareApplicationMoneyTableData(response.data);
      // dispatch(openSnackbar("Add New Configuration Successfully", "success"));
      // dispatch(loaderEndActionCreater());
    } catch (e) {
      console.log(e.message);
      // dispatch(openSnackbar("Get Transaction failed", "error"));
      // dispatch(loaderEndActionCreater());
    }
  };

  const getMemberShareTransactions = async () => {
    try {
      // console.log(val);
      let token = window.localStorage.getItem("sayasLoginToken");
      // let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL +
          "/api/v1/member/shareTransactions/" +
          `${params.id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log("getMemberShareTransactions", response.data);

      // response.data.map((ele) => {
      //   if (ele.noOfShares == 0) console.log("In transaction table::", ele);
      // });

      setShareIssueTableData(
        response.data.filter((ele) => {
          return ele.noOfShares != 0;
        })
      );

      // dispatch(openSnackbar("Add New Configuration Successfully", "success"));
      // dispatch(loaderEndActionCreater());
    } catch (e) {
      console.log(e);
      // dispatch(openSnackbar("Get Transaction failed", "error"));
      // dispatch(loaderEndActionCreater());
    }
  };

  React.useEffect(() => {
    getMemberTransactions();
    setConflict(false);
  }, [params.id, conflict]);

  React.useEffect(() => {
    getMemberShareTransactions();
    setConflict(false);
  }, [params.id, conflict]);

  React.useEffect(() => {
    if (!id) {
      setId(params.id);
      setConflict(true);
    }
  }, [params.id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // console.log("tabPanel");

  return (
    <Box sx={{ width: "100%", height: "100%", overFlow: "auto" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {data.map((m, i) => (
            <Tab className={styles.tab} label={m.title} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>
      {data.length === 4 ? (
        <>
          {data.map((tab, i) => (
            <TabPanel value={value} index={i}>
              {i == 0 ? (
                <UpdateMemberForm id={params.id} setId={setId} />
              ) : i == 1 ? (
                <ShareHoldingTable
                  shareApplicationMoneyTableData={
                    shareApplicationMoneyTableData
                  }
                  shareIssueTableData={shareIssueTableData}
                  setConflict={setConflict}
                />
              ) : i == 2 ? (
                <TransactionTable
                  shareApplicationMoneyTableData={
                    shareApplicationMoneyTableData
                  }
                  shareIssueTableData={shareIssueTableData}
                  setConflict={setConflict}
                />
              ) : (
                <ActivityLogsTable
                  selectedData={selectedData}
                  usedFor={usedFor}
                />
              )}
            </TabPanel>
          ))}
        </>
      ) : (
        <>
          {data.map((tab, i) => (
            <TabPanel value={value} index={i}>
              {i == 0 ? (
                <UpdateUserForm id={id} setId={setId} />
              ) : (
                <ActivityLogsTable noEmail={true} />
              )}
            </TabPanel>
          ))}
        </>
      )}
    </Box>
  );
}
