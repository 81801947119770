const actions = {
  USER_CREATE: "USER_CREATE",
  SELECTED_USER: "SELECTED_USER",

  createUserAction: (data) => {
    return {
      type: actions.USER_CREATE,
      data,
    };
  },
  selectedUserAction: (data) => {
    return {
      type: actions.SELECTED_USER,
      data,
    };
  },

  usersVisibiltyAction : (data) => {
    // console.log("memberDataAction", data);
    return {
      type: "COLUMNSTATUS",
      data,
    };
  },
};

export default actions;
