import React, { useEffect, useState } from "react";
import ConfigInput from "./ConfigInput";
import axios from "axios";
import AddConfigModal from "./AddConfigModal";

import { ConfigurationHeader } from "./ConfigurationHeader";

import { ConfigurationUploadTemplate } from "./ConfigurationUploadTemplate";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ConfigurationUploadMessageTemplate } from "./ConfigurationUploadMessageTemplate";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import styles from "../DataAcquisition/DataAcq.module.css";

const ConfigRight = (props) => {
  const { configType } = props;
  const [dataVariable, setDataVariable] = useState([]);
  const [conflict, setConflict] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getConfigData = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/configuration",
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      setDataVariable(response.data.data);
      setConflict(false);
      dispatch(loaderEndActionCreater());

      // console.log(response.data);
    } catch (e) {
      console.log(e);
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    getConfigData();
    // console.log(dataVariable);
  }, [conflict]);

  return (
    <div
      className="col-md-9 p-0"
      style={{
        width: "74%",
        height: "100%",
      }}
    >
      {/* <ConfigurationHeader/> */}
      <div style={{ padding: "40px 20px" }}>
        {configType.dataKey === "uploadTemplate" ? (
          <>
            <ConfigurationUploadTemplate />
          </>
        ) : configType.dataKey === "uploadMessageTemplate" ||
          configType.dataKey === "uploadEmailTemplate" ||
          configType.dataKey === "uploadSmsTemplate" ? (
          <>
            <ConfigurationUploadMessageTemplate
              dataType={configType?.dataKey}
            />
          </>
        ) : (
          <>
            {/* <p>{props.configType}</p> */}
            <div
              style={{
                display: "flex",
                width: "100%",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "15px",
              }}
            >
              {dataVariable[0]
                ? dataVariable[0][configType.dataKey].map((item, i) => (
                    <ConfigInput
                      setConflict={setConflict}
                      setSelectedData={setSelectedData}
                      configType={configType}
                      i={i}
                      element={item}
                    />
                  ))
                : ""}
            </div>

            <div align="right" className="pt-3 pb-3">
              <AddConfigModal
                configType={configType}
                setConflict={setConflict}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            </div>
            {/* <ConfigurationTable /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default ConfigRight;
