import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

const DatePickers = (props) => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    start,
    type,
    setIsValid,
  } = props;
  const [value, setValue] = React.useState(
    type === "start" ? startDate : endDate
  );

  // const [startValue, setStartValue] = React.useState(startDate);
  // const [endValue, setEndValue] = React.useState(endDate);
  // console.log("Start", start);

  const handleChange = (newValue, pickerType) => {
    // setValue(newValue);
    // console.log(newValue, type, moment(newValue).isValid());
    if (type === "start") {
      let startdate = new Date(startDate);
      if (pickerType === "timePicker" && moment(newValue).isValid()) {
        newValue?.setDate(startdate.getDate());
        newValue?.setMonth(startdate.getMonth());
        newValue?.setFullYear(startdate.getFullYear());
        setStartDate(newValue);
        if (setIsValid) setIsValid(true);
      } else if (pickerType === "datePicker" && moment(newValue).isValid()) {
        newValue?.setHours(startdate.getHours());
        newValue?.setMinutes(startdate.getMinutes());
        newValue?.setSeconds(startdate.getSeconds());
        setStartDate(newValue);
        if (setIsValid) setIsValid(true);
      } else {
        if (setIsValid) setIsValid(false);
      }
      setValue(newValue);
    } else if (type === "close") {
      // console.log("lineno.32", endDate);
      let enddate = new Date(endDate);
      if (pickerType === "datePicker" && moment(newValue).isValid()) {
        newValue?.setHours(enddate.getHours());
        newValue?.setMinutes(enddate.getMinutes());
        newValue?.setSeconds(enddate.getSeconds());
        // console.log(
        //   "insidedatepicker",
        //   moment(newValue).isValid(),
        //   typeof newValue
        // );
        setEndDate(newValue);
        if (setIsValid) setIsValid(true);
      } else if (pickerType == "timePicker" && moment(newValue).isValid()) {
        // console.log("bigcheck", newValue);
        newValue?.setDate(enddate.getDate());
        newValue?.setMonth(enddate.getMonth());
        newValue?.setFullYear(enddate.getFullYear());
        // console.log("insidetimepicker", moment(newValue).isValid(), newValue);
        // console.log("check", typeof newValue);
        setEndDate(newValue);
        if (setIsValid) setIsValid(true);
      } else {
        if (setIsValid) setIsValid(false);
      }
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (type == "start") {
      setValue(startDate);
    } else if (type == "close") {
      setValue(endDate);
    }
  }, [startDate, endDate]);

  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  return (
    <div style={{ marginTop: 30 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="d-flex justify-content-between" style={{ gap: 15 }}>
          <DesktopDatePicker
            readOnly={props.name == "info" ? true : false}
            label="Date"
            inputFormat="dd/MM/yyyy"
            disabled={props.disabled || false}
            // value={startDate ? startDate : endDate ? endDate : value}
            value={value}
            mask="__:__:__"
            // value={value || type === "close" ? endDate : startDate}
            onChange={(value) => handleChange(value, "datePicker")}
            minDate={
              props.name == "info"
                ? null
                : type === "close"
                ? start || Date.now()
                : Date.now()
            }
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            readOnly={props.name == "info" ? true : false}
            ampm={true}
            openTo="hours"
            views={["hours", "minutes", "seconds"]}
            inputFormat="HH:mm:ss"
            mask="__:__:__"
            label="Time"
            disabled={props.disabled || false}
            // error={
            //   props.name == "info"
            //     ? false
            //     : type === "close"
            //     ? !isValidEnd
            //     : !isValidStart
            // }
            error={true}
            minTime={
              moment(start).format("dd/MM/yyyy") ===
              moment(endDate).format("dd/MM/yyyy")
                ? start
                : null
            }
            // minTime={
            //   props.name == "info"
            //     ? null
            //     : type === "close"
            //     ? moment(start).format("dd/MM/yyyy") ===
            //       moment(endDate).format("dd/MM/yyyy")
            //       ? new Date(moment(start).add(1, "second"))
            //       : null
            //     : new Date()
            // }
            // value={startDate ? startDate : endDate ? endDate : value}
            value={value}
            onChange={(value) => handleChange(value, "timePicker")}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DatePickers;
