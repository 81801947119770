import { FormControlLabel, Switch } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import EmailTemplateInput from "./EmailTemplateInput";
import { UploadTemplate } from "./UploadTemplate";

const emailTemplateTab = [
  {
    title: "Mobile App Enabled",
    type: "mobileApp",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "mobile_app_enabled",
      title: "Mobile App Enabled",
      template: "Your mobile app has been activated successfully",
    },
  },
  {
    title: "Mobile App Disabled",
    type: "mobileApp",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "mobile_app_disabled",
      title: "Mobile App Disabled",
      template:
        "Your mobile app has been deactivated. Please contact customer care for further details.",
    },
  },
  {
    title: "Shares Issued",
    type: "shareIssued",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "share_issued",
      title: "Share Issued",
      template: "${no_of_shares} shares credited successfully to your account.",
    },
  },
  {
    title: "Share Application Money Credited",
    type: "shareApplicationMoney",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "share_application_money_credited",
      title: "Share Application Money Credited",
      template:
        "Rs.${amount} is credited to your Share Application Money Wallet.",
    },
  },
  {
    title: "Share Application Money Debited",
    type: "shareApplicationMoney",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "share_application_money_debited",
      title: "Share Application Money Debited",
      template:
        "Rs.${amount} is debited from your Share Application Money Wallet.",
    },
  },
  {
    title: "Shares Transferred 1",
    type: "shareTransfer",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "share_transfer1",
      title: "Share Transferred",
      template:
        "${no_of_shares} shares transferred from your account to ${to_name}. ",
    },
  },
  {
    title: "Shares Transferred 2",
    type: "shareTransfer",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "share_transfer2",
      title: "Share Transferred",
      template:
        "${to_name} transferred ${no_of_shares} shares to your account.",
    },
  },
  {
    title: "Data Acquisition Form Submitted by the Member",
    type: "dataAcquisition",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "data_acquisition",
      title: "Data Acquisition Form Submitted by the Member",
      template:
        "A response to {type_of_daq} {daq_id} is submitted successfully.",
    },
  },
  {
    title: "Data Acqusition Launched",
    type: "dataAcquisitionLaunch",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "data_acquisition_launch",
      title: "Data Acquisition Launch",
      template: "New {type_of_daq} {daq_id} is launched.",
    },
  },
  {
    title: "Password Updated",
    type: "passwordUpdated",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "password_updated",
      title: "Password Updated",
      template: "Your password has been updated successfully.",
    },
  },
  {
    title: "Support Request Submitted",
    type: "support_request",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "support_request_submitted",
      title: "Support Request Submitted",
      template:
        "Your support request has been submitted successfully. Support request number is {support_req_no}.",
    },
  },
  {
    title: "Support Request Resolved",
    type: "support_request",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "support_request_resolved",
      title: "Support Request Resolved",
      template:
        "Your support request {support_req_no} has been resolved successfully.",
    },
  },
  {
    title: "Support Request Closed",
    type: "support_request",
    defaultData: {
      email: false,
      sms: false,
      mobileNotification: true,
      name: "support_request_closed",
      title: "Support Request Closed",
      template: "Your support request {support_req_no} is closed.",
    },
  },
];

export const ConfigurationUploadMessageTemplate = (props) => {
  const { dataType } = props;
  const [data, setData] = useState([]);
  const [inConfig, setInConfig] = useState(false);
  const dispatch = useDispatch();

  const getConfigData = useSelector((state) => {
    // setInConfig(true)
    // console.log(state.configuration.uploadNotificationTemplate);
    return state.configuration.uploadNotificationTemplate;
  });

   //auth token fetch from selector
   const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);

  useEffect(() => {
    // console.log(getConfigData);
    setData(getConfigData);
  }, [getConfigData]);

  const addMessageTemplate = async (newData) => {
    // console.log("added");
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/configuration/emailTemplate",
        newData,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
    } catch (e) {
      // console.log("Update Configuration failed : ", e);
      if (e.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  // useEffect(() => {
  //   if (data)
  //     if (data?.length < 1) {
  //       dispatch(loaderStartActionCreater());
  //       // emailTemplateTab.map((ele) => {
  //       []?.map((ele) => {
  //         addMessageTemplate(ele.defaultData);
  //       });
  //       dispatch(getConfiguationActionCreater(getAuthData));
  //       dispatch(loaderEndActionCreater());
  //     }
  // }, []);

  // console.log(data);

  // console.log(data);

  return (
    <div>
      {data?.map((ele) => {
        return <EmailTemplateInput item={ele} dataType={dataType} />;
      })}
    </div>
  );
  // <div
  //   style={{
  //     width: "100%",
  //     display: "flex",
  //     flexDirection: "row",
  //     flexWrap: "wrap",
  //     justifyContent: "space-between",
  //     alignItems: "flex-start",
  //   }}
  // >
  //   <UploadTemplate
  //     dataVal={data}
  //     completeData={data}
  //     place={"Message Template"}
  //     dataKey={"uploadMessageTemplate"}
  //     infoMes={infoMessage.uploadMessageTemplate}
  //   />
  //   <div
  //     style={{
  //       width: "47%",
  //       display: "flex",
  //       flexDirection: "row",
  //       justifyContent: "space-evenly",
  //       alignItems: "center",
  //       margin: "25px 0px",
  //     }}
  //   >
  //     <FormControlLabel
  //       value="start"
  //       width="100%"
  //       control={
  //         <Switch
  //           color="primary"
  //           checked={check.email}
  //           onChange={(ele) => {
  //             // console.log("handleStatus");
  //             // console.log("curr Status", check[`email`]);

  //             const newDataForUpdate = {
  //               ...data[0],
  //               ["email"]: ele.target.checked,
  //             };
  //             setCheck(newDataForUpdate);
  //             updateMessageTemplate(data[0].id, newDataForUpdate);
  //           }}
  //         />
  //       }
  //       label="Email"
  //       labelPlacement="start"
  //     />
  //     <FormControlLabel
  //       value="start"
  //       width="100%"
  //       control={
  //         <Switch
  //           color="primary"
  //           checked={check.sms}
  //           onChange={(ele) => {
  //             // console.log("handleStatus");
  //             // console.log("curr Status", check[`sms`]);
  //             const newDataForUpdate = {
  //               ...data[0],
  //               ["sms"]: ele.target.checked,
  //             };
  //             setCheck(newDataForUpdate);

  //             updateMessageTemplate(data[0].id, newDataForUpdate);
  //           }}
  //         />
  //       }
  //       label="SMS"
  //       labelPlacement="start"
  //     />
  //     <FormControlLabel
  //       value="start"
  //       width="100%"
  //       control={
  //         <Switch
  //           color="primary"
  //           checked={check.mobileNotification}
  //           onChange={(ele) => {
  //             // console.log("handleStatus");
  //             // console.log("curr Status", check[`mobileNotification`]);
  //             const newDataForUpdate = {
  //               ...data[0],
  //               ["mobileNotification"]: ele.target.checked,
  //             };
  //             setCheck(newDataForUpdate);

  //             updateMessageTemplate(data[0].id, newDataForUpdate);
  //           }}
  //         />
  //       }
  //       label="mobileNotification"
  //       labelPlacement="start"
  //     />
  //   </div>

  // </div>
  // );
};
