import React, { useState, useEffect } from "react";
import styles from "../member.module.css";
const MemberInput = (props) => {
  const { formik, label, name } = props;

  const AlphaCheck = (e) => {
    const re = /[a-zA-Z ]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const PinCheck = (e) => {
    // console.log(e.target.value.toString().length);
    // console.log(e.key);
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  };

  const PhoneCheck = (e) => {
    // console.log(e.target.value.toString().length);
    // console.log(e.key);
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    } else if (
      e?.target?.value?.toString().length === 0 &&
      (e.key == 1 ||
        e.key == 0 ||
        e.key == 2 ||
        e.key == 3 ||
        e.key == 4 ||
        e.key == 5)
    ) {
      e.preventDefault();
    }
  };

  const handleID = (e) => {
    const re = /[a-zA-Z0-9]+/g;
    if (e.target.value.length > 14 || !re.test(e.key)) {
      e.preventDefault();
    }
  };

  const removeLessThenFive = (val) => {
    console.log(val);
    if (val.toString().length === 0) return "";
    if (parseInt(val.toString()[0]) > 5) return parseInt(val);
    removeLessThenFive(val.toString().slice(1));
  };

  return (
    <div>
      <label className="mb-2" id={styles.userLabel}>
        {label}
        {label == "Email" ||
        label == "Address Line 2" ||
        label === "Organisation Name" ||
        label === "City/Village" ||
        label === "Middle Name" ? null : (
          <span className="text-danger"> *</span>
        )}
      </label>
      <input
        name={name}
        label={label}
        className={`form-control ${styles.memberInput}`}
        style={{
          width: "90%",
          // height: "54px",
          fontWeight: "bold",
          fontFamily: "Mulish",
          border:
            formik.errors[name] && formik.touched[name]
              ? "2px solid red"
              : null,
        }}
        maxlength={label == "Pin Code" ? "6" : label == "Phone" ? "10" : null}
        value={
          label == "Phone"
            ? formik.values[name] === ""
              ? ""
              : parseInt(formik.values[name])
            : formik.values[name] || ""
        }
        onChange={formik.handleChange}
        // disabled={name == "memberId" ? true : false}
        placeholder={
          label == "Member"
            ? "Regular"
            : label == "Shareholder"
            ? "Premium"
            : null
        }
        onKeyPress={
          label == "Phone"
            ? (e) => PhoneCheck(e)
            : label == "Pin Code"
            ? (e) => PinCheck(e)
            : label == "Email" ||
              label == "Address Line 1" ||
              label == "Address Line 2"
            ? null
            : label == "Member ID"
            ? (e) => {
                handleID(e);
              }
            : (e) => AlphaCheck(e)
        }
      />
      {/* {formik.errors[`${name}`] ? (
        <span id={styles.errors}>{formik.errors[`${name}`]}</span>
      ) : null} */}
    </div>
  );
};

export default MemberInput;
