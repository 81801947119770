import actions from "./authonticationActions";

const { REGISTER_BEGIN, REGISTER_SUCCESS, LOGIN_BEGIN, LOGIN_SUCCESS } =
  actions;

const initState = {
  user: "",
  expireDate: new Date(),
  isAuth: false,
};

const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  // console.log(data);
  switch (type) {
    case "addLogin":
      // let isDateValid = data.exp >= new Date();
      return {
        ...state,
        user: data.user,
        expireDate: new Date(data.exp),
        isAuth: true,
        role: data.role,
        loginToken: data.loginToken,
      };
    case "loginOut":
      return { ...state, isAuth: false };
    default:
      return state;
  }
};
export default AuthReducer;
