import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import classes from "./Sidenav.module.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DashboardIcon from "../assets/icons/dashboard.png";
import ShareCapitalIcon from "../assets/icons/share-capital.png";
import UsersIcon from "../assets/icons/users.png";
import MembersIcon from "../assets/icons/members.png";
import DataAcquisitionIcon from "../assets/icons/data-acquisition.png";
import ReportsIcon from "../assets/icons/reports.png";
import CommunicationIcon from "../assets/icons/communication.png";
import SupportIcon from "../assets/icons/support.png";
import ConfigurationIcon from "../assets/icons/configuration.png";
import SayasLogo from "../assets/sayas-logo.png";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const SideNav = () => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  const closeReports = () => {
    if (open) setOpen(false);
  };

  const { path } = useRouteMatch();
  return (
    <>
      <div className="sideNavbar">
        <div className={classes.sideNav}>
          <img className={classes.navLogo} src={SayasLogo} />
          <List className={classes.navList}>
            <NavLink activeClassName={classes.active} to={`${path}/dashboard`}>
              <ListItem
                button
                className={classes.navBtn}
                onClick={closeReports}
              >
                <ListItemIcon>
                  <img src={DashboardIcon} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </NavLink>
            <NavLink
              activeClassName={classes.active}
              to={`${path}/share-capital`}
            >
              <ListItem
                button
                className={classes.navBtn}
                onClick={closeReports}
              >
                <ListItemIcon>
                  <img src={ShareCapitalIcon} />
                </ListItemIcon>
                <ListItemText primary="Share Capital" />
              </ListItem>
            </NavLink>

            {userRole.toLowerCase() === "admin" ? (
              <NavLink activeClassName={classes.active} to={`${path}/users`}>
                <ListItem
                  button
                  className={classes.navBtn}
                  onClick={closeReports}
                >
                  <ListItemIcon>
                    <img src={UsersIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </NavLink>
            ) : (
              ""
            )}

            <NavLink activeClassName={classes.active} to={`${path}/members`}>
              <ListItem
                button
                className={classes.navBtn}
                onClick={closeReports}
              >
                <ListItemIcon>
                  <img src={MembersIcon} />
                </ListItemIcon>
                <ListItemText primary="Members" />
              </ListItem>
            </NavLink>
            <NavLink
              activeClassName={classes.active}
              to={`${path}/data-acquisition`}
            >
              <ListItem
                button
                className={classes.navBtn}
                onClick={closeReports}
              >
                <ListItemIcon>
                  <img src={DataAcquisitionIcon} />
                </ListItemIcon>
                <ListItemText primary="Data Acquisition" />
              </ListItem>
            </NavLink>
            {userRole.toLowerCase() !== "staff" ? (
              <>
                <NavLink
                  activeClassName={classes.active}
                  to={`${path}/communication`}
                >
                  <ListItem
                    button
                    className={classes.navBtn}
                    onClick={closeReports}
                  >
                    <ListItemIcon>
                      <img src={CommunicationIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Communication" />
                  </ListItem>
                </NavLink>

                <NavLink
                  activeClassName={classes.active}
                  to={`${path}/reports/`}
                >
                  <ListItemButton
                    onClick={handleClick}
                    className={classes.navBtn}
                  >
                    <ListItemIcon>
                      <img src={ReportsIcon} />
                    </ListItemIcon>

                    <ListItemText primary="Reports" />

                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                  </ListItemButton>
                </NavLink>
              </>
            ) : (
              ""
            )}
            <Collapse
              in={open}
              className="reportCollapse"
              timeout="auto"
              unmountOnExit
            >
              <List component="div" disablePadding>
                <NavLink
                  activeClassName={classes.active}
                  to={`${path}/reports/share-issuance`}
                >
                  <ListItemButton sx={{ pl: 4 }} className={classes.reportBtn}>
                    <ListItemText primary="Share Issuance" />
                  </ListItemButton>
                </NavLink>
                <NavLink
                  activeClassName={classes.active}
                  to={`${path}/reports/share-application-money`}
                >
                  <ListItemButton sx={{ pl: 4 }} className={classes.reportBtn}>
                    <ListItemText primary="Share Application Money" />
                  </ListItemButton>
                </NavLink>
                <NavLink
                  activeClassName={classes.active}
                  to={`${path}/reports/share-transfer`}
                >
                  <ListItemButton sx={{ pl: 4 }} className={classes.reportBtn}>
                    <ListItemText primary="Share Transfer" />
                  </ListItemButton>
                </NavLink>
                <NavLink
                  activeClassName={classes.active}
                  to={`${path}/reports/shareholding-distribution`}
                >
                  <ListItemButton sx={{ pl: 4 }} className={classes.reportBtn}>
                    <ListItemText primary="Shareholding Distribution" />
                  </ListItemButton>
                </NavLink>
                {/* <NavLink
                  activeClassName={classes.active}
                  to={`${path}/reports/activity-logs`}
                >
                  <ListItemButton sx={{ pl: 4 }} className={classes.reportBtn}>
                    <ListItemText primary="Activity Logs" />
                  </ListItemButton>
                </NavLink> */}
              </List>
            </Collapse>
            {/* <NavLink activeClassName={classes.active} to={`${path}/reports`}>
            <ListItem button className={classes.navBtn}>
              <ListItemText primary="Reports" />
            </ListItem>
          </NavLink> */}
            {/* ///comment by sandeep to hide support */}
            {/* <NavLink activeClassName={classes.active} to={`${path}/support`}>
              <ListItem
                button
                className={classes.navBtn}
                onClick={closeReports}
              >
                <ListItemIcon>
                  <img src={SupportIcon} />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </ListItem>
            </NavLink> */}
            {/* ////////////// */}

            {userRole.toLowerCase() === "admin" ? (
              <NavLink
                activeClassName={classes.active}
                to={`${path}/configuration`}
              >
                <ListItem
                  button
                  className={classes.navBtn}
                  onClick={closeReports}
                >
                  <ListItemIcon>
                    <img src={ConfigurationIcon} />
                  </ListItemIcon>
                  <ListItemText primary="Configuration" />
                </ListItem>
              </NavLink>
            ) : (
              ""
            )}
            {/* //////export member by Sandeep*/}
            <NavLink
              activeClassName={classes.active}
              to={`${path}/newMemberExport`}
            >
              <ListItem
                button
                className={classes.navBtn}
                onClick={closeReports}
              >
                <ListItemIcon>
                  <i class="fa fa-download" style={{ fontSize: "22px" }}></i>
                </ListItemIcon>

                <ListItemText primary="New Member Export" />
              </ListItem>
            </NavLink>
          </List>
        </div>
      </div>
    </>
  );
};

export default SideNav;
