import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
export default function MemberInfoForBulkShareConfirm(props) {
  const {
    memberShipID,
    firstName,
    lastName,
    noOfShare,
    id,
    OnDelete,
    data,
    setData,
    minValue,
    maxValue,
    error,
    pricePerShare,
    shareApplicationMoney,
  } = props;

  const [value, setValue] = useState("");

  //   console.log("in memberConfirm", memberShipID, firstName, lastName, noOfShare);
  const allowOnlyPositiveNumber = (e) => {
    if (window.event) {
      var charCode = window.event.keyCode;
    } else if (e) {
      var charCode = e.which;
    } else {
      return true;
    }
    if (charCode >= 48 && charCode <= 57) return true;
    else {
      alert("Please Enter Only Positive Integer Number");
      e.preventDefault();
      return false;
    }
  };
  const handleChange = (e) => {
    setValue(e.target.value);
    let newValue = data;
    let val = newValue.map((ele) => {
      if (ele.id === id) {
        return {
          ...ele,
          numberOfShare: e.target.value,
          error:
            (parseInt(e.target.value) !== 0 &&
              parseInt(e.target.value) < parseInt(minValue)) ||
            parseInt(e.target.value) > parseInt(maxValue) ||
            e.target.value === "" ||
            shareApplicationMoney <
              parseInt(parseInt(pricePerShare) * parseInt(e.target.value)),
        };
      }
      return ele;
    });

    setData(val);
  };
  useEffect(() => {
    if (noOfShare) setValue(noOfShare);
  }, [noOfShare]);

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        m: 1.5,
        height: "30px",
        // borderBottom : "1px solid black"
      }}
      noValidate
      //   autoComplete="off"
    >
      {/* <div
        style={{
          
          margin: 1,
        }}
      ></div> */}
      <TextField
        id="standard-basic"
        // label="Standard"
        variant="standard"
        disabled
        value={memberShipID}
        sx={{
          textAlign: "center",
          m: 1,
          height: "100%",
        }}
      />
      <TextField
        id="standard-basic"
        // label="Standard"
        variant="standard"
        disabled
        value={firstName}
        sx={{
          textAlign: "center",
          height: "100%",
          m: 1,
        }}
      />
      <TextField
        id="standard-basic"
        // label="Standard"
        variant="standard"
        disabled
        value={lastName}
        sx={{
          textAlign: "center",
          height: "100%",
          m: 1,
        }}
      />
      <TextField
        id="standard-basic"
        // label="Standard"
        variant="standard"
        // disabled
        error={error}
        value={value}
        onChange={handleChange}
        sx={{
          textAlign: "center",
          height: "100%",
          m: 1,
        }}
        onKeyPress={allowOnlyPositiveNumber}
      />
      <div
        style={{
          // width: "12ch",
          height: "100%",
          //   margin: 1,
          margin: "15px 10px",
          textAlign: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "cenetr",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          OnDelete(id);
        }}
      >
        <CloseIcon />
      </div>
    </Box>
  );
}
