import { Grid } from "@mui/material";

import React, { useState, useEffect } from "react";
import "../Communication/CommunicationForm.css";
import ExportTable from "../../Components/Export/ExportTable";
import MaterialSelect from "../../Components/SelectDropdown/MaterialSelect";

export const NewMemberExportHeader = (props) => {
  const { startDate, endDate, exportData } = props;

  // console.log(exportData);

  const options = ["New Members Export"];

  return (
    <div style={{ margin: "20px 30px" }}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderColor: "grey.500",
        }}
      >
        <Grid
          sx={{
            background: null,
          }}
        >
          <Grid sx={{ fontSize: "1.4rem", background: null }}>
            <MaterialSelect
              option_users={options}
              val="New Members Export"
              optionVal={"tabValue"}
              filterUsers={"setTabValue"}
            />
          </Grid>
        </Grid>

        <Grid
          spacing={3}
          md={2.5}
          sx={{
            display: "flex",
            flexDirection: "row",
            m: 1,
            justifyContent: "flex-end",
          }}
        ></Grid>
      </Grid>
    </div>
  );
};
