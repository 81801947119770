import MaterialTable from "material-table";
import React, { forwardRef, useEffect } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useDispatch } from "react-redux";
import { selectedUserRows } from "../Redux/Users/actionCreator";
import { selectedMemberRows } from "../Redux/Members/actionCreator";
import { createTheme } from "@mui/material";
import { MuiThemeProvider } from "@material-ui/core";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { selectedDataAcqRows } from "../Redux/DataAcquisition/actionCreator";
import "./table.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const DataTable = (props) => {
  const { rowClick, tableRef } = props;
  const [rowDataTransaction, setRowDataTransaction] = useState([]);
  const [completeRowData, setCompleteRowData] = useState([]);
  const [isSurrenderTransaction, setIsSurrenderTransaction] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = (rowData) => {
    // console.log(rowData)
    setIsSurrenderTransaction(rowData.isSurrendered);
    setOpen(true);
  };
  const dispatch = useDispatch();
  const breakpoints = createBreakpoints({});

  const theme = createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#0096FF",
      },
      secondary: {
        main: "#0096FF",
      },
    },

    typography: {
      h6: {
        fontSize: "13px",
        [breakpoints.up("md")]: {
          fontSize: "15px",
        },
      },
    },
  });

  // React.useEffect(() => {
  //   props.tableRef &&
  //     props.tableRef.current &&
  //     props.tableRef.current.onQueryChange();
  // }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        ref={tableRef}
        icons={tableIcons}
        tableRef={tableRef}
        title={props.title}
        columns={props.columns}
        data={props.data}
        options={{ ...props.options, pageSizeOptions: [10, 25, 50, 100] }}
        onSearchChange={(ele) => {
          // console.log("In table : ", ele);
        }}
        onRowClick={
          rowClick
            ? (event, rowData) => {
                // console.log(rowData);
                setCompleteRowData(rowData);
                setRowDataTransaction(rowData.transactionId);
                handleOpen(rowData);
              }
            : props.onRowClick
        }
        //for multiple selection
        onSelectionChange={(data, rowData) => {
          console.log(data, rowData, "rowData");
          dispatch(selectedUserRows(data));
          dispatch(selectedMemberRows(rowData,data));
          dispatch(selectedDataAcqRows(data));
        }}
      />
      {/* //transaction table */}
      {open ? (
        <TransitionsModalForMemberTrasactions
          open={open}
          setOpen={setOpen}
          rowDataTransaction={rowDataTransaction}
          completeRowData={completeRowData}
          isSurrenderTransaction={isSurrenderTransaction}
        />
      ) : (
        ""
      )}
    </MuiThemeProvider>
  );
};

// fuction for trasactionTable Modal
export const TransitionsModalForMemberTrasactions = (props) => {
  const {
    open,
    setOpen,
    rowDataTransaction,
    completeRowData,
    isSurrenderTransaction,
  } = props;
  const params = useParams();
  const [newData, setNewData] = useState([]);

  // console.log(params.id, completeRowData);
  // console.log("data", newData);

  useEffect(() => {
    if (
      completeRowData.toMemberId.id === params.id &&
      completeRowData.fromMemberId === null &&
      completeRowData.isSurrendered
    ) {
      setNewData(
        completeRowData?.transactionId?.map((e) => {
          return {
            ...completeRowData.toMemberId,
            ...e,
            transactionType: "surrender",
            memberId: e.memberId,
          };
        })
      );
    } else if (
      completeRowData.toMemberId.id === params.id &&
      completeRowData.fromMemberId === null
    ) {
      setNewData(
        completeRowData?.transactionId?.map((e) => {
          return {
            ...completeRowData.toMemberId,
            ...e,
            transactionType: "issue",
            memberId: e.memberId,
          };
        })
      );
    } else if (
      completeRowData.toMemberId.id === params.id &&
      completeRowData.fromMemberId !== null
    ) {
      setNewData(
        completeRowData?.transactionId?.map((e) => {
          return {
            ...completeRowData.toMemberId,
            ...e,
            transactionType: "in",
            memberId: e.memberId,
          };
        })
      );
    } else {
      setNewData(
        completeRowData?.transactionId?.map((e) => {
          return {
            ...completeRowData.fromMemberId,
            ...e,
            transactionType: "out",
            memberId: e.memberId,
          };
        })
      );
    }
  }, [completeRowData]);

  const handleClose = () => setOpen(false);

  const firstColumns = (type, isSurrender) => {
    return [
      // {
      //   title: "Type",
      //   field: "transactionId.type",
      //   cellStyle: {
      //     minWidth: 140,
      //     textTransform: "capitalize",
      //   },
      // },
      {
        title: "Date",
        field: "createdAt",
        type: "date",
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              maxWidth: "10rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {moment(rowData.createdAt).format("DD/MM/yyyy")}
          </div>
        ),
        dateSetting: {
          format: "dd/MM/yyyy",
        },
      },
      {
        title: "Transaction ID",
        field: "id",
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        title: "Type of transaction",
        field: "transactionType",
        cellStyle: {
          minWidth: 190,
          textTransform: "capitalize",
        },
        headerStyle: {
          whiteSpace: "nowrap",
        },
        render: (rowData) =>
          rowData.transactionType === "in"
            ? "Transferred In"
            : rowData.transactionType === "out"
            ? rowData.isValid
              ? "Regenerate"
              : "Transferred Out"
            : isSurrenderTransaction
            ? "Surrendered"
            : "Issued",
      },
      {
        title: "Type Of Shares",
        field: "typeOfShares",
        cellStyle: {
          minWidth: 160,
          textTransform: "capitalize",
        },
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        title: "Price Per Share",
        field: "pricePerShare",
        cellStyle: {
          minWidth: 160,
          textTransform: "capitalize",
        },
        headerStyle: {
          whiteSpace: "nowrap",
        },
      },
      {
        title: `Share Capital ${type === "issue" ? "Added" : "Transferred"}`,
        field: "shareCapital",
        cellStyle: {
          minWidth: 160,
          textTransform: "capitalize",
        },
        headerStyle: {
          whiteSpace: "nowrap",
        },
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              maxWidth: "10rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {parseInt(
              parseInt(rowData.pricePerShare) *
                parseInt(rowData.sharesTo - rowData.sharesFrom + 1)
            )}
          </div>
        ),
      },
      {
        title: "Share Nos (From)",
        field: "sharesFrom",
        cellStyle: {
          minWidth: 140,
          textTransform: "capitalize",
        },
        headerStyle: {
          whiteSpace: "nowrap",
        },
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              minWidth: "7rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
              // color: `${rowData.isValid ? "green" : "red"}`,
            }}
          >
            {rowData.typeOfShares.charAt(0).toUpperCase()}
            {rowData.sharesFrom}
          </div>
        ),
      },
      {
        title: "Share Nos (To)",
        field: "sharesTo",
        cellStyle: {
          minWidth: 140,
          textTransform: "capitalize",
        },
        headerStyle: {
          whiteSpace: "nowrap",
        },
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              minWidth: "7rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
              // color: `${rowData.isValid ? "green" : "red"}`,
            }}
          >
            {rowData.typeOfShares.charAt(0).toUpperCase()}
            {rowData.sharesTo}
          </div>
        ),
      },
      {
        title: "No. of shares",
        field: "sharesTo",
        // cellStyle: {
        //   minWidth: 140,
        //   textTransform: "capitalize",
        // },
        headerStyle: {
          whiteSpace: "nowrap",
        },
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              minWidth: "7rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
              color: `${rowData.isValid ? "green" : "red"}`,
            }}
          >
            {rowData.sharesTo - rowData.sharesFrom + 1}
          </div>
        ),
      },

      {
        title: "Share Certificate No.",
        field: "shareCertificationNo",
        headerStyle: {
          whiteSpace: "nowrap",
        },
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              minWidth: "10rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              paddingRight: "1rem",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{rowData.shareCertificationNo}</div>
            {/* {params.id === completeRowData.toMemberId.id  ( */}
            <div>
              {" "}
              <a
                href={`${rowData.shareCertificationUrl}`}
                // href="https://sayas-public.s3.amazonaws.com/1644576309531icon.jpg"
                target={"_blank"}
                style={{ pointer: "cursor" }}
              >
                <DownloadIcon />
              </a>{" "}
            </div>
            {/* ) : (
            ""
          )} */}
          </div>
        ),
      },

      // {
      //   title: "Date",
      //   field: "transactionId.createdAt",
      //   type: "date",
      //   render: (rowData) => (
      //     <div
      //       sx={{ display: "table-cell" }}
      //       style={{
      //         maxWidth: "10rem",
      //         wordBreak: "break-word",
      //         whiteSpace: "nowrap",
      //         whiteSpace: "nowrap",
      //         lineBreak: "auto",
      //         overflowX: "hidden",
      //         textOverflow: "ellipsis",
      //       }}
      //     >
      //       {moment(rowData.updatedAt.slice(0, -1)).format("DD/MM/yyyy")}
      //     </div>
      //   ),
      //   dateSetting: {
      //     format: "dd/MM/yyyy",
      //   },
      // },

      {
        title: "Status",
        field: "status",
        render: (rowData) => (
          <div
            sx={{ display: "table-cell" }}
            style={{
              maxWidth: "10rem",
              wordBreak: "break-word",
              whiteSpace: "nowrap",
              whiteSpace: "nowrap",
              lineBreak: "auto",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${rowData.status ? "Successful" : "Failed"}`}
          </div>
        ),
      },

      // {
      //   title: "No. of Share",
      //   field: "transactionId.noOfShares",
      //   render: (rowData) => (
      //     <div
      //       sx={{ display: "table-cell" }}
      //       style={{
      //         maxWidth: "10rem",
      //         wordBreak: "break-word",
      //         whiteSpace: "nowrap",
      //         whiteSpace: "nowrap",
      //         lineBreak: "auto",
      //         overflowX: "hidden",
      //         textOverflow: "ellipsis",
      //         color: `${
      //           rowData.fromMemberId && rowData.fromMemberId.id === params.id
      //             ? "red"
      //             : "green"
      //         }`,
      //       }}
      //     >
      //       {`${
      //         rowData.fromMemberId && rowData.fromMemberId.id === params.id
      //           ? "-"
      //           : "+"
      //       }${
      //         rowData.transactionId[0].type === "issue"
      //           ? rowData.transactionId[0].noOfShares
      //           : rowData.noOfShares
      //       }`}

      //       {/* {rowData.transactionId[0].type === "issue"
      //         ? rowData.transactionId[0].noOfShares
      //         : rowData.noOfShares} */}
      //     </div>
      //   ),
      // },

      // {
    ];
  };

  const options = {
    search: false,
    selection: false,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "bold",
      fontSize: "14.4px",
      fontFamily: "Mulish",
    },

    cellStyle: {
      fontSize: "14.4px",
    },

    padding: "12px",

    // rowStyle: (rowData, i) => {
    //   if (params.id !== completeRowData?.toMemberId?.id) {
    //     return { background: "#FEDEDE" };
    //   }
    // },

    // paging: false,
    maxBodyHeight: "60vh",
    minBodyHeight: "60vh",
  };

  // console.log("Data on transfer table", newData);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <DataTable
              title="Share Transaction"
              columns={firstColumns(
                completeRowData.transactionId[0].type,
                completeRowData.isSurrender
              )}
              data={
                newData[0]?.transactionType === "in"
                  ? newData.filter((ele) => {
                      return ele.memberId === params.id;
                      // return true;
                    })
                  : newData[0]?.transactionType === "out"
                  ? newData.filter((ele) => {
                      return ele.memberId === params.id;
                      // return true;
                    })
                  : newData
              }
              // data = {newData}
              options={options}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DataTable;
