import React from "react";
import styles from "../users.module.css";

const UserSelectField = (props) => {
  const { formik, label, name, items } = props;

  // console.log("User Starff", props.disabled);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label className="mb-1" id={styles.userLabel}>
        {label}
      </label>
      <select
        name={name}
        value={formik.values[`${name}`]}
        onChange={formik.handleChange}
        disabled={props.disabled || false}
        error={formik.errors}
        className={`form-select ${styles.userSelectField}`}
        // required = {true}
        style={{
          width: "90%",
          textTransform: "capitalize",
          fontWeight: "bold",
          fontFamily: "Mulish",
          // height: "45px",
          border:
            formik.errors[name] && formik.touched[name]
              ? "2px solid red"
              : null,
        }}
      >
        {items.map((e, i) => {
          return (
            <option
              value={e}
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Mulish",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {e}
            </option>
          );
        })}
         <option
          value={""}
          hidden
          selected
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Mulish",
            color: "black",
            textTransform: "capitalize",
          }}
        >
          {""}
        </option>
      </select>
      {/* {formik.errors[`${name}`] ? (
        <span className="text-danger">{formik.errors[`${name}`]}</span>
      ) : null} */}
    </div>
  );
};

export default UserSelectField;
