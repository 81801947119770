import React, { useState, useEffect } from "react";
import { UserHeader } from "./UserHeader";
import UsersTable from "./UsersTable";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { openSnackbar } from "./../../Redux/Snackbar/snackbarStore";
import { MultiSelectDropdown } from "./../../Components/SelectDropdown/MultiSelectDropdown";
import { BASE_URL } from "./../../Components/URL/Url";
import { loaderEndActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { loaderStartActionCreater } from "./../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";
import { usrsVisibiltyActionCreater } from "../../Redux/Users/actionCreator";
import UpdateUserRoleModal from "./updateUserRoleModal";
import UpdateUserTeamsModal from "./updateUserTeamsModal";
// import exportFromJSON from 'export-from-json'
// const fileName = 'download'
// const exportType =  exportFromJSON.types.csv

export const User = (props) => {
  const { setDataLength, dataLength, setId } = props;

  //dispatch(setNavbar("User"))
  const [data, setData] = useState([]);
  const [conflict, setConflict] = useState(false);
  const [allData, setAllData] = useState(null);
  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openUserUplateRoleModal, setOpenUserUplateRoleModal] = useState(false);
  const [openUserUplateTeamModal, setOpenUserUplateTeamModal] = useState(false);

  const dispatch = useDispatch();
  const [option_users, setOption_users] = useState([
    "All Users",
    "Active Users",
    "Inactive Users",
  ]);
  const [option_action_1, setOption_action_1] = useState([
    "Import User",
    "Export User",
  ]);
  const [option_action_2, setOption_action_2] = useState([
    "Activate User",
    "Deactivate User",
    "Update User Role",
    "Update User Teams",
  ]);

  const tableRef = React.createRef();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  // const option_users = ["All Users", "Active Users", "Inactive Users"];
  // let option_action_1 = ["Import User", "Export User"];
  // let option_action_2 = ["Activate User", "Deactivate User"];

  const [userRole, serUserRole] = useState("");
  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });

  useEffect(() => {
    setRoles(
      getConfigData?.roles?.map((e) => {
        return e.type;
      })
    );
    setTeams(
      getConfigData?.teams?.map((e) => {
        return e.type;
      })
    );
  }, [getConfigData]);

  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);

  useEffect(() => {
    if (userRole !== "") {
      if (userRole.toLowerCase() === "staff") {
        setOption_action_1([]);
        setOption_action_2([]);
      } else if (userRole.toLowerCase() === "manager") {
        setOption_action_1(["Export User"]);
        setOption_action_2([
          "Activate User",
          "Deactivate User",
          "Update User Teams",
        ]);
      }
    }
  }, [userRole]);

  // const selectedUsers = useSelector((state) => state.users.selectedRow);

  const [options, setOptions] = useState(option_action_1);
  const [optionVal, setOptionVal] = useState("All Users");

  const getTableDetails = async () => {
    try {
      // let token1 = window.localStorage.getItem("sayasLoginToken");
      let token1 = getAuthData;
      if (!token1) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        // BASE_URL + `/api/v1/users`
        BASE_URL + `/api/v1/users`,
        {
          headers: {
            Authorization: `Bearer ` + `${token1}`,
          },
        }
      );
      // setData(response.data.data);
      setAllData(
        response?.data?.data.map((ele) => {
          return {
            ...ele,
            activeStatus: ele.activityStatus === true ? "Active" : "Inactive",
          };
        })
      );
      setDataLength(response.data.count);
      // dispatch(loaderEndActionCreater());
      // console.log(response.data);
    } catch (err) {
      // console.log(err);
      if (err.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      // let errors = err.response.data.error;
      // dispatch(openSnackbar(errors, "error"));
      dispatch(loaderEndActionCreater()); // console.log(response.data);
    }
  };

  const handleActivateStatus = async (status, selectedUsers) => {
    dispatch(loaderStartActionCreater());
    let userArray = selectedUsers.map((user) => user.id);
    if (status == "Activate User") {
      const userData = { userIds: userArray, activityStatus: true };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/users/updateStatus`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getTableDetails();
        dispatch(openSnackbar("User Activated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status == "Deactivate User") {
      const userData = { userIds: userArray, activityStatus: false };
      try {
        // let token = window.localStorage.getItem("sayasLoginToken");
        let token = getAuthData;
        if (!token) {
          dispatch(LogoutSayasActionCreate());
        }
        await axios.post(BASE_URL + `/api/v1/users/updateStatus`, userData, {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        });
        getTableDetails();
        dispatch(openSnackbar("User Deactivated Successfully!", "success"));
      } catch (err) {
        // console.log(err);
        let errors = err?.response?.data?.error;
        if (errors) dispatch(openSnackbar(errors, "error"));
        if (err?.request?.status === 401) {
          dispatch(LogoutSayasActionCreate());
        }
      }
    } else if (status === "Update User Role") {
      setOpenUserUplateRoleModal(true);
    } else if (status === "Update User Teams") {
      setOpenUserUplateTeamModal(true);
    }
    setOptions(option_action_1);
    setOptionVal("All Users");
    dispatch(loaderEndActionCreater());
  };

  const filterUsers = async (name) => {
    setOptionVal(name);
    dispatch(loaderStartActionCreater());
    if (name == "Active Users") {
      let active = allData?.filter((item) => item.activityStatus == true);
      // console.log("active", active);
      setData(active);
      dispatch(openSnackbar("Active Users Filtered Successfully!", "success"));
    } else if (name == "Inactive Users") {
      let inActive = allData?.filter((item) => item.activityStatus !== true);
      // console.log("inActive", inActive);
      setData(inActive);
      dispatch(
        openSnackbar("Inactive Users Filtered Successfully!", "success")
      );
    } else {
      setData(allData);
    }
    dispatch(loaderEndActionCreater());
  };

  useEffect(() => {
    if (getAuthData) {
      dispatch(loaderStartActionCreater());
      getTableDetails();
      setConflict(false);
    }
  }, [conflict, getAuthData]);

  useEffect(() => {
    if (getAuthData) getTableDetails();
  }, [getAuthData]);
  
  useEffect(() => {
    setData(allData);
    if (allData) dispatch(loaderEndActionCreater());
  }, [allData]);

  const columns = [
    {
      title: "User ID",
      field: "userId",

      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    // { title: "User", field: "user" },
    {
      title: "First Name",
      field: "firstName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.firstName || "").localeCompare(b.firstName || "");
      },
    },
    {
      title: "Middle Name",
      field: "middleName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.middleName || "").localeCompare(b.middleName || "");
      },
    },
    {
      title: "Last Name",
      field: "lastName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customSort: (a, b) => {
        return (a.lastName || "").localeCompare(b.lastName || "");
      },
    },
    {
      title: "Email",
      field: "email",
      customSort: (a, b) => {
        return (a.email || "").localeCompare(b.email || "");
      },
    },
    { title: "Phone", field: "phone" },
    // { title: "Nominee Name", field: "nomineeName" },
    // { title: "Organisation Name", field: "organisationName" },
    // { title: "State", field: "state" },
    // { title: "City", field: "city" },
    // { title: "Pin Code", field: "pincode" },
    // { title: "Address Line 1", field: "addressLine1" },
    // { title: "Address Line 2", field: "addressLine2" },
    {
      title: "Role",
      field: "role",
      render: (rowData) => (roles?.includes(rowData.role) ? rowData.role : ""),
      customSort: (a, b) => {
        return (roles?.includes(a.role) ? a.role : "").localeCompare(
          roles?.includes(b.role) ? b.role : ""
        );
      },
    },
    {
      title: "Team",
      field: "team",
      render: (rowData) => {
        // console.log("In team Col:", rowData.team, typeof rowData.team);
        // let teams =
        //   typeof rowData.team === "string"
        //     ? rowData.team.split(", ")
        //     : rowData.team;
        return (
          rowData.team
            ?.filter((ele) => {
              return teams?.includes(ele);
            })
            .join(", ") || ""
        );
      },
      customSort: (a, b) => {
        return a?.team
          ?.filter((ele) => {
            return teams?.includes(ele);
          })
          .join(", ")
          .localeCompare(
            b.team
              ?.filter((ele) => {
                return teams?.includes(ele);
              })
              .join(", ") || ""
          );
      },
    },
    // { title: "Access", field: "access" },
    // {
    // title: "Date",
    // field: "date",
    // type: "date",
    // dateSetting: {
    //     format: "dd/MM/yyyy",
    //   },
    // },
    {
      title: "Activity Status",
      field: "activeStatus",
      customFilterAndSearch: (term, rowData) =>
        rowData.activeStatus
          .toString()
          .toLowerCase()
          .indexOf(term.toLowerCase()) === 0,
      // render: (rowData) =>
      //   rowData.activityStatus === true ? <>Active</> : <>Inactive</>,
    },
  ];

  const initialColumnStatus = {
    userId: true,
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    role: false,
    team: false,
    // access: false,
  };
  // date: false,
  // user: false,
  // nomineeName: false,
  // organisationName: false,
  // state: false,
  // city: false,
  // pincode: false,
  // addressLine1: false,
  // addressLine2: false,

  // const [columnsStatus, setColumnsStatus] = useState(initialColumnStatus);

  const columnsStatus = useSelector((state) => {
    // console.log(state.users);
    return state.users.columnsStatus;
  });

  const setColumnsStatus = (data) => {
    console.log(data);
    dispatch(usrsVisibiltyActionCreater(data));
  };

  // console.log(columnsStatus);

  useEffect(() => {
    if (getAuthData) dispatch(getConfiguationActionCreater(getAuthData));
  }, [getAuthData]);

  return (
    <div>
      <UserHeader
        tableRef={tableRef}
        option_users={option_users}
        option_action_1={option_action_1}
        option_action_2={option_action_2}
        options={options}
        setConflict={setConflict}
        setOptions={setOptions}
        handleActivateStatus={handleActivateStatus}
        filterUsers={filterUsers}
        setOptionVal={setOptionVal}
        optionVal={optionVal}
        setData={setData}
        data={data || []}
        dataLength={dataLength}
        columnsStatus={columnsStatus}
      />

      <div className="overflow-hidden position-relative">
        <div
          align="right"
          style={{
            position: "absolute",
            top: "2%",
            right: "2%",
            zIndex: "2",
          }}
        >
          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>
        <UsersTable
          tableRef={tableRef}
          data={data || []}
          columnsStatus={columnsStatus}
          columns={columns}
          setDataLength={setDataLength}
          setColumnsStatus={setColumnsStatus}
          setId={setId}
        />
      </div>

      {openUserUplateRoleModal ? (
        <UpdateUserRoleModal
          open={openUserUplateRoleModal}
          setOpen={setOpenUserUplateRoleModal}
          setOptions={setOptions}
          option_action_1={option_action_1}
          setOptionVal={setOptionVal}
          getTableDetails={getTableDetails}
        />
      ) : (
        ""
      )}

      {openUserUplateTeamModal ? (
        <UpdateUserTeamsModal
          open={openUserUplateTeamModal}
          setOpen={setOpenUserUplateTeamModal}
          setOptions={setOptions}
          option_action_1={option_action_1}
          setOptionVal={setOptionVal}
          getTableDetails={getTableDetails}
        />
      ) : (
        ""
      )}

      {/* <CreateUser/> */}
    </div>
  );
};
