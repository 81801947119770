import React from "react";
import { useEffect } from "react";
// import axios from "axios";
// import { Country, State, City } from "country-state-city";
import StateCity from "./../../../Components/Data/StateCity";
import styles from "../users.module.css";

const UsersCityField = (props) => {
  const { formik, label, name, setCityName, states } = props;
  const { values } = formik;

  const a = StateCity.map((item) => {
    return { state_id: item.state_id, state_name: item.state_name };
  });

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const arr2 = getUniqueListBy(a, "state_name");

  const items = states;

  useEffect(() => {
    if (formik.values[`${name}`] !== formik.values[`${name}`]?.toUpperCase()) {
      formik.values[`${name}`] = "";
    }
  }, [[formik.values[`${name}`]], states]);

  // console.log("State::", formik.values[`${name}`], name);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label className="mb-2" id={styles.userLabel}>
        {label}
        <span className="text-danger"> *</span>
      </label>
      <select
        name={name}
        value={formik.values[`${name}`] || ""}
        onChange={formik.handleChange}
        style={{
          width: "90%",
          textTransform: "capitalize",
          // height: "54px",
          fontFamily: "Mulish",
          borderRadius: "5px",
          border:
            formik.errors[name] && formik.touched[name]
              ? "2px solid red"
              : null,
        }}
        error={formik.errors}
        className={`form-select ${styles.userSelectField}`}
      >
        {items.map((state, i) => {
          return (
            <option
              value={state}
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                fontFamily: "Mulish",
                margin: "14px 10px",
                color: "black",
                textTransform: "capitalize",
              }}
            >
              {state}
            </option>
          );
        })}
        <option
          value={""}
          hidden
          selected
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            fontFamily: "Mulish",
            color: "black",
            textTransform: "capitalize",
          }}
        >
          {""}
        </option>
      </select>
    </div>
  );
};

export default UsersCityField;
