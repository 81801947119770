import React from "react";
import DataTable from "../../Components/Table";
import { useHistory, useRouteMatch } from "react-router";
import { useDispatch } from "react-redux";
import {
  dataAcqActionCreator,
  selectedDataAcqRows,
} from "../../Redux/DataAcquisition/actionCreator";

const DataAcquisitionTable = (props) => {
  const { data, columns, columnsStatus, sel, setId,tableRef } = props;

  const history = useHistory();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const select = () => {
    return sel !== "select" ? true : false;
  };
  const page = () => {
    return sel !== "select" ? 10 : 5;
  };

  const options = {
    search: true,
    selection: true,
    filtering: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      fontWeight: "600",
      fontSize: 16,
      fontFamily: "Mulish",
      padding: "8px 5px",
    },
    cellStyle: {
      padding: "4px 10px",
    },
    maxBodyHeight: "58vh",
    pageSize: page(),

    searchFieldStyle: {
      marginRight: "5vw",
      width: "20vw",
    },
    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },
  };

  return (
    <>
      <DataTable
        tableRef={tableRef}
        title=""
        columns={columns.filter((e) => {
          return columnsStatus[e.field];
        })}
        data={data}
        options={options}
        onRowClick={(event, rowData) => {
          // dispatch(selectedDataAcqRows(rowData));
          if (select()) {
            setId(rowData.id);
            history.push(`${path}/dataAcq-info`);
          }
        }}
      />
    </>
  );
};

export default DataAcquisitionTable;
