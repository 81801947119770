import React from "react";
import ConfigList from "./ConfigList";
import styles from "../DataAcquisition/DataAcq.module.css";
import configStyles from "./Configuration.module.css";
const ConfigLeft = (props) => {
  const { configData, setConfigType } = props;
  return (
    <div
      className="mb-0 p-0"
      id={styles.scrollBar}
      style={{
        borderRight: "1px solid #CCCCCC",
        width: "25%",
        height: "100%",
        // overflow : "auto",
        // scrollBehavior:"smooth",
      }}
    >
      <div className={configStyles.configLeftHead}>Master Table</div>
      <hr style={{ border: "1px solid black", margin: "0px" }} />
      <ConfigList configData={configData} setConfigType={setConfigType} />
    </div>
  );
};

export default ConfigLeft;
