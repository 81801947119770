import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../Components/Table";
import MembersCard from "./MembersCard";
import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import {
  getDashboardTransfer,
  getPieChartDataActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../Redux/Dashboard/dashboardActionCreator";
import { getShareForMemberWithId, selectedMemberRows } from "../../Redux/Members/actionCreator";
import axios from "axios";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ShareHoldingTable = (props) => {
  const { shareApplicationMoneyTableData, setConflict, shareIssueTableData } =
    props;
  const [data, setData] = useState(0);
  const dispatch = useDispatch();
  const [userRole, serUserRole] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const userRoleFetch = useSelector((state) => {
    return state.auth.role;
  });
  useEffect(() => {
    // console.log("User role", userRole);
    serUserRole(userRoleFetch);
  }, [userRoleFetch]);
  const params = useParams();
  const id = params.id;
  const history = useHistory();

  const shareCertificate = useSelector((state) => {
    return state.members.memberData;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const surrenderShare = async () => {
    dispatch(loaderStartActionCreater());

    try {
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.put(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/surrender/" + `${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // let response = await axios.post(
      //   "http://localhost:8080" + "/api/v1/member/" + `${type}`,
      //   val
      // );
      dispatch(getShareForMemberWithId(params.id, getAuthData));
      dispatch(getPieChartDataActionCreater(getAuthData));
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      // getAllsharesDetails();
      dispatch(openSnackbar(`Shares Surrendered successfully.`, "success"));
      // console.log(response.data);
      dispatch(loaderEndActionCreater());
      // setUpdate(true);
      dispatch(selectedMemberRows(undefined, []));

      if (setConflict) setConflict(true);
      // handleClose();
      history.push("/admin/members");
      
    } catch (e) {
      // console.log(e.response);
      // console.log(e.request);
      dispatch(loaderEndActionCreater());
      if (e?.response?.data?.error)
        dispatch(openSnackbar(`${e?.response?.data?.error}`, "error"));
      if (e?.response?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    setData(
      shareCertificate?.map((ele) => {
        return {
          ...ele,
          shareApplicationAdd: ele.noOfShares * ele.pricePerShare,
        };
      })
    );
  }, [shareCertificate]);

  // console.log(data);

  const columns = [
    {
      title: "Certificate No.",
      field: "shareCertificationNo",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            minWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            display: "flex",
            paddingRight: "1rem",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{rowData.shareCertificationNo}</div>
          {/* {params.id === completeRowData?.toMemberId?.id ? ( */}
          <div>
            {" "}
            <a
              href={`${rowData.shareCertificationUrl}`}
              // href="https://sayas-public.s3.amazonaws.com/1644576309531icon.jpg"
              target={"_blank"}
              style={{ pointer: "cursor" }}
            >
              <DownloadIcon
                sx={{ height: "1.2ch", width: "1.2ch", display: "block" }}
              />
            </a>{" "}
          </div>
          {/* ) : (
            ""  
          )} */}
        </div>
      ),
    },
    {
      title: "Date",
      field: "updatedAt",
      // type: "date",
      dateSetting: {
        format: "dd/MM/yyyy",
      },
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.updatedAt.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) != -1,
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData.updatedAt.slice(0, -1)).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: "No. Of Shares",
      field: "noOfShares",
      headerStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Share Capital Added",
      field: "shareApplicationAdd",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {rowData.noOfShares * rowData.pricePerShare}
        </div>
      ),
    },
    {
      title: "Validity",
      field: "isValid",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
            textTransform: "capitalize",
            color: `${rowData?.isValid ? "green" : "red"}`,
          }}
        >
          {`${rowData?.isValid ? "Valid" : "Invalid"}`}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData?.isValid ? "valid " : "invalid").indexOf(term.toLowerCase()) !=
        -1,
    },
  ];

  const options = {
    search: true,
    // selection: true,
    filtering: true,
    // selection: true,

    headerStyle: {
      backgroundColor: "#E7E7E7",
      color: "#000",
      padding: "10px",
      whiteSpace: "nowrap",
      padding: "8px 5px",
    },

    cellStyle: {
      padding: "10px",
    },

    filterCellStyle: {
      padding: "5px 18px 5px 8px",
    },

    // paging: false,
    maxBodyHeight: "45vh",
  };

  return (
    <>
      <div>
        <MembersCard
          shareIssueTableData={shareIssueTableData}
          setConflict={setConflict}
        />
      </div>

      <div style={{ marginTop: "-10px", marginBottom: "20px" }}>
        {userRole.toLowerCase() === "admin" ? (
          <Button
            variant="contained"
            color="error"
            disabled={userRole.toLowerCase() === "admin" ? false : true}
            onClick={() => {
              // alert("clicked");
              // surrenderShare();
              handleOpen();
            }}
          >
            Surrender Shares
          </Button>
        ) : (
          ""
        )}
      </div>

      {
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Do you want to proceed and Surrender shares?
            </Typography>
            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
            <Stack
              spacing={2}
              direction="row"
              sx={{
                marginTop: "2rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="text"
                onClick={() => {
                  // surrenderShare();
                  handleClose();
                }}
              >
                cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  surrenderShare();
                  handleClose();
                }}
              >
                Proceed
              </Button>
            </Stack>
          </Box>
        </Modal>
      }

      <DataTable
        title="Share Certificate Table"
        columns={columns}
        data={data}
        options={options}
      />
    </>
  );
};

export default ShareHoldingTable;
