import { Box, Grid, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
import { GeneralSelectField } from "../../Components/GeneralComponents/GeneralSelectField";
import Image from "../Configuration/pinDocs.png";
import "./CommunicationForm.css";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import axios from "axios";
import moment from "moment";
import { handleUpload } from "../../Components/GeneralComponents/GeneralFileUpload";
import { loaderStartActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { getConfiguationActionCreater } from "../../Redux/Configuration/configurationActionCreator";

const initialData = {
  communicationId: new Date().valueOf(),
  description: "",
  visibleTo: "",
  communicationType: "",
  title: "",
  communicationMode: "",
  attachmentUrl: "attachmentUrl",
  memberIds: [],
  memberId: "",
};

export const CommunicationForm = (props) => {
  const {
    setOpenModal,
    currentRowData,
    tableUpdate,
    setTableUpdate,
    setCurrentRowData,
    totalDataLength,
  } = props;

  const [data, setData] = useState(
    currentRowData.length === 0 ? initialData : currentRowData
  );
  const [shareTypes, setShareTypes] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isVisibleCustom, setIsVisibleCustom] = useState("");
  const dispatch = useDispatch();
  const [file, setFile] = useState("");

  const [uploadFile, setUploadFile] = useState(
    `${data.attachmentUrl ? data.attachmentUrl : "attachmentUrl"}`
  );

  const addCommunicationData = async () => {
    try {
      // console.log(data);
      // console.log("IDFormate",moment(new Date()).format("YYYY/MM/DD/hhmmss"));

      data["communicationId"] = `${moment(new Date())
        .format("YYYY/MM/DD")
        .toString()}/${("0000000" + (totalDataLength + 3)).slice(-7)}`;

      // ("0000000" + (dataLength + 1)).slice(-7)

      data["dateAndTime"] = new Date();
      data.attachmentUrl = file === "" ? "" : await handleUpload(file);
      data.visibleTo = data.visibleTo.toLowerCase();
      // data.visibleTo =
      //   data.visibleTo === "all members" ? "all" : data.visibleTo;
      // console.log(data.memberId.match(/[^\s]+/g));
      // data.communicationType2 = data.type;
      data.memberIds = [
        ...data?.memberId?.replace(/,\s+/g, ",").split(/[\n,\s+]/),
      ];

      // console.log(data.type);
      //   data?.memberId?.replace(/,\s+/g, ",").split(/[\n,\s+]/)
      // );
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }

      // "http://localhost:8080" + "/api/v1/communication",
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/communication",
        data,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      dispatch(
        openSnackbar(
          `${data?.communicationType} has been sent successfully`,
          "success"
        )
      );
      setSubmitted(false);
      setTableUpdate(true);
      // data.memberIds = [];
    } catch (e) {
      // dispatch(openSnackbar(e, "error"));
      if (e?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
    setData(initialData);
  };

  useEffect(() => {
    setData({ ...data, attachmentUrl: uploadFile });
  }, [uploadFile]);

  const handleSubmit = async () => {
    // console.log(data);
    setSubmitted(true);

    if (
      data.description === "" ||
      data.visibleTo === "" ||
      data.communicationType === "" ||
      data.title === "" ||
      data.communicationMode === ""
    ) {
      dispatch(openSnackbar("Please fill the all mandatory * field", "error"));
      return;
    } else {
      setOpenModal(false);
      dispatch(loaderStartActionCreater());
      await addCommunicationData();
    }
  };
  const handleCancel = () => {
    // dispatch(
    //   openSnackbar(
    //     `${
    //       data.communicationType === ""
    //         ? "Communication"
    //         : data.communicationType
    //     } cancelled`,
    //     "error"
    //   )
    // );

    setOpenModal(false);
    setCurrentRowData([]);
  };

  ///config Data fetch
  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  useEffect(() => {
    // console.log(getConfigData);
    let newShareTypes = getConfigData.memberType
      ? getConfigData.memberType.map((e) => {
          return e.type;
        })
      : [];

    setShareTypes(["All Members", ...newShareTypes, "Custom"]);
  }, [getConfigData]);

  ///auth token
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  useEffect(() => {
    dispatch(getConfiguationActionCreater(getAuthData));
  }, []);

  // console.log("data in communcation", data);

  return (
    <div
      style={{
        padding: "10px",
        width: "40vw",
        textTransform: "capitalize",
      }}
    >
      <div
        style={{
          fontFamily: "Mulish",
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "1.5rem",
          color: "#303030",
          lineHeight: "23px",
          color: "#303030",
        }}
      >
        Communication
      </div>
      <Box
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "space-evenly",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          // width: "100%",
        }}
      >
        <GeneralSelectField
          data={data}
          setData={setData}
          lowMargin
          width={"49%"}
          submitted={submitted}
          dataKey={"communicationType"}
          place={"Communication *"}
          items={["Announcement", "Notification", "Alert", "News"]}
          disabled={currentRowData.length !== 0 ? true : false}
        />
        <GeneralInputField
          data={data}
          width={"49%"}
          lowMargin
          required
          submitted={submitted}
          dataKey={"title"}
          setData={setData}
          place={"Title"}
          disabled={currentRowData.length !== 0 ? true : false}
        />
        {/* <GeneralInputField
          data={data}
          width={"49%"}
          lowMargin
          dataKey={"url"}
          setData={setData}
          label={"URL  "}
          disabled={currentRowData.length !== 0 ? true : false}
        /> */}
        <GeneralSelectField
          data={data}
          setData={setData}
          lowMargin
          submitted={submitted}
          width={"49%"}
          dataKey={"communicationMode"}
          place={"Type *"}
          items={["Mobile App", "Email", "Both"]}
          disabled={currentRowData.length !== 0 ? true : false}
        />
        <GeneralSelectField
          data={data}
          setData={setData}
          lowMargin
          submitted={submitted}
          width={"49%"}
          setIsVisibleCustom={setIsVisibleCustom}
          dataKey={"visibleTo"}
          value={data?.visibleTo
            ?.split(" ")
            .map((ele) => {
              return ele?.charAt(0).toUpperCase() + ele?.slice(1);
            })
            .join(" ")}
          // value={"Custom"}
          place={"Visible To *"}
          items={shareTypes.length > 0 ? shareTypes : []}
          disabled={currentRowData.length !== 0 ? true : false}
        />
        {/* /////custom member List */}
        {isVisibleCustom.toLowerCase() === "custom" ||
        data?.visibleTo.toLowerCase() === "custom" ? (
          <Grid item sx={{ marginBottom: "-.8rem", width: "100%" }}>
            <GeneralInputField
              data={data}
              multiline
              lowMargin
              // required
              // submitted={submitted}
              dataValue={data.memberIds.join(", ")}
              fullWidth
              width={"100%"}
              dataKey={"memberId"}
              setData={setData}
              label={"Custom Members List"}
              disabled={currentRowData.length !== 0 ? true : false}
            />
          </Grid>
        ) : (
          ""
        )}

        <Grid
          container
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "4rem",
            borderBottom: 0.5,
            borderColor: "#8c8c8c",
            mb: 0.7,
            mt: 2,
            borderRadius: "5px",
            // bgcolor: "#f0f0f0",
          }}
        >
          <Grid
            xs={12}
            className="communicationFileName"
            sx={{
              // overflow: "auto",
              p: "0px 0px",
              fontSize: 18,
              fontWeight: "bold",
            }}
            // style = {{position : "absolute"}}
          >
            {/* {uploadFile.name} */}
            <GeneralInputField
              fullWidth
              data={data}
              setData={setData}
              // lowMargin
              uploadFile={uploadFile}
              disabled
              width={"99.5%"}
              dataValue={uploadFile}
              // paddingRight={"3.5rem"}
              label={"Attachment"}
              link
            />
          </Grid>

          {/* uncomment when required */}
          {currentRowData.length === 0 ? (
            <Grid
              xs={2}
              className="communicationPinIcon"
              style={{
                position: "absolute",
                zIndex: "1",
                right: "2.5rem",
                marginTop: "1.6rem",
                cursor: "pointer",
              }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "3rem",
                width: "3rem",
                cursor: "pointer",
              }}
            >
              {" "}
              <input
                type="file"
                accept="*/*"
                style={{ opacity: 0, position: "absolute", cursor: "pointer" }}
                onChange={async (e) => {
                  setFile(e.target.files);
                  setUploadFile(e.target.files[0].name);
                }}
                id="contained-button-file"
              />
              <img src={Image} srcSet={Image} alt="pin" loading="lazy" />{" "}
            </Grid>
          ) : (
            ""
          )}
          {/* uncomment when required */}
        </Grid>

        <Grid item sx={{ mb: 2, width: "100%" }}>
          <GeneralInputField
            data={data}
            multiline
            lowMargin
            required
            submitted={submitted}
            fullWidth
            dataKey={"description"}
            setData={setData}
            label={"Description"}
            disabled={currentRowData.length !== 0 ? true : false}
          />
        </Grid>
      </Box>

      {/* Button Secction */}
      <Grid
        fullWidth
        sx={{
          p: 1,
          m: 1,
          display: "flex",
          flexDirection: "row-reverse",
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
        }}
      >
        {currentRowData.length === 0 ? (
          <Button
            onClick={handleSubmit}
            className="sendBtn"
            variant="contained"
            sx={{
              bgcolor: "#0096FF!important",
              color: "#FFFFFF",
              marginLeft: "1rem",
              marginRight: "-0.5rem",
              padding: "10px 2rem",
              borderRadius: "5px",
              fontFamily: "Mulish",
              fontWeight: "bold",
              fontStyle: "normal",
              height: "2.5rem",
            }}
          >
            Send
          </Button>
        ) : (
          ""
        )}

        <Button
          variant="text"
          className="sendBtn"
          sx={{
            textTransform: "capitalize",
            color: "#ACACAC",
            padding: "0rem 2rem",
            borderRadius: "5px",
            fontFamily: "Mulish",
            fontWeight: "bold",
            fontStyle: "normal",
            height: "2.5rem",
          }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Grid>
    </div>
  );
};
