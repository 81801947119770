import React, { useState, useEffect } from "react";
import { userActionCreator } from "../../Redux/Users/actionCreator";
import { useDispatch } from "react-redux";
import { memberActionCreator } from "../../Redux/Members/actionCreator";
import { useHistory } from "react-router";
import styles from "./ListItems.module.css";
import { Checkbox } from "@mui/material";
const ListItems = (props) => {
  const {
    firstName,
    lastName,
    email,
    currentVal,
    selectedData,
    setSelectedData,
    data,
    id,
    setId,
  } = props;

  const history = useHistory();

  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  const handleSelectedData = (newData) => {
    // if (checked) {
    //   let val = selectedData.filter((item) => item.id !== newData.id);
    //   setSelectedData(val);
    // } else {
    //   setSelectedData([...selectedData, newData]);
    // }
    // let val = selectedData.filter((item) => item.id !== newData.id);
    // if (val.length == selectedData.length) {
    //   setSelectedData({...selectedData, newData});
    // } else {
    //   setSelectedData(val);
    // }

    let tempObj = selectedData;
    if (selectedData[newData.id]) {
      tempObj[newData.id] = false;
    } else {
      tempObj[newData.id] = true;
    }
    setSelectedData({ ...tempObj });
  };

  useEffect(() => {
    setChecked(selectedData[data.id] ? true : false);
  }, [selectedData]);

  return (
    <div
      className="d-flex justify-content-start align-items-center py-2"
      style={{ background: id == data.id ? "rgba(0, 146, 232, 0.08)" : "" }}
    >
      <div style={{ margin: "0 20px" }}>
        {/* <input
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            // setChecked(!checked);
            handleSelectedData(data);
          }}
          style={{ width: "25px", height: "25px", color: "#0096FF!important" }}
          // onClick={() => handleSelectedData(data)}
        /> */}
        <Checkbox
          checked={checked}
          onChange={(e) => {
            // setChecked(!checked);
            handleSelectedData(data);
          }}
          style={{
            color: `${checked ? "#0096FF" : null}`,
          }}
        />
      </div>
      <div
        style={{ cursor: "pointer", width: "100%" }}
        onClick={() => {
          setId(data.id);
          dispatch(userActionCreator(currentVal));
          dispatch(memberActionCreator(currentVal));
          history.push(`${data.id}`);
        }}
      >
        <h5 className={styles.name}>
          {firstName} {lastName}
        </h5>
        <p className={styles.id}>{email}</p>
      </div>
    </div>
  );
};

export default ListItems;
