import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import MySnackbar from "./Components/Snackbar/Snackbar";
// import history from "./history";

import Routes from "./Routes/routes";
import { loaderEndActionCreater } from "./Redux/Loader/LoaderActionCreator";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loaderEndActionCreater());
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <MySnackbar />
    </>
  );
}

export default App;
