import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "./member.module.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GeneralSelectField } from "../../Components/GeneralComponents/GeneralSelectField";
import { GeneralInputField } from "../../Components/GeneralComponents/GeneralInputField";
// import { confirmTransferShare } from "./MemberForm/confirmTransferShare";
import axios from "axios";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Slide,
  TextField,
} from "@mui/material";
import {
  getDashboardTransfer,
  getPieChartDataActionCreater,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../Redux/Dashboard/dashboardActionCreator";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import ConfirmTransferShare from "./MembersForm/confirmTransferShare";
import { useHistory } from "react-router-dom";
var initialState = {
  fromMemberID: "",
  fromMemberIDNew: "",
  fromMemberName: "",
  toMemberID: "",
  toMemberIDNew: "",
  toMemberName: "",
  typeOfShare: "",
  totalShare: null,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [mnth, day, date.getFullYear()].join("/");
}

export default function MemberModal(props) {
  const { open, setOpen, setConflict2 } = props;
  const [submitted, setSubmitted] = useState(false);
  var [checkVal, setCheckVal] = useState(false);
  var [conflict, setConflict] = useState(false);
  const [shareTypes, setShareTypes] = useState([]);
  const [data, setData] = useState(initialState);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [availableShares, setAvailableShares] = useState({});
  const dispatch = useDispatch();
  const [maxVal, setMaxVal] = useState(0);
  const history = useHistory();

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };
  /// fetch config data

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });

  const getMemberDetails = async (id, key, key2) => {
    // console.log("Calling!!");
    if (id === "") {
      setData({ ...data, [key]: "" });
      return;
    }
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/memberId/" + `${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log(response);
      let memberName =
        response.data.firstName +
        " " +
        response.data.middleName +
        " " +
        response.data.lastName;
      // console.log(memberName);
      setData({ ...data, [key]: memberName, [key2]: response.data.id });
      setConflict(false);
    } catch (err) {
      // console.log(err?.response.data.error);
      setData({ ...data, [key]: "" });
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      // setConflict(false);
    }
  };

  const postMemberTransfer = async (val) => {
    try {
      // console.log(val);
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/v1/member/transferShares",
        val,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      dispatch(getPieChartDataActionCreater(getAuthData));

      dispatch(
        openSnackbar(
          `${val.noOfShares} Shares Transferred from ${data.fromMemberName} to ${data.toMemberName} successfully`,
          "success"
        )
      );
      setConflict2(true);
      // history.push("/admin/member");

      dispatch(loaderEndActionCreater());
    } catch (err) {
      // console.log(err.response.data.error);
      if (err?.response?.data?.error)
        dispatch(openSnackbar(err?.response?.data?.error, "error"));
      dispatch(loaderEndActionCreater());
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    setData({
      fromMemberID: "",
      fromMemberName: "",
      toMemberID: "",
      toMemberName: "",
      typeOfShare: "",
      totalShare: 0,
    });
  }, []);

  useEffect(() => {
    setShareTypes(
      getConfigData.shareType
        ? getConfigData.shareType.map((e) => {
            return e.type;
          })
        : []
    );
  }, [getConfigData]);

  const handleClose = () => {
    setOpen(false);
    // setOpenConfirmModal(false);
    // setOpen2(false);
    setData({
      fromMemberID: "",
      fromMemberName: "",
      toMemberID: "",
      toMemberName: "",
      typeOfShare: "",
      totalShare: null,
    });
    setSubmitted(false);
    setConflict(true);
  };

  useEffect(() => {
    getMemberDetails(data.fromMemberID, "fromMemberName", "fromMemberIDNew");
  }, [data.fromMemberID]);

  useEffect(() => {
    if (data.fromMemberID) getAvailableShares(data.fromMemberIDNew);
  }, [data.fromMemberIDNew]);

  // useEffect(() => {
  //   console.log("mylog", data);
  // }, [data]);

  useEffect(() => {
    getMemberDetails(data.toMemberID, "toMemberName", "toMemberIDNew");
  }, [data.toMemberID]);

  const handleClickOpen = () => {
    setSubmitted(true);
    // console.log(data);
    if (
      data.fromMemberID === "" ||
      data.toMemberID === "" ||
      data.typeOfShare === "" ||
      data.totalShare === ""
    ) {
      dispatch(openSnackbar("Please fill all mandatory field", "error"));
      // console.log("you enter wrong from member id");
      return;
    } else if (data.toMemberName === "" || data.fromMemberName === "") {
      dispatch(openSnackbar("Entered Membership ID is incorrect", "error"));
      // console.log("you enter wrong to member id");
      return;
    } else if (data.typeOfShare === "" || data.totalShare === null) {
      return;
    } else if (data.fromMemberID === data.toMemberID) {
      dispatch(openSnackbar("Same member share transfer not allowed", "error"));
      return;
    } else if (parseInt(data.totalShare) <= 0) {
      dispatch(openSnackbar("Please enter valid number.", "error"));
      return;
    } else if (
      parseInt(data.totalShare) >
      parseInt(availableShares[data.typeOfShare.toLowerCase()])
    ) {
      dispatch(
        openSnackbar("Enter the no. less than available shares", "error")
      );
      return;
    }
    // setOpen(false);
    setOpenConfirmModal(true);
  };

  const handleSubmit = () => {
    if (!checkVal) {
      dispatch(openSnackbar("please check the confirm box.", "error"));
      return;
    }

    let newData = {
      fromMemberId: data.fromMemberIDNew,
      typeOfShare: data.typeOfShare,
      noOfShares: parseInt(data.totalShare),
      toMemberId: data.toMemberIDNew,
    };

    dispatch(loaderStartActionCreater());
    postMemberTransfer(newData);
    handleClose();
  };

  const handleFomMemberIDChange = (e) => {
    setData({ ...data, ["fromMemberID"]: e.target.value });
    getMemberDetails(data.fromMemberID, "fromMemberName");
  };

  const handletoMemberIDChange = (e) => {
    setData({ ...data, ["toMemberID"]: e.target.value });
    getMemberDetails(data.fromMemberID, "toMemberName");
  };

  const handleCheck = () => {
    setCheckVal(!checkVal);
  };

  const getAvailableShares = async (id) => {
    // console.log("calling with id", id);
    try {
      ////checktoken
      let token = window.localStorage.getItem("sayasLoginToken");
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/member/availableShares/${id}`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      // console.log("#########", response.data);
      setAvailableShares(response.data);
    } catch (err) {
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  useEffect(() => {
    setMaxVal(availableShares[data.typeOfShare.toLowerCase()]);
  }, [availableShares]);

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <label id={styles.lab}>Transfer Shares</label>
        </DialogTitle>
        <hr className="m-0" />
        <DialogContent>
          <DialogContentText>
            <div className="row">
              <div className="col">
                <label id={styles.lab}>From</label>
                <div
                  style={{
                    padding: "5px 10px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "5px",
                    textTransform: "capitalize",
                    marginBottom: "0rem",
                  }}
                >
                  <GeneralInputField
                    data={data}
                    setData={setData}
                    conflict={conflict}
                    setConflict={setConflict}
                    fullWidth
                    required
                    submitted={submitted}
                    value={data.fromMemberID}
                    dataKey={"fromMemberID"}
                    useFor={"api"}
                    label={"Membership ID"}
                  />
                  {/* <div
                    style={{
                      margin: "1rem .1rem",

                      textTransform: "capitalize",
                    }}
                  >
                    <TextField
                      fullWidth
                      error={
                        submitted && data.fromMemberID === "" ? true : false
                      }
                      // id={styles.userInput}
                      // className="form-control"
                      // required={true}
                      value={data.fromMemberID}
                      onChange={handleFomMemberIDChange}
                      label={"Membership ID"}
                      id="fullWidth"
                    />
                  </div> */}
                  <GeneralInputField
                    data={data}
                    setData={setData}
                    value={data.fromMemberName}
                    submitted={submitted}
                    fullWidth
                    disabled
                    dataKey={"fromMemberName"}
                    label={"Member Name"}
                  />
                </div>
                {/* <TextField
                  margin="dense"
                  id="name"
                  label="Type of Share"
                  type="email"
                  fullWidth
                  variant="outlined"
                /> */}
                <GeneralSelectField
                  data={data}
                  setData={setData}
                  fullWidth
                  required
                  submitted={submitted}
                  dataKey={"typeOfShare"}
                  place={"Type of Share *"}
                  items={shareTypes.length > 0 ? shareTypes : []}
                />
                {data.typeOfShare && (
                  <div
                    className="mt-2"
                    style={{
                      fontSize: 14,
                      color: availableShares[data.typeOfShare.toLowerCase()]
                        ? "green"
                        : "red",
                    }}
                  >
                    Available Shares:{"  "}
                    {availableShares[data.typeOfShare.toLowerCase()] || 0}
                  </div>
                )}
              </div>
              <div className="col">
                <label id={styles.lab}>To</label>
                <div>
                  <div
                    style={{
                      padding: "5px 10px",
                      border: "1px solid #c4c4c4",
                      borderRadius: "5px",
                      textTransform: "capitalize",
                    }}
                  >
                    <GeneralInputField
                      data={data}
                      setData={setData}
                      conflict={conflict}
                      setConflict={setConflict}
                      fullWidth
                      useFor={"api"}
                      required
                      submitted={submitted}
                      value={data.toMemberID}
                      dataKey={"toMemberID"}
                      label={"Membership ID"}
                    />
                    {/* <div
                      style={{
                        margin: "1rem .1rem",

                        textTransform: "capitalize",
                      }}
                    >
                      <TextField
                        fullWidth
                        error={
                          submitted && data.toMemberID === "" ? true : false
                        }
                        // id={styles.userInput}
                        className="form-control"
                        required={true}
                        // value={data.toMemberID}
                        onChange={handletoMemberIDChange}
                        label={"Membership ID"}
                        // id="fullWidth"
                      />
                    </div> */}
                    <GeneralInputField
                      data={data}
                      setData={setData}
                      value={data.toMemberName}
                      submitted={submitted}
                      fullWidth
                      disabled
                      dataKey={"toMemberName"}
                      label={"Member Name"}
                    />
                  </div>

                  <GeneralInputField
                    data={data}
                    setData={setData}
                    fullWidth
                    required
                    submitted={submitted}
                    maxLimit={11}
                    maxVal={parseInt(maxVal || 0)}
                    onlyNumber
                    // value={data.totalShare}
                    dataKey={"totalShare"}
                    label={"Number of Share"}
                  />
                </div>
              </div>
            </div>
          </DialogContentText>
          <DialogContentText align="right" className="mt-4">
            <Button
              onClick={handleClickOpen}
              variant="contained"
              sx={{
                bgcolor: "#0096FF",
              }}
            >
              Transfer
            </Button>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <ConfirmTransferShare
        newData={data}
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        handleClosetransfer={handleClose}
        setConflict2={setConflict2}
      />
    </div>
  );
}
