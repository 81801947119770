import axios from "axios";
import { LogoutSayasActionCreate } from "../../Redux/Authontication/authonticationActionCreator";
import { BASE_URL } from "./../../Components/URL/Url";

export const getTableDetails = async (setData, url, token, dispatch) => {
  try {
    if (!token) {
      dispatch(LogoutSayasActionCreate());
    }
    let response = await axios.get(
      // process.env.REACT_APP_BASE_URL + url
      BASE_URL + url,
      {
        headers: {
          Authorization: `Bearer ` + `${token}`,
        },
      }
    );
    setData(response.data.data);
    // console.log(response.data);
  } catch (e) {
    console.log(e);
  }
};
