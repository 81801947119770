import {
  Button,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
} from "@mui/material";
import { Box, Popover } from "@mui/material";
import React, { useEffect, useState, useRef, memo, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loaderStartActionCreater } from "../../Redux/Loader/LoaderActionCreator";
import { openSnackbar } from "../../Redux/Snackbar/snackbarStore";
import CustomSlider from "../CustomSlider/CustomSlider";
import { MultipleElasticSearch } from "./multipleAdvanceSearch ";
import { MultipleSearch } from "./multipleSearch";
import StateCity from "../Data/StateCity";
import { city } from "../Data/maharashtraCity";

const CustomSearch = forwardRef((props, ref) => {
  const {
    data,
    setTableData,
    setFinalAdvanceSearchData,
    rangeSearchCol,
    columns,
    setOptionVal,
    simpleSearchCol,
    tableData,
    moduleName,
    isAction,
    setIsAction,
    finalAdvanceSearchData,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownData, setDropdownData] = useState();
  const [searchData, setSearchData] = useState({});
  const [stateList, setStateList] = useState();
  const [maharashtraDistList, setMaharashtraDistList] = useState();
  const [maharashtraTalukaList, setMaharashtraTalukaList] = useState();
  const [memberType, setMemberType] = useState([]);
  const [shareHolderType, setShareHolderType] = useState([]);

  // console.log(data)

  const getConfigData = useSelector((state) => {
    return state.configuration;
  });

  // console.log(ref?.current?.state?.data);
  // console.log("simpleSearchCol!!", simpleSearchCol);
  // console.log("In Custom search::", data);
  //get state list
  useEffect(() => {
    let newList = StateCity.reduce((ans, val) => {
      return ans?.includes(val.state_name) ? ans : [...ans, val.state_name];
    }, []);
    let distList = city.map((ele) => ele.name);
    let talukaList = city.reduce((ans, val) => {
      return { ...ans, [`${val.name}`]: val.tahasil };
    }, {});
    // console.log(newList);
    // console.log(distList, talukaList);
    setStateList(newList);
    setMaharashtraDistList(distList);
    setMaharashtraTalukaList(talukaList);
  }, [StateCity, city]);

  // console.log(maharashtraDistList);
  // console.log(maharashtraTalukaList);

  const searchVal = useRef(null);
  const dispatch = useDispatch();

  const arrowHandleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const arrowHandleClose = () => {
    setAnchorEl(null);
  };

  const openP = Boolean(anchorEl);
  const id = openP ? "simple-popover" : undefined;

  useEffect(() => {
    // if (ref?.current?.state?.data?.length > 0) {
    if (data?.length > 0) {
      let dropData = data?.reduce((ans, val) => {
        let dummyDq = ans;
        simpleSearchCol?.map((ele) => {
          if (val["toMemberId"]) {
            // console.log(
            //   val["toMemberId"] ? val["toMemberId"][ele[1]] : "Not available"
            // );
            dummyDq[ele[1]] = dummyDq[ele[1]]?.includes(
              val["toMemberId"][ele[1]]
            )
              ? dummyDq[ele[1]]
              : dummyDq[ele[1]]
              ? [...dummyDq[ele[1]], val["toMemberId"][ele[1]]]
              : [val["toMemberId"][ele[1]]];
          }
          if (val["fromMemberId"]) {
            // console.log(
            //   val["toMemberId"] ? val["toMemberId"][ele[1]] : "Not available"
            // );
            dummyDq[ele[1]] = dummyDq[ele[1]]?.includes(
              val["fromMemberId"][ele[1]]
            )
              ? dummyDq[ele[1]]
              : dummyDq[ele[1]]
              ? [...dummyDq[ele[1]], val["fromMemberId"][ele[1]]]
              : [val["fromMemberId"][ele[1]]];
          }
        });
        return dummyDq;
      }, {});

      // console.log("dropData", dropData);
      // dropData.state = stateList; // state list
      // dropData.member = memberType; //configuration
      // dropData.shareholder = shareHolderType; //configuration
      // dropData.mobileStatus = ["Enabled", "Disabled"]; //static
      // dropData.activity = ["Active", "Inactive"]; //static
      setDropdownData(dropData);
      // setAllVal(myState);
    }

    // console.log("simpleSearchCol", simpleSearchCol);
    // console.log("ref", ref?.current?.state?.data);
  }, [ref, openP]);

  const customSearch = () => {
    let newData = data?.filter((ele) => {
      // console.log("in filter!!", ele, Object.keys(searchData));
      return Object.keys(searchData)?.reduce((ans, val) => {
        return (
          (searchData[`${val}`]?.length <= 0 && ans) ||
          (((ele["toMemberId"] &&
            searchData[`${val}`]?.includes(ele["toMemberId"][`${val}`])) ||
            (ele["fromMemberId"] &&
              searchData[`${val}`]?.includes(ele["fromMemberId"][`${val}`]))) &&
            ans)
        );
      }, true);
    });

    // console.log(newData);

    arrowHandleClose();
    setTableData(newData);
    if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(newData);
    // if (setOptionVal) setOptionVal("All Members");
  };

  const allSearch = () => {
    if (searchVal.current?.value.trim() !== "") {
      // let newData = data?.filter((item) => {
      //   return Object.keys(item).some((key) => {
      //     if (!columns.map((col) => col.field).includes(key)) return false;

      //     let searchFun = columns.find(
      //       (col) => col.field === key
      //     )?.customFilterAndSearch;

      //     if (searchFun) console.log("mylog", key, searchFun);

      //     let boolVal = searchFun
      //       ? searchFun(searchVal.current.value, item)
      //       : String(item[key])
      //           .toLowerCase()
      //           .indexOf(searchVal.current.value.toLowerCase()) > -1;
      //     return boolVal;
      //   });
      // });

      // console.log(searchVal.current?.value);
      // let newData = ref?.current?.state?.data.filter((rowData) =>

      let newData = finalAdvanceSearchData?.filter((rowData) =>
        columns.some((col) => {
          if (col.customFilterAndSearch) {
            return col.customFilterAndSearch(searchVal.current.value, rowData);
          } else if (col.render) {
            let renderText = col.render(rowData);
            if (typeof renderText === "object")
              renderText = renderText.props.children;
            return (
              renderText
                .toLowerCase()
                .indexOf(searchVal.current.value.toLowerCase()) > -1
            );
          } else {
            return (
              rowData[col.field]
                ?.toString()
                .toLowerCase()
                .indexOf(searchVal.current.value.toLowerCase()) > -1
            );
          }
        })
      );

      setTableData(newData);
      // if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(newData);
    } else {
      setTableData(finalAdvanceSearchData);
    }
    // else if (searchVal.current?.value === "") {
    //   setTableData(data);
    //   // if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(data);
    //   if (setOptionVal) setOptionVal("All Members");
    //   customSearch();
    // }
  };

  const handleClear = () => {
    // arrowHandleClose();
    setTableData(data);
    if (setFinalAdvanceSearchData) setFinalAdvanceSearchData(data);
    setSearchData({});
  };

  useEffect(() => {
    // console.log("In", isAction);
    customSearch();
    if (isAction) setIsAction(false);
  }, [isAction]);

  // console.log("itemList=", searchData);
  // console.log("maharashtraTalukaList", maharashtraTalukaList);

  return (
    <>
      <TextField
        id="outlined-search"
        className="searchBox"
        type="search"
        placeholder="Search"
        inputRef={searchVal}
        onChange={allSearch}
        InputProps={{
          endAdornment: (
            <i
              className="fa fa-caret-down"
              onClick={arrowHandleClick}
              style={{ fontSize: "20px", color: "#787878", cursor: "pointer" }}
            />
          ),
          startAdornment: (
            <i className="fa fa-search" style={{ color: "#858585" }} />
          ),
        }}
        sx={{
          backgroundColor: "#f6f6f6",
          borderRadius: "5px",
          "& input": {
            padding: "10px",
          },
          "& fieldset": {
            display: "none",
          },
        }}
      />

      {openP ? (
        <Popover
          id={id}
          open={openP}
          anchorEl={anchorEl}
          onClose={arrowHandleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              width: `${simpleSearchCol ? "60rem" : "50rem"}`,
              // width: "60rem",
              overFlow: "auto",
              maxHeight: "80vh",
            }}
          >
            <div
              style={{
                display: "grid",
                gap: "10px",
                width: "100%",
                padding: "20px 30px 20px",
                alignItems: "center",
                overFlow: "auto",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  overflow: "auto",
                }}
              >
                {dropdownData
                  ? simpleSearchCol?.map((ele) => {
                      return (
                        <MultipleElasticSearch
                          title={ele[0]}
                          setSearchData={setSearchData}
                          searchData={searchData}
                          keyValue={ele[1]}
                          // itemList={dropdownData[ele[1]].filter((ele) => {
                          //   return ele != null && ele != "";
                          // })}
                          itemList={
                            ele[1] === "district"
                              ? searchData?.state?.includes("Maharashtra")
                                ? [
                                    ...new Set([
                                      ...maharashtraDistList,
                                      ...(dropdownData[ele[1]]?.filter(
                                        (ele) => {
                                          return ele != null && ele != "";
                                        }
                                      ) || []),
                                    ]),
                                  ]
                                : dropdownData[ele[1]]?.filter((ele) => {
                                    return ele != null && ele != "";
                                  })
                              : ele[1] === "taluka"
                              ? searchData?.state?.includes("Maharashtra")
                                ? [
                                    ...new Set([
                                      ...(searchData?.district?.reduce(
                                        (ans, val) => {
                                          return maharashtraTalukaList[`${val}`]
                                            ? [
                                                ...ans,
                                                ...maharashtraTalukaList[
                                                  `${val}`
                                                ],
                                              ]
                                            : ans;
                                        },
                                        []
                                      ) || []),
                                      ...(dropdownData[ele[1]]?.filter(
                                        (ele) => {
                                          return ele != null && ele != "";
                                        }
                                      ) || []),
                                    ]),
                                  ]
                                : dropdownData[ele[1]]?.filter((ele) => {
                                    return ele != null && ele != "";
                                  })
                              : dropdownData[ele[1]]?.filter((ele) => {
                                  return ele != null && ele != "";
                                })
                          }
                        />
                      );
                    })
                  : ""}
              </div>
            </div>

            <hr className="m-0" />
            <div
              style={{
                padding: "20px 20px 20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  onClick={handleClear}
                  sx={{
                    bgcolor: "#0096FF",
                  }}
                >
                  Clear
                </Button>
              </div>
              <div>
                <Button onClick={arrowHandleClose}>Cancel</Button>
                <Button
                  variant="contained"
                  onClick={customSearch}
                  sx={{
                    bgcolor: "#0096FF",
                  }}
                >
                  Search
                </Button>
              </div>
            </div>
          </Box>
        </Popover>
      ) : (
        ""
      )}
    </>
  );
});

export default memo(CustomSearch);
