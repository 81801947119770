import { ShareTransferHeader } from "./ShareTransferHeader";
import { ShareTransferTable } from "./ShareTransferTable";
import { useDispatch, useSelector } from "react-redux";
import { createRef, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import {
  loaderEndActionCreater,
  loaderStartActionCreater,
} from "../../../Redux/Loader/LoaderActionCreator";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";
import CustomSearchForReport from "../../../Components/CustomSearch/CustomSearchForReport";
import { reportDataVisibiltyActionCreater } from "../../../Redux/Reports/reportActionCreator";
import { MultiSelectDropdown } from "../../../Components/SelectDropdown/MultiSelectDropdown";
function convert(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
var sort = function (prop, arr) {
  prop = prop.split(".");
  var len = prop.length;
  // console.log(arr);
  arr.sort(function (a, b) {
    var i = 0;
    while (i < len) {
      a = a[prop[i]];
      b = b[prop[i]];
      i++;
    }
    // console.log(moment(a).toLocaleString());
    if (
      moment(a.slice(0, -1).format("DDMMyyyykkmmss")) >
      moment(b.slice(0, -1).format("DDMMyyyykkmmss"))
    ) {
      return -1;
    } else if (
      moment(a.slice(0, -1).format("DDMMyyyykkmmss")) <
      moment(b.slice(0, -1).format("DDMMyyyykkmmss"))
    ) {
      return 1;
    } else {
      return 0;
    }
  });
  return arr;
};

export const ShareTransfer = () => {
  const [completeData, setCompleteData] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [tabValue, setTabValue] = useState("All Certificates");
  const [selectValue, setSelectValue] = useState("Last 30 Days");
  const [finalAdvanceSearchData, setFinalAdvanceSearchData] = useState(null);
  const [isAction, setIsAction] = useState(false);
  const [isUncheckAll, setIsUncheckAll] = useState(false);
  const [data, setData] = useState({
    startDate: moment().add(-30, "days").format("YYYY MM DD"),
    endDate: moment().format("YYYY MM DD"),
  });
  const dispatch = useDispatch();
  const tableRef = createRef();

  //auth token fetch from selector
  const getAuthData = useSelector((state) => {
    // console.log(state.auth);
    return state.auth.loginToken;
  });
  // console.log("completeData", completeData);

  // const filterData = () => {
  //   setFinalData(
  //     completeData
  //       ? completeData.filter((ele) => {
  //           // console.log("stratDate", moment(data.startDate).format("YYYY-MM-DD"));
  //           if (tabValue === "All") {
  //             return (
  //               convert(data.startDate) <= convert(ele.toMemberId.createdAt) &&
  //               convert(data.endDate) >= convert(ele.toMemberId.createdAt)
  //             );
  //           } else if (tabValue === "Credit") {
  //             return (
  //               ele.particulars === "credit" &&
  //               convert(data.startDate) <= convert(ele.toMemberId.createdAt) &&
  //               convert(data.endDate) >= convert(ele.toMemberId.createdAt)
  //             );
  //           } else {
  //             return (
  //               ele.particulars === "debit" &&
  //               convert(data.startDate) <= convert(ele.toMemberId.createdAt) &&
  //               convert(data.endDate) >= convert(ele.toMemberId.createdAt)
  //             );
  //           }
  //         })
  //       : []
  //   );
  // dispatch(loaderEndActionCreater());
  // };

  const filterDataDays = () => {
    let newFinalData = finalAdvanceSearchData
      ? finalAdvanceSearchData.filter((ele) => {
        if (selectValue === "Last 30 Days") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
            moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
            moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Today") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") ==
            moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") ==
            moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Yesterday") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") ==
            moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") ==
            moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "This Week") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
            moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
            moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Previous Week") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
            moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
            moment(ele.createdAt).format("YYYY-MM-DD")
          );
        } else if (selectValue === "Custom") {
          return (
            moment(data.startDate).format("YYYY-MM-DD") <=
            moment(ele.createdAt).format("YYYY-MM-DD") &&
            moment(data.endDate).format("YYYY-MM-DD") >=
            moment(ele.createdAt).format("YYYY-MM-DD")
          );
        }
      })
      : [];
    setFinalData(newFinalData);
    // dispatch(loaderEndActionCreater());
  };

  useEffect(() => {
    // filterData();
    filterDataDays();
  }, [tabValue, selectValue, data, finalAdvanceSearchData]);

  // console.log(memberList);

  const getShareApplicationMoney = async () => {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      let response = await axios.get(
        // process.env.REACT_APP_BASE_URL + `/api/v1/reports/shareTransfer`,
        //direct access to backend, 
        'http://43.204.110.120:8080' + `/api/v1/reports/shareTransfer`,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );

      // console.log(
      //   "main transfer",
      //   response.data.filter((ele) => {
      //     return ele.fromMemberId !== null;
      //   })
      // );

      // console.log(
      //   "main",
      //   response.data.sort((a, b) => {
      //     return (
      //       a.createdAt.slice(0, -1).format("DDMMyyyykkmmss") -
      //       b.createdAt.slice(0, -1).format("DDMMyyyykkmmss")
      //     );
      //   })
      // );

      let res = response.data.filter((ele) => {
        return ele.fromMemberId !== null;
      });

      // console.log(res)

      res = res.map((ele) => {
        return {
          ...ele,
          toMembershipId: ele?.toMemberId?.memberId,
          toMembershipName:
            ele?.toMemberId?.firstName + " " + ele?.toMemberId?.lastName,
          fromMembershipId: ele?.fromMemberId?.memberId,
          fromMembershipName:
            ele?.fromMemberId?.firstName + " " + ele?.fromMemberId?.lastName,
          amount: parseInt(
            parseInt(ele?.transactionId[0]?.pricePerShare || 0) *
            parseInt(ele?.noOfShares || 0)
          ),
          validShareCertificationNo: ele?.transactionId
            .map((ele) => {
              if (ele.isValid) {
                return ele.shareCertificationNo;
              }
            })
            .filter((e) => {
              return e != undefined;
            })
            .join(", "),
          invalidShareCertificationNo: ele?.transactionId
            .map((ele) => {
              // console.log("in transfer table",ele);
              if (!ele.isValid) {
                return ele.shareCertificationNo;
              }
            })
            .filter((e) => {
              return e != undefined;
            })

            .join(", "),
          shareNos: ele?.transactionId
            .reduce((ans, e) => {
              return ans.includes(
                `${e.typeOfShares.charAt(0)}${e.sharesFrom
                }-${e.typeOfShares.charAt(0)}${e.sharesTo}`
              )
                ? ans
                : [
                  ...ans,
                  `${e.typeOfShares.charAt(0)}${e.sharesFrom
                  }-${e.typeOfShares.charAt(0)}${e.sharesTo}`,
                ];
            }, [])
            .join(", "),
        };
      });

      // console.log(res);
      setCompleteData(res);
      setFinalAdvanceSearchData(res);

      // dispatch(loaderEndActionCreater());
      // console.log("Res", response.data.data);
    } catch (err) {
      // console.log(err.response.data.error);
      // let errors = err.response.data.error;
      dispatch(loaderEndActionCreater());
      // dispatch(openSnackbar(errors, "error"));
      if (err?.request?.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
    }
  };

  const columns = [
    {
      title: "Date",
      field: "createdAt",
      // type: "date",
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(rowData.createdAt.slice(0, -1)).format("DD/MM/yyyy")}
        </div>
      ),
      customFilterAndSearch: (term, rowData) =>
        moment(rowData.createdAt.slice(0, -1))
          .format("DD/MM/yyyy")
          .indexOf(term) > -1,
      dateSetting: {
        format: "dd/MM/yyyy",
      },
    },
    // {
    //   title: "Transaction ID",
    //   field: "id",
    //   headerStyle: {
    //     whiteSpace: "nowrap",
    //   },
    // },
    {
      title: "To Member ID",
      field: "toMembershipId",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.toMemberId?.memberId}`}
        </div>
      ),
      customSort: (a, b) => {
        return (a.toMembershipId || "").localeCompare(b.toMembershipId || "");
      },
      // cellStyle: {
      //   minWidth: 190,
      // },
    },
    {
      title: "To Member Name",
      field: "toMembershipName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.toMemberId?.firstName} ${rowData?.toMemberId?.lastName}`}
        </div>
      ),
      customSort: (a, b) => {
        return (a.toMembershipName || "").localeCompare(
          b.toMembershipName || ""
        );
      },
      // cellStyle: {
      //   minWidth: 190,
      // },
    },
    {
      title: "From MemberID",
      field: "fromMembershipId",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.fromMemberId?.memberId}`}
        </div>
      ),
      customSort: (a, b) => {
        return (a.fromMembershipId || "").localeCompare(
          b.fromMembershipId || ""
        );
      },
      // cellStyle: {
      //   minWidth: 150,
      // },
    },

    {
      title: "From Member Name",
      field: "fromMembershipName",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData.fromMemberId ? rowData.fromMemberId.firstName : ""} ${rowData.fromMemberId ? rowData.fromMemberId.lastName : ""
            } `}
        </div>
      ),
      customSort: (a, b) => {
        return (a.fromMembershipName || "").localeCompare(
          b.fromMembershipName || ""
        );
      },
      // cellStyle: {
      //   minWidth: 180,
      // },
    },
    {
      title: "No. Of Share",
      field: "noOfShares",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      customFilterAndSearch: (term, rowData) =>
        rowData.noOfShares.toString().indexOf(term) === 0,
      // cellStyle: {
      //   minWidth: 170,
      // },
    },
    {
      title: "Share Type",
      field: "typeOfShares",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "10rem",
            wordBreak: "break-word",
            whiteSpace: "nowrap",
            whiteSpace: "nowrap",
            lineBreak: "auto",
            overflowX: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.transactionId[0].typeOfShares}`}
        </div>
      ),
      customSort: (a, b) => {
        return (a.transactionId[0].typeOfShares || "").localeCompare(
          b.transactionId[0].typeOfShares || ""
        );
      },
      // cellStyle: {
      //   minWidth: 160,
      // },
    },
    // {
    //   title: "Date",
    //   field: "date",
    //   type: "date",
    //   dateSetting: {
    //     format: "dd/MM/yyyy",
    //   },
    // },

    {
      title: "Amount",
      field: "amount",
      // cellStyle: {
      //   minWidth: 150,
      // },
      // render: (rowData) => (
      //   <div
      //     sx={{ display: "table-cell" }}
      //     style={{
      //       maxWidth: "10rem",
      //       wordBreak: "break-word",
      //       whiteSpace: "nowrap",
      //       whiteSpace: "nowrap",
      //       lineBreak: "auto",
      //       overflowX: "hidden",
      //       textOverflow: "ellipsis",
      //     }}
      //   >
      //     {`${parseInt(
      //       parseInt(rowData?.transactionId[0]?.pricePerShare || 0) *
      //         parseInt(rowData.noOfShares || 0)
      //     )}`}
      //   </div>
      // ),
      customFilterAndSearch: (term, rowData) =>
        (rowData?.transactionId[0]?.pricePerShare * rowData.noOfShares)
          .toString()
          .indexOf(term) === 0,
    },
    {
      title: "Share Nos. (From-To)",
      field: "shareNos",
      cellStyle: {
        minWidth: 200,
      },
      customSort: (a, b) => {
        return (a.shareNos || "").localeCompare(b.shareNos || "");
      },
    },
    {
      title: "Status",
      field: "transactionId.status",
    },
    {
      title: "Valid Cert. No",
      field: "validShareCertificationNo",
      headerStyle: {
        whiteSpace: "nowrap",
      },
      // cellStyle: {
      //   minWidth: 190,
      // },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "15rem",
            wordBreak: "break-word",
            // whiteSpace: "nowrap",
            lineBreak: "normal",
            // overflowX: "hidden",
            // textOverflow: "ellipsis",
          }}
        >
          {/* {console.log(rowData?.transactionId)} */}
          {`${rowData?.transactionId
            .map((ele) => {
              if (ele.isValid) {
                return ele.shareCertificationNo;
              }
            })
            .filter((e) => {
              return e != undefined;
            })
            .join(", ")}`}
        </div>
      ),
    },
    {
      title: "Invalid Cert. No",
      field: "invalidShareCertificationNo",
      // cellStyle: {
      //   minWidth: 200,
      // },
      headerStyle: {
        whiteSpace: "nowrap",
      },
      render: (rowData) => (
        <div
          sx={{ display: "table-cell" }}
          style={{
            maxWidth: "15rem",
            wordBreak: "break-word",
            // whiteSpace: "nowrap",
            lineBreak: "normal",
            // overflowX: "hidden",
            // textOverflow: "ellipsis",
          }}
        >
          {`${rowData?.transactionId
            .map((ele) => {
              // console.log("in transfer table",ele);
              if (!ele.isValid) {
                return ele.shareCertificationNo;
              }
            })
            .filter((e) => {
              return e != undefined;
            })

            .join(", ")}`}
        </div>
      ),
    },
  ];

  useEffect(() => {
    getShareApplicationMoney();
    dispatch(loaderStartActionCreater());
  }, []);

  useEffect(() => {
    if (completeData) dispatch(loaderEndActionCreater());
  }, [completeData]);

  const columnsStatus = useSelector((state) => {
    // console.log(state.members.columnsStatus);
    return state.reports.shareTransfer;
  });

  const setColumnsStatus = (data) => {
    // console.log(data);
    dispatch(reportDataVisibiltyActionCreater(data, "SHARE_TRANSFER"));
  };

  useEffect(() => {
    if (isUncheckAll === false && tabValue === "All Certificates") {
      if (!columnsStatus.validShareCertificationNo)
        dispatch(
          reportDataVisibiltyActionCreater(
            "validShareCertificationNo",
            "SHARE_TRANSFER"
          )
        );
      if (!columnsStatus.invalidShareCertificationNo)
        dispatch(
          reportDataVisibiltyActionCreater(
            "invalidShareCertificationNo",
            "SHARE_TRANSFER"
          )
        );
    }
    if (isUncheckAll === false && tabValue === "Valid Certificates") {
      if (!columnsStatus.validShareCertificationNo)
        dispatch(
          reportDataVisibiltyActionCreater(
            "validShareCertificationNo",
            "SHARE_TRANSFER"
          )
        );
      if (columnsStatus.invalidShareCertificationNo)
        dispatch(
          reportDataVisibiltyActionCreater(
            "invalidShareCertificationNo",
            "SHARE_TRANSFER"
          )
        );
    }
    if (isUncheckAll === false && tabValue === "Invalid Certificates") {
      if (columnsStatus.validShareCertificationNo)
        dispatch(
          reportDataVisibiltyActionCreater(
            "validShareCertificationNo",
            "SHARE_TRANSFER"
          )
        );
      if (!columnsStatus.invalidShareCertificationNo)
        dispatch(
          reportDataVisibiltyActionCreater(
            "invalidShareCertificationNo",
            "SHARE_TRANSFER"
          )
        );
    }
    if (isUncheckAll) setIsUncheckAll(false);
  }, [tabValue]);

  useEffect(() => {
    if (
      columnsStatus.validShareCertificationNo &&
      columnsStatus.invalidShareCertificationNo
    ) {
      setTabValue("All Certificates");
    } else if (columnsStatus.validShareCertificationNo) {
      setTabValue("Valid Certificates");
    } else if (columnsStatus.invalidShareCertificationNo) {
      setTabValue("Invalid Certificates");
    } else {
      setTabValue("All Certificates");
      setIsUncheckAll(true);
    }
  }, [columnsStatus]);

  // console.log("finalData", finalData,finalAdvanceSearchData);
  return (
    <>
      <ShareTransferHeader
        tableRef={tableRef}
        setTabValue={setTabValue}
        tabValue={tabValue}
        finalData={finalData}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        setData={setData}
      />
      <div>
        <div
          className="d-flex justify-content-end gap-2 align-items-center mb-3"
          style={{ padding: "0rem 2rem" }}
        >
          {/* {console.log(tableRef ? tableRef?.current?.state?.data : data)} */}
          <CustomSearchForReport
            data={completeData || []}
            // data={tableRef ? tableRef?.current?.state?.data : data}
            ref={tableRef}
            tableData={finalData}
            setTableData={setFinalData}
            finalAdvanceSearchData={finalAdvanceSearchData}
            setFinalAdvanceSearchData={setFinalAdvanceSearchData}
            // rangeSearchCol={[
            //   ["Total Shares", "totalShare"],
            //   ["Share Capital", "shareCapital"],
            //   ["Share Application Money", "shareApplicationMoney"],
            // ]}
            simpleSearchCol={[
              // ["State", "state"],
              ["First Name", "firstName"],
              ["Last Name", "lastName"],
              ["Membership Id", "memberId"],
              // ["District", "district"],
              // ["Taluka", "taluka"],
              // ["City", "city"],
              // ["Organisation Name", "organisation_name"],
            ]}
            columns={columns}
            isAction={isAction}
            setIsAction={setIsAction}
          />
          <MultiSelectDropdown
            columns={columns}
            columnsStatus={columnsStatus}
            setColumnsStatus={setColumnsStatus}
          />
        </div>

        <ShareTransferTable
          tableRef={tableRef}
          finalData={finalData}
          tabValue={tabValue}
          columns={columns}
          columnsStatus={columnsStatus}
        />
      </div>
    </>
  );
};
