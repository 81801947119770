import React, { useState } from "react";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

const Calender = () => {
  return (
    <div align="right" style={{ padding: "20px 30px 0 0" }}>
      <CalendarTodayOutlinedIcon
        style={{ color: "#888888", fontSize: "30px", cursor: "pointer" }}
      />
    </div>
  );
};

export default Calender;
