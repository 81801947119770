import React, { useState, useEffect } from "react";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styles from "../member.module.css";
import CSVReader from "react-csv-reader";
import { Button, Tooltip } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbar } from "../../../Redux/Snackbar/snackbarStore";
import { getTableDetails } from "../../Uitls/Utils";
import { BASE_URL } from "../../../Components/URL/Url";
import DialoguePriview from "../../../Components/Dialogs/Dialogs";
import pincodeDirectory from "india-pincode-lookup";
import Help from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import {
  getDashboardTransfer,
  getTop10ShareHolders,
  getTotalShareHoldersMembers,
} from "../../../Redux/Dashboard/dashboardActionCreator";
import { LogoutSayasActionCreate } from "../../../Redux/Authontication/authonticationActionCreator";

export default function MemberShareApplication(props) {
  const { open, setOpen, setData, setConflict } = props;
  const dispatch = useDispatch();

  const [uploadData, setUploadData] = useState([]);
  const [error, setError] = useState([]);
  const [pinError, setPinError] = useState([]);
  const [downloadFileData, setDownloadFileData] = React.useState();

  // download template imeplementation
  const [downloadURL, setDownloadURL] = useState();
  const getConfigData = useSelector((state) => {
    return state.configuration.uploadTemplate;
  });

    //auth token fetch from selector
    const getAuthData = useSelector((state) => {
      // console.log(state.auth);
      return state.auth.loginToken;
    });

  useEffect(() => {
    // console.log(getConfigData);
    let newURL = getConfigData
      ? getConfigData.filter((e) => {
          return e.type === "shareApplicationMoney";
        })
      : "";

    setDownloadURL(newURL[0] ? newURL[0].fileURL : "");
  }, [getConfigData]);

  const handleClose = () => {
    setOpen(false);
    setUploadData([]);
    // setConflict(true);
  };

  const handleForce = (data, fileInfo) => {
    // console.log("Change File!!");
    setUploadData([]);
    setUploadData(data);
  };

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header,
  };

  const handleUploadFile = async () => {
    setOpen(false);

    let a = uploadData.map((data) => {
      return (
        data?.memberId?.toString()?.length <= 14 &&
        !isNaN(data.amount) &&
        parseInt(data.amount) > 0 &&
        data.amount % 1 === 0
      );
    });

    var newData = uploadData.filter((ele, i) => {
      return a[i];
    });

    newData = newData.filter((item, i) => (item.memberId = item.memberId));

    const remainingDataUpdate = uploadData.filter((ele, i) => {
      return !a[i];
    });

    const newErrorList = remainingDataUpdate.map((item, i) => {
      let isId = item.memberId.toString().length <= 14;
      let isAmount = isNaN(item.amount)
        ? true
        : item.amount <= 0
        ? true
        : false;

      return {
        ...item,
        [`error`]: `Please insert a valid ${!isId ? "Member Id" : ""}${
          !isId && isAmount ? " and " : ""
        }${isAmount ? "Amount" : ""}`,
      };

      // item.error = `Please insert a valid ${!ismail ? "email address" : ""}${
      //   !ismail && !isPhone ? " ,and " : ""
      // }${!ismail ? "phone number" : ""}`;
    });

    // console.log("newErrorList", newErrorList);

    // if (!a.includes(false) && !b.includes(false)) {
    try {
      ////checktoken
      // let token = window.localStorage.getItem("sayasLoginToken");
      let token = getAuthData;
      if (!token) {
        dispatch(LogoutSayasActionCreate());
      }
      const response = await axios.post(
        BASE_URL + `/api/v1/member/addBulkMoney`,
        newData,
        {
          headers: {
            Authorization: `Bearer ` + `${token}`,
          },
        }
      );
      getTableDetails(setData, `/api/v1/member`);
      dispatch(
        openSnackbar("Import Share Application Money is completed", "success")
      );
      dispatch(getDashboardTransfer(getAuthData));
      dispatch(getTop10ShareHolders(getAuthData));
      dispatch(getTotalShareHoldersMembers(getAuthData));
      handleClose();
      console.log("Success////");
      // console.log("newData", newData);
      // console.log("response.data.errUsers", response.data.errTransactions);
      ////new file updates
      const JsonFields = ["memberId", "amount", "error"];
      var csvStr = JsonFields.join(",") + "\n";

      [...newErrorList, ...response.data.errTransactions].forEach((element) => {
        let memberId = element.memberId;
        let amount = element.amount;
        let error = element.error;
        // console.log("In create file");
        csvStr += memberId + "," + amount + "," + error + "\n";
      });
      if ([...newErrorList, ...response.data.errTransactions].length > 0) {
        var hiddenElement = document.createElement("a");
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvStr);
        hiddenElement.target = "_blank";
        hiddenElement.download = "Share_Application_Money_Error_Log.csv";
        hiddenElement.click();
      }
    } catch (err) {
      console.log("Error", err.response);
      handleClose();
      // let errors = err.response.data;
      if (err.response?.status == 400) {
        dispatch(openSnackbar("Duplicate Field Error", "error"));
      }
      if (err.request.status === 401) {
        dispatch(LogoutSayasActionCreate());
      }
      // dispatch(openSnackbar(`${err.response.data}`, "error"));
    }
    // } else {
    //   myFunction(uploadData);
    //   setUploadData([]);
    // }
    handleClose();
  };

  const SelectFile = () => {
    dispatch(openSnackbar("Select CSV File", "error"));
  };

  // useEffect(async () => {
  //   try {
  //     const response = await axios.get(BASE_URL + `/api/v1/configuration/`);
  //     console.log(response.data.data[0].uploadTemplate[0].fileURL);
  //     setDownloadFile(response.data.data[0].uploadTemplate[0].fileURL);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, []);

  // const getCSVData = async (fileLink) => {
  //   try {
  //     console.log(fileLink);
  //     axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //     var fileData = await axios.get(fileLink, {
  //       responseType: "blob",
  //       withCredentials: false,
  //     });
  //     const newFileData = fileData.data;
  //     console.log(newFileData);
  //     setDownloadFileData(newFileData);
  //     // dispatch(openSnackbar("Data Imported Successfully", "success"));
  //   } catch (err) {
  //     // dispatch(openSnackbar("Data Imported Successfully", "error"));
  //   }
  // };

  React.useEffect(() => {
    let a = uploadData.map((data) => {
      return (
        data?.memberId?.toString()?.length <= 14 &&
        !isNaN(data.amount) &&
        parseInt(data.amount) > 0 &&
        data.amount % 1 === 0
      );
    });
    // console.log(a);
    setError(a);
  }, [uploadData]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="m-0 p-2">
          <label id={styles.userLabel}>Upload File</label>
        </DialogTitle>
        <hr className="m-0" />
        <div style={{ paddingLeft: "150px" }}>
          <DialogContent className="pb-0">
            <div className="d-flex align-items-center">
              <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={handleForce}
                parserOptions={papaparseOptions}
              />

              {/* ///download template */}
              {downloadURL === "" ? (
                ""
              ) : (
                <Tooltip
                  title={`Click To Download Template`}
                  arrow
                  placement="top"
                >
                  <IconButton>
                    {/* {console.log(downloadURL ? downloadURL.split("/")[3].substring(13): "")} */}
                    <Help
                      style={{ fontSize: "20px" }}
                      onClick={() => {
                        // window.location.href = downloadURL;
                        // getCSVData(downloadURL);
                        // var csvContent =
                        //   "data:text/csv;charset=utf-8,%EF%BB%BF" +
                        //   encodeURI(downloadFileData);
                        // console.log(downloadURL);
                        // var link = document.createElement("a");
                        // link.setAttribute("href",  downloadURL);
                        // link.setAttribute(
                        //   "download",
                        //   `${
                        //     downloadURL
                        //       ? downloadURL.split("/")[3].substring(13)
                        //       : ""
                        //   }`
                        // );
                        // link.click();
                        window.location.href = downloadURL;
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </DialogContent>

          <DialogContent className="m-0 pt-0">
            {uploadData.length !== 0 ? (
              <div className="mt-2" style={{ paddingLeft: "50px" }}>
                <DialoguePriview
                  data={uploadData}
                  error={error}
                  pinError={pinError}
                  module="shareApplication"
                />
              </div>
            ) : null}
          </DialogContent>
        </div>

        <hr className="m-0" />
        <DialogContent className="p-0 pt-2 pb-3">
          <div className="d-flex justify-content-end align-items-center">
            <Button id={styles.cancelFile} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              id={styles.fileUploadBtn}
              onClick={uploadData.length == 0 ? SelectFile : handleUploadFile}
            >
              Upload
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
