import actions from "./shareCapitalActions";
import {
  ADD_DATA,
  UPDATE_DATA,
  UPDATE_TABLE_ROW_DATA,
} from "./shareCapitalConstants";

const initState = {
  user: null,
  loading: false,
  error: null,
  formData: {},
  selectedRow: {},
  columnsStatus: {
    shareCapitalId: true,
    authorisedShareCapital: true,
    authorisedShare: true,
    pricePerShare: true,
    date: true,
    typesOfShare: true,
    remarks: true,
    remainingShare: true,
  },
};

// console.log("3",actions);

const ShareCapitalReducer = (state = initState, action) => {
  const { type, data, err } = action;

  // console.log("3.1",type);
  // console.log("3.1.1",data);

  switch (type) {
    case ADD_DATA:
      return { ...state, formData: data };
    case UPDATE_DATA:
      var newVal = Object.create({ ...state, formData: newVal });
      return newVal;
    case UPDATE_TABLE_ROW_DATA:
      return { ...state, selectedRow: data };
    case "COLUMNSTATUS":
      return {
        ...state,
        columnsStatus: {
          ...state.columnsStatus,
          [`${data}`]: state.columnsStatus[`${data}`]
            ? state.columnsStatus[`${data}`]
              ? false
              : true
            : true,
        },
      };
    default:
      return state;
  }
};
export default ShareCapitalReducer;
